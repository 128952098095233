import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { background, primary } from "../../styles/colors";
import { CssTextField } from "../../styles/StyledComponents";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { MuiDateRangePicker } from "../../layoutComponents/DateRangePicker";
import moment from "moment";
import TableComponent from "../../layoutComponents/TableComponent";
import { fetchRecursivePaginatedData } from "../../Regex";

const styles = {
  dateButtonsGroup: {
    borderRadius: 4,
    padding: 4,
    color: "white",
    backgroundColor: "#7D4909",
  },
  dateText: {
    marginTop: 4,
    fontSize: 12,
    color: "#666",
  },
  getReportButton: {
    background: "rgb(241 154 48)",
    color: "white",
    padding: "4px 4px",
    width: 160,
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  innerDate: {
    fontWeight: 600,
    margin: "0 6px",
  },
  progressText: {
    top: 0,
    left: 0,
    bottom: 5,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    height: 200
  }
};

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: "auto", // Enable vertical scrolling
    },
  },
};

const status = [
  { label: "DRAFT", value: 0 },
  { label: "OPEN", value: 1 },
  { label: "CLOSED", value: 2 },
  { label: "CANCELLED", value: 3 },
];

const ReportPage = (props) => {
  const [filterValue, setFilterValue] = useState({});
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [dateRangeTitle, setDateRangeTitle] = useState("");
  const [tableData, setTableData] = useState([]);
  const [fetchReportProgress, setFetchReportProgress] = useState(0);
  const [clicked, setClicked] = useState(false);

  const handleCountStateUpdate = (data) => {
    setFetchReportProgress(data);
  };

  const handleResponseData = (responseDataList, type = "") => {
    let responseData = [...responseDataList];

    setTableData(responseData);
  };

  const FetchTableData = async () => {
    setClicked(true);
    fetchRecursivePaginatedData(
      "GET",
      "reports",
      {},
      "",
      handleCountStateUpdate
    ).then(async ({ responseDataList }) => {
      handleResponseData(responseDataList);
      setClicked(false);
    });
  };

  const toggle = () => setDatePickerOpen(!datePickerOpen);

  const handleDateChange = (date) => {
    if (date?.startDate || date?.endDate) {
      setDateRangeTitle(
        `Date ${moment(date?.startDate).format("DD-MMM-YY")} to ${moment(
          date?.endDate
        ).format("DD-MMM-YY")}`
      );
      setDateRange(date);
      setDatePickerOpen(!datePickerOpen);
    } else {
      setDateRangeTitle("");
      setDateRange({});
    }
  };

  const handleFilterChange = (key, value) => {
    setFilterValue({
      ...filterValue,
      [key]: value,
    });
  };

  const { tabModule, header } = props;

  return (
    <div style={{ padding: 12 }}>
      <div className="d-flex justifySB alignC">
        <div>
          <CssTextField
            autoComplete="off"
            value={filterValue?.status ?? ""}
            onChange={(e) => {
              handleFilterChange("status", e.target.value);
            }}
            select
            label="Status"
            name="status"
            style={{
              width: 190,
              margin: "0 5px",
            }}
            SelectProps={{ MenuProps: MenuProps }}
            variant="outlined"
          >
            {status.map((option) => (
              <MenuItem
                key={option.label}
                value={option.label}
                id={option.label}
                style={{
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </CssTextField>
        </div>
        <div className="d-flex alignC">
          <span style={{ margin: "0 10px" }}>
            <span>
              <IconButton
                style={styles.dateButtonsGroup}
                disableAutoFocus={true} // Prevent focusing when the menu opens
                disableEnforceFocus={true}
                onClick={(e) => {
                  setDatePickerOpen(!datePickerOpen);
                }}
              >
                <DateRangeIcon color="grey" />
              </IconButton>
              <MuiDateRangePicker
                open={datePickerOpen}
                toggle={toggle}
                dateRange={dateRange}
                onDateRangeChange={(e) => handleDateChange(e)}
              />
            </span>
          </span>
          <Button
            style={styles.getReportButton}
            onClick={FetchTableData}
            disabled={clicked}
          >
            Get Report
          </Button>
        </div>
      </div>
      <div style={{ margin: "10px 0 0 0", paddingLeft: 5 }}>
        <div className="d-flex alignC justifyFE">
          <span style={styles.dateText}>
            Date
            <span style={styles.innerDate}>
              {moment(dateRange?.startDate).format("Do MMM YY")}
            </span>
            to
            <span style={styles.innerDate}>
              {moment(dateRange?.endDate).format("Do MMM YY")}
            </span>
          </span>
        </div>
        <TableComponent
          module={`Report ${tabModule} Table`}
          noAddButton={true}
          padding={"0px"}
          subMenu={false}
          noSearchBar={true}
          headerShownAlways={true}
          loader={false}
          loaderUI={{
            visible: true,
            comp: () => (
              <div className="d-flex width-100 alignC justifyC d-flex-column" style={styles.loaderContainer}>
                <Box style={{ position: "relative" }}>
                  <CircularProgress
                    variant="determinate"
                    style={{ color: "#ddd" }}
                    size={70}
                    thickness={4}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    style={{
                      position: "absolute",
                      left: 0,
                      color: primary,
                    }}
                    size={70}
                    thickness={4}
                    value={
                      fetchReportProgress === undefined
                        ? 0
                        : fetchReportProgress
                    }
                  />
                  <Box style={styles.progressText}>
                    <Typography
                      variant="body1"
                      component="div"
                      color="text.secondary"
                    >
                      {`${Math.round(
                        fetchReportProgress === undefined
                          ? 0
                          : fetchReportProgress
                      )}%`}
                    </Typography>
                  </Box>
                </Box>
              </div>
            ),
          }}
          header={header.map((h) => h.label)}
          tableList={tableData ? tableData : []}
        />
      </div>
    </div>
  );
};

export default ReportPage;
