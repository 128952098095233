import React, { useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";

const BrandDetail = (props) => {
  const [RiceBagCountList, setRiceBagCountList] = useState([]);

  const fetchRiceBagCount = async () => {
    console.log("RR");
    props
      .BrandsManagementsFunction(
        "get",
        "dispatch/rice-bag-count",
        null,
        "RiceBagCountList",
        null,
        { is_active: "True", brand_id: props.brandId }
      )
      .then((RiceBagCountList) => {
        setRiceBagCountList(RiceBagCountList);
      });
  };

  useEffect(() => {
    fetchRiceBagCount();
  }, [window.location.pathname]);

  return (
    <div>
      <TableComponent
        module={"Rice Bag Count"}
        subMenu={true}
        noAddButton={true}
        padding={"0px"}
        tableRowHeight={50}
        headerShownAlways={true}
        tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
        loader={false}
        actions={[]}
        header={[
          "Sr.No.",
          "Miller name",
          "Available rice bags",
          "Used Rice Bags",
        ]}
        subMenuheader={["Sr.No.", "Packaging", "Available Bags", "Used Bags"]}
        tableHeight="auto"
        tablePagination={false}
        footer={false}
        tableList={
          RiceBagCountList && RiceBagCountList.length
            ? RiceBagCountList.map((singleBag) => {
                singleBag["Miller name"] = singleBag
                  ? singleBag.miller_name
                  : "-";
                singleBag["Available rice bags"] = singleBag
                  ? singleBag.bags && singleBag.bags.length > 0
                    ? singleBag.bags.reduce(
                        (previousValue, currentValue) =>
                          Number(previousValue) +
                          (currentValue.total_bags
                            ? currentValue.total_bags
                            : 0),
                        0
                      )
                    : "-"
                  : "-";
                singleBag["Used Rice Bags"] = singleBag
                  ? singleBag.bags && singleBag.bags.length > 0
                    ? singleBag.bags.reduce(
                        (previousValue, currentValue) =>
                          Number(previousValue) +
                          (currentValue.used_bags
                            ? Number(currentValue.used_bags)
                            : 0),
                        0
                      )
                    : "-"
                  : "-";
                singleBag["subList"] = singleBag.bags
                  ? singleBag.bags.map((sBag) => {
                      sBag["Packaging"] = sBag
                        ? `${sBag.size}${sBag.unit}${" - "}${sBag.bag_type}`
                        : "-";
                      sBag["Available Bags"] = sBag
                        ? sBag.total_bags
                          ? sBag.total_bags
                          : 0
                        : "-";
                      sBag["Used Bags"] = sBag ? sBag.used_bags : "-";

                      return sBag;
                    })
                  : [];

                return singleBag;
              })
            : []
        }
      />
    </div>
  );
};

export default BrandDetail;
