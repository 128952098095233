import React, { useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";

const BrandInventory = (props) => {
  const [BagInventoryList, setBagInventoryList] = useState([]);

  const fetchBagInventory = async () => {
    console.log("RR");
    props
      .BrandsManagementsFunction(
        "get",
        "dispatch/ssipl-bag-inventory/list",
        null,
        "BagInventoryList",
        null,
        { is_active: "True", brand_id: props.brandId }
      )
      .then((BagInventoryList) => {
        setBagInventoryList(BagInventoryList);
      });
  };

  useEffect(() => {
    fetchBagInventory();
  }, [window.location.pathname]);

  console.log(props);

  return (
    <div>
      <TableComponent
        module={"Bag Inventory"}
        noAddButton={true}
        padding={"0px"}
        tableRowHeight={60}
        headerShownAlways={true}
        tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
        loader={false}
        actions={[]}
        header={["Sr.No.", "Packaging", "Available Bags", "Used Bags"]}
        tableHeight="auto"
        tablePagination={false}
        footer={false}
        tableList={
          BagInventoryList && BagInventoryList.length
            ? BagInventoryList.map((singleBag) => {
                singleBag["Packaging"] = singleBag.packaging
                  ? props.PackagingList?.find(
                      (p) => p.id === singleBag.packaging
                    )?.sizeUnit || "-"
                  : "-";
                singleBag["Available Bags"] = singleBag.remaining_bags
                  ? singleBag.remaining_bags
                  : "-";

                singleBag["Used Bags"] = singleBag.used_bags
                  ? singleBag.used_bags
                  : "-";

                return singleBag;
              })
            : []
        }
      />
    </div>
  );
};

export default BrandInventory;
