// import { moduleIdURL, moduleURL, noFmoduleIdURL } from "../../http/config";
// import { requests } from "../../requests";
// export const PrecarriersFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//     try {
//       const module = path?path:"Precarriers";
//       dispatch(fetchingRequest(state?state:module));
//       const options = {
//         method: method,
//         url: id ? moduleIdURL(module, id) : moduleURL(module),
//         params: { id: id },
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state?state:module}_SUCCESS`,
//           payload:state?state: module,
//           data: response.data,
//         });
//         return response.data;
//       });
  
//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response ? error.response.data : "Some error occured, please try again!",
//       };
//     }
//   };
// export const PolicysFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Policys";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const SOpaymentTermsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "SOpaymentTerms";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const IncotermsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Incoterms";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const ShippingTermsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "ShippingTerms";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const CurrencysFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Currencys";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const NotifysFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Notifys";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const RiceBagSellersFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "RiceBagSellers";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const BeneficiarysFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Beneficiarys";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const ShippersFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Shippers";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const UsersAndPermissionsFunction = (method, path, id, state, addData, params) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "UsersAndPermissions";

//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: params ? params : { id: id },
//       data: addData ? addData : "",
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const UsersPermissionsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "UsersPermissions";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const BrandsManagementsFunction = (method, path, id, state, addData, params) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "BrandsManagements";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: params ? params : { id: id },
//       data: addData ? addData : "",
//     };
//     const data = await requests(options).then((response) => {
//       console.log(response, "response");
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });
//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const MillersFunction = (method, path, id, state, addData, params) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Millers";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: params ? params : { id: id },
//       data: addData ? addData : "",
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const BrokersFunction = (method, path, id, state, addData, params) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Brokers";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: params ? params : { id: id },
//       data: addData ? addData : "",
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const TransportersFunction = (method, path, id, state, addData, params) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Transporters";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: params ? params : { id: id },
//       data: addData ? addData : "",
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const PortsFunction =
//   (method, path, id, state, addData, params, noForwordSlash,noeditparam) => async (dispatch, getState) => {
//     try {
//       const module = path ? path : "Ports";
//       dispatch(fetchingRequest(state ? state : module));
//       const options = {
//         method: method,
//         url: noForwordSlash ? noFmoduleIdURL(module) : id ? moduleIdURL(module, id) : moduleURL(module),
//         params:noeditparam?{}: params ? params : { id: id },
        
//         data: addData ? addData : "",
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state ? state : module}_SUCCESS`,
//           payload: state ? state : module,
//           data: response.data,
//         });
//         return response.error ? response : response.data;
//       });

//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response ? error.response.data : "Some error occured, please try again!",
//       };
//     }
//   };
// export const HsNsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "HsNs";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };

// export const PurchaseOrdersFunction =
//   (method, path, id, state, addData, param, noeditparam) => async (dispatch, getState) => {
//     try {
//       const module = path ? path : "PurchaseOrders";
//       dispatch(fetchingRequest(state ? state : module));
//       const options = {
//         method: method,
//         url: id ? moduleIdURL(module, id) : moduleURL(module),
//         params: noeditparam ? {} : param ? param : { id: id },
//         data: addData ? addData : "",
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state ? state : module}_SUCCESS`,
//           payload: state ? state : module,
//           data: response.data,
//         });

//         return response.error ? response : response.data;
//       });

//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response ? error.response.data : "Some error occured, please try again!",
//       };
//     }
//   };
// export const SalesOrdersFunction =
//   (method, path, id, state, addData, param, noeditparam) => async (dispatch, getState) => {
//     try {
//       const module = path ? path : "SalesOrders";
//       dispatch(fetchingRequest(state ? state : module));
//       const options = {
//         method: method,
//         url: id ? moduleIdURL(module, id) : moduleURL(module),
//         params: noeditparam ? {} : param ? param : { id: id },
//         data: addData ? addData : "",
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state ? state : module}_SUCCESS`,
//           payload: state ? state : module,
//           data: response.data,
//         });

//         return response.error ? response : response.data;
//       });

//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response ? error.response.data : "Some error occured, please try again!",
//       };
//     }
//   };
// export const PackagingsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Packaging";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const ContractTypesFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "ContractTypes";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const PaymentTermsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "PaymentTerms";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const ItemTypesFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "ItemTypes";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };

// class ItemCategory {
//   constructor(id, categoryName, status) {
//     this.id = id;
//     this.categoryName = categoryName;
//     this.status = status;
//   }
// }

// const itemCategories = [new ItemCategory("cat1", "White Rice", true), new ItemCategory("cat2", "Parboiled Rice", true)];

// export const ItemCategoriesFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "ItemCategories";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//         //data: itemCategories,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };
// export const SurveyCompanysFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "SurveyCompanys";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };

// class Client {
//   constructor(id, name, address, city, state, country, phone) {
//     this.id = id;
//     this.name = name;
//     this.address = address;
//     this.city = city;
//     this.state = state;
//     this.country = country;
//     this.phone = phone;
//   }
// }

// export const ClientsFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Clients";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//         //data: clients,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };

// // export const PortsFunction =
// //   (method, path, id, state, data) => async (dispatch, getState) => {
// //     try {
// //       const module = path ? path : "Ports";
// //       dispatch(fetchingRequest(state ? state : module));
// //       const options = {
// //         method: method,
// //         url: id ? moduleIdURL(module, id) : moduleURL(module),
// //         params: { id: id },
// //       };
// //       const data = await requests(options).then((response) => {
// //         dispatch({
// //           type: `FETCH_${state ? state : module}_SUCCESS`,
// //           payload: state ? state : module,
// //           data: response.data,
// //         });
// //
// //return response.error ? response : response.data;
// //       });

// //       return data;
// //     } catch (error) {
// //       return {
// //         error: true,
// //         data: error.response
// //           ? error.response.data
// //           : "Some error occured, please try again!",
// //       };
// //     }
// //   };

// export const DispatchesFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Dispatches";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };

// export const UsersFunction = (method, path, id, state, data) => async (dispatch, getState) => {
//   try {
//     const module = path ? path : "Users";
//     dispatch(fetchingRequest(state ? state : module));
//     const options = {
//       method: method,
//       url: id ? moduleIdURL(module, id) : moduleURL(module),
//       params: { id: id },
//     };
//     const data = await requests(options).then((response) => {
//       dispatch({
//         type: `FETCH_${state ? state : module}_SUCCESS`,
//         payload: state ? state : module,
//         data: response.data,
//       });

//       return response.error ? response : response.data;
//     });

//     return data;
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response ? error.response.data : "Some error occured, please try again!",
//     };
//   }
// };

// const fetchingRequest = (data) => {
//   return {
//     type: "FETCH_REQUEST",
//     payload: data,
//     data: [],
//   };
// };
import { moduleIdURL, moduleURL, noFmoduleIdURL } from "../../http/config";
import { requests } from "../../requests";

const extractKey = (path) => {
  let textKey = path.split("/")[1].replaceAll("-","").toUpperCase().replace(/S$/, "") ;
  const item = localStorage.getItem(textKey+"LIST")
  const item2 = localStorage.getItem(textKey+"SLIST")
  console.log(path, textKey, Boolean(item) || Boolean(item2))
  if(Boolean(item)) {
    return textKey + "LIST"
  }
  else if(Boolean(item2)) {
    return textKey + "SLIST"
  }
  else {
    return null;
  }
}

const fetchApiDataAndUpdateLocalStorage = async (name, type='read', data=false) => {
  console.log("here LOCAL STORAGE", name, type, data)
  return null
  // if(type === 'read'){
  // const localStorageData = localStorage.getItem(name.toUpperCase());
  //   if (localStorageData) {
  //     const parsedData = JSON.parse(localStorageData);
  //     return parsedData;
  //   }
  //   else {
  //     return null;
  //   }
  // }
  // else if(type === 'write'){
  //   if(name){
  //     localStorage.setItem(name.toUpperCase(), JSON.stringify(data)); 
  //   }
  // }
}
export const ChatsFunction =
  (method, path, id, state, addData, param, noeditparam) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "Chats";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: noeditparam ? {} : param ? param : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const HomesFunction =
  (method, path, id, state, addData, param, noeditparam) =>
  async (dispatch, getState) => {
    try {
      const module = path ? path : "Homes";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: noeditparam ? {} : param ? param : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PrecarriersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Precarriers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PolicysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Policys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const SOpaymentTermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "SOpaymentTerms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const IncotermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Incoterms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ShippingTermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ShippingTerms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const CurrencysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Currencys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const NotifysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Notifys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const RiceBagSellersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "RiceBagSellers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const BeneficiarysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Beneficiarys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ShippersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Shippers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const UsersAndPermissionsFunction =
  (method, path, id, state, addData, params) => async (dispatch, getState) => {
    try {
      const module = path ? path : "UsersAndPermissions";

      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const UsersPermissionsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "UsersPermissions";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const BrandsManagementsFunction =
  (method, path, id, state, addData, params,noForwordSlash) => async (dispatch, getState) => {
    try {
      const module = path ? path : "BrandsManagements";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: noForwordSlash
        ? noFmoduleIdURL(module) 
        : id ? moduleIdURL(module, id) : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        console.log(response, "response");
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const MillersFunction =
  (method, path, id, state, addData, params, noForwordSlash) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Millers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: noForwordSlash ? noFmoduleIdURL(module) : id ? moduleIdURL(module, id) : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const BrokersFunction =
  (method, path, id, state, addData, params) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Brokers";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const TransportersFunction =
  (method, path, id, state, addData, params) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Transporters";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PortsFunction =
  (method, path, id, state, addData, params, noForwordSlash) =>
  async (dispatch, getState) => {
    try {
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        let data = await fetchApiDataAndUpdateLocalStorage(state, 'read')
        if(data !== null){
          return data
        }
      }
      const module = path ? path : "Ports";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: noForwordSlash
          ? noFmoduleIdURL(module)
          : id
          ? moduleIdURL(module, id)
          : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.error ? response : response.data;
      });

      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(state, 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const InlandGodownFunction =
  (method, path, id, state, addData, params, noForwordSlash) =>
  async (dispatch, getState) => {
    try {
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        let data = await fetchApiDataAndUpdateLocalStorage(state, 'read')
        if(data !== null){
          return data
        }
      }
      const module = path ? path : "InlandGodown";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: noForwordSlash
          ? noFmoduleIdURL(module)
          : id
          ? moduleIdURL(module, id)
          : moduleURL(module),
        params: params ? params : { id: id },
        data: addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });
        return response.error ? response : response.data;
      });

      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(state, 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const HsNsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "HsNs";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

// export const PurchaseOrdersFunction =
//   (method, path, id, state, addData, param, noeditparam) =>
//   async (dispatch, getState) => {
//     try {
//       const module = path ? path : "PurchaseOrders";
//       dispatch(fetchingRequest(state ? state : module));
//       const options = {
//         method: method,
//         url: id ? moduleIdURL(module, id) : moduleURL(module),
//         params: noeditparam ? {} : param ? param : { id: id },
//         data: addData ? addData : "",
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state ? state : module}_SUCCESS`,
//           payload: state ? state : module,
//           data: response.data,
//         });

//         return response.error ? response : response.data;
//       });

//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response
//           ? error.response.data
//           : "Some error occured, please try again!",
//       };
//     }
//   };
export const PurchaseOrdersFunction =
  (method, path, id, state, addData, param, noeditparam,noLoader,oldData,nextApi, noForwordSlash) =>
  async (dispatch, getState) => {
    try {
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        let data = await fetchApiDataAndUpdateLocalStorage(state, 'read')
        if(data !== null){
          return data
        }
      }
      const module = path ? path : "PurchaseOrders";
      console.log(noLoader,"vsnoLoader")
      if(noLoader){}else{
      dispatch(fetchingRequest(state ? state : module));
      }
      console.log(nextApi,"nextApi")
      const options = {
        method: method,
        url:nextApi?nextApi: noForwordSlash
        ? noFmoduleIdURL(module) : id ? moduleIdURL(module, id) : moduleURL(module),
        params:nextApi|| noeditparam ? {} : param ? param : { id: id },
        data:nextApi?"": addData ? addData : "",
      };
      const data = await requests(options).then((response) => {
        const resData=response.data
        console.log(resData,response,"resData")
        if(oldData&&oldData.length>0){
          if (resData.results.length > 0) {
            resData.results = [...oldData, ...resData.results];
          } else {
            resData.results = oldData;
          }
        
        }
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: resData,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(state, 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const SalesOrdersFunction =
(method, path, id, state, addData, param, noeditparam,noLoader,oldData,nextApi) =>
// (method, path, id, state, addData, param, noeditparam) =>
  async (dispatch, getState) => {
    try {
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        let data = await fetchApiDataAndUpdateLocalStorage(state, 'read')
        if(data !== null){
          return data
        }
      }
      const module = path ? path : "SalesOrders";
      if(noLoader){}else{
      dispatch(fetchingRequest(state ? state : module));
      }
      // const options = {
      //   method: method,
      //   // url: id ? moduleIdURL(module, id) : moduleURL(module),
      //   url:nextApi?nextApi: id ? moduleIdURL(module, id) : moduleURL(module),
      //   params: noeditparam ? {} : param ? param : { id: id },
      //   data: addData ? addData : "",
      // };
      const options = {
        method: method,
        url:nextApi?nextApi: id ? moduleIdURL(module, id) : moduleURL(module),
        params:nextApi|| noeditparam ? {} : param ? param : { id: id },
        data:nextApi?"": addData ? addData : "",
      };
      // const data = await requests(options).then((response) => {
      //   dispatch({
      //     type: `FETCH_${state ? state : module}_SUCCESS`,
      //     payload: state ? state : module,
      //     data: response.data,
      //   });

      //   return response.error ? response : response.data;
      // });

      // return data;




      const data = await requests(options).then((response) => {
        const resData=response.data
        console.log(resData,response,"resData")
        if(oldData&&oldData.length>0){
          if (resData.results.length > 0) {
            resData.results = [...oldData, ...resData.results];
          } else {
            resData.results = oldData;
          }
        
        }
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: resData,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(state, 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ReportsFunction =
(method, path, id, state, addData, param, noeditparam,noLoader,oldData,nextApi) =>
// (method, path, id, state, addData, param, noeditparam) =>
  async (dispatch, getState) => {
    try {
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        let data = await fetchApiDataAndUpdateLocalStorage(state, 'read')
        if(data !== null){
          return data
        }
      }
      const module = path ? path : "Reports";
      if(noLoader){}else{
      dispatch(fetchingRequest(state ? state : module));
      }
     const options = {
        method: method,
        url:nextApi?nextApi: id ? moduleIdURL(module, id) : moduleURL(module),
        params:nextApi|| noeditparam ? {} : param ? param : { id: id },
        data:nextApi?"": addData ? addData : "",
      };
    
      const data = await requests(options).then((response) => {
        const resData=response.data
        console.log(resData,response,"resData")
        if(oldData&&oldData.length>0){
          if (resData.results.length > 0) {
            resData.results = [...oldData, ...resData.results];
          } else {
            resData.results = oldData;
          }
        
        }
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: resData,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && !path.includes('port','sales','contract-type', 'unloading') && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(state, 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PackagingsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Packaging";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ContractTypesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ContractTypes";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const PaymentTermsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "PaymentTerms";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const ItemTypesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ItemTypes";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

class ItemCategory {
  constructor(id, categoryName, status) {
    this.id = id;
    this.categoryName = categoryName;
    this.status = status;
  }
}

const itemCategories = [
  new ItemCategory("cat1", "White Rice", true),
  new ItemCategory("cat2", "Parboiled Rice", true),
];

export const ItemCategoriesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "ItemCategories";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
          //data: itemCategories,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };
export const SurveyCompanysFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      console.log("HERESURVEY")
      const module = path ? path : "SurveyCompanys";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

class Client {
  constructor(id, name, address, city, state, country, phone) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.city = city;
    this.state = state;
    this.country = country;
    this.phone = phone;
  }
}

export const ClientsFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Clients";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
          //data: clients,
        });

        return response.error ? response : response.data;
      });
      if(method === 'get' && path.includes('masters')){
        fetchApiDataAndUpdateLocalStorage(extractKey(path), 'write', data)
      }

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

// export const PortsFunction =
//   (method, path, id, state, data) => async (dispatch, getState) => {
//     try {
//       const module = path ? path : "Ports";
//       dispatch(fetchingRequest(state ? state : module));
//       const options = {
//         method: method,
//         url: id ? moduleIdURL(module, id) : moduleURL(module),
//         params: { id: id },
//       };
//       const data = await requests(options).then((response) => {
//         dispatch({
//           type: `FETCH_${state ? state : module}_SUCCESS`,
//           payload: state ? state : module,
//           data: response.data,
//         });
//
//return response.error ? response : response.data;
//       });

//       return data;
//     } catch (error) {
//       return {
//         error: true,
//         data: error.response
//           ? error.response.data
//           : "Some error occured, please try again!",
//       };
//     }
//   };

export const DispatchesFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Dispatches";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

export const UsersFunction =
  (method, path, id, state, data) => async (dispatch, getState) => {
    try {
      const module = path ? path : "Users";
      dispatch(fetchingRequest(state ? state : module));
      const options = {
        method: method,
        url: id ? moduleIdURL(module, id) : moduleURL(module),
        params: { id: id },
      };
      const data = await requests(options).then((response) => {
        dispatch({
          type: `FETCH_${state ? state : module}_SUCCESS`,
          payload: state ? state : module,
          data: response.data,
        });

        return response.error ? response : response.data;
      });

      return data;
    } catch (error) {
      return {
        error: true,
        data: error.response
          ? error.response.data
          : "Some error occured, please try again!",
      };
    }
  };

const fetchingRequest = (data) => {
  return {
    type: "FETCH_REQUEST",
    payload: data,
    data: [],
  };
};
