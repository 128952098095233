import { Snackbar, Alert } from "@mui/material";
import React, { createContext, useState, useContext } from "react";
// import Alert from '@mui/material/Alert';
// import Snackbar from '@mui/material/Snackbar';

export const SnackbarContext = createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

const MuiAlert = React.forwardRef(function MuiAlert(props, ref) {

  const { severity, style, ...otherProps } = props;
  const customStyles = {
    success: { backgroundColor: "#f19a30", color: "#fff" }, // Orange
    warning: { backgroundColor: "#f19a30", color: "#fff" }, // Orange
    info: { backgroundColor: "#f19a30", color: "#fff" },   // Orange
    error: { backgroundColor: "#D32F2F", color: "#fff" },   // Red
  };

  // Merge custom styles for non-error severities
  const alertStyle =
    severity  ? { ...customStyles[severity], ...style } : style;


  return <Alert elevation={6} variant="filled" {...otherProps} ref={ref} style={{ fontFamily: "Poppins", ...alertStyle }} />;
});

const styles = {
  root: {
    "& > * + *": {
      marginTop: 2,
    },
    "& .MuiAlert-icon": {
      padding: "7px 0px"
    }
  },
};

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "success",
    duration: 4000
  });

  const handleSnackbarMessageBox = (data) => {
    console.log(data)
    return (
      data.map((item, index) => {
        return (
          <p key={index} style={{ margin: 0, fontFamily: 'Poppins' }}>
            {item}
          </p>
        )
      })
    )
  }


  const showSnackbar = (message, type = "success", duration = 4000) => {
    console.log("CALEDD SNACKBAR", message, type, duration)
    let messageText = message
    if (type === "error") {
      messageText = typeof message === 'string' 
      ? message 
      : message?.length > 0
      ? message?.[0]?.message && message?.[0]?.message?.length > 0
      : message?.[0]?.message[0]
      ? message?.[0]?.message
      : message
      messageText = message
    }
    setSnackbar({ 
      open: true, 
      message: messageText, 
      type, 
      duration: duration ? duration : type === 'error' ? 5000 : 4000
    });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <div style={styles.root}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert onClose={handleClose} severity={snackbar.type}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    </SnackbarContext.Provider>
  );
}
