import { Paper, Typography } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useState } from "react";
import TabComponent from "../../layoutComponents/TabComponent";
import ReportPage from "./ReportPage";
import {
  BAG_REPORT_HEADER,
  BROKER_REPORT_HEADER,
  DISPATCH_REPORT_HEADER,
  PO_ORDER_DETAIL_HEADER,
  STOCK_REPORT_HEADER,
} from "./headers";

const ReportsComponent = (props) => {
  const tabValue = [
    { label: "Purchase Order", value: 0, header: PO_ORDER_DETAIL_HEADER },
    { label: "Dispatch", value: 1, header: DISPATCH_REPORT_HEADER },
    { label: "Broker", value: 2, header: BROKER_REPORT_HEADER },
    { label: "Stock", value: 3, header: STOCK_REPORT_HEADER },
    { label: "Bag", value: 4, header: BAG_REPORT_HEADER },
  ];

  const [tabNumber, setTabNumber] = useState(0);

  return (
    <Layout customWidth={true} openWidth="235px" closeWidth="57px">
      <Paper className="width-100 height-100 padding15 backgroundWhite">
        <div className="d-flex">
          <Typography
            variant="h6"
            className="text-center fontWeightBold textColorGrey"
          >
            Reports
          </Typography>
        </div>
        <div style={{ margin: "15px 0 0 0" }}>
          <TabComponent
            selectedValue={tabNumber}
            onChange={(newVal) => {
              setTabNumber(newVal);
            }}
            list={tabValue.map((item) => {
              return {
                name: item.label,
                component: (
                  <ReportPage
                    tabModule={item.label}
                    header={item.header}
                    ReportsFunction={props.ReportsFunction}
                  />
                ),
              };
            })}
          />
        </div>
      </Paper>
    </Layout>
  );
};

export default ReportsComponent;
