import { MenuItem, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
import AlwaysShowSidebar from "../../layoutComponents/PDF/AlwaysShowSidebar";
import WithoutToolbarExample from "../../layoutComponents/PDF/WithoutToolbarExample";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { filterData } from "../../Regex";
import {
  color1,
  color2,
  color3,
  color4,
  color5,
  primary,
} from "../../styles/colors";
import { DeleteIcon, UploadCloud } from "../../styles/Icons";
import {
  CardDetail,
  CssDialog,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import ShowPdf from "./ShowPdf";
function DocumentUploads(props) {
  const { selectedCustomInvoice, postData, updateValues, 
    noAddButton,brcRoadTap, advance, advice, 
  } = props;
  console.log(props.brcRoadTap,"brcRoadTapbrcRoadTapbrcRoadTap")
  const [notGiven, setNotGiven] = useState(false);
  const [selectedCustomIn, setSelectedCustomIn] = useState(
    selectedCustomInvoice ? selectedCustomInvoice : {}
  );

  const [fileArray, setFileArray] = useState([]);
  // const [singleFileName, setSingleFileName] = useState(
  //   selectedCustomInvoice && selectedCustomInvoice.bl_number ? selectedCustomInvoice.bl_number : ""
  // );
  const [success, setSuccess] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileInput_ref, setFileInputRef] = useState({});
  const [loader, setLoad] = useState(false);
  const ss = async () => {
    setLoad(true);
    return "";
  };
  const handleUpdate = (e) => {
    console.log(fileArray,e.target.files,"dddddddddddddd")
    e.preventDefault();
    e.stopPropagation();
    ss().then(() => {
      let fileObj = [];
      const fileA = fileArray&& fileArray.length > 0 ? fileArray : [];
      fileObj.push(e.target.files);
      for (let i = 0; i < fileObj[0].length; i++) {
        fileA.push(fileObj[0][i]);
      }
      console.log(brcRoadTap,'checkingbrc from')
      // if(brcRoadTap&&fileA.length>2){

      //   setNotGiven("Maximum 2 files only")
      //   setLoad(false);
      // }
      // else{
      // if(!brcRoadTap||(brcRoadTap&&(fileA.length===2||fileA.length===1))){
        console.log(fileA, brcRoadTap,"brcRoadTapfileA wefujken");
      setFileArray(fileA);
    
      setLoad(false);
    });
  };
  const uploadFiles = () => {
    const formData = new FormData();
    formData.append("is_active", true);
    formData.append(
      brcRoadTap?"commercial_invoice": "commercial_invoice",
      selectedCustomIn ? selectedCustomIn.id : ""
    );
    fileArray.map((s, i) => {
      if (!s.id) {
        formData.append(`file_${i}`, s);
      }
      return s;
    });
    if (fileArray.length) {
      if (postData) {
        setLoad(true);
        postData(
          "post",
          brcRoadTap
          ? "arrival/BRC&RoDTEP-upload/list"
          : advance
          ? "arrival/advance-upload/list"
          : advice
          ? "arrival/advice-upload/list"
          : "arrival/documents-upload/list",
          null,
          "post",
          formData
        ).then((res) => {
          if (res.error) {
            setLoad(false);
            setNotGiven(
              typeof res.data === "string"
                ? res.data
                : res.data.length > 0
                ? res.data
                    .map((s) => {
                      return `${s.field ? `${s.field} :` : ""} ${
                        typeof s.message === "string"
                          ? s.message
                          : s.message.length > 0 && s.message[0] && s.message[0]
                      }`;
                    })
                    .join(" ,")
                : res.data
            );
          } else {
            // if (
            //   selectedCustomIn &&
            //   selectedCustomIn.shipment_process_status ===
            //     "CUSTOM INVOICE GENERATED"
            // ) {
              // postData(
              //   "patch",
              //   "arrival/custom-invoice",
              //   selectedCustomIn.id,
              //   "patch",
              //   {
              //     shipment_process_status: "DOCUMENTS UPLOADED",
              //     id: selectedCustomIn.id,
              //     invoice_number: selectedCustomIn.invoice_number,
              //   }
              // ).then((res2) => {
                // if (res2.error) {
                //   setLoad(false);
                //   setNotGiven(
                //     typeof res2.data === "string"
                //       ? res2.data
                //       : res2.data.length > 0
                //       ? res2.data
                //           .map((s) => {
                //             return `${s.field ? `${s.field} :` : ""} ${
                //               typeof s.message === "string"
                //                 ? s.message
                //                 : s.message.length > 0 &&
                //                   s.message[0] &&
                //                   s.message[0]
                //             }`;
                //           })
                //           .join(" ,")
                //       : res2.data
                //   );
                // } else {
                  // if (updateValues) {
                  //   updateValues(res2);
                  // }
                  setSuccess("File Uploaded !");
              //   }
              // });
            // } else {
            //   setSuccess("File Uploaded !");
            // }
          }
        });
      }
    }
  };
  const fetchDocuments = (prevFiles) => {
    if (selectedCustomIn) {
      setLoad(true);
      postData("get", 
        brcRoadTap 
        ?"arrival/BRC&RoDTEP-upload/list"
        : advance
          ? "arrival/advance-upload/list"
          : advice
            ? "arrival/advice-upload/list"
            :"arrival/documents-upload/list", 
        null, "docs", null, 
        {
        [brcRoadTap?"commercial_invoice_id":"commercial_invoice_id"]:
          selectedCustomIn && selectedCustomIn.id && selectedCustomIn.id,
      }).then((docs) => {
        if (docs) {
          if (prevFiles && prevFiles.length > 0) {
            const pData = prevFiles.filter((s) => !s.id);
            setFileArray(docs.length > 0 ? docs.concat(pData) : pData);
          } else {
            setFileArray(docs.length > 0 ? docs : []);
          }
        } else {
          if (prevFiles && prevFiles.length > 0) {
            const pData = prevFiles.filter((s) => !s.id);
            setFileArray(pData);
          } else {
            setFileArray([]);
          }
        }
        // if (singleFileName && selectedCustomIn && selectedCustomIn.bl_number !== singleFileName) {
        //   setSelectedCustomIn({ ...singleFileName, bl_number: singleFileName });
        // }
        setLoad(false);
      });
    }
  };
  const [confirmDialog, setConfirmDialog] = useState(false);
  useEffect(() => {
    if (selectedCustomIn) {
      fetchDocuments();
    }
  }, [selectedCustomIn]);
  useEffect(() => {
    if (selectedCustomInvoice) {
      setSelectedCustomIn(selectedCustomInvoice);
      // setSingleFileName(selectedCustomInvoice.bl_number ? selectedCustomInvoice.bl_number : "");
    }
  }, [selectedCustomInvoice]);
  const pdfUi = (uploaded, i) => {
    return (
      <CardDetail
        keyId={"-1"}
        height="150px"
        width="150px"
        margin="10px 5px"
        children={() => {
          return (
            <div
              className="width-100 height-100 d-flex d-flex-column"
              onClick={(e) => {
                e.stopPropagation();
                setShowPdf(uploaded);
              }}
            >
              <div className="width-100 d-flex marginB10">
                <div className="leftText textOEllipse d-flex alignC">
                  {uploaded.file_url
                    ? uploaded.file_url.split("https://storage.googleapis.com/ssipl-dev.appspot.com/")
                    : uploaded.name}
                </div>
                {noAddButton ? (
                  ""
                ) : (
                  <div
                    className="d-flex alignC "
                    style={{ margin: "0 0 0 5px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setConfirmDialog(uploaded);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
              <div
                className="width-100  d-flex alignC justifyC postionR disabled"
                style={{ height: "calc(100% - 30.57px)" }}
              >
                {uploaded.pdf_file ? (
                  <iframe
                    title="file"
                    scrolling="no"
                    seamless="seamless"
                    className="width-100 height-100 objFC example overflowH"
                    src={uploaded.pdf_file}
                  ></iframe>
                ) : (
                  <WithoutToolbarExample
                    fileUrl={URL.createObjectURL(uploaded)}
                  />
                )}
              </div>
            </div>
          );
        }}
      />
    );
  };

  const createForm = () => {
    return (
      <>
        <input
          accept="application/pdf"
          ref={(ref) => {
            setFileInputRef(ref);
          }}
          multiple
          id={brcRoadTap ?"brcRoadTap" : advance ? "advance" : advice ? "advice":"files"}
          style={{
            visibility: "hidden",
            width: "0",
            maxHeight: "20px",
          }}
          type="file"
          disabled={
            brcRoadTap&&fileArray.length=== 6
          ? true
          : advance && fileArray.length >= 6
          ? true
          : advice && fileArray.length >= 6
          ? true
          : fileArray.length >=14
          ? true
          :false}
          onChange={handleUpdate}
        />
        <label htmlFor={brcRoadTap?"brcRoadTap" : advance ? "advance" : advice ? "advice" :"files"} className="btn">
          <div
            className={
              notGiven === "Please upload a file !"
                ? "upload-a-file-container-assignment-error"
                : "upload-a-file-container-assignment"
            }
          >
            <div
              className="upload-a-file-content-container"
              style={{
                color:
                  notGiven === "Please upload a file !" ? "#db0011" : primary,
              }}
            >
              <div className="upload-a-file-icon-container">
                <UploadCloud
                  color={
                    notGiven === "Please upload a file !" ? "#db0011" : primary
                  }
                />
              </div>
              <p
                style={{
                  margin: "0 3% 0 0",
                  fontSize: "0.8rem",
                  color:
                    notGiven === "Please upload a file !" ? "#db0011" : primary,
                }}
              >
                {fileArray.length > 0 ? "Upload more Files." : "Upload files."}
              </p>
            </div>
          </div>
        </label>
        { advance && fileArray.length >= 6  
        ? <span style={{ marginTop: 10, }}> Maximum Upload Limit : 6 </span>
        : advice && fileArray.length >= 6  
        ? <span style={{ marginTop: 10, }}> Maximum Upload Limit : 6 </span>
        : brcRoadTap && fileArray.length >= 6
        ? <span style={{ marginTop: 10, }}> Maximum Upload Limit : 6 </span>
        : fileArray.length >= 14
        ? <span style={{ marginTop: 10, }}> Maximum Upload Limit : 14 </span>
        : null
        }
        <div
          className="d-flex width-100 marginTB20"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* <CssTextField95
            autoComplete="off"
            style={{ width: "200px", margin: "20px 0 0" }}
            id="outlined-basic"
            error={notGiven === "Add Bl original number !" ? true : false}
            helperText={notGiven === "Add Bl original number !" ? notGiven : ""}
            label={`BL Number`}
            variant="outlined"
            required={true}
            name="singleFileName"
            value={singleFileName ? singleFileName : ""}
            onChange={(e) => {
              e.stopPropagation();
              setSingleFileName(e.target.value);
              setNotGiven(false);
            }}
          /> */}
          <StyledButton
            name={`Clear`}
            margin="20px 0 0 20px"
            height="37.97px"
            onClick={() => {
              // setSingleFileName(selectedCustomIn.bl_number ? selectedCustomIn.bl_number : "");
              if (
                fileArray.length > 0 &&
                fileArray.filter((s) => !s.id) &&
                fileArray.filter((s) => !s.id).length > 0
              ) {
                console.log(
                  fileArray,
                  fileArray.filter((s) => s.id),
                  "fileArray.filter((s) => !s.id)"
                );
                setFileArray(fileArray.filter((s) => s.id));
              } else if (fileArray.length > 0) {
                console.log(fileArray, "fileArray")
                setFileArray(fileArray);
              }
              setNotGiven(false);
            }}
          />
          <StyledButton
            withBg={true}
            name="Upload"
            height="37.97px"
            margin="20px 0px 0 20px"
            onClick={(e) => {
              e.stopPropagation();
              if (
                fileArray.length > 0 &&
                fileArray.filter((s) => !s.id) &&
                fileArray.filter((s) => !s.id).length > 0
              ) {
                // if (singleFileName) {
                setOpenDialog(true);
                // } else {
                //   setNotGiven("Add Bl original number !");
                // }
              } else {
                // if (fileArray.length > 0) {
                //   if (
                //     singleFileName &&
                //     singleFileName !== (selectedCustomIn.bl_number ? selectedCustomIn.bl_number : "")
                //   ) {
                //     if (selectedCustomIn) {
                //       setLoad(true);
                //       postData("patch", "arrival/custom-invoice", selectedCustomIn.id, "patch", {
                //         bl_number: singleFileName,
                //       }).then((res2) => {
                //         if (res2.error) {
                //           setLoad(false);
                //           setNotGiven(
                //             typeof res2.data === "string"
                //               ? res2.data
                //               : res2.data.length > 0
                //               ? res2.data
                //                   .map((s) => {
                //                     return `${s.field ? `${s.field} :` : ""} ${
                //                       typeof s.message === "string"
                //                         ? s.message
                //                         : s.message.length > 0 && s.message[0] && s.message[0]
                //                     }`;
                //                   })
                //                   .join(" ,")
                //               : res2.data
                //           );
                //         } else {
                //           if (updateValues) {
                //             updateValues(res2);
                //           }
                //           setSuccess("Bl Number updated !");
                //         }
                //       });
                //     }
                //   } else {
                //     setNotGiven("No new file to upload !");
                //   }
                // } else {
                setNotGiven("Please upload a file !");
                // }
              }
            }}
          />
        </div>
      </>
    );
  };
  const showUploadedPdf = () => {
    return loader ? (
      <StyledCircularProgress />
    ) : (
      <div className="width-100 height-100 d-flex flexWrap">
        <HeadingLine
          maxWidth="100%"
          width="100%"
          children={`Uploaded Files:`}
          margin="0 0 10px"
          // withBg={true}
        />
        {fileArray && fileArray.map((uploaded, i) => pdfUi(uploaded, i))}
      </div>
    );
  };
  console.log(loader, fileArray, "wefujken");
  return (
    <Paper className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary d-flex d-flex-column height-100 width-100 textC paddingTB20">
      <ConfirmDialog
        loader={loader}
        onSubmit={(e) => {
          e.stopPropagation();
          console.log(confirmDialog, "confirmDialog");
          if (confirmDialog.id) {
            setLoad(true);
            postData(
              "delete",
               advance
              ? `arrival/advance-upload/${confirmDialog.id}`
              : advice
              ? `arrival/advice-upload/${confirmDialog.id}`
              : "arrival/documents-upload",
              advance || advice ? null :confirmDialog.id,
              "delete"
            ).then((res) => {
              if (res.error) {
                setLoad(false);
                setNotGiven(
                  typeof res.data === "string"
                    ? res.data
                    : res.data.length > 0
                    ? res.data
                        .map((s) => {
                          return `${s.field ? `${s.field} :` : ""} ${
                            typeof s.message === "string"
                              ? s.message
                              : s.message.length > 0 &&
                                s.message[0] &&
                                s.message[0]
                          }`;
                        })
                        .join(" ,")
                    : res.data
                );
              } else {
                setSuccess("File Deleted !");
              }
            });
          } else {
            const files = [];
            fileArray.map((s) => {
              if (s.name === confirmDialog.name) {
              } else {
                files.push(s);
              }
              return s;
            });
            setFileArray(files);
            setConfirmDialog(false);
          }
        }}
        height="calc(100% - 60px)"
        width="600px"
        action={"Delete"}
        setOpenDialog={setConfirmDialog}
        openDialog={confirmDialog !== false ? true : false}
        content={() => {
          return loader ? (
            <StyledCircularProgress />
          ) : (
            <div className="width-100 height-100">
              <HeadingLine
                maxWidth="100%"
                width="100%"
                subHeading={true}
                children={`Delete this file ?`}
                margin="0 0 10px"
                // withBg={true}
              />
              <div
                className="width-100  d-flex alignC justifyC postionR"
                style={{ height: "calc(100% - 33.57px)" }}
              >
                {confirmDialog.pdf_file ? (
                  <iframe
                    scrolling="no"
                    seamless="seamless"
                    className="width-100 height-100 objFC example overflowH"
                    src={confirmDialog.pdf_file}
                  ></iframe>
                ) : (
                  <WithoutToolbarExample
                    fileUrl={URL.createObjectURL(confirmDialog)}
                  />
                )}
              </div>
            </div>
          );
        }}
      />
      {showPdf !== false ? (
        <ShowPdf
          close={() => {
            setShowPdf(false);
          }}
          open={true}
          loader={loader}
          details={showPdf}
          selectedCustomInvoice={selectedCustomIn}
        />
      ) : (
        ""
      )}

      {openDialog === true ? (
        <CssDialog
          headerPrimary={true}
          resetText={"Cancel"}
          height={fileArray.length.length > 3 ? "600px" : "342px"}
          width="600px"
          header={`Are you sure you want to upload only  ${
            fileArray
              ? fileArray.length && fileArray.length > 1
                ? `${fileArray.length} files`
                : `${fileArray.length} file`
              : ""
          }  ?`}
          addButtonWidth="60px"
          addButton={"Save"}
          reset={() => {
            setOpenDialog(false);
          }}
          onClose={() => {
            setOpenDialog(false);
          }}
          snackbarClose={() => {
            setOpenDialog(false);
          }}
          onSubmit={(e) => {
            e.stopPropagation();
            uploadFiles();
          }}
          children={() => {
            return showUploadedPdf();
          }}
        />
      ) : (
        ""
      )}
      {(notGiven && notGiven !== "Add Bl original number !") || success ? (
        <SnackbarComponent
          error={notGiven}
          autoHideDuration={4000}
          success={success}
          snackbarClose={() => {
            if (success === "File Deleted !") {
              fetchDocuments(fileArray);
              setLoad(false);
              setConfirmDialog(false);
            }
            if (success === "File Uploaded !") {
              fetchDocuments();
              setOpenDialog(false);
              setLoad(false);
            }
            // if (success === "Bl Number updated !") {
            //   fetchDocuments();
            //   setLoad(false);
            // }
            setSuccess(false);
            setNotGiven(false);
          }}
        />
      ) : (
        ""
      )}
      {console.log(fileArray, "fileArray")}
      {showUploadedPdf()}
      {noAddButton 
        ? ""
        : // selectedCustomIn &&
          //   selectedCustomIn.shipment_process_status &&
          //   selectedCustomIn.shipment_process_status === "CUSTOM INVOICE GENERATED" &&

          createForm()}
    </Paper>
  );
}

export default DocumentUploads;
