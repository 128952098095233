import React, { useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";
import moment from "moment";
import { itemHeader } from "./InlandGodownPrePostDialog";

const InlandGodownClosedDispatch = (props) => {
  const [closedDispatchList, setClosedDispatchList] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  console.log(props);

  const fetchClosedDispatchList = (urlText = false, type = false) => {
    if (props?.singleInlandGodown?.id) {
      props
        .InlandGodownFunction(
          "get",
          type === "pagination" ? urlText : "dispatch/list",
          null,
          "PODispatchedList",
          null,
          type === "pagination"
            ? null
            : {
                post_dispatch: "False",
                inland_godown_id: props?.singleInlandGodown?.id,
                dispatch_status: "dispatched",
                is_closed: "True",
              },
          null,
          null,
          null,
          null,
          type === "pagination" ? true : false
        )
        .then((ClosedDispatchListData) => {
          setClosedDispatchList(ClosedDispatchListData?.results);
          if (ClosedDispatchListData && ClosedDispatchListData.results.length > 0) {
            if (ClosedDispatchListData?.results) {
              setPaginationData({
                count: ClosedDispatchListData?.count_on_page || 0,
                total_count: ClosedDispatchListData?.total_count || 0,
                next: ClosedDispatchListData?.next || null,
                previous: ClosedDispatchListData?.previous || null,
              });
            }

            const blankarr = [];
            ClosedDispatchListData.results.map((sData) => {
              props
                .InlandGodownFunction(
                  "get",
                  "dispatch/dispatch-items/list",
                  null,
                  "dispatch-item",
                  null,
                  {
                    dispatch_id: sData.id,
                  }
                )
                .then((res2) => {
                  sData.subList = res2 ? res2 : [];
                  blankarr.push(sData);
                  if (ClosedDispatchListData.results.length === blankarr.length) {
                    setClosedDispatchList(blankarr);
                  }
                  return sData;
                });
              return sData;
            });
          } else {
            setClosedDispatchList([]);
          }
        });
    }
  };

  const pageChangeAPI = (url) => {
    fetchClosedDispatchList(url, "pagination");
  };

  useEffect(() => {
    fetchClosedDispatchList();
  }, [window.location.pathname]);

  const { ItemCateoryList, ItemList, HSNlist, SurveyCompanyList } = props;

  return (
    <TableComponent
      {...props}
      module={"Closed Dispatch"}
      noAddButton={true}
      loader={false}
      actions={[]}
      header={[
        "Sr.No.",
        "Dispatch Date",
        "Dispatch Status",
        "Contract Type",
        "Miller Bill Number",
        "Purchase Order",
        "Truck Number",
        "Bilty Number",
        "Net Weight",
        "Freight Rate",
        "Survey Company",
        "Broker Payment",
        "Close",
      ]}
      subMenu={true}
      subMenuheader={[
        "Sr.No.",
        "Item Category",
        "Item Type",
        "Hsn Code",
        "Contract Rate",
      ]}
      tableHeight="calc(100% - 95px)"
      tablePagination={true}
      paginationData={paginationData}
      pageChangeAPI={pageChangeAPI}
      // resetPage={resetPage}
      tableList={
        closedDispatchList && closedDispatchList.length
          ? closedDispatchList
              .map((singleDispatch) => {
                singleDispatch["Dispatch Date"] = moment(
                  singleDispatch.dispatch_date
                ).format("DD MMM YYYY");
                singleDispatch["Dispatch Status"] =
                  singleDispatch.dispatch_status;
                singleDispatch["Contract Type"] = singleDispatch.contract_type;
                singleDispatch["Miller Bill Number"] =
                  singleDispatch.miller_bill_number;
                singleDispatch["Purchase Order"] =
                  singleDispatch.purchase_order_details?.po_number;
                singleDispatch["Truck Number"] = singleDispatch.truck_number;
                singleDispatch["Bilty Number"] = singleDispatch.bilty_number;
                singleDispatch["Net Weight"] = Number(
                  singleDispatch.net_weight
                ).toFixed(2);
                singleDispatch["Net WeightUnit"] =
                  Number(singleDispatch.net_weight).toFixed(2) +
                  (singleDispatch?.unit || "");
                singleDispatch["Freight Rate"] = singleDispatch.freight_rate;
                singleDispatch["Freight RateUnit"] = singleDispatch.freight_rate
                  ? "₹ " +
                    Number(singleDispatch.freight_rate).toFixed(2) +
                    (singleDispatch?.unit || "")
                  : "";
                singleDispatch["Survey Company"] = SurveyCompanyList?.find(
                  (d) => d.id === Number(singleDispatch.survey_company)
                )?.name;
                singleDispatch["Broker Payment"] =
                  singleDispatch.broker_payment;

                singleDispatch["subList"] = singleDispatch?.subList?.map(
                  (item) => {
                    itemHeader.map((header) => {
                      item[header.label] =
                        header.key === "item_category"
                          ? ItemCateoryList?.find(
                              (d) => d.id === Number(item[header.key])
                            )?.name || ""
                          : header.key === "item_type"
                          ? ItemList?.find(
                              (d) => d.id === Number(item[header.key])
                            )?.broken_percentage
                          : header.key === "hsn_code"
                          ? HSNlist?.find(
                              (d) => d.id === Number(item[header.key])
                            )?.hsn_code
                          : item[header.key] || "";
                    });
                    item["PackagingArr"] = item.dispatch_packagings || [];
                    return item;
                  }
                );

                return singleDispatch;
              })
              .sort((a, b) => {
                var dateB = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateA = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateB) - new Date(dateA);
              })
          : []
      }
    />
  );
};

export default InlandGodownClosedDispatch;
