import React, { useCallback, useState } from "react";
import { CssTextField95 } from "../styles/StyledComponents";
import { InputAdornment } from "@mui/material";
import { SearchIcon } from "../styles/Icons";
import { primary } from "../styles/colors";
import debounce from "lodash/debounce";
import { Close } from "@mui/icons-material";

const TableSearch = ({ tableSearchField, ...props }) => {
  const [searchText, setSearchText] = useState("");

  const handleDebouncedChange = useCallback(
    debounce((value) => {
      if (tableSearchField?.onChange) {
        tableSearchField?.onChange(value);
      }
    }, 500),
    [tableSearchField]
  );

  const handleChange = (value) => {
    setSearchText(value);
    handleDebouncedChange(value);
  };

  return (
    <CssTextField95
      id="outlined-basic"
      style={props.style || {
        backgroundColor: "#F2F2F2",
        borderRadius: "4px",
        width: "100%",
        maxWidth: "380px",
      }}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ margin: 0 }}>
            <SearchIcon color={primary} />
          </InputAdornment>
        ),
        endAdornment: Boolean(searchText) ? (
          <InputAdornment position="end" style={{ cursor: 'pointer' }}>
            <Close color={primary} onClick={() => handleChange("")} />
          </InputAdornment>
        ) : null,
      }}
      placeholder={tableSearchField?.placeholder || "Search"}
      value={searchText}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default TableSearch;
