import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

const styles = {
  select: {
    backgroundColor: "#FFF",
    borderRadius: "4px",
    width: "96%",
    margin: "4px",
    maxWidth: "380px",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
};

const TableSelect = (props) => {
  const [selectValue, setSelectValue] = useState("");

  const handleChange = (value) => {
    setSelectValue(value);
    props.onChange(value);
  };

  useEffect(() => {
    if (props.selectedData) {
      handleChange(props.selectedData);
    }
  }, [props.selectedData]);

  const { data } = props;

  return (
    <FormControl style={props.style || styles.select}>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectValue}
        onChange={(e) => handleChange(e.target.value)}
      >
        {data &&
          data?.length > 0 &&
          data.map((d) => {
            return <MenuItem value={d}>{d}</MenuItem>;
          })}
      </Select>
    </FormControl>
  );
};

export default TableSelect;
