import { useEffect, useState } from "react";
import TablePagination from '@mui/material/TablePagination';
import { Divider } from "@material-ui/core";
import { baseURL } from "../http/config";
import { mediumScreen } from "../Regex";


const TableComponentPagination = (props) => {
  const {
    count,
    totalCount,
    next,
    previous,
    pageChangeAPI,
    resetPage,
  } = props;

  console.log("TABLEPAGINATION", props)

  const [page, setPage] = useState(JSON.parse(localStorage.getItem('paginationPage')) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(count);

  useEffect(() => {
    let moduleData = JSON.parse(localStorage.getItem('moduleData'));
    console.log("PAG MODULE",props.module, moduleData)
    if(props.module){
      if (moduleData && props.module !== moduleData) {
        moduleData = props.module;
        localStorage.setItem('moduleData', JSON.stringify(moduleData));
        setPage(0)
      }
      else{
        moduleData = props.module;
        localStorage.setItem('moduleData', JSON.stringify(moduleData));
      }
    }
  }, [props.module])

  useEffect(() => {
    setRowsPerPage(count); 
  }, [count]);

  useEffect(() => {
    console.log("RESET", resetPage)
    if (resetPage === 'resetPage'|| localStorage.getItem('pageReset')) {
      setPage(0);
      localStorage.removeItem('pageReset')
    }
  }, [resetPage, localStorage.getItem('pageReset')]);

  useEffect(() => {
    console.log("PAGE", page)
    localStorage.setItem("paginationPage", page);
  }, [page])

  const removeBaseUrl = (text) => {
    const baseUrl = baseURL();
    const updatedText = text.includes(baseUrl) ? text.replace(baseUrl, "") : text;
    return updatedText;
  }

  const handleChangePage = (event, newPage) => {
    console.log('newPage', newPage)
    if(page > newPage){
      pageChangeAPI(removeBaseUrl(previous))
    }
    else if(page < newPage){
      pageChangeAPI(removeBaseUrl(next))
    }
    setPage(newPage);
  };

  return (
    <>
    {console.log(totalCount, rowsPerPage, page)}
    <Divider />
    <TablePagination
      component="div"
      colSpan={3}
      count={totalCount}
      page={page}
      rowsPerPageOptions={[rowsPerPage]}
      rowsPerPage={rowsPerPage}
      labelDisplayedRows={() => {
        // If page is 0 (first page), show rowsPerPage = 10
        if (page === 0) {
          const from = totalCount > 0 ? 1 : 0;
          const to = totalCount > 0 ? Math.min(rowsPerPage, totalCount) : 0   ; // up to 10 rows on first page
          return `${from}-${to} of ${totalCount > 0 ? totalCount : 0}`;
        } else {
          // For subsequent pages, start from row 11 onward
          const from = Boolean(next)
          ? ((10 + ((page - 1) * rowsPerPage)) + 1) // For page 1, start from row 11
          : Number(totalCount - rowsPerPage) + 1
          const to = Boolean(next) 
          ? Math.min(from + rowsPerPage - 1, totalCount) // Ensure not exceeding totalCount
          : Number(totalCount)
          return `${from}-${to} of ${totalCount}`;
        }
      }}
      nextIconButtonProps={{
        disabled: !Boolean(next),  // Disable when reaching last page
        style: {
          margin: '0 15px 0 10px',
          padding: 0
        }
      }}
      backIconButtonProps={{
        disabled: page === 0,  // Disable when on the first page
        style: {
          padding: 0
        }
      }}
      onPageChange={handleChangePage}
      sx={{
        overflow: 'hidden',

        '& .MuiTablePagination-displayedRows': {
          fontFamily: 'Poppins',
          fontSize: mediumScreen() ? "0.75rem" : "0.8rem",
          margin: 0,
        },
        '& .MuiTablePagination-toolbar': {
          // padding: '0 0 5px 0',
          margin: '5px 0',
          minHeight: mediumScreen() ? 38 : 40,
        },
      }}
    />
    </>
  );
}

export default TableComponentPagination;