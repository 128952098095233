import React, { useEffect /* useRef, useEffect */ } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import WebViewAppBar from "./WebViewAppBar";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
// import MobileViewAppBar from "./MobileViewAppBar";
import SubMenuLayout from "./SubMenuLayout";
// import SwipeView from "./SwipeView";
// import { withRouter } from "react-router-dom";
import { primary } from "../styles/colors";
import { useNavigate } from "react-router-dom";
//import { poppinsBold, poppinsLight, poppinsMedium, poppinsRegular } from "../styles/Fonts"
import { CssBaseline } from "@material-ui/core";
// import { primary } from "../styles/colors";
import { connect } from "react-redux";
import CustomAppBar from "./CustomAppBar";
import { mediumScreen } from "../Regex";


const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },

  palette: {
    primary: {
      light: primary,
      main: primary,
      dark: primary,
    },
    secondary: {
      light: primary,
      main: primary,
      dark: primary,
    },
  },
  status: {
    danger: "orange",
  },
});

function Layout(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  
  useEffect(() => {
    if (props.noAppBar) {
      setOpen(false);
    }
  }, [props.noAppBar]);
  const { pathname } = useLocation();
  const { tab, id } = useParams();
  return (
    <div
      className={
        props.noAppBar
          ? "height-100 width-100 overflowH d-flex"
          : "height-100vh width-100 overflowH d-flex"
      }>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />{" "}
        {props.noAppBar ? (
          ""
        ) : (
          <WebViewAppBar
            {...props}
            open={open}
            setOpen={setOpen}
            navigate={navigate}
            pathname={pathname}
            params={{ tab: tab, id: id }}
          />
        )}
        <div
          style={{
            transition: open
              ? "235px 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
              : "57px 0.3s cubic-bezier(0.4, 0, 0.2, 1)",

            minHeight: "52px",
            //zIndex: "999999",
            position: "absolute",
            top: "0",
            left: props.noAppBar ? 0 : open ? "235px" : "57px",
            background: "white",
            boxShadow: "0px 2px 2px rgb(241, 154, 48)",
            width: `calc(100% - ${
              props.noAppBar 
              ? 0 
              : open 
              ?  "235px" 
              : "57px"
            })`,
          }}>
          <CustomAppBar
            {...props}
            open={true}
            setOpen={setOpen}
            navigate={navigate}
            pathname={pathname}
            params={{ tab: tab, id: id }}
          />
        </div>
        {props.tabs && (<>
          {console.log("TABS:",props.tabs)}
          <SubMenuLayout
            setOpen={setOpen}
            {...props}
            navigate={navigate}
            pathname={pathname}
            params={{ tab: tab, id: id }}
          />
       </> )}
       {console.log(props,"NOAPPBAR :", props.noAppBar, "open", open, "CENTERWIDTH", props.centerWidth, "WIDTH", props.width, "CLOSEWIDTH",  props.closeWidth)}
        <div
          style={{
            marginTop: "52px",
            height: "calc(100% - 52px)",
            width: props.noAppBar
              ? `calc(100% - ${props.centerWidth})`
              : props.customWidth
              ? open ? `calc(100% - ${props.openWidth})` : `calc(100% - ${props.closeWidth})`
              : open  && mediumScreen() 
              ? "calc(100% - 410px)"
              : open && !mediumScreen()
              ? props.centerWidth
                ? `calc(100% - ${props.openWidth})`
                : props.width
                ? props.width
                : "calc(100% - 561.47px)"
              : props.centerWidth && mediumScreen() 
              ? `calc(100% - 233px)`
              : props.centerWidth && !mediumScreen()
              ? `calc(100% - ${props.closeWidth})`
              : props.width
              ? props.width
              : "calc(100% - 327px)",
          }}>
          {props.children}
        </div>
      </MuiThemeProvider>
    </div>
  );
}


const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
