import React, { useState } from "react";
import { StyledButton } from "../styles/StyledComponents";
import { List, ListItem, Popover } from "@material-ui/core";
import RestoreIcon from "@mui/icons-material/Restore";

// import ClickAwayListener from "@mui/base/ClickAwayListener";
import { BottomArrow } from "../styles/Icons";
import ConfirmDialog from "./ConfirmDialog";
import ReplayIcon from "@mui/icons-material/Replay";
import { ListMapUI } from "./ListMapUI";
import { ClickAwayListener } from "@mui/base";


function ShowPackaging(props) {
  const [openPopover, setOpenPopover] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    title,
    details,
    noTotalbags,
    noBags,
    returned_bags,
    totalCiBags,
    fromPO,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const handleHeader = () => {
    let headerArr = [
      { label: "Size", key: "size", key2: "unit" },
      { label: "Bag Type", key: "bag_type" },
    ];

    if (!noTotalbags) {
      headerArr.push({
        label: "Remaining Bags",
        key: "new_remaining_bags",
        orKey: "remaining_bags",
      });
    }
    if (!noBags) {
      headerArr.push({ label: "Used Bags", key: "used_bags" });
    }
    if (totalCiBags) {
      headerArr.push({ label: "Total Bags", key: "total_bags" });
    }
    if (returned_bags) {
      headerArr.push({ label: "Returned Bags", key: "returned_bags" });
    }
    return headerArr;
  };

  return (
    <>
      {title
        ? title
        : details &&
          details[0] &&
          `${details[0].size} ${details[0].unit} ${
            details.length > 1
              ? `.. (${
                  details.length > 9 ? details.length : `0${details.length}`
                })`
              : ""
          }`}
      <StyledButton
        name={<BottomArrow width="20" />}
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopover(true);
          setAnchorEl(e.currentTarget);
        }}
        padding="0"
        minWidth="20px"
      />

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            setOpenPopover(false);
            setAnchorEl(null);
          }}
        >
          <div
            style={{
              height: "auto",
              minHeight: "fit-content",
              width: "100%",
              padding: "8px 0",
            }}
            className="d-flex d-flex-column alignC justifyC"
          >
            <ListMapUI header={handleHeader()} data={details} />
            {/* {details &&
              details.map((sDetails) => (
                <div
                  key={sDetails.size && sDetails.size.toString()}
                  style={{ width: "100%", padding: "8px 16px" }}
                >
                  <div
                    className="d-flex alignC textC"
                    style={{ margin: "0", fontSize: "0.9rem", height: "24px" }}
                  >
                    <div
                      className="d-flex"
                      style={{ marginRight: "10px", minWidth: 150 }}
                    >
                      {
                        <span
                          style={{ fontWeight: "600", minWidth: "fit-content" }}
                        >
                          {"Size Unit "}
                        </span>
                      }{" "}
                      <div style={{ marginRight: "3px" }} />
                      <span
                        style={{ width: "fit-content", minWidth: "96.28px" }}
                      >
                        {` : ${sDetails.size} ${sDetails.unit} , `}{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex"
                      style={{ marginRight: "10px", minWidth: 150 }}
                    >
                      <span
                        style={{ fontWeight: "600", minWidth: "fit-content" }}
                      >
                        {" Bag Type "}
                      </span>{" "}
                      <div style={{ marginRight: "3px" }} />
                      <span
                        style={{ width: "fit-content", minWidth: "50.99px" }}
                      >
                        {` : ${
                          sDetails.bag_type && sDetails.bag_type
                            ? sDetails.bag_type
                            : ""
                        } , `}
                      </span>
                    </div>
                    {noTotalbags ? (
                      ""
                    ) : (
                      <div
                        className="d-flex alignC"
                        style={{ marginRight: "10px", minWidth: 190 }}
                      >
                        <span
                          style={{ fontWeight: "600", minWidth: "fit-content" }}
                        >
                          {"  Remaining Bags "}
                        </span>
                        <div style={{ marginRight: "3px" }} />{" "}
                        <span
                          style={{ width: "fit-content", minWidth: "35px" }}
                        >
                          {" "}
                          {` : ${
                            sDetails.new_remaining_bags ||
                            sDetails.new_remaining_bags === 0
                              ? sDetails.new_remaining_bags
                              : sDetails.remaining_bags
                              ? sDetails.remaining_bags
                              : ""
                          }`}{" "}
                        </span>
                        <Tooltip title="Reset Bags" placement="top">
                          <span
                            onClick={() => {
                              if (
                                Boolean(sDetails.new_remaining_bags) &&
                                sDetails.new_remaining_bags > 0
                              ) {
                                setOpenDialog(sDetails.id);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              color:
                                Boolean(sDetails.new_remaining_bags) &&
                                sDetails.new_remaining_bags > 0
                                  ? "rgb(241 154 48)"
                                  : "rgba(0,0,0,0.2)",
                              transform: "scale(0.8)",
                              height: 22,
                            }}
                          >
                            <ReplayIcon />
                          </span>
                        </Tooltip>
                        <ConfirmDialog
                          openDialog={openDialog === sDetails.id}
                          setOpenDialog={(d) => setOpenDialog(d)}
                          width="400px"
                          height="200px"
                          header="Bag Reset"
                          action="Yes"
                          resetText="No"
                          onSubmit={() => {
                            props?.onResetSubmit(sDetails);
                            setOpenDialog(false);
                          }}
                          content={() => (
                            <div
                              className="d-flex d-flex-column justifyC"
                              style={{ width: "100%", padding: "2px" }}
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#888",
                                  paddingBottom: 16,
                                }}
                              >
                                {`${sDetails.size} ${sDetails.unit} | ${
                                  sDetails.bag_type && sDetails.bag_type
                                    ? sDetails.bag_type
                                    : ""
                                } `}
                              </span>

                              <span style={{ fontSize: "14px", color: "#333" }}>
                                Are you sure you want to reset the bag?
                              </span>
                            </div>
                          )}
                        />
                      </div>
                    )}
                    {totalCiBags ? (
                      <div
                        className="d-flex alignC"
                        style={{ marginRight: "10px", minWidth: 120 }}
                      >
                        <span
                          style={{ fontWeight: "600", minWidth: "fit-content" }}
                        >
                          {"  Total Bags "}
                        </span>
                        <div style={{ marginRight: "3px" }} />
                        <span
                          style={{ width: "fit-content", minWidth: "35px" }}
                        >{` : ${
                          sDetails.total_bags ? sDetails.total_bags : ""
                        }`}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {noBags ? (
                      ""
                    ) : (
                      <div
                        className="d-flex alignC"
                        style={{ marginRight: "10px", minWidth: 120 }}
                      >
                        <span
                          style={{ fontWeight: "600", minWidth: "fit-content" }}
                        >
                          {"  Used Bags "}
                        </span>
                        <div style={{ marginRight: "3px" }} />
                        <span
                          style={{ width: "fit-content", minWidth: "35px" }}
                        >{` : ${
                          sDetails.used_bags ? sDetails.used_bags : ""
                        }`}</span>
                      </div>
                    )}
                    {returned_bags && (
                      <div
                        className="d-flex alignC"
                        style={{ marginRight: "10px", minWidth: 120 }}
                      >
                        <span
                          style={{ fontWeight: "600", minWidth: "fit-content" }}
                        >
                          {"  Returned Bags "}
                        </span>
                        <div style={{ marginRight: "3px" }} />
                        <span
                          style={{ width: "fit-content", minWidth: "35px" }}
                        >{` : ${
                          sDetails.returned_bags ? sDetails.returned_bags : ""
                        }`}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))} */}
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  );
}

export default ShowPackaging;
