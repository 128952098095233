import React, { Component, useState } from "react";
import { CssButton, CssCreateTextField } from "./PurchaseOrdersComponent";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Stepper, Typography, StepContent, Divider } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import CustomStepperPO from "../../layoutComponents/CustomStepperPO";
import Box from "@mui/material/Box";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableComponent from "../../layoutComponents/TableComponent";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Slide,
  TextField,
  Tooltip,
  withStyles,
  Button,
} from "@material-ui/core";
import { lightGrey, primary, red, white } from "../../styles/colors";
import MultipleSelectBar from "../../layoutComponents/MultipleSelectBar";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import ReactDOM from "react-dom";
import PurchaseIcon from "@mui/icons-material/AddShoppingCart";
import { BottomArrow, CrossIcon, EditIcon, TopArrow } from "../../styles/Icons";
import {
  CssKeyboardDatePicker,
  CssTextField,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { Add, CheckCircle } from "@material-ui/icons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { filterData, mediumScreen, StopScroll } from "../../Regex";
import { EndPoints } from "../../utils/EndPoints";
import PurchaseOrderPdf from "./PurchaseOrderPdf";
import ShowSpecifications, {
  getSpecificationId,
} from "../../layoutComponents/ShowSpecifications";
import ShowPackaging from "../../layoutComponents/ShowPackagingPort";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Chip,
  FormControlLabel,
  ListItemIcon,
  Menu,
  Switch,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import { SnackbarContext } from "../../snackbarContext";
import {
  CustomAutocomplete,
  CustomMultiSelectAutocomplete,
  CustomSearchAutocomplete,
} from "../../layoutComponents/CustomAutocomplete";
import { CustomItemTableUI } from "./CustomItemTableUI";

const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
  },
  none: { height: 0, width: 0 },
  innerDiv: {
    height: "50%",
    background: "white",
    borderRadius: "10px",
    marginBottom: "20px",
    pointerEvents: "none",
    width: "100%",
  },
  innerDiv1: {
    height: "65%",
    background: "#8080801a",
    borderRadius: "10px",
    padding: "10px 10px 0 10px",
    width: "100%",
    pointerEvents: "none",
  },
  innerMainDiv: {
    width: "100%",
    display: "flex",
  },
  shortCloseBox: {
    border: "1px solid rgb(241 154 48)",
    // border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "5px 20px 20px 20px",
  },
  shortCloseHeadingBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
  },
  shortCloseInnerBoxTitle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    padding: "12px 0px 12px 5px",
  },
  shortCloseInnerContainer: {
    padding: "15px 10px",
    backgroundColor: "#f5f5f5",
    border: "1px solid gainsboro",
    borderRadius: "4px",
    marginBottom: 20,
  },
  shortCloseListStyleValue: {
    color: "#575656",
    fontSize: "0.9em",
  },
  shortCloseItemStyleValue: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    width: "80%",
    fontSize: "0.9em",
  },
  shortCloseBottomText: {
    color: "#575656",
    fontWeight: 500,
    fontSize: "0.875em",
    textAlign: "center",
    marginTop: 20,
  },
};
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: "auto", // Enable vertical scrolling
    },
  },
};

const StaticFieldValue = {
  acceptance:
    "Sealed and signed purchase order to be provided by seller within 03 days of receipt of the same from buyer. If no reply is received from seller then it will be termed as acceptance from the buyer",
  eway: "If packing in25kg then seller will be responsible for generating the E-Way bill. Buyer will not generate E-way bill on the behalf of the Seller",
  loading: `Loading to be done within 10 days of receipt of bags.  Extension beyond current delivery period at buyer's option.`,
  cancellation: `If contract is cancelled for whatsoever reason, then bags to be dispatched by miller immediately. Payment of transportation to be mutually decided.`,
  packing_condition: `(Bags to be dispatched by the buyer at its own cost within 5 - 6 WD).`,
  brokerage_commission: `Seller Side Brokerage of Rs.100/Mt Will Be Deducted from The Payment Upon Request of Broker.`,
  short_shipments_and_penalty: `Any short shipment or delay in supply of cargo will incur a penalty as per the current market price besides recovery of all costs/losses arising on account of this.`,
  other_conditions: `The Seller assures that there is no lien, charge or encumbrance of any nature created in respect of the Materials sold by the Seller and assures that a clear, good and valid title shall be conveyed to Buyer. Seller also confirms that there is no restriction whatsoever by means of any statute, regulations, contracts, covenants or the like, restricting the Seller from performing any of its obligations under this Purchase Order. Seller shall be primarily responsible to prove all the time to the Buyer for providing of all necessary documents required by any Government agency to substantiate the legal ownership of the cargo as per any regulation. Seller shall also undertake that he shall provide all the necessary support to the Buyer to handle any issue come up with respect to quality & legal title of the material either before or after export of the cargo. Seller also confirms that the Material supplied under this agreement is not meant for Public Distribution System (PDS) or diverted from PDS. Seller confirms they are not Fair Price Shop or Stage I contractor of the District. Seller abides by the Essential Commodities Act and its Provision as contemplated under Sec 6A of the Act. The Seller shall indemnify the buyer for any cost, expenses, penalties that may be incurred/liable to be paid by buyer in respect of the title of the goods sold by buyer. Cargo should be fresh milled from the rice mill & there should not be any live insect or weevil. No e-mail communication will be accepted as a legal notice /claim  served on the Buyer.`,
  delivery_terms: `F.O.R. delivered in buyer’s nominated CHA’s warehouse by trucks.`,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ActionMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (onClick) => {
    return () => {
      handleClose(); // Close the menu first
      onClick && onClick(); // Then run the item's onClick if it exists
    };
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0", margin: "0 20px 0 0" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        disableAutoFocus={true} // Prevent focusing when the menu opens
        disableEnforceFocus={true} // Prevent focus trapping within the menu
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              width: "20ch",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.menuItemsList.map((option, i) => (
          <>
            <MenuItem
              key={i}
              onClick={handleMenuItemClick(option?.onClick)}
              className="d-flex alignC"
            >
              <span
                style={{
                  marginRight: "10px",
                  height: 20,
                  transform: "scale(0.9)",
                  color: "#666",
                }}
              >
                {option.icon}
              </span>
              <span style={{ fontSize: "0.875em" }}>{option.item}</span>
            </MenuItem>
            {i !== props.menuItemsList.length - 1 ? (
              <Divider variant="middle" style={{ margin: "2% 0" }} />
            ) : null}
          </>
        ))}
      </Menu>
    </div>
  );
};

export const CssValueTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      color: "black",
    },
    "& label.Mui-focused": {
      color: "rgb(241 154 48)",
    },
    "& .MuiOutlinedInput-root": {
      caretColor: "transparent",
      borderColor: primary,
      "& fieldset": {
        borderColor: "#0000ff69",
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "10px",
        fontSizze: mediumScreen() ? "0.8rem" : "0.9rem",
      },
    },
  },
})(TextField);
const MaxMin = [
  { name: "Greater Than", id: "greater_than" },
  { name: "Greater Than Or Equal To", id: "greater_than_or_equal_to" },
  { name: "Less Than", id: "less_than" },
  { name: "Not Equal To", id: "not_equal_to" },
  { name: "Equal To", id: "equal_to" },
  { name: "Less Than Or Equal To", id: "less_than_or_equal_to" },
  // { name: "No Condition", id: "no_condition" },
];
export class Details extends Component {
  static contextType = SnackbarContext;
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      editDialog: false,
      submitButtonClicked: false,
      loadingFun: false,
      error: false,
      success: false,
      added: false,
      calenderOpenLS: false,
      selctedPOStatus: "DRAFT",
      MaxMin: MaxMin,
      specificationArr: [],
      specificationArrCopy: [],
      specificationId: false,
      submittedData: {},
      submittedItemData: {},
      errorMsg: {
        Unit: false,
        contractTypeName: false,
        inlandGodown: false,
        BorkerName: false,
        Borkerage: false,
        portArry: false,
        MillerName: false,
        BrandName: false,
        HSN: false,
        Rate: false,
        PaymentTerm: false,
        Remark: false,
        poNum: false,

        packagingArr: false,
      },
      Unit: "",
      approve: "",
      reject: "",
      contractTypeName: "",
      inlandGodown: [],
      contractTypeId: "",
      BorkerName: "",
      BorkerNameId: "",
      Borkerage: "",
      BorkerageId: "",
      portArry: [],
      Miller: [],
      MillerNameId: "",
      MillerName: "",
      MillerAddress: "",
      MillerGST: "",
      delivery_terms_extras: "",
      delivery_terms: "",
      ContactPerson: "",
      last_survey: new Date(),
      calenderOpen: false,
      SalesOrderId: "",
      editSnack: false,
      SalesOrder: "",
      BrandName: false,
      selectedBrandMultipleName: false,
      ItemCategory: "",
      ItemType: "",
      packagingArr: [],
      editSelectedItemPackagingArr: [],
      HSN: "",
      HSN_id: "",
      total_quantity: "",
      Rate: "",
      searchedData: new Date(),
      selectedPoid: false,
      PaymentTerm: "",
      PaymentTermId: "",
      onlyDialog: false,
      Remark: "",
      poNum: "",
      selectedSOItems: [],

      onlyUi: false,
      pushNoti: false,
      showSpeci: false,
      poItemsArray: [],
      poItemsArrayCopy: [],
      defaultStep: 0,
      currentStep: 0,
      Acceptance: false,
      Eway: false,
      Cancellation: false,
      Loading: false,
      sales_tax_terms: "",
      packing_condition: "",
      brokerage_commission: "",
      short_shipments_and_penalty: "",
      other_conditions: "",
      moveToShipHeader2: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        "HSN Code",
        "Total Item Quantity",
        "Contract Rate",
        "Buyer Marking",
        "Sales Tax Terms",
        "Specifications",
      ],
      specificationHeader: [
        {
          name: "Type",
          type: "select",
          dataType: "text",
          api: "name",
          array: [],
          value: "name",
          filter: (array, specificationArr) => {
            return (
              array &&
              array.length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ) &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ).length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              )
            );
          },
        },
        {
          name: "Condition",
          type: "select",
          dataType: "text",
          api: "condition",
          array: MaxMin,
          value: "name",
        },
        {
          name: "Value",
          type: "textField",
          dataType: "number",
          api: "value",
        },
      ],
      submittedSpecifies: {},
      confirmAddWithoutSpecification: false,
      shortCloseDialog: false,
      cancelPO: false,
      requiredToggle: true,
      isItemRowEdit: false,
      loosePoItem: [],
    };
  }
  getSpecificationName = (sp) => {
    switch (sp) {
      case "equal_to":
        return "only";

      case "not_equal_to":
        return "not";

      case "greater_than":
        return "above";

      case "greater_than_or_equal_to":
        return "min";

      case "less_than":
        return "below";

      case "less_than_or_equal_to":
        return "max";
      default:
        return " ";
    }
  };
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  reset = (noSnack) => {
    const {
      editDialog,
      contractTypeName,
      PaymentTerm,
      MillerName,
      ContactPerson,
      BorkerName,
      BrandName,
      Remark,
      poNum,
      portArry,
      packagingArr,
      Rate,
      Unit,
      HSN,
      SalesOrder,
      total_quantity,
      selectedSOItems,
    } = this.state;
    const { singlelistView } = this.props;
    if (editDialog !== false) {
      this.setState({
        specificationArrCopy: [],
        specificationArr: [],
        total_quantity: "",
        BrandNameId: "",
        BrandName: false,
        selectedBrandMultipleName: false,
        selctedPOStatus: "DRAFT",
        SalesOrder: "",
        SalesOrderId: "",
        contractTypeName: "",
        PaymentTerm: "",
        MillerName: "",
        MillerAddress: "",
        MillerGST: "",
        ContactPerson: "",
        ItemCategory: "",
        ItemTypeId: "",
        ItemCategoryId: "",
        ItemType: "",
        BorkerName: "",
        Borkerage: "",
        Remark: "",
        poNum: "",
        portArry: [],
        packagingArr: [],
        Rate: "",
        Unit: "",
        HSN: "",
        HSN_id: "",
        selectedSOItems: [],
        submittedData: {},
        submittedItemData: {},
        errorMsg: {
          Unit: false,
          contractTypeName: false,
          BorkerName: false,
          Borkerage: false,
          portArry: false,
          MillerName: false,
          BrandName: false,
          HSN: false,
          Rate: false,
          PaymentTerm: false,
          Remark: false,
          poNum: false,
          packagingArr: false,
        },
      });
      console.log("IF HERE");
      this.setState(
        {
          editDialog: singlelistView,
          loadingFun: true,
          showSpeci: false,
        },
        () => {
          this.setData(
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          );
        }
      );
    } else {
      console.log("ELSE HERE");
      if (
        !Rate &&
        !Unit &&
        !HSN &&
        !Remark &&
        !poNum &&
        !BrandName &&
        !BorkerName &&
        !ContactPerson &&
        !MillerName &&
        !PaymentTerm &&
        !contractTypeName &&
        packagingArr.length === 0 &&
        portArry.length === 0 &&
        !SalesOrder &&
        !total_quantity &&
        selectedSOItems.length === 0
      ) {
        this.setState({
          error: noSnack ? false : "Nothing to reset !",
          submittedData: {},
          submittedItemData: {},
          errorMsg: {
            Unit: false,
            contractTypeName: false,
            BorkerName: false,
            Borkerage: false,
            portArry: false,
            inlandGodown: false,
            MillerName: false,
            BrandName: false,
            HSN: false,
            Rate: false,
            PaymentTerm: false,
            Remark: false,
            poNum: false,
            packagingArr: false,
          },
        });
      } else {
        this.props.clearPoItems();
        this.clearSurveyFields();
        this.setState(
          {
            specificationArrCopy: [],
            specificationArr: [],
            total_quantity: "",
            BrandNameId: "",
            BrandName: false,
            selectedBrandMultipleName: false,
            selctedPOStatus: "DRAFT",
            SalesOrder: "",
            SalesOrderId: "",
            contractTypeName: "",
            inlandGodown: [],
            PaymentTerm: "",
            MillerName: "",
            MillerAddress: "",
            MillerGST: "",
            ContactPerson: "",
            ItemCategory: "",
            ItemTypeId: "",
            ItemCategoryId: "",
            ItemType: "",
            BorkerName: "",
            Borkerage: "",
            Remark: "",
            poNum: "",
            portArry: [],
            packagingArr: [],
            Rate: "",
            Unit: "",
            HSN: "",
            HSN_id: "",
            selectedSOItems: [],
            submittedData: {},
            submittedItemData: {},
            errorMsg: {
              Unit: false,
              contractTypeName: false,
              BorkerName: false,
              Borkerage: false,
              portArry: false,
              inlandGodown: false,
              MillerName: false,
              BrandName: false,
              HSN: false,
              Rate: false,
              PaymentTerm: false,
              Remark: false,
              poNum: false,
              packagingArr: false,
            },
          },
          () => {
            this.setState({
              success: noSnack ? false : "Reset successfully !",
            });
          }
        );
      }
    }
  };
  setData = (Unit) => {
    const {
      MillerList,
      BrandList,
      ItemCateoryList,
      ItemList,
      BrokerList,
      ContractTypeList,
      InlandGodownList,
      PackagingList,
      PaymentTermList,
      PO_Items_List,
      poItemheader,
      HSNlist,
      singlelistView,
      poPackahging,
    } = this.props;
    const { PortsList } = this.state;
    const { editDialog } = this.state;

    const oldnewPortArr = editDialog.ports ? editDialog.ports : [];
    const newPortArr = [];
    if (PortsList && PortsList.length > 0) {
      PortsList.map((sPack) => {
        if (oldnewPortArr.length === 0) {
          console.log(sPack, sPack.id.toString(), editDialog.ports);
          // if ("Any Indian Port" === sPack.id.toString()) {
          //   return newPortArr.push(sPack);
          // } else {
          return null;
          // }
        } else {
          if (oldnewPortArr.includes(sPack.id.toString())) {
            return newPortArr.push(sPack);
          } else {
            return null;
          }
        }
      });
    }

    const newPOItemArray = [];
    if (PO_Items_List && PO_Items_List.length > 0) {
      PO_Items_List &&
        PO_Items_List.map((sList) => {
          const packaging_details = [];
          const pId = filterData(poPackahging, "po_items", sList.id)
            ? filterData(poPackahging, "po_items", sList.id).map((s) => {
                return s.packaging.toString();
              })
            : [];
          if (PackagingList && PackagingList.length > 0) {
            PackagingList.map((sPack) => {
              const newObject =
                poPackahging &&
                filterData(poPackahging, "po_items", sList.id) &&
                filterData(
                  poPackahging &&
                    filterData(poPackahging, "po_items", sList.id),
                  "packaging",
                  sPack.id,
                  "onlyOne"
                )
                  ? filterData(
                      poPackahging &&
                        filterData(poPackahging, "po_items", sList.id),
                      "packaging",
                      sPack.id,
                      "onlyOne"
                    )
                  : null;

              if (pId.includes(sPack.id.toString()) || pId.includes(sPack.id)) {
                sPack.remaining_bags = newObject ? newObject.remaining_bags : 0;
                sPack.used_bags = newObject
                  ? newObject.used_bags
                    ? newObject.used_bags
                    : 0
                  : 0;
                return packaging_details.push(sPack);
              } else {
                return null;
              }
            });
          }
          console.log(sList, "sList");
          const addData = {
            ...sList,
            soitem_id: sList.so_item_detail ? sList.so_item_detail : undefined,
            // sList.so_item_details && sList.so_item_details.length > 0
            //   ? sList.so_item_details[0].id
            //   : undefined,
            brand: sList.brand,

            hsn_code: sList.hsn_code ? sList.hsn_code : "",
            packagingArr: packaging_details.map((sP) => {
              sP.sizeUnit = `${sP.size} ${sP.unit}`;
              return sP;
            }),
            total_quantity: sList.total_quantity,

            packaging: sList.packaging,
          };
          addData["id"] = sList.id;
          addData["remaining_bags"] = sList.remaining_bags;
          addData["dispatched_quantity"] = sList.dispatched_quantity;
          addData[poItemheader[1]] =
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter((sM) =>
              sM.id === sList.item_category ? sList.item_category : 0
            )[0]
              ? ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0]?.name &&
                ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0]?.name
              : "";
          addData[`${poItemheader[1]}id`] = sList.item_category;

          addData[poItemheader[2]] =
            ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((sM) =>
              sM.id === sList.item_type ? sList.item_type : 0
            )[0]
              ? ItemList.filter((sM) =>
                  sM.id === sList.item_type ? sList.item_type : 0
                )[0]?.broken_percentage
              : "";
          addData[`${poItemheader[2]}id`] = sList.item_type;

          addData[poItemheader[0]] =
            BrandList &&
            BrandList.length > 0 &&
            BrandList.filter((sM) =>
              sM.id === sList.brand ? sList.brand : 0
            )[0]
              ? BrandList.filter((sM) =>
                  sM.id === sList.brand ? sList.brand : 0
                )[0].brand_name
              : "";
          addData[`${poItemheader[0]}id`] = sList.brand;

          addData.selectedBrandMultipleName = `${
            filterData(BrandList, "id", sList.brand, "onlyOne")
              ? filterData(BrandList, "id", sList.brand, "onlyOne").brand_name
              : ""
          }-${
            filterData(ItemCateoryList, "id", sList.item_category, "onlyOne")
              ? filterData(
                  ItemCateoryList,
                  "id",
                  sList.item_category,
                  "onlyOne"
                ).name
              : ""
          }-${
            filterData(ItemList, "id", sList.item_type, "onlyOne")
              ? filterData(ItemList, "id", sList.item_type, "onlyOne")
                  ?.broken_percentage
              : ""
          } %`;
          addData[poItemheader[3]] =
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }) &&
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }).length > 0
              ? packaging_details.map((sP) => {
                  return `${sP.size} ${sP.unit}`;
                })[0]
              : "-";
          addData[`${poItemheader[3]}Id`] = packaging_details;
          addData["packagageArrUsed"] = packaging_details.map((sP) => {
            const newObj = {};
            newObj.id = sP.id;
            newObj.bag_type = sP.bag_type;
            newObj.size = sP.size;
            newObj.unit = sP.unit;
            newObj.sizeUnit = `${sP.size} ${sP.unit}`;
            newObj.used_bags_po = sP.used_bags ? sP.used_bags : 0;
            newObj.used_bags = 0;
            newObj.remaining_bags = sP.remaining_bags;
            return newObj;
          });
          addData[poItemheader[4]] =
            HSNlist &&
            HSNlist.length > 0 &&
            HSNlist.filter(
              (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
            )[0]
              ? HSNlist.filter(
                  (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
                )[0].hsn_code
              : "";

          addData[`${poItemheader[4]}id`] = sList.hsn_code
            ? sList.hsn_code
            : "";
          addData[poItemheader[5]] = sList.total_quantity;
          addData[`${poItemheader[5]}Unit`] = `${sList.total_quantity} ${
            Unit && Unit ? Unit : ""
          }`;

          addData[poItemheader[6]] = sList.contract_rate;

          addData["specificationArr"] = sList["specifications"];
          addData["Specifications"] = sList["specifications"];

          addData[`${poItemheader[6]}Unit`] = `${sList.contract_rate}/${
            Unit && Unit ? Unit : ""
          }`;

          addData.id = sList.id;
          addData.po_item_id = sList.id;
          newPOItemArray.push(addData);
          return sList;
        });
    }

    if (editDialog !== false) {
      if (
        editDialog.sales_order_details && editDialog.sales_order_details.id
          ? editDialog.sales_order_details.id
          : ""
      ) {
        this.props
          .PurchaseOrdersFunction(
            "get",
            "dispatch/so-items/list",
            null,
            null,
            null,
            {
              so_id: editDialog.sales_order_details.id,
            }
          )
          .then((soitems) => {
            if (soitems && soitems.length > 0) {
              const soDetail = {};
              soitems.map((s) => {
                const poofThisSo = filterData(
                  newPOItemArray,
                  "soitem_id",
                  s.id,
                  "onlyOne"
                )
                  ? filterData(newPOItemArray, "soitem_id", s.id, "onlyOne")
                  : {};
                soDetail[s.id] =
                  poofThisSo.total_quantity ?? s.total_quantity ?? 0;
                return s;
              });
              console.log(
                newPOItemArray,
                "newPOItemArray.map((po)=>{return po.id})"
              );
              this.setState({
                soitemsList: soitems,
                submittedItemData: soDetail,
                selectedSOItems: newPOItemArray.map((po) => {
                  return po.soitem_id;
                }),
              });
            }
          });
      }
      this.props.setPoItems(newPOItemArray, newPOItemArray);
      this.setState(
        {
          selctedPOStatus: editDialog.status ? editDialog.status : "OPEN",
          delivery_terms_extras: editDialog.delivery_terms_extras,
          delivery_terms: editDialog.delivery_terms,
          SalesOrder:
            editDialog.sales_order_details &&
            editDialog.sales_order_details.so_number
              ? editDialog.sales_order_details.so_number
              : "",
          SalesOrderId:
            editDialog.sales_order_details && editDialog.sales_order_details.id
              ? editDialog.sales_order_details.id
              : "",

          last_survey: editDialog.last_survey ? editDialog.last_survey : "",
          searchedData: editDialog.contract_date,
          contractTypeName:
            ContractTypeList &&
            ContractTypeList.length > 0 &&
            ContractTypeList.filter((sM) =>
              sM.id === editDialog.contract_type_details.id
                ? editDialog.contract_type_details.id
                : 0
            )[0]
              ? ContractTypeList.filter((sM) =>
                  sM.id === editDialog.contract_type_details.id
                    ? editDialog.contract_type_details.id
                    : 0
                )[0].contract_type
              : "",
          contractTypeId: editDialog.contract_type_details.id
            ? editDialog.contract_type_details.id
            : 0,
          inlandGodown:
            editDialog?.inland_godowns?.map((data) => {
              return filterData(
                this.props.masters["InlandGodownList"],
                "id",
                Number(data),
                "onlyOne"
              );
            }) || [],
          PaymentTermId: editDialog.payment_terms
            ? Number(editDialog.payment_terms)
            : "",
          PaymentTerm:
            PaymentTermList &&
            PaymentTermList.length > 0 &&
            PaymentTermList.filter(
              (sM) => sM.id === Number(editDialog.payment_terms)
            )[0]
              ? PaymentTermList &&
                PaymentTermList.length > 0 &&
                PaymentTermList.filter(
                  (sM) => sM.id === Number(editDialog.payment_terms)
                )[0].days_range
              : "",

          MillerNameId: editDialog.miller_details.id
            ? editDialog.miller_details.id
            : 0,
          MillerName:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0]?.name
              : "",

          MillerAddress:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].address
              : "",
          MillerGSTId: "",
          MillerGST:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].gstin
              : "",

          ContactPerson:
            MillerList &&
            MillerList.length > 0 &&
            MillerList.filter((sM) =>
              sM.id === editDialog.miller_details.id
                ? editDialog.miller_details.id
                : 0
            )[0]
              ? MillerList.filter((sM) =>
                  sM.id === editDialog.miller_details.id
                    ? editDialog.miller_details.id
                    : 0
                )[0].contact_person
              : "",
          ContactPersonId: editDialog.miller_details.id
            ? editDialog.miller_details.id
            : 0,

          Borkerage:
            BrokerList &&
            BrokerList.length > 0 &&
            BrokerList.filter((sM) =>
              sM.id === editDialog.broker_details?.id
                ? editDialog.broker_details?.id
                : 0
            )[0]
              ? BrokerList.filter((sM) =>
                  sM.id === editDialog.broker_details?.id
                    ? editDialog.broker_details?.id
                    : 0
                )[0].default_brokerage
              : "",
          BorkerageId: editDialog.broker_details?.id
            ? editDialog.broker_details?.id
            : 0,
          BorkerName:
            BrokerList &&
            BrokerList.length > 0 &&
            BrokerList.filter((sM) =>
              sM.id === editDialog.broker_details?.id
                ? editDialog.broker_details?.id
                : 0
            )[0]
              ? BrokerList.filter((sM) =>
                  sM.id === editDialog.broker_details?.id
                    ? editDialog.broker_details?.id
                    : 0
                )[0]?.name
              : "",
          BorkerNameId: editDialog.broker_details?.id
            ? editDialog.broker_details?.id
            : "",
          Remark: editDialog.remark ? editDialog.remark : "",
          poNum: editDialog.po_number ? editDialog.po_number : "",
          portArry: newPortArr,

          Unit: editDialog.unit,
          Acceptance: editDialog.acceptance_of_purchase_order ?? "",
          Eway: editDialog.eway_bill ?? "",
          Loading: editDialog.loading ?? "",
          Cancellation: editDialog.contract_cancellation ?? "",
          packing_condition: editDialog.packing_condition ?? "",
          brokerage_commission: editDialog.brokerage_commission ?? "",
          short_shipments_and_penalty:
            editDialog.short_shipments_and_penalty ?? "",
          other_conditions: editDialog.other_conditions ?? "",
        },
        () => {
          this.setState({ loadingFun: false });
        }
      );
    }
  };
  handleClearFields = () => {
    this.setState({
      added: false,
      loadingFun: false,
      success: false,
      error: false,
    });
  };
  clearSurveyFields = () => {
    const errorMsg = this.state.errorMsg;
    errorMsg.percentageValue = false;
    errorMsg.selectedSurrvayStatus = false;
    errorMsg.selectedCondition = false;
    this.setState({
      errorMsg,
      percentageValue: "",
      selectedSurrvayStatus: "",
      selectedCondition: "",
      selectedConditionId: "",
      specificationId: false,
    });
  };
  setInitialValues = (next) => {
    const {
      openDialog,
      PortsList,
      onlyDialog,
      onlyUi,
      poItemsArray,
      poItemsArrayCopy,
    } = next;
    console.log(
      "next PO",
      next,
      openDialog === true && this.state.openDialog === false,
      openDialog !== this.state.openDialog
    );
    if (openDialog === true && this.state.openDialog === false) {
      this.setState(
        {
          specificationArrCopy: [],
          specificationArr: [],
          Acceptance: StaticFieldValue?.acceptance,
          Eway: StaticFieldValue?.eway,
          Loading: StaticFieldValue?.loading,
          Cancellation: StaticFieldValue?.cancellation,
          packing_condition: StaticFieldValue?.packing_condition,
          brokerage_commission: StaticFieldValue?.brokerage_commission,
          short_shipments_and_penalty:
            StaticFieldValue?.short_shipments_and_penalty,
          other_conditions: StaticFieldValue?.other_conditions,
          delivery_terms: StaticFieldValue?.delivery_terms,
        },
        () => {
          this.clearSurveyFields();
        }
      );
    }
    this.setState({
      openDialog,
      onlyDialog,
      onlyUi,
      poItemsArray,
      poItemsArrayCopy,
      specificationHeader: [
        {
          name: "Type",
          type: "select",
          dataType: "text",
          api: "name",
          array: next.specifiationTypes
            ? next.specifiationTypes.map((s) => {
                return { ...s, name: s.text };
              })
            : [],
          value: "name",
          filter: (array, specificationArr) => {
            return (
              array &&
              array.length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ) &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              ).length > 0 &&
              array.filter(
                (ab) =>
                  !filterData(specificationArr, "name", ab.name, "onlyOne")
              )
            );
          },
        },
        {
          name: "Condition",
          type: "select",
          dataType: "text",
          api: "condition",
          array: MaxMin,
          value: "name",
        },
        {
          name: "Value",
          type: "textField",
          dataType: "number",
          api: "value",
        },
      ],
      PortsList:
        PortsList && PortsList.length > 0
          ? PortsList.map((sP) => {
              sP.nameCity = `${sP.name} - ${sP.city}`;
              return sP;
            })
              .concat({
                nameCity: "Any Indian Port",
                name: "Any Indian Port",
                id: "Any Indian Port",
              })
              .sort((a, b) => a.nameCity.localeCompare(b.nameCity))
          : [
              {
                nameCity: "Any Indian Port",
                name: "Any Indian Port",
                id: "Any Indian Port",
              },
            ],
    });
    if (openDialog !== this.state.openDialog) {
      this.setState({
        defaultStep: 0,
        currentStep: 0,
        submittedSpecifies: {},
      });
    }
  };
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  UNSAFE_componentWillReceiveProps(next) {
    console.log(next);
    this.setInitialValues(next);
  }

  componentDidMount() {
    this.showSnackbar = this.context;
  }

  handleShortCloseDialog = () => {
    this.setState({ shortCloseDialog: false });
  };

  handleClosePo = () => {
    this.setState({ shortCloseDialog: false });
    this.props
      .PurchaseOrdersFunction(
        "patch",
        "dispatch/purchase-order",
        this.props.singlelistView.id,
        null,
        { status: "CLOSED" },
        null,
        "noeditparam"
      )
      .then((res) => {
        console.log(res);
        if (res.error) {
          this.showSnackbar(res.data, "error");
        } else {
          this.showSnackbar("Purchase Order Closed Successfully", "success");
          this.props.PurchaseOrdersFunction(
            "get",
            EndPoints["PurchaseOrders"],
            null,
            null,
            null
          );
        }
      });
  };

  handleCancelPo = () => {
    this.setState({ cancelPO: false });
    this.props
      .PurchaseOrdersFunction(
        "patch",
        "dispatch/purchase-order",
        this.props.singlelistView.id,
        null,
        { status: "CANCELLED" },
        null,
        "noeditparam"
      )
      .then((res) => {
        console.log(res);
        if (res.error) {
          this.showSnackbar(res.data, "error");
        } else {
          this.showSnackbar("Purchase Order Cancelled Successfully", "success");
          this.props.PurchaseOrdersFunction(
            "get",
            EndPoints["PurchaseOrders"],
            null,
            null,
            null
          );
        }
      });
  };

  handleRequiredToggleChange = (e) => {
    this.setState({ requiredToggle: e.target.checked }, () => {
      if (this.state.requiredToggle) {
        const errorMsg = this.state.errorMsg;
        errorMsg.selctedPOStatus = false;
        this.setState({ errorMsg, selctedPOStatus: "DRAFT" });
      } else {
        const errorMsg = this.state.errorMsg;
        errorMsg.selctedPOStatus = false;
        this.setState({ errorMsg, selctedPOStatus: "OPEN" });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log(
      prevProps,
      this.props,
      this.props.singlelistView,
      prevProps.singlelistView !== this.props.singlelistView
    );
    if (prevProps.singlelistView !== this.props.singlelistView) {
      this.setInitialValues(this.props);
    }
  }

  handleCreatedEditedItemList = (data) => {
    console.log(data);
    this.setState({
      loosePoItem: data,
    });
  };

  handleSaveEditFetchApi = (operation, POStatus) => {
    let poID = this.state?.editDialog.id;
    this.showSnackbar(
      `Purchase Order ${
        operation === "add" ? "Created" : "Updated"
      } Successfully`,
      "success"
    );
    this.props.closeDialog();
    this.handleClearFields();
    if(poID){
      this.props
        .PurchaseOrdersFunction(
          "get",
          `dispatch/purchase-order/${poID}`,
          null,
          null,
          null,
          null
        )
        .then((resp) => {
          localStorage.setItem("selected-po", resp);
          this.props.updateSelectedTabData(
            resp,
            resp.id,
            window.location.pathname.split("/")[3],
            "",
            operation ==='add' ? 'create' : 'edit'
          );
        });
      }
      else{
        console.log("hereLOOSEPO", POStatus)
        this.props.updateSelectedTabData(
         {status: POStatus},
          '',
          '',
          "",
          'create'
        );
      }
  };

  handleFieldErrorCheck = () => {
    const {
      selctedPOStatus,
      MillerNameId,
      contractTypeId,
      BorkerNameId,
      inlandGodown,
      Unit,
    } = this.state;

    const errorMsg = this.state.errorMsg;
    let hasError = false;

    if (!MillerNameId) {
      errorMsg.MillerName = "Please give a Miller Name!";
      hasError = true;
    }

    if (!contractTypeId) {
      errorMsg.contractTypeName = "Please select a Contract Type!";
      hasError = true;
    }

    if (!Unit) {
      errorMsg.Unit = "Please select a Unit!";
      hasError = true;
    }

    if (selctedPOStatus !== "DRAFT") {
      if (!BorkerNameId) {
        errorMsg.BorkerName = "Please select a Broker Name!";
        hasError = true;
      }

      if (!inlandGodown || inlandGodown?.length === 0) {
        errorMsg.inlandGodown = "Please select at least one Inland Godown!";
        hasError = true;
      }
    }

    this.setState({ errorMsg });
    return hasError;
  };

  handleCreateEditLoosePO = (operation) => {
    const {
      searchedData,
      MillerNameId,
      selctedPOStatus,
      BorkerNameId,
      PaymentTermId,
      Unit,
      Remark,
      delivery_terms,
      delivery_terms_extras,
      poNum,
      Acceptance,
      Eway,
      Loading,
      Cancellation,
      packing_condition,
      brokerage_commission,
      short_shipments_and_penalty,
      other_conditions,
      contractTypeId,
      inlandGodown,
      BorkerageId,
      last_survey,
      loosePoItem,
      editDialog,
    } = this.state;

    if (this.handleFieldErrorCheck()) {
      return;
    } else {
      console.log("loosePOFn");
      let purchase_order = {};
      let po_items = [];
      let oldPoItem = [];
      let newPoItem = [];

      purchase_order["contract_date"] =
        moment(searchedData).format("YYYY-MM-DD");
      purchase_order["miller"] = Number(MillerNameId);
      purchase_order["status"] = selctedPOStatus;
      purchase_order["broker"] = BorkerNameId;
      purchase_order["payment_terms"] = PaymentTermId;
      purchase_order["unit"] = Unit;
      purchase_order["sales_order"] = null;
      purchase_order["remark"] = Remark;
      purchase_order["delivery_terms"] = Boolean(delivery_terms)
        ? delivery_terms
        : "";
      purchase_order["delivery_terms_extras"] = Boolean(delivery_terms_extras)
        ? delivery_terms_extras
        : "";
      purchase_order["po_number"] = poNum;
      purchase_order["acceptance_of_purchase_order"] = Boolean(Acceptance)
        ? Acceptance
        : "";
      purchase_order["eway_bill"] = Boolean(Eway) ? Eway : "";
      purchase_order["loading"] = Boolean(Loading) ? Loading : "";
      purchase_order["contract_cancellation"] = Boolean(Cancellation)
        ? Cancellation
        : "";
      purchase_order["packing_condition"] = Boolean(packing_condition)
        ? packing_condition
        : "";
      purchase_order["brokerage_commission"] = Boolean(brokerage_commission)
        ? brokerage_commission
        : "";
      purchase_order["short_shipments_and_penalty"] = Boolean(
        short_shipments_and_penalty
      )
        ? short_shipments_and_penalty
        : "";
      purchase_order["other_conditions"] = Boolean(other_conditions)
        ? other_conditions
        : "";
      purchase_order["contract_type"] = Number(contractTypeId);
      purchase_order["inland_godowns"] = inlandGodown.map((ig) => {
        return ig.id;
      });
      purchase_order["brokerage"] = BorkerageId;
      purchase_order["last_survey"] = last_survey
        ? moment(last_survey).format("YYYY-MM-DD")
        : null;
      // purchase_order["total_quantity"] = loosePoItem.reduce(
      //   (prev, curr) => Number(prev) + Number(curr["Total Item Quantity"]),
      //   0
      // );
      console.log("loosePoItem", loosePoItem);
      purchase_order["total_po_quantity"] = loosePoItem.reduce(
        (prev, curr) => Number(prev) + Number(curr["Total Item Quantity"]),
        0
      );

      if (operation === "add") {
        purchase_order["approval_status"] =
          this.props?.user?.roles.toString().toLowerCase() === "admin"
            ? "ACCEPTED"
            : "PENDING";
        purchase_order["is_active"] = true;
        purchase_order["dispatched_quantity"] = 0;
        purchase_order["remaining_quantity"] = loosePoItem.reduce(
          (prev, curr) => Number(prev) + Number(curr["Total Item Quantity"]),
          0
        );
      }

      loosePoItem?.length &&
        loosePoItem.forEach((item) => {
          let Obj = {};

          Obj["soitem_id"] = null;
          Obj["item_category"] = item["Item Category_data"]?.id || null;
          Obj["item_type"] = item["Item Type_data"]?.id || null;
          Obj["hsn_code"] = item["HSN Code_data"]?.id || null;
          Obj["total_quantity"] = item["Total Item Quantity"] || 0;
          Obj["contract_rate"] = item["Contract Rate"] || 0;
          Obj["sales_tax_terms"] = item["Sales Tax Terms"] || "";
          if (operation === "add" && item["Specifications_data"]?.length) {
            Obj["new_specifications"] = item["Specifications_data"].map(
              (spec) => {
                return {
                  name: spec.name,
                  condition: spec.condition,
                  value: spec.value,
                  po_items: "",
                };
              }
            );

            Obj["specifications"] = item["Specifications_data"].map((spec) => {
              return {
                name: spec.name,
                condition: spec.condition,
                value: spec.value,
                po_items: "",
              };
            });
          }

          if (operation === "edit") {
            if (item.id) {
              Obj["id"] = item.id;
            } else {
              Obj["id"] = null;
            }
            Obj["purchase_order"] = editDialog.id;
            Obj["dispatched_quantity"] = 0;
            if (item["Specifications_data"]?.length) {
              Obj["specifications"] = [];
              Obj["new_specifications"] = [];

              item["Specifications_data"].forEach((spec) => {
                const specObj = {
                  name: spec.name,
                  condition: spec.condition,
                  value: spec.value,
                  po_items: item.id,
                };

                if (spec.id) {
                  specObj.id = spec.id;
                  Obj["specifications"].push(specObj);
                } else {
                  Obj["new_specifications"].push(specObj);
                }
              });
            }
          }

          console.log(
            "operation",
            operation,
            item.id,
            Obj,
            po_items,
            oldPoItem,
            newPoItem
          );
          if (operation === "add") {
            po_items.push(Obj);
          } else if (operation === "edit") {
            if (item.id) {
              oldPoItem.push(Obj);
            } else {
              newPoItem.push(Obj);
            }
          }
        });

      let payload = {
        purchase_order,
        po_items,
      };

      this.setState({ submitButtonClicked: true });

      this.props
        .PurchaseOrdersFunction(
          operation === "edit" ? "patch" : "post",
          operation === "edit"
            ? "dispatch/purchase-order"
            : "dispatch/purchase-order/create",
          operation === "edit" ? editDialog.id : null,
          null,
          operation === "edit" ? payload?.purchase_order : payload,
          null,
          "noeditparam"
        )
        .then((res) => {
          console.log("OP-", operation, oldPoItem, newPoItem);
          this.setState({
            loadingFun: false,
            submitButtonClicked: false,
          });
          if (res.error) {
            this.showSnackbar(res.data, "error");
          } else {
            if (operation === "edit" && oldPoItem.length) {
              this.props
                .PurchaseOrdersFunction(
                  "put",
                  "dispatch/po-items/bulk-update",
                  null,
                  null,
                  oldPoItem
                )
                .then((res2) => {
                  this.setState({
                    loadingFun: false,
                  });
                  if (res2.error) {
                    this.showSnackbar(res2.data, "error");
                  } else {
                    if (newPoItem.length) {
                      this.props
                        .PurchaseOrdersFunction(
                          "post",
                          "dispatch/po-items/bulk-create",
                          null,
                          null,
                          newPoItem
                        )
                        .then((res3) => {
                          if (res3.error) {
                            this.showSnackbar(res3.data, "error");
                          } else {
                            // fetch list -edit
                            this.handleSaveEditFetchApi(operation, selctedPOStatus);
                          }
                        });
                    } else {
                      // fetch list -edit
                      this.handleSaveEditFetchApi(operation, selctedPOStatus);
                    }
                  }
                });
            } else {
              // fetch list -add
              this.handleSaveEditFetchApi(operation,selctedPOStatus);
            }
          }
        });
    }
  };

  render() {
    const {
      singlelistView,
      poItemsArrayTOPreview,
      poItemsArrayTOPreviewCopy,
      POITemTable,
      PaymentTermList,
      status,
      unit,
      ContractTypeList,
      InlandGodownList,
      BrokerList,
      MillerList,
      SalesOrderList,
      BrandList,
      ItemCateoryList,
      ItemList,
      PackagingList,
      HSNlist,
      poItemheader,
      selctedStatus,
      selectedMonth,
      noTotalQuantity,
      poItemsArray,
      poItemsArrayCopy,
      showTotal,
    } = this.props;
    const {
      PortsList,
      specificationHeader,
      openDialog,
      editDialog,
      loadingFun,
      success,
      error,
      added,
      calenderOpenLS,
      errorMsg,
      selctedPOStatus,
      Unit,
      contractTypeName,
      inlandGodown,
      contractTypeId,
      Borkerage,
      BorkerName,
      BorkerNameId,
      BorkerageId,
      portArry,
      Miller,
      MillerNameId,
      MillerName,
      MillerAddress,
      MillerGST,
      ContactPerson,
      last_survey,
      SalesOrder,
      SalesOrderId,
      BrandName,
      selectedBrandMultipleName,
      ItemCategory,
      ItemType,
      packagingArr,
      editSelectedItemPackagingArr,
      total_quantity,
      HSN,
      HSN_id,
      Rate,
      BrandNameId,
      itemsId,
      ItemCategoryId,
      calenderOpen,
      searchedData,
      selectedPoid,
      PaymentTerm,
      PaymentTermId,
      Remark,
      poNum,
      onlyDialog,
      onlyUi,
      specificationArr,
      specificationArrCopy,
      confirmDel,
      pushNoti,
      submittedSpecifies,
      confirmAddWithoutSpecification,
      moveToShipHeader2,
      soitemsList,
      submittedData,
      selectedSOItems,
      submittedItemData,
      confirmApp,
      Acceptance,
      Eway,
      Loading,
      Cancellation,
      sales_tax_terms,
      packing_condition,
      brokerage_commission,
      short_shipments_and_penalty,
      other_conditions,
      delivery_terms_extras,
      delivery_terms,
      shortCloseDialog,
      cancelPO,
      requiredToggle,
      loosePoItem,
    } = this.state;
    var tableLen = this.props.poItemsArrayTOPreviewCopy.length;
    console.log(editDialog,InlandGodownList, "editdialog");
    const steps = ["Add Item Details", "Add Multiple Specifications"];
    console.log(poNum, "po number");
    console.log(packagingArr, "packkkk");
    console.log(confirmDel, "deleteee");
    const handleConfirm = () => {
      this.setState({
        pushNoti: true,
      });
      this.props
        .PurchaseOrdersFunction(
          "delete",
          "dispatch/purchase-order/" + singlelistView.id,
          null,
          singlelistView.id,
          null
        )
        .then((res) => {
          console.log(res, "resssss");
          if (res === "Purchase order has been deleted") {
            this.setState({
              // pushNoti: true,
              snackDel: "Purchase Order Deleted",
            });
            this.props.PurchaseOrdersFunction(
              "get",
              EndPoints["PurchaseOrders"],
              null,
              null,
              null
            );
          }
          if (res.toString()[0] === "E") {
            console.log(pushNoti, "zzzzzzzz");
            this.setState({
              pushNoti: true,
              confirmDel: false,
              snackDel: "Purchase Order cannot be Deleted",
            });
          }
        });
    };
    const handleConfirmApp = () => {
      this.setState({
        // pushNoti: true,
      });
      this.props
        .PurchaseOrdersFunction(
          "patch",
          "dispatch/purchase-order",
          singlelistView.id,
          null,
          { approval_status: this.state.approve ? "ACCEPTED" : "REJECTED" },
          null,
          "noeditparam"
        )
        .then((res) => {
          console.log(res, "resssss");
          this.setState({ confirmApp: false });
          if (res === "Purchase order has been Approved") {
            this.setState({
              // pushNoti: true,
              confirmApp: false,
              // approve: false,
              snackDel: "Purchase Order approved",
            });
          }
          if (res.toString()[0] === "E") {
            console.log(pushNoti, "zzzzzzzz");
            this.setState({
              // pushNoti: true,
              confirmApp: false,
              approve: false,
              reject: false,
              snackDel: "Purchase Order rejected",
            });
          }

          this.showSnackbar(
            `Purchase Order ${
              this.state.approve ? "Approved" : "Rejected"
            } Successfully`,
            "success"
          );

          this.props
            .PurchaseOrdersFunction(
              "get",
              `dispatch/purchase-order/${singlelistView.id}`,
              null,
              null,
              null,
              null
            )
            .then((resp) => {
              localStorage.setItem("selected-po", resp);
              this.props.updateSelectedTabData(
                resp,
                resp.id,
                window.location.pathname.split("/")[3],
                "",
                "edit"
              );
            });
        });
    };
    const scrollDiv = (id) => {
      const chat = document.getElementById(id);
      if (chat) {
        chat.scrollIntoView();
      }
    };
    const editFunct = (singleDate) => {
      console.log("edit");
      const newArr = [];
      poItemsArrayCopy.map((singleItem) => {
        if (singleItem.po_item_id === singleDate.po_item_id) {
        } else {
          newArr.push(singleItem);
        }
        return singleItem;
      });
      this.props.setPoItems(null, newArr);
      console.log(singleDate, "singleDate", this.props);
      this.setState(
        {
          defaultStep: 0,
          currentStep: 1,
          specificationArr: singleDate["specificationArr"]
            ? singleDate["specificationArr"].map((ss) => {
                const specId = Number(ss.nameId ?? ss.name);
                return ss["conditionId"]
                  ? {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    }
                  : {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      Condition: getSpecificationId(ss["condition"]),

                      conditionId: ss["condition"],
                      ConditionId: ss["condition"],
                      condition: getSpecificationId(ss["condition"]),
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    };
              })
            : [],
          specificationArrCopy: singleDate["specificationArr"]
            ? singleDate["specificationArr"].map((ss) => {
                const specId = Number(ss.nameId ?? ss.name);
                return ss["conditionId"]
                  ? {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    }
                  : {
                      ...ss,
                      name: this.props.specifiationTypes.find(
                        (st) => st.id === specId
                      )
                        ? this.props.specifiationTypes.find(
                            (st) => st.id === specId
                          ).text
                        : "",

                      nameId: specId,
                      Condition: getSpecificationId(ss["condition"]),
                      conditionId: ss["condition"],
                      ConditionId: ss["condition"],
                      condition: getSpecificationId(ss["condition"]),
                      lid: ss.lid || ss.lid === 0 ? ss.lid : ss.id,
                    };
              })
            : [],
          selectedPoid: singleDate.po_item_id,
          itemsId: singleDate["Item Typeid"],
          ItemType: singleDate["Item Type"],
          ItemCategoryId: singleDate["Item Categoryid"],
          ItemCategory: singleDate["Item Category"],
          packagingArr: singleDate.packagingArr,
          editSelectedItemPackagingArr: singleDate.packagingArr.filter(
            (d) => d.used_bags
          ),
          BrandNameId: singleDate["Brandid"],
          BrandName: singleDate["Brand"],
          HSN_id: singleDate.hsn_code,
          HSN: singleDate["HSN Code"],
          total_quantity: singleDate["Total Item Quantity"],
          Rate: singleDate["Contract Rate"],
          sales_tax_terms:
            singleDate.sales_tax_terms || singleDate["Sales Tax Terms"],
          selectedBrandMultipleName: Boolean(
            singleDate.brand_details?.item_category
          )
            ? `${singleDate["Brand"]}-${
                filterData(
                  ItemCateoryList,
                  "id",
                  singleDate.brand_details?.item_category,
                  "onlyOne"
                )
                  ? filterData(
                      ItemCateoryList,
                      "id",
                      singleDate.brand_details?.item_category,
                      "onlyOne"
                    ).name
                  : ""
              }-${
                filterData(
                  ItemList,
                  "id",
                  singleDate.brand_details?.item_type,
                  "onlyOne"
                )
                  ? filterData(
                      ItemList,
                      "id",
                      singleDate.brand_details?.item_type,
                      "onlyOne"
                    )?.broken_percentage
                  : ""
              } %`
            : `${singleDate["Brand"]}`,
          isItemRowEdit: true,
        },
        () => {}
      );
    };
    const colorArr = [white, lightGrey];
    const addItemsFunction = () => {
      this.setState({ isItemRowEdit: false });
      console.log(sales_tax_terms);
      // this.setState({
      //   defaultStep: 0,
      //   currentStep: 0,
      //   errorMsg: {},
      //   submittedSpecifies: {},
      // });
      const newpoItemsArray = poItemsArrayCopy;
      const addData = {
        packaging: packagingArr.map((sP) => {
          return Number(sP.id);
        }),
        brand: BrandNameId,
        packagingArr: packagingArr,
        broker: BorkerNameId,
        hsn_code: HSN_id,
        item_type: itemsId,
        item_category: ItemCategoryId,
        total_quantity: total_quantity,
        sales_tax_terms: sales_tax_terms,
      };
      console.log(addData.packaging, "ppppppp");
      addData[poItemheader[1]] = ItemCategory;
      addData[`${poItemheader[1]}id`] = ItemCategoryId;
      addData[poItemheader[2]] = ItemType;
      addData[`${poItemheader[2]}id`] = itemsId;
      addData[poItemheader[0]] = BrandName;
      addData[`${poItemheader[0]}id`] = BrandNameId;
      addData[poItemheader[3]] = packagingArr
        .map((sP) => {
          return `${sP.size} ${sP.unit}`;
        })
        .join(" ,");
      addData[`${poItemheader[3]}Id`] = packagingArr.map((sP) => {
        sP.sizeUnit = `${sP.size} ${sP.unit}`;
        return sP;
      });
      addData[`packagageArrUsed`] = packagingArr.map((sP) => {
        sP.sizeUnit = `${sP.size} ${sP.unit}`;
        return sP;
      });
      addData[poItemheader[4]] = HSN;
      addData[`${poItemheader[4]}id`] = HSN_id;
      addData[poItemheader[5]] = total_quantity;
      addData[`${poItemheader[5]}Unit`] = `${total_quantity} ${
        Unit ? Unit : ""
      }`;

      addData[poItemheader[6]] = Rate;
      console.log(specificationArr, "specificationArray");
      addData["specificationArr"] = specificationArr;
      addData["Specifications"] = specificationArr;
      addData["specifications"] = specificationArr;
      addData[`${poItemheader[6]}Unit`] = `${Rate}/${Unit ? Unit : ""}`;
      addData.po_item_id = selectedPoid
        ? selectedPoid
        : newpoItemsArray.length + 1;
      addData.id = selectedPoid ? selectedPoid : newpoItemsArray.length + 1;
      addData.selectedBrandMultipleName = selectedBrandMultipleName
        ? selectedBrandMultipleName
        : "";
      const newArr = [];

      if (newpoItemsArray.length > 0) {
        newpoItemsArray.map((s) => {
          console.log(s.id, addData.id, s, addData, "UPDATEITEM");
          if (s.id === addData.id) {
            console.log("1", addData);
            newArr.push(addData);
          } else {
            console.log(s, "2 same add data");
            newArr.push(s);
          }
          return s;
        });
        if (selectedPoid === false) {
          console.log("3", addData);
          newArr.push(addData);
        }
      } else {
        console.log("4 first time item add", addData);
        newArr.push(addData);
      }
      this.props.setPoItems(newArr, newArr);
      this.setState({
        itemsId: "",
        ItemType: "",
        ItemCategoryId: "",
        ItemCategory: "",
        BrandNameId: "",
        BrandName: false,
        selectedBrandMultipleName: false,
        HSN_id: "",
        HSN: "",
        total_quantity: "",
        selectedPoid: false,
        Rate: "",
        specificationArr: [],
        specificationArrCopy: [],
        defaultStep: 0,
        currentStep: 0,
        packagingArr: [],
        sales_tax_terms: "",
      });
    };
    const SpecificationTable = () => {
      return (
        <div className="d-flex width-100 flexWrap justifyC">
          <div className="width-100 " style={{ margin: "20px 10px" }}>
            <div
              style={{
                backgroundColor: "#8080801a",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}
            >
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgb(128 128 128 / 64%)" }}
              >
                {specificationHeader.map((sC) => (
                  <div
                    key={sC.name}
                    className="justifyC d-flex alignC"
                    style={{
                      width: "calc(25% - 54px)",
                      margin: "12px 10px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}
                  >
                    {sC.name}
                  </div>
                ))}{" "}
                <div
                  className="justifyC d-flex alignC"
                  style={{
                    margin: "12px 5px",
                    width: "82px",
                    display: "flex",
                    alignItems: "center",
                    color: "#100f0f",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </div>
              </div>
            </div>
            <div
              style={{
                height: "fit-content",
                width: "100%",
                minWidth: "100%",
                position: "relative",
                borderRadius: "0 0 7px 7px",
                borderRight: "1px solid rgba(128, 128, 128, 0.64)",
                borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
                borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
                padding: "5px 0",
              }}
            >
              {specificationArr &&
                specificationArr.map((sA, i) => (
                  <div
                    className={`dividerBorder d-flex width-100 justifySE `}
                    style={{
                      backgroundColor:
                        colorArr && colorArr[i % colorArr.length],
                      height: "44px",
                    }}
                  >
                    {specificationHeader.map((sC) => (
                      <div
                        key={sC.name}
                        className="justifyC d-flex alignC"
                        style={{
                          width: "calc(25% - 54px)",
                          margin: "12px 10px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}
                      >
                        {sA[sC.api]
                          ? `${sA[sC.api]} ${
                              sC.name === "Value" &&
                              this.props.specifiationTypes.find(
                                (st) => st.text === sA["name"]
                              )
                                ? this.props.specifiationTypes.find(
                                    (st) => st.text === sA["name"]
                                  ).unit
                                : ""
                            }`
                          : sC.api === "name"
                          ? `${sA["name"] ?? sA["Type"]}`
                          : "-"}
                      </div>
                    ))}{" "}
                    <div
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "10px",
                        width: "82px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        const submittedData = sA;
                        specificationHeader.map((singleInput) => {
                          submittedData[singleInput.name] = sA[singleInput.api];
                          submittedData[`${singleInput.name}Id`] =
                            sA[`${singleInput.api}Id`];

                          this.setState({
                            errorMsg: {
                              ...errorMsg,
                              [singleInput.name]: false,
                            },
                          });
                          return singleInput;
                        });

                        const arr = [];
                        specificationArrCopy.map((s) => {
                          if (sA.lid === s.lid) {
                          } else {
                            arr.push(s);
                          }
                          return s;
                        });
                        console.log(arr, "arr");
                        this.setState({
                          submittedSpecifies: {
                            ...submittedData,
                            lid:
                              sA.lid || sA.lid === 0
                                ? sA.lid
                                : specificationArrCopy.length + 1,
                          },
                          specificationArr: arr,

                          errorMsg: { ...errorMsg, addSpecification: false },
                        });
                      }}
                    >
                      <StyledButton
                        name={<EditIcon />}
                        toolTip="Edit"
                        maxHeight="24px"
                        padding="0"
                        height="24px"
                        width="40px"
                        minWidth="0"
                      />
                    </div>
                  </div>
                ))}{" "}
              <div
                className="d-flex width-100 justifySE"
                style={{
                  backgroundColor:
                    colorArr &&
                    colorArr[specificationArr.length % colorArr.length],
                  minHeight: "60px",
                }}
              >
                {specificationHeader.map((singleInput, i) =>
                  singleInput.type === "select" ? (
                    <CssTextField95
                      required={
                        singleInput.name === "Condition" &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? false
                          : true
                      }
                      error={errorMsg[singleInput.name] ? true : false}
                      autoComplete="off"
                      disabled={
                        singleInput.name === "Condition" &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? true
                          : false
                      }
                      value={
                        submittedSpecifies[singleInput.name]
                          ? submittedSpecifies[singleInput.name]
                          : ""
                      }
                      style={{ width: "calc(25% - 54px)", margin: "10px" }}
                      select
                      label={
                        singleInput.name === "Condition" &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? "No Condition"
                          : singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                      }
                      SelectProps={{
                        disableunderline: "true",
                        MenuProps: MenuProps,
                      }}
                      variant="outlined"
                      onChange={(e) => {
                        if (e.target.value) {
                          const id = `${singleInput.name}Id`;
                          this.setState(
                            singleInput.name === "Condition"
                              ? {
                                  submittedSpecifies: {
                                    ...submittedSpecifies,
                                    [singleInput.name]: e.target.value,
                                    [id]: e.currentTarget.id,
                                  },
                                }
                              : {
                                  submittedSpecifies: {
                                    ...submittedSpecifies,
                                    [singleInput.name]: e.target.value,
                                    [id]: e.currentTarget.id,
                                    Condition:
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ) &&
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ).value_type === "text"
                                        ? ""
                                        : "",
                                    ConditionId:
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ) &&
                                      this.props.specifiationTypes.find(
                                        (st) => st.text === e.target.value
                                      ).value_type === "text"
                                        ? ""
                                        : "",
                                  },
                                  errorMsg: {
                                    ...errorMsg,
                                    [singleInput.name]: false,
                                  },
                                }
                          );
                        }
                      }}
                    >
                      {singleInput &&
                      singleInput.array &&
                      singleInput.array.length > 0 ? (
                        singleInput.filter ? (
                          singleInput.filter(
                            singleInput.array,
                            specificationArr
                          ) &&
                          singleInput.filter(
                            singleInput.array,
                            specificationArr
                          ).length > 0 ? (
                            singleInput
                              .filter(singleInput.array, specificationArr)
                              .map((option) => (
                                <MenuItem
                                  style={{ textTransform: "capitalize" }}
                                  key={option[singleInput.value]}
                                  value={option[singleInput.value]}
                                  id={
                                    option.id
                                      ? option.id
                                      : option[singleInput.value]
                                  }
                                >
                                  {singleInput.endAdornment
                                    ? `${option[singleInput.value]} ${
                                        singleInput.endAdornment
                                      }`
                                    : option[singleInput.value]}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={`No type is remaining.`}
                              value={`No type is remaining.`}
                              id={`No type is remaining.`}
                            >
                              {`No type is remaining.`}
                            </MenuItem>
                          )
                        ) : (
                          singleInput.array.map((option) => (
                            <MenuItem
                              style={{ textTransform: "capitalize" }}
                              key={option[singleInput.value]}
                              value={option[singleInput.value]}
                              id={
                                option.id
                                  ? option.id
                                  : option[singleInput.value]
                              }
                            >
                              {singleInput.endAdornment
                                ? `${option[singleInput.value]} ${
                                    singleInput.endAdornment
                                  }`
                                : option[singleInput.value]}
                            </MenuItem>
                          ))
                        )
                      ) : (
                        <MenuItem
                          disabled={true}
                          key={`No ${singleInput.name} added yet`}
                          value={`No ${singleInput.name} added yet`}
                          id={`No ${singleInput.name} added yet`}
                        >
                          {`No ${singleInput.name} added yet`}
                        </MenuItem>
                      )}
                    </CssTextField95>
                  ) : (
                    <CssTextField95
                      autoFocus={singleInput.autoFocus ? true : false}
                      error={errorMsg[singleInput.name] ? true : false}
                      autoComplete="off"
                      InputProps={{
                        endAdornment:
                          this.props.specifiationTypes &&
                          submittedSpecifies["Type"]
                            ? this.props.specifiationTypes.find(
                                (st) => st.text === submittedSpecifies["Type"]
                              )
                              ? this.props.specifiationTypes.find(
                                  (st) => st.text === submittedSpecifies["Type"]
                                ).unit
                              : ""
                            : "",
                      }}
                      onFocus={StopScroll}
                      style={{ width: "calc(25% - 54px)", margin: "10px" }}
                      id="outlined-basic"
                      label={
                        singleInput.name.charAt(0).toUpperCase() +
                        singleInput.name.slice(1)
                      }
                      type={
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ) &&
                        this.props.specifiationTypes.find(
                          (st) => st.text === submittedSpecifies["Type"]
                        ).value_type === "text"
                          ? "text"
                          : singleInput.dataType
                      }
                      value={
                        submittedSpecifies[singleInput.name]
                          ? submittedSpecifies[singleInput.name]
                          : ""
                      }
                      name={singleInput.name}
                      onKeyDown={(evt) => {
                        if (
                          this.props.specifiationTypes.find(
                            (st) => st.text === submittedSpecifies["Type"]
                          ).value_type === "text"
                        ) {
                        } else {
                          if (singleInput.only_number === true) {
                            evt.key === "." && evt.preventDefault();
                          }
                          if (singleInput.dataType === "number") {
                            evt.key === "e" && evt.preventDefault();

                            evt.key === "E" && evt.preventDefault();
                            evt.key === "-" && evt.preventDefault();
                            evt.keyCode === 38 && evt.preventDefault();
                            evt.keyCode === 40 && evt.preventDefault();
                          }
                        }
                      }}
                      onInput={(e) => {
                        if (singleInput.only_number === true) {
                          e.target.value = e.target.value
                            .replace(/[^\w]|_/g, "")
                            .toLowerCase();
                        }
                      }}
                      onChange={(event) => {
                        console.log(
                          this.props.specifiationTypes,
                          submittedSpecifies["Type"],
                          "this.props.specifiationTypes&&submittedSpecifies"
                        );
                        this.setState({
                          submittedSpecifies: {
                            ...submittedSpecifies,
                            [singleInput.name]:
                              event.target.value && singleInput.upperCase
                                ? event.target.value.toUpperCase()
                                : event.target.value,
                          },
                          errorMsg: { ...errorMsg, [singleInput.name]: false },
                        });
                      }}
                      variant="outlined"
                      // className="textC"
                    />
                  )
                )}
                <div
                  className="justifySA d-flex"
                  style={{
                    margin: "10px",
                    width: "82px",
                    display: "flex",
                    color: "#100f0f",
                    textTransform: "capitalize",
                  }}
                >
                  <StyledButton
                    onClick={() => {
                      const newObj = submittedSpecifies;
                      const correct = [];
                      const er = { ...errorMsg };
                      specificationHeader.map((singleInput, i) => {
                        if (
                          singleInput.name === "Condition" &&
                          this.props.specifiationTypes.find(
                            (st) => st.text === submittedSpecifies["Type"]
                          ) &&
                          this.props.specifiationTypes.find(
                            (st) => st.text === submittedSpecifies["Type"]
                          ).value_type === "text"
                            ? true
                            : submittedSpecifies[singleInput.name]
                        ) {
                          correct.push(singleInput);
                          newObj[singleInput.api] =
                            submittedSpecifies[singleInput.name];
                          newObj[`${singleInput.api}Id`] =
                            submittedSpecifies[`${singleInput.name}Id`];
                          if (i === 1 && submittedSpecifies[singleInput.name]) {
                            newObj["Condition"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`ConditionId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                          }
                          if (i === 0) {
                            newObj["Type"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`TypeId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                            newObj["name"] =
                              submittedSpecifies[singleInput.name];
                            newObj[`nameId`] =
                              submittedSpecifies[`${singleInput.name}Id`];
                          }
                        } else {
                          er[singleInput.name] = `Please add !`;
                        }
                      });
                      if (correct.length === 3) {
                        const arr = specificationArr;
                        console.log(submittedSpecifies, "submittedSpecifies");
                        arr.push({
                          ...newObj,
                          lid:
                            submittedSpecifies.lid ||
                            submittedSpecifies.lid === 0
                              ? submittedSpecifies.lid
                              : specificationArrCopy.length + 1,
                        });
                        console.log(arr, "arrarr");
                        this.setState({
                          specificationArr: arr,
                          specificationArrCopy: arr,
                          submittedSpecifies: {},
                          errorMsg: {},
                        });
                      } else {
                        this.setState({ errorMsg: er });
                      }
                    }}
                    background={
                      errorMsg["addSpecification"] === true ? red : ""
                    }
                    withBg={errorMsg["addSpecification"] === true}
                    name={<Add />}
                    toolTip="Add"
                    maxHeight="37.99px"
                    padding="0"
                    height="37.99px"
                    width="40px"
                    minWidth="0"
                  />{" "}
                  <StyledButton
                    name={<CrossIcon color={primary} strokeWidth="2" />}
                    toolTip="Clear"
                    maxHeight="37.99px"
                    onClick={() => {
                      this.setState({
                        requiredToggle: false,
                      });
                      if (
                        submittedSpecifies &&
                        (submittedSpecifies["Type"] ||
                          submittedSpecifies["Condition"] ||
                          submittedSpecifies["Value"])
                      ) {
                        this.setState({ submittedSpecifies: {}, errorMsg: {} });
                      } else {
                        this.setState({
                          error: "Nothing to Clear",
                          errorMsg: {},
                        });
                      }
                    }}
                    padding="0"
                    margin="0 0 0 2px"
                    height="37.99px"
                    width="40px"
                    minWidth="0"
                  />
                </div>
              </div>
            </div>
          </div>{" "}
          <div
            id="update-button"
            className="d-flex alignC justifyFE width-100"
            style={{
              margin: "10px",
              // width: "calc(25% - 20px)",
            }}
          >
            {/* {confirmAddWithoutSpecification === true ? (
              <ConfirmDialog
                onSubmit={(e) => {
                  e.stopPropagation();
                  addItemsFunction();
                  this.setState({ confirmAddWithoutSpecification: false });
                }}
                resetText={"No"}
                reset={() => {
                  this.setState({
                    confirmAddWithoutSpecification: false,
                    errorMsg: { ...errorMsg, addSpecification: true },
                  });
                }}
                height="300px"
                width="250px"
                action={"Yes"}
                setOpenDialog={() => {
                  this.setState({ confirmAddWithoutSpecification: false });
                }}
                openDialog={
                  confirmAddWithoutSpecification !== false ? true : false
                }
                content={() => {
                  return (
                    <div className="width-100 height-100">
                      <HeadingLine
                        maxWidth="100%"
                        width="100%"
                        subHeading={true}
                        children={`You are adding items without any specifications!`}
                        margin="0 0 10px"
                        // withBg={true}
                      />
                    </div>
                  );
                }}
              />
            ) : (
              ""
            )} */}
            <StyledButton
              onClick={() => {
                if (BrandName !== false) {
                  if (ItemCategory || requiredToggle) {
                    if (ItemType || requiredToggle) {
                      if (packagingArr.length > 0 || requiredToggle) {
                        if (HSN || requiredToggle) {
                          if (total_quantity || requiredToggle) {
                            if (Rate || requiredToggle) {
                              if (
                                specificationArr.length > 0 ||
                                requiredToggle
                              ) {
                                this.setState(
                                  {
                                    defaultStep: 0,
                                    currentStep: 0,
                                    errorMsg: {},
                                    submittedSpecifies: {},
                                  },
                                  () => {
                                    addItemsFunction();
                                  }
                                );
                              } else if (
                                submittedSpecifies &&
                                (submittedSpecifies["Type"] ||
                                  submittedSpecifies["Condition"] ||
                                  submittedSpecifies["Value"])
                              ) {
                                this.setState({
                                  errorMsg: {
                                    ...errorMsg,
                                    addSpecification: true,
                                  },
                                });
                              } else {
                                this.setState(
                                  {
                                    defaultStep: 0,
                                    currentStep: 0,
                                    errorMsg: {},
                                    submittedSpecifies: {},
                                  },
                                  () => {
                                    addItemsFunction();
                                  }
                                );
                              }
                            } else {
                              const errorMsg = this.state.errorMsg;
                              scrollDiv("rate");
                              errorMsg.Rate = "Please give an Contract rate!";

                              this.setState({ errorMsg });
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            scrollDiv("total-quantity");
                            errorMsg.total_quantity =
                              "Please give an total_quantity!";

                            this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          scrollDiv("hsn");
                          errorMsg.HSN = "Please give an HSN!";

                          this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        scrollDiv("packaging");
                        errorMsg.packagingArr = "Please give packaging!";
                        this.setState({ errorMsg });
                      }
                    } else {
                      const errorMsg = this.state.errorMsg;
                      scrollDiv("brand");
                      errorMsg.ItemType = "Please select an ItemType!";
                      this.setState({ errorMsg });
                    }
                  } else {
                    const errorMsg = this.state.errorMsg;
                    scrollDiv("brand");
                    errorMsg.ItemCategory = "Please select an ItemCategory!";
                    this.setState({ errorMsg });
                  }
                } else {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.BrandName = "Please give an BrandName!";
                  scrollDiv("brand");
                  this.setState({ errorMsg });
                }
              }}
              name={`${editDialog !== false ? "Update" : "Add"} Item`}
              minWidth="30px"
              width="150px"
              margin="0 10px"
              withBg={true}
              background={errorMsg["updatedButton"] ? red : primary}
            />
          </div>
        </div>
      );
    };
    return (
      <div style={onlyDialog !== true ? styles.mainDiv : styles.none}>
        {error ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            // success={success}
            snackbarClose={() => {
              this.setState({
                error: "",
              });
            }}
          />
        ) : (
          ""
        )}
        {onlyUi === true && editDialog === false ? (
          ""
        ) : openDialog === true || editDialog !== false ? (
          <Dialog
            disableEnforceFocus={true}
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100vh - 64px)";
                target.style.minHeight = "calc(100vh - 64px)";

                target.style.height = "calc(100vh - 64px)";
                target.style.minWidth = "calc(100% - 100px)";
                target.style.maxWidth = "calc(100% - 100px)";
                target.style.margin = "32px 50px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.props.closeDialog();
              this.setState(
                {
                  openDialog: false,
                  editDialog: false,
                  added: false,
                },
                () => {
                  this.reset("no snac");
                  this.handleClearFields();
                }
              );
            }}
            TransitionComponent={Transition}
          >
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              onClick={() => {
                this.props.closeDialog();
                this.reset("no snac");
                this.handleClearFields();
                this.setState({
                  openDialog: false,
                  editDialog: false,
                  added: false,
                });
              }}
            >
              {" "}
              <h3 style={styles.heading}>
                {" "}
                <PurchaseIcon style={{ color: "white", margin: "0 5px 0 0" }} />
                {editDialog ? "Edit " : "Create "}Purchase Order
              </h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton
                  style={{ padding: "5px", borderRadius: "5px" }}
                  onClick={() => this.setState({ requiredToggle: true })}
                >
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>{" "}
            <DialogContent
              className="dashedDividerBottom"
              style={{ height: "100%" }}
            >
              {" "}
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  success={success}
                  autoHideDuration={error ? 5000 : 2000}
                  snackbarClose={() => {
                    if (
                      error === "Nothing to reset !" ||
                      success === "Reset successfully !"
                    ) {
                      this.setState({
                        error: "",
                        success: "",
                        loadingFun: false,
                      });
                    } else {
                      if (
                        success === "Purchase Order Added!" ||
                        success === "Purchase Order Updated!"
                      ) {
                        this.props.closeDialog();
                        this.reset("no snac");
                        this.handleClearFields();
                        this.setState({
                          openDialog: false,
                          editDialog: false,
                          error: "",
                          success: "",
                          loadingFun: false,
                        });
                      } else if (error) {
                        this.setState({
                          error: "",
                        });
                      }
                    }
                  }}
                />
              ) : (
                ""
              )}
              {added ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#1a9520",
                    }}
                  />
                  <span
                    className="heading"
                    style={{ color: "#1a9520", margin: "10px 0 0 0" }}
                  >
                    {added}
                  </span>
                </div>
              ) : (
                <>
                  {" "}
                  {loadingFun ? (
                    <Backdrop
                      style={{ zIndex: 1, color: "#fff" }}
                      open={loadingFun}
                    >
                      <StyledCircularProgress />
                    </Backdrop>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justifyFE width-100">
                    {editDialog && editDialog.status !== "DRAFT" ? null : (
                      <div style={{ padding: "3px 10px" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              size={"small"}
                              checked={requiredToggle}
                              onChange={this.handleRequiredToggleChange}
                              color="warning"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={"DRAFT"}
                          InputLabelProps={{
                            styles: { FontFamily: "Poppins" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0",
                      minHeight: "71.91px",
                    }}
                  >
                    <MuiPickersUtilsProvider
                      libInstance={moment()}
                      utils={DateFnsUtils}
                    >
                      <CssKeyboardDatePicker
                        label={"Contract Date"}
                        variant="inline"
                        onOpen={() => {
                          this.setState({ calenderOpen: true });
                        }}
                        onClose={() => {
                          this.setState({ calenderOpen: false });
                        }}
                        error={false}
                        invalidLabel=""
                        onKeyPress={(ev) => {
                          ev.preventDefault();
                        }}
                        onKeyDown={(evt) => {
                          evt.keyCode === 8 && evt.preventDefault();
                        }}
                        allowKeyboardControl={false}
                        keyboardIcon={
                          calenderOpen ? <TopArrow /> : <BottomArrow />
                        }
                        autoOk
                        inputVariant="outlined"
                        style={
                          { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                        }
                        format="dd MMM yyyy"
                        //  style={
                        //   styles.withDivider50 //disabled={tp}
                        // }
                        value={searchedData ? searchedData : new Date()}
                        //views={['year', 'month']}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(newValue) => {
                          this.setState({ searchedData: newValue });
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <CssTextField
                      required={true}
                      disabled={editDialog === false ? false : true}
                      autoComplete="off"
                      value={
                        contractTypeName ? contractTypeName : "" //
                      }
                      error={errorMsg.contractTypeName ? true : false}
                      helperText={
                        errorMsg.contractTypeName
                          ? errorMsg.contractTypeName
                          : ""
                      }
                      onChange={(e) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.contractTypeName = false;
                        this.setState({
                          errorMsg,
                          contractTypeId: e.currentTarget.id,
                          contractTypeName: e.target.value,
                          inlandGodown: [],
                        });
                      }}
                      style={
                        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      }
                      id="contract-type"
                      select
                      label="Contract Type"
                      variant="outlined"
                      SelectProps={{ MenuProps: MenuProps }}
                    >
                      {ContractTypeList &&
                        ContractTypeList.length > 0 &&
                        ContractTypeList.map((option) => (
                          <MenuItem
                            key={option.contract_type.toString()}
                            value={option.contract_type}
                            id={option.id}
                          >
                            {option.contract_type}
                          </MenuItem>
                        ))}
                    </CssTextField>
                    <CssTextField
                      required={true}
                      autoComplete="off"
                      value={
                        selctedPOStatus ? selctedPOStatus : "" //
                      }
                      disabled={
                        editDialog === false
                          ? true
                          : editDialog.status === "DRAFT"
                          ? true
                          : false
                      }
                      error={errorMsg.selctedPOStatus ? true : false}
                      helperText={
                        errorMsg.selctedPOStatus ? errorMsg.selctedPOStatus : ""
                      }
                      onChange={(e) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.selctedPOStatus = false;
                        this.setState({
                          errorMsg,
                          selctedPOStatus: e.target.value,
                        });
                      }}
                      style={
                        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      }
                      select
                      label="Status"
                      variant="outlined"
                      InputLabelProps={{}}
                      SelectProps={{ MenuProps: MenuProps }}
                    >
                      {status &&
                        status.length > 0 &&
                        status.map((option) => (
                          <MenuItem
                            key={option.name.toString()}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                    </CssTextField>
                    <CssTextField
                      required={true}
                      autoComplete="off"
                      value={
                        Unit ? Unit : "" //
                      }
                      error={errorMsg.Unit ? true : false}
                      helperText={errorMsg.Unit ? errorMsg.Unit : ""}
                      onChange={(e) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.Unit = false;
                        if (editDialog !== false) {
                          this.setData(e.target.value ? e.target.value : "");
                        } else {
                          this.props.setPoItems(
                            poItemsArrayCopy.map((ss) => {
                              return {
                                ...ss,
                                [`${poItemheader[5]}Unit`]: `${
                                  ss[poItemheader[5]]
                                } ${e.target.value ? e.target.value : ""}`,
                              };
                            }),
                            poItemsArray.map((ss) => {
                              return {
                                ...ss,
                                [`${poItemheader[5]}Unit`]: `${
                                  ss[poItemheader[5]]
                                } ${e.target.value ? e.target.value : ""}`,
                              };
                            })
                          );
                        }
                        this.setState({
                          errorMsg,
                          Unit: e.target.value,
                        });
                      }}
                      style={
                        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      }
                      id="unit"
                      disabled={SalesOrder ? true : false}
                      select
                      label="Unit"
                      variant="outlined"
                      InputLabelProps={{}}
                      SelectProps={{ MenuProps: MenuProps }}
                    >
                      {unit &&
                        unit.length > 0 &&
                        unit.map((option) => (
                          <MenuItem
                            key={option.name.toString()}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                    </CssTextField>
                  </div>
                  <div
                    style={
                      {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        fontSize: "1rem",
                        color: "grey",
                        background: "white",
                        margin: "20px 0",
                        minHeight: "71.91px",
                      } //height: "100%",
                    }
                  >
                    {/* <CustomAutocomplete
                      id="broker"
                      required={!requiredToggle ? true : false}
                      autoComplete="off"
                      options={BrokerList}
                      getOptionLabel={(option) => option.name}
                      value={
                        BorkerName ? BrokerList?.find(d => d.name == BorkerName) : "" //
                      }
                      error={errorMsg.BorkerName ? true : false}
                      helperText={
                        errorMsg.BorkerName ? errorMsg.BorkerName : ""
                      }
                      onChange={(e, newValue) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.BorkerName = false;
                        console.log(newValue, "newValue");
                        this.setState({
                          errorMsg,

                          Borkerage: filterData(
                            BrokerList,
                            "id",
                            Number(newValue.id),
                            "onlyOne"
                          )
                            ? filterData(
                                BrokerList,
                                "id",
                                Number(newValue.id),
                                "onlyOne"
                              ).default_brokerage
                            : "",

                          BorkerNameId: newValue.id,

                          BorkerageId: newValue.id,
                          BorkerName: newValue.name,
                        });
                      }}
                      style={
                        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      }
                      label="Broker"
                    /> */}
                    {console.log("InlandGodownList", InlandGodownList)}
                    {contractTypeName === "ex loose" ? (
                      <CustomMultiSelectAutocomplete
                        id="inlandGodown"
                        required={!requiredToggle}
                        autoComplete="off"
                        multiple
                        options={this.props.masters['InlandGodownList'] || []}
                        getOptionLabel={(option) =>
                          option?.name ? option?.name : ""
                        }
                        value={inlandGodown ? inlandGodown : null}
                        error={errorMsg?.inlandGodown ? true : false}
                        helperText={
                          errorMsg?.inlandGodown ? errorMsg?.inlandGodown : ""
                        }
                        onChange={(e, newValue) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.MillerName = false;
                          this.setState({ inlandGodown: newValue });
                        }}
                        style={{ width: "calc(25% - 20px)", margin: "10px" }}
                        optionKey="name"
                        label="Inland Godown"
                      />
                    ) : (
                      <MultipleSelectBar
                        required={!requiredToggle ? true : false}
                        id="port"
                        width={"calc(25% - 20px)"}
                        margin={"10px"}
                        value={portArry.map((sP) => {
                          sP.nameCity = `${sP.name} - ${sP.city ?? ""}`;
                          return sP;
                        })}
                        array={
                          PortsList && PortsList.length > 0
                            ? PortsList.map((sP) => {
                                sP.nameCity = `${sP.name} - ${sP.city ?? ""}`;
                                return sP;
                              })
                            : []
                        }
                        error={errorMsg.portArry ? true : false}
                        helperText={errorMsg.portArry ? errorMsg.portArry : ""}
                        selectedArr={(val) => {
                          console.log("PORT val", val);
                          const errorMsg = this.state.errorMsg;
                          errorMsg.portArry = false;
                          this.setState({ portArry: val, errorMsg });
                        }}
                        title="nameCity"
                      />
                    )}
                    {/* <CssTextField
                      id="broker"
                      required={!requiredToggle ? true : false}
                      autoComplete="off"
                      value={
                        BorkerName ? BorkerName : "" //
                      }
                      error={errorMsg.BorkerName ? true : false}
                      helperText={
                        errorMsg.BorkerName ? errorMsg.BorkerName : ""
                      }
                      onChange={(e) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.BorkerName = false;

                        this.setState({
                          errorMsg,

                          Borkerage: filterData(
                            BrokerList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                BrokerList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              ).default_brokerage
                            : "",

                          BorkerNameId: e.currentTarget.id,

                          BorkerageId: e.currentTarget.id,
                          BorkerName: e.target.value,
                        });
                      }}
                      style={
                        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      }
                      select
                      label="Broker"
                      variant="outlined"
                      InputLabelProps={{}}
                      SelectProps={{ MenuProps: MenuProps }}
                    >
                      {BrokerList &&
                        BrokerList.length > 0 &&
                        BrokerList.map((option) => (
                          <MenuItem
                            key={option.name.toString()}
                            value={option.name}
                            id={option.id}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                    </CssTextField> */}
                    <CustomSearchAutocomplete
                      id="broker"
                      required={!requiredToggle ? true : false}
                      autoComplete="off"
                      value={
                        Borkerage ? Borkerage : "" //
                      }
                      onChange={(e,newValue) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.BorkerName = false;

                        this.setState({
                          errorMsg,
                          Borkerage: newValue,
                          BorkerNameId: newValue?.id || "",
                          BorkerageId: newValue?.id || "",
                          BorkerName: newValue?.name || "",
                        });
                      }}
                      error={errorMsg.BorkerName ? true : false}
                      helperText={
                        errorMsg.BorkerName ? errorMsg.BorkerName : ""
                      }
                      style={{ width: "calc(25% - 20px)", margin: "10px" }}
                      renderOptionKey="name"
                      getOptionLabel={(option) => option.name}
                      label="Broker"
                      placeholder="Search Broker"
                      defaultData={{
                        value: editDialog?.broker_details?.name || "",
                      }}
                      api={{
                        callFn: this.props.PurchaseOrdersFunction,
                        url: "masters/broker/list",
                        resKey: 'BrokerList',
                        params: {is_active: "True"}
                      }}
                    />
                    {console.log("EDITDATA",editDialog)}
                    <CustomSearchAutocomplete
                      id="miller"
                      required={true}
                      autoComplete="off"
                      value={Miller ? Miller : ""}
                      error={errorMsg.MillerName ? true : false}
                      helperText={
                        errorMsg.MillerName ? errorMsg.MillerName : ""
                      }
                      onChange={(e,newValue) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.MillerName = false;

                        this.setState({
                          errorMsg,
                          Miller: newValue,
                          MillerNameId: newValue?.id || "",
                          MillerName: newValue?.name || "",
                          MillerAddress: newValue?.address || "",
                          MillerGST: newValue?.gstin || "",
                          ContactPerson: newValue?.contact_person || "",
                        });
                      }}
                      style={{ width: "calc(25% - 20px)", margin: "10px" }}
                      renderOptionKey="name"
                      getOptionLabel={(option) => option.name}
                      label="Miller Name"
                      defaultData={{
                        value: editDialog.miller_details?.name || "",
                      }}
                      placeholder="Search Miller"
                      api={{
                        callFn: this.props.PurchaseOrdersFunction,
                        url: "masters/miller/list",
                        resKey: 'MillerList',
                        params: {is_active: "True"}
                      }}
                    />
                    {/* <CssTextField
                      id="miller"
                      required={true}
                      autoComplete="off"
                      value={MillerName ? MillerName : ""}
                      error={errorMsg.MillerName ? true : false}
                      helperText={
                        errorMsg.MillerName ? errorMsg.MillerName : ""
                      }
                      onChange={(e) => {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.MillerName = false;
                        this.setState({
                          errorMsg,
                          MillerNameId: e.currentTarget.id,
                          MillerName: e.target.value,
                          MillerAddress: filterData(
                            MillerList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                MillerList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              ).address
                            : "",
                          MillerGST: filterData(
                            MillerList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                MillerList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              ).gstin
                            : "",
                          ContactPerson: filterData(
                            MillerList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                MillerList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              ).contact_person
                            : "",
                        });
                      }}
                      style={{ width: "calc(25% - 20px)", margin: "10px" }}
                      select
                      label="Miller Name"
                      variant="outlined"
                      SelectProps={{ MenuProps: MenuProps }}
                    >
                      {MillerList &&
                        MillerList.length > 0 &&
                        MillerList.map((option) => (
                          <MenuItem
                            key={option.name.toString()}
                            value={option.name}
                            id={option.id}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                    </CssTextField>{" "} */}
                    <CssTextField
                      required={!requiredToggle ? true : false}
                      autoComplete="off"
                      value={
                        MillerAddress ? MillerAddress : "" //
                      }
                      error={errorMsg.MillerAddress ? true : false}
                      helperText={
                        errorMsg.MillerAddress ? errorMsg.MillerAddress : ""
                      }
                      disabled={true}
                      style={{
                        width: "calc(25% - 20px)",
                        margin: "10px 10px 0px 10px",
                      }}
                      label="Miller Address"
                      variant="outlined"
                    />
                  </div>
                  <div
                    style={
                      {
                        display: "flex",
                        fontSize: "1rem",
                        color: "grey",
                        background: "white",
                        marginTop: "20px",
                        minHeight: "71.91px",
                      } //height: "100%",
                    }
                  >
                    <CssTextField
                      required={!requiredToggle ? true : false}
                      autoComplete="off"
                      value={
                        MillerGST ? MillerGST : "" //
                      }
                      error={errorMsg.MillerGST ? true : false}
                      helperText={errorMsg.MillerGST ? errorMsg.MillerGST : ""}
                      style={
                        {
                          width: "calc(25% - 20px)",
                          margin: "10px 10px 0px 10px",
                        } //disabled={tp}
                      }
                      label="Miller GST"
                      variant="outlined"
                      disabled={true}
                    />
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="contact-person"
                      value={
                        ContactPerson ? ContactPerson : "" //
                      }
                      disabled={true}
                      error={errorMsg.ContactPerson ? true : false}
                      helperText={
                        errorMsg.ContactPerson ? errorMsg.ContactPerson : ""
                      }
                      style={
                        {
                          width: "calc(25% - 20px)",
                          margin: "10px 10px 0px 10px",
                        } //disabled={tp}
                      }
                      label="Contact Person"
                      variant="outlined"
                      InputLabelProps={{}}
                    />
                    {editDialog !== false && (
                      <MuiPickersUtilsProvider
                        libInstance={moment()}
                        utils={DateFnsUtils}
                      >
                        <CssKeyboardDatePicker
                          variant="inline"
                          style={
                            { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                          }
                          onOpen={() => {
                            this.setState({ calenderOpenLS: true });
                          }}
                          onClose={() => {
                            this.setState({ calenderOpenLS: false });
                          }}
                          error={false}
                          invalidLabel=""
                          onKeyPress={(ev) => {
                            ev.preventDefault();
                          }}
                          onKeyUp={(ev) => {
                            ev.preventDefault();
                          }}
                          onKeyDown={(ev) => {
                            ev.preventDefault();
                          }}
                          allowKeyboardControl={false}
                          keyboardIcon={
                            calenderOpenLS ? <TopArrow /> : <BottomArrow />
                          }
                          autoOk
                          inputVariant="outlined"
                          label={"Last Survey"}
                          format="dd MMM yyyy"
                          value={last_survey ? last_survey : new Date()}
                          InputAdornmentProps={{ position: "end" }}
                          onChange={(newValue) => {
                            this.setState({ last_survey: newValue });
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    {contractTypeName === "ex loose" ? null : (
                      <CssTextField
                        id="sales_order"
                        autoComplete="off"
                        value={
                          SalesOrder ? SalesOrder : "" //
                        }
                        error={errorMsg.SalesOrder ? true : false}
                        helperText={
                          errorMsg.SalesOrder ? errorMsg.SalesOrder : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.SalesOrder = false;
                          const selectedSo = filterData(
                            SalesOrderList,
                            "id",
                            Number(e.currentTarget.id),
                            "onlyOne"
                          )
                            ? filterData(
                                SalesOrderList,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              )
                            : {};
                          this.setState({
                            errorMsg,
                            SalesOrder: e.target.value,
                            selectedSOItems: [],
                            submittedItemData: {},
                            SalesOrderId: e.currentTarget.id,
                            Unit: selectedSo.unit,
                            UnitId: selectedSo.unit,
                          });
                          if (e.target.value) {
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                "dispatch/so-items/list",
                                null,
                                null,
                                null,
                                {
                                  so_id: e.currentTarget.id,
                                }
                              )
                              .then((soitems) => {
                                if (soitems && soitems.length > 0) {
                                  const soDetail = {};
                                  soitems.map((s) => {
                                    soDetail[s.id] = s.total_quantity ?? 0;
                                    return s;
                                  });
                                  console.log(soitems, "poofThisSo");

                                  this.setState({
                                    soitemsList: soitems,
                                    submittedItemData: soDetail,
                                  });
                                }
                              });
                          }
                        }}
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "10px 10px 0px 10px",
                          } //disabled={tp}
                        }
                        select
                        label="Sales Order Numbers"
                        variant="outlined"
                        InputLabelProps={{}}
                        SelectProps={{ MenuProps: MenuProps }}
                      >
                        {SalesOrderList &&
                          SalesOrderList.length > 0 &&
                          SalesOrderList.sort((a, b) => b.id - a.id).map(
                            (option) => (
                              <MenuItem
                                key={option.so_number}
                                value={option.so_number}
                                id={option.id}
                              >
                                <div className="d-flex d-flex-column ">
                                  <span>{option.so_number}</span>
                                  <span style={{ fontSize: 12, color: "#888" }}>
                                    Remaining :{" "}
                                    {option?.remaining_quantity || ""}{" "}
                                    {option?.unit || ""}
                                  </span>
                                </div>
                              </MenuItem>
                            )
                          )}
                      </CssTextField>
                    )}
                    {contractTypeName === "ex loose" ? null : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CssButton
                          style={{
                            background: "rgb(241 154 48)",
                            color: "white",
                            // marginRight: "10px",
                            textTransform: "capitalize",
                            marginBottom: "-10px",
                            height: "30px",
                          }}
                          onClick={() => {
                            this.setState({
                              SalesOrder: "",
                              SalesOrderId: "",
                              selectedSOItems: [],
                              submittedItemData: {},
                            });
                          }}
                        >
                          clear
                        </CssButton>
                      </div>
                    )}
                  </div>
                  {console.log(SalesOrder, "SalesOrderwhenedit")}
                  {SalesOrder ? (
                    <div className="width-100" id="selectSOItem">
                      <TableComponent
                        // singleUnit={selctedCIUnit && selctedCIUnit.unit && selctedCIUnit.unit}
                        // weight={selctedCIUnit && selctedCIUnit.unit && selctedCIUnit.value}
                        // changeUnit={(value, unit) => {
                        //   return changeUnit(unit, selctedCIUnit && selctedCIUnit.unit && selctedCIUnit.unit, value);
                        // }}
                        bottomBarheader={["Shipped Bags", "Shipped Quantity"]}
                        nobottomBarAction={true}
                        bottomBar={true}
                        setSelectedListArray={(list) => {
                          this.setState({ selectedSOItems: list });
                        }}
                        selectedListArray={selectedSOItems}
                        setSelectedListArrayErrorSet={() => {
                          this.setState({
                            errorMsg: { ...errorMsg, selectedSOItems: false },
                          });
                        }}
                        setSelectedListArrayError={
                          errorMsg["selectedSOItems"]
                            ? errorMsg["selectedSOItems"]
                            : false
                        }
                        checkbox={true}
                        // loading={loading}
                        heightNew="500px"
                        maxHeight="calc(100vh - 40px)"
                        widthNew="900px"
                        module={"Bag details"}
                        actions={[]}
                        noAddButton={true}
                        header={moveToShipHeader2}
                        noSearchBar={true}
                        {...this.props}
                        setSelectedList={(data) => {}}
                        {...this.props}
                        tableList={
                          soitemsList && soitemsList.length > 0
                            ? soitemsList.map((d) => {
                                console.log("DD", d);
                                console.log(d, d.packaging, "ssssale details");
                                return {
                                  ...d,
                                  Brand: d.brand_details.brand_name,

                                  "Item Category": ItemCateoryList.filter(
                                    (a) =>
                                      a.id === d.brand_details.item_category
                                  )
                                    ? ItemCateoryList.filter(
                                        (a) =>
                                          a.id === d.brand_details.item_category
                                      )[0]?.name
                                    : "",

                                  "Item Type": ItemList.filter(
                                    (a) => a.id === d.brand_details.item_type
                                  )
                                    ? `${
                                        ItemList.filter(
                                          (a) =>
                                            a.id === d.brand_details.item_type
                                        )[0]?.broken_percentage
                                      }%`
                                    : "",

                                  Packaging:
                                    d.packaging &&
                                    d.packaging.map((sP) => {
                                      // console.log(sp)
                                      const newAP = filterData(
                                        PackagingList,
                                        "id",
                                        Number(sP),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            PackagingList,
                                            "id",
                                            Number(sP),
                                            "onlyOne"
                                          )
                                        : {};
                                      return `${newAP.size}${newAP.unit}`;
                                    }) &&
                                    d.packaging.map((sP) => {
                                      const newAP = filterData(
                                        PackagingList,
                                        "id",
                                        Number(sP),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            PackagingList,
                                            "id",
                                            Number(sP),
                                            "onlyOne"
                                          )
                                        : {};
                                      return `${newAP.size}${newAP.unit}`;
                                    }).length > 0 &&
                                    `tota( )...${
                                      d.packaging.map((sP) => {
                                        const newAP = filterData(
                                          PackagingList,
                                          "id",
                                          Number(sP),
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              PackagingList,
                                              "id",
                                              Number(sP),
                                              "onlyOne"
                                            )
                                          : {};
                                        return `${newAP.size}${newAP.unit}`;
                                      })[0]
                                    }`,

                                  ["PackagingUI"]: (
                                    <ShowPackaging
                                      noTotalbags={true}
                                      noRemainingBags={true}
                                      details={d.packaging.map((sP) => {
                                        const newAP = filterData(
                                          PackagingList,
                                          "id",
                                          Number(sP),
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              PackagingList,
                                              "id",
                                              Number(sP),
                                              "onlyOne"
                                            )
                                          : {};

                                        // newAP.used_bags =
                                        //   sP.total_bags_dispatched;
                                        console.log(newAP, "newap");
                                        return newAP;
                                      })}
                                    />
                                  ),

                                  ["HSN Code"]: filterData(
                                    HSNlist,
                                    "id",
                                    d.hsn_code,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        HSNlist,
                                        "id",
                                        d.hsn_code,
                                        "onlyOne"
                                      )["hsn_code"]
                                    : "",
                                  ["Total Item Quantity"]:
                                    d.total_quantity ?? "0",

                                  "Total Item Quantity": submittedItemData[
                                    `${d.id}`
                                  ]
                                    ? submittedItemData[`${d.id}`]
                                    : 0,
                                  "Total Item QuantityUI": () => {
                                    return (
                                      <CssTextField95
                                        required={true}
                                        onInput={(e) => {
                                          console.log(
                                            d.total_quantity &&
                                              d.total_quantity !== null
                                              ? Number(d.total_quantity)
                                              : 0,
                                            "wsedrtfgvhbjnlk"
                                          );
                                          if (
                                            Number(e.target.value) >
                                            (d.total_quantity
                                              ? Number(d.total_quantity)
                                              : 0)
                                          ) {
                                            this.setState(
                                              {
                                                error: `Only ${
                                                  d.total_quantity ?? 0
                                                } is available in SO Item.`,
                                                submittedItemData: {
                                                  ...submittedItemData,
                                                  [`${d.id}`]:
                                                    d.total_quantity &&
                                                    d.total_quantity !== null
                                                      ? Number(d.total_quantity)
                                                      : 0,
                                                },
                                              },
                                              () => {}
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            max: d.total_quantity
                                              ? Number(d.total_quantity)
                                              : 0,
                                          },

                                          endAdornment: Unit,
                                        }}
                                        type="number"
                                        onFocus={StopScroll}
                                        autoComplete="off"
                                        id={`so${d.id}`}
                                        style={{
                                          width: "100%",
                                          margin: "10px 0",
                                        }}
                                        error={
                                          errorMsg[`so${d.id}`]
                                            ? errorMsg[`so${d.id}`]
                                            : false
                                        }
                                        variant="outlined"
                                        value={
                                          submittedItemData[`${d.id}`]
                                            ? submittedItemData[`${d.id}`]
                                            : ""
                                        }
                                        onChange={(e) => {
                                          if (
                                            Number(e.target.value) >
                                            (d.total_quantity
                                              ? Number(d.total_quantity)
                                              : 0)
                                          ) {
                                            this.setState({
                                              submittedItemData: {
                                                ...submittedItemData,
                                                [`${d.id}`]: d.total_quantity
                                                  ? Number(d.total_quantity)
                                                  : 0,
                                              },
                                              errorMsg: {
                                                ...errorMsg,
                                                [`so${d.id}`]: false,
                                              },
                                            });
                                          } else {
                                            this.setState({
                                              submittedItemData: {
                                                ...submittedItemData,
                                                [`${d.id}`]: e.target.value,
                                              },
                                              errorMsg: {
                                                ...errorMsg,
                                                [`so${d.id}`]: false,
                                              },
                                            });
                                          }
                                        }}
                                      />
                                    );
                                  },

                                  ["Contract Rate"]: d.contract_rate
                                    ? `₹ ${Number(d.contract_rate).toFixed(
                                        2
                                      )} /${Unit}`
                                    : "",
                                  ["Sales Tax Terms"]: d.sales_tax_terms
                                    ? d.sales_tax_terms
                                    : "",
                                  ["Buyer Marking"]: d.buyers_marking,

                                  ["Specifications"]:
                                    "abcdweeeeeeeeeeeeeeeeeeeeee",
                                  SpecificationsUI: (
                                    <ShowSpecifications
                                      details={
                                        d.so_specifications &&
                                        d.so_specifications.length > 0
                                          ? d.so_specifications.map((sP) => {
                                              // sP.used_bags =0
                                              const ss = sP.nameId ?? sP.name;
                                              const filterS =
                                                this.props.specifiationTypes.find(
                                                  (st) => st.id === ss
                                                );
                                              return {
                                                ...sP,
                                                name: filterS
                                                  ? `${filterS.text.toLowerCase()}`
                                                  : "",
                                                value: filterS
                                                  ? `${
                                                      sP.value
                                                    } ${filterS.unit.toLowerCase()}`
                                                  : sP.value,
                                              };
                                            })
                                          : []
                                      }
                                    />
                                  ),
                                };
                              })
                            : []
                          // soitemsList&& soitemsList.length>0?soitemsList:""
                        }
                        filterChild={false}
                      />
                    </div>
                  ) : (
                    <>
                      <HeadingLine
                        maxWidth="calc(100% - 20px)"
                        width="calc(100% - 20px)"
                        children={`Add Multiple PO Items`}
                        center={true}
                        margin={"0px 10px 20px 10px"}
                      />
                      <div
                        style={{
                          border: "1px solid rgba(128, 128, 128, 0.64)",
                        }}
                        className="borderRadius7"
                      >
                        {contractTypeName === "ex loose" ? (
                          <CustomItemTableUI
                            specifiationTypes={this.props.specifiationTypes}
                            masters={this.props.masters}
                            widthList={this.props.widthList}
                            BrandList={this.props.BrandList}
                            ItemCateoryList={this.props.ItemCateoryList}
                            ItemList={this.props.ItemList}
                            Unit={this.state.Unit}
                            module={requiredToggle ? "PO" : "Purchase Order"}
                            itemHeader={[
                              {
                                label: "Item Category",
                                labelKey: "Item Category",
                                apiKey: "name",
                                key: "Item Categoryid",
                                array: this.props.ItemCateoryList,
                                arrayKey: "id",
                                required: requiredToggle ? false : true,
                              },
                              {
                                label: "Item Type",
                                labelKey: "Item Type",
                                apiKey: "broken_percentage",
                                key: "Item Typeid",
                                array: this.props.ItemList,
                                arrayKey: "id",
                                required: requiredToggle ? false : true,
                              },
                              {
                                label: "HSN Code",
                                labelKey: "HSN Code",
                                apiKey: "hsn_code",
                                key: "HSN Codeid",
                                array: this.props.HSNlist,
                                arrayKey: "id",
                                required: requiredToggle ? false : true,
                              },
                              {
                                label: "Total Item Quantity",
                                labelKey: "Total Item QuantityUnit",
                                key: "Total Item Quantity",
                                required: requiredToggle ? false : true,
                              },
                              {
                                label: "Contract Rate",
                                labelKey: "Contract RateUnit",
                                key: "Contract Rate",
                                required: requiredToggle ? false : true,
                              },
                              {
                                label: "Sales Tax Terms",
                                labelKey: "Sales Tax Terms",
                                key: "Sales Tax Terms",
                                orKey: "sales_tax_terms",
                                required: false,
                              },
                              {
                                label: "Specifications",
                                labelKey: "Specifications",
                                key: "Specifications",
                                required: false,
                              },
                            ]}
                            defaultItemTableData={poItemsArray}
                            loosePoItem={loosePoItem.length > 0 ? loosePoItem : poItemsArray}
                            handleCreatedEditedItemList={
                              this.handleCreatedEditedItemList
                            }
                          />
                        ) : (
                          <>
                            {console.log("EDIT", poItemsArray)}
                            {POITemTable(
                              "edit",
                              poItemsArray,
                              null,
                              true,
                              null,
                              null,
                              null,
                              null,
                              editFunct,
                              Unit,
                              true
                            )}

                            {/* started */}

                            <div>
                              <div
                                style={{
                                  margin: "20px 20px 0px 20px",
                                  padding: "10px",
                                }}
                              >
                                {" "}
                                {this.state.defaultStep === 0 ? (
                                  <div>
                                    <div
                                      style={
                                        {
                                          display: "flex",
                                          justifyContent: "space-between",

                                          fontSize: "1rem",
                                          color: "grey",
                                          background: "white",
                                          marginBottom: "20px",
                                          width: "100%",
                                          minHeight: "71.91px",
                                        } //height: "100%",
                                      }
                                    >
                                      {console.log(
                                        "selectedBrandMultipleName",
                                        selectedBrandMultipleName
                                      )}
                                      <CssTextField
                                        required={true}
                                        id="brand"
                                        autoComplete="off"
                                        value={
                                          selectedBrandMultipleName
                                            ? selectedBrandMultipleName
                                            : ""
                                        }
                                        disabled={
                                          editDialog &&
                                          selectedBrandMultipleName &&
                                          singlelistView.total_dispatched_quantity &&
                                          this.state.isItemRowEdit
                                        }
                                        error={
                                          errorMsg.BrandName ? true : false
                                        }
                                        helperText={
                                          errorMsg.BrandName
                                            ? errorMsg.BrandName
                                            : ""
                                        }
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.BrandName = false;
                                          errorMsg.ItemCategory = false;
                                          errorMsg.ItemType = false;
                                          const brandSelected =
                                            BrandList &&
                                            BrandList.length > 0 &&
                                            BrandList.filter(
                                              (option) =>
                                                option.id.toString() ===
                                                e.currentTarget.id.toString()
                                            );

                                          const iCName =
                                            ItemCateoryList &&
                                            ItemCateoryList.length > 0 &&
                                            ItemCateoryList.filter(
                                              (sIc) =>
                                                sIc.id ===
                                                (brandSelected &&
                                                  brandSelected[0]
                                                    .item_category &&
                                                  brandSelected[0]
                                                    .item_category)
                                            ) &&
                                            ItemCateoryList &&
                                            ItemCateoryList.length > 0 &&
                                            ItemCateoryList.filter(
                                              (sIc) =>
                                                sIc.id ===
                                                (brandSelected &&
                                                  brandSelected[0]
                                                    .item_category &&
                                                  brandSelected[0]
                                                    .item_category)
                                            )[0] &&
                                            ItemCateoryList &&
                                            ItemCateoryList.length > 0 &&
                                            ItemCateoryList.filter(
                                              (sIc) =>
                                                sIc.id ===
                                                (brandSelected &&
                                                  brandSelected[0]
                                                    .item_category &&
                                                  brandSelected[0]
                                                    .item_category)
                                            )[0]?.name;

                                          const iTname =
                                            ItemList &&
                                            ItemList.length > 0 &&
                                            ItemList.filter(
                                              (sIc) =>
                                                sIc.id ===
                                                (brandSelected &&
                                                  brandSelected[0].item_type &&
                                                  brandSelected[0].item_type)
                                            ) &&
                                            ItemList.filter(
                                              (sIc) =>
                                                sIc.id ===
                                                (brandSelected &&
                                                  brandSelected[0].item_type &&
                                                  brandSelected[0].item_type)
                                            )[0] &&
                                            ItemList.filter(
                                              (sIc) =>
                                                sIc.id ===
                                                (brandSelected &&
                                                  brandSelected[0].item_type &&
                                                  brandSelected[0].item_type)
                                            )[0]?.broken_percentage;

                                          this.setState({
                                            errorMsg,
                                            BrandNameId: e.currentTarget.id,
                                            BrandName:
                                              brandSelected &&
                                              brandSelected[0].brand_name,
                                            // ItemCategoryId:
                                            //   brandSelected &&
                                            //   brandSelected[0].item_category &&
                                            //   brandSelected[0].item_category,
                                            // ItemCategory: iCName,
                                            // itemsId:
                                            //   brandSelected &&
                                            //   brandSelected[0].item_type &&
                                            //   brandSelected[0].item_type,
                                            // ItemType: iTname,
                                            selectedBrandMultipleName:
                                              e.target.value,
                                          });
                                        }}
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px",
                                          } //disabled={tp}
                                        }
                                        select
                                        label="Brand"
                                        variant="outlined"
                                        InputLabelProps={{}}
                                        SelectProps={{ MenuProps: MenuProps }}
                                      >
                                        {BrandList &&
                                          BrandList.length > 0 &&
                                          BrandList.sort(
                                            (a, b) =>
                                              (Boolean(a.item_category) ||
                                                Boolean(a.item_type)) -
                                              (Boolean(b.item_category) ||
                                                Boolean(b.item_type))
                                          ).map((option) => (
                                            <MenuItem
                                              key={option.brand_name.toString()}
                                              value={
                                                option.item_category &&
                                                option.item_type
                                                  ? `${option.brand_name}-${
                                                      filterData(
                                                        ItemCateoryList,
                                                        "id",
                                                        option.item_category,
                                                        "onlyName"
                                                      )
                                                        ? filterData(
                                                            ItemCateoryList,
                                                            "id",
                                                            option.item_category,
                                                            "onlyName"
                                                          ).name
                                                        : ""
                                                    }-${
                                                      filterData(
                                                        ItemList,
                                                        "id",
                                                        option.item_type,
                                                        "onlyName"
                                                      )
                                                        ? filterData(
                                                            ItemList,
                                                            "id",
                                                            option.item_type,
                                                            "onlyName"
                                                          )?.broken_percentage
                                                        : ""
                                                    } %`
                                                  : `${option.brand_name}`
                                              }
                                              id={option.id}
                                            >
                                              {option.item_category &&
                                              option.item_type
                                                ? `${option.brand_name}-${
                                                    filterData(
                                                      ItemCateoryList,
                                                      "id",
                                                      option.item_category,
                                                      "onlyName"
                                                    )
                                                      ? filterData(
                                                          ItemCateoryList,
                                                          "id",
                                                          option.item_category,
                                                          "onlyName"
                                                        ).name
                                                      : ""
                                                  }-${
                                                    filterData(
                                                      ItemList,
                                                      "id",
                                                      option.item_type,
                                                      "onlyName"
                                                    )
                                                      ? filterData(
                                                          ItemList,
                                                          "id",
                                                          option.item_type,
                                                          "onlyName"
                                                        )?.broken_percentage
                                                      : ""
                                                  } %`
                                                : `${option.brand_name}`}
                                            </MenuItem>
                                          ))}
                                      </CssTextField>
                                      <CssTextField
                                        required={
                                          !requiredToggle ? true : false
                                        }
                                        autoComplete="off"
                                        value={
                                          ItemCategory ? ItemCategory : "" //
                                        }
                                        error={
                                          errorMsg.ItemCategory ? true : false
                                        }
                                        helperText={
                                          errorMsg.ItemCategory
                                            ? errorMsg.ItemCategory
                                            : ""
                                        }
                                        disabled={
                                          editDialog &&
                                          ItemCategory &&
                                          singlelistView.total_dispatched_quantity &&
                                          this.state.isItemRowEdit
                                        }
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px",
                                          } //disabled={tp}
                                        }
                                        select
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.ItemCategory = false;
                                          this.setState({
                                            errorMsg,
                                            ItemCategoryId:
                                              ItemCateoryList.find(
                                                (d) => d.name === e.target.value
                                              ).id,
                                            ItemCategory: e.target.value,
                                          });
                                        }}
                                        label="Item Category"
                                        variant="outlined"
                                        // disabled={true}
                                        InputLabelProps={{}}
                                        SelectProps={{ MenuProps: MenuProps }}
                                      >
                                        {ItemCateoryList &&
                                          ItemCateoryList.length > 0 &&
                                          ItemCateoryList.map((option) => (
                                            <MenuItem
                                              key={option.name}
                                              value={option.name}
                                              id={option.id}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                      </CssTextField>
                                      <CssTextField
                                        required={
                                          !requiredToggle ? true : false
                                        }
                                        autoComplete="off"
                                        value={ItemType ? ItemType : ""}
                                        error={errorMsg.ItemType ? true : false}
                                        helperText={
                                          errorMsg.ItemType
                                            ? errorMsg.ItemType
                                            : ""
                                        }
                                        disabled={
                                          editDialog &&
                                          ItemType &&
                                          singlelistView.total_dispatched_quantity &&
                                          this.state.isItemRowEdit
                                        }
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px",
                                          } //disabled={tp}
                                        }
                                        select
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.ItemType = false;
                                          console.log(e);
                                          this.setState({
                                            errorMsg,
                                            itemsId: ItemList.find(
                                              (d) =>
                                                d?.broken_percentage ===
                                                e.target.value
                                            ).id,
                                            ItemType: e.target.value,
                                          });
                                        }}
                                        label="Item Type"
                                        variant="outlined"
                                        // disabled={true}
                                        SelectProps={{ MenuProps: MenuProps }}
                                      >
                                        {ItemList &&
                                          ItemList.length > 0 &&
                                          ItemList.map((option) => (
                                            <MenuItem
                                              key={option.broken_percentage}
                                              value={option.broken_percentage}
                                              id={option.id}
                                            >
                                              {`${option.broken_percentage} %`}
                                            </MenuItem>
                                          ))}
                                      </CssTextField>
                                      <MultipleSelectBar
                                        required={
                                          !requiredToggle ? true : false
                                        }
                                        width={"calc(25% - 20px)"}
                                        margin={"10px"}
                                        id={"packaging"}
                                        value={packagingArr}
                                        array={
                                          PackagingList &&
                                          PackagingList.length > 0
                                            ? PackagingList.map((sP) => {
                                                sP.sizeUnit = `${sP.size}${sP.unit} - ${sP.bag_type}`;
                                                return sP;
                                              })
                                            : []
                                        }
                                        error={
                                          errorMsg.packagingArr
                                            ? errorMsg.packagingArr
                                            : false
                                        }
                                        helperText={
                                          errorMsg.packagingArr
                                            ? errorMsg.packagingArr
                                            : ""
                                        }
                                        title="sizeUnit"
                                        disabledItem={
                                          editSelectedItemPackagingArr &&
                                          editSelectedItemPackagingArr.length >
                                            0
                                            ? editSelectedItemPackagingArr.map(
                                                (d) => d.id
                                              )
                                            : []
                                        }
                                        selectedArr={(val) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.packagingArr = false;
                                          let selectedData = [];
                                          if (
                                            editDialog &&
                                            singlelistView.total_dispatched_quantity &&
                                            this.state.isItemRowEdit
                                          ) {
                                            selectedData = Boolean(
                                              val &&
                                                editSelectedItemPackagingArr.every(
                                                  (editItem) =>
                                                    val?.find(
                                                      (d) =>
                                                        d.id === editItem.id
                                                    )
                                                )
                                            )
                                              ? val
                                              : val?.length > 0
                                              ? [
                                                  ...val,
                                                  ...editSelectedItemPackagingArr.filter(
                                                    (editItem) =>
                                                      !val?.some(
                                                        (d) =>
                                                          d.id === editItem.id
                                                      )
                                                  ),
                                                ]
                                              : editSelectedItemPackagingArr;
                                          } else {
                                            selectedData = val;
                                          }
                                          console.log(
                                            val,
                                            editSelectedItemPackagingArr,
                                            selectedData
                                          );

                                          this.setState({
                                            errorMsg,
                                            packagingArr: selectedData,
                                          });
                                        }}
                                      />
                                    </div>

                                    <div
                                      style={
                                        {
                                          display: "flex",
                                          // justifyContent: 'space-between',

                                          fontSize: "1rem",
                                          color: "grey",
                                          background: "white",
                                          margin: "20px 0",
                                          width: "100%",
                                          minHeight: "71.91px",
                                        } //height: "100%",
                                      }
                                    >
                                      <CssTextField
                                        required={
                                          !requiredToggle ? true : false
                                        }
                                        autoComplete="off"
                                        value={
                                          HSN ? HSN : "" //
                                        }
                                        id="hsn"
                                        error={errorMsg.HSN ? true : false}
                                        helperText={
                                          errorMsg.HSN ? errorMsg.HSN : ""
                                        }
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.HSN = false;
                                          this.setState({
                                            errorMsg,
                                            HSN: e.target.value,
                                            HSN_id: e.currentTarget.id,
                                          });
                                        }}
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px",
                                          } //disabled={tp}
                                        }
                                        select
                                        label="HSN Code"
                                        variant="outlined"
                                        InputLabelProps={{}}
                                        SelectProps={{ MenuProps: MenuProps }}
                                      >
                                        {HSNlist &&
                                          HSNlist.length > 0 &&
                                          HSNlist.map((option) => (
                                            <MenuItem
                                              key={option.hsn_code.toString()}
                                              value={option.hsn_code}
                                              id={option.id}
                                            >
                                              {option.hsn_code}
                                            </MenuItem>
                                          ))}
                                      </CssTextField>
                                      <CssTextField
                                        required={
                                          !requiredToggle ? true : false
                                        }
                                        type="number"
                                        autoComplete="off"
                                        value={total_quantity}
                                        onChange={(e) => {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg.total_quantity = false;

                                          this.setState({
                                            errorMsg,
                                            total_quantity: e.target.value,
                                          });
                                        }}
                                        onKeyDown={(evt) => {
                                          // if (singleInput.only_number === true) {
                                          //   evt.key === "." && evt.preventDefault();
                                          // }
                                          evt.key === "e" &&
                                            evt.preventDefault();

                                          evt.key === "E" &&
                                            evt.preventDefault();
                                          evt.key === "-" &&
                                            evt.preventDefault();
                                          evt.keyCode === 38 &&
                                            evt.preventDefault();
                                          evt.keyCode === 40 &&
                                            evt.preventDefault();
                                        }}
                                        InputProps={{
                                          endAdornment: Unit,
                                        }}
                                        //style={styles.withDivider50}
                                        error={
                                          errorMsg.total_quantity ? true : false
                                        }
                                        helperText={
                                          errorMsg.total_quantity
                                            ? errorMsg.total_quantity
                                            : false
                                        }
                                        // id="outlined-basic"
                                        label="Total Item Quantity"
                                        onFocus={StopScroll}
                                        // onKeyDown={(evt) => {
                                        //   evt.key === "e" && evt.preventDefault();

                                        //   evt.key === "E" && evt.preventDefault();
                                        //   evt.key === "-" && evt.preventDefault();
                                        // }}
                                        // id="total-quantity"
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px",
                                          } //disabled={tp}
                                        }
                                        variant="outlined"
                                        name="total_quantity"
                                      />
                                      <CssTextField
                                        required={
                                          !requiredToggle ? true : false
                                        }
                                        type="number"
                                        onKeyDown={(evt) => {
                                          // if (singleInput.only_number === true) {
                                          //   evt.key === "." && evt.preventDefault();
                                          // }
                                          evt.key === "e" &&
                                            evt.preventDefault();

                                          evt.key === "E" &&
                                            evt.preventDefault();
                                          evt.key === "-" &&
                                            evt.preventDefault();
                                          evt.keyCode === 38 &&
                                            evt.preventDefault();
                                          evt.keyCode === 40 &&
                                            evt.preventDefault();
                                        }}
                                        autoComplete="off"
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px",
                                          } //disabled={tp}
                                        }
                                        InputProps={{
                                          endAdornment: `/${Unit}`,
                                        }}
                                        // InputProps={{
                                        //   startAdornment: <InputAdornment position="start">{Unit}</InputAdornment>,
                                        // }}
                                        error={errorMsg.Rate ? true : false}
                                        helperText={
                                          errorMsg.Rate
                                            ? "Please Add Rate!"
                                            : false
                                        }
                                        id="outlined-basic"
                                        label="Contract Rate"
                                        onFocus={StopScroll}
                                        variant="outlined"
                                        name="Rate"
                                        // id="rate"
                                        value={
                                          Boolean(this.state.Rate)
                                            ? this.state.Rate
                                            : ""
                                        }
                                        onChange={this.handleChange}
                                      />
                                      <CssTextField
                                        required={false}
                                        autoComplete="off"
                                        id="Sales tax Terms"
                                        value={
                                          sales_tax_terms ? sales_tax_terms : "" //
                                        }
                                        error={
                                          errorMsg.sales_tax_terms
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          errorMsg.sales_tax_terms
                                            ? errorMsg.sales_tax_terms
                                            : ""
                                        }
                                        style={
                                          {
                                            width: "calc(25% - 20px)",
                                            margin: "10px 10px 0px 0",
                                          } //disabled={tp}
                                        }
                                        label="Sales Tax Terms"
                                        name="sales_tax_terms"
                                        onChange={this.handleChange}
                                        variant="outlined"
                                      />
                                      {this.state.defaultStep === 1 ? (
                                        ""
                                      ) : (
                                        <div
                                          style={{
                                            // width: "calc(25% - 20px)",
                                            margin: "10px",
                                          }}
                                          className="d-flex justifyFE"
                                          id="update-button"
                                        >
                                          <StyledButton
                                            name="Next"
                                            background={
                                              errorMsg["updatedButton"]
                                                ? red
                                                : primary
                                            }
                                            onClick={() => {
                                              if (BrandName !== false) {
                                                if (
                                                  ItemCategory ||
                                                  requiredToggle
                                                ) {
                                                  if (
                                                    ItemType ||
                                                    requiredToggle
                                                  ) {
                                                    if (
                                                      packagingArr.length > 0 ||
                                                      requiredToggle
                                                    ) {
                                                      if (
                                                        HSN ||
                                                        requiredToggle
                                                      ) {
                                                        if (
                                                          total_quantity ||
                                                          requiredToggle
                                                        ) {
                                                          if (
                                                            Rate ||
                                                            requiredToggle
                                                          ) {
                                                            this.setState({
                                                              defaultStep: 1,
                                                              currentStep: 1,
                                                              errorMsg: {},
                                                            });
                                                          } else {
                                                            const errorMsg =
                                                              this.state
                                                                .errorMsg;
                                                            errorMsg.Rate =
                                                              "Please give an Contract rate!";

                                                            this.setState({
                                                              errorMsg,
                                                            });
                                                          }
                                                        } else {
                                                          const errorMsg =
                                                            this.state.errorMsg;
                                                          errorMsg.total_quantity =
                                                            "Please give an total_quantity!";

                                                          this.setState({
                                                            errorMsg,
                                                          });
                                                        }
                                                      } else {
                                                        const errorMsg =
                                                          this.state.errorMsg;
                                                        errorMsg.HSN =
                                                          "Please give an HSN!";

                                                        this.setState({
                                                          errorMsg,
                                                        });
                                                      }
                                                    } else {
                                                      const errorMsg =
                                                        this.state.errorMsg;
                                                      errorMsg.packagingArr =
                                                        "Please give packaging!";
                                                      this.setState({
                                                        errorMsg,
                                                      });
                                                    }
                                                  } else {
                                                    const errorMsg =
                                                      this.state.errorMsg;
                                                    errorMsg.ItemType =
                                                      "Please select an ItemType!";
                                                    this.setState({ errorMsg });
                                                  }
                                                } else {
                                                  const errorMsg =
                                                    this.state.errorMsg;
                                                  errorMsg.ItemCategory =
                                                    "Please select an ItemCategory!";
                                                  this.setState({ errorMsg });
                                                }
                                              } else {
                                                const errorMsg =
                                                  this.state.errorMsg;
                                                errorMsg.BrandName =
                                                  "Please give an BrandName!";
                                                this.setState({ errorMsg });
                                              }
                                            }}
                                            margin="10px 0"
                                            withBg={true}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  SpecificationTable()
                                )}
                                <Stepper activeStep={this.state.defaultStep}>
                                  <Step
                                    onClick={() => {
                                      if (this.state.defaultStep === 1) {
                                        this.setState({ defaultStep: 0 });
                                      }
                                    }}
                                    style={{
                                      cursor: this.state.defaultStep
                                        ? "pointer"
                                        : "",
                                    }}
                                  >
                                    <StepLabel
                                      style={{
                                        marginLeft: "200px",
                                      }}
                                    >
                                      Add Item Details
                                    </StepLabel>
                                  </Step>
                                  <Step>
                                    <StepLabel
                                      style={{
                                        marginRight: "200px",
                                      }}
                                    >
                                      Add Multiple Specifications
                                    </StepLabel>
                                  </Step>
                                </Stepper>
                              </div>
                            </div>
                            {/* ended */}
                          </>
                        )}
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="Acceptance"
                      multiline
                      minRows={2}
                      maxRows={5}
                      value={
                        Acceptance ? Acceptance : "" //
                      }
                      error={errorMsg.Acceptance ? true : false}
                      helperText={
                        errorMsg.Acceptance ? errorMsg.Acceptance : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 10px 0 0",
                        } //disabled={tp}
                      }
                      type="text"
                      label="Acceptance"
                      variant="outlined"
                      name="Acceptance"
                      onChange={this.handleChange}
                    />{" "}
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="Eway"
                      multiline
                      minRows={2}
                      maxRows={5}
                      value={
                        Eway ? Eway : "" //
                      }
                      error={errorMsg.Eway ? true : false}
                      helperText={errorMsg.Eway ? errorMsg.Eway : ""}
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 0 0px 10px",
                        } //disabled={tp}
                      }
                      name="Eway"
                      onChange={this.handleChange}
                      label="Eway Bill"
                      variant="outlined"
                    />{" "}
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    {" "}
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="Loading"
                      multiline
                      minRows={2}
                      maxRows={5}
                      value={
                        Loading ? Loading : "" //
                      }
                      error={errorMsg.Loading ? true : false}
                      helperText={errorMsg.Loading ? errorMsg.Loading : ""}
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 10px 0px 0",
                        } //disabled={tp}
                      }
                      label="Loading"
                      name="Loading"
                      onChange={this.handleChange}
                      variant="outlined"
                    />{" "}
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="Cancellation"
                      multiline
                      minRows={2}
                      maxRows={5}
                      value={
                        Cancellation ? Cancellation : "" //
                      }
                      error={errorMsg.Cancellation ? true : false}
                      helperText={
                        errorMsg.Cancellation ? errorMsg.Cancellation : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 0 0px 10px",
                        } //disabled={tp}
                      }
                      label="Contract Cancellation"
                      variant="outlined"
                      name="Cancellation"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="packing_condition"
                      value={
                        packing_condition ? packing_condition : "" //
                      }
                      error={errorMsg.packing_condition ? true : false}
                      helperText={
                        errorMsg.packing_condition
                          ? errorMsg.packing_condition
                          : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 10px 0px 0px",
                        } //disabled={tp}
                      }
                      label="Packing Condition"
                      variant="outlined"
                      name="packing_condition"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="brokerage_commission"
                      multiline
                      minRows={2}
                      maxRows={5}
                      value={
                        brokerage_commission ? brokerage_commission : "" //
                      }
                      error={errorMsg.brokerage_commission ? true : false}
                      helperText={
                        errorMsg.brokerage_commission
                          ? errorMsg.brokerage_commission
                          : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 10px 0px 0px",
                        } //disabled={tp}
                      }
                      label="Broker Commission"
                      variant="outlined"
                      name="brokerage_commission"
                      onChange={this.handleChange}
                    />
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="short_shipments_and_penalty"
                      multiline
                      minRows={2}
                      maxRows={5}
                      value={
                        short_shipments_and_penalty
                          ? short_shipments_and_penalty
                          : "" //
                      }
                      error={
                        errorMsg.short_shipments_and_penalty ? true : false
                      }
                      helperText={
                        errorMsg.short_shipments_and_penalty
                          ? errorMsg.short_shipments_and_penalty
                          : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 0 0px 15px",
                        } //disabled={tp}
                      }
                      label="Short Shipments & Penalty"
                      variant="outlined"
                      name="short_shipments_and_penalty"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="other_conditions"
                      multiline
                      minRows={14}
                      maxRows={20}
                      value={
                        other_conditions ? other_conditions : "" //
                      }
                      error={errorMsg.other_conditions ? true : false}
                      helperText={
                        errorMsg.other_conditions
                          ? errorMsg.other_conditions
                          : ""
                      }
                      style={
                        {
                          width: "calc(100% - 15px)",
                          margin: "10px 0px 0px 0px",
                        } //disabled={tp}
                      }
                      label="Other Conditions"
                      variant="outlined"
                      name="other_conditions"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    {" "}
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="delivery_terms"
                      value={
                        delivery_terms ? delivery_terms : "" //
                      }
                      error={errorMsg.delivery_terms ? true : false}
                      helperText={
                        errorMsg.delivery_terms ? errorMsg.delivery_terms : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 10px 0px 0",
                        } //disabled={tp}
                      }
                      label="Delivery Terms"
                      name="delivery_terms"
                      onChange={this.handleChange}
                      variant="outlined"
                    />{" "}
                    <CssTextField
                      required={false}
                      autoComplete="off"
                      id="delivery_place"
                      value={
                        delivery_terms_extras ? delivery_terms_extras : "" //
                      }
                      error={errorMsg.delivery_terms_extras ? true : false}
                      helperText={
                        errorMsg.delivery_terms_extras
                          ? errorMsg.delivery_terms_extras
                          : ""
                      }
                      style={
                        {
                          width: "calc(50% - 20px)",
                          margin: "10px 0 0px 10px",
                        } //disabled={tp}
                      }
                      label="Delivery Place"
                      variant="outlined"
                      name="delivery_terms_extras"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",

                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "15px 10px 0",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #80808042",
                        padding: "10px",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ color: "black" }}>Payment Terms : </span>
                      <span style={{ margin: "0 10px" }}>
                        Payment shall be made by RTGS within
                      </span>{" "}
                      <CssCreateTextField
                        required={!requiredToggle ? true : false}
                        autoComplete="off"
                        value={
                          PaymentTerm ? PaymentTerm : "" //
                        }
                        id="payment-term"
                        error={errorMsg.PaymentTerm ? true : false}
                        helperText={
                          errorMsg.PaymentTerm ? errorMsg.PaymentTerm : ""
                        }
                        onChange={(e) => {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.PaymentTerm = false;

                          this.setState({
                            errorMsg,
                            PaymentTermId: filterData(
                              PaymentTermList,
                              "days_range",
                              e.target.value,
                              "onlyOne"
                            )
                              ? filterData(
                                  PaymentTermList,
                                  "days_range",
                                  e.target.value,
                                  "onlyOne"
                                ).id
                              : "",
                            PaymentTerm: e.target.value,
                          });
                        }}
                        style={
                          { width: "calc(15% - 5px)" } //disabled={tp}
                        }
                        select
                        label="Working Day"
                        variant="outlined"
                        SelectProps={{ MenuProps: MenuProps }}
                      >
                        {PaymentTermList &&
                          PaymentTermList.length > 0 &&
                          PaymentTermList.map((option) => (
                            <MenuItem
                              key={option.days_range.toString()}
                              value={option.days_range}
                              id={option.id}
                            >
                              {option.days_range}
                            </MenuItem>
                          ))}
                      </CssCreateTextField>
                      {/* )} */}
                      <span style={{ margin: "0 10px" }}>
                        WD after receipt of material in our CHA godown /CFS.
                      </span>
                    </div>
                  </div>
                  {console.log(SalesOrderList, "SalesOrderList")}
                  {/* <div
                      style={{
                        border: "1px solid #80808042",
                        padding: "15px",
                        width: "100%",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        margin: "0 10px",
                      }}
                    >
                      <span style={{ color: "black" }}>Delivery Terms : </span>
                      <span style={{ margin: "0 10px" }}>
                        {contractTypeName} Delivered in buyer’s nominated CHA’s
                        warehouse in{" "}
                        <span
                          style={{
                            margin: "0 5px",
                            textTransform: "capitalize",
                            fontWeight: "600",
                          }}
                        >
                          <CssCreateTextField
                            required={false}
                            autoComplete="off"
                            value={
                              delivery_terms_extras ? delivery_terms_extras : "" //
                            }
                            id="Place"
                            error={
                              errorMsg.delivery_terms_extras ? true : false
                            }
                            helperText={
                              errorMsg.delivery_terms_extras
                                ? errorMsg.delivery_terms_extras
                                : ""
                            }
                            onChange={(e) => {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.PaymentTerm = false;

                              this.setState({
                                errorMsg,
                                delivery_terms_extras: e.target.value,
                              });
                            }}
                            style={
                              { width: "calc(25% - 5px)" } //disabled={tp}
                            }
                            label="Delivery Place1"
                            variant="outlined"
                          />
                        </span>{" "}
                        by trucks.
                      </span>{" "}
                    </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                    }}
                  >
                    <CssTextField
                      autoComplete="off"
                      style={
                        { width: "100%", margin: "10px" } //disabled={tp}
                      }
                      // error={errorMsg.Remark ? true : false}
                      // helperText={
                      //   errorMsg.Remark ? "Please Add Remark!" : false
                      // }
                      id="outlined-basic"
                      label="Remarks"
                      variant="outlined"
                      name="Remark"
                      value={Remark}
                      onChange={this.handleChange}
                    />
                  </div>
                </>
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}
            >
              {" "}
              {added ? (
                editDialog !== false ? (
                  <StyledButton
                    onClick={() => {
                      this.reset("no snac");
                      this.handleClearFields();
                    }}
                    name={"Add Another"}
                    withBg={true}
                    background={primary}
                    width="120px"
                    margin="0 10px 0 0"
                  />
                ) : (
                  ""
                )
              ) : (
                <>
                  <StyledButton
                    onClick={() => {
                      this.reset();
                    }}
                    name={editDialog !== false ? "Reset to default" : "Reset"}
                    // width="120px"
                    margin="0 10px 0 0"
                    // background="rgb(30 53 89)"
                    // color="white"
                  />

                  <StyledButton
                    name={editDialog === false ? "Submit" : "Update"}
                    withBg={true}
                    background={"#1a9520"}
                    width="120px"
                    disabled={this.state?.submitButtonClicked}
                    onClick={() => {
                      if (contractTypeName === "ex loose") {
                        console.log(this.state, loosePoItem);
                        this.handleCreateEditLoosePO(
                          editDialog === false ? "add" : "edit"
                        );
                        // loosePoItem
                      } else {
                        if (Unit) {
                          if (contractTypeName) {
                            if (BorkerName || requiredToggle) {
                              if (portArry.length > 0 || requiredToggle) {
                                if (MillerName) {
                                  if (MillerAddress || requiredToggle) {
                                    if (MillerGST || requiredToggle) {
                                      // if (ContactPerson) {
                                      if (
                                        !SalesOrder ||
                                        (SalesOrder &&
                                          selectedSOItems.length > 0) ||
                                        requiredToggle
                                      ) {
                                        if (
                                          SalesOrder ||
                                          (!SalesOrder &&
                                            poItemsArray.length > 0) ||
                                          requiredToggle
                                        ) {
                                          if (PaymentTerm || requiredToggle) {
                                            // if (Remark) {
                                            this.setState({
                                              loadingFun: true,
                                            });

                                            const addData = {
                                              contract_date:
                                                moment(searchedData).format(
                                                  "YYYY-MM-DD"
                                                ),
                                              miller: Number(MillerNameId),

                                              status: selctedPOStatus,
                                              broker: BorkerNameId,
                                              payment_terms: PaymentTermId,

                                              unit: Unit,
                                              sales_order: SalesOrder
                                                ? SalesOrderId
                                                : "",
                                              remark: Remark,
                                              delivery_terms: Boolean(
                                                delivery_terms
                                              )
                                                ? delivery_terms
                                                : "",
                                              delivery_terms_extras: Boolean(
                                                delivery_terms_extras
                                              )
                                                ? delivery_terms_extras
                                                : "",
                                              po_number: poNum,
                                              acceptance_of_purchase_order:
                                                Boolean(Acceptance)
                                                  ? Acceptance
                                                  : "",
                                              eway_bill: Boolean(Eway)
                                                ? Eway
                                                : "",
                                              loading: Boolean(Loading)
                                                ? Loading
                                                : "",
                                              contract_cancellation: Boolean(
                                                Cancellation
                                              )
                                                ? Cancellation
                                                : "",
                                              packing_condition: Boolean(
                                                packing_condition
                                              )
                                                ? packing_condition
                                                : "",
                                              brokerage_commission: Boolean(
                                                brokerage_commission
                                              )
                                                ? brokerage_commission
                                                : "",
                                              short_shipments_and_penalty:
                                                Boolean(
                                                  short_shipments_and_penalty
                                                )
                                                  ? short_shipments_and_penalty
                                                  : "",
                                              other_conditions: Boolean(
                                                other_conditions
                                              )
                                                ? other_conditions
                                                : "",
                                              contract_type:
                                                Number(contractTypeId),
                                              ports: portArry.map((sP) => {
                                                return sP.id;
                                              }),
                                              brokerage: BorkerageId,
                                              last_survey: last_survey
                                                ? moment(last_survey).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                : null,
                                              total_quantity: SalesOrderId
                                                ? selectedSOItems.reduce(
                                                    (
                                                      previousValue,
                                                      currentValue
                                                    ) =>
                                                      Number(previousValue) +
                                                      Number(
                                                        submittedItemData[
                                                          currentValue
                                                        ]
                                                          ? submittedItemData[
                                                              currentValue
                                                            ]
                                                          : 0
                                                      ),
                                                    0
                                                  )
                                                : poItemsArray.reduce(
                                                    (
                                                      previousValue,
                                                      currentValue
                                                    ) =>
                                                      Number(previousValue) +
                                                      Number(
                                                        currentValue[
                                                          "Total Item Quantity"
                                                        ]
                                                      ),
                                                    0
                                                  ),
                                              total_po_quantity: SalesOrderId
                                                ? selectedSOItems.reduce(
                                                    (
                                                      previousValue,
                                                      currentValue
                                                    ) =>
                                                      Number(previousValue) +
                                                      Number(
                                                        submittedItemData[
                                                          currentValue
                                                        ]
                                                          ? submittedItemData[
                                                              currentValue
                                                            ]
                                                          : 0
                                                      ),
                                                    0
                                                  )
                                                : poItemsArray.reduce(
                                                    (
                                                      previousValue,
                                                      currentValue
                                                    ) =>
                                                      Number(previousValue) +
                                                      Number(
                                                        currentValue[
                                                          "Total Item Quantity"
                                                        ]
                                                      ),
                                                    0
                                                  ),
                                            };
                                            if (editDialog === false) {
                                              addData.approval_status =
                                                this.props?.user?.roles
                                                  .toString()
                                                  .toLowerCase() === "admin"
                                                  ? "ACCEPTED"
                                                  : "PENDING";
                                            }
                                            const soErrors = [];
                                            if (
                                              SalesOrder &&
                                              selectedSOItems.length > 0
                                            ) {
                                              selectedSOItems.map(
                                                (singleItem) => {
                                                  console.log(
                                                    submittedItemData,
                                                    "submittedItemData"
                                                  );
                                                  if (
                                                    submittedItemData[
                                                      singleItem
                                                    ] &&
                                                    Number(
                                                      submittedItemData[
                                                        singleItem
                                                      ]
                                                    ) !== 0
                                                  ) {
                                                    console.log(singleItem);
                                                  } else {
                                                    if (
                                                      !soErrors.includes(
                                                        singleItem
                                                      )
                                                    )
                                                      soErrors.push(singleItem);
                                                  }
                                                }
                                              );
                                            }

                                            if (editDialog === false) {
                                              addData["is_active"] = true;
                                            }
                                            if (editDialog === false) {
                                              addData[
                                                "dispatched_quantity"
                                              ] = 0;
                                              addData["remaining_quantity"] =
                                                SalesOrderId
                                                  ? selectedSOItems.reduce(
                                                      (
                                                        previousValue,
                                                        currentValue
                                                      ) =>
                                                        Number(previousValue) +
                                                        Number(
                                                          submittedItemData[
                                                            currentValue
                                                          ]
                                                            ? submittedItemData[
                                                                currentValue
                                                              ]
                                                            : 0
                                                        ),
                                                      0
                                                    )
                                                  : poItemsArray.reduce(
                                                      (
                                                        previousValue,
                                                        currentValue
                                                      ) =>
                                                        Number(previousValue) +
                                                        Number(
                                                          currentValue[
                                                            "Total Item Quantity"
                                                          ]
                                                        ),
                                                      0
                                                    );
                                            }
                                            const addData_PO_ITEM = [];
                                            const oldPoItem = [];
                                            const newPoItem = [];
                                            const newPOAddObj = {};

                                            newPOAddObj["purchase_order"] =
                                              addData;
                                            console.log(
                                              SalesOrderId,
                                              "SalesOrderId"
                                            );
                                            if (SalesOrderId) {
                                              if (selectedSOItems.length > 0) {
                                                // if (editDialog !== false) {
                                                //   poItemsArray.map((s) => {
                                                //     if (
                                                //       !selectedSOItems.includes(
                                                //         s
                                                //       )
                                                //     ) {
                                                //       addData_PO_ITEM.push({
                                                //         id: s.id,
                                                //       });
                                                //     }
                                                //     console.log(
                                                //       selectedSOItems,
                                                //       "selectedSOItems"
                                                //     );
                                                //     return s;
                                                //   });
                                                // }
                                                var doMap = true;
                                                selectedSOItems.map(
                                                  (single) => {
                                                    const filterSoitem =
                                                      filterData(
                                                        soitemsList,
                                                        "id",
                                                        Number(single),
                                                        "onlyOne"
                                                      )
                                                        ? filterData(
                                                            soitemsList,
                                                            "id",
                                                            Number(single),
                                                            "onlyOne"
                                                          )
                                                        : undefined;

                                                    if (
                                                      doMap === true &&
                                                      filterSoitem &&
                                                      filterSoitem !== undefined
                                                    ) {
                                                      const poData = filterData(
                                                        poItemsArray,
                                                        "soitem_id",
                                                        Number(single),
                                                        "onlyOne"
                                                      )
                                                        ? filterData(
                                                            poItemsArray,
                                                            "soitem_id",
                                                            Number(single),
                                                            "onlyOne"
                                                          )
                                                        : undefined;
                                                      const newData = {};
                                                      newData["item_category"] =
                                                        this.state
                                                          .ItemCategoryId
                                                          ? this.state
                                                              .ItemCategoryId
                                                          : filterSoitem
                                                              .brand_details
                                                              .item_category ||
                                                            null;
                                                      newData["item_type"] =
                                                        this.state.itemsId
                                                          ? this.state.itemsId
                                                          : filterSoitem
                                                              .brand_details
                                                              .item_type ||
                                                            null;
                                                      newData["brand"] =
                                                        filterSoitem.brand;
                                                      newData["contract_rate"] =
                                                        filterSoitem.contract_rate;
                                                      newData[
                                                        "sales_tax_terms"
                                                      ] =
                                                        filterSoitem.sales_tax_terms;
                                                      const specificationsArray =
                                                        poData
                                                          ? poData.specifications
                                                          : filterSoitem.so_specifications;
                                                      console.log(
                                                        poData,
                                                        specificationsArray,
                                                        "specificationsArray"
                                                      );
                                                      if (
                                                        specificationsArray.length >
                                                        0
                                                      ) {
                                                        newData[
                                                          "specifications"
                                                        ] =
                                                          specificationsArray.map(
                                                            (s) => {
                                                              delete s.created_at;

                                                              delete s.created_by;

                                                              delete s.modified_by;

                                                              delete s.modified_at;

                                                              return s;
                                                            }
                                                          );
                                                      }
                                                      // newData["specifications"] =
                                                      //   filterSoitem.so_specifications;
                                                      newData["hsn_code"] =
                                                        filterSoitem.hsn_code;
                                                      newData["packaging"] =
                                                        filterSoitem.packaging;

                                                      if (
                                                        editDialog === false
                                                      ) {
                                                        newData[
                                                          "po_packaging"
                                                        ] =
                                                          filterSoitem.packaging.map(
                                                            (sP) => {
                                                              return {
                                                                packaging: sP,
                                                              };
                                                            }
                                                          );
                                                      }
                                                      newData[
                                                        "total_quantity"
                                                      ] =
                                                        submittedItemData[
                                                          single
                                                        ];
                                                      newData["soitem_id"] =
                                                        single;

                                                      if (
                                                        editDialog !== false &&
                                                        poData
                                                      ) {
                                                        newData["id"] =
                                                          poData.id;

                                                        // newData["po_items"] =
                                                        //   poData.id;
                                                        newData[
                                                          "remaining_bags"
                                                        ] =
                                                          poData.remaining_bags
                                                            ? poData.remaining_bags
                                                            : 0;
                                                        newData["used_bags"] =
                                                          poData.used_bags
                                                            ? poData.used_bags
                                                            : 0;
                                                        newData[
                                                          "dispatched_quantity"
                                                        ] =
                                                          poData.dispatched_quantity
                                                            ? poData.dispatched_quantity
                                                            : 0;

                                                        newData[
                                                          "purchase_order"
                                                        ] = editDialog.id;
                                                      } else if (editDialog) {
                                                        newData[
                                                          "purchase_order"
                                                        ] = editDialog.id;
                                                      }
                                                      console.log(
                                                        "filterSoitem",
                                                        filterSoitem,
                                                        Boolean(
                                                          filterSoitem.brand
                                                        )
                                                      );
                                                      if (
                                                        Boolean(
                                                          filterSoitem.brand
                                                        )
                                                      ) {
                                                        addData_PO_ITEM.push(
                                                          newData
                                                        );
                                                      }
                                                    } else {
                                                      doMap = false;
                                                      scrollDiv("selectSOItem");
                                                      const errorMsg =
                                                        this.state.errorMsg;
                                                      errorMsg.selectedSOItems =
                                                        "Please select so items!";

                                                      this.setState({
                                                        errorMsg,
                                                      });
                                                    }

                                                    return single;
                                                  }
                                                );
                                              } else {
                                                // const errorMsg =
                                                //   this.state.errorMsg;
                                                // scrollDiv(`so${soErrors[0]}`);
                                                // soErrors.map((s) => {
                                                //   errorMsg[`so${s}`] = true;
                                                // });
                                                // console.log(
                                                //   errorMsg,
                                                //   "errorMsg"
                                                // );
                                                // // errorMsg.SalesOrder = "SO Quantity is less!";
                                                // this.setState({
                                                //   loadingFun: false,
                                                //   errorMsg,
                                                // });
                                                scrollDiv("selectSOItem");
                                                const errorMsg =
                                                  this.state.errorMsg;
                                                errorMsg.selectedSOItems =
                                                  "Please select so items!";

                                                this.setState({
                                                  errorMsg,
                                                });
                                              }
                                            } else {
                                              poItemsArray.map((ss) => {
                                                console.log(ss);
                                                const newData = {};
                                                newData["item_category"] =
                                                  ss["Item Categoryid"] || null;
                                                if (
                                                  SalesOrderId &&
                                                  ss.soitem_id
                                                ) {
                                                  newData["soitem_id"] =
                                                    ss.soitem_id;
                                                }
                                                newData["item_type"] =
                                                  ss["Item Typeid"] || null;
                                                newData["brand"] =
                                                  ss["Brandid"];
                                                newData["contract_rate"] =
                                                  ss["Contract Rate"];
                                                newData["sales_tax_terms"] =
                                                  ss.sales_tax_terms;
                                                if (
                                                  ss.specificationArr &&
                                                  ss.specificationArr.length > 0
                                                ) {
                                                  const new_specifications = [];
                                                  const old_specifications = [];
                                                  ss.specificationArr.map(
                                                    (spec) => {
                                                      console.log(
                                                        spec,
                                                        "specspec"
                                                      );
                                                      if (spec.created_at) {
                                                        old_specifications.push(
                                                          {
                                                            name: Number(
                                                              spec.nameId ??
                                                                spec.name
                                                            ),
                                                            condition:
                                                              spec.conditionId ===
                                                              "no_condition"
                                                                ? ""
                                                                : spec.conditionId
                                                                ? spec.conditionId ??
                                                                  ""
                                                                : spec.condition ??
                                                                  "",
                                                            value: spec.value,
                                                            id: spec.id,
                                                            po_items:
                                                              spec.po_items,
                                                          }
                                                        );
                                                      } else {
                                                        new_specifications.push(
                                                          {
                                                            name: Number(
                                                              spec.nameId ??
                                                                spec.name
                                                            ),
                                                            condition:
                                                              spec.conditionId ===
                                                              "no_condition"
                                                                ? ""
                                                                : spec.conditionId
                                                                ? spec.conditionId ??
                                                                  ""
                                                                : spec.condition ??
                                                                  "",
                                                            value: spec.value,
                                                            po_items:
                                                              editDialog !==
                                                              false
                                                                ? ss.id
                                                                : "",
                                                          }
                                                        );
                                                      }
                                                      return spec;
                                                    }
                                                  );
                                                  newData[
                                                    "new_specifications"
                                                  ] = new_specifications;
                                                  newData["specifications"] =
                                                    editDialog !== false
                                                      ? old_specifications
                                                      : new_specifications;
                                                }

                                                newData["hsn_code"] =
                                                  ss["HSN Codeid"];
                                                if (editDialog !== false) {
                                                  newData["packaging"] =
                                                    ss.packagingArr &&
                                                    ss.packagingArr.length > 0
                                                      ? ss.packagingArr.map(
                                                          (sp) => {
                                                            return Number(
                                                              sp.id
                                                            );
                                                          }
                                                        )
                                                      : ss.packagingArr &&
                                                        ss.packagingArr.length >
                                                          0
                                                      ? ss.packagingArr.map(
                                                          (sp) => {
                                                            const newIb = {
                                                              packaging: Number(
                                                                sp.id
                                                              ),
                                                            };
                                                            return newIb;
                                                          }
                                                        )
                                                      : [];
                                                  // newData["packaging"] =
                                                  //   ss.packaging &&
                                                  //   ss.packaging.length > 0
                                                  //     ? ss.packaging.map((sp) => {
                                                  //         return Number(sp);
                                                  //       })
                                                  //     : ss.packaging &&
                                                  //       ss.packaging.length > 0
                                                  //     ? ss.packaging.map((sp) => {
                                                  //         const newIb = {
                                                  //           packaging: Number(sp),
                                                  //         };
                                                  //         return newIb;
                                                  //       })
                                                  //     : [];
                                                  newData["purchase_order"] =
                                                    editDialog.id;
                                                } else {
                                                }
                                                if (
                                                  editDialog === false ||
                                                  SalesOrderId
                                                ) {
                                                  newData["po_packaging"] =
                                                    ss.packaging &&
                                                    ss.packaging.length > 0
                                                      ? ss.packaging.map(
                                                          (sp) => {
                                                            const newIb = {
                                                              packaging:
                                                                Number(sp),
                                                            };
                                                            return newIb;
                                                          }
                                                        )
                                                      : [];
                                                }
                                                newData["total_quantity"] =
                                                  ss["Total Item Quantity"];
                                                if (editDialog !== false) {
                                                  newData["id"] = ss.id;
                                                  newData["remaining_bags"] =
                                                    ss.remaining_bags
                                                      ? ss.remaining_bags
                                                      : 0;
                                                  newData["used_bags"] =
                                                    ss.used_bags
                                                      ? ss.used_bags
                                                      : 0;
                                                  newData[
                                                    "dispatched_quantity"
                                                  ] = ss.dispatched_quantity
                                                    ? ss.dispatched_quantity
                                                    : 0;
                                                }
                                                newData["soitem_id"] =
                                                  SalesOrderId
                                                    ? ss.soitem_id
                                                    : null;
                                                console.log(
                                                  "ssBrand",
                                                  ss,
                                                  Boolean(ss["Brandid"])
                                                );
                                                if (Boolean(ss["Brandid"])) {
                                                  addData_PO_ITEM.push(newData);
                                                }
                                                return ss;
                                              });
                                            }

                                            addData_PO_ITEM.map(
                                              (item, index) => {
                                                console.log(
                                                  item,
                                                  tableLen,
                                                  "itdditemem",
                                                  index,
                                                  index >= tableLen,
                                                  !item.id
                                                );
                                                if (tableLen > 0) {
                                                  if (
                                                    index >= tableLen ||
                                                    !item.id
                                                  ) {
                                                    const obj = item;
                                                    delete obj.id;
                                                    if (
                                                      item.specifications &&
                                                      item.specifications
                                                        .length === 0
                                                    ) {
                                                      delete obj.specifications;

                                                      obj.new_specifications.map(
                                                        (item, index) => {
                                                          delete item.po_items;
                                                        }
                                                      );
                                                    }
                                                    newPoItem.push(obj);
                                                  } else {
                                                    if (
                                                      item?.sales_tax_terms ===
                                                      undefined
                                                    ) {
                                                      item.sales_tax_terms =
                                                        null;
                                                    }
                                                    oldPoItem.push(item);
                                                  }
                                                } else {
                                                  let obj2 = { ...item };
                                                  obj2.contract_rate =
                                                    item.contract_rate || 0;
                                                  obj2.total_quantity =
                                                    item.total_quantity ||
                                                    parseFloat(
                                                      Number(0.0).toFixed(2)
                                                    );
                                                  obj2.hsn_code =
                                                    item.hsn_code || null;
                                                  console.log("obj", obj2);
                                                  newPoItem.push(obj2);
                                                }
                                              }
                                            );

                                            newPOAddObj["po_items"] =
                                              addData_PO_ITEM;

                                            if (
                                              SalesOrderId &&
                                              soErrors.length > 0
                                              //   (poItemsArray.reduce(
                                              //   (
                                              //     previousValue,
                                              //     currentValue
                                              //   ) =>
                                              //     Number(previousValue) +
                                              //     Number(
                                              //       currentValue[
                                              //         "Total Item Quantity"
                                              //       ]
                                              //     ),
                                              //   0
                                              // )
                                              // >
                                              // (filterData(SalesOrderList,"id",Number(SalesOrderId),"onlyOne")?Number(filterData(SalesOrderList,"id",Number(SalesOrderId),"onlyOne").total_quantity):"")))
                                            ) {
                                              console.log(
                                                soErrors,
                                                "insideserdtgyujioi"
                                              );
                                              const errorMsg =
                                                this.state.errorMsg;
                                              scrollDiv(`so${soErrors[0]}`);
                                              soErrors.map((s) => {
                                                errorMsg[`so${s}`] = true;
                                              });
                                              console.log(errorMsg, "errorMsg");
                                              // errorMsg.SalesOrder = "SO Quantity is less!";
                                              this.setState({
                                                loadingFun: false,
                                                errorMsg,
                                              });
                                            } else {
                                              this.setState({
                                                submitButtonClicked: true,
                                              });
                                              this.props
                                                .PurchaseOrdersFunction(
                                                  editDialog !== false
                                                    ? "patch"
                                                    : "post",
                                                  editDialog !== false
                                                    ? "dispatch/purchase-order"
                                                    : "dispatch/purchase-order/create",
                                                  editDialog !== false
                                                    ? editDialog.id
                                                    : null,
                                                  null,
                                                  editDialog !== false
                                                    ? addData
                                                    : newPOAddObj,
                                                  null,
                                                  "noeditparam"
                                                )
                                                .then((res) => {
                                                  this.setState({
                                                    submitButtonClicked: false,
                                                  });
                                                  if (res.error) {
                                                    this.setState({
                                                      error:
                                                        typeof res.data ===
                                                        "string"
                                                          ? res.data
                                                          : res.data.length > 0
                                                          ? res.data
                                                              .map((s) => {
                                                                return `${
                                                                  s.field
                                                                    ? `${s.field} :`
                                                                    : ""
                                                                } ${
                                                                  typeof s.message ===
                                                                  "string"
                                                                    ? s.message
                                                                    : s.message
                                                                        .length >
                                                                        0 &&
                                                                      s
                                                                        .message[0] &&
                                                                      s
                                                                        .message[0]
                                                                }`;
                                                              })
                                                              .join(" ,")
                                                          : res.data,
                                                      loadingFun: false,
                                                    });
                                                  } else {
                                                    console.log(
                                                      "HEREE",
                                                      editDialog,
                                                      editDialog !== false
                                                    );
                                                    if (editDialog !== false) {
                                                      console.log(
                                                        addData_PO_ITEM,
                                                        "adddata_po_item",
                                                        oldPoItem,
                                                        "oldPoItem"
                                                      );
                                                      // this.setState({ loadingFun: false, })
                                                      if (
                                                        oldPoItem.length > 0
                                                      ) {
                                                        this.props
                                                          .PurchaseOrdersFunction(
                                                            editDialog !== false
                                                              ? "put"
                                                              : "post",
                                                            editDialog !== false
                                                              ? "dispatch/po-items/bulk-update"
                                                              : "dispatch/po-items/list",
                                                            null,
                                                            null,
                                                            oldPoItem
                                                          )
                                                          .then((res2) => {
                                                            if (res2.error) {
                                                              this.setState({
                                                                error:
                                                                  typeof res2.data ===
                                                                  "string"
                                                                    ? res2.data
                                                                    : res2.data
                                                                        .length >
                                                                      0
                                                                    ? res2.data
                                                                        .map(
                                                                          (
                                                                            s
                                                                          ) => {
                                                                            return `${
                                                                              s.field
                                                                                ? `${s.field} :`
                                                                                : ""
                                                                            } ${
                                                                              typeof s.message ===
                                                                              "string"
                                                                                ? s.message
                                                                                : s
                                                                                    .message
                                                                                    .length >
                                                                                    0 &&
                                                                                  s
                                                                                    .message[0] &&
                                                                                  s
                                                                                    .message[0]
                                                                            }`;
                                                                          }
                                                                        )
                                                                        .join(
                                                                          " ,"
                                                                        )
                                                                    : res2.data,
                                                                loadingFun: false,
                                                              });
                                                            } else if (
                                                              newPoItem.length <=
                                                              0
                                                            ) {
                                                              console.log(
                                                                "here"
                                                              );
                                                              this.props
                                                                .PurchaseOrdersFunction(
                                                                  "get",
                                                                  `dispatch/purchase-order/${editDialog.id}`,
                                                                  null,
                                                                  null,
                                                                  null,
                                                                  null
                                                                )
                                                                .then(
                                                                  (resp) => {
                                                                    this.setState(
                                                                      {
                                                                        loadingFun: false,
                                                                        submitButtonClicked: false,
                                                                        success:
                                                                          editDialog
                                                                            ? "Purchase Order Updated!"
                                                                            : "Purchase Order Added!",
                                                                      },
                                                                      () => {
                                                                        localStorage.setItem(
                                                                          "selected-po",
                                                                          resp
                                                                        );
                                                                        this.props.updateSelectedTabData(
                                                                          resp,
                                                                          resp.id,
                                                                          window.location.pathname.split(
                                                                            "/"
                                                                          )[3],
                                                                          editDialog,
                                                                          editDialog ? 'edit' : 'create'
                                                                        );
                                                                      }
                                                                    );
                                                                  }
                                                                );
                                                            }
                                                          });
                                                      }
                                                      if (
                                                        editDialog !== false &&
                                                        newPoItem.length > 0
                                                      ) {
                                                        this.props
                                                          .PurchaseOrdersFunction(
                                                            "post",
                                                            "dispatch/po-items/bulk-create",
                                                            null,
                                                            null,
                                                            newPoItem
                                                          )
                                                          .then((res) => {
                                                            console.log(
                                                              oldPoItem,
                                                              newPoItem,
                                                              "bbbbbbbbb"
                                                            );
                                                          });
                                                      }
                                                      localStorage.setItem(
                                                        "selected-po",
                                                        singlelistView
                                                      );
                                                      this.props
                                                        .PurchaseOrdersFunction(
                                                          "get",
                                                          `dispatch/purchase-order/${editDialog.id}`,
                                                          null,
                                                          null,
                                                          null,
                                                          null
                                                        )
                                                        .then((resp) => {
                                                          if (
                                                            editDialog !== false
                                                          ) {
                                                            this.props.setPoItemsPrev(
                                                              poItemsArray,
                                                              poItemsArray,
                                                              poItemsArray
                                                            );
                                                          }
                                                          this.setState(
                                                            {
                                                              // added:
                                                              // "Purchase Order Added!",
                                                              loadingFun: false,
                                                              submitButtonClicked: false,
                                                              success:
                                                                editDialog
                                                                  ? "Purchase Order Updated!"
                                                                  : "Purchase Order Added!",
                                                            },
                                                            () => {
                                                              this.props.updateSelectedTabData(
                                                                resp,
                                                                resp.id,
                                                                window.location.pathname.split(
                                                                  "/"
                                                                )[3],
                                                                editDialog,
                                                                editDialog ? 'edit' : 'create'
                                                              );
                                                              // this.props?.listClickHandler(resp, 'reload')
                                                            }
                                                          );
                                                        });
                                                    } else {
                                                      this.setState({
                                                        submitButtonClicked: false,
                                                      });
                                                      this.props.closeDialog();
                                                      // this.reset("no snac");
                                                      this.handleClearFields();
                                                      localStorage.setItem(
                                                        "selected-po",
                                                        singlelistView
                                                      );

                                                      // this.props.selectHandler(selctedPOStatus || 'OPEN', 'dropdownChange')
                                                      this.props.selectHandler(
                                                        "OPEN"
                                                      );
                                                    }
                                                  }
                                                });
                                            }
                                          } else {
                                            const errorMsg =
                                              this.state.errorMsg;

                                            scrollDiv("payment-term");
                                            errorMsg.PaymentTerm =
                                              "Please give an PaymentTerm!";
                                            this.setState({
                                              errorMsg,
                                            });
                                          }
                                        } else {
                                          scrollDiv("update-button");
                                          const errorMsg = this.state.errorMsg;
                                          // errorMsg.ItemCategory =
                                          //   "Please select an ItemCategory!";
                                          // errorMsg.ItemType =
                                          //   "Please select an ItemType!";
                                          // errorMsg.BrandName =
                                          //   "Please select an Brand!";
                                          // errorMsg.packagingArr =
                                          //   "Please select an Packaging!";
                                          // errorMsg.HSN =
                                          //   "Please select an HSN!";
                                          errorMsg.updatedButton = "Please add";
                                          this.setState({ errorMsg });
                                        }
                                      } else {
                                        scrollDiv("selectSOItem");
                                        const errorMsg = this.state.errorMsg;
                                        errorMsg.selectedSOItems =
                                          "Please select so items!";

                                        this.setState({ errorMsg });
                                      }
                                    } else {
                                      scrollDiv("miller");
                                      const errorMsg = this.state.errorMsg;
                                      errorMsg.MillerGST =
                                        "Please give an MillerGST!";

                                      this.setState({ errorMsg });
                                    }
                                  } else {
                                    scrollDiv("miller");
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.MillerAddress =
                                      "Please give an MillerAddress!";
                                    this.setState({ errorMsg });
                                  }
                                } else {
                                  const errorMsg = this.state.errorMsg;
                                  scrollDiv("miller");
                                  errorMsg.MillerName =
                                    "Please give an MillerName!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                scrollDiv("port");
                                const errorMsg = this.state.errorMsg;
                                errorMsg.portArry = "Please give Port!";

                                this.setState({ errorMsg });
                              }
                            } else {
                              scrollDiv("broker");
                              const errorMsg = this.state.errorMsg;
                              errorMsg.BorkerName =
                                "Please give an BorkerName!";

                              this.setState({ errorMsg });
                            }
                          } else {
                            // Update;
                            const errorMsg = this.state.errorMsg;
                            scrollDiv("contract-type");
                            errorMsg.contractTypeName =
                              "Please select a Contract Type!";
                            this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          scrollDiv("unit");
                          errorMsg.Unit = "Please select a Unit!";
                          this.setState({ errorMsg });
                        }
                      }
                    }}
                  />
                </>
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {shortCloseDialog === true ? (
          <Dialog
            open={this.state.shortCloseDialog}
            TransitionComponent={Transition}
            onClose={this.handleShortCloseDialog}
          >
            <DialogContent
              style={{
                borderRadius: 15,
                padding: 8,
                width: 400,
              }}
            >
              <div style={styles.shortCloseBox}>
                <div style={styles.shortCloseHeadingBox}>
                  <Typography style={styles.shortCloseInnerBoxTitle}>
                    PO Close
                  </Typography>
                </div>
                <div>
                  <div style={styles.shortCloseInnerContainer}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <span style={styles.shortCloseItemStyleValue}>
                        PO Quantity
                      </span>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {console.log("DATA", this.state, this.props)}
                        <span style={styles.shortCloseListStyleValue}>
                          {Number(
                            this.props.singlelistView.total_po_quantity
                          ).toFixed(3)}{" "}
                          {this.props.singlelistView.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={styles.shortCloseInnerContainer}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <span style={styles.shortCloseItemStyleValue}>
                        Dispatched
                      </span>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        <span style={styles.shortCloseListStyleValue}>
                          {Number(
                            this.props.singlelistView.total_dispatched_quantity
                          ).toFixed(3)}{" "}
                          {this.props.singlelistView.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={styles.shortCloseInnerContainer}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <span style={styles.shortCloseItemStyleValue}>
                        Remaining
                      </span>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        <span style={styles.shortCloseListStyleValue}>
                          {Number(
                            Number(
                              Number(
                                this.props.singlelistView.total_po_quantity
                              ).toFixed(3)
                            ) -
                              Number(
                                Number(
                                  this.props.singlelistView
                                    .total_dispatched_quantity
                                ).toFixed(3)
                              )
                          ).toFixed(3)}{" "}
                          {this.props.singlelistView.unit}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Divider style={{ margin: "25px 0" }} />
                  <Typography style={styles.shortCloseBottomText}>
                    Are you sure you want to close this Purchase Order?
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 30,
                      paddingRight: 40,
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={this.handleShortCloseDialog}
                      style={{
                        padding: "2.5px",
                        marginRight: 20,
                        fontSize: "0.9em",
                      }}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgb(241 154 48)",
                        color: "white",
                        padding: "2.5px",
                      }}
                      onClick={this.handleClosePo}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
        {cancelPO === true ? (
          <Dialog
            open={true}
            onClose={() => this.setState({ cancelPO: false })}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog" style={{ fontSize: "1.1em" }}>
              Cancel Purchase Order
            </DialogTitle>
            <DialogContent>
              Are you sure you want to cancel this Purchase Order ?
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({
                    cancelPO: false,
                  });
                }}
                style={{
                  backgroundColor: "rgb(241 154 48)",
                  color: "white",
                  padding: "2.5px",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(241 154 48)",
                  color: "white",
                  padding: "2.5px",
                }}
                onClick={this.handleCancelPo}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {onlyDialog !== true && singlelistView && (
          <>
            {pushNoti === true ? (
              <Dialog
                open={true}
                onClose={() => this.setState({ pushNoti: false })}
                aria-labelledby="confirm-dialog"
              >
                <DialogTitle id="confirm-dialog">
                  Purchase Order cannot be deleted!
                </DialogTitle>
                <DialogContent>
                  Dispatch/Survey Report already exists
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        pushNoti: false,
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            {confirmApp === true ? (
              <Dialog
                open={true}
                onClose={() => this.setState({ confirmApp: false })}
                aria-labelledby="confirm-dialog"
              >
                <DialogTitle id="confirm-dialog">Make Changes ?</DialogTitle>
                <DialogContent>
                  Are you sure you want to{" "}
                  {this.state.approve === true ? "approve" : "reject"} this
                  purchase order ?
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        confirmApp: false,
                        // approve: true,
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleConfirmApp}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            {confirmDel === true ? (
              <Dialog
                open={true}
                onClose={() => this.setState({ confirmDel: false })}
                aria-labelledby="confirm-dialog"
              >
                <DialogTitle id="confirm-dialog">Make Changes ?</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this Purchase Order ?
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.setState({
                        confirmDel: false,
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleConfirm}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                textTransform: "capitalize",
                borderRadius: " 5px",
                marginBottom: "10px",
              }}
            >
              {this.props?.user?.roles?.toString().toLowerCase() === "admin" &&
              singlelistView.approval_status === "PENDING" && this.props.singlelistView.status == 'OPEN' ? (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {/* <Tooltip title="Approve" arrow placement="top">
                    <div
                      onClick={() => {
                        this.setState({ confirmApp: true, approve: true });
                        console.log(confirmApp, "confirm delete");
                      }}
                      style={{ marginRight: "10px" }}>
                        
                      <CheckCircleOutlineIcon color="success" />
                    </div>
                  </Tooltip> */}

                  <Tooltip title="Approve this Arrival" arrow placement="top">
                    <Button
                      onClick={() => {
                        this.setState({ confirmApp: true, approve: true });
                      }}
                      style={{
                        marginRight: "10px",
                        color: "green",
                        marginRight: "10px",
                        textTransform: "capitalize",
                        border: "2px solid green",
                        padding: "3px 4px",
                      }}
                    >
                      Approve
                      {/* <CheckCircleOutlineIcon
                        fontSize="small"
                        color="success"
                      /> */}
                    </Button>
                  </Tooltip>

                  <Tooltip title="Reject this Arrival" arrow placement="top">
                    <Button
                      onClick={() => {
                        this.setState({ confirmApp: true, reject: true });
                      }}
                      style={{
                        marginRight: "10px",
                        color: "#d32f2f",
                        marginRight: "10px",
                        textTransform: "capitalize",
                        border: "2px solid #d32f2f",
                        padding: "3px 4px",
                      }}
                    >
                      Reject
                      {/* <CancelOutlinedIcon fontSize="small" color="error" /> */}
                      {/* <CancelOutlinedIcon color="error" /> */}
                    </Button>
                  </Tooltip>
                  {/* <Tooltip title="Reject" arrow placement="top">
                    <div
                      onClick={() => {
                        this.setState({ confirmApp: true, reject: true });
                        console.log(confirmApp, "confirm delete");
                      }}
                      style={{ marginRight: "10px" }}>
                      <CancelOutlinedIcon color="error" />
                    </div>
                  </Tooltip> */}
                </div>
              ) : (
                ""
              )}
              {1 > 0 ? (
                <>
                  {["DRAFT", "OPEN"].includes(
                    this.props.singlelistView.status
                  ) && (
                    <ActionMenu
                      menuItemsList={[
                        {
                          item: "Edit",
                          icon: <ModeOutlinedIcon fontSize="small" />,
                          onClick: () => {
                            if (
                              singlelistView
                              // !singlelistView.total_dispatched_quantity
                            ) {
                              console.log(singlelistView, "singlelistView");
                              this.setState(
                                {
                                  editDialog: singlelistView,
                                  loadingFun: true,
                                  showSpeci: false,
                                  requiredToggle: singlelistView?.status
                                    ? Boolean(singlelistView.status === "DRAFT")
                                    : false,
                                },
                                () => {
                                  this.setData(
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : ""
                                  );
                                }
                              );
                            } else {
                              this.setState({
                                error:
                                  "Dispatch exists, can't edit this Purchase Order",
                              });
                            }
                          },
                        },
                        {
                          item: "Delete",
                          icon: <DeleteIcon fontSize="small" />,
                          onClick: () => {
                            this.setState({ confirmDel: true });
                            console.log(confirmDel, "confirm delete");
                          },
                        },
                        Number(
                          this.props.singlelistView.total_dispatched_quantity
                        ) == Number(0)
                          ? {
                              item: "PO Cancel",
                              icon: <BackspaceIcon fontSize="small" />,
                              onClick: () => {
                                this.setState({ cancelPO: true });
                              },
                            }
                          : {
                              item: "PO Close",
                              icon: <MoveToInboxIcon fontSize="small" />,
                              onClick: () => {
                                this.setState({ shortCloseDialog: true });
                              },
                            },
                      ]}
                    />
                  )}
                  <PurchaseOrderPdf
                    specifiationTypes={this.props.specifiationTypes ?? []}
                    getSpecificationName={this.getSpecificationName}
                    tableList={poItemsArrayTOPreview}
                    header={[
                      "Serial No",
                      "Description",
                      `Net Qty (${
                        singlelistView && singlelistView.unit
                          ? singlelistView.unit
                          : ""
                      })`,
                      `Unit Price Per ${
                        singlelistView && singlelistView.unit
                          ? singlelistView.unit
                          : ""
                      } (Inclusive of all Taxes)`,
                      "Sales Tax Terms",
                    ]}
                    {...this.state}
                    {...this.props}
                    widthList={["40px", "180px", "80px", "120px", "80px"]}
                  />
                </>
              ) : (
                <>
                  <CssButton
                    style={{
                      background: "rgb(241 154 48)",
                      color: "white",
                      marginRight: "10px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      // this.props.openDialogFunct()

                      if (
                        singlelistView &&
                        !singlelistView.total_dispatched_quantity
                      ) {
                        this.setState(
                          {
                            editDialog: singlelistView,
                            loadingFun: true,
                            showSpeci: false,
                          },
                          () => {
                            this.setData(
                              singlelistView && singlelistView.unit
                                ? singlelistView.unit
                                : ""
                            );
                          }
                        );
                      } else {
                        this.setState({
                          error:
                            "Dispatch exists, can't edit this Purchase Order",
                        });
                      }
                    }}
                  >
                    Edit{" "}
                    <ModeOutlinedIcon
                      fontSize="small"
                      style={{ height: "17px", margin: "0 0 3px 0" }}
                    />
                  </CssButton>
                  <PurchaseOrderPdf
                    specifiationTypes={this.props.specifiationTypes ?? []}
                    getSpecificationName={this.getSpecificationName}
                    tableList={poItemsArrayTOPreview}
                    header={[
                      "Serial No",
                      "Description",
                      `Net Qty (${
                        singlelistView && singlelistView.unit
                          ? singlelistView.unit
                          : ""
                      })`,
                      `Unit Price Per ${
                        singlelistView && singlelistView.unit
                          ? singlelistView.unit
                          : ""
                      } (Inclusive of all Taxes)`,
                      "Sales Tax Terms",
                    ]}
                    {...this.state}
                    {...this.props}
                    widthList={["40px", "180px", "80px", "120px", "80px"]}
                  />
                  <CssButton
                    onClick={() => {
                      this.setState({ confirmDel: true });
                      console.log(confirmDel, "confirm delete");
                    }}
                    style={{
                      background: "rgb(241 154 48)",
                      color: "white",
                      textTransform: "capitalize",
                      margin: "0 3px 0 0",
                    }}
                  >
                    Delete <DeleteIcon fontSize="small" />
                  </CssButton>
                </>
              )}
            </div>
            <div
              className="scrollBluePrimary"
              style={{
                height: "calc(100% - 42.5px)",
                overflow: "auto",
                width: "100%",
              }}
            >
              {console.log(singlelistView, "details singlelistview")}{" "}
              <div
                style={{
                  height: "fit-content",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              >
                <div style={styles.innerDiv}>
                  <div style={styles.innerMainDiv}>
                    <Paper
                      style={{
                        maxWidth: "500px",
                        marginRight: "25px",
                        padding: "15px",
                        background: "#8080801a",
                        pointerEvents: "none",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          pointerEvents: "none",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          id="standard-multiline-flexible"
                          variant="standard"
                          style={
                            {
                              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                              width: "270px",
                              color: "black",
                            } //disabled={tp}
                          }
                          // id="outlined-disabled"
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="PO Number"
                          value={singlelistView && singlelistView.po_number}
                          InputProps={{
                            style: {
                              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              caretColor: "transparent",
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Contract Date"
                          value={
                            singlelistView &&
                            moment(singlelistView.contract_date).format(
                              "DD MMM YYYY"
                            )
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              caretColor: "transparent",
                              width: "270px",
                            } //disabled={tp}
                          }
                          label="SO Number"
                          value={
                            singlelistView && singlelistView.sales_order_details
                              ? singlelistView.sales_order_details.so_number
                              : ""
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              caretColor: "transparent",
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Broker"
                          value={
                            singlelistView &&
                            singlelistView?.broker_details?.name
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Survey Date"
                          // value={moment(
                          //   singlelistView && singlelistView.last_survey
                          // ).format("ddd, MMM Do YYYY")}

                          value={
                            singlelistView && singlelistView.last_survey
                              ? moment(
                                  singlelistView && singlelistView.last_survey
                                ).format("DD MMM YYYY")
                              : ""
                          }
                        />
                      </div>
                    </Paper>
                    {/* <div style={{ width: '33.3%' }}> */}{" "}
                    <Paper
                      style={{
                        maxWidth: "500px",
                        marginRight: "25px",
                        padding: "15px",
                        background: "#8080801a",
                      }}
                    >
                      {" "}
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          // maxRows={10}
                          variant="standard"
                          style={{
                            width: "270px",
                            color: "black",
                          }}
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="MillerName & Address"
                          value={`${
                            singlelistView.miller_details &&
                            singlelistView.miller_details.name
                          }, ${
                            singlelistView &&
                            singlelistView.miller_details.address
                          }`}
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={{
                            width: "270px",
                          }}
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Miller GST No."
                          value={
                            singlelistView &&
                            singlelistView.miller_details.gstin
                          }
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Contact Person"
                          value={
                            singlelistView &&
                            singlelistView.miller_details.contact_person
                          }
                        />
                      </div>
                      {/* <CssValueTextField
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        variant="standard"
                        style={
                          {
                            width: "270px",
                          } //disabled={tp}
                        }
                        onKeyDown={(evt) => evt.preventDefault()}
                        label="Survey Date"
                        // value={moment(
                        //   singlelistView && singlelistView.last_survey
                        // ).format("ddd, MMM Do YYYY")}

                        value={
                          singlelistView && singlelistView.last_survey
                            ? moment(
                                singlelistView && singlelistView.last_survey
                              ).format("ddd, MMM Do YYYY")
                            : ""
                        }
                      /> */}
                      {/* <div style={{ marginBottom: '10px' }}>
                    <MultipleSelectBar
                      width={'210px'}
                      margin={'10px 0'}
                      id="standard-multiline-flexible"
                      multiline
                      maxRows={4}
                      variant="standard"
                      value={
                        singlelistView &&
                        singlelistView.ports_details.length > 0
                          ? singlelistView.ports_details
                          : []
                      }
                      array={
                        singlelistView &&
                        singlelistView.ports_details.length > 0
                          ? singlelistView.ports_details
                          : [] //array pass krre na toh city nhi aaega
                      }
                      disabled={true}
                      title="city"
                    />
                  </div> */}
                    </Paper>
                    {/* </div> */}
                    {/* <div style={{ width: '33.3%' }}> */}
                    <Paper
                      style={{
                        maxWidth: "500px",
                        // height: "275px",
                        padding: "15px",
                        background: "#8080801a",
                        pointerEvents: "none",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                        }}
                      >
                        <CssValueTextField
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "270px",
                              color: "black",
                              caretColor: "transparent",
                              pointerEvents: "none",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Status"
                          value={singlelistView && singlelistView.status}
                        />{" "}
                      </div>
                      <div style={{ marginBottom: "7px 0" }}>
                        <div style={{ width: "25%" }}>
                          {" "}
                          <CssValueTextField
                            id="standard-multiline-flexible"
                            multiline
                            maxRows={4}
                            variant="standard"
                            style={
                              {
                                width: "270px",
                                color: "black",
                                caretColor: "transparent",
                                pointerEvents: "none",
                              } //disabled={tp}
                            }
                            onKeyDown={(evt) => evt.preventDefault()}
                            label="Contract Type"
                            value={
                              singlelistView &&
                              singlelistView.contract_type_details.contract_type
                            }
                          />
                        </div>
                      </div>
                      <div style={{ margin: "10px 0" }}></div>
                      <div style={{ marginTop: "15px" }}>
                        {singlelistView.contract_type_details.contract_type !==
                        "ex loose" ? (
                          <MultipleSelectBar
                            width={"270px"}
                            height={"100%"}
                            margin={"10px 0"}
                            id="standard-multiline-flexible"
                            multiline
                            maxRows={4}
                            variant="standard"
                            value={
                              singlelistView &&
                              singlelistView.ports_details &&
                              singlelistView.ports_details.length > 0
                                ? singlelistView.ports_details.map((data) => {
                                    data[
                                      "namecity"
                                    ] = `${data.name} - ${data.city}`;
                                    return data;
                                  })
                                : []
                              // [
                              //     {
                              //       city: "Any Indian Port",
                              //       name: "Any Indian Port",
                              //       id: "Any Indian Port",
                              //     },
                              //   ]
                            }
                            array={
                              singlelistView &&
                              singlelistView.ports_details &&
                              singlelistView.ports_details.length > 0
                                ? singlelistView.ports_details.map((data) => {
                                    data[
                                      "namecity"
                                    ] = `${data.name} - ${data.city}`;
                                    return data;
                                  })
                                : [
                                    {
                                      city: "Any Indian Port",
                                      name: "Any Indian Port",
                                      id: "Any Indian Port",
                                    },
                                  ] //array pass krre na toh city nhi aaega
                            }
                            disabled={true}
                            title="city"
                          />
                        ) : (
                          <div className="d-flex d-flex-column">
                            <span style={{ color: "rgba(0,0,0,0.54)" }}>
                              Inland Godown
                            </span>
                            <div
                              style={{
                                borderBottom: "1px solid #888",
                                marginTop: 10,
                              }}
                            >
                              {singlelistView &&
                                singlelistView?.inland_godowns.map((s) => {
                                  return (
                                    <Chip
                                      label={
                                        InlandGodownList?.find((p) => p.id == s)
                                          ?.name
                                      }
                                      style={{
                                        fontFamily: "Poppins",
                                        fontSize: "11px",
                                        margin: "5px 5px 5px 0",
                                      }}
                                      size="small"
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    </Paper>
                  </div>
                </div>
                {singlelistView.contract_type_details.contract_type ===
                "ex loose" ? (
                  <TableComponent
                    module={"PO Item"}
                    noAddButton={true}
                    noSearchBar={true}
                    simpleTable={true}
                    padding={"0px"}
                    tableRowHeight={30}
                    headerShownAlways={true}
                    tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
                    loader={false}
                    actions={[]}
                    header={[
                      "Item Category",
                      "Item Type",
                      "HSN Code",
                      "Total Item Quantity",
                      "Contract Rate",
                      "Sales Tax Terms",
                      "Specifications",
                    ]}
                    tableHeight="auto"
                    tablePagination={false}
                    footer={true}
                    tableList={
                      poItemsArrayTOPreviewCopy &&
                      poItemsArrayTOPreviewCopy.length
                        ? poItemsArrayTOPreviewCopy.map((s) => {
                            s["Total Item QuantityUnit"] =
                              s["Total Item Quantity"] +
                              " " +
                              (singlelistView?.unit || "");
                            s["Contract RateUnit"] =
                              s["Contract Rate"] +
                              (singlelistView?.unit
                                ? "/" + singlelistView?.unit
                                : "");
                            s["Item TypeUnit"] = s["Item Type"] + "%";
                            console.log("s", s);
                            s[`Specifications`] = () => {
                              const transformedSpecifications =
                                s["specificationArr"] &&
                                s["specificationArr"].length > 0
                                  ? s["specificationArr"].map((sP) => {
                                      const ss = sP.name;
                                      const filterS =
                                        this.props.specifiationTypes?.find(
                                          (st) => st.id === ss
                                        );
                                      return {
                                        ...sP,
                                        name: filterS
                                          ? `${filterS.text?.toLowerCase()}`
                                          : "",
                                        value: filterS
                                          ? `${
                                              sP.value
                                            } ${filterS.unit?.toLowerCase()}`
                                          : sP.value,
                                      };
                                    })
                                  : [];

                              return (
                                <div
                                  className="d-flex widthFC alignFE"
                                  style={{ margin: "0px auto" }}
                                >
                                  <ShowSpecifications
                                    details={transformedSpecifications}
                                  />
                                </div>
                              );
                            };

                            return s;
                          })
                        : []
                    }
                  />
                ) : (
                  POITemTable(
                    null,
                    poItemsArrayTOPreviewCopy,
                    // poItemsArrayTOPreview,
                    null,
                    null,
                    "950px",
                    null,
                    null,
                    "fromPO"
                  )
                )}
                <Paper style={styles.innerDiv1}>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Acceptance"
                          value={
                            singlelistView &&
                            singlelistView.acceptance_of_purchase_order
                              ? singlelistView.acceptance_of_purchase_order
                              : ""
                          }
                        />
                      </div>
                    </div>{" "}
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Eway Bill"
                          value={
                            singlelistView && singlelistView.eway_bill
                              ? singlelistView.eway_bill
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Contract Cancellation"
                          value={
                            singlelistView &&
                            singlelistView.contract_cancellation
                              ? singlelistView.contract_cancellation
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Loading"
                          value={
                            singlelistView && singlelistView.loading
                              ? singlelistView.loading
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {console.log("singlelistView", singlelistView)}
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Packing Condition"
                          value={
                            singlelistView && singlelistView.packing_condition
                              ? singlelistView.packing_condition
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Broker Commission"
                          value={
                            singlelistView &&
                            singlelistView.brokerage_commission
                              ? singlelistView.brokerage_commission
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Short Shipments & Penalty"
                          value={
                            singlelistView &&
                            singlelistView.short_shipments_and_penalty
                              ? singlelistView.short_shipments_and_penalty
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div style={{ width: "100%" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={5}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Other Conditions"
                          value={
                            singlelistView && singlelistView.other_conditions
                              ? singlelistView.other_conditions
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Delivery Terms"
                          value={
                            singlelistView && singlelistView.delivery_terms
                              ? singlelistView.delivery_terms
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "calc(50% - 5px)" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Delivery Place"
                          value={
                            singlelistView &&
                            singlelistView.delivery_terms_extras
                              ? singlelistView.delivery_terms_extras
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={styles.innerMainDiv}>
                    <div style={{ width: "100%" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Payment Terms"
                          value={
                            "Payment shall be made by RTGS within " +
                            `${
                              singlelistView && singlelistView.payment_terms
                                ? PaymentTermList &&
                                  PaymentTermList.length > 0 &&
                                  PaymentTermList.filter(
                                    (sPT) =>
                                      sPT.id === singlelistView.payment_terms
                                  ) &&
                                  PaymentTermList.filter(
                                    (sPT) =>
                                      sPT.id === singlelistView.payment_terms
                                  )[0] &&
                                  PaymentTermList.filter(
                                    (sPT) =>
                                      sPT.id === singlelistView.payment_terms
                                  )[0].days_range
                                : ""
                            }` +
                            " WD after receipt of material in our CHA godown /CFS."
                          }
                        />
                      </div>
                    </div>
                    {/* <div style={{ width: '25%' }}>
              {' '}
              <div style={{ margin: '10px 0' }}>
                <CssValueTextField
                  //disabled
                  style={
                    {
                      width: '210px',
                    } //disabled={tp}
                  }
                  id="outlined-disabled"
                  onKeyDown={(evt) => evt.preventDefault()}
                  label="HSN Code"
                  value={
                    singlelistView
                      .hsn_details? singlelistView
                      .hsn_details.hsn_code&&singlelistView
                      .hsn_details.hsn_code:""
                  }
                />
              </div>
            </div> */}
                  </div>
                  {/* <div style={styles.innerMainDiv}>
                    <div style={{ width: "100%" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          //disabled
                          style={
                            {
                              width: "100%",
                            } //disabled={tp}
                          }
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Delivery Place2"
                          value={`Delivered in buyer’s nominated CHA’s warehouse in ${
                            singlelistView &&
                            singlelistView.delivery_terms_extras
                              ? singlelistView.delivery_terms_extras
                              : ""
                          } by trucks.`}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div style={styles.innerMainDiv}>
                    <div style={{ width: "100%" }}>
                      {" "}
                      <div style={{ margin: "10px" }}>
                        <CssValueTextField
                          // disabled={true}
                          style={{
                            width: "100%",
                          }}
                          id="standard-multiline-flexible"
                          multiline
                          maxRows={4}
                          variant="standard"
                          onKeyDown={(evt) => evt.preventDefault()}
                          label="Remark"
                          value={singlelistView && singlelistView.remark}
                        />
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="marginB10" />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Details;
