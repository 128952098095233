import React, { useEffect } from "react";
import { Tabs, Tab, AppBar, withStyles, Box, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import { StyledCircularProgress } from "../styles/StyledComponents";
import { primary, white } from "../styles/colors";
import CommingSoon from "./CommingSoon";
import { mediumScreen } from "../Regex";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div
          style={{
            // margin: "0 -24px -24px -24px",
            height: "calc(100% + 10px)",
            padding: "10px 2px",
            overflowY: "auto",
            // margin: "0 -41px -24px -41px",
            // height: "calc(100% + 24px)",
            // padding: "2px 17px",
            // overflowY: "auto",
            // width: "calc(100% + 80px)",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function TabPanelnoPadding(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div
          className="scrollBluePrimary"
          style={{
            // margin: "0 -24px -24px -24px",
            height: "calc(100% + 15px)",
            padding: "0",
            margin: "0",
            overflowY: "auto",
            overflowX: "auto",
            width: "100%",
            minWidth: "100%",
            // margin: "0 -41px -24px -41px",
            // height: "calc(100% + 24px)",
            // padding: "2px 17px",
            // overflowY: "auto",
            // width: "calc(100% + 80px)",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanelnoPadding.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CssTab = withStyles({
  root: {
    "&.PrivateTabIndicator-colorSecondary-1003": {
      backgroundColor: "#1E32FA",
    },
  },
})(Tab);
const CssTabs = withStyles({
  root: {
    "& .PrivateTabIndicator-colorSecondary-5": {
      backgroundColor: "#00867E",
    },
    "& .MuiTabs-flexContainer": {
      display: " flex",
      height: "40px",
      // justifyContent: "",
    },
    //  '&.MuiBox-root-31 ': {
    //   padding: ' 10px 24px 20px 24px',
    // },
  },
})(Tabs);

export default function TabComponent(props) {
  const reUse = props.list ? props.list : [{ name: "dummy", component: <CommingSoon /> }];
  const [value, setValue] = React.useState(0);
  const [valueToMap, setValueToMap] = React.useState(reUse);
  const handleChange = (event, newValue) => {
    if (props.onChange) {
      props.onChange(newValue);
    }
    setValue(newValue);
  };
  useEffect(() => {
    if (props.selectedValue) {
      setValue(props.selectedValue);
    } else {
    }
  }, [props.selectedValue]);
  useEffect(() => {
    if (props.list) {
      setValueToMap(props.list);
    } else {
    }
  }, [props.list]);

  const { noPadding, customUI } = props;
  return (
    <div
      className="height-100 width-100 d-flex d-flex-column alignC"
      style={{
        backgroundColor: white,
        minHeight: "100%",
      }}
    >
      {props.loading && props.loading.boards && props.loading.boards === true ? (
        <StyledCircularProgress />
      ) : (
        <>
        <div className="d-flex alignC width-100">
        {customUI && customUI}
          <AppBar
            style={{
              boxShadow: "none",
              borderBottom: "1px solid #0000000B",
              width: "100%",
              backgroundColor: "white",
            }}
            position="static"
          >
            <CssTabs
              style={{ backgroundColor: white, color: primary }}
              value={value}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {valueToMap &&
                valueToMap.map(
                  (singleTab, index) =>
                    singleTab && (
                      <CssTab
                        onClick={(event) => {
                          handleChange(event, index, singleTab.name);
                        }}
                        key={singleTab.name}
                        style={{
                          fontSize: mediumScreen() ? "0.7rem" : "0.9rem",
                          minWidth: "fit-content",
                          letterSpacing: "1px",
                          textTransform: "capitalize",
                          opacity: value === index && 1,
                        }}
                        value={index}
                        label={singleTab.name}
                        wrapped
                        {...a11yProps(index)}
                      />
                    )
                )}
            </CssTabs>
          </AppBar>
        </div>
          {valueToMap &&
            valueToMap.map((singleTab, index) =>
              value === index ? (
                noPadding ? (
                  <TabPanelnoPadding
                    key={index.toString()}
                    className="list width-100 height-100-49 overflowya postionR"
                    style={{
                      backgroundColor: white,
                    }}
                    value={index}
                    index={index}
                  >
                    {singleTab.component}
                  </TabPanelnoPadding>
                ) : (
                  <TabPanel
                    key={index.toString()}
                    className="list width-100 height-100-49 overflowya postionR"
                    style={{
                      backgroundColor: white,
                    }}
                    value={index}
                    index={index}
                  >
                    {singleTab.component}
                  </TabPanel>
                )
              ) : (
                ""
              )
            )}
        </>
      )}
    </div>
  );
}
