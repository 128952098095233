import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { CssTextField } from "../styles/StyledComponents";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, Chip, CircularProgress } from "@mui/material";
import { primary } from "../styles/colors";
import debounce from "lodash.debounce";
import { useSnackbar } from "../snackbarContext";
import { mediumScreen } from "../Regex";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: "auto", // Enable vertical scrolling
    },
  },
};

const getNestedValue = (obj, key) => {
  return key
    .split(".")
    .reduce((acc, curr) => (acc[curr] ? acc[curr] : ""), obj);
};

const MultiLineRender = ({ option, optionKeys }) => {
  console.log(option, optionKeys);
  return (
    <li
      style={{
        display: "flex",
        flexDirection: "column",
        lineHeight: "1em",
        fontFamily: "Poppins",
        width: '100%'
      }}
    >
      {optionKeys.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              fontSize: index === 0 ? "14px" : "10px",
              color: index === 0 ? "#333" : "grey",
              marginbottom: index === 0 ? "2px" : "0px",
              width: '100%',
              display: "flex",
              justifyContent: 'space-between'
            }}
          >
            <span>
              <span style={{ fontWeight: 500, fontSize: 12 }}>{item?.label || ""} </span>
              <span style={{ fontSize: 12}}>
                {item?.key?.includes(".")
                  ? getNestedValue(option, item?.key)
                  : option?.[item?.key] || ""}
                {item?.key2?.includes(".")
                  ? getNestedValue(option, item?.key2)
                  : option?.[item?.key2] || ""}
              </span>
            </span>
              {item?.keyB && (
                <span>
                  <span style={{ fontWeight: 500, fontSize: 11 }}>{item?.labelB || ""} </span>
                  <span style={{ fontSize: 11}}>
                    {item?.keyB?.includes(".")
                      ? getNestedValue(option, item?.keyB)
                      : option?.[item?.keyB] || ""}
                    {item?.keyB2?.includes(".")
                      ? getNestedValue(option, item?.keyB2)
                      : option?.[item?.keyB2] || ""}
                  </span>
                </span>
              )}
          </div>
        );
      })}
    </li>
  );
};

export const CustomAutocomplete = (props) => {
  // console.log("VALUE-AUTO",props.value)
  return (
    <Autocomplete
      {...props}
      value={props?.value || null}
      onChange={(e, newValue) => {
        if (props?.onChange) {
          props?.onChange(e, newValue);
        }
      }}
      options={[...(Array.isArray(props?.options) ? props.options : [])].sort((a, b) =>
        (a[props.sortKey || "name"] || "").localeCompare(
          b[props.sortKey || "name"] || ""
        )
      )}
      getOptionLabel={(option) =>
        props?.getOptionLabel(option) || option?.title || ""
      }
      MenuProps={MenuProps}
      renderOption={(option, p) => {
        const optionLabel = props?.getOptionLabel(option);
        if (props.renderOption) {
          return props.renderOption(option, p);
        } else if (props.multiLineRender?.length) {
          return (
            <MultiLineRender
              option={option}
              optionKeys={props.multiLineRender}
            />
          );
        } else {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                lineHeight: "1.1em",
                fontFamily: "Poppins",
                fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                margin: "5px 0",
              }}
            >
              <span>{optionLabel || ""}</span>
            </div>
          );
        }
      }}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label={props?.label || ""}
          variant="outlined"
          required={props?.required || false}
          helperText={props?.helperText}
          error={props?.error}
          onBlur={props?.onBlur}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: "6px",
              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {Boolean(props?.OptionLoader) ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{
        width: props.width || 200,
        ...(props?.style || {}),
      }}
    />
  );
};

export const CustomMultiSelectAutocomplete = (props) => {
  const icon = (
    <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: primary }} />
  );
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" style={{ color: primary }} />
  );

  return (
    <Autocomplete
      {...props}
      multiple
      id="checkboxes-tags-demo"
      value={props?.value || []}
      onChange={(e, newValue) => {
        if (props?.onChange) {
          props?.onChange(e, newValue);
        }
      }}
      MenuProps={MenuProps}
      options={props?.options || []}
      getOptionLabel={(option) =>
        props?.getOptionLabel(option) || option?.title || ""
      }
      disableCloseOnSelect
      renderOption={(p, option, state) => {
        const { key, ...optionProps } = p;
        if (!option) return null;
        return (
          <li key={key} {...optionProps} className="d-flex alignC">
            {/* {console.log(p,props?.optionKey, p[props?.optionKey], option, state, props.value)} */}
            <div>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8, padding: 0, color: primary }}
                checked={Boolean(
                  props?.value?.length > 0 &&
                    props.value.some((s) => s.id == p.id)
                )}
              />
            </div>
            <div style={{ fontSize: mediumScreen() ? "0.7rem" : "0.8rem" }}>
              {p[props?.optionKey] || ""}
            </div>
          </li>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value && value.length > 0 && typeof value !== "string"
          ? value.map((option, index) => (
              <>
                {console.log(
                  option,
                  props?.optionKey,
                  option[props?.optionKey]
                )}
                <Chip
                  key={value.toString()}
                  variant="outlined"
                  label={option[props?.optionKey]}
                  size="small"
                  style={{
                    fontFamily: "Poppins",
                    padding: "0 5px",
                    margin: "2px",
                  }}
                  {...getTagProps({ index })}
                  disabled={props?.fixedOptions?.includes(option?.id)}
                />
              </>
            ))
          : "";
      }}
      style={{
        width: props.width || 200,
        ...(props?.style || {}),
      }}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label={props?.label || ""}
          variant="outlined"
          required={props?.required || false}
          helperText={props?.helperText}
          error={props?.error}
          onBlur={props?.onBlur}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: "6px",
              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
            },
          }}
        />
      )}
    />
  );
};

export const CustomSearchAutocomplete = (props) => {
  const { api } = props;
  const showSnackbar = useSnackbar();
  const [inputValue, setInputValue] = useState("");
  const [filterOption, setFilterOption] = useState([]);
  const [filterOptionLoader, setFilterOptionLoader] = useState(false);

  const fetchAPI = (text) => {
    console.log(text);
    if (text) {
      setFilterOptionLoader(true);
      let fetchKey = api.resKey;
      api
        .callFn("get", api.url, null, fetchKey, null, {
          search: text,
          ...api.params,
        })
        .then((fetchKey) => {
          console.log(fetchKey);
          setFilterOptionLoader(false);
          if (fetchKey.error) {
            showSnackbar(fetchKey.data, "error");
          } else {
            let data =
              fetchKey?.results?.length > 0 ? fetchKey?.results : fetchKey;
            console.log(data);
            setFilterOption(data);
          }
        });
    }
  };

  const debouncedInputChange = useMemo(
    () =>
      debounce((text) => {
        if (api.url) {
          fetchAPI(text);
        }
      }, 500),
    []
  );

  useEffect(() => {
    return () => {
      console.log("cancel");
      debouncedInputChange.cancel();
    };
  }, [debouncedInputChange]);

  const handleInputChange = (e, newInputValue, reason) => {
    console.log("Input change:", newInputValue, reason);
    setInputValue(newInputValue);
    if (reason === "input") {
      // only call api when input change and not select option
      debouncedInputChange(newInputValue); // Call the debounced function
    }
  };

  useEffect(() => {
    console.log("SAERCH =", props.defaultData);
    if (props?.defaultData?.value) {
      handleInputChange(null, props?.defaultData?.value, "input");
    }
  }, [props?.defaultData]);

  useEffect(() => {
    setFilterOption(props?.options || []);
  }, [props?.options]);

  console.log(filterOption);

  return (
    <Autocomplete
      {...props}
      options={filterOption}
      getOptionLabel={(option) =>
        props?.getOptionLabel(option) || option?.title || ""
      }
      inputValue={inputValue}
      onInputChange={handleInputChange}
      MenuProps={MenuProps}
      value={props.value || null}
      onChange={(e, newValue) => {
        if (props?.onChange) {
          props?.onChange(e, newValue);
        }
      }}
      renderOption={(option, p) => {
        const optionLabel = props?.getOptionLabel(option);
        if (props.renderOption) {
          return props.renderOption(option, p);
        } else if (props.multiLineRender?.length) {
          return (
            <MultiLineRender
              option={option}
              optionKeys={props.multiLineRender}
            />
          );
        } else {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                lineHeight: "1.1em",
                fontFamily: "Poppins",
                margin: "5px 0",
                fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
              }}
            >
              {console.log(
                "RENDER",
                option,
                props?.renderOptionKey,
                option?.[props?.renderOptionKey]
              )}
              <span>{option?.[props?.renderOptionKey]}</span>
            </div>
          );
        }
      }}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <CssTextField
          {...params}
          required={props?.required || false}
          label={props?.label || "Search as you type"}
          placeholder={props?.placeholder || ""}
          helperText={props?.helperText}
          error={props?.error}
          onBlur={props?.onBlur}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            style: {
              padding: "6px",
              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {filterOptionLoader ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{ width: props.width || 200, ...props?.style }}
    />
  );
};
