import { useEffect, useState } from "react";
import TabComponent from "../../layoutComponents/TabComponent";
import InlandGodownDetails from "./InlandGodownDetails";
import InlandGodownPostDispatch from "./InlandGodownPostDispatch";
import InlandGodownPreDispatch from "./InlandGodownPreDispatch";
import InlandGodownClosedDispatch from "./InlandGodownCLosedDispatch";

const UnitList = [{ name: "Kgs" }, { name: "MT" }, { name: "Qtl" }]
const  StatusDispatch = [
  { name: "dispatched" },
  { name: "reached" },
  { name: "full_return" },
  { name: "semi_returned" },
  { name: "unloaded" },
]

const InlandGodownComponent = (props) => {
  const [tabValue, setTabValue] = useState(0);

  const { InlandGodownListData, singleInlandGodown } = props;
  return (
    <TabComponent
      selectedValue={tabValue}
      onChange={(newVal) => {
        setTabValue(newVal);
      }}
      list={[
        {
          name: "Pre Dispatch",
          component: (
            <InlandGodownPreDispatch
              {...props}
              singleInlandGodown={singleInlandGodown}
              fetchInlandGodownList={props.fetchInlandGodownList}
              UnitList={UnitList}
              StatusDispatch={StatusDispatch}
            />
          ),
        },
        {
          name: "Post Dispatch",
          component: (
            <InlandGodownPostDispatch
              {...props}
              singleInlandGodown={singleInlandGodown}
              fetchInlandGodownList={props.fetchInlandGodownList}
              UnitList={UnitList}
              StatusDispatch={StatusDispatch}
            />
          ),
        },
        {
          name: "Closed Dispatch",
          component: (
            <InlandGodownClosedDispatch
              {...props}
              singleInlandGodown={singleInlandGodown}
              fetchInlandGodownList={props.fetchInlandGodownList}
              UnitList={UnitList}
              StatusDispatch={StatusDispatch}
            />
          ),
        },
        {
          name: "Inland Godown Details",
          component: (
            <InlandGodownDetails
              {...props}
              singleInlandGodown={singleInlandGodown}
              fetchInlandGodownList={props.fetchInlandGodownList}
              UnitList={UnitList}
              StatusDispatch={StatusDispatch}
            />
          ),
        },
      ]}
    />
  );
};

export default InlandGodownComponent;
