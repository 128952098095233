import React, { useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";
import {
  CustomAutocomplete,
  CustomSearchAutocomplete,
} from "../../layoutComponents/CustomAutocomplete";
import { CssTextField } from "../../styles/StyledComponents";
import MuiDatePicker from "../../layoutComponents/MuiDatePicker";
import { Button, Divider, IconButton } from "@mui/material";
import { mediumScreen, StopScroll } from "../../Regex";
import CustomDialog from "../../layoutComponents/CustomDialog";
import { useSnackbar } from "../../snackbarContext";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import { EndPoints } from "../../utils/EndPoints";
import { Add, Delete } from "@mui/icons-material";
import moment from "moment";

const styles = {
  inputField: {
    width: 220,
    margin: "10px 10px 10px 2px",
  },
  inputExtraField: {
    minWidth: 230,
    width: "calc(25% - 20px)",
    margin: "10px",
  },
};

const Header = [
  "Brand",
  "Item Category",
  "Item Type",
  "Packaging",
  "Total Bags",
  "Total Return Bags",
];

const StatusArrival = ["Continue to Shipping", "Move to Godown"];

const ReturnBag = (props) => {
  const [returnBagDialog, setReturnBagDialog] = useState(false);
  const [packagingReturnData, setPackagingReturnData] = useState([]);

  const { itemReturnBagData, handleReturnBagSubmit } = props;

  const handleBagChange = (index, value) => {
    const newData = [...packagingReturnData];
    newData[index].return_bag = value;
    setPackagingReturnData(newData);
  };

  const checkAllBagsFieldValid = () => {
    let isValid = true;
    packagingReturnData.forEach((item) => {
      if (
        Boolean(item.return_bag) &&
        Number(item?.return_bag) > Number(item?.total_bags_dispatched)
      ) {
        isValid = false;
        item.return_bag_error = "Return bags exceeds the total bags dispatched";
      }
    });
    return isValid;
  };

  useEffect(() => {
    if (itemReturnBagData) {
      setPackagingReturnData(itemReturnBagData.packaging);
    }
  }, [itemReturnBagData]);

  return (
    <>
      <Button
        style={{
          cursor: "pointer",
          color: "rgb(241 154 48)",
          fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
          fontFamily: "Poppins",
          textTransform: "none",
        }}
        size="small"
        withBg={false}
        onClick={() => {
          setReturnBagDialog(true);
        }}
      >
        Return
      </Button>
      <CustomDialog
        open={returnBagDialog}
        onClose={() => {
          setReturnBagDialog(false);
        }}
        maxWidth={"xs"}
        title={"Return Bags"}
        noResetButton={true}
        submitButton="Done"
        submitButtonDisabled={!checkAllBagsFieldValid()}
        onSubmitClick={() => {
          handleReturnBagSubmit(itemReturnBagData?.id, packagingReturnData);
          setReturnBagDialog(false);
        }}
      >
        {packagingReturnData.length &&
          packagingReturnData.map((item, index) => (
            <div key={item.id} style={{ margin: "10px 0" }}>
              <span>
                {item?.packaging_details?.size} {item?.packaging_details?.unit}{" "}
                Total Bags{" "}
                {Number(item?.total_bags_dispatched) -
                  Number(item?.return_bag || 0)}
              </span>
              <CssTextField
                required={true}
                style={styles.inputField}
                id="outlined-basic"
                label={"Return Bags"}
                type={"number"}
                onFocus={StopScroll}
                value={item.return_bag || ""}
                error={
                  Boolean(item.return_bag) &&
                  Number(item?.return_bag) > Number(item?.total_bags_dispatched)
                }
                helperText={
                  Boolean(item.return_bag) &&
                  Number(item?.return_bag) > Number(item?.total_bags_dispatched)
                    ? "Return Bags cannot be greater than Total Bags"
                    : ""
                }
                onKeyDown={(evt) => {
                  ["e", "E", "-", "+", "."].includes(evt.key) &&
                    evt.preventDefault();
                }}
                onChange={(e) => {
                  handleBagChange(index, e.target.value);
                }}
                variant="outlined"
              />
            </div>
          ))}
      </CustomDialog>
    </>
  );
};

const SubMenuRow = ({
  id,
  singleRowData,
  index,
  inputData,
  error,
  handleInputDataChange,
  removeItemState,
  props,
}) => {
  const isShipping = Boolean(inputData.status === "Continue to Shipping");
  return (
    <>
      <div className="d-flex  justifyFS">
        <CustomAutocomplete
          id="status"
          required={true}
          options={StatusArrival}
          getOptionLabel={(option) => option}
          value={inputData.status || null}
          error={!!error.status}
          helperText={error.status}
          onChange={(e, newValue) => {
            handleInputDataChange("status", newValue, index, id);
          }}
          style={styles.inputField}
          disableClearable
          label="Status"
        />
        <CssTextField
          required={true}
          style={styles.inputField}
          id="outlined-basic"
          label={"Bags"}
          type={"number"}
          onFocus={StopScroll}
          value={inputData.bags || ""}
          error={!!error.bags}
          helperText={error.bags}
          onKeyDown={(evt) => {
            ["e", "E", "-", "+", "."].includes(evt.key) && evt.preventDefault();
          }}
          onChange={(e) => {
            handleInputDataChange("bags", e.target.value, index, id);
          }}
          variant="outlined"
        />
        {Boolean(inputData?.status && !isShipping) ? (
          <CustomAutocomplete
            id="godown"
            required={true}
            options={singleRowData.godowns_list || []}
            getOptionLabel={(option) => option?.name}
            value={inputData.godown || null}
            error={!!error.godown}
            helperText={error.godown}
            onChange={(e, newValue) => {
              handleInputDataChange("godown", newValue, index, id);
            }}
            style={styles.inputField}
            disableClearable
            label="Godown"
          />
        ) : (
          <>
            <MuiDatePicker
              required={true}
              style={styles.inputField}
              error={!!error?.shipping_date}
              helperText={error?.shipping_date}
              invalidLabel=""
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
              allowKeyboardControl={false}
              autoOk
              inputVariant="outlined"
              label={"Shipping Date"}
              value={inputData?.shipping_date || ""}
              onChange={(newValue) => {
                handleInputDataChange("shipping_date", newValue, index, id);
              }}
            />
            <CustomAutocomplete
              id="unloading_agent"
              options={props.masters?.UnloadingAgentList || []}
              getOptionLabel={(option) => option?.name}
              value={inputData.unloading_agent || null}
              onChange={(e, newValue) => {
                handleInputDataChange("unloading_agent", newValue, index, id);
              }}
              style={styles.inputField}
              disableClearable
              label="Unloading Agent"
            />
            <CssTextField
              required={false}
              style={styles.inputField}
              id="outlined-basic"
              label={"MR/Leo No."}
              type="text"
              value={inputData?.mr_or_leo_no || ""}
              onChange={(e) => {
                handleInputDataChange(
                  "mr_or_leo_no",
                  e.target.value,
                  index,
                  id
                );
              }}
              variant="outlined"
            />
            <CustomAutocomplete
              id="sales_order"
              required={true}
              options={
                props.masters?.salesOrderList.filter((s) =>
                  s.so_items_brands.includes(
                    singleRowData.dispatch_items_details.brand
                  )
                ) || []
              }
              getOptionLabel={(option) => option?.so_number}
              value={inputData.sales_order || null}
              error={!!error.sales_order}
              helperText={error.sales_order}
              multiLineRender={[
                { label: "", key: "so_number" },
                {
                  label: "Remaining :",
                  key: "remaining_quantity",
                  key2: "unit",
                },
              ]}
              onChange={(e, newValue) => {
                handleInputDataChange(
                  "sales_order",
                  newValue,
                  index,
                  id,
                  singleRowData.dispatch_items_details.brand
                );
              }}
              style={styles.inputField}
              disableClearable
              label="Sales Order"
            />
            <CustomAutocomplete
              id="custom_invoice"
              required={true}
              options={inputData?.customInvoiceList || []}
              getOptionLabel={(option) => option?.invoice_number}
              value={inputData.custom_invoice || null}
              error={!!error.custom_invoice}
              helperText={error.custom_invoice}
              onChange={(e, newValue) => {
                handleInputDataChange("custom_invoice", newValue, index, id);
              }}
              style={styles.inputField}
              disableClearable
              label="Custom Invoice"
            />
            <CustomAutocomplete
              id="commercial_invoice"
              options={inputData?.commercialInvoiceList || []}
              getOptionLabel={(option) => option?.invoice_number}
              value={inputData.commercial_invoice || null}
              error={!!error.commercial_invoice}
              helperText={error.commercial_invoice}
              onChange={(e, newValue) => {
                handleInputDataChange(
                  "commercial_invoice",
                  newValue,
                  index,
                  id
                );
              }}
              style={styles.inputField}
              disableClearable
              label="Commercial Invoice"
            />
          </>
        )}
        <CssTextField
          required={false}
          style={styles.inputField}
          id="outlined-basic"
          label={"Remark"}
          type="text"
          value={inputData?.remark || ""}
          error={!!error.remark}
          helperText={error.remark}
          onChange={(e) => {
            handleInputDataChange("remark", e.target.value, index, id);
          }}
          variant="outlined"
        />
        {index > 0 && (
          <span
            className="d-flex"
            style={{ paddingTop: "10px", alignItems: "flex-start" }}
          >
            <IconButton
              style={{ color: "red" }}
              onClick={() => removeItemState(id, index)}
            >
              <Delete />
            </IconButton>
          </span>
        )}
      </div>
      {index >= 0 && <Divider style={{ margin: "2px 0" }} />}
    </>
  );
};

const MarkReachedDialogBox = (props) => {
  const defaultFields = {
    status: [],
    bags: "",
    remark: "",
    godown: [],
    shipping_date: new Date(),
    unloading_agent: [],
    mr_or_leo_no: "",
    sales_order: [],
    custom_invoice: [],
    customInvoiceList: [],
    commercial_invoice: [],
    commercialInvoiceList: [],
  };

  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [error, setError] = useState({});
  const [extraData, setExtraData] = useState({});
  const [extraDataError, setExtraDataError] = useState({});
  const [returnBagData, setReturnBagData] = useState([]);
  const [surveyorList, setSurveyor] = useState([]);

  const showSnackbar = useSnackbar();

  const resetState = () => {
    setItemData([]);
    setInputData({});
    setExtraData({});
    setError({});
    setExtraDataError({});
    setReturnBagData([]);
    setSurveyor([]);
    setActionDialogOpen(false);
  };

  const fetchItemList = async () => {
    props
      .PortsFunction(
        "get",
        "arrival/items/list",
        null,
        "PortDispatchedList",
        null,
        {
          arrival_id: props.listRow.id,
        }
      )
      .then((PortDispatchedItemList) => {
        if (PortDispatchedItemList) {
          setItemData(PortDispatchedItemList);
          setActionDialogOpen(true);
        }
      });
  };

  const fetchCustomInvoice = (salesId, brandId, newInputData, id, index) => {
    let updatedData = { ...newInputData };
    props
      .PortsFunction(
        "get",
        "arrival/custom-invoice/list",
        null,
        "PortCIs",
        null,
        {
          sales_order: salesId,
          is_active: "True",
          brand: brandId,
          status: "DRAFT,OPEN",
        }
      )
      .then((customInvoices) => {
        let filterCustomInvoiceData = [];
        if (customInvoices) {
          const portID = props?.singlelistView && props?.singlelistView?.id;
          filterCustomInvoiceData = customInvoices.filter(
            (c) => c.loading_port === portID
          );
        }
        updatedData[`inputData_${id}`][index].customInvoiceList =
          filterCustomInvoiceData;
        setInputData(updatedData);
      });
  };

  const fetchCommercialInvoice = (comm_inv, newInputData, id, index) => {
    let updatedData = { ...newInputData };
    props
      .PortsFunction(
        "get",
        `arrival/commercial-invoice/${comm_inv}`,
        null,
        "PortCIs",
        null,
        {}
      )
      .then((commercialInvoice) => {
        updatedData[`inputData_${id}`][index].commercialInvoiceList =
          commercialInvoice;
        setInputData(updatedData);
      });
  };

  const fetchSurveyor = (surveyComapanyId) => {
    props
      .PortsFunction("get", "masters/surveyor/list", null, "", null, {
        is_active: "True",
        company_id: surveyComapanyId,
      })
      .then((res) => {
        if (res) {
          setSurveyor(res);
        }
      });
  };

  const formatLabel = (str) => {
    // console.log(str);
    if (str) {
      return str
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  };

  const singleRowAvailableBags = (poItemId) => {
    const itemRowTotalBags = itemData?.find(
      (item) => item.dispatch_items_details.po_item == poItemId
    )?.dispatch_items_details?.total_bags;

    const itemRowReturnBags =
      returnBagData
        .find((r) => r.id == poItemId)
        ?.packaging?.reduce((a, b) => a + Number(b.return_bag), 0) || 0;

    return Number(Number(itemRowTotalBags) - Number(itemRowReturnBags));
  };

  const singleRowBagsSum = (poItemId) => {
    const itemRowTotalBags = inputData[`inputData_${poItemId}`]?.reduce(
      (t, item) => Number(t) + Number(item.bags || 0),
      0
    );
    return Number(itemRowTotalBags || 0);
  };

  const isAddItemRowButtonVisible = (poItemId) => {
    const finalAvailableBags = singleRowAvailableBags(poItemId);
    const bagsSum = singleRowBagsSum(poItemId);
    console.log(
      "BAG ADDED",
      itemData,
      poItemId,
      bagsSum,
      finalAvailableBags,
      Number(finalAvailableBags) > Number(bagsSum)
    );
    return Boolean(Number(finalAvailableBags) > Number(bagsSum));
  };

  const addItemState = (poItemId) => {
    let newInputData = { ...inputData };
    let newErrorData = { ...error };
    newInputData[`inputData_${poItemId}`].push({
      ...defaultFields,
      po_item_id: poItemId,
    });
    newErrorData[`inputData_${poItemId}`].push({
      ...Object.fromEntries(
        Object.keys(defaultFields).map((key) => [key, false])
      ),
      po_item_id: poItemId,
    });

    setInputData(newInputData);
    setError(newErrorData);
  };

  const removeItemState = (poItemId, index) => {
    let newInputData = { ...inputData };
    let newErrorData = { ...error };
    newInputData[`inputData_${poItemId}`].splice(index, 1);
    newErrorData[`inputData_${poItemId}`].splice(index, 1);

    setInputData(newInputData);
    setError(newErrorData);
  };

  const handleInputDataChange = (key, value, index, id, brandId = "") => {
    const newInputData = { ...inputData };
    const errorData = { ...error };
    newInputData[`inputData_${id}`][index][key] = value;
    errorData[`inputData_${id}`][index][key] = [
      "status",
      "godown",
      "sales_order",
      "custom_invoice",
      "commercial_invoice",
    ].includes(key)
      ? !Boolean(value || value?.id)
      : key === "bags" && Number(value) > singleRowAvailableBags(id)
      ? "Bags cannot be greater than Total Available Bags"
      : !Boolean(value);

    console.log("change", key, value, index, id, newInputData, errorData);
    if (key === "sales_order" && value?.id) {
      setError(errorData);
      fetchCustomInvoice(value.id, brandId, newInputData, id, index);
    } else if (key === "custom_invoice" && value?.id) {
      setError(errorData);
      if (value.commercial_invoice)
        fetchCommercialInvoice(
          value.commercial_invoice,
          newInputData,
          id,
          index
        );
      else setInputData(newInputData);
    } else {
      setInputData(newInputData);
      setError(errorData);
    }
  };

  const handleExtraDataChange = (key, value) => {
    const newExtraData = { ...extraData };
    const errorData = { ...extraDataError };
    newExtraData[key] = value;
    if (key == "tare_weight") {
      errorData[key] = Boolean(value)
        ? Boolean(value) && Number(value) < Number(newExtraData.gross_weight)
          ? false
          : "Tare Weight cannot be greater than Gross Weight"
        : "Invalid Tare Weight";
      if (value) {
        newExtraData.net_weight = Number(
          Number(newExtraData.gross_weight) - Number(value)
        ).toFixed(3);
      }
    } else if (key == "gross_weight") {
      errorData[key] = Boolean(value)
        ? Boolean(value) && Number(value) > Number(newExtraData.tare_weight)
          ? false
          : "Gross Weight cannot be less than tare Weight"
        : "Invalid Gross Weight";
      if (value) {
        newExtraData.net_weight = Number(
          Number(value) - Number(newExtraData.tare_weight)
        ).toFixed(3);
      }
    } else {
      errorData[key] = !Boolean(value);
    }
    setExtraData(newExtraData);
    setExtraDataError(errorData);
  };

  const setInitialValue = () => {
    console.log("SET INITIAL-listRow", props.listRow);
    let surveyId = props.listRow?.survey_company || props.listRow.arrival_table_details?.dispatch_survey_company;
    console.log("SET INITIAL-surveyId", surveyId);
    fetchSurveyor(surveyId);
    setExtraData({
      arrival_date: moment(
        props.listRow?.arrival_table_details?.dispatch_date
      ).format("YYYY-MM-DD"),
      gross_weight:
        props.listRow?.arrival_table_details?.dispatch_net_weight || 0,
      tare_weight: "",
      net_weight:
        props.listRow?.arrival_table_details?.dispatch_net_weight || 0,
      survey_company:
        props.SurveyCompanyList &&
        props.SurveyCompanyList.find((s) => s.id == surveyId),
      surveyor: [],
    });
  };

  const handleReturnBagSubmit = (id, data) => {
    console.log(id, data);
    setReturnBagData(
      returnBagData.map((item) => {
        if (item.id === id) {
          return {
            id: id,
            packaging: data,
          };
        }
        return item;
      })
    );
  };

  const checkRowAvailableBags = async (field = "") => {
    if (returnBagData.length > 0) {
      let hasError = false;
      let errorMsg = { ...error };
      returnBagData.map((item) => {
        const finalAvailableBags = singleRowAvailableBags(item.id);
        const bagsSum = singleRowBagsSum(item.id);
        if (Number(bagsSum) > Number(finalAvailableBags)) {
          hasError = true;
          errorMsg[`inputData_${item.id}`].forEach((obj) => {
            obj.bags = `Sum of bags exceeds available ${finalAvailableBags} bags`;
          });
        } else if (
          field === "submit" &&
          Number(bagsSum) < Number(finalAvailableBags)
        ) {
          hasError = true;
          errorMsg[`inputData_${item.id}`].forEach((obj) => {
            obj.bags = `Sum of bags less than available ${finalAvailableBags} bags`;
          });
        } else {
          hasError = false;
          errorMsg[`inputData_${item.id}`].forEach((obj) => {
            obj.bags = "";
          });
        }
      });
      console.log(errorMsg);
      setError(errorMsg);
      return hasError;
    }
  };

  const checkAllFields = async () => {
    let hasError = false;
    let extraDataErrorMsg = { ...extraDataError };
    console.log("hasError", hasError, extraDataErrorMsg, extraDataError);
    Object.keys(extraData).forEach((key) => {
      if (key === "survey_company" && !extraData[key]?.id) {
        hasError = true;
        extraDataErrorMsg[key] = `Invalid ${formatLabel(key)}`;
      } else if (!Boolean(extraData[key] && !extraDataErrorMsg[key])) {
        hasError = true;
        extraDataErrorMsg[key] = extraDataErrorMsg[key]
          ? extraDataErrorMsg[key]
          : `Invalid ${formatLabel(key)}`;
      } else {
        extraDataErrorMsg[key] = false;
      }
    });

    let hasInputData = false;
    let godownFieldsCheck = ["status", "bags", "godown"];
    let shippingFieldsCheck = [
      "status",
      "bags",
      "shipping_date",
      "sales_order",
      "custom_invoice",
    ];
    let errorMsg = { ...error };
    Object.keys(inputData).forEach((key) => {
      // Check if all entries are missing a valid status
      if (!inputData[key]?.some((d) => Boolean(d?.status?.length))) {
        console.log("here");
        hasError = true;
        hasInputData = true;
        errorMsg[key][0] = {
          status: `Invalid Status`,
          bags: `Invalid Bags`,
          godown: `Invalid Godown`,
        };
        setError(errorMsg);
      } else {
        inputData[key].forEach((d, index) => {
          if (Boolean(d.status?.length)) {
            const fields =
              d.status === "Continue to Shipping"
                ? shippingFieldsCheck
                : godownFieldsCheck;
            fields.map((field) => {
              console.log(d, field, !d[field], !d[field]?.id, errorMsg);
              if (["bags", "status", "shipping_date"].includes(field)) {
                if (!Boolean(d[field])) {
                  hasError = true;
                  hasInputData = true;
                  errorMsg[key][index][field] =
                    field === "bags" &&
                    Number(d[field]) > singleRowAvailableBags(key.split("_")[1])
                      ? "Bags cannot be greater than Total Available Bags"
                      : `Invalid ${formatLabel(field)}`;
                }
              } else if (!d[field]?.id) {
                hasError = true;
                hasInputData = true;
                errorMsg[key][index][field] = `Invalid ${formatLabel(field)}`;
              } else {
                errorMsg[key][index][field] = false;
              }
            });
          }
        });
      }
    });

    if (hasInputData) {
      console.log("errorMsg", errorMsg);
      setError(errorMsg);
    }

    setExtraDataError(extraDataErrorMsg);
    return hasError;
  };

  const handleSubmit = async () => {
    let isError = await checkAllFields();
    let isBagsError = await checkRowAvailableBags("submit");
    if (isError || isBagsError) return;
    else {
      let arrival = {
        arrival_status: "reached",
        approval_status:
          props?.user?.userData?.roles?.toString().toLowerCase() === "admin"
            ? "ACCEPTED"
            : "PENDING",
        arrival_date: moment(extraData.arrival_date).format("YYYY-MM-DD"),
        gross_weight: Number(extraData.gross_weight),
        net_weight: Number(extraData.net_weight),
        tare_weight: Number(extraData.tare_weight),
        survey_company: extraData.survey_company?.id,
        surveyor: extraData.surveyor?.id || null,
      };

      let itemBulkUpdateGodown = [];
      let itemBulkUpdateShipping = [];

      let packagingBulkUpdateGodown = [];
      let packagingBulkUpdateShipping = [];

      let moveShippingArr = [];

      let totalArr = 0;
      Object.keys(inputData).forEach((key, keyIndex) => {
        inputData[key].forEach((input, index) => {
          let itemRowData = itemData.find(
            (i) => i.dispatch_items_details?.po_item == input.po_item_id
          );
          console.log(itemRowData, "itemRowData", itemData, input);
          let returnBagRow = returnBagData.find(
            (r) => r.id === input.po_item_id
          );
          let returned_bags_of_packgings = returnBagRow?.packaging?.map((p) => {
            return { [String(p.packaging)]: Number(p.return_bag || 0) };
          });
          // itemBulk
          let item = {
            dispatch_items: itemRowData.dispatch_items,
            arrival: props.listRow.id,
            remark: input.remark,
            godown: input.godown?.id || null,
            arrival_bags: Number(input.bags),
            returned_bags_of_packgings: returned_bags_of_packgings,
            returned_bags: returnBagRow?.packaging.reduce(
              (acc, curr) => acc + Number(curr.return_bag || 0),
              0
            ),
            port: itemRowData.godowns_list?.[0]?.port,
            arrival_status: "reached",
            arrival_item_status: "",
            return_status: "semi_returned",
          };
          if (index === 0) {
            item.id = itemRowData.id;
          }
          if (input.status === "Move to Godown") {
            item.arrival_item_status = "godown";

            itemBulkUpdateGodown.push(item);
          } else if (input.status === "Continue to Shipping") {
            item.arrival_item_status = "shipped";
            item.sales_order = input.sales_order?.id || null;
            item.custom_invoice = input.custom_invoice?.id || null;
            item.shipping_date = moment(input.shipping_date).format(
              "YYYY-MM-DD"
            );
            item.unloading_agent = input?.unloading_agent?.id || null;
            item.mr_or_leo_no = input.mr_or_leo_no || "";
            item.commercial_invoice = input?.commercial_invoice?.id || null;

            itemBulkUpdateShipping.push(item);
          }

          //packagingBulk
          itemRowData.dispatch_items_details.dispatch_packaging.map((p) => {
            let arrivalItem = itemRowData.arrival_packagings.find(
              (i) => i.dispatch_packaging === p.id
            );
            console.log(
              arrivalItem,
              "arrivalItem",
              itemRowData.arrival_packagings
            );
            let Obj = {
              dispatch_packaging: arrivalItem?.dispatch_packaging,
              packaging: p.packaging,
              returned_bags:
                returnBagRow?.packaging.find((r) => r.id == p.id)?.return_bag ||
                0,
              arrival_item: "",
              index: totalArr++,
              arrival_packaging_bags: Number(input.bags),
            };

            if (index === 0) {
              Obj.arrival_item = itemRowData.id;
              if (arrivalItem?.id) {
                Obj.id = arrivalItem?.id;
              }
            }
            if (input.status === "Move to Godown") {
              Obj.godown = input.godown?.id;
              packagingBulkUpdateGodown.push(Obj);
            } else if (input.status === "Continue to Shipping") {
              Obj.godown = null;
              packagingBulkUpdateShipping.push(Obj);
            }
          });

          //moveShippingArr

          let used_weight = {};
          let packaging_count = {};
          itemRowData.dispatch_items_details.dispatch_packaging.map((p) => {
            let returnBag =
              returnBagRow?.packaging.find((r) => r.id == p.id)?.return_bag ||
              0;
            used_weight[String(p.packaging)] = returnBag;
            packaging_count[String(p.packaging)] = Number(
              Number(p.packaging_details?.size) * Number(returnBag)
            );
          });

          let shipObj = {
            is_active: true,
            custom_invoice: input.custom_invoice?.id || null,
            commercial_invoice: input?.commercial_invoice?.id || null,
            coming_from: "DIRECT",
            brand: itemRowData.brand,
            godown: input.godown?.id || null,
            port: itemRowData.godowns_list?.[0]?.port,
            shipping_date: moment(input.shipping_date).format("YYYY-MM-DD"),
            unloading_agent: input?.unloading_agent?.id || null,
            mr_or_leo_no: input.mr_or_leo_no || "",
            arrival_item: "",
            index: totalArr + 1,
            used_weight: used_weight,
            packaging_count: packaging_count,
          };

          if (index === 0) {
            shipObj.arrival_item = itemRowData.id;
            moveShippingArr.push(shipObj);
          }
          //  "used_weight": {
          //         "6": 6
          //     },
          //     "packaging_count": {
          //         "6": 120
          //     },
        });
      });

      console.log(
        "payload",
        inputData,
        returnBagData,
        itemData,
        arrival,
        itemBulkUpdateGodown,
        itemBulkUpdateShipping
      );

      //2:
      // newArr(godown) = [
      //     {
      //       "dispatch_items": 208,
      //       "arrival": 205,
      //       "remark": "check",
      //       "godown": "14",
      //       "returned_bags_of_packgings": {
      //           "6": 0
      //       },
      //       "returned_bags": 0,
      //       "id": 206,
      //       "port": 7,
      //       "arrival_status": "reached",
      //       "arrival_item_status": "godown",
      //       "return_status": "semi_returned"
      //   }
      // ]
      // newArr (shipping) = [
      //   {
      //     "dispatch_items": 208,
      //     "arrival": 205,
      //     "remark": "check",
      //     "godown": null,
      //     "returned_bags_of_packgings": {
      //       "6": 0
      //     },
      //     "returned_bags": 0,
      //     "id": 206,
      //     "port": 7,
      //     "arrival_status": "reached",
      //     "sales_order": 26,
      //     "custom_invoice": 48,
      //     "shipping_date": "2025-01-03",
      //     "unloading_agent": "6",
      //     "mr_or_leo_no": "wdsd",
      //     "arrival_item_status": "shipped",
      //     "return_status": "semi_returned"
      //   }
      // ]
      // this.props.PortsFunction(
      //   "put",
      //   "arrival/items/bulk-update",
      //   null,
      //   "post",
      //   newArr
      // )
      //3
      // newPackagingArr = [
      //     {
      //       "dispatch_packaging": 203,
      //       "arrival_item": 206,
      //       "packaging": 6,
      //       "returned_bags": 0,
      //       "godown": "14" || null (for shipping),
      //       "id": 202
      //   }
      // ]
      // this.props
      // .PortsFunction(
      //   "put",
      //   "arrival/packaging/bulk-update",
      //   null,
      //   "post",
      //   newPackagingArr
      // )
      // 4 : Shipping only
      // moveShippingArr = [
      //     {
      //       "used_weight": {
      //           "6": 6
      //       },
      //       "packaging_count": {
      //           "6": 120
      //       },
      //       "is_active": true,
      //       "custom_invoice": 48,
      //       "commercial_invoice": null,
      //       "coming_from": "DIRECT",
      //       "brand": 23,
      //       "godown": "14",
      //       "port": 7,
      //       "shipping_date": "2025-01-03",
      //       "unloading_agent": "6",
      //       "mr_or_leo_no": "wdsd",
      //       "arrival_item": 206
      //   }
      // ]
      // if (
      //   moveShippingArr.length >
      //     0 &&
      //   this.props?.user?.userData?.roles
      //     ?.toString()
      //     .toLowerCase() ===
      //     "admin"
      // ) {
      //   this.props
      //     .PortsFunction(
      //       "post",
      //       "arrival/commercial-invoice/packing-list/bulk-create",
      //       null,
      //       "post",
      //       moveShippingArr
      //     )
      //refetch = this.fetchDispatchList(
      //     editArrival
      //     ? "Reached"
      //     : "Arrival"
      // );

      props
        .PortsFunction("patch", "arrival", props.listRow.id, "post", arrival)
        .then((res) => {
          if (res.error) {
            console.log(res);
            showSnackbar(res.data, "error");
          } else {
            if (itemBulkUpdateGodown.length > 0) {
              props
                .PortsFunction(
                  "put",
                  "arrival/items/bulk-update",
                  null,
                  "post",
                  itemBulkUpdateGodown
                )
                .then((res2) => {
                  if (res2.error) {
                    console.log(res2);
                    showSnackbar(res2.data, "error");
                  } else {
                    console.log(res2);
                    let arrivalItemsIds = JSON.parse(res2);
                    console.log("arrivalItemsIds", arrivalItemsIds);
                    let newPackaingBulkUpdate = [];
                    packagingBulkUpdateGodown.map((pa) => {
                      console.log(pa, pa.index, arrivalItemsIds[pa.index]);
                      let Obj = {
                        ...pa,
                        arrival_item: arrivalItemsIds[pa.index],
                      };
                      delete Obj.index;
                      console.log("OBJ", Obj);
                      newPackaingBulkUpdate.push(Obj);
                    });
                    props
                      .PortsFunction(
                        "put",
                        "arrival/packaging/bulk-update",
                        null,
                        "post",
                        newPackaingBulkUpdate
                      )
                      .then((resp) => {
                        if (resp.error) {
                          console.log(resp);
                          showSnackbar(resp.data, "error");
                        } else if (itemBulkUpdateShipping.length <= 0) {
                          resetState();
                          props.fetchDispatchList("Reached");
                        }
                      });
                  }
                });
            }
            if (itemBulkUpdateShipping.length > 0) {
              props
                .PortsFunction(
                  "put",
                  "arrival/items/bulk-update",
                  null,
                  "post",
                  itemBulkUpdateShipping
                )
                .then((res3) => {
                  if (res3.error) {
                    console.log(res3);
                    showSnackbar(res3.data, "error");
                  } else {
                    let arrivalItemsIds = JSON.parse(res3);
                    let newPackaingBulkUpdateShipping = [];
                    packagingBulkUpdateShipping.map((pa) => {
                      let Obj = {
                        ...pa,
                        arrival_item: arrivalItemsIds[pa.index],
                      };
                      delete Obj.index;
                      newPackaingBulkUpdateShipping.push(Obj);
                    });

                    props
                      .PortsFunction(
                        "put",
                        "arrival/packaging/bulk-update",
                        null,
                        "post",
                        newPackaingBulkUpdateShipping
                      )
                      .then((res4) => {
                        if (res4.error) {
                          console.log(res4);
                          showSnackbar(res4.data, "error");
                        } else {
                          let newShippingArr = [];
                          moveShippingArr.map((pa) => {
                            let Obj = {
                              ...pa,
                              arrival_item: arrivalItemsIds[pa.index],
                            };
                            delete Obj.index;
                            newShippingArr.push(Obj);
                          });
                          if (
                            newShippingArr.length > 0 &&
                            this.props?.user?.userData?.roles
                              ?.toString()
                              .toLowerCase() === "admin"
                          ) {
                            this.props
                              .PortsFunction(
                                "post",
                                "arrival/commercial-invoice/packing-list/bulk-create",
                                null,
                                "post",
                                newShippingArr
                              )
                              .then((res5) => {
                                if (res5.error) {
                                  console.log(res5);
                                  showSnackbar(res5.data, "error");
                                } else {
                                  resetState();
                                  props.fetchDispatchList("Reached");
                                }
                              });
                          }
                        }
                      });
                  }
                });
            }

            // refetch and reset state
          }
        });
    }
  };

  useEffect(() => {
    let dataField = {};
    let errorField = {};
    itemData?.forEach((item) => {
      dataField[`inputData_${item.dispatch_items_details?.po_item}`] = [
        {
          ...defaultFields,
          po_item_id: item.dispatch_items_details?.po_item,
        },
      ];

      errorField[`inputData_${item.dispatch_items_details?.po_item}`] = [
        {
          ...Object.fromEntries(
            Object.keys(defaultFields).map((key) => [key, false])
          ),
          po_item_id: item.dispatch_items_details?.po_item,
        },
      ];
    });
    const returnBagDataArr = itemData?.map((item) => ({
      id: item.dispatch_items_details?.po_item,
      packaging: item.dispatch_items_details.dispatch_packaging.map((p) => ({
        ...p,
        return_bag: "",
      })),
    }));
    setReturnBagData(returnBagDataArr);
    setInputData(dataField);
    setError(errorField);
  }, [itemData]);

  useEffect(() => {
    console.log("RETURENED-CHECK", returnBagData);
    checkRowAvailableBags();
  }, [returnBagData]);

  useEffect(() => {
    if (actionDialogOpen) {
      setInitialValue();
    }
  }, [props.listRow, actionDialogOpen]);

  const { unit, ItemCateoryList, ItemList, BrandList } = props;

  console.log("itemData", itemData, props, inputData, returnBagData);
  return (
    <>
      <Button
        style={{
          cursor: "pointer",
          backgroundColor: "rgb(241 154 48)",
          color: "white",
          fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
          fontFamily: "Poppins",
        }}
        size="small"
        withBg={true}
        onClick={async() => {
          await fetchItemList();
        }}
      >
        Mark Reached
      </Button>
      <CustomDialog
        open={actionDialogOpen}
        onClose={() => {
          resetState();
        }}
        maxWidth={"90%"}
        title={"Mark as Reached (Selected Dispatched Item Details)"}
        resetButton="Reset"
        onResetClick={() => {
          setInitialValue();
        }}
        submitButton="Submit"
        onSubmitClick={() => {
          handleSubmit();
        }}
      >
        <div className="d-flex flexWrap justifyFS">
          <MuiDatePicker
            required={true}
            style={styles.inputExtraField}
            error={!!extraDataError?.arrival_date}
            helperText={extraDataError?.arrival_date}
            invalidLabel=""
            onKeyPress={(ev) => {
              ev.preventDefault();
            }}
            allowKeyboardControl={false}
            autoOk
            inputVariant="outlined"
            label={"Arrival Date"}
            value={extraData?.arrival_date || ""}
            onChange={(newValue) => {
              handleExtraDataChange("arrival_date", newValue);
            }}
          />
          <CssTextField
            required={true}
            style={styles.inputExtraField}
            id="outlined-basic"
            label={"Port Gross weight"}
            type={"number"}
            onFocus={StopScroll}
            value={extraData?.gross_weight || ""}
            error={!!extraDataError.gross_weight}
            helperText={extraDataError.gross_weight}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleExtraDataChange("gross_weight", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            style={styles.inputExtraField}
            id="outlined-basic"
            label={"Port Tare weight"}
            type={"number"}
            onFocus={StopScroll}
            value={extraData?.tare_weight || ""}
            error={!!extraDataError.tare_weight}
            helperText={extraDataError.tare_weight}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleExtraDataChange("tare_weight", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            disabled={true}
            style={styles.inputExtraField}
            id="outlined-basic"
            label={"Port Net weight"}
            type={"number"}
            onFocus={StopScroll}
            value={extraData?.net_weight || ""}
            error={!!extraDataError.net_weight}
            helperText={extraDataError.net_weight}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleExtraDataChange("net_weight", e.target.value);
            }}
            variant="outlined"
          />
          <CustomSearchAutocomplete
            id="surveyCompany"
            required={true}
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            value={extraData.survey_company || null}
            error={!!extraDataError.survey_company}
            helperText={extraDataError.survey_company}
            onChange={(e, newValue) => {
              handleExtraDataChange("survey_company", newValue);
            }}
            style={styles.inputExtraField}
            label="Survey Company"
            placeholder="Search Survey Company"
            multiLineRender={[
              { label: "", key: "name" },
              { label: "GST :", key: "gstin" },
              { label: "Address :", key: "address" },
            ]}
            api={{
              callFn: props.PortsFunction,
              url: EndPoints["SurveyCompanys"],
              resKey: "SurveyCompanyList",
              params: { is_active: "True" },
            }}
          />
          <CustomAutocomplete
            id="surveyor"
            options={surveyorList || []}
            getOptionLabel={(option) => option?.name}
            value={extraData.surveyor || null}
            onChange={(e, newValue) => {
              handleExtraDataChange("surveyor", newValue);
            }}
            style={styles.inputExtraField}
            disableClearable
            label="Surveyor"
          />
        </div>
        <div style={{ margin: "20px 0", height: 800 }}>
          <TableComponent
            {...props}
            module={"Mark As Reached"}
            noAddButton={true}
            noSearchBar={true}
            simpleTable={true}
            padding={"0px"}
            tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
            headerShownAlways={true}
            tableRowHeight={30}
            loader={false}
            actions={[]}
            header={Header}
            tableHeight="100%"
            tablePagination={false}
            subMenu={true}
            defaultMenuOpenIndex={0}
            footer={false}
            footerList={
              itemData?.length && {
                "Item Category": "Total",
                "Total Bags":
                  itemData?.reduce((acc, item) => {
                    return (
                      Number(acc) +
                      Number(item.dispatch_items_details?.total_bags)
                    );
                  }, 0) +
                  " " +
                  (unit || ""),
              }
            }
            subMenuCustomUI={{
              visible: true,
              component: (single) => {
                return (
                  <div>
                    {inputData[
                      `inputData_${single.dispatch_items_details?.po_item}`
                    ]?.length &&
                      inputData[
                        `inputData_${single.dispatch_items_details?.po_item}`
                      ]?.map((input, inputIndex) => {
                        return (
                          <SubMenuRow
                            id={single.dispatch_items_details?.po_item}
                            singleRowData={single}
                            index={inputIndex}
                            inputData={input}
                            error={
                              error[
                                `inputData_${single.dispatch_items_details?.po_item}`
                              ][inputIndex]
                            }
                            handleInputDataChange={handleInputDataChange}
                            removeItemState={removeItemState}
                            props={props}
                          />
                        );
                      })}
                    {console.log("singleItemRow", single)}
                    {isAddItemRowButtonVisible(
                      single.dispatch_items_details?.po_item
                    ) && (
                      <Button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(241 154 48)",
                          color: "white",
                          fontSize: mediumScreen() ? "0.7rem" : "0.75rem",
                          fontFamily: "Poppins",
                          margin: "10px",
                        }}
                        size="small"
                        withBg={false}
                        onClick={() => {
                          addItemState(single.dispatch_items_details?.po_item);
                        }}
                        startIcon={
                          <Add
                            style={{
                              color: "white",
                              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                            }}
                          />
                        }
                      >
                        Add
                      </Button>
                    )}
                  </div>
                );
              },
            }}
            tableList={
              itemData && itemData.length
                ? itemData.map((s) => {
                    const details = s.dispatch_items_details;

                    console.log(
                      "itemsLIST",
                      s,
                      BrandList,
                      Number(details.brand),
                      BrandList?.find((d) => d.id === Number(details.brand))
                    );

                    s["Brand"] =
                      BrandList?.find((d) => d.id === Number(details.brand))
                        ?.brand_name || "";
                    s["Item Category"] =
                      ItemCateoryList?.find(
                        (d) => d.id === Number(details.item_category)
                      )?.name || "";
                    s["Item Type"] =
                      ItemList?.find((d) => d.id === Number(details.item_type))
                        ?.broken_percentage || "";
                    s["Item TypeUnit"] = s["Item Type"] + "%";
                    s["PackagingData"] = details.dispatch_packaging;
                    s["Packaging"] = () => (
                      <div>
                        <ShowPackaging
                          noTotalbags={true}
                          returned_bags={true}
                          details={details.dispatch_packaging.map((sP) => {
                            const singlePack = sP.packaging_details;
                            singlePack.used_bags = sP.total_bags_dispatched;
                            singlePack.returned_bags = 0;
                            // singlePack.returned_bags = this.state[
                            //   `${sP.id}Returned`
                            // ]
                            //   ? this.state[`${sP.id}Returned`]
                            //   : "";
                            return singlePack;
                          })}
                        />
                        <ReturnBag
                          itemReturnBagData={returnBagData.find(
                            (d) => d.id === details.po_item
                          )}
                          handleReturnBagSubmit={handleReturnBagSubmit}
                        />
                      </div>
                    );
                    s["Total Bags"] = details.total_bags
                      ? details.total_bags
                      : "";
                    s["Total Return Bags"] =
                      returnBagData
                        .find((r) => r.id === details.po_item)
                        ?.packaging?.reduce(
                          (a, b) => a + Number(b.return_bag),
                          0
                        ) || 0;

                    console.log("s", s);

                    return s;
                  })
                : []
            }
          />
        </div>
      </CustomDialog>
    </>
  );
};

export default MarkReachedDialogBox;
