import React, { useEffect, useState } from "react";
import CustomDialog from "../../layoutComponents/CustomDialog";
import MuiDatePicker from "../../layoutComponents/MuiDatePicker";
import moment from "moment";
import {
  CustomAutocomplete,
  CustomMultiSelectAutocomplete,
} from "../../layoutComponents/CustomAutocomplete";
import { divide, multiply, set, subtract } from "lodash";
import { CssTextField, CssTextField85 } from "../../styles/StyledComponents";
import { Divider, InputAdornment } from "@mui/material";
import TableComponent from "../../layoutComponents/TableComponent";
import { primary } from "../../styles/colors";
import { ListMapUI } from "../../layoutComponents/ListMapUI";
import { StopScroll } from "../../Regex";
import EastIcon from "@mui/icons-material/East";
import { useSnackbar } from "../../snackbarContext";

const styles = {
  inputField: {
    width: 250,
    margin: "10px 10px 10px 2px",
  },
  inputExtraField: {
    minWidth: 230,
    width: "calc(33% - 20px)",
    margin: "10px",
  },
  helperTextUnit: {
    position: "absolute",
    top: "5vh",
    color: primary,
  },
};

const changeUnit = (from, to, weigths, opposite) => {
  //default in kg

  if (opposite) {
    if (from === "MT") {
      return changeTo(to, divide(weigths, 1000), opposite);
    } else if (from === "Kgs") {
      return changeTo(to, weigths);
    } else if (from === "Qtl") {
      return changeTo(to, divide(weigths, 100), opposite);
    }
  } else {
    if (from === "MT") {
      return changeTo(to, multiply(weigths, 1000));
    } else if (from === "Kgs") {
      return changeTo(to, weigths);
    } else if (from === "Qtl") {
      return changeTo(to, multiply(weigths, 100));
    }
  }
};

const changeTo = (to, weigths, opposite) => {
  if (opposite) {
    if (to === "MT") {
      return multiply(weigths, 1000);
    } else if (to === "Kgs") {
      return weigths;
    } else if (to === "Qtl") {
      return multiply(weigths, 100);
    }
  } else {
    if (to === "MT") {
      return divide(weigths, 1000);
    } else if (to === "Kgs") {
      return weigths;
    } else if (to === "Qtl") {
      return divide(weigths, 100);
    }
  }
};

const InventoryShippingDialog = (props) => {
  const [inventoryBrandStock, setInventoryBrandStock] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [extraDataError, setExtraDataError] = useState({});
  const [optionLoader, setOptionLoader] = useState(false);
  const [customInvoiceList, setCustomInvoiceList] = useState([]);
  const [commercialInvoiceList, setCommercialInvoiceList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [itemInputData, setItemInputData] = useState([]);
  const [salesOrderListArr, setSalesOrderListArr] = useState([]);
  const [customInvoicePackaging, setCustomInvoicePackaging] = useState([]);
  const showSnackbar = useSnackbar();

  const reset = () => {
    setExtraData({});
    setExtraDataError({});
    setCustomInvoiceList([]);
    setCommercialInvoiceList([]);
    setItemData([]);
    setItemInputData([]);
  };

  const fetchSalesOrder = (brandId) => {
    props
      .PortsFunction(
        "get",
        "dispatch/sales-order/list",
        null,
        "PortCIs",
        null,
        {
          status: "OPEN",
          brand: brandId,
        }
      )
      .then((salesorderFilterList) => {
        setExtraData((prev) => ({
          ...prev,
          commercial_invoice: [],
          custom_invoice: [],
          custom_invoice_quantity: "",
        }))
        if (salesorderFilterList) {
          setSalesOrderListArr(salesorderFilterList);
        } else {
          setSalesOrderListArr([]);
        }
      });
  };

  const fetchInventoryBrandStock = (godownId, brandId) => {
    props
      .PortsFunction(
        "get",
        "arrival/inventory-bag-stock/list/",
        null,
        "",
        null,
        {
          godown_id: godownId,
          brand_ids: brandId,
        }
      )
      .then((res) => {
        setInventoryBrandStock(res);
      });
  };

  const fetchCustomInvoice = (salesId, brandId) => {
    setOptionLoader(true)
    props
      .PortsFunction(
        "get",
        "arrival/custom-invoice/list",
        null,
        "PortCIs",
        null,
        {
          sales_order: salesId,
          is_active: "True",
          brand: extraData.brand_to?.id || brandId,
          status: "DRAFT,OPEN",
        }
      )
      .then((customInvoices) => {
        setExtraData((prev) => ({
          ...prev,
          commercial_invoice: [],
          custom_invoice: [],
          custom_invoice_quantity: "",
        }))
        let filterCustomInvoiceData = [];
        if (customInvoices) {
          const portID = props?.singlelistView && props?.singlelistView?.id;
          filterCustomInvoiceData = customInvoices.filter(
            (c) => c.loading_port === portID
          );
          setCustomInvoiceList(filterCustomInvoiceData);
          setOptionLoader(false)
        }
      });
  };

  const fetchCommercialInvoice = (comm_inv) => {
    if (comm_inv) {
      props
        .PortsFunction(
          "get",
          `arrival/commercial-invoice/${comm_inv}`,
          null,
          "PortCIs",
          null,
          {}
        )
        .then((commercialInvoice) => {
          setCommercialInvoiceList(commercialInvoice);
        });
    }
  };

  const handleExtraDataChange = (key, value) => {
    console.log(key, value);
    if (key === "brand_to") {
      if (value?.id) {
        fetchSalesOrder(value?.id);
        fetchInventoryBrandStock(props.shippingData?.godown, value?.id);
      } else {
        fetchSalesOrder(props.shippingData?.brand);
        fetchInventoryBrandStock(
          props.shippingData?.godown,
          props.shippingData?.brand
        );
      }
      setExtraData((prev) => ({
        ...prev,
        brand_to: value,
        sales_order: [],
        commercial_invoice: [],
        custom_invoice: [],
        custom_invoice_quantity: "",
      }));
    } else {
      setExtraData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }

    if (key === "sales_order") {
      setCustomInvoiceList([]);
      fetchCustomInvoice(value?.id, extraData.brand?.id);
    } else if (key === "custom_invoice") {
      setExtraData((prev) => ({
        ...prev,
        custom_invoice_quantity: subtract(
          Number(value?.net_weight ? value?.net_weight : 0),
          Number(value?.used_weight ? value?.used_weight : 0)
        ),
        custom_invoice: value,
      }));
      console.log("CUST-",value, value?.net_weight, value?.used_weight);
      if (value.id) {
        let customPackagingList = [];
        let customItem = value.custom_invoice_item_details;
        if (customItem.length > 0) {
          customItem.map((item) => {
            if (item.pkg_counter && Object.keys(item.pkg_counter).length > 0) {
              Object.keys(item.pkg_counter).map((k) => {
                let packData = props.masters["PackagingList"].find(
                  (p) => p.id === Number(k)
                );
                console.log(
                  "custom-packData",
                  packData,
                  item?.pkg_counter?.[k],
                  k
                );
                if (packData) {
                  customPackagingList.push({
                    ...packData,
                    sizeUnit: `${packData.size} ${packData.unit} ${packData.bag_type}`,
                    customInvoiceBags: item?.pkg_counter?.[k] || 0,
                  });
                }
              });
            }
          });
        }
        console.log("customPackagingList", customPackagingList);
        setCustomInvoicePackaging(customPackagingList);
      }
      fetchCommercialInvoice(value?.commercial_invoice);
    }
  };

  const calculateTotalBags = (
    total_quantity,
    total_quantity_unit,
    packSize,
    packUnit
  ) => {
    if (total_quantity_unit === packUnit) {
      return packSize
        ? Math.ceil(divide(Number(total_quantity), Number(packSize)))
        : "";
    } else {
      return packSize
        ? Math.ceil(
            divide(
              Number(total_quantity),
              Number(changeUnit(packUnit, total_quantity_unit, packSize))
            )
          )
        : "";
    }
  };

  const calculateOldShippedBags = (shippedBags, totalBags, totalBagsTo) => {
    return Number(shippedBags) > 0
      ? Math.ceil(Number(divide(totalBags, totalBagsTo)) * Number(shippedBags))
      : 0;
  };

  const checkShippedBagsValid = (shippedBags, itemData) => {
    let brandData = inventoryBrandStock.find(
      (inv) => inv.brand_id == (extraData?.brand_to?.id || extraData?.brand?.id)
    );
    let availableBags =
      brandData?.packaging_data?.find(
        (p) =>
          p.packaging[0]?.id ==
          (itemData.packaging_to?.id || itemData.bagsData?.id)
      )?.total_bags || 0;
    console.log(brandData, availableBags);
    let totalBags = itemData.total_bags_to || itemData.total_bags;

    if(!extraData?.brand_to?.id && !itemData.packaging_to?.id){
      availableBags = itemData.total_bags
    }

    console.log("checkBags", shippedBags, totalBags, availableBags, !extraData?.brand_to?.id , !itemData.packaging_to?.id);
    if (shippedBags > totalBags || shippedBags > availableBags) {
      return availableBags <= 0
        ? "No Available Bags"
        : `limit ${
            totalBags < availableBags ? totalBags : availableBags
          } bags!`;
    } else {
      return false;
    }
  };

  const handleItemFieldChange = (key, value, index) => {
    let updatedData = [...itemInputData];
    updatedData[index][key] = value;
    console.log(key, value, updatedData, inventoryBrandStock);
    if (key === "shipped_bags") {
      updatedData[index].shipped_quantity = multiply(
        Number(value || 0),
        Number(updatedData[index].packaging_to?.id 
          ? updatedData[index].packaging_to?.size
          : updatedData[index].bagsData.size)
      );
      updatedData[index].shipped_bags_error = checkShippedBagsValid(
        value,
        updatedData[index]
      );
      if (updatedData[index].packaging_to?.id) {
        updatedData[index].shipped_bags_old = calculateOldShippedBags(
          updatedData[index]?.shipped_bags || 0,
          updatedData[index]?.total_bags,
          updatedData[index]?.total_bags_to
        );
      }
    } else if (key === "packaging_to") {
      updatedData[index].total_bags_to = calculateTotalBags(
        updatedData[index]?.total_quantity,
        updatedData[index]?.unit,
        value.size,
        value.unit
      );
      updatedData[index].shipped_bags_old = calculateOldShippedBags(
        updatedData[index]?.shipped_bags || 0,
        updatedData[index]?.total_bags,
        updatedData[index]?.total_bags_to
      );
      updatedData[index].shipped_bags_error = checkShippedBagsValid(
        updatedData[index]?.shipped_bags || 0,
        updatedData[index]
      );
    }
    setItemInputData(updatedData);
  };

  const convertedShippedQtyValue = (key, value, rowUnit) => {
    if (extraData.custom_invoice?.unit) {
      if (key === "shipped_quantity") {
        return changeUnit(rowUnit, extraData.custom_invoice?.unit, value || "");
      }
    }
  };

  const checkValidation = () => {
    let error = {};
    let isValid = true;

    if (!extraData.brand) {
      error.brand = "Brand is required";
      isValid = false;
    }
    console.log("error", extraData, extraData.sales_order, extraData.custom_invoice);
    if (!extraData.sales_order?.id) {
      error.sales_order = "Sales order is required";
      isValid = false;
    }

    if (!extraData.custom_invoice?.id) {
      error.custom_invoice = "Custom invoice is required";
      isValid = false;
    }

    if (
      !extraData.custom_invoice_quantity ||
      extraData.custom_invoice_quantity == 0
    ) {
      error.custom_invoice_quantity =
        Number(extraData.custom_invoice_quantity) <= Number(0)
          ? "Invalid quantity"
          : "Custom invoice quantity is required";
      isValid = false;
    }

    let itemError = false;
    itemInputData.forEach((item) => {
      if (!item.shipped_bags || item.shipped_bags_error) {
        itemError = true;
        item.shipped_bags_error = "Required!";
      }
    });

    if (itemError) {
      isValid = false;
      setItemInputData(itemInputData);
    }

    setExtraDataError(error);
    return isValid;
  };

  const handleSubmit = () => {
    console.log("handleSubmit", itemInputData, checkValidation());
    if (!checkValidation()) return;
    else if (
      props?.user?.userData?.roles?.toString().toLowerCase() === "admin"
    ) {
      let adminPayload = [];

      itemInputData.forEach((item) => {
        if (Number(item.shipped_bags) > Number(0)) {
          let Obj = {};
          Obj.is_active = true;
          Obj.shipping_date = moment(
            extraData.shipping_date || new Date()
          ).format("YYYY-MM-DD");
          Obj.unloading_agent = extraData.unloading_agent?.id || null;
          Obj.mr_or_leo_no = extraData.mr_or_leo_no || "";
          Obj.custom_invoice = Number(extraData.custom_invoice?.id);
          Obj.sales_order = Number(extraData.sales_order?.id);
          Obj.item_category = null;
          Obj.item_type = null;
          Obj.coming_from = "VIA GODOWN";
          Obj.brand = Number(extraData.brand?.id);
          Obj.shipped_brand = extraData.brand_to?.id
            ? Number(extraData.brand_to?.id)
            : null;
          Obj.godown = props.shippingData?.godown;
          Obj.port = props.singlelistView.id;
          Obj.old_packaging = item?.bagsData?.id;
          Obj.new_packaging = item?.packaging_to?.id || null;
          Obj.old_packaging_count = {
            [item.bagsData?.id]: Number(item.shipped_bags),
          };

          if (extraData.commercial_invoice?.id) {
            Obj.product_of_comm_inv = Number(extraData.commercial_invoice?.id);
            Obj.bl_number = extraData.custom_invoice?.bl_number || "";
          }

          if (item.packaging_to?.id) {
            Obj.packaging_count = {
              [item.packaging_to?.id]: Number(item.shipped_bags),
            };
            Obj.used_weight = {
              [item.packaging_to?.id]: convertedShippedQtyValue(
                "shipped_quantity",
                item.shipped_quantity,
                item.unit
              ),
            };
            Obj.old_packaging_count = {
              [item.bagsData?.id]: Number(item.shipped_bags_old),
            };
          } else {
            Obj.packaging_count = {
              [item.bagsData?.id]: Number(item.shipped_bags),
            };
            Obj.used_weight = {
              [item.bagsData?.id]: convertedShippedQtyValue(
                "shipped_quantity",
                item.shipped_quantity,
                item.unit
              ),
            };
          }
          adminPayload.push(Obj);
        }
      });

      props
        .PortsFunction(
          "post",
          "arrival/commercial-invoice/packing-list/bulk-create",
          null,
          "post",
          adminPayload
        )
        .then((res) => {
          if (res.error) {
            console.log(res);
            showSnackbar(res.data, "error");
          } else {
            showSnackbar(`Bags Shipped Successfully`, "success");
            reset();
            props.handleCloseDialog();
            props.updateAgain();
          }
        });
    } else {
      let ObjClient = {};

      let shipBagsPack = {};
      let packagingCount = {};
      let oldPackagingCount = {};
      let OldShippingBagsPack = {};
      let packagingConversion = [];
      itemInputData.forEach((item) => {
        if (Number(item?.shipped_bags) > Number(0)) {
          let arr = [];
          if (item.packaging_to?.id) {
            shipBagsPack[String(item.packaging_to?.id)] = Number(
              item.shipped_bags
            );
            packagingCount[String(item.packaging_to?.id)] = Number(
              item.shipped_bags
            );
            oldPackagingCount[String(item.bagsData?.id)] = Number(
              item.shipped_bags_old
            );
            OldShippingBagsPack[String(item.bagsData?.id)] = Number(
              item.shipped_bags_old
            );
          } else {
            shipBagsPack[String(item.bagsData?.id)] = Number(
              item.shipped_bags
            );
            packagingCount[String(item.bagsData?.id)] = Number(
              item.shipped_bags
            );
            oldPackagingCount[String(item.bagsData?.id)] = Number(
              item.shipped_bags
            );
            OldShippingBagsPack[String(item.bagsData?.id)] = Number(
              item.shipped_bags
            );
          }
          arr.push(Number(item.bagsData?.id));
          arr.push(
            item.packaging_to?.id ? Number(item.packaging_to?.id) : null
          );
          packagingConversion.push(arr);
        }
      });

      ObjClient.is_active = true;
      ObjClient.date = moment(extraData.shipping_date || new Date()).format(
        "YYYY-MM-DD"
      );
      ObjClient.unloading_agent = extraData.unloading_agent?.id || null;
      ObjClient.mr_or_leo_no = extraData.mr_or_leo_no || "";
      ObjClient.product_of_cust_inv = Number(extraData.custom_invoice?.id);
      ObjClient.product_of_sales_order = Number(extraData.sales_order?.id);
      ObjClient.item_category = null;
      ObjClient.item_type = null;
      ObjClient.shipping_bags_packaging = JSON.stringify(shipBagsPack);
      ObjClient.shipping_bags = itemInputData.reduce(
        (a, c) => Number(a) + Number(c?.shipped_bags || 0),
        0
      );
      ObjClient.coming_from = "VIA GODOWN";
      ObjClient.brand = Number(extraData.brand?.id);
      ObjClient.godown = props.shippingData?.godown;
      ObjClient.status = "OUT";
      ObjClient.approval_status = "PENDING";
      ObjClient.shipped_brand = extraData.brand_to?.id
        ? Number(extraData.brand_to?.id)
        : null;
      ObjClient.packaging_conversion = packagingConversion;
      ObjClient.packaging_count = packagingCount;
      ObjClient.old_packaging_count = oldPackagingCount;
      ObjClient.products_in_arrival_item = JSON.stringify(OldShippingBagsPack); // just for approval payload in godown history

      props
        .PortsFunction(
          "post",
          `arrival/godown-history/list`,
          null,
          "post",
          ObjClient
        )
        .then((res) => {
          if (res.error) {
            console.log(res);
            showSnackbar(res.data, "error");
          } else {
            showSnackbar(`Wait for Admin Approval!`, "success");
            reset();
            props.handleCloseDialog();
            props.updateAgain();
          }
        });
    }
  };

  const setInitialValue = () => {
    if (props.shippingData && props.open) {
      let itemInputData = [];
      setExtraData({
        shipping_date: moment().format("DD MMM YYYY"),
        brand: props.BrandList.find((d) => d.id == props.shippingData.brand),
        brand_to: [],
        sales_order: [],
        custom_invoice: [],
        custom_invoice_quantity: "",
        commercial_invoice: [],
        unloading_agent: [],
        mr_or_leo_no: "",
      });
      setExtraDataError({});

      let packagingBags = JSON.parse(
        props.shippingData?.bag_number_of_packgings
      );
      let packagingRowData = Object.keys(packagingBags).map((key) =>
        props.masters["PackagingList"].find((p) => p.id == key)
      );
      console.log(packagingRowData, packagingBags);
      let packDetail = packagingRowData.filter((d) =>
        packagingBags.hasOwnProperty(d.id.toString())
      );
      console.log(packDetail);
      packDetail.map((pD) => {
        itemInputData.push({
          bagsData: pD,
          packaging_to: [],
          total_bags: packagingBags?.[pD.id] || 0,
          total_bags_to: "",
          total_quantity: multiply(
            Number(packagingBags?.[pD.id] || 0),
            Number(pD.size)
          ),
          shipped_bags_old: "",
          shipped_bags: "",
          shipped_bags_error: false,
          shipped_quantity: 0,
          unit: pD?.unit,
        });
      });
      setItemInputData(itemInputData);
      setItemData(packagingRowData);
    }
  };

  useEffect(() => {
    setSalesOrderListArr(props.salesOrderList);
  }, [props.salesOrderList]);

  useEffect(() => {
    console.log("call", props.shippingData, props.open);
    if (props.shippingData && props.open) {
      fetchInventoryBrandStock(
        props.shippingData?.godown,
        props.shippingData?.brand
      );
      setInitialValue();
    }
  }, [props.shippingData?.brand]);

  const { open, BrandList, salesOrderList, handleCloseDialog, masters } = props;

  console.log(props, extraData);

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleCloseDialog();
        reset();
      }}
      maxWidth={"90%"}
      title={`Move To Shipping | ${props.singlelistView?.name || ""} | ${
        props.singlelistView.godown_details.find(
          (g) => g.id == props.shippingData?.godown
        )?.name || ""
      }`}
      resetButton="Reset"
      onResetClick={() => {
        setInitialValue();
      }}
      submitButton="Move To Shipping"
      onSubmitClick={() => {
        handleSubmit();
      }}
    >
      <div className="d-flex d-flex-column" style={{ height: 600 }}>
        <div className="d-flex">
          <div className="d-flex flexWrap justifyFS" style={{ width: "70%" }}>
            <MuiDatePicker
              required={true}
              style={styles.inputExtraField}
              error={!!extraDataError?.shipping_date}
              helperText={extraDataError?.shipping_date}
              invalidLabel=""
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
              allowKeyboardControl={false}
              autoOk
              inputVariant="outlined"
              label={"Arrival Date"}
              value={extraData?.shipping_date || ""}
              onChange={(newValue) => {
                handleExtraDataChange("shipping_date", newValue);
              }}
            />
            <CustomAutocomplete
              required={true}
              id="brand_from"
              disabled={true}
              options={BrandList || []}
              getOptionLabel={(option) => option?.brand_name}
              value={extraData.brand || null}
              onChange={(e, newValue) => {
                handleExtraDataChange("brand", newValue);
              }}
              error={!!extraDataError?.brand}
              helperText={extraDataError?.brand}
              style={styles.inputExtraField}
              label="Brand"
            />
            <CustomAutocomplete
              id="brand_to"
              options={
                BrandList.filter((b) => b.id !== extraData.brand?.id) || []
              }
              getOptionLabel={(option) => option?.brand_name}
              value={extraData.brand_to || null}
              onChange={(e, newValue) => {
                handleExtraDataChange("brand_to", newValue);
              }}
              style={styles.inputExtraField}
              label="Brand (To)"
            />
            <CustomAutocomplete
              required={true}
              id="sales_order"
              options={
                salesOrderListArr.filter((s) =>
                  s.so_items_brands.includes(
                    extraData?.brand_to?.id
                      ? extraData.brand_to?.id
                      : extraData.brand?.id
                  )
                ) || []
              }
              getOptionLabel={(option) => option?.so_number}
              value={extraData.sales_order || null}
              multiLineRender={[
                { label: "", key: "so_number" },
                {
                  label: "Remaining :",
                  key: "remaining_quantity",
                  key2: "unit",
                },
              ]}
              error={!!extraDataError?.sales_order}
              helperText={extraDataError?.sales_order}
              onChange={(e, newValue) => {
                handleExtraDataChange("sales_order", newValue);
              }}
              style={styles.inputExtraField}
              label="Sales Order"
            />
            <CustomAutocomplete
              required={true}
              id="custom_invoice"
              options={customInvoiceList || []}
              getOptionLabel={(option) => option?.invoice_number}
              value={extraData.custom_invoice || null}
              onChange={(e, newValue) => {
                handleExtraDataChange("custom_invoice", newValue);
              }}
              OptionLoader={optionLoader}
              error={!!extraDataError?.custom_invoice}
              helperText={extraDataError?.custom_invoice}
              style={styles.inputExtraField}
              label="Custom Invoice"
            />
            <CssTextField
              required={true}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                      {extraData.custom_invoice?.unit || ""}
                    </span>
                  </InputAdornment>
                ),
              }}
              style={styles.inputExtraField}
              id="outlined-basic"
              label={"Custom Invoice Quantity"}
              type="text"
              value={extraData?.custom_invoice_quantity || ""}
              error={!!extraDataError?.custom_invoice_quantity}
              helperText={extraDataError?.custom_invoice_quantity}
              onChange={(e) => {
                handleExtraDataChange(
                  "custom_invoice_quantity",
                  e.target.value
                );
              }}
              variant="outlined"
            />
            <CustomAutocomplete
              id="commercial_invoice"
              options={commercialInvoiceList || []}
              getOptionLabel={(option) => option?.invoice_number}
              value={extraData.commercial_invoice || null}
              onChange={(e, newValue) => {
                handleExtraDataChange("commercial_invoice", newValue);
              }}
              style={styles.inputExtraField}
              label="Commercial Invoice"
            />
            <CustomAutocomplete
              id="unloading_agent"
              options={props.masters?.UnloadingAgentList || []}
              getOptionLabel={(option) => option?.name}
              value={extraData.unloading_agent || null}
              onChange={(e, newValue) => {
                handleExtraDataChange("unloading_agent", newValue);
              }}
              style={styles.inputExtraField}
              label="Unloading Agent"
            />
            <CssTextField
              required={false}
              style={styles.inputExtraField}
              id="outlined-basic"
              label={"MR/Leo No."}
              type="text"
              value={extraData?.mr_or_leo_no || ""}
              onChange={(e) => {
                handleExtraDataChange("mr_or_leo_no", e.target.value);
              }}
              variant="outlined"
            />
          </div>
          <div
            style={{
              width: "30%",
              border: "1px solid gainsboro",
              borderRadius: 10,
              padding: "0px 5px 0px 10px",
              maxHeight: "300px",
            }}
          >
            <h4 style={{ margin: "6px 0 2px" }}>Brand Details</h4>
            <div
              className="d-flex d-flex-column justifySB"
              style={{ overflowY: "auto", height: "90%" }}
            >
              {inventoryBrandStock.map((d, i) => {
                let packagingData = d.packaging_data;
                return (
                  <div
                    style={{
                      border: "1px solid gainsboro",
                      margin: "10px 0",
                      borderRadius: 10,
                    }}
                  >
                    <div
                      className="d-flex justifySB"
                      style={{
                        fontFamily: "Poppins",
                        background: "#eee",
                        padding: "2px 8px",
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                      }}
                    >
                      <span style={{ width: "40%", fontSize: 13 }}>
                        {d.brand_name}
                      </span>
                      <span
                        style={{
                          color: primary,
                          fontWeight: 500,
                          width: "30%",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        Available
                      </span>
                      <span
                        style={{
                          width: "30%",
                          textAlign: "end",
                          fontSize: 12,
                        }}
                      >
                        Used
                      </span>
                    </div>
                    <Divider
                      variant="middle"
                      style={{ margin: "0", padding: "0" }}
                    />
                    {packagingData.map((p, index) => {
                      let data = p.packaging[0];
                      return (
                        <div
                          className="d-flex d-flex-column"
                          style={{ padding: "2px 8px" }}
                          key={i}
                        >
                          <div className="d-flex justifySB">
                            <span
                              style={{ width: "40%", fontSize: 12 }}
                            >{`${data.size} ${data.unit} ${data.bag_type}`}</span>
                            <span
                              style={{
                                color: primary,
                                fontWeight: 500,
                                width: "30%",
                                textAlign: "center",
                                fontSize: 12,
                              }}
                            >
                              {Number(p?.total_bags || 0)}
                            </span>
                            <span
                              style={{
                                width: "30%",
                                textAlign: "end",
                                fontSize: 12,
                              }}
                            >
                              {Number(p?.used_bags || 0)}
                            </span>
                          </div>
                          {index !== packagingData?.length - 1 && (
                            <Divider
                              variant="middle"
                              style={{ margin: "1px 0" }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ margin: "20px 0", height: 500 }}>
          <TableComponent
            {...props}
            module={"Mark To Shipping"}
            noAddButton={true}
            noSearchBar={true}
            simpleTable={true}
            padding={"0px"}
            tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
            headerShownAlways={true}
            tableRowHeight={30}
            loader={false}
            actions={[]}
            header={[
              "Packaging",
              "Total Bags",
              "Total Quantity",
              "Shipped Bags",
              "Shipped Quantity",
            ]}
            tableHeight="auto"
            tablePagination={false}
            subMenu={false}
            footer={false}
            // footerList={
            //   itemInputData?.length && {
            //     Packaging: "Total",
            //     "Total Bags":
            //       itemInputData?.reduce((acc, item) => {
            //         return Number(acc) + Number(item.bagsData?.remaining_bags);
            //       }, 0) +
            //       " " +
            //       (itemInputData[0]?.unit || ""),
            //   }
            // }
            tableList={
              itemData && itemData.length
                ? itemData.map((s, index) => {
                    const p = itemInputData[index].bagsData;
                    console.log("itemData", itemData, p, itemInputData);
                    s["Packaging"] = () => (
                      <div
                        className="d-flex alignC justifyC"
                        style={{ fontFamily: "Poppins", maxWidth: "100%" }}
                      >
                        <span className="d-flex d-flex-column" style={{ fontFamily: "Poppins" }}>
                          <span>{`${p?.size} ${p?.unit} ${p?.bag_type}`}</span>
                          {Boolean(extraData?.custom_invoice?.id) &&
                          <span style={{ fontSize: 10, color: "grey", fontWeight: 600 }}>
                            Custom Invoice Bags : {customInvoicePackaging.find((d) => d.id === p.id)
                              ?.customInvoiceBags || 0}
                          </span>
                          }
                        </span>
                        <span
                          style={{
                            color: primary,
                            margin: "0 10px",
                            height: 24,
                          }}
                        >
                          <EastIcon />
                        </span>
                        <CustomAutocomplete
                          options={
                            customInvoicePackaging?.length > 0
                              ? customInvoicePackaging?.filter(
                                  (d) =>
                                    d.sizeUnit !== `${p?.size} ${p?.unit} ${p?.bag_type}`
                                )
                              : []
                          }
                          optionKey="sizeUnit"
                          getOptionLabel={(option) =>
                            option?.size
                              ? `${option?.size} ${option?.unit} ${option?.bag_type}`
                              : ""
                          }
                          multiLineRender={[
                            { label: "", key: "sizeUnit" },
                            { label: "Custom Invoice Bags :", key: "customInvoiceBags" },
                          ]}
                          value={itemInputData[index].packaging_to || null}
                          size="small"
                          onChange={(e, newValue) => {
                            handleItemFieldChange(
                              "packaging_to",
                              newValue,
                              index
                            );
                          }}
                          style={{ minWidth: 190, width: "50%" }}
                          label=""
                        />
                      </div>
                    );
                    s["Total Bags"] = () => (
                      <div
                        className="d-flex alignC justifyC"
                        style={{ fontFamily: "Poppins", maxWidth: 340 }}
                      >
                        <span style={{ fontFamily: "Poppins" }}>
                          {`${itemInputData[index].total_bags}`} Bags
                        </span>
                        {Boolean(itemInputData[index].packaging_to?.id) && (
                          <>
                            <span
                              style={{
                                color: primary,
                                margin: "0 10px",
                                height: 24,
                              }}
                            >
                              <EastIcon />
                            </span>
                            <span style={{ fontFamily: "Poppins" }}>
                              {`${itemInputData[index].total_bags_to}`} Bags
                            </span>
                          </>
                        )}
                      </div>
                    );
                    s["Total Quantity"] = itemInputData[index].total_quantity;
                    s["Shipped Bags"] = () => (
                      <div className="d-flex alignC justifyC">
                        {Boolean(
                          itemInputData[index].packaging_to?.id &&
                            itemInputData[index].shipped_bags
                        ) && (
                          <>
                            <span style={{ fontFamily: "Poppins" }}>
                              {`${itemInputData[index].shipped_bags_old}`}
                            </span>
                            <span
                              style={{
                                color: primary,
                                margin: "0 10px",
                                height: 18,
                                transform: "scale(1.3)",
                                fontWeight: "bolder",
                              }}
                            >
                              ≈
                            </span>
                          </>
                        )}
                        <CssTextField85
                          required={
                            itemInputData.some(
                              (d) => Number(d.shipped_bags) > 0
                            )
                              ? false
                              : true
                          }
                          style={{ width: 120 }}
                          id="outlined-basic"
                          label={""}
                          type={"number"}
                          onFocus={StopScroll}
                          value={itemInputData[index].shipped_bags || ""}
                          error={itemInputData[index].shipped_bags_error}
                          helperText={itemInputData[index].shipped_bags_error}
                          onKeyDown={(evt) => {
                            ["e", "E", "-", "+", "."].includes(evt.key) &&
                              evt.preventDefault();
                          }}
                          onChange={(e) => {
                            handleItemFieldChange(
                              "shipped_bags",
                              e.target.value,
                              index
                            );
                          }}
                          variant="outlined"
                        />
                      </div>
                    );
                    s["Shipped Quantity"] = () => (
                      <>
                        <span>
                          {`${itemInputData[index].shipped_quantity} ${
                            itemInputData[index].unit || ""
                          }`}
                        </span>
                        <span
                          style={{
                            marginLeft: "10px",
                            color: primary,
                          }}
                        >
                          ({" "}
                          {convertedShippedQtyValue(
                            "shipped_quantity",
                            itemInputData[index].shipped_quantity,
                            itemInputData[index].unit
                          )}{" "}
                          {extraData.custom_invoice?.unit || ""} )
                        </span>
                      </>
                    );
                    return s;
                  })
                : []
            }
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default InventoryShippingDialog;
