import { TableCell } from "@material-ui/core";
import { Skeleton, Stack, TableRow } from "@mui/material";
import { mediumScreen } from "../Regex";

const styles = {
  subMenuListContainer: (height) => ({
    height: height || 70,
    padding: "16px",
    borderBottom: "1px solid rgb(241, 154, 48)",
  }),
  subMenuListInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "95%",
  },
  subMenuListSOInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "98%",
  },
  tableCell: {
    width: 100,
                                      whiteSpace: "nowrap",
                                      fontSize: mediumScreen()
                                        ? "0.7rem"
                                        : "0.8rem",
                                      backgroundColor: "#fbfcfe",
                                      fontFamily: "Poppins",
                                      padding:"8px 8px",
                                      
  }
};

export const TableUISkeleton = ({tileLength = 10, noOfCells = 5}) => {
  return (
  <>
      {[...Array(tileLength).keys()].map((components, index) => {
        return (
          <TableRow sx={{ height: 60 }}>
          {[...Array(noOfCells).keys()].map((com, i) => {
            <TableCell style={styles.tableCell}>
              <Stack spacing={0} style={{ alignItems: 'center' }}>
                <Skeleton animation="wave" variant="text" width={120} height={30} />
                <Skeleton animation="wave" variant="text" width={50} height={15} />
              </Stack>
            </TableCell>
          })}
        </TableRow>
        )
      })}
    </>
  )
}

export const SubMenuListSkeleton = ({ tileLength = 3 }) => {
  return (
    <div className="width-100 height-100">
      {[...Array(tileLength).keys()].map((components, index) => {
        return (
          <div key={index + "submenulist"} style={styles.subMenuListContainer(70)}>
            <span style={styles.subMenuListInnerContainer}>
              <Skeleton
                animation="wave"
                variant="text"
                width="80%"
                height={25}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={15}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};
export const SubMenuListSOSkeleton = ({ tileLength = 3 }) => {
  return (
    <div className="width-100 height-100">
      {[...Array(tileLength).keys()].map((components, index) => {
        return (
          <div key={index + "submenulist"} style={styles.subMenuListContainer(95)}>
            <span style={styles.subMenuListSOInnerContainer}>
              <Skeleton
                animation="wave"
                variant="text"
                width="80%"
                height={15}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="50%"
                height={25}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height={10}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="85%"
                height={15}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};
export const SubMenuListPOSkeleton = ({ tileLength = 3 }) => {
  return (
    <div className="width-100 height-100">
      {[...Array(tileLength).keys()].map((components, index) => {
        return (
          <div key={index + "submenulist"} style={styles.subMenuListContainer(100)}>
            <span style={styles.subMenuListSOInnerContainer}>
              <Skeleton
                animation="wave"
                variant="text"
                width="82%"
                height={22}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="35%"
                height={25}
                style={{
                  borderRadius: "10px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height={10}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="85%"
                height={15}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};
