import React, { Component } from "react";
import { green, primary } from "../styles/colors";
import { Download, InfoIcon, ThreeDots } from "../styles/Icons";
import { CardDetail, CssDialog, StyledCircularProgress } from "../styles/StyledComponents";
import { Backdrop, List, Paper, Popover } from "@material-ui/core";

// import ClickAwayListener from "@mui/base/ClickAwayListener";
import moment from "moment";
import TableComponent from "./TableComponent";
import InvoicePdfUi from "./InvoicePdfUi";
import { ClickAwayListener } from "@mui/base";
export class InvoiceCardUi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listArr: [],
      listArrCopy: [],
      loader: false,
      open: false,
      anchorEl: null,
      singleInvoiceSelected: false,
      openInvoice: false,
      selectedRange: [null, null],
      loadingDialog: false,
      downloadPdf: false,
      fetched: false,
      onlyShow: false,
    };
  }
  fetchAllData = () => {
    this.setState({ loader: true });
    this.props.fetchFunction("get", "masters/item-category/list", null, "ItemCateoryList").then((ItemCateoryList) => {
      if (ItemCateoryList) {
        this.setState({ ItemCateoryList }, () => {
          this.props.fetchFunction("get", "masters/item-type/list", null, "ItemList").then((ItemList) => {
            if (ItemList) {
              this.setState({ ItemList }, () => {
                this.props.fetchFunction("get", "masters/brand/list", null, "BrandList").then((BrandList) => {
                  if (BrandList) {
                    this.setState({
                      loader: false,
                      BrandList: BrandList.map((sBrand) => {
                        if (this.state.ItemList.length > 0 && this.state.ItemCateoryList.length > 0) {
                          sBrand.item_category_name = this.state.ItemCateoryList.filter(
                            (singleItem) => singleItem.id === sBrand.item_category
                          )
                            ? this.state.ItemCateoryList.filter(
                                (singleItem) => singleItem.id === sBrand.item_category
                              )[0].name
                            : "";
                          sBrand.item_type_name = this.state.ItemList.filter(
                            (singleItem) => singleItem.id === sBrand.item_type
                          )
                            ? this.state.ItemList.filter((singleItem) => singleItem.id === sBrand.item_type)[0]
                                .broken_percentage
                            : "";

                          return sBrand;
                        } else {
                          return sBrand;
                        }
                      }),
                    });
                  } else {
                    this.setState({ loader: false });
                  }
                });
              });
            } else {
              this.setState({ loader: false });
            }
          });
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };
  fetchDispatches = (setPdfDownload) => {
    const { singleInvoiceSelected } = this.state;
    if (singleInvoiceSelected && singleInvoiceSelected.id) {
      this.props
        .fetchFunction("get", "dispatch/list", null, "dispatchList", null, {
          invoice_id: singleInvoiceSelected.id,
        })
        .then((dispatchList) => {
          const newArrId = [];
          const newArr = [];
          if (dispatchList && dispatchList.length > 0) {
            dispatchList.map((sR) => {
              if (sR.id) {
                this.props
                  .fetchFunction("get", "dispatch/dispatch-items/list", null, "dispatch-item", null, {
                    dispatch_id: sR.id,
                  })
                  .then((res2) => {
                    if (res2) {
                      sR.subList = res2;
                      if (!newArrId.includes(sR.id)) {
                        newArr.push(sR);
                        newArrId.push(sR.id);
                      }

                      if (newArr.length === dispatchList.length) {
                        this.setState(
                          {
                            openInvoice: newArr ? newArr : [],
                            loadingDialog: false,
                          },
                          () => {
                            if (this.props.setLoader) {
                              this.props.setLoader();
                            }
                            if (setPdfDownload) {
                              this.setState({
                                downloadPdf: true,
                              });
                            }
                          }
                        );

                        return sR;
                      }
                    } else {
                      if (!newArrId.includes(sR.id)) {
                        newArr.push(sR);
                        newArrId.push(sR.id);
                      }
                      if (newArr.length === dispatchList.length) {
                        this.setState(
                          {
                            openInvoice: dispatchList,
                            loadingDialog: false,
                          },
                          () => {
                            if (this.props.setLoader) {
                              this.props.setLoader();
                            }
                            if (setPdfDownload) {
                              this.setState({
                                downloadPdf: true,
                              });
                            }
                          }
                        );
                      }
                    }
                  });
              }
            });
          } else {
            this.setState(
              {
                openInvoice: [],
                loadingDialog: false,
              },
              () => {
                if (this.props.setLoader) {
                  this.props.setLoader();
                }
                if (setPdfDownload) {
                  this.setState({
                    downloadPdf: true,
                  });
                }
              }
            );
          }
        });
    }
  };
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  setInitialValues = (props) => {
    const { listArr, loader, listArrCopy, onlyDownload, onlyShow } = props;
    console.log(listArr, "listArr");
    if (listArr.length > 0) {
      listArr.sort((a, b) => {
        var dateA = moment(b.invoice_date).format("MMM D YYYY h:mm:ss A");
        var dateB = moment(a.invoice_date).format("MMM D YYYY h:mm:ss A");
        return new Date(dateA) - new Date(dateB);
      });
    }
    if (listArrCopy.length > 0) {
      listArrCopy.sort((a, b) => {
        var dateA = moment(b.invoice_date).format("MMM D YYYY h:mm:ss A");
        var dateB = moment(a.invoice_date).format("MMM D YYYY h:mm:ss A");
        return new Date(dateA) - new Date(dateB);
      });
    }
    if (onlyShow === false && this.state.onlyShow !== false) {
      this.setState({ downloadPdf: true });
    }
    this.setState(
      {
        listArr,
        listArrCopy,
        loader,
        onlyShow: onlyShow ? onlyShow : false,
      },
      () => {
        if (onlyDownload && this.state.fetched === false) {
          this.setState(
            {
              singleInvoiceSelected: onlyDownload,
              openInvoice: true,
              loadingDialog: true,
              fetched: true,
            },
            () => {
              this.fetchDispatches("setPdfDownload");
            }
          );
        } else {
        }
      }
    );
  };
  handleTouchTap = (event, id) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      singleInvoiceSelected: id,
    });
  };
  render() {
    const { listArr, listArrCopy, loader, openInvoice, singleInvoiceSelected, downloadPdf, loadingDialog } = this.state;
    const { onlyDownload } = this.props;

    return (
      <div className={`width-100  d-flex d-flex-column height-100`}>
        {onlyDownload
          ? ""
          : loadingDialog === true && (
              <Backdrop style={{ zIndex: 1, color: "#fff", position: "absolute" }} open={loadingDialog}>
                <StyledCircularProgress color="inherit" />
              </Backdrop>
            )}

        {downloadPdf === true && loadingDialog === false && (
          <InvoicePdfUi
            tableList={
              openInvoice && openInvoice.length
                ? openInvoice
                    .sort((a, b) => {
                      var dateA = moment(b.createdAt).format("MMM D YYYY h:mm:ss A");
                      var dateB = moment(a.createdAt).format("MMM D YYYY h:mm:ss A");
                      return new Date(dateA) - new Date(dateB);
                    })
                    .map((singleDispatch) => {
                      if (this.props.setData) {
                        return this.props.setData(singleDispatch);
                      } else {
                        return singleDispatch;
                      }
                    })
                : []
            }
            {...this.state}
            {...this.props}
            subMenuheader={["Sr.No.", "Brand", "Item Category", "Item Type", "Packaging"]}
            onClose={() => {
              if (onlyDownload && this.props.onDownload) {
                this.props.onDownload();
              }
              this.setState(
                {
                  downloadPdf: false,
                  singleInvoiceSelected: false,
                  openInvoice: false,
                  open: false,
                  anchorEl: null,
                },
                () => {}
              );
            }}
          />
        )}

        {onlyDownload
          ? ""
          : openInvoice &&
            !downloadPdf && (
              <CssDialog
                noReset={true}
                header={`Invoice No. ${singleInvoiceSelected && singleInvoiceSelected.invoice_number} Total ${
                  openInvoice && openInvoice.length > 1 ? "Dispatches" : "Dispatch"
                } ( ${openInvoice && openInvoice.length})`}
                onClose={() => {
                  this.setState({ openInvoice: false });
                }}
                snackbarClose={() => {
                  this.setState({ openInvoice: false });
                }}
                children={() => {
                  return (
                    <div className="d-flex d-flex-column justifyC alignC width-100 height-100 fontSize1 ">
                      {singleInvoiceSelected && singleInvoiceSelected.amount && module === "broker" ? (
                        <Paper
                          className=" backgroundWhite "
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 15px",
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "150px",
                              }}
                            >
                              Brokerage Amount :{" "}
                            </p>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                              }}
                            >
                              {singleInvoiceSelected.amount}
                            </p>
                          </div>
                        </Paper>
                      ) : (
                        ""
                      )}
                      <TableComponent
                        actions={[]}
                        subMenu={true}
                        noAddButton={true}
                        module={"Dispatch"}
                        filterChild={false}
                        {...this.state}
                        header={
                          this.props.header
                            ? this.props.header
                            : [
                                "Sr.No.",
                                "PO#",
                                "Dispatch date",
                                "From",
                                "To",
                                "Bags qty",
                                "Gross wt",
                                "Dispatch Quantity",
                                "Status",
                              ]
                        }
                        subMenuheader={["Sr.No.", "Brand", "Item Category", "Item Type", "Packaging"]}
                        {...this.props}
                        tableList={
                          openInvoice && openInvoice.length
                            ? openInvoice
                                .sort((a, b) => {
                                  var dateA = moment(b.createdAt).format("MMM D YYYY h:mm:ss A");
                                  var dateB = moment(a.createdAt).format("MMM D YYYY h:mm:ss A");
                                  return new Date(dateA) - new Date(dateB);
                                })
                                .map((singleDispatch) => {
                                  if (this.props.setData) {
                                    return this.props.setData(singleDispatch);
                                  } else {
                                    return singleDispatch;
                                  }
                                })
                            : []
                        }
                      />
                    </div>
                  );
                }}
                loadingDialog={loadingDialog}
                noActions={true}
              />
            )}
        {onlyDownload ? (
          ""
        ) : loader === true ? (
          <StyledCircularProgress />
        ) : listArrCopy && listArrCopy.length > 0 ? (
          <>
            <div className="width-100 d-flex justifyFE marginT10B20">{this.props.filterChild()}</div>
            <div
              className={`width-100 d-flex flexWrap ${
                loader === true || (listArrCopy && listArrCopy.length === 0)
                  ? "height-100"
                  : listArr.length > 0
                  ? "heightFC"
                  : "height-6799"
              }`}
            >
              {listArr.length > 0 ? (
                listArr
                  .sort((a, b) => {
                    var dateA = moment(b.invoice_date).format("MMM D YYYY h:mm:ss A");
                    var dateB = moment(a.invoice_date).format("MMM D YYYY h:mm:ss A");
                    return new Date(dateA) - new Date(dateB);
                  })
                  .map((singleList, i) => (
                    <CardDetail
                      keyId={i}
                      height="165px"
                      width="165px"
                      margin="10px"
                      children={() => {
                        return (
                          <div
                            className="width-100 height-100 d-flex d-flex-column justifySA"
                            onClick={(e) =>
                              this.setState(
                                {
                                  singleInvoiceSelected: singleList,
                                  openInvoice: true,
                                  loadingDialog: true,
                                },
                                () => {
                                  this.fetchDispatches();
                                }
                              )
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <Popover
                              open={this.state.open}
                              onClose={() => {
                                this.setState({
                                  open: false,
                                  anchorEl: null,
                                  // singleInvoiceSelected: false,
                                });
                              }}
                              anchorEl={this.state.anchorEl}
                              style={{ marginTop: "-2.5%", minWidth: "200px" }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={() =>
                                  this.setState({
                                    open: false,
                                    anchorEl: null,
                                    // singleInvoiceSelected: false,
                                  })
                                }
                              >
                                <List style={{ padding: "10px", color: primary }}>
                                  <div
                                    className="d-flex alignC fontSize09 justifySB"
                                    style={{
                                      height: "24.5px",
                                      padding: "4.705px 0",
                                      width: "70px",
                                      minWidth: "fit-content",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState(
                                        {
                                          loadingDialog: true,
                                          downloadPdf: true,
                                          // singleInvoiceSelected: singleList,
                                        },
                                        () => {
                                          this.fetchDispatches();
                                        }
                                      );
                                    }}
                                  >
                                    PDF
                                    <div style={{ margin: "0 2px 0 0" }}></div>
                                    <Download color={primary} height="15" width="15" />
                                  </div>
                                  {/* <div
                                    className="d-flex alignC fontSize09 justifySB"
                                    style={{
                                      height: "24.5px",
                                      padding: "4.705px 0",
                                      width: "70px",
                                      minWidth: "fit-content",
                                    }}
                                    onClick={(e) => {}}
                                  >
                                    Excel
                                    <div style={{ margin: "0 2px 0 0" }}></div>
                                    <Download color={primary} height="15" width="15" />
                                  </div> */}
                                </List>
                              </ClickAwayListener>
                            </Popover>
                            <div className="d-flex alignC justifyFE fontSize09 width-100">
                              <div style={{ width: "calc(100% - 39px)" }}>Invoice</div>
                              <div
                                onClick={(e) =>
                                  this.setState(
                                    {
                                      singleInvoiceSelected: singleList,
                                      openInvoice: true,
                                      loadingDialog: true,
                                    },
                                    () => {
                                      this.fetchDispatches();
                                    }
                                  )
                                }
                                className="d-flex alignC justifyFE fontSize09 width-100"
                                style={{
                                  width: "25px",
                                  minWidth: "25px",
                                  color: green,
                                }}
                              >
                                {/* <InfoIcon color={green} height={"20"} width="20" /> */}
                              </div>{" "}
                              <div
                                className="d-flex alignC justifyFE fontSize09"
                                style={{
                                  height: "24.5px",
                                  padding: "4.705px 0",
                                  width: "25px",
                                  minWidth: "25px",
                                  marginRight: "-11px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleTouchTap(e, singleList);
                                }}
                              >
                                {" "}
                                <ThreeDots height="20" />
                              </div>
                            </div>
                            <div className="width-100 textColorGrey">No.</div>
                            <div className="width-100">{singleList ? singleList.invoice_number : ""}</div>
                            <div className="width-100 textColorGrey" style={{ margin: "4.705px 0px 0 0" }}>
                              Date.
                            </div>
                            <div className="width-100">
                              {singleList ? moment(singleList.invoice_date).format("ddd, MMM Do YYYY") : ""}
                            </div>
                            <div className="width-100 textColorGrey" style={{ margin: "4.705px 0px 0 0" }}>
                              Dispatch Count.
                            </div>
                            <div className="width-100" style={{ minHeight: "19.81px" }}>
                              {singleList
                                ? singleList.dispatch_count
                                  ? singleList.dispatch_count > 9
                                    ? singleList.dispatch_count
                                    : `0${singleList.dispatch_count}`
                                  : ""
                                : ""}
                            </div>{" "}
                          </div>
                        );
                      }}
                    />
                  ))
              ) : (
                <div className="width-100 height-100 d-flex justifyC alignC">No invoice yet!</div>
              )}
            </div>
          </>
        ) : (
          <div className="width-100 height-100 d-flex justifyC alignC">No list!</div>
        )}
      </div>
    );
  }
}

export default InvoiceCardUi;
