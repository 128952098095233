import React, { useEffect } from "react";
import { useState } from "react";
import CreateDialog from "../../layoutComponents/CreateDialog";
import {
  calculateExchangeValue,
  calculateGstAmount,
  calculatePercentage,
  calculatePrice,
  filterData,
  StopScroll,
} from "../../Regex";
import {
  CssTextField85,
  CssTextField95,
  HeadingLine,
  ShowDetails,
  StyledButton,
  StyledLoadingButton,
} from "../../styles/StyledComponents";
import { sum, subtract, multiply, divide } from "lodash";
import { primary, red } from "../../styles/colors";
import { lightGrey, white } from "../../styles/colors";
import { Divider } from "@material-ui/core";
function ItemTableWIthINpitDetails(props) {
  const {
    postData,
    selected,
    selectedSo,
    masters,
    errorMsg,
    setErrorMsg,
    setData,
    editData,
    editLeftData,
    ci_type,
    onlyView,
    alldisabled,
  } = props;

  const [editDetail, seteditDetail] = useState({});
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);

  const [dialogOpen, setDialogOpen] = useState({ open: false, details: false });
  const unit = selectedSo && selectedSo.unit ? selectedSo.unit : "";
  const [submittedData, setSubmittedData] = useState({});
  const [submittedDutyData, setSubmittedDutyData] = useState({});
  useEffect(() => {
    setSubmittedData(editData);
    seteditDetail(editData);
  }, [editData]);
  useEffect(() => {
    console.log(
      "EDITLEFTDATA",
      editLeftData,
      editLeftData?.total_fob_pmt_in_other ||
        editLeftData["Total Fob Value OTHER PMT"] ||
        ""
    );
    let OBJ = {
      ["Total Fob Value OTHER PMT"]:
        editLeftData?.total_fob_pmt_in_other ||
        editLeftData["Total Fob Value OTHER PMT"] ||
        "",
      ["Total Fob Value OTHER TOTAL"]:
        editLeftData?.total_fob_value_in_other ||
        editLeftData["Total Fob Value OTHER TOTAL"] ||
        "",
      ["Total Fob Value INR TOTAL"]:
        editLeftData?.total_fob_value_in_inr ||
        editLeftData["Total Fob Value INR TOTAL"] ||
        "",
      ["Duty Percent Of FOB"]:
        editLeftData?.duty_percentage_of_fob ||
        editLeftData["Duty Percent Of FOB"] ||
        "10",
      ["Duty Percent OTHER PMT"]:
        editLeftData?.duty_of_fob_pmt_in_other ||
        editLeftData["Duty Percent OTHER PMT"] ||
        "",
      ["Duty Percent OTHER TOTAL"]:
        editLeftData?.duty_of_fob_value_in_other ||
        editLeftData["Duty Percent OTHER TOTAL"] ||
        "",
      ["Duty Percent INR TOTAL"]:
        editLeftData?.duty_of_fob_value_in_inr ||
        editLeftData["Duty Percent INR TOTAL"] ||
        "",
      ["Total Export Duty OTHER PMT"]:
        editLeftData?.payable_export_duty_pmt_in_other ||
        editLeftData["Total Export Duty OTHER PMT"] ||
        "",
      ["Total Export Duty OTHER TOTAL"]:
        editLeftData?.payable_export_duty_value_in_other ||
        editLeftData["Total Export Duty OTHER TOTAL"] ||
        "",
      ["Total Export Duty INR TOTAL"]:
        editLeftData?.payable_export_duty_value_in_inr ||
        editLeftData["Total Export Duty INR TOTAL"] ||
        "",
      ["Duty paid OTHER PMT"]:
        editLeftData?.duty_paid_under_protest_pmt_in_other ||
        editLeftData["Duty paid OTHER PMT"] ||
        "",
      ["Duty paid OTHER TOTAL"]:
        editLeftData?.duty_paid_under_protest_value_in_other ||
        editLeftData["Duty paid OTHER TOTAL"] ||
        "",
      ["Duty paid INR TOTAL"]:
        editLeftData?.duty_paid_under_protest_value_in_inr ||
        editLeftData["Duty paid INR TOTAL"] ||
        "",
    };
    console.log(OBJ);
    setSubmittedDutyData(OBJ);
  }, [editLeftData]);
  const [inputArr, setInputArr] = useState([
    {
      disabled: true,
      name: "Net Weight",
      type: "textField",
      dataType: "number",
      api: "net_weight",
      defaultValue: {
        value:
          editDetail && editDetail[`Net Weight`]
            ? editDetail[`Net Weight`]
            : "",
      },
      endAdornment: unit,
    },
    {
      name: "Gross Weight",
      type: "textField",
      dataType: "number",
      endAdornment: unit,
      defaultValue: {
        value:
          editDetail && editDetail["Gross Weight"]
            ? editDetail["Gross Weight"]
            : "",
      },
      api: "gross_weight",
      disabled: alldisabled,
      onChange: async (sub) => {
        setSubmittedData((prev) => {
          return {
            ...prev,
            "Gross Weight": sub["Gross Weight"],
          };
        });
        return sub;
      },
    },
  ]);
  const inputArrCopy = [
    {
      disabled: true,
      name: "Net Weight",
      type: "textField",
      dataType: "number",
      api: "Net Weight",
      defaultValue: {
        value:
          editDetail && editDetail[`Net Weight`]
            ? editDetail[`Net Weight`]
            : "",
      },
      endAdornment: unit,
    },
    {
      name: "Gross Weight",
      type: "textField",
      dataType: "number",
      endAdornment: unit,
      defaultValue: {
        value:
          editDetail && editDetail["Gross Weight"]
            ? editDetail["Gross Weight"]
            : "",
      },
      api: "Gross Weight",
      disabled: alldisabled,
      onChange: async (sub) => {
        setSubmittedData((prev) => {
          return {
            ...prev,
            "Gross Weight": sub["Gross Weight"],
          };
        });
        return sub;
      },
    },
  ];
  const changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  const changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, multiply(weigths, 100));
      }
    }
  };
  const calculateNetWeight = (submiited) => {
    console.log(submiited, selected.packaging)
    const allWeights =
      selected.packaging &&
      selected.packaging.length > 0 &&
      selected.packaging.map((sP) => {
        const pVal = filterData(
          masters.PackagingList,
          "id",
          Number(sP),
          "onlyOne"
        )
          ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
          : "";
          console.log(pVal)
        return changeUnit(
          pVal.unit,
          unit,
          multiply(
            Number(
              submiited[`${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`]
                ? submiited[
                    `${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                  ]
                : 0
            ),
            Number(pVal.size)
          ).toFixed(2)
        );
      });

      console.log("allWeight", allWeights)

    return allWeights.reduce(
      (previousValue, currentValue) =>
        Number(previousValue) + Number(currentValue ? currentValue : 0),
      0
    );
  };
  const rowHeader = [
    "Exchange rate",
    `Price (${
      selected && selected["Currency"] ? `${selected["Currency"]}` : ""
    }) PER ${unit}`,
    `Amount in ${
      selected && selected["Currency"] ? `${selected["Currency"]}` : ""
    }`,
    `PRICE (INR) PER ${unit}`,
    `Amount in (INR)`,
  ];
  const rowLeftHeader = [
    { field: "CALCULATION OF EXCESS DUTY PAID UNDER PROTEST", key: "" },
    {
      field: `${
        selected && selected["Currency"]
          ? `${
              selected["Currency"].includes("Dollar")
                ? "USD"
                : selected["Currency"]
            }`
          : ""
      } PMT`,
      key: "OTHER PMT",
    },
    {
      field: `${
        selected && selected["Currency"]
          ? `${
              selected["Currency"].includes("Dollar")
                ? "USD"
                : selected["Currency"]
            }`
          : ""
      } TOTAL`,
      key: "OTHER TOTAL",
    },
    { field: "INR TOTAL", key: "INR TOTAL" },
  ];

  const handleDutyValues = (value, field, data) => {
    console.log(field + " : " + value, submittedDutyData[field], submittedData);

    let fieldValue = data
      ? data
      : // : value.match(/^\d*\.?\d{0,2}$/)
      value
      ? value
      : value;
    // : submittedDutyData[field];
    let newObj = { ...submittedDutyData };

    if (field === "Duty Percent Of FOB") {
      newObj[field] = fieldValue;
      newObj[`Duty Percent ${rowLeftHeader[1].key}`] = Number(
        multiply(
          Number(divide(newObj[`Duty Percent Of FOB`], 100)),
          Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
        )
      ).toFixed(2);
      newObj[`Duty Percent ${rowLeftHeader[2].key}`] = Number(
        multiply(
          Number(Number(divide(newObj[`Duty Percent Of FOB`], 100))),
          Number(
            multiply(
              Number(submittedData["Net Weight"]),
              Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
            )
          )
        )
      ).toFixed(2);
      newObj[`Duty Percent ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(
            multiply(
              Number(Number(divide(newObj[`Duty Percent Of FOB`], 100))),
              Number(
                multiply(
                  Number(submittedData["Net Weight"]),
                  Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
                )
              )
            )
          )
        )
      ).toFixed(2);

      newObj[`Total Export Duty ${rowLeftHeader[1].key}`] = Number(
        multiply(
          divide(
            Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`]),
            Number(1.1)
          ),
          Number(Number(divide(newObj[`Duty Percent Of FOB`], 100)))
        )
      ).toFixed(2);
      newObj[`Total Export Duty ${rowLeftHeader[2].key}`] = Number(
        multiply(
          divide(
            Number(
              multiply(
                Number(submittedData["Net Weight"]),
                Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
              )
            ),
            Number(1.1)
          ),
          Number(Number(divide(newObj[`Duty Percent Of FOB`], 100)))
        )
      ).toFixed(2);
      newObj[`Total Export Duty ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(
            multiply(
              divide(
                Number(
                  multiply(
                    Number(submittedData["Net Weight"]),
                    Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
                  )
                ),
                Number(1.1)
              ),
              Number(Number(divide(newObj[`Duty Percent Of FOB`], 100)))
            )
          )
        )
      ).toFixed(2);

      newObj[`Duty paid ${rowLeftHeader[1].key}`] = Number(
        subtract(
          Number(
            multiply(
              Number(Number(divide(newObj[`Duty Percent Of FOB`], 100))),
              Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
            )
          ),
          Number(
            multiply(
              divide(
                Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`]),
                Number(1.1)
              ),
              Number(Number(divide(newObj[`Duty Percent Of FOB`], 100)))
            )
          )
        )
      ).toFixed(2);
      newObj[`Duty paid ${rowLeftHeader[2].key}`] = Number(
        subtract(
          Number(newObj[`Duty Percent ${rowLeftHeader[2].key}`]),
          Number(newObj[`Total Export Duty ${rowLeftHeader[2].key}`])
        )
      ).toFixed(2);
      newObj[`Duty paid ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(newObj[`Duty paid ${rowLeftHeader[2].key}`])
        )
      ).toFixed(2);
    } else if (field.includes("Total Fob Value")) {
      newObj[field] = fieldValue;
      newObj[`Total Fob Value ${rowLeftHeader[1].key}`] = Number(
        divide(Number(newObj[field]), Number(submittedData["Net Weight"]))
      ).toFixed(2);
      // newObj[`Total Fob Value ${rowLeftHeader[2].key}`] = Number(
      //   multiply(Number(submittedData["Net Weight"]), Number(newObj[field]))
      // ).toFixed(2);
      newObj[`Total Fob Value ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(
            multiply(Number(submittedData["Net Weight"]), Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`]))
          )
        )
      ).toFixed(2);

      newObj[`Duty Percent ${rowLeftHeader[1].key}`] = Number(
        multiply(
          Number(divide(newObj[`Duty Percent Of FOB`], 100)),
          Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
        )
      ).toFixed(2);
      newObj[`Duty Percent ${rowLeftHeader[2].key}`] = Number(
        multiply(
          Number(divide(newObj[`Duty Percent Of FOB`], 100)),
          Number(
            multiply(Number(submittedData["Net Weight"]), Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`]))
          )
        )
      ).toFixed(2);
      newObj[`Duty Percent ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(
            multiply(
              Number(divide(newObj[`Duty Percent Of FOB`], 100)),
              Number(
                multiply(
                  Number(submittedData["Net Weight"]),
                  Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
                )
              )
            )
          )
        )
      ).toFixed(2);

      newObj[`Total Export Duty ${rowLeftHeader[1].key}`] = Number(
        multiply(
          divide(Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`]), Number(1.1)),
          Number(divide(newObj[`Duty Percent Of FOB`], 100))
        )
      ).toFixed(2);
      newObj[`Total Export Duty ${rowLeftHeader[2].key}`] = Number(
        multiply(
          divide(
            Number(
              multiply(
                Number(submittedData["Net Weight"]),
                Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
              )
            ),
            Number(1.1)
          ),
          Number(divide(newObj[`Duty Percent Of FOB`], 100))
        )
      ).toFixed(2);
      newObj[`Total Export Duty ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(
            multiply(
              divide(
                Number(
                  multiply(
                    Number(submittedData["Net Weight"]),
                    Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
                  )
                ),
                Number(1.1)
              ),
              Number(divide(newObj[`Duty Percent Of FOB`], 100))
            )
          )
        )
      ).toFixed(2);

      newObj[`Duty paid ${rowLeftHeader[1].key}`] = Number(
        subtract(
          Number(
            multiply(
              Number(divide(newObj[`Duty Percent Of FOB`], 100)),
              Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`])
            )
          ),
          Number(
            multiply(divide(Number(newObj[`Total Fob Value ${rowLeftHeader[1].key}`]), Number(1.1)), Number(divide(newObj[`Duty Percent Of FOB`], 100)))
          )
        )
      ).toFixed(2);
      newObj[`Duty paid ${rowLeftHeader[2].key}`] = Number(
        subtract(
          Number(newObj[`Duty Percent ${rowLeftHeader[2].key}`]),
          Number(newObj[`Total Export Duty ${rowLeftHeader[2].key}`])
        )
      ).toFixed(2);
      newObj[`Duty paid ${rowLeftHeader[3].key}`] = Number(
        multiply(
          Number(submittedData["exchange_rate"]),
          Number(newObj[`Duty paid ${rowLeftHeader[2].key}`])
        )
      ).toFixed(2);
    } else if (field.includes("Duty Percent")) {
      newObj[field] = fieldValue;
      newObj[`Duty paid ${rowLeftHeader[1].key}`] = Number(
        subtract(
          Number(newObj[field]),
          Number(newObj[`Total Export Duty ${rowLeftHeader[1].key}`])
        )
      ).toFixed(2);
    } else if (field.includes("Total Export Duty")) {
      newObj[field] = fieldValue;
      newObj[`Duty paid ${rowLeftHeader[1].key}`] = Number(
        subtract(
          Number(newObj[`Duty Percent ${rowLeftHeader[1].key}`]),
          Number(newObj[`Total Export Duty ${rowLeftHeader[1].key}`])
        )
      ).toFixed(2);
    } else if (field.includes("Duty paid")) {
      newObj[field] = fieldValue;
    }

    setSubmittedDutyData(newObj);
  };

  const handleRightDutyValuesTable = (e, field, i) => {
    const { value } = e.target;
    console.log(field + " : " + value, submittedData[field]);

    let fieldValue = value.match(/^\d*\.?\d{0,2}$/)
      ? value
      : submittedData[field];
    let newObj = { ...submittedData };

    if (field.includes("Fob Value")) {
      // FOB
      newObj[field] = fieldValue;

      newObj[`Fob Value ${rowHeader[3]}`] = Number(
        i == 0
          ? calculatePrice(
              calculateExchangeValue(
                Number(submittedData["exchange_rate"] || 0),
                Number(newObj[field] || 0)
              ),
              Number(submittedData["Net Weight"] || 0)
            )
          : newObj[`Fob Value ${rowHeader[3]}`]
      ).toFixed(2);

      newObj[`Fob Value ${rowHeader[4]}`] = Number(
        i === 0
          ? calculateExchangeValue(
              Number(submittedData["exchange_rate"] || 0),
              Number(newObj[field] || 0)
            )
          : newObj[`Fob Value ${rowHeader[4]}`]
      ).toFixed(2);

      // Total
      newObj[`Total ${rowHeader[2]}`] = Number(
        sum([
          Number(newObj[field]),
          Number(newObj[`Ocean Freight ${rowHeader[2]}`] || 0),
          Number(newObj[`Insurance ${rowHeader[2]}`] || 0),
        ])
      ).toFixed(2);

      newObj[`Total ${rowHeader[3]}`] = Number(
        calculatePrice(
          i === 0
            ? sum([
                calculateExchangeValue(
                  Number(submittedData["exchange_rate"] || 0),
                  Number(newObj[field] || 0)
                ),
                Number(newObj[`Ocean Freight ${rowHeader[4]}`] || 0),
                Number(newObj[`Insurance ${rowHeader[4]}`] || 0),
              ])
            : newObj[`Total ${rowHeader[4]}`],
          Number(submittedData["Net Weight"] || 0)
        ).toFixed(2)
      ).toFixed(2);

      newObj[`Total ${rowHeader[4]}`] = Number(
        i === 0
          ? sum([
              calculateExchangeValue(
                Number(submittedData["exchange_rate"] || 0),
                Number(newObj[field] || 0)
              ),
              Number(newObj[`Ocean Freight ${rowHeader[4]}`] || 0),
              Number(newObj[`Insurance ${rowHeader[4]}`] || 0),
            ])
          : newObj[`Total ${rowHeader[4]}`]
      ).toFixed(2);

      // IGST
      newObj[`IGST Amount ${rowHeader[4]}`] = Number(
        calculatePercentage(
          i === 0
            ? Number(newObj[`Total ${rowHeader[4]}`])
            : Number(newObj[`Total ${rowHeader[4]}`]),
          Number(submittedData.rate_of_igst || 0)
        ).toFixed(2)
      ).toFixed(2);

      // FINAL
      newObj[`Final ${rowHeader[2]}`] = Number(
        i === 0
          ? Number(
              // calculateGstAmount(
              sum([
                Number(newObj[field] || 0),
                Number(newObj[`Ocean Freight ${rowHeader[2]}`] || 0),
                Number(newObj[`Insurance ${rowHeader[2]}`] || 0),
              ])
              // Number(submittedData.rate_of_igst || 0),
              // Number(newObj[field] || 0)
              // )
            ).toFixed(2)
          : 0
      ).toFixed(2);

      newObj[`Final ${rowHeader[4]}`] = Number(
        i === 0
          ? Number(
              // calculateGstAmount(
              sum([
                calculateExchangeValue(
                  Number(submittedData["exchange_rate"] || 0),
                  Number(newObj[field] || 0)
                ),
                Number(newObj[`Ocean Freight ${rowHeader[4]}`] || 0),
                Number(newObj[`Insurance ${rowHeader[4]}`] || 0),
              ])
              // Number(submittedData.rate_of_igst || 0),
              // Number(newObj[field] || 0)
              // )
            ).toFixed(2)
          : newObj[`Total ${rowHeader[4]}`]
      ).toFixed(2);
    } else if (field.includes("Ocean Freight")) {
      // Ocean Freight
      newObj[field] = fieldValue;

      newObj[`Ocean Freight ${rowHeader[3]}`] = Number(
        i == 0
          ? calculatePrice(
              calculateExchangeValue(
                Number(submittedData["exchange_rate"] || 0),
                Number(newObj[field] || 0)
              ),
              Number(submittedData["Net Weight"] || 0)
            )
          : newObj[`Ocean Freight ${rowHeader[3]}`]
      ).toFixed(2);

      newObj[`Ocean Freight ${rowHeader[4]}`] = Number(
        i == 0
          ? calculateExchangeValue(
              Number(submittedData["exchange_rate"] || 0),
              Number(newObj[field] || 0)
            )
          : newObj[`Ocean Freight ${rowHeader[4]}`]
      ).toFixed(2);

      // Total
      newObj[`Total ${rowHeader[2]}`] = Number(
        sum([
          Number(newObj[field]),
          Number(newObj[`Fob Value ${rowHeader[2]}`] || 0),
          Number(newObj[`Insurance ${rowHeader[2]}`] || 0),
        ])
      ).toFixed(2);

      newObj[`Total ${rowHeader[3]}`] = Number(
        calculatePrice(
          i === 0
            ? sum([
                calculateExchangeValue(
                  Number(submittedData["exchange_rate"] || 0),
                  Number(newObj[field] || 0)
                ),
                Number(newObj[`Fob Value ${rowHeader[4]}`] || 0),
                Number(newObj[`Insurance ${rowHeader[4]}`] || 0),
              ])
            : newObj[`Total ${rowHeader[4]}`],
          Number(submittedData["Net Weight"] || 0)
        ).toFixed(2)
      ).toFixed(2);

      newObj[`Total ${rowHeader[4]}`] = Number(
        i === 0
          ? sum([
              calculateExchangeValue(
                Number(submittedData["exchange_rate"] || 0),
                Number(newObj[field] || 0)
              ),
              Number(newObj[`Fob Value ${rowHeader[4]}`] || 0),
              Number(newObj[`Insurance ${rowHeader[4]}`] || 0),
            ])
          : newObj[`Total ${rowHeader[4]}`]
      ).toFixed(2);

      // IGST
      newObj[`IGST Amount ${rowHeader[4]}`] = Number(
        calculatePercentage(
          i === 0
          ? Number(newObj[`Total ${rowHeader[4]}`])
          : Number(newObj[`Total ${rowHeader[4]}`]),
          Number(submittedData.rate_of_igst || 0)
        ).toFixed(2)
      ).toFixed(2);

      // FINAL
      newObj[`Final ${rowHeader[2]}`] = Number(
        i === 0 || i === 2
          ? Number(
              // calculateGstAmount(
              sum([
                Number(newObj[field] || 0),
                Number(newObj[`Fob Value ${rowHeader[2]}`] || 0),
                Number(newObj[`Insurance ${rowHeader[2]}`] || 0),
              ])
              // Number(submittedData.rate_of_igst || 0),
              // Number(newObj[`Fob Value ${rowHeader[2]}`] || 0)
              // )
            ).toFixed(2)
          : 0
      ).toFixed(2);

      newObj[`Final ${rowHeader[4]}`] = Number(
        i === 0
          ? Number(
              // calculateGstAmount(
              sum([
                calculateExchangeValue(
                  Number(submittedData["exchange_rate"] || 0),
                  Number(newObj[field] || 0)
                ),
                Number(newObj[`Fob Value ${rowHeader[2]}`] || 0),
                Number(newObj[`Insurance ${rowHeader[2]}`] || 0),
              ])
              // Number(submittedData.rate_of_igst || 0),
              // Number(newObj[`Fob Value ${rowHeader[4]}`] || 0)
              // )
            ).toFixed(2)
          : newObj[`Total ${rowHeader[4]}`]
      ).toFixed(2);
    } else if (field.includes("Insurance")) {
      // Insurance
      newObj[field] = fieldValue;

      newObj[`Insurance ${rowHeader[3]}`] = Number(
        i == 0
          ? calculatePrice(
              calculateExchangeValue(
                Number(submittedData["exchange_rate"] || 0),
                Number(newObj[field] || 0)
              ),
              Number(submittedData["Net Weight"] || 0)
            )
          : newObj[`Insurance ${rowHeader[3]}`]
      ).toFixed(2);

      newObj[`Insurance ${rowHeader[4]}`] = Number(
        i == 0
          ? calculateExchangeValue(
              Number(submittedData["exchange_rate"] || 0),
              Number(newObj[field] || 0)
            )
          : newObj[`Insurance ${rowHeader[4]}`]
      ).toFixed(2);

      // Total
      newObj[`Total ${rowHeader[2]}`] = Number(
        sum([
          Number(newObj[field]),
          Number(newObj[`Fob Value ${rowHeader[2]}`] || 0),
          Number(newObj[`Ocean Freight ${rowHeader[2]}`] || 0),
        ])
      ).toFixed(2);

      newObj[`Total ${rowHeader[3]}`] = Number(
        calculatePrice(
          i === 0
            ? sum([
                calculateExchangeValue(
                  Number(submittedData["exchange_rate"] || 0),
                  Number(newObj[field] || 0)
                ),
                Number(newObj[`Fob Value ${rowHeader[4]}`] || 0),
                Number(newObj[`Ocean Freight ${rowHeader[4]}`] || 0),
              ])
            : newObj[`Total ${rowHeader[4]}`],
          Number(submittedData["Net Weight"] || 0)
        ).toFixed(2)
      ).toFixed(2);

      newObj[`Total ${rowHeader[4]}`] = Number(
        i === 0
          ? sum([
              calculateExchangeValue(
                Number(submittedData["exchange_rate"] || 0),
                Number(newObj[field] || 0)
              ),
              Number(newObj[`Fob Value ${rowHeader[4]}`] || 0),
              Number(newObj[`Ocean Freight ${rowHeader[4]}`] || 0),
            ])
          : newObj[`Total ${rowHeader[4]}`]
      ).toFixed(2);

      // IGST
      newObj[`IGST Amount ${rowHeader[4]}`] = Number(
        calculatePercentage(
          i === 0
            ? Number(newObj[`Total ${rowHeader[4]}`])
            : Number(newObj[`Total ${rowHeader[4]}`]),
          Number(submittedData.rate_of_igst || 0)
        ).toFixed(2)
      ).toFixed(2);

      // FINAL
      newObj[`Final ${rowHeader[2]}`] = Number(
        i === 0 || i === 2
          ? Number(
              // calculateGstAmount(
              sum([
                Number(newObj[field] || 0),
                Number(newObj[`Fob Value ${rowHeader[2]}`] || 0),
                Number(newObj[`Ocean Freight ${rowHeader[2]}`] || 0),
              ])
              // Number(submittedData.rate_of_igst || 0),
              // Number(newObj[`Fob Value ${rowHeader[2]}`] || 0)
              // )
            ).toFixed(2)
          : 0
      ).toFixed(2);

      newObj[`Final ${rowHeader[4]}`] = Number(
        i === 0
          ? Number(
              // calculateGstAmount(
              sum([
                calculateExchangeValue(
                  Number(submittedData["exchange_rate"] || 0),
                  Number(newObj[field] || 0)
                ),
                Number(newObj[`Fob Value ${rowHeader[4]}`] || 0),
                Number(newObj[`Ocean Freight ${rowHeader[4]}`] || 0),
              ])
              // Number(submittedData.rate_of_igst || 0),
              // Number(newObj[`Fob Value ${rowHeader[4]}`] || 0)
              // )
            ).toFixed(2)
          : newObj[`Total ${rowHeader[4]}`]
      ).toFixed(2);
    } else if (field.includes("Total")) {
      // Total
      newObj[field] = fieldValue;
    } else if (field.includes("IGST Amount")) {
      // IGST Amount
      newObj[field] = fieldValue;
    } else if (field.includes("Final")) {
      // IGST Amount
      newObj[field] = fieldValue;
    }

    setSubmittedData(newObj);
    setError({ ...error, [field]: false });
  };

  useEffect(() => {
    if (
      submittedData &&
      submittedDutyData[`Total Fob Value ${rowLeftHeader[1].key}`]
    ) {
      console.log("called here in submit");
      handleDutyValues(
        "",
        `Total Fob Value ${rowLeftHeader[2].key}`,
        submittedDutyData[`Total Fob Value ${rowLeftHeader[2].key}`] || 0
      );
    }
  }, [submittedData["Net Weight"]]);

  useEffect(() => {
    if (selected && selected.packaging && dialogOpen.open === true) {
      const newInput = inputArrCopy.map((s, i) => {
        return {
          ...s,
          defaultValue: {
            value: editDetail && editDetail[s.api] ? editDetail[s.api] : "",
          },
        };
      });
      const ids = [];
      console.log(selected, "selected")
      selected.packaging &&
        selected.packaging.length > 0 &&
        selected.packaging.map((P) => {
          let sP = P?.id ? P.id : P
          const pVal = P?.id ? P : filterData(
            masters.PackagingList,
            "id",
            Number(sP),
            "onlyOne"
          )
            ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
            : "";
          if (!ids.includes(sP)) {
            ids.push(sP);
            newInput.push({
              disabled: alldisabled,
              name: `${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`,
              type: "textField",
              dataType: "number",
              only_number: true,
              api: `${sP}-bags`,
              defaultValue: {
                value:
                  editDetail && editDetail[`${sP}-bags`]
                    ? editDetail[`${sP}-bags`]
                    : "",
              },
              endAdornment: `bags`,
              helperText: `${
                submittedData[`${sP}-bags`]
                  ? `Weight : ${multiply(
                      Number(submittedData[`${sP}-bags`]),
                      Number(pVal.size)
                    ).toFixed(2)}${pVal.unit}`
                  : ""
              }`,
              shrink: true,
              onChange: async (submiited) => {
                submiited["Net Weight"] = Number(
                  calculateNetWeight(submiited)
                ).toFixed(3);
                const ofExhange = submittedData[
                  `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                ]
                  ? submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]
                  : 0;
                const fvexchange = submittedData[
                  `Fob Value ${rowHeader.slice(2, 5)[2]}`
                ]
                  ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                  : 0;

                const insurance = submittedData[
                  `Insurance ${rowHeader.slice(2, 5)[2]}`
                ]
                  ? submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]
                  : 0;
                setSubmittedData((prev) => {
                  return {
                    ...prev,
                    [`${sP}-bags`]:
                      submiited[
                        `${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                      ],
                    "Net Weight": Number(
                      Number(calculateNetWeight(submiited)).toFixed(3)
                    ),
                    [`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(ofExhange, calculateNetWeight(submiited))
                    ).toFixed(2),
                    [`Fob Value ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(fvexchange, calculateNetWeight(submiited))
                    ).toFixed(2),
                    [`Insurance ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(insurance, calculateNetWeight(submiited))
                    ).toFixed(2),
                    [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                      calculatePrice(
                        sum([ofExhange, insurance, insurance]),
                        calculateNetWeight(submiited)
                      )
                    ).toFixed(2),
                  };
                });
                return submiited;
              },
            });
          }
          return sP;
        });
      newInput.push({
        name: "Exchange Rate",
        type: "textField",
        dataType: "number",
        endAdornment: "INR",
        startAdornment: `1 ${
          selected && selected["Currency"] ? `${selected["Currency"]}` : ""
        } =`,
        defaultValue: {
          value:
            editDetail && editDetail["exchange_rate"]
              ? editDetail["exchange_rate"]
              : "",
        },
        onChange: async (sub) => {
          setSubmittedData((prev) => {
            const ofExhange = calculateExchangeValue(
              sub["Exchange Rate"] ? sub["Exchange Rate"] : 0,
              submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[0]}`]
                ? submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[0]}`]
                : 0
            );
            const fvexchange = calculateExchangeValue(
              sub["Exchange Rate"] ? sub["Exchange Rate"] : 0,
              submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                : 0
            );
            const insurance = calculateExchangeValue(
              sub["Exchange Rate"] ? sub["Exchange Rate"] : 0,
              submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                ? submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                : 0
            );
            console.log(
              sum([ofExhange, fvexchange, insurance]),
              "sum([ofExhange, fvexchange, insurance])"
            );
            return {
              ...prev,
              exchange_rate: sub["Exchange Rate"],
              [`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]: ofExhange,
              [`Fob Value ${rowHeader.slice(2, 5)[2]}`]: fvexchange,
              [`Insurance ${rowHeader.slice(2, 5)[2]}`]: insurance,
              [`IGST Amount ${rowHeader.slice(2, 5)[2]}`]: calculatePercentage(
                sum([fvexchange]),
                Number(
                  submittedData["rate_of_igst"]
                    ? submittedData["rate_of_igst"]
                    : 0
                )
              ),
              [`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  ofExhange,
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(2),
              [`Fob Value ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  fvexchange,
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(2),
              [`Insurance ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  insurance,
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(2),

              [`Total ${rowHeader.slice(2, 5)[2]}`]: Number(
                sum([ofExhange, fvexchange, insurance])
              ).toFixed(2),

              [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(
                calculatePrice(
                  sum([ofExhange, fvexchange, insurance]),
                  submittedData["Net Weight"] ? submittedData["Net Weight"] : 0
                )
              ).toFixed(2),
              [`Final ${rowHeader.slice(2, 5)[2]}`]:
                ci_type === "GST"
                  ? calculateGstAmount(
                      sum([ofExhange, fvexchange, insurance]),
                      Number(
                        submittedData["rate_of_igst"]
                          ? submittedData["rate_of_igst"]
                          : 0
                      ),
                      fvexchange
                    )
                  : sum([ofExhange, fvexchange, insurance]),
              // : submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
            };
          });
          handleDutyValues(
            "",
            `Total Fob Value ${rowLeftHeader[1].key}`,
            submittedDutyData[`Total Fob Value ${rowLeftHeader[1].key}`]
              ? submittedDutyData[`Total Fob Value ${rowLeftHeader[1].key}`]
              : 0
          );
          return sub;
        },
        api: "exchange_rate",
        disabled: alldisabled,
      });
      if (ci_type === "GST") {
        newInput.push({
          name: "Rate of IGST ( IN % )",
          type: "textField",
          dataType: "number",
          endAdornment: "%",
          percentage: "100",
          defaultValue: {
            value:
              editDetail && editDetail["rate_of_igst"]
                ? editDetail["rate_of_igst"]
                : "",
          },
          onChange: async (sub) => {
            setSubmittedData((prev) => {
              return {
                ...prev,
                rate_of_igst: sub["Rate of IGST ( IN % )"],

                [`IGST Amount ${rowHeader.slice(2, 5)[2]}`]:
                  calculatePercentage(
                    sum([
                      Number(
                        submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]
                          ? submittedData[
                              `Total ${rowHeader.slice(2, 5)[2]}`
                            ]
                          : 0
                      ),
                    ]),
                    Number(
                      sub["Rate of IGST ( IN % )"]
                        ? sub["Rate of IGST ( IN % )"]
                        : 0
                    )
                  ),
                [`Final ${rowHeader.slice(2, 5)[0]}`]: calculateGstAmount(
                  sum([
                    Number(
                      submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[0]}`]
                        ? submittedData[
                            `Ocean Freight ${rowHeader.slice(2, 5)[0]}`
                          ]
                        : 0
                    ),
                    Number(
                      submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                        ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                        : 0
                    ),
                    Number(
                      submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                        ? submittedData[`Insurance ${rowHeader.slice(2, 5)[0]}`]
                        : 0
                    ),
                  ]),
                  Number(
                    sub["Rate of IGST ( IN % )"]
                      ? sub["Rate of IGST ( IN % )"]
                      : 0
                  ),
                  Number(
                    submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                      ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[0]}`]
                      : 0
                  )
                ),
                [`Final ${rowHeader.slice(2, 5)[2]}`]: calculateGstAmount(
                  sum([
                    Number(
                      submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]
                        ? submittedData[
                            `Ocean Freight ${rowHeader.slice(2, 5)[2]}`
                          ]
                        : 0
                    ),
                    Number(
                      submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                        ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                        : 0
                    ),
                    Number(
                      submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]
                        ? submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]
                        : 0
                    ),
                  ]),
                  Number(
                    sub["Rate of IGST ( IN % )"]
                      ? sub["Rate of IGST ( IN % )"]
                      : 0
                  ),
                  Number(
                    submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                      ? submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]
                      : 0
                  )
                ),
              };
            });
            return sub;
          },
          api: "rate_of_igst",
          disabled: alldisabled,
        });
      }
      if (
        sum([ci_type === "GST" ? 4 : 3, selected.packaging.length]) ===
        newInput.length
      ) {
        setInputArr(newInput.reverse());
        setLoader(false);
      }
    }
  }, [dialogOpen.open, submittedData, editDetail, ci_type]);

  const colorArr = [white, lightGrey];
  const valuesTable = () => {
    return (
      <div
        className="d-flex width-100 flexWrap "
        style={{ margin: "0 0px 10px 10px" }}
      >
        {/* <Divider style={{ width: "100%", margin: "0" }} /> */}
        {/* <HeadingLine maxWidth="fit-content" width="fit-content" children={`Amount Details`} center={true} /> */}
        <div className="width-100 marginT10B20">
          <div
            style={{
              backgroundColor: "#8080801a",
              color: primary,
              borderColor: primary,
              width: "fit-content",
              minWidth: "100%",
              tableLayout: "fixed",
              display: "flex",
              minHeight: "30px",
              overflow: "hidden",
              borderRadius: "7px 7px 0 0",
            }}
          >
            <div
              className="d-flex justifySE width-100"
              style={{ background: "rgb(128 128 128 / 64%)" }}
            >
              {rowHeader.map((sC, i) => (
                <div
                  key={sC}
                  className="justifyC d-flex "
                  style={{
                    width: i === 0 ? "112px" : "140px",
                    minWidth: i === 0 ? "112px" : "140px",
                    maxWidth: i === 0 ? "112px" : "140px",
                    margin: "12px 10px",
                    display: "flex",
                    color: "#100f0f",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {i === 0
                    ? `${sC} ( ${
                        submittedData
                          ? submittedData["exchange_rate"]
                            ? submittedData["exchange_rate"]
                            : ""
                          : ""
                      } )`
                    : sC}
                </div>
              ))}{" "}
            </div>
          </div>
          <div
            style={{
              height: "fit-content",
              width: "100%",
              minWidth: "100%",
              position: "relative",
              borderRadius: "0 0 7px 7px",
              borderRight: "1px solid rgba(128, 128, 128, 0.64)",
              borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
              borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
              // padding: "5px 0",
            }}
          >
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[2 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                Fob Value
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}
                  required={true}
                  disabled={onlyView ? true : i === 2 || i === 1 ? true : false}
                  onFocus={StopScroll}
                  error={error[`Fob Value ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Fob Value ${singleInput}`]
                      ? submittedData[`Fob Value ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleRightDutyValuesTable(e, `Fob Value ${singleInput}`, i)
                  }
                  //             onChange={(event) => {
                  //               setSubmittedData({
                  //                 ...submittedData,
                  //                 [`Fob Value ${singleInput}`]: event.target.value.match(/^\d*\.?\d{0,2}$/) ? event.target.value : submittedData[`Fob Value ${singleInput}`],
                  //                 [`Total ${singleInput}`]: Number(sum([
                  //                   Number(event.target.value ? event.target.value : 0),
                  //                   Number(
                  //                     submittedData[`Ocean Freight ${singleInput}`]
                  //                       ? submittedData[`Ocean Freight ${singleInput}`]
                  //                       : 0
                  //                   ),
                  //                   Number(
                  //                     submittedData[`Insurance ${singleInput}`] ? submittedData[`Insurance ${singleInput}`] : 0
                  //                   ),
                  //                 ])).toFixed(2),
                  //                [`Fob Value ${rowHeader.slice(2, 5)[1]}`]:Number(i==0?
                  //                 calculatePrice(calculateExchangeValue(
                  //                   submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                   event.target.value ? event.target.value : 0
                  //                 ),submittedData["Net Weight"]?submittedData["Net Weight"]:0):
                  //                 submittedData[`Fob Value ${rowHeader.slice(2, 5)[1]}`]).toFixed(2),
                  //                [`IGST Amount ${rowHeader.slice(2, 5)[2]}`]: Number(calculatePercentage(
                  //                   i === 0
                  //                     ? sum([
                  //                         calculateExchangeValue(
                  //                           submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                           event.target.value ? event.target.value : 0
                  //                         ),
                  //                       ])
                  //                     : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`],
                  //                   Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0)
                  //                 )).toFixed(2),
                  //                 [`Final ${singleInput}`]:
                  //                   i === 0
                  //                     ? Number(calculateGstAmount(
                  //                         sum([
                  //                           Number(event.target.value ? event.target.value : 0),
                  //                           Number(
                  //                             submittedData[`Ocean Freight ${singleInput}`]
                  //                               ? submittedData[`Ocean Freight ${singleInput}`]
                  //                               : 0
                  //                           ),
                  //                           Number(
                  //                             submittedData[`Insurance ${singleInput}`]
                  //                               ? submittedData[`Insurance ${singleInput}`]
                  //                               : 0
                  //                           ),
                  //                         ]),
                  //                         Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0), Number(event.target.value ? event.target.value : 0),
                  //                       )).toFixed(2)
                  //                     : "",
                  //                 [`Fob Value ${rowHeader.slice(2, 5)[2]}`]:
                  //                   Number(i === 0
                  //                     ? calculateExchangeValue(
                  //                         submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                         event.target.value ? event.target.value : 0
                  //                       )
                  //                     : submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //                 [`Total ${rowHeader.slice(2, 5)[2]}`]:
                  //                   Number(i === 0
                  //                     ? sum([
                  //                         calculateExchangeValue(
                  //                           submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                           event.target.value ? event.target.value : 0
                  //                         ),
                  //                         submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
                  //                         submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`],
                  //                       ])
                  //                     : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  // [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(calculatePrice(i === 0
                  //   ? sum([
                  //       calculateExchangeValue(
                  //         submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //         event.target.value ? event.target.value : 0
                  //       ),
                  //       submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
                  //       submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`],
                  //     ])
                  //   : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),submittedData["Net Weight"]?submittedData["Net Weight"]:0).toFixed(2),

                  //                 [`Final ${rowHeader.slice(2, 5)[2]}`]:
                  //                   Number(i === 0
                  //                     ? calculateGstAmount(
                  //                         sum([
                  //                           calculateExchangeValue(
                  //                             submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                             event.target.value ? event.target.value : 0
                  //                           ),
                  //                           submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
                  //                           submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`],
                  //                         ]),
                  //                         Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0),
                  //                         event.target.value ? event.target.value : 0
                  //                       )
                  //                     : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //               });

                  //               setError({ ...error, [`Fob Value ${singleInput}`]: false });
                  //             }}
                  variant="outlined"
                />
              ))}
            </div>

            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[3 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                Ocean Freight
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}

                  disabled={onlyView ? true : i === 2 || i === 1 ? true : false}
                  required={true}
                  onFocus={StopScroll}
                  error={error[`Ocean Freight ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Ocean Freight ${singleInput}`]
                      ? submittedData[`Ocean Freight ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleRightDutyValuesTable(
                      e,
                      `Ocean Freight ${singleInput}`,
                      i
                    )
                  }
                  // onChange={(event) => {
                  //   setSubmittedData({
                  //     ...submittedData,
                  //     [`Ocean Freight ${singleInput}`]: event.target.value,
                  //     [`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]:Number(i==0?
                  //     calculatePrice(calculateExchangeValue(
                  //       submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //       event.target.value ? event.target.value : 0
                  //     ),submittedData["Net Weight"]?submittedData["Net Weight"]:0):
                  //     submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[1]}`]).toFixed(2),
                  //     [`Total ${singleInput}`]: Number(sum([
                  //       Number(event.target.value ? event.target.value : 0),
                  //       Number(
                  //         submittedData[`Fob Value ${singleInput}`] ? submittedData[`Fob Value ${singleInput}`] : 0
                  //       ),
                  //       Number(
                  //         submittedData[`Insurance ${singleInput}`] ? submittedData[`Insurance ${singleInput}`] : 0
                  //       ),
                  //     ])).toFixed(2),
                  //     [`Final ${singleInput}`]:
                  //       i === 0 || i === 2
                  //         ? Number(calculateGstAmount(
                  //             sum([
                  //               Number(event.target.value ? event.target.value : 0),
                  //               Number(
                  //                 submittedData[`Fob Value ${singleInput}`]
                  //                   ? submittedData[`Fob Value ${singleInput}`]
                  //                   : 0
                  //               ),
                  //               Number(
                  //                 submittedData[`Insurance ${singleInput}`]
                  //                   ? submittedData[`Insurance ${singleInput}`]
                  //                   : 0
                  //               ),
                  //             ]),
                  //             Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0),Number(
                  //               submittedData[`Fob Value ${singleInput}`]
                  //                 ? submittedData[`Fob Value ${singleInput}`]
                  //                 : 0
                  //             ),
                  //           )).toFixed(2)
                  //         : "",
                  //     [`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]:
                  //       Number(i === 0
                  //         ? calculateExchangeValue(
                  //             submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //             event.target.value ? event.target.value : 0
                  //           )
                  //         : submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //     [`Total ${rowHeader.slice(2, 5)[2]}`]:
                  //       Number(i === 0
                  //         ? sum([
                  //             calculateExchangeValue(
                  //               submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //               event.target.value ? event.target.value : 0
                  //             ),
                  //             submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //             submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
                  //           ])
                  //         : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //         [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(calculatePrice(i === 0
                  //           ? sum([
                  //               calculateExchangeValue(
                  //                 submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                 event.target.value ? event.target.value : 0
                  //               ),
                  //               submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //               submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
                  //             ])
                  //           : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]
                  //           , submittedData["Net Weight"]?submittedData["Net Weight"]:0
                  //         )).toFixed(2),

                  //     [`Final ${rowHeader.slice(2, 5)[2]}`]:
                  //       Number(i === 0
                  //         ? calculateGstAmount(
                  //             sum([
                  //               calculateExchangeValue(
                  //                 submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                 event.target.value ? event.target.value : 0
                  //               ),
                  //               submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //               submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`],
                  //             ]),
                  //             Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0),
                  //             submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //           )
                  //         : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //   });

                  //   setError({ ...error, [`Ocean Freight ${singleInput}`]: false });
                  // }}
                  variant="outlined"
                />
              ))}
            </div>

            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[4 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                Insurance
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}
                  disabled={onlyView ? true : i === 2 || i === 1 ? true : false}
                  required={true}
                  onFocus={StopScroll}
                  error={error[`Insurance ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Insurance ${singleInput}`]
                      ? submittedData[`Insurance ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleRightDutyValuesTable(e, `Insurance ${singleInput}`, i)
                  }
                  // onChange={(event) => {
                  //   setSubmittedData({
                  //     ...submittedData,
                  //     [`Insurance ${singleInput}`]: event.target.value,
                  //     [`Insurance ${rowHeader.slice(2, 5)[1]}`]:Number(i==0?
                  //     calculatePrice(calculateExchangeValue(
                  //       submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //       event.target.value ? event.target.value : 0
                  //     ),submittedData["Net Weight"]?submittedData["Net Weight"]:0):
                  //     submittedData[`Insurance ${rowHeader.slice(2, 5)[1]}`]).toFixed(2),

                  //     [`Total ${singleInput}`]: Number(sum([
                  //       Number(event.target.value ? event.target.value : 0),
                  //       Number(
                  //         submittedData[`Fob Value ${singleInput}`] ? submittedData[`Fob Value ${singleInput}`] : 0
                  //       ),
                  //       Number(
                  //         submittedData[`Ocean Freight ${singleInput}`]
                  //           ? submittedData[`Ocean Freight ${singleInput}`]
                  //           : 0
                  //       ),
                  //     ])).toFixed(2),

                  //     [`Final ${singleInput}`]:
                  //       i === 0 || i === 2
                  //         ? Number(calculateGstAmount(
                  //             sum([
                  //               Number(event.target.value ? event.target.value : 0),
                  //               Number(
                  //                 submittedData[`Fob Value ${singleInput}`]
                  //                   ? submittedData[`Fob Value ${singleInput}`]
                  //                   : 0
                  //               ),
                  //               Number(
                  //                 submittedData[`Ocean Freight ${singleInput}`]
                  //                   ? submittedData[`Ocean Freight ${singleInput}`]
                  //                   : 0
                  //               ),
                  //             ]),
                  //             Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0),Number(
                  //               submittedData[`Fob Value ${singleInput}`]
                  //                 ? submittedData[`Fob Value ${singleInput}`]
                  //                 : 0
                  //             ),
                  //           )).toFixed(2)
                  //         : "",
                  //     [`Insurance ${rowHeader.slice(2, 5)[2]}`]:
                  //       Number(i === 0
                  //         ? calculateExchangeValue(
                  //             submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //             event.target.value ? event.target.value : 0
                  //           )
                  //         : submittedData[`Insurance ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //     [`Total ${rowHeader.slice(2, 5)[2]}`]:
                  //       Number(i === 0
                  //         ? sum([
                  //             calculateExchangeValue(
                  //               submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //               event.target.value ? event.target.value : 0
                  //             ),
                  //             submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //             submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`],
                  //           ])
                  //         : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //         [`Total ${rowHeader.slice(2, 5)[1]}`]: Number(calculatePrice(
                  //           i === 0
                  //           ? sum([
                  //               calculateExchangeValue(
                  //                 submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                 event.target.value ? event.target.value : 0
                  //               ),
                  //               submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //               submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`],
                  //             ])
                  //           : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]
                  //           , submittedData["Net Weight"]? submittedData["Net Weight"] : 0
                  //         )).toFixed(2),
                  //     [`Final ${rowHeader.slice(2, 5)[2]}`]:
                  //       Number(i === 0
                  //         ? calculateGstAmount(
                  //             sum([
                  //               calculateExchangeValue(
                  //                 submittedData["exchange_rate"] ? submittedData["exchange_rate"] : 0,
                  //                 event.target.value ? event.target.value : 0
                  //               ),
                  //               submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //               submittedData[`Ocean Freight ${rowHeader.slice(2, 5)[2]}`],
                  //             ]),
                  //             Number(submittedData.rate_of_igst ? submittedData.rate_of_igst : 0),
                  //             submittedData[`Fob Value ${rowHeader.slice(2, 5)[2]}`],
                  //           )
                  //         : submittedData[`Total ${rowHeader.slice(2, 5)[2]}`]).toFixed(2),
                  //   });

                  //   setError({ ...error, [`Insurance ${singleInput}`]: false });
                  // }}
                  variant="outlined"
                />
              ))}
            </div>
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[5 % colorArr.length],
                minHeight: "60px",
                borderRadius: "0 0 7px 7px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "274px",
                  margin: "12px 10px",
                  fontWeight: "500",
                  fontSize: "0.78rem",
                }}
              >
                Total
              </div>
              {rowHeader.slice(2, 5).map((singleInput, i) => (
                <CssTextField95
                  // autoFocus={}
                  required={true}
                  disabled={onlyView ? true : true} //} // false}
                  onFocus={StopScroll}
                  error={error[`Total ${singleInput}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{ width: "140px", minWidth: "140px", margin: "10px" }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedData[`Total ${singleInput}`]
                      ? submittedData[`Total ${singleInput}`]
                      : ""
                  }
                  name={singleInput}
                  onKeyDown={(evt) => {
                    evt.key === "e" && evt.preventDefault();
                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleRightDutyValuesTable(e, `Total ${singleInput}`, i)
                  }
                  // onChange={(event) => {
                  //   setSubmittedData({
                  //     ...submittedData,
                  //     [`Total ${singleInput}`]: event.target.value,
                  //   });
                  //   setError({ ...error, [`Total ${singleInput}`]: false });
                  // }}
                  variant="outlined"
                />
              ))}
            </div>
            {ci_type === "GST" ? (
              <div
                className="d-flex width-100 justifySE"
                style={{
                  backgroundColor: colorArr && colorArr[5 % colorArr.length],
                  minHeight: "60px",
                  borderRadius: "0 0 7px 7px",
                }}
              >
                <div
                  className="d-flex alignC"
                  style={{
                    minWidth: "274px",
                    margin: "12px 10px",
                    fontWeight: "500",
                    fontSize: "0.78rem",
                  }}
                >
                  IGST Amount on FOB Value
                </div>
                {rowHeader.slice(2, 5).map((singleInput, i) =>
                  i === 1 || i === 0 ? (
                    <div
                      style={{ minWidth: "140px", margin: "12px 10px" }}
                    ></div>
                  ) : (
                    <CssTextField95
                      // autoFocus={}

                      disabled={onlyView ? true : true} // false}
                      required={true}
                      onFocus={StopScroll}
                      error={error[`IGST Amount ${singleInput}`] ? true : false}
                      autoComplete="off"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                        endAdornment: "",
                        startAdornment: "",
                      }}
                      style={{
                        width: "140px",
                        minWidth: "140px",
                        margin: "10px",
                      }}
                      id="outlined-basic"
                      label={""}
                      type={"number"}
                      value={
                        submittedData[`IGST Amount ${singleInput}`]
                          ? submittedData[`IGST Amount ${singleInput}`]
                          : ""
                      }
                      name={singleInput}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();
                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        evt.keyCode === 38 && evt.preventDefault();
                        evt.keyCode === 40 && evt.preventDefault();
                      }}
                      onChange={(e) =>
                        handleRightDutyValuesTable(
                          e,
                          `IGST Amount ${singleInput}`,
                          i
                        )
                      }
                      // onChange={(event) => {
                      //   setSubmittedData({
                      //     ...submittedData,
                      //     [`IGST Amount ${singleInput}`]: event.target.value,
                      //   });

                      //   setError({ ...error, [`IGST Amount ${singleInput}`]: false });
                      // }}
                      variant="outlined"
                    />
                  )
                )}
              </div>
            ) : (
              ""
            )}
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[5 % colorArr.length],
                minHeight: "60px",
                borderRadius: "0 0 7px 7px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  minWidth: "112px",
                  margin: "12px 10px",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                }}
              >
                Final value
              </div>
              {rowHeader.slice(1, 5).map((singleInput, i) =>
                i === 0 || i === 2 ? (
                  <div
                    style={{ minWidth: "140px", margin: "12px 10px" }}
                    className={`d-flex ${
                      i !== 0 ? "justifyC" : "justifyC"
                    } alignC`}
                  >
                    {i === 0
                      ? selected && selected["Currency"]
                        ? `${selected["Currency"]}`
                        : ""
                      : "INR"}
                  </div>
                ) : (
                  <CssTextField95
                    required={true}
                    disabled={onlyView ? true : true} // false}
                    onFocus={StopScroll}
                    error={error[`Final ${singleInput}`] ? true : false}
                    autoComplete="off"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      endAdornment: "",
                      startAdornment: "",
                    }}
                    style={{
                      width: "140px",
                      minWidth: "140px",
                      margin: "10px",
                    }}
                    id="outlined-basic"
                    label={""}
                    type={"number"}
                    value={
                      submittedData[`Final ${singleInput}`]
                        ? submittedData[`Final ${singleInput}`]
                        : ""
                    }
                    name={singleInput}
                    onKeyDown={(evt) => {
                      evt.key === "e" && evt.preventDefault();
                      evt.key === "E" && evt.preventDefault();
                      evt.key === "-" && evt.preventDefault();
                      evt.keyCode === 38 && evt.preventDefault();
                      evt.keyCode === 40 && evt.preventDefault();
                    }}
                    onChange={(e) =>
                      handleRightDutyValuesTable(e, `Final ${singleInput}`, i)
                    }
                    // onChange={(event) => {
                    //   setSubmittedData({
                    //     ...submittedData,
                    //     [`Final ${singleInput}`]: event.target.value,
                    //   });

                    //   setError({ ...error, [`Final ${singleInput}`]: false });
                    // }}
                    variant="outlined"
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const valuesLeftTable = () => {
    const width = (i) => {
      return i === 0 ? 190 : i === 1 ? 100 : 140;
    };
    const widthPer = ["40%", "20%", "20%", "20%"];
    return (
      <div
        className="d-flex width-100 flexWrap "
        style={{ margin: "0 0px 10px 10px" }}
      >
        <div className="width-100 marginT10B20">
          <div
            style={{
              backgroundColor: "#8080801a",
              color: primary,
              borderColor: primary,
              width: "fit-content",
              minWidth: "100%",
              tableLayout: "fixed",
              display: "flex",
              minHeight: "30px",
              overflow: "hidden",
              borderRadius: "7px 7px 0 0",
            }}
          >
            <div
              className="d-flex justifySE width-100"
              style={{ background: "rgb(128 128 128 / 64%)" }}
            >
              {rowLeftHeader.map((sC, i) => (
                <div
                  key={sC}
                  className="justifyC d-flex "
                  style={{
                    width: widthPer[i],
                    minWidth: width(i),
                    // maxWidth: width(i),
                    margin: "12px 10px",
                    display: "flex",
                    color: "#100f0f",
                    textTransform: "capitalize",
                    textAlign: i === 0 ? "left" : "center",
                  }}
                >
                  {sC.field}
                </div>
              ))}{" "}
            </div>
          </div>
          <div
            style={{
              height: "fit-content",
              width: "100%",
              minWidth: "100%",
              position: "relative",
              borderRadius: "0 0 7px 7px",
              borderRight: "1px solid rgba(128, 128, 128, 0.64)",
              borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
              borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
              // padding: "5px 0",
            }}
          >
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[2 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  width: widthPer[0],
                  minWidth: width(0),
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                TOTAL FOB VALUE (including export duty reimbursement from buyer)
              </div>
              {rowLeftHeader.slice(1, 4).map((singleInput, i) => (
                <CssTextField95
                  required={true}
                  disabled={onlyView ? true : i === 1 ? false : true}
                  onFocus={StopScroll}
                  error={
                    error[`Total Fob Value ${singleInput.key}`] ? true : false
                  }
                  autoComplete="off"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{
                    width: widthPer[i + 1],
                    minWidth: width(i + 1),
                    margin: "10px",
                  }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedDutyData[`Total Fob Value ${singleInput.key}`]
                      ? submittedDutyData[`Total Fob Value ${singleInput.key}`]
                      : ""
                  }
                  name={singleInput.key}
                  onKeyDown={(evt) => {
                    (["e", "E", "+", "-"].includes(evt.key) ||
                      evt.which === 38 ||
                      evt.which === 40) &&
                      evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleDutyValues(
                      e.target.value,
                      `Total Fob Value ${singleInput.key}`
                    )
                  }
                  variant="outlined"
                />
              ))}
            </div>
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[3 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC d-flex-column"
                style={{
                  width: widthPer[0],
                  minWidth: width(0),
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                <div className="d-flex alignC width-100">
                  Duty @
                  <CssTextField85
                    required={true}
                    disabled={onlyView ? true : false}
                    onFocus={StopScroll}
                    error={error[`Duty Percent Of FOB`] ? true : false}
                    autoComplete="off"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: "%",
                      startAdornment: "",
                    }}
                    style={{
                      width: 120,
                      minWidth: 80,
                      margin: "0 10px",
                    }}
                    id="outlined-basic"
                    label={""}
                    type={"number"}
                    value={
                      submittedDutyData[`Duty Percent Of FOB`]
                        ? submittedDutyData[`Duty Percent Of FOB`]
                        : ""
                    }
                    name={"Duty Percent Of FOB"}
                    onKeyDown={(evt) => {
                      (["e", "E", "+", "-"].includes(evt.key) ||
                        evt.which === 38 ||
                        evt.which === 40) &&
                        evt.preventDefault();
                    }}
                    onChange={(e) => {
                      handleDutyValues(e.target.value, `Duty Percent Of FOB`);
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="width-100">of FOB Value as above</div>
              </div>
              {rowLeftHeader.slice(1, 4).map((singleInput, i) => (
                <CssTextField95
                  required={true}
                  disabled={onlyView ? true : i !== 0 ? true : false}
                  onFocus={StopScroll}
                  error={
                    error[`Duty Percent ${singleInput.key}`] ? true : false
                  }
                  autoComplete="off"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{
                    width: widthPer[i + 1],
                    minWidth: width(i + 1),
                    margin: "10px",
                  }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedDutyData[`Duty Percent ${singleInput.key}`]
                      ? submittedDutyData[`Duty Percent ${singleInput.key}`]
                      : ""
                  }
                  name={singleInput.key}
                  onKeyDown={(evt) => {
                    (["e", "E", "+", "-"].includes(evt.key) ||
                      evt.which === 38 ||
                      evt.which === 40) &&
                      evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleDutyValues(
                      e.target.value,
                      `Duty Percent ${singleInput.key}`
                    )
                  }
                  variant="outlined"
                />
              ))}
            </div>
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[4 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  width: widthPer[0],
                  minWidth: width(0),
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                Total Export Duty payable as per us (which is reimbursed from
                buyer)
              </div>
              {rowLeftHeader.slice(1, 4).map((singleInput, i) => (
                <CssTextField95
                  required={true}
                  disabled={onlyView ? true : i !== 0 ? true : false}
                  onFocus={StopScroll}
                  error={
                    error[`Total Export Duty ${singleInput.key}`] ? true : false
                  }
                  autoComplete="off"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{
                    width: widthPer[i + 1],
                    minWidth: width(i + 1),
                    margin: "10px",
                  }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedDutyData[`Total Export Duty ${singleInput.key}`]
                      ? submittedDutyData[
                          `Total Export Duty ${singleInput.key}`
                        ]
                      : ""
                  }
                  name={singleInput.key}
                  onKeyDown={(evt) => {
                    (["e", "E", "+", "-"].includes(evt.key) ||
                      evt.which === 38 ||
                      evt.which === 40) &&
                      evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleDutyValues(
                      e.target.value,
                      `Total Export Duty ${singleInput.key}`
                    )
                  }
                  variant="outlined"
                />
              ))}
            </div>
            <div
              className="d-flex width-100 justifySE"
              style={{
                backgroundColor: colorArr && colorArr[5 % colorArr.length],
                minHeight: "60px",
              }}
            >
              <div
                className="d-flex alignC"
                style={{
                  width: widthPer[0],
                  minWidth: width(0),
                  margin: "12px 10px",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                Duty paid under protest
              </div>
              {rowLeftHeader.slice(1, 4).map((singleInput, i) => (
                <CssTextField95
                  required={true}
                  disabled={onlyView ? true : i !== 0 ? true : false}
                  onFocus={StopScroll}
                  error={error[`Duty paid ${singleInput.key}`] ? true : false}
                  autoComplete="off"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: "",
                    startAdornment: "",
                  }}
                  style={{
                    width: widthPer[i + 1],
                    minWidth: width(i + 1),
                    margin: "10px",
                  }}
                  id="outlined-basic"
                  label={""}
                  type={"number"}
                  value={
                    submittedDutyData[`Duty paid ${singleInput.key}`]
                      ? submittedDutyData[`Duty paid ${singleInput.key}`]
                      : ""
                  }
                  name={singleInput.key}
                  onKeyDown={(evt) => {
                    (["e", "E", "+", "-"].includes(evt.key) ||
                      evt.which === 38 ||
                      evt.which === 40) &&
                      evt.preventDefault();
                  }}
                  onChange={(e) =>
                    handleDutyValues(
                      e.target.value,
                      `Duty paid ${singleInput.key}`
                    )
                  }
                  variant="outlined"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {dialogOpen && dialogOpen.open && loader === false && (
        <CreateDialog
          onlyView={onlyView ? true : false}
          extraDetialLeft={valuesLeftTable}
          extraDetial={valuesTable}
          // extraDetialValue={selectedBrandsArr}
          noTitle={true}
          module={`${selected["Brand"]}-${selected["Item Category"]}-${selected["Item Type"]}%`}
          closeOnSave={true}
          selectedList={dialogOpen.details ? dialogOpen.details : false}
          editList={dialogOpen.details ? dialogOpen.details : false}
          handleClose={() => {
            setDialogOpen({ open: false, details: false });
          }}
          detailUI={() => {
            return (
              <ShowDetails
                isMobile={true}
                width="100%"
                lists={[
                  {
                    name: "HSN Code",
                    value:
                      selected && selected["HSN Code"]
                        ? `${selected["HSN Code"]}`
                        : "",
                  },
                  {
                    name: "Contract Rate",
                    value:
                      selected && selected["Contract Rate"]
                        ? `${selected["Contract Rate"]}`
                        : "",
                  },
                  {
                    name: "Currency",
                    value:
                      selected && selected["Currency"]
                        ? `${selected["Currency"]}`
                        : "",
                  },
                ]}
                height={"fit-content"}
                margin="10px"
              />
            );
          }}
          textFieldStyle={{ width: "calc(33% - 20px)", margin: "10px" }}
          heightNew={onlyView ? "fit-content" : "calc(100vh - 40px)"}
          maxHeight="calc(80vh - 60px)" //{onlyView ? : "calc(100vh - 140px)"}
          widthNew="calc(95vw - 30px)"
          inputArray={inputArr}
          checkAllFields={() => {
            const checkValidValue = (input) => {
              if (
                [
                  null,
                  "null",
                  undefined,
                  "undefined",
                  NaN,
                  "NaN",
                  "",
                  "NA",
                ].includes(input)
              ) {
                return false;
              }
              return true;
            };
            const checkData = (name) => {
              console.log(
                name,
                submittedData[name],
                checkValidValue(submittedData[name])
              );
              if (checkValidValue(submittedData[name])) {
                return true;
              } else {
                setError((prev) => {
                  return { ...prev, [name]: true };
                });
                return false;
              }
            };

            if (checkData(`Fob Value ${rowHeader[2]}`) === true) {
              if (checkData([`Fob Value ${rowHeader[3]}`]) === true) {
                if (checkData([`Fob Value ${rowHeader[4]}`]) === true) {
                  if (checkData([`Ocean Freight ${rowHeader[2]}`]) === true) {
                    if (checkData([`Ocean Freight ${rowHeader[3]}`]) === true) {
                      if (
                        checkData([`Ocean Freight ${rowHeader[4]}`]) === true
                      ) {
                        if (checkData([`Insurance ${rowHeader[2]}`]) === true) {
                          if (
                            checkData([`Insurance ${rowHeader[3]}`]) === true
                          ) {
                            if (
                              checkData([`Insurance ${rowHeader[4]}`]) === true
                            ) {
                              if (
                                checkData([`Total ${rowHeader[2]}`]) === true
                              ) {
                                if (
                                  checkData([`Total ${rowHeader[3]}`]) === true
                                ) {
                                  if (
                                    checkData([`Total ${rowHeader[4]}`]) ===
                                    true
                                  ) {
                                    // if (checkData([`IGST Amount ${rowHeader[2]}`]) === true || ci_type !== "GST") {
                                    // if (checkData([`IGST Amount ${rowHeader[3]}`]) === true || ci_type !== "GST") {
                                    if (
                                      checkData([
                                        `IGST Amount ${rowHeader[4]}`,
                                      ]) === true ||
                                      ci_type !== "GST"
                                    ) {
                                      if (
                                        checkData([`Final ${rowHeader[2]}`]) ===
                                        true
                                      ) {
                                        if (
                                          checkData([
                                            `Final ${rowHeader[4]}`,
                                          ]) === true
                                        ) {
                                          return true;
                                        } else {
                                          return false;
                                        }
                                      } else {
                                        return false;
                                      }
                                    } else {
                                      return false;
                                    }
                                    // } else {
                                    //   return false;
                                    // }
                                    // } else {
                                    //   return false;
                                    // }
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return false;
                                }
                              } else {
                                return false;
                              }
                            } else {
                              return false;
                            }
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          }}
          postDataToState={(data) => {
            if (setData) {
              console.log(submittedData, "submittedData", submittedDutyData);
              setData(submittedData, submittedDutyData);
            }
            setErrorMsg();
            setDialogOpen({ open: false, details: false });
          }}
        />
      )}

      <StyledLoadingButton
        width="120px"
        loading={loader}
        withBg={true}
        background={
          (errorMsg && errorMsg["addDetail"]) ||
          errorMsg[`${selected.id}addDetail`]
            ? red
            : false
        }
        name={
          onlyView
            ? "View Details"
            : editData
            ? "Update details"
            : "add details"
        }
        onClick={() => {
          setLoader(true);
          if (!editData) {
            setSubmittedData({});
          }
          setError({});
          setDialogOpen({ open: true, details: false });
        }}
      />
    </>
  );
}

export default ItemTableWIthINpitDetails;
