import DraftsIcon from "@mui/icons-material/Drafts";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BlockIcon from "@mui/icons-material/Block";
import PaymentIcon from "@mui/icons-material/Payment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SouthWestOutlinedIcon from '@mui/icons-material/SouthWestOutlined';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import { Chip } from "@mui/material";
import { mediumScreen } from "../Regex";

const statusIcons = (size = 0.7) => ({
  Draft: <DraftsIcon style={{ transform: `scale(${size})` }} />,
  Approved: <CheckIcon style={{ transform: `scale(${size})` }} />,
  Rejected: <CancelIcon style={{ transform: `scale(${size})` }} />,
  "Partially Fulfilled": (
    <HourglassBottomIcon style={{ transform: `scale(${size})` }} />
  ),
  Closed: <DoneAllIcon style={{ transform: `scale(${size})` }} />,
  Cancelled: <BlockIcon style={{ transform: `scale(${size})` }} />,
  Unpaid: <PaymentIcon style={{ transform: `scale(${size})` }} />,
  "Partially Paid": (
    <AttachMoneyIcon style={{ transform: `scale(${size})` }} />
  ),
  "Fully Paid": <CheckIcon style={{ transform: `scale(${size})` }} />,
  IN: <SouthWestOutlinedIcon style={{ transform: `scale(${size})` }} />,
  OUT: <NorthEastOutlinedIcon style={{ transform: `scale(${size})` }} />,
  Active: <CheckIcon style={{ transform: `scale(${size})` }} />,
  Inactive: <CancelIcon style={{ transform: `scale(${size})` }} />,
});


// Define status colors
const statusColors = {
  Draft: "#9e9e9e", // Grey
  Approved: "#4caf50", // Green
  Rejected: "#f44336", // Red
  "Partially Fulfilled": "#FFB74D", // Orange
  Closed: "#2196f3", // Grey
  Cancelled: "#d32f2f", // Dark Red,
  Unpaid: "#1E3A8A", // Orange,
  "Partially Paid": "#FFB74D", //Light Orange
  "Fully Paid": "#4CAF50", // Green
  IN: "#4CAF50", // Green
  OUT: "#f44336", // Green
  Active: "#4caf50", // Green
  Inactive: "#f44336", // Red
};

// Lighten color utility function
const lightenColor = (color, percent) => {
  const num = parseInt(color.slice(1), 16);
  const r = (num >> 16) + percent;
  const b = ((num >> 8) & 0x00ff) + percent;
  const g = (num & 0x0000ff) + percent;
  return (
    "#" +
    (
      0x1000000 +
      (r < 255 ? r : 255) * 0x10000 +
      (b < 255 ? b : 255) * 0x100 +
      (g < 255 ? g : 255)
    )
      .toString(16)
      .slice(1)
  );
};

const StatusChip = ({ status, size = 0.7, chipSize='', icon=false }) => {
  let statusText = status;
  const textColor = statusColors[statusText];
  const backColor = textColor
    ? lightenColor(textColor, statusText === "Draft" ? 85 : 160)
    : "#fff";

  return (
    <div style={{ transform: chipSize !== '' && `scale(${chipSize})` }}>
      <Chip
        label={statusText}
        icon={icon ? statusIcons(size)[statusText] : false}
        variant="outlined"
        size="small"
        sx={{
          fontSize: mediumScreen() ? 9 : 9,
          fontFamily: "Poppins",
          backgroundColor: backColor,
          borderColor: textColor,
          color: textColor,
          fontWeight: 500,
          padding: 0,
          margin: 0,
          height: 12,
          transform: `scale(0.95)`,
          "& .MuiChip-icon": {
            color: textColor,
          },
          "& .MuiChip-label": {
            paddingLeft: 1, // Remove extra padding on the left
          },
        }}
      />
    </div>
  );
};

export default StatusChip;