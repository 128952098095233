import React from "react";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CssTextField } from "../styles/StyledComponents";
import { primary } from "../styles/colors";
import { Box, Typography } from "@mui/material";
import moment from "moment";

const CustomToolbar = (props) => {
  console.log("Tooltip", props);
  const { parsedValue } = props;
  return (
    <Box
      sx={{
        backgroundColor: primary, // Orange background
        padding: "16px",
        color: "#fff",
        textAlign: "flex-start",
        fontFamily: "Poppins",
      }}
    >
      <Typography variant="body1" style={{ fontFamily: "Poppins" }}>
        {parsedValue ? moment(parsedValue).format("YYYY") : moment().format("YYYY")}
      </Typography>
      <Typography variant="h4" style={{ fontFamily: "Poppins" }}>
        {parsedValue
          ? moment(parsedValue).format("ddd, MMM DD")
          : "Select Date"}
      </Typography>
    </Box>
  );
};

const MuiDatePicker = (props) => {
  const { value, onChange, width } = props;
  return (
    <div style={props.style}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          {...props}
          showToolbar
          label={props.label}
          value={value || ""}
          onChange={onChange}
          inputFormat="dd MMM yyyy"
          ToolbarComponent={(p) => CustomToolbar(p)} // Custom toolbar component
          renderDay={(day, selectedDays, pickersDayProps) => {
            const isSelected =
              pickersDayProps.selected &&
              selectedDays[0] &&
              day.getDate() === selectedDays[0].getDate() &&
              day.getMonth() === selectedDays[0].getMonth() &&
              day.getFullYear() === selectedDays[0].getFullYear();

            return (
              <PickersDay
                {...pickersDayProps}
                style={{
                  backgroundColor: isSelected ? primary : "transparent", // Orange for selected days
                  color: isSelected ? "#ffffff" : "inherit", // White text for selected days
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  borderRadius: "50%", // Circle styling
                  margin: "0 2px",
                }}
              />
            );
          }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              required={props.required}
              error={props.error || false}
              helperText={props.helperText}
              disabled={props.disabled}
              variant={props.inputVariant || "outlined"}
              style={{ width: "100%" }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MuiDatePicker;
