import React, { useEffect } from "react";
import { connect } from "react-redux";
import PortsComponent from "./PortsComponent";
import { PortsFunction, InlandGodownFunction } from "../../redux/index";
import { useParams } from "react-router";

import { EndPoints } from "../../utils/EndPoints";
function PortsContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.PortsFunction("get", EndPoints["Ports"]);
      props.InlandGodownFunction("get", EndPoints["InlandGodown"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <PortsComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  Ports: state.modules.Ports,
  InlandGodown: state.modules.InlandGodown,
  loading: state.modules.loading,
  inlandGodownLoader: state.modules.loading[EndPoints["InlandGodown"]],
  [EndPoints["Ports"]]: state.modules[EndPoints["Ports"]],
  [EndPoints["InlandGodown"]]: state.modules[EndPoints["InlandGodown"]],
});
const mapDispatchToProps = {
  PortsFunction,
  InlandGodownFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortsContainer);
