import React, { useState, useEffect } from "react";
import {
  lightGrey,
  primary,
  white,
  green,
  red,
  yellow,
} from "../styles/colors";
import {
  CssDialog,
  StyledButton,
  StyledSearchBar,
} from "../styles/StyledComponents";
import {
  Divider,
  Popover,
  List,
  ListItem,
  ClickAwayListener,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import {
  Box,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import { BottomArrow, PlusIcon, TopArrow } from "../styles/Icons";
import { MoreHorizOutlined } from "@material-ui/icons";
import CreateDialog from "./CreateDialog";
import CreateDialogPO from "./CreateDialogPO";
import CreateDialogForSurvayReport from "./CreateDialogForSurvayReport";
import { filterData, mediumScreen } from "../Regex";
import SnackbarComponent from "./SnackbarComponent";
import TableDataHover from "./TableDataHover";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import TableComponentPagination from "./TablePagination";
import ToolTipComponent from "./ToolTipComponent";
import { CSVModuleDownload } from "./CSVModuleDownload";
import TableSearch from "./TableSearch";
import { TableUISkeleton } from "./ShowSkeleton";

const styles = {
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "0 0 0 0",
    padding: "10px",
    minWidth: 100,
  },
  withDivider50: { width: "calc(40% - 5px)" },
};

const SubListTable = (props) => {
  const { singleList, colorArr, secondLineSubHeaderData } = props;
  return (
    <TableContainer>
      <Table
        sx={{
          width: "max-content",
          minWidth: "max-content",
          tableLayout: "auto",
        }}
      >
        <TableHead>
          <TableRow>
            {props.subMenu && (
              <TableCell
                align="center"
                style={{
                  width: "23px",
                  color: "#100f0f",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  borderBottom: "none",
                  padding: "10px 16px",
                }}
              ></TableCell>
            )}
            {props.subMenuheader &&
              props.subMenuheader.map((name, i) => (
                <TableCell
                  key={`${name}-header`}
                  align="center"
                  style={{
                    textTransform: "capitalize",
                    color: "#100f0f",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
                    width:
                      i === props.subMenuheader.length - 1 ? "100%" : "auto",
                    whiteSpace: "nowrap",
                    borderBottom: "none",
                    padding: "10px 16px",
                  }}
                >
                  {name}
                </TableCell>
              ))}
            {props.subMenuAction && (
              <TableCell
                align="center"
                style={{
                  width: "50px",
                  textTransform: "capitalize",
                  color: "#100f0f",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
                  borderBottom: "none",
                  padding: "10px 16px",
                }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {singleList &&
            singleList.subList &&
            singleList.subList.length > 0 &&
            singleList.subList.map((sList, i3) => (
              <TableRow
                key={i3}
                style={{
                  backgroundColor: colorArr[i3 % colorArr.length],
                  fontFamily: "Poppins",
                }}
              >
                {props.subMenu && (
                  <TableCell
                    align="center"
                    style={{
                      width: "23px",
                      padding: "8px 16px",
                      borderBottom: "none",
                    }}
                  ></TableCell>
                )}
                {props.subMenuheader.map((name, i) => (
                  <TableCell
                    key={`${name}-${i3}`}
                    align="center"
                    style={{
                      textTransform: "capitalize",
                      width:
                        i === props.subMenuheader.length - 1 ? "100%" : "auto",
                      whiteSpace: "nowrap",
                      fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
                      fontFamily: "Poppins",
                      borderBottom: "none",
                      padding: "8px 16px",
                    }}
                  >
                    {name === "Sr.No." ? (
                      props.descending ? (
                        singleList.subList.length - i3 < 10 ? (
                          `0${singleList.subList.length - i3}`
                        ) : (
                          singleList.subList.length - i3
                        )
                      ) : i3 + 1 < 10 ? (
                        `0${i3 + 1}`
                      ) : (
                        i3 + 1
                      )
                    ) : sList[name] ? (
                      // Special condition for Paid/Unpaid etc.
                      [
                        "paid",
                        "Paid",
                        "PAID",
                        "pending",
                        "Pending",
                        "PENDING",
                        "unpaid",
                        "Unpaid",
                        "UNPAID",
                      ].includes(sList[name]) ? (
                        <div
                          style={{
                            padding: "5px",
                            backgroundColor: ["paid", "Paid", "PAID"].includes(
                              sList[name]
                            )
                              ? green
                              : ["pending", "Pending", "PENDING"].includes(
                                  sList[name]
                                )
                              ? yellow
                              : red,
                          }}
                        >
                          {sList[name]}
                        </div>
                      ) : sList[`${name}Unit`] ? (
                        sList[`${name}Unit`]
                      ) : sList[`${name}UI`] ? (
                        sList[`${name}UI`]
                      ) : typeof sList[name] === "function" ? (
                        sList[name]()
                      ) : (
                        sList[name]
                      )
                    ) : secondLineSubHeaderData &&
                      secondLineSubHeaderData[name] ? (
                      <TableDataHover
                        rowHeader={secondLineSubHeaderData[name]}
                        rowList={sList}
                        subList={true}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableComponent = (props) => {
  console.log("MODULE", props.module);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedList, setSelectedList] = useState(null);

  const [selectedListArray, setSelectedListArray] = useState([]);
  const [loader, setLoad] = useState(false);
  const [editList, setEditList] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputArr, setInputArray] = useState([
    { name: "title", type: "textField", dataType: "text" },
    // { name: "ssipluser", type: "select", dataType: "text" },
  ]);
  const [menuOpen, setmenuOpen] = useState(
    false || props?.defaultMenuOpenIndex
  );
  const [openCheckConfirmDialog, setOpenCheckConfirmDialog] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [widthList, setWidthList] = useState({});
  const [header, setHeader] = useState(["Sr.No."]);
  const [searchChecked, setSearchChecked] = useState(false);
  const [error, setError] = useState(false);

  const [fetched, setFetched] = useState(false);
  const [actions, setActions] = useState([
    {
      name: "edit",
      onClick: () => {
        setEditList(true);
      },
    },
  ]);
  const {
    module,
    customCretae,
    filterChild,
    noAddButton,
    checkbox,
    checkBoxUI,
    bottomBar,
    singleOnclick,
    checkboxConfirmation,
    addModuleToRole,
    customButton,
    removeallOption,
    noSearchBar,
    simpleTable,
    tableRowHeight,
    Title,
    minHeight,
    tablePaperWidth,
    tablePaperHeight,
    nobottomBarAction,
    bottomBarheader,
    changeUnit,
    singleUnit,
    weight,
    setSelectedListArrayError,
    setSelectedListArrayErrorSet,
    padding,
    confirmToSelect,
    confirmToSelectDenied,
    headerShownAlways,
    tableHeaderBGColor,
    footer,
    footerList,
    secondLineHeaderData,
    secondLineSubHeaderData,
    tablePagination,
    paginationData,
    pageChangeAPI,
    resetPage,
    toggleComponent,
    csvDownloadButton,
    subMenuCustomUI,
    tableSearchField,
  } = props;

  const calculateSerialNumber = (
    i,
    tableList,
    rowsPerPage,
    descending,
    totalCount,
    next
  ) => {
    const page = JSON.parse(localStorage.getItem("paginationPage"));
    const startSerial = page * rowsPerPage;

    // console.log(
    //   "HERE SERIAL",
    //   i,
    //   tableList,
    //   rowsPerPage,
    //   descending,
    //   page,
    //   startSerial,
    //   totalCount
    // );

    if (descending) {
      const totalRows = totalCount;
      const serial = totalRows - (startSerial + i);
      console.log("DECREASING SERIAL", serial);
      return serial < 10 ? `0${serial}` : serial;
    } else {
      const serial = Boolean(next)
        ? startSerial + (i + 1)
        : Number(totalCount - rowsPerPage) + Number(i + 1);
      return serial < 10 ? `0${serial}` : serial;
    }
  };

  useEffect(() => {
    if (
      selectedListArray &&
      props.selectedListArray &&
      props.selectedListArray &&
      props.selectedListArray
        .map((s) => {
          return s;
        })
        .join() !==
        selectedListArray
          .map((s) => {
            return s;
          })
          .join() &&
      fetched === false
    ) {
      setFetched(true);
      setSelectedListArray(props.selectedListArray);
    }
  }, [props.selectedListArray]);
  useEffect(() => {
    if (
      selectedListArray &&
      props.setSelectedListArray &&
      (!props.selectedListArray ||
        (props.selectedListArray &&
          props.selectedListArray
            .map((s) => {
              return s;
            })
            .join() !==
            selectedListArray
              .map((s) => {
                return s;
              })
              .join()))
    ) {
      props.setSelectedListArray(selectedListArray, props?.checkboxId);
    }
  }, [selectedListArray]);
  useEffect(() => {
    setLoad(props.loader);
  }, [props.loader]);
  useEffect(() => {
    if (setSelectedListArrayError) {
      setError(true);
    }
  }, [setSelectedListArrayError]);
  const [tableListCopy, setTableListCopy] = useState([]);
  const colorArr = [white, lightGrey];

  const longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key] && typeof it[key] === "function"
            ? 20
            : it[`${key}UIPdf`]
            ? it[`${key}UIPdf`].toString().length
            : it[key].toString().length;
        } else {
          return key.toString().length;
        }
      })
    );
  };
  const setSubmenuWidth = (subList) => {
    if (subList && subList[0] && !Array.isArray(subList[0])) {
      const widthList1 = widthList ? widthList : {};
      const array = props.subMenuheader
        ? props.subMenuheader
        : Object.keys(subList[0]);
      if (array.length > 0) {
        array.map((single, i) => {
          widthList1[`SUB${props.subMenuheader[i]}`] = `${Math.floor(
            10.5 *
              (single.toString().length > longest(single, subList)
                ? single.toString().length
                : longest(single, subList)
                ? longest(single, subList)
                : single.toString().length)
          )}px`;
          return single;
        });
      }
      setWidthList(widthList1);
    }
  };
  useEffect(() => {
    if (props.tableList && props.tableList.length > 0) {
      if (props.tableList[0] && !Array.isArray(props.tableList[0])) {
        const widthList = {}; //widthList?widthList:{}
        const array = props.header
          ? props.header
          : Object.keys(props.tableList[0]);
        array.map((single) => {
          return (widthList[single] = `${Math.floor(
            10 *
              (single.toString().length > longest(single, props.tableList)
                ? single.toString().length
                : longest(single, props.tableList)
                ? longest(single, props.tableList)
                : 1)
          )}px`);
        });
        setWidthList(widthList);
        if (props.subMenu && menuOpen !== false) {
          setSubmenuWidth(
            tableList[menuOpen] && tableList[menuOpen].subList
              ? tableList[menuOpen].subList
              : []
          );
        }
        setTableList(props.tableList ? props.tableList : []);
        setTableListCopy(props.tableList ? props.tableList : []);
        setActions(
          props.actions
            ? props.actions
            : [
                {
                  name: "edit",
                  onClick: () => {
                    setEditList(true);
                  },
                },
              ]
        );
        if (
          Object.keys(props.tableList[0]) &&
          Object.keys(props.tableList[0]).length > 0
        ) {
          const inputArr = [];
          Object.keys(props.tableList[0]).map((single) => {
            return inputArr.push({
              name: single,
              type: "textField",
              dataType: "text",
            });
          });
          setInputArray(inputArr);
        }
        setHeader(
          props.header
            ? props.header
            : props.tableList &&
              props.tableList.length > 0 &&
              props.tableList[0]
            ? Object.keys(props.tableList[0])
            : []
        );
      }
    } else {
      setTableList([]);
      setTableListCopy([]);
    }
  }, [props.tableList]);
  const selectALlCheckox = (checkedData) => {
    if (
      selectedListArray.length === tableList.length ||
      !Boolean(checkedData)
    ) {
      setSelectedListArray([]);
      props.selectedCheckBoxData && props.selectedCheckBoxData([], "all");
      props.allCheckBoxSelected && props.allCheckBoxSelected([]);
    } else {
      const pvArr1 = [];
      const condArr = [];
      tableList.map((s) => {
        console.log(
          props?.selectAllCheckBoxCond?.field,
          s,
          s[props?.selectAllCheckBoxCond?.field],
          props?.selectAllCheckBoxCond?.condition,
          s[props?.selectAllCheckBoxCond?.field] ===
            props?.selectAllCheckBoxCond?.condition
        );
        if (props?.selectAllCheckBoxCond?.field) {
          if (
            s[props?.selectAllCheckBoxCond?.field] ===
            props?.selectAllCheckBoxCond?.condition
          ) {
            condArr.push(s);
          }
        }
        // else{
        pvArr1.push(s.id);
        // }
      });

      // if(props?.selectAllCheckBoxCond?.field){
      //   setSelectedListArray(pvArr1)
      // }
      if (pvArr1.length === tableList.length) {
        setSelectedListArray(pvArr1);
        props.selectedCheckBoxData && props.selectedCheckBoxData(pvArr1, "all");
        props.allCheckBoxSelected &&
          props.allCheckBoxSelected(
            props?.selectAllCheckBoxCond?.field ? condArr : pvArr1
          );
      }
    }
  };
  const selectSingleCheckox = (
    singleList,
    singleCheckBox = false,
    checked = false
  ) => {
    console.log(singleList, checked, selectedListArray);
    props.selectedCheckBoxData &&
      props.selectedCheckBoxData(singleList, checked);
    setError(false);
    if (setSelectedListArrayErrorSet) {
      setSelectedListArrayErrorSet(false);
    }
    if (singleCheckBox) {
      setSelectedListArray([singleList.id]);
    } else {
      const currentIndex = selectedListArray.indexOf(singleList.id);
      const newChecked = [...selectedListArray];

      if (currentIndex === -1) {
        newChecked.push(singleList.id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      if (props?.selectAllCheckBoxCond?.field) {
        console.log("newChecked", newChecked);
        let condArr = [...props?.selectCheckox];
        if (condArr.some((d) => d.id === singleList.id)) {
          condArr = condArr.filter((d) => d.id !== singleList.id);
        } else {
          condArr.push(singleList);
        }
        console.log(
          props?.selectCheckox,
          condArr,
          condArr.some((d) => d.id === singleList.id),
          singleList.id
        );
        props.allCheckBoxSelected && props.allCheckBoxSelected(condArr);
      }
      setSelectedListArray(newChecked);
    }
  };

  const handleSelectCheckboxChange = (e) => {
    setSearchChecked(e.target.checked);
    toggleComponent.onChange && toggleComponent.onChange(e.target.checked);
  };

  const [successMsg, setSuccess] = useState("");
  return (
    <Paper
      style={{
        width: tablePaperWidth ? tablePaperWidth : "100%",
        height: tablePaperHeight ? tablePaperHeight : "100%",
        minHeight: minHeight ? minHeight : "",
        padding: padding ? padding || "" : "15px 0 15px 15px",
        position: "relative",
        boxShadow: "none",
      }}
      // onClick={(e) => {
      //   e.stopPropagation();
      // }}
    >
      {successMsg ? (
        <SnackbarComponent
          autoHideDuration={5000}
          success={successMsg}
          snackbarClose={() => {
            // setError(false);
            setSuccess("");
          }}
        />
      ) : (
        ""
      )}
      {openCheckConfirmDialog !== false && (
        <CssDialog
          noReset={true}
          height="240px"
          width="240px"
          header={`Confirmation`}
          addButtonWidth="75px"
          addButton={
            (openCheckConfirmDialog,
            openCheckConfirmDialog === true
              ? (tableList.length === selectedListArray.length) === true
                ? "Remove"
                : "Add"
              : (selectedListArray.indexOf(openCheckConfirmDialog.id) !==
                  -1) ===
                true
              ? "Remove"
              : "Add")
          }
          onClose={() => {
            setOpenCheckConfirmDialog(false);
          }}
          snackbarClose={() => {
            setOpenCheckConfirmDialog(false);
          }}
          onSubmit={() => {
            // if (openCheckConfirmDialog === true) {
            //   selectALlCheckox();
            // } else {
            //   selectSingleCheckox(openCheckConfirmDialog);
            // }
            if (addModuleToRole) {
              addModuleToRole(
                openCheckConfirmDialog === true ? null : openCheckConfirmDialog,
                openCheckConfirmDialog === true
                  ? tableList.length === selectedListArray.length
                  : selectedListArray.indexOf(openCheckConfirmDialog.id) !== -1
              );
            }
            setOpenCheckConfirmDialog(false);
          }}
          children={() => {
            return checkboxConfirmation
              ? checkboxConfirmation(
                  openCheckConfirmDialog,
                  openCheckConfirmDialog === true
                    ? tableList.length === selectedListArray.length
                    : selectedListArray.indexOf(openCheckConfirmDialog.id) !==
                        -1
                )
              : "";
          }}
        />
      )}
      {openDialog === true || editList ? (
        customCretae ? (
          customCretae === "survay report" ? (
            <CreateDialogForSurvayReport
              {...props}
              inputArray={props.inputArray ? props.inputArray : inputArr}
              editList={editList}
              selectedList={selectedList === null ? false : selectedList}
              handleClose={() => {
                setOpenDialog(false);
                setEditList(false);
              }}
              fetchAgain={(res, msg) => {
                setSuccess(msg);
                setOpenDialog(false);
                setEditList(false);
                if (props.fetchAgain) {
                  props.fetchAgain(res);
                }
              }}
              postData={props.postData}
            />
          ) : (
            <CreateDialogPO
              {...props}
              inputArray={props.inputArray ? props.inputArray : inputArr}
              editList={editList}
              selectedList={selectedList === null ? false : selectedList}
              handleClose={() => {
                setOpenDialog(false);
                setEditList(false);
              }}
              fetchAgain={(res, msg) => {
                setOpenDialog(false);
                setEditList(false);
                if (props.fetchAgain) {
                  props.fetchAgain(res);
                }
                setSuccess(msg);
              }}
              postData={props.postData}
            />
          )
        ) : (
          <CreateDialog
            {...props}
            inputArray={props.inputArray ? props.inputArray : inputArr}
            editList={editList}
            selectedList={selectedList === null ? false : selectedList}
            handleClose={() => {
              props.handleClose && props.handleClose();
              setOpenDialog(false);
              setEditList(false);
            }}
            fetchAgain={(res, msg) => {
              setOpenDialog(false);
              setEditList(false);

              if (props.fetchAgain) {
                props.fetchAgain(res);
              }
              setSuccess(msg);
            }}
            postData={props.postData}
          />
        )
      ) : (
        ""
      )}{" "}
      <Popover
        open={openPopover}
        // onRequestClose={() => {
        //   setOpenPopover(false);
        //   setAnchorEl(null);
        // }}
        anchorEl={anchorEl}
        style={{ marginTop: "-2.5%" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpenPopover(false);
            setAnchorEl(null);
          }}
        >
          <List>
            {actions &&
              actions.length > 0 &&
              actions?.map((singleAction) => (
                <ListItemButton
                  key={`${singleAction.name}i`}
                  // button
                  onClick={singleAction.onClick}
                  style={styles.listItemsStyle}
                >
                  {singleAction.name.toUpperCase() === "EDIT" ? (
                    <EditOutlinedIcon />
                  ) : null}
                  <span
                    style={{ textTransform: "capitalize", paddingLeft: 16 }}
                  >
                    {singleAction.name}
                  </span>
                  {/* <p
                    className="d-flex alignC textC"
                    style={{ margin: "0", fontSize: "0.9rem", height: "24px", width: '100%' }}
                  >
                    {singleAction.name}
                  </p> */}
                </ListItemButton>
              ))}
          </List>
        </ClickAwayListener>
      </Popover>
      <>
        {noSearchBar ? (
          ""
        ) : (
          <div className="d-flex alignC">
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              {" "}
              {Title ? (
                <div
                  className="widthFC fontWeight400 alignC d-flex"
                  style={{ fontSize: mediumScreen() ? "0.9rem" : "20px" }}
                >
                  {Title}
                </div>
              ) : (
                ""
              )}
              <div className="d-flex alignC">
                {Boolean(tableSearchField?.visible) ? (
                  <TableSearch tableSearchField={tableSearchField} />
                ) : (
                  <StyledSearchBar
                    padding={"12px"}
                    bgC={"#F2F2F2"}
                    fieldName={
                      props.searchFiled
                        ? props.searchFiled
                        : props.header
                        ? props.header
                        : Object.keys(props.tableList[0])
                        ? Object.keys(props.tableList[0])
                        : ["id"]
                    }
                    copyData={tableListCopy}
                    filteredData={(filterNotify) => {
                      setTableList(filterNotify);
                    }}
                    onClear={() => {
                      setTableList(tableListCopy);
                    }}
                    placeholder={`Search ${module}`}
                  />
                )}
                {props?.headerSearchMiddleComp && (
                  <span style={{ marginLeft: "20px" }}>
                    {props.headerSearchMiddleComp()}
                  </span>
                )}
              </div>
              {toggleComponent?.display ? (
                //<FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size={"small"}
                      checked={searchChecked}
                      onChange={handleSelectCheckboxChange}
                      color="warning"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={toggleComponent?.label}
                />
              ) : (
                //</FormGroup>
                ""
              )}
              {Title ? (
                ""
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {filterChild ? filterChild() : ""}
                  {noAddButton ? (
                    ""
                  ) : (
                    <ToolTipComponent
                      title="PO Status is DRAFT"
                      condition={props?.addButtonDisabled}
                    >
                      <Button
                        onClick={() => {
                          if (
                            props.validateAddButton &&
                            props.validateAddButton() === "false"
                          ) {
                          } else {
                            setOpenDialog(true);
                            setSelectedList(null);
                            if (props.onAdd) {
                              props.onAdd();
                            }
                            if (props.reset) {
                              props.reset();
                            }
                          }
                        }}
                        disabled={props?.addButtonDisabled}
                        style={{
                          background: props?.addButtonDisabled
                            ? "rgba(0,0,0,0.3)"
                            : "rgb(241 154 48)",
                          color: props?.addButtonDisabled
                            ? "rgba(0,0,0,0.2)"
                            : "white",
                          textTransform: "capitalize",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PlusIcon />
                        </div>
                        Add New {module}
                      </Button>
                    </ToolTipComponent>
                  )}
                </div>
              )}
            </div>
            {console.log("csvDownloadButton", csvDownloadButton)}
            {csvDownloadButton && csvDownloadButton?.visible ? (
              <div>
                <CSVModuleDownload data={csvDownloadButton} />{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {(tableList && tableList?.length > 0) || headerShownAlways ? (
          <TableContainer
            component={Paper}
            className="scrollBluePrimary"
            style={{
              height: props?.tableHeight
                ? props.tableHeight
                : noSearchBar
                ? "calc(100% - 20px)"
                : tablePagination
                ? "calc(100% - 104px)"
                : "calc(100% - 58px)",
              overflowX: "auto",
              margin: simpleTable ? 0 : "20px 0 0 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Table
              sx={{ width: "100%", flexGrow: 1 }}
              stickyHeader
              aria-label="simple table"
            >
              <>
                <TableHead
                  style={{
                    backgroundColor: tableHeaderBGColor || "#cfcfcf",
                  }}
                >
                  <TableRow>
                    {props.subMenu && (
                      <TableCell
                        style={{
                          width: "20px",
                          backgroundColor: tableHeaderBGColor || "#cfcfcf",
                          // padding: '4px 8px'
                        }}
                      />
                    )}
                    {checkBoxUI?.isvisible ? (
                      <TableCell
                        style={{
                          width: "20px",
                          padding: "8px 0px",
                          backgroundColor: tableHeaderBGColor || "#cfcfcf",
                        }}
                      >
                        {checkBoxUI?.header}
                      </TableCell>
                    ) : checkbox ? (
                      <TableCell
                        style={{
                          width: "20px",
                          padding: "8px 0px",
                          backgroundColor: tableHeaderBGColor || "#cfcfcf",
                        }}
                      >
                        {!Boolean(props?.singleCheckBox) ? (
                          removeallOption ? (
                            ""
                          ) : error ? (
                            <Checkbox
                              style={{
                                border: "1px solid red",
                              }}
                              onChange={() => {
                                if (
                                  (confirmToSelect &&
                                    confirmToSelect() === "success") ||
                                  !confirmToSelect
                                ) {
                                  if (setSelectedListArrayErrorSet) {
                                    setSelectedListArrayErrorSet(false);
                                  }
                                  setError(false);
                                  if (checkboxConfirmation) {
                                    setOpenCheckConfirmDialog(true);
                                  } else {
                                    selectALlCheckox();
                                  }
                                } else {
                                  if (confirmToSelectDenied) {
                                    confirmToSelectDenied();
                                  }
                                }
                              }}
                              checked={
                                tableList.length === selectedListArray.length
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": "head" }}
                            />
                          ) : (
                            <Checkbox
                              onChange={(e) => {
                                if (
                                  (confirmToSelect &&
                                    confirmToSelect() === "success") ||
                                  !confirmToSelect
                                ) {
                                  if (setSelectedListArrayErrorSet) {
                                    setSelectedListArrayErrorSet(false);
                                  }
                                  setError(false);
                                  if (checkboxConfirmation) {
                                    setOpenCheckConfirmDialog(true);
                                  } else {
                                    selectALlCheckox(e.target.checked);
                                  }
                                } else {
                                  if (confirmToSelectDenied) {
                                    confirmToSelectDenied();
                                  }
                                }
                              }}
                              checked={
                                tableList.length === selectedListArray.length
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": "head" }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {console.log("HEADER", header)}
                    {(headerShownAlways ? props.header : header).map(
                      (column, i) => (
                        <TableCell
                          style={{
                            backgroundColor: tableHeaderBGColor || "#cfcfcf",
                            width: "auto",
                            fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
                            whiteSpace: "nowrap",
                            fontFamily: "Poppins",
                            textTransform: "capitalize",
                            padding:
                              column === "Sr.No." ? "12px 6px" : "12px 8px",
                          }}
                          key={column}
                          align="center"
                        >
                          {column}
                        </TableCell>
                      )
                    )}
                    {actions && actions.length > 0 && (
                      <TableCell
                        style={{
                          width: "auto",
                          backgroundColor: tableHeaderBGColor || "#cfcfcf",
                          // padding: '16px 2px'
                        }}
                        align="center"
                      >
                        Action
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {props.loadingTableData ? (
                  <div
                    style={{ position: "absolute", left: "35vw", top: "30vh" }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                    headerShownAlways
                      ? tableList && tableList?.length > 0
                      : true
                  ) ? (
                  <>
                    <TableBody style={{ backgroundColor: "#fbfcfe" }}>
                      {tableList.map((singleList, index) => {
                        return 1 > 2 ? (
                          <TableUISkeleton
                            tileLength={10}
                            noOfCell={header.length}
                          />
                        ) : (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                cursor: "pointer",
                                height: tableRowHeight || 60,
                              }}
                            >
                              {props.subMenu && (
                                <TableCell
                                  key={index + "menu"}
                                  style={{
                                    width: "20px",
                                    padding: "8px 2px",
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      transform: mediumScreen()
                                        ? "scale(0.8)"
                                        : "scale(0.9)",
                                    }}
                                    onClick={() => {
                                      if (index !== menuOpen) {
                                        if (props.fetchSubmenuData) {
                                          props.fetchSubmenuData(singleList);
                                        }
                                      }
                                      setSubmenuWidth(singleList.subList);
                                      setmenuOpen(
                                        menuOpen === false
                                          ? index
                                          : index !== menuOpen
                                          ? index
                                          : false
                                      );
                                    }}
                                    size="small"
                                  >
                                    {menuOpen === index ? (
                                      <TopArrow color={"rgba(0, 0, 0, 0.87)"} />
                                    ) : (
                                      <BottomArrow
                                        color={"rgba(0, 0, 0, 0.87)"}
                                      />
                                    )}
                                  </IconButton>
                                </TableCell>
                              )}
                              {checkbox ? (
                                <TableCell
                                  key={index + "menu"}
                                  style={{
                                    width: "20px",
                                    padding: "8px 0px",
                                  }}
                                >
                                  {error === true ? (
                                    <Checkbox
                                      style={{
                                        border: "1px solid red",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          (confirmToSelect &&
                                            confirmToSelect(singleList) ===
                                              "success") ||
                                          !confirmToSelect
                                        ) {
                                          if (checkboxConfirmation) {
                                            setOpenCheckConfirmDialog(
                                              singleList
                                            );
                                          } else {
                                            selectSingleCheckox(singleList);
                                          }
                                        } else {
                                          if (confirmToSelectDenied) {
                                            confirmToSelectDenied(singleList);
                                          }
                                        }
                                      }}
                                      checked={
                                        selectedListArray.indexOf(
                                          singleList.id
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ "aria-labelledby": "head" }}
                                    />
                                  ) : props?.selectAllCheckBoxCond?.field &&
                                    singleList[
                                      props?.selectAllCheckBoxCond?.field
                                    ] !==
                                      props?.selectAllCheckBoxCond
                                        ?.condition ? (
                                    <Checkbox
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ "aria-labelledby": "head" }}
                                      disabled
                                    />
                                  ) : (
                                    <Checkbox
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          (confirmToSelect &&
                                            confirmToSelect(singleList) ===
                                              "success") ||
                                          !confirmToSelect
                                        ) {
                                          if (checkboxConfirmation) {
                                            setOpenCheckConfirmDialog(
                                              singleList
                                            );
                                          } else {
                                            selectSingleCheckox(
                                              singleList,
                                              props?.singleCheckBox,
                                              e.target.checked
                                            );
                                          }
                                        } else {
                                          if (confirmToSelectDenied) {
                                            confirmToSelectDenied(singleList);
                                          }
                                        }
                                      }}
                                      checked={
                                        selectedListArray.indexOf(
                                          singleList.id
                                        ) !== -1
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ "aria-labelledby": "head" }}
                                    />
                                  )}
                                </TableCell>
                              ) : checkBoxUI?.isvisible ? (
                                <TableCell
                                  key={index + "menu"}
                                  style={{
                                    width: "20px",
                                    padding: "8px 0px",
                                  }}
                                >
                                  {checkBoxUI?.component(singleList)}
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {header.map((name, i) => (
                                <TableCell
                                  key={name + i + index}
                                  style={{
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                    fontSize: mediumScreen()
                                      ? "0.7rem"
                                      : "0.8rem",
                                    backgroundColor: "#fbfcfe",
                                    fontFamily: "Poppins",
                                    padding:
                                      name === "Sr.No." ? "8px 4px" : "8px 8px",
                                    textTransform:
                                      name === "Email"
                                        ? "lowercase"
                                        : "capitalize",
                                  }}
                                  align="center"
                                  onClick={() => {
                                    if (singleOnclick) {
                                      singleOnclick(singleList);
                                    }
                                  }}
                                >
                                  {secondLineHeaderData &&
                                  secondLineHeaderData[name] ? (
                                    <span
                                      className="d-flex d-flex-column alignC"
                                      style={{ width: "100%" }}
                                    >
                                      <span className="d-flex alignC">
                                        {name === "Sr.No." &&
                                        paginationData?.count ? (
                                          calculateSerialNumber(
                                            index,
                                            tableList,
                                            paginationData?.count,
                                            props.descending,
                                            paginationData?.total_count,
                                            paginationData?.next
                                          )
                                        ) : name === "Sr.No." &&
                                          !Boolean(paginationData?.count) ? (
                                          calculateSerialNumber(
                                            index,
                                            tableList,
                                            tableList.length,
                                            props.descending,
                                            tableList.length,
                                            paginationData?.next
                                          )
                                        ) : (singleList && singleList[name]) ||
                                          singleList[name] === 0 ? (
                                          typeof singleList[name] ===
                                          "object" ? (
                                            singleList[name] &&
                                            singleList[name].length &&
                                            singleList[name].length >
                                              singleList[name]
                                                .map((sName) => {
                                                  return sName[
                                                    singleList[`${name}Join`]
                                                      ? singleList[
                                                          `${name}Join`
                                                        ]
                                                      : ""
                                                  ];
                                                })
                                                .join(" ,")
                                          ) : singleList[name] === "paid" ||
                                            singleList[name] === "Paid" ||
                                            singleList[name] === "PAID" ||
                                            singleList[name] === "pending" ||
                                            singleList[name] === "Pending" ||
                                            singleList[name] === "PENDING" ||
                                            singleList[name] === "unpaid" ||
                                            singleList[name] === "UNPAID" ||
                                            singleList[name] === "Unpaid" ? (
                                            <div
                                              style={{
                                                padding: "2px 12px",
                                                background:
                                                  singleList[name] === "paid" ||
                                                  singleList[name] === "PAID" ||
                                                  singleList[name] === "Paid"
                                                    ? green
                                                    : singleList[name] ===
                                                        "pending" ||
                                                      singleList[name] ===
                                                        "Pending" ||
                                                      singleList[name] ===
                                                        "PENDING"
                                                    ? yellow
                                                    : red,
                                                borderRadius: "16px",
                                                color: "white",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {singleList[name]}
                                            </div>
                                          ) : singleList[`${name}Unit`] ? (
                                            singleList[`${name}Unit`]
                                          ) : singleList[`${name}UI`] ? (
                                            typeof singleList[`${name}UI`] ===
                                            "function" ? (
                                              singleList[`${name}UI`]()
                                            ) : (
                                              singleList[`${name}UI`]
                                            )
                                          ) : typeof singleList[name] ===
                                            "function" ? (
                                            singleList[name]()
                                          ) : (
                                            singleList[name]
                                          )
                                        ) : null}
                                        {secondLineHeaderData &&
                                          secondLineHeaderData[name] &&
                                          secondLineHeaderData[name]
                                            .secondLiner === "" && (
                                            <TableDataHover
                                              rowHeader={
                                                secondLineHeaderData[name]
                                              }
                                              rowList={singleList}
                                            />
                                          )}
                                      </span>
                                      {secondLineHeaderData &&
                                        secondLineHeaderData[name] &&
                                        secondLineHeaderData[name]
                                          .secondLiner !== "" && (
                                          <TableDataHover
                                            rowHeader={
                                              secondLineHeaderData[name]
                                            }
                                            rowList={singleList}
                                          />
                                        )}
                                    </span>
                                  ) : name === "Sr.No." &&
                                    paginationData?.count ? (
                                    calculateSerialNumber(
                                      index,
                                      tableList,
                                      paginationData?.count,
                                      props.descending,
                                      paginationData?.total_count,
                                      paginationData?.next
                                    )
                                  ) : name === "Sr.No." &&
                                    !Boolean(paginationData?.count) ? (
                                    calculateSerialNumber(
                                      index,
                                      tableList,
                                      tableList.length,
                                      props.descending,
                                      tableList.length,
                                      paginationData?.next
                                    )
                                  ) : (singleList && singleList[name]) ||
                                    singleList[name] === 0 ? (
                                    typeof singleList[name] === "object" ? (
                                      singleList[name] &&
                                      singleList[name].length &&
                                      singleList[name].length >
                                        singleList[name]
                                          .map((sName) => {
                                            return sName[
                                              singleList[`${name}Join`]
                                                ? singleList[`${name}Join`]
                                                : ""
                                            ];
                                          })
                                          .join(" ,")
                                    ) : singleList[name] === "paid" ||
                                      singleList[name] === "Paid" ||
                                      singleList[name] === "PAID" ||
                                      singleList[name] === "pending" ||
                                      singleList[name] === "Pending" ||
                                      singleList[name] === "PENDING" ||
                                      singleList[name] === "unpaid" ||
                                      singleList[name] === "UNPAID" ||
                                      singleList[name] === "Unpaid" ? (
                                      <div
                                        style={{
                                          padding: "2px 12px",
                                          background:
                                            singleList[name] === "paid" ||
                                            singleList[name] === "PAID" ||
                                            singleList[name] === "Paid"
                                              ? green
                                              : singleList[name] ===
                                                  "pending" ||
                                                singleList[name] ===
                                                  "Pending" ||
                                                singleList[name] === "PENDING"
                                              ? yellow
                                              : red,
                                          borderRadius: "16px",
                                          color: "white",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {singleList[name]}
                                      </div>
                                    ) : singleList[`${name}Unit`] ? (
                                      singleList[`${name}Unit`]
                                    ) : singleList[`${name}UI`] ? (
                                      typeof singleList[`${name}UI`] ===
                                      "function" ? (
                                        singleList[`${name}UI`]()
                                      ) : (
                                        singleList[`${name}UI`]
                                      )
                                    ) : typeof singleList[name] ===
                                      "function" ? (
                                      singleList[name]()
                                    ) : (
                                      singleList[name]
                                    )
                                  ) : null}
                                </TableCell>
                              ))}
                              {singleList?.action ? (
                                singleList.action === "true" ? (
                                  <TableCell
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpenPopover(true);
                                      setAnchorEl(e.currentTarget);
                                      setSelectedList(singleList);
                                      if (props.setSelectedList) {
                                        props.setSelectedList(singleList);
                                      }
                                    }}
                                    style={{
                                      width: "auto",
                                      padding: "8px 2px",
                                    }}
                                    align="center"
                                  >
                                    <MoreHorizOutlined />
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{
                                      width: "10px",
                                      padding: "8px 0px",
                                    }}
                                    align="center"
                                  >
                                    -
                                  </TableCell>
                                )
                              ) : (
                                actions &&
                                actions.length > 0 && (
                                  <TableCell
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpenPopover(true);
                                      setAnchorEl(e.currentTarget);
                                      setSelectedList(singleList);
                                      if (props.setSelectedList) {
                                        props.setSelectedList(singleList);
                                      }
                                    }}
                                    style={{
                                      width: "auto",
                                      padding: "8px 2px",
                                    }}
                                    align="center"
                                  >
                                    <MoreHorizOutlined />
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                            <TableRow
                              style={{
                                height:
                                  index === tableList.length - 1 ? "100%" : 0,
                                verticalAlign:
                                  index === tableList.length - 1
                                    ? "baseline"
                                    : "middle",
                              }}
                            >
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={header.length + 1}
                              >
                                {/* {console.log(index, menuOpen)} */}
                                <Collapse
                                  in={menuOpen === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box sx={{ margin: 1 }}>
                                    {subMenuCustomUI?.visible ? (
                                      subMenuCustomUI?.component(singleList)
                                    ) : singleList &&
                                      singleList.subList &&
                                      singleList.subList.length > 0 ? (
                                      <SubListTable
                                        {...props}
                                        singleList={singleList}
                                        colorArr={colorArr}
                                        secondLineSubHeaderData={
                                          secondLineSubHeaderData
                                        }
                                      />
                                    ) : (
                                      <div className="d-flex width d-flex-column">
                                        <div
                                          style={{
                                            color: primary,
                                            borderColor: primary,
                                            justifyContent: "center",
                                            width: "fit-content",
                                            minWidth: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            height: "34px",
                                            minHeight: "34px",
                                            overflow: "hidden",
                                            borderRadius: "7px 7px 0 0",
                                          }}
                                        >
                                          No List added!
                                        </div>
                                      </div>
                                    )}
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                    {footer && (
                      <TableFooter>
                        <TableRow
                          style={{
                            position: "sticky",
                            bottom: -1,
                            backgroundColor: "white",
                            zIndex: 1000,
                            height: tableRowHeight || "inherit",
                          }}
                        >
                          {footer ? (
                            <>
                              {props.subMenu && (
                                <TableCell
                                  style={{
                                    width: "auto",
                                    fontSize: "0.8rem",
                                    whiteSpace: "nowrap",
                                    fontFamily: "Poppins",
                                    textTransform: "capitalize",
                                    color: "inherit",
                                    borderTop: "1px solid #333",
                                    // padding: '4px 8px'
                                  }}
                                />
                              )}
                              {checkBoxUI?.isvisible ? (
                                <TableCell
                                  style={{
                                    width: "20px",
                                    padding: "8px 0px",
                                    borderTop: "1px solid #333",
                                  }}
                                >
                                  {checkBoxUI?.footer}
                                </TableCell>
                              ) : (
                                checkbox && (
                                  <TableCell
                                    style={{
                                      width: "20px",
                                      borderTop: "1px solid #333",
                                      padding: "8px 0px",
                                    }}
                                  />
                                )
                              )}
                              {header &&
                                header.map((name) => (
                                  <TableCell
                                    style={{
                                      width: "auto",
                                      fontSize: "0.8rem",
                                      whiteSpace: "nowrap",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                      color: "inherit",
                                      borderTop: "1px solid #333",
                                      // padding: '4px 8px'
                                    }}
                                    key={name}
                                    align="center"
                                  >
                                    {footerList && footerList[name]
                                      ? footerList[name]
                                      : ""}
                                  </TableCell>
                                ))}
                              {actions && actions.length > 0 && (
                                <TableCell
                                  align="center"
                                  style={{
                                    width: "auto",
                                    borderTop: "1px solid #333",
                                    // padding: '16px 2px'
                                  }}
                                />
                              )}
                            </>
                          ) : null}
                        </TableRow>
                      </TableFooter>
                    )}
                  </>
                ) : null}
              </>
            </Table>
          </TableContainer>
        ) : (
          <div
            className="d-flex width-100  alignC justifyC d-flex-column"
            style={{ height: "75%" }}
          >
            <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
              No {module} yet !
            </span>
          </div>
        )}
        {headerShownAlways &&
          tableList?.length <= 0 &&
          (props?.loaderUI?.visible ? (
            props.loaderUI.comp()
          ) : (
            <div
              className="d-flex width-100  alignC justifyC d-flex-column"
              style={{ height: "75%", borderBottom: "1px solid #333" }}
            >
              <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                No {module} added !
              </span>
            </div>
          ))}
        {bottomBar === true && tableList && tableList.length > 0 && (
          <div
            className={`${nobottomBarAction ? "dividerBorder" : ""}`}
            style={{
              backgroundColor: nobottomBarAction
                ? ""
                : "rgb(158 158 158 / 48%)",

              color: primary,
              width: "100%",
              minWidth: "100%",
              tableLayout: "fixed",
              display: "flex",
              alignItems: "center",
              height: "40px",
              minHeight: "40px",
              overflow: "hidden",
              borderRadius: "0 0 7px 7px",
              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
              fontWeight: "500",
            }}
          >
            <div
              className="d-flex alignC width-100"
              style={{
                justifyContent: nobottomBarAction ? "space-evenly" : "flex-end",
              }}
            >
              {nobottomBarAction ? (
                <>
                  {" "}
                  {checkbox && (
                    <div
                      className="d-flex alignC justifyC"
                      style={{
                        margin: "12px 10px",
                        width: "30px",
                        textTransform: "capitalize",
                        minWidth: "30px",
                      }}
                    ></div>
                  )}
                  {header &&
                    header.map((name) => (
                      <div
                        key={`${name}2`}
                        className="d-flex alignC justifyC"
                        style={{
                          margin: "12px 10px",
                          width: widthList[name]
                            ? widthList[name]
                            : "fit-content",
                          textTransform:
                            name === "Email" ? "lowercase" : "capitalize",
                          color:
                            bottomBarheader &&
                            ((bottomBarheader.includes(name) &&
                              name === "Total Quantity") ||
                              (bottomBarheader.includes(name) &&
                                name === "Shipped Quantity"))
                              ? checkbox
                                ? selectedListArray.length > 0 && weight
                                  ? selectedListArray
                                      .map((s) => {
                                        return filterData(
                                          tableList,
                                          "id",
                                          Number(s),
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              tableList,
                                              "id",
                                              Number(s),
                                              "onlyOne"
                                            )
                                          : "";
                                      })
                                      .reduce(
                                        (previousValue, currentValue) =>
                                          Number(previousValue) +
                                          Number(
                                            currentValue[name]
                                              ? changeUnit &&
                                                (name === "Total Quantity" ||
                                                  name ===
                                                    "Shipped Quantity") &&
                                                currentValue.unit
                                                ? changeUnit(
                                                    currentValue[name],
                                                    currentValue.unit
                                                  )
                                                : currentValue[name]
                                              : 0
                                          ),
                                        0
                                      ) > weight
                                    ? "#FF3D00"
                                    : ""
                                  : ""
                                : tableList.reduce(
                                    (previousValue, currentValue) =>
                                      Number(previousValue) +
                                      Number(
                                        currentValue[name]
                                          ? changeUnit &&
                                            (name === "Total Quantity" ||
                                              name === "Shipped Quantity") &&
                                            currentValue.unit
                                            ? changeUnit(
                                                currentValue[name],
                                                currentValue.unit
                                              )
                                            : currentValue[name]
                                          : 0
                                      ),
                                    0
                                  ) > weight
                                ? "#FF3D00"
                                : ""
                              : "",
                        }}
                      >
                        {bottomBarheader && bottomBarheader.includes(name)
                          ? checkbox
                            ? selectedListArray.length > 0 && weight
                              ? `${selectedListArray
                                  .map((s) => {
                                    return filterData(
                                      tableList,
                                      "id",
                                      Number(s),
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          tableList,
                                          "id",
                                          Number(s),
                                          "onlyOne"
                                        )
                                      : "";
                                  })
                                  .reduce(
                                    (previousValue, currentValue) =>
                                      Number(previousValue) +
                                      Number(
                                        currentValue[name]
                                          ? changeUnit &&
                                            (name === "Total Quantity" ||
                                              name === "Shipped Quantity") &&
                                            currentValue.unit
                                            ? changeUnit(
                                                currentValue[name],
                                                currentValue.unit
                                              )
                                            : currentValue[name]
                                          : 0
                                      ),
                                    0
                                  )} ${
                                  name === "Total Quantity" ||
                                  name === "Shipped Quantity"
                                    ? singleUnit
                                      ? singleUnit
                                      : ""
                                    : ""
                                }`
                              : ""
                            : `${
                                name === "Bags"
                                  ? tableList.reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) +
                                        Number(
                                          currentValue[name]
                                            ? changeUnit &&
                                              (name === "Total Quantity" ||
                                                name === "Shipped Quantity") &&
                                              currentValue.unit
                                              ? changeUnit(
                                                  currentValue[name],
                                                  currentValue.unit
                                                )
                                              : currentValue[name]
                                            : 0
                                        ),
                                      0
                                    )
                                  : tableList
                                      .reduce(
                                        (previousValue, currentValue) =>
                                          Number(previousValue) +
                                          Number(
                                            currentValue[name]
                                              ? changeUnit &&
                                                (name === "Total Quantity" ||
                                                  name ===
                                                    "Shipped Quantity") &&
                                                currentValue.unit
                                                ? changeUnit(
                                                    currentValue[name],
                                                    currentValue.unit
                                                  )
                                                : currentValue[name]
                                              : 0
                                          ),
                                        0
                                      )
                                      .toFixed(3)
                              } ${
                                name === "Total Quantity" ||
                                name === "Shipped Quantity"
                                  ? singleUnit
                                    ? singleUnit
                                    : ""
                                  : ""
                              }`
                          : ""}
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {checkbox ? (
                    <div
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "12px 10px",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}
                    >
                      {selectedListArray.length === 0
                        ? ""
                        : `Total Selected : ${selectedListArray.length}`}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  <StyledButton
                    onClick={() => {
                      if (props.onCheckSubmit) {
                        if (selectedListArray.length > 0) {
                          const arreyToSEnd = selectedListArray.map((id) => {
                            const filterData = tableList.filter(
                              (sList) => sList.id === id
                            )[0];

                            return filterData;
                          });
                          props.onCheckSubmit(arreyToSEnd).then(() => {
                            setSelectedListArray([]);
                          });
                        } else {
                          setError(true);
                        }
                      }
                    }}
                    name={props.buttonName ? props.buttonName : "Mark as Paid"}
                    withBg={true}
                    // width="130px"
                    margin="0 10px"
                    padding="2px 5px"
                  />
                </>
              )}
            </div>
          </div>
        )}
        {console.log(
          "PAG",
          props.module,
          tablePagination,
          window.location.pathname
        )}
        {tablePagination && (
          <Paper>
            <TableComponentPagination
              count={paginationData?.count || ""}
              totalCount={paginationData?.total_count || ""}
              next={paginationData?.next}
              previous={paginationData?.previous}
              pageChangeAPI={pageChangeAPI}
              resetPage={resetPage}
              module={module + window.location.pathname}
            />
          </Paper>
        )}
      </>
    </Paper>
  );
};

export default TableComponent;
