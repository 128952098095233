import { Button, IconButton, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditIcon } from "../../styles/Icons";
import { mediumScreen } from "../../Regex";
import InlandGodownAddEdit from "./InlandGodownAddEdit";
import { useSnackbar } from "../../snackbarContext";
import { Delete } from "@mui/icons-material";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";

const styles = {
  paperContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  innerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 50px",
    height: "calc(100% - 31.7px)",
  },
  header: {
    fontSize: mediumScreen() ? "0.8rem" : "18px",
    margin: "10px 0 ",
    minWidth: "200px",
  },
  content: {
    fontSize: mediumScreen() ? "0.8rem" : "18px",
    margin: "10px 0 10px 10px",
    color: "#808080",
    wordBreak: "break-all",
  },
  unloadingName: {
    fontSize: mediumScreen() ? "0.8rem" : "18px",
    margin: "0px 0 10px 0px",
    color: "#808080",
  },
};

const CommanDesign = ({ label, value }) => {
  return (
    <div className="d-flex width-100">
      <p style={styles.header}>{label} :</p>
      <p style={styles.content}>{value || ""}</p>
    </div>
  );
};

const InlandGodownDetails = (props) => {
  const [singleData, setSingleData] = useState({});
  const [editData, setEditData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const showSnackbar = useSnackbar();

  const fetchSingleListData = (id) => {
      if (id) {
        props
          .InlandGodownFunction(
            "get",
            `masters/inland-godown/${id}`,
            null,
            "inlandGodownData",
            null
          )
          .then((res) => {
            if (res.error) {
              showSnackbar(res.data, "error");
            } else {
              setSingleData(res);
            }
          });
      }
    };

  const handleEditInlandGodown = (data, operation, type) => {
    console.log("clciked Inland", data, operation, type);
    if (type === "inlandGodown") {
      let payload = {
        id: data?.id,
        name: data?.name,
        address: data?.address,
        city: data?.city,
        state: data?.state,
        is_active: data?.is_active,
      };

      props
        .InlandGodownFunction(
          "patch",
          "masters/inland-godown/",
          data?.id,
          null,
          payload,
          null,
          null,
        )
        .then((res) => {
          if (res.error) {
            showSnackbar(res.data, "error");
          } else {
            showSnackbar(`Inland Godown Updated Successfully`, "success");
            props.fetchInlandGodownList();
            fetchSingleListData(data?.id);
          }
        });
    }
  };

  const handleInlandGodownDelete = (id) => {
    props
      .InlandGodownFunction(
        "delete",
        "masters/inland-godown/",
        id,
        null,
        null,
        null,
        null,
      )
      .then((res) => {
        if (res.error) {
          showSnackbar(res.data, "error");
        } else {
          showSnackbar(`Inland Godown Deleted Successfully`, "success");
          props.fetchInlandGodownList();
        }
      });
  };

  useEffect(() => {
    console.log("openDialog", openDialog, editData);
  }, [openDialog]);

  useEffect(() => {

  }, [singleData])

  useEffect(() => {
      if (props.singleInlandGodown) {
        fetchSingleListData(props.singleInlandGodown?.id);
      }
    }, [props.singleInlandGodown]);

  console.log("singleData", singleData);

  return (
    <div>
      <Paper
        className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
        style={styles.paperContainer}
      >
        {console.log(props)}
        <div className="width-100 d-flex justifyFE">
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteDialog(true);
              }}
              style={{ cursor: "pointer" }}
              color="error"
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <ConfirmDialog
            openDialog={deleteDialog}
            setOpenDialog={(d) => setDeleteDialog(d)}
            width="400px"
            height="200px"
            header="Inland Godown"
            action="Yes"
            resetText="No"
            onSubmit={() => {
              handleInlandGodownDelete(singleData?.id);
              setDeleteDialog(false);
            }}
            content={() => (
              <div
                className="d-flex d-flex-column justifyC"
                style={{ width: "100%", padding: "2px" }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    color: "#888",
                    paddingBottom: 16,
                  }}
                >
                  {singleData?.name}
                </span>

                <span style={{ fontSize: "14px", color: "#333" }}>
                  Are you sure you want to delete this inland godown?
                </span>
              </div>
            )}
          />
          <Tooltip title="Edit">
            <Button
              onClick={() => {
                setOpenDialog(true);
                setEditData(singleData);
              }}
              style={{ cursor: "pointer", color: "inherit" }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <InlandGodownAddEdit
            {...props}
            openDialog={openDialog}
            operation="edit"
            editData={editData}
            handleInlandGodown={handleEditInlandGodown}
            closeDialog={() => {
              setOpenDialog(false);
              setEditData({});
            }}
          />
        </div>

        <div
          className="overflowYA scrollBluePrimary"
          style={styles.innerContainer}
        >
          <CommanDesign
            label="Inland Godown Name"
            value={singleData?.name}
          />
          <CommanDesign label="Address" value={singleData?.address} />
          <CommanDesign label="City" value={singleData?.city} />
          <CommanDesign label="State" value={singleData?.state} />
          <CommanDesign
            label="Unloading Agent"
            value={
              singleData &&
              singleData.inland_godown_unloading_agents &&
              singleData.inland_godown_unloading_agents.length > 0 &&
              singleData.inland_godown_unloading_agents.map((a) => (
                <p style={styles.unloadingName}>{a.name}</p>
              ))
            }
          />
        </div>
      </Paper>
    </div>
  );
};

export default InlandGodownDetails;
