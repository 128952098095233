import { Button, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { mediumScreen, StopScroll } from "../../Regex";
import CustomDialog from "../../layoutComponents/CustomDialog";
import { CssTextField } from "../../styles/StyledComponents";
import MuiDatePicker from "../../layoutComponents/MuiDatePicker";
import { useSnackbar } from "../../snackbarContext";
import moment from "moment";

const styles = {
  inputField: {
    minWidth: 230,
    width: "calc(25% - 20px)",
    margin: "10px",
  },
};

export const SuppliedBagsDialog = (props) => {
  const showSnackbar = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputData, setInputData] = useState({});
  const [error, setError] = useState({});

  const resetState = () => {
    setDialogOpen(false);
    setInputData({});
    setError({});
  };

  const TotalAmount = (data) => {
    return Number(
      Number(data.supplied_bags_amount || 0) +
        Number(data.tax_amount || 0) +
        Number(data.freight_or_vat_amount || 0)
    ).toFixed(2);
  };

  const handleInputDataChange = (key, value) => {
    let newData = { ...inputData };
    newData[key] = value;
    if (key === "supplied_bags") {
      newData["remaining_bags"] =
        Number(props.supplierDialogData.Quantity) - Number(value);
      newData["supplied_bags_amount"] = Number(
        Number(value) * Number(props.supplierDialogData.Price)
      ).toFixed(2);
      newData["total_amount"] = TotalAmount(newData);
    } else if (key === "tax_amount" || key === "freight_or_vat_amount") {
      newData["total_amount"] = TotalAmount(newData);
    }
    setInputData(newData);
    setError((prev) => ({
      ...prev,
      [key]: value ? "" : prev[key] || "Invalid Field!",
    }));
  };

  const checkValidation = () => {
    let hasError = false;
    let newError = {};

    if (!inputData.date) {
      hasError = true;
      newError.date = "Date is required";
    }

    if (!inputData.supplied_bags) {
      hasError = true;
      newError.supplied_bags = "Supplied bags is required";
    } else if (
      Number(inputData.supplied_bags) >
      Number(props.supplierDialogData.Quantity)
    ) {
      hasError = true;
      newError.supplied_bags = "Supplied bags cannot exceed total quantity";
    }

    if (!inputData.bill_date) {
      hasError = true;
      newError.bill_date = "Bill date is required";
    }

    if (!inputData.bill_no) {
      hasError = true;
      newError.bill_no = "Bill number is required";
    }

    if (!inputData.tax_amount && inputData.tax_amount !== 0) {
      hasError = true;
      newError.tax_amount = "Tax amount is required";
    }

    if (
      !inputData.freight_or_vat_amount &&
      inputData.freight_or_vat_amount !== 0
    ) {
      hasError = true;
      newError.freight_or_vat_amount = "Freight/VAT amount is required";
    }

    if (!inputData.remaining_amount && inputData.remaining_amount !== 0) {
      hasError = true;
      newError.remaining_amount = "Remaining amount is required";
    }

    if (!inputData.delivery_location) {
      hasError = true;
      newError.delivery_location = "Delivery location is required";
    }

    setError(newError);
    return hasError;
  };

  const handleSubmit = async () => {
    if (checkValidation()) return;
    else {
      const addData = {
        date: moment(inputData.date).format("YYYY-MM-DD"),
        supplied_bags: Number(inputData.supplied_bags),
        bill_date: moment(inputData.bill_date).format("YYYY-MM-DD"),
        bill_no: inputData.bill_no,
        remaining_bags: inputData.remaining_bags,
        supplied_bags_amount: inputData.supplied_bags_amount,
        tax_amount: inputData.tax_amount,
        freight_or_vat_amount: inputData.freight_or_vat_amount,
        total_amount: inputData.total_amount,
        remaining_amount: inputData.remaining_amount,
        delivery_location: inputData.delivery_location,
        is_active: true,
        bag_purchase_order: props.supplierDialogData.id,
      };

      await props
        .BrandsManagementsFunction(
          "post",
          "dispatch/bag-po-receival/list",
          null,
          "SupplierDetail",
          addData
        )
        .then((res) => {
          if (res.error) {
            showSnackbar(res.data, "error");
          } else {
            showSnackbar(`Supplier Bags Added Successfully`, "success");
            resetState();
            props.fetchBagPurchaseOrderList();
          }
        });
    }
  };

  const setInitialValue = (data) => {
    setInputData((prev) => ({
      ...prev,
      date: "",
      supplied_bags: "",
      bill_date: "",
      bill_no: "",
      remaining_bags: data.Quantity,
      supplied_bags_amount: 0,
      tax_amount: "",
      freight_or_vat_amount: "",
      total_amount: 0,
      remaining_amount: "",
      delivery_location: "",
    }));
  };

  return (
    <>
      <Button
        style={{
          cursor: "pointer",
          backgroundColor: "rgb(241 154 48)",
          color: "white",
          fontSize: mediumScreen() ? "0.7rem" : "0.75rem",
          fontFamily: "Poppins",
        }}
        size="small"
        withBg={true}
        onClick={async () => {
          setInitialValue(props.supplierDialogData);
          setDialogOpen(true);
        }}
      >
        SUPPLIER BAGS
      </Button>
      <CustomDialog
        open={dialogOpen}
        onClose={() => {
          resetState();
        }}
        maxWidth={"lg"}
        title={"Add Supplier Bag Detail"}
        resetButton="Reset"
        onResetClick={() => {
          setInitialValue(props.supplierDialogData);
        }}
        submitButton={"Add"}
        onSubmitClick={() => {
          handleSubmit();
        }}
      >
        <div className="d-flex justifyFS flexWrap">
          <MuiDatePicker
            required={true}
            style={styles.inputField}
            error={!!error?.date}
            helperText={error?.date}
            invalidLabel=""
            onKeyPress={(ev) => {
              ev.preventDefault();
            }}
            allowKeyboardControl={false}
            autoOk
            inputVariant="outlined"
            label={"Supplier Date"}
            value={inputData.date || ""}
            onChange={(newValue) => {
              handleInputDataChange("date", newValue);
            }}
          />
          <CssTextField
            required={true}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            style={styles.inputField}
            onKeyDown={(evt) => {
              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            autoComplete="off"
            error={error.supplied_bags ? true : false}
            helperText={
              error.supplied_bags
                ? "Please Add Supplier Bag!"
                : `Remaining Bags: ${
                    Number(inputData.remaining_bags) > 0
                      ? Number(inputData.remaining_bags)
                      : 0
                  }`
            }
            // id="outlined-basic"
            label="Supplier Bags"
            onFocus={StopScroll}
            variant="outlined"
            name="supplied_bags"
            id="supplier_bags"
            value={inputData.supplied_bags}
            onChange={(e) => {
              handleInputDataChange("supplied_bags", e.target.value);
            }}
          />
          <MuiDatePicker
            required={true}
            style={styles.inputField}
            error={!!error?.bill_date}
            helperText={error?.bill_date}
            invalidLabel=""
            onKeyPress={(ev) => {
              ev.preventDefault();
            }}
            allowKeyboardControl={false}
            autoOk
            inputVariant="outlined"
            label={"Bill Date"}
            value={inputData.bill_date || ""}
            onChange={(newValue) => {
              handleInputDataChange("bill_date", newValue);
            }}
          />
          <CssTextField
            required={true}
            style={styles.inputField}
            id="outlined-basic"
            label={"Bill No"}
            type="text"
            value={inputData.bill_no || ""}
            error={!!error.bill_no}
            helperText={error.bill_no}
            onChange={(e) => {
              handleInputDataChange("bill_no", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Supplied Bags Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData.supplied_bags_amount}
            helperText={`Price: ₹ ${Number(
              props.supplierDialogData.Price
            )}/Bag`}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleInputDataChange("supplied_bags_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Tax Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData.tax_amount}
            error={!!error.tax_amount}
            helperText={error.tax_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleInputDataChange("tax_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Freight/Vat Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData.freight_or_vat_amount}
            error={!!error.freight_or_vat_amount}
            helperText={error.freight_or_vat_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleInputDataChange("freight_or_vat_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Total Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData.total_amount}
            error={!!error.total_amount}
            helperText={error.total_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleInputDataChange("total_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            // disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Remaining Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData.remaining_amount}
            error={!!error.remaining_amount}
            helperText={error.remaining_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleInputDataChange("remaining_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            style={styles.inputField}
            id="outlined-basic"
            label={"Delivery Location"}
            type="text"
            value={inputData.delivery_location || ""}
            error={!!error.delivery_location}
            helperText={error.delivery_location}
            onChange={(e) => {
              handleInputDataChange("delivery_location", e.target.value);
            }}
            variant="outlined"
          />
        </div>
      </CustomDialog>
    </>
  );
};
