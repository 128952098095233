import React, { useEffect, useState } from "react";
import moment from "moment";
import TableComponent from "../../layoutComponents/TableComponent";
import { ActionContainer } from "./InlandGodownPrePostDialog";

const itemHeader = [
  {
    label: "Item Category",
    labelKey: "Item Category",
    key: "item_category",
  },
  {
    label: "Item Type",
    labelKey: "Item TypeUnit",
    key: "item_type",
  },
  {
    label: "HSN Code",
    labelKey: "HSN Code",
    key: "hsn_code",
  },
  {
    label: "Total Item Quantity",
    labelKey: "Total Item QuantityUnit",
    key: "total_item_quantity",
  },
  {
    label: "Contract Rate",
    labelKey: "Contract RateUnit",
    key: "contract_rate",
  },
];

const InlandGodownPostDispatch = (props) => {
  const [postDispatchList, setPostDispatchList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [reload, setReload] = useState(false);

  const fetchPostDispatchList = (urlText = false, type = false) => {
    props
      .InlandGodownFunction(
        "get",
        type === "pagination" ? urlText : "dispatch/list",
        null,
        "PODispatchedList",
        null,
        type === "pagination"
          ? null
          : {
              post_dispatch: "True",
              inland_godown_id: props?.singleInlandGodown?.id,
            },
        type === "pagination" ? true : false
      )
      .then((PostDispatchListData) => {
        // setPostDispatchList(PostDispatchListData?.results);
        if (PostDispatchListData && PostDispatchListData.results.length > 0) {
          if (PostDispatchListData.results) {
            setPaginationData({
              count: PostDispatchListData?.count_on_page || 0,
              total_count: PostDispatchListData?.total_count || 0,
              next: PostDispatchListData?.next || null,
              previous: PostDispatchListData?.previous || null,
            });
          }

          const blankarr = [];
          PostDispatchListData.results.map((sData) => {
            props
              .InlandGodownFunction(
                "get",
                "dispatch/dispatch-items/list",
                null,
                "dispatch-item",
                null,
                {
                  dispatch_id: sData.id,
                }
              )
              .then((res2) => {
                sData.subList = res2 ? res2 : [];
                blankarr.push(sData);
                if (PostDispatchListData.results.length === blankarr.length) {
                  setPostDispatchList(blankarr);
                }
                return sData;
              });
            return sData;
          });
        }
      });
  };

  const pageChangeAPI = (url) => {
    fetchPostDispatchList(url, "pagination");
  };

  const reloadFn = () => {
    setReload(true)
  }

  useEffect(() => {
    fetchPostDispatchList();
  }, [window.location.pathname]);

   useEffect(() => {
      if(reload) {
        setReload(false);
      }
    }, [reload]) 

  const {
    ItemCateoryList,
    ItemList,
    HSNlist,
    SurveyCompanyList,
    ContractTypeList,
  } = props;

  return (
    <>
      <TableComponent
        {...props}
        module={"Post Dispatch"}
        noAddButton={true}
        loader={false}
        actions={[]}
        header={[
          "Sr.No.",
          "Dispatch Date",
          "Action",
          "Dispatch Status",
          "Contract Type",
          "Purchase Order",
          "Truck Number",
          "Bilty Number",
          "Net Weight",
          "Freight Rate",
          "Survey Company",
          "Broker Payment",
        ]}
        secondLineHeaderData={{
                                'Dispatch Status': {
                                  secondLiner: "Dispatch To",
                                  secondLinerHover: [],
                                },
                              }}
        subMenu={true}
        subMenuheader={[
          "Sr.No.",
          "Item Category",
          "Item Type",
          "Hsn Code",
          "Contract Rate",
        ]}
        tableHeight="calc(100% - 95px)"
        tablePagination={true}
        paginationData={paginationData}
        pageChangeAPI={pageChangeAPI}
        // resetPage={resetPage}
        tableList={
          postDispatchList && postDispatchList.length
            ? postDispatchList
                .map((singleDispatch) => {
                  singleDispatch["Dispatch Date"] = moment(
                    singleDispatch.dispatch_date
                  ).format("DD MMM YYYY");
                
                  singleDispatch["Dispatch Status"] =
                    singleDispatch.dispatch_status;
                  singleDispatch["Dispatch To"] =
                    singleDispatch.dispatched_to_details.name;
                  singleDispatch["Contract Type"] = ContractTypeList.find(
                    (d) => d.id == singleDispatch.contract_type
                  )?.contract_type;
                  singleDispatch["Purchase Order"] =
                    singleDispatch.purchase_order_details?.po_number;
                  singleDispatch["Truck Number"] = singleDispatch.truck_number;
                  singleDispatch["Bilty Number"] = singleDispatch.bilty_number;
                  singleDispatch["Net Weight"] = Number(
                    singleDispatch.net_weight
                  ).toFixed(2);
                  singleDispatch["Net WeightUnit"] =
                    Number(singleDispatch.net_weight).toFixed(2) +
                    (singleDispatch?.unit || "");
                  singleDispatch["Freight Rate"] = Number(
                    singleDispatch.freight_rate
                  ).toFixed(2);
                  singleDispatch["Freight RateUnit"] =
                    singleDispatch.freight_rate
                      ? "₹ " +
                        Number(singleDispatch.freight_rate).toFixed(2) +
                        (singleDispatch?.unit || "")
                      : "";
                  singleDispatch["Survey Company"] = SurveyCompanyList?.find(
                    (d) => d.id === Number(singleDispatch.survey_company)
                  )?.name;
                  singleDispatch["Broker Payment"] =
                    singleDispatch.broker_payment;

                  singleDispatch["subList"] = singleDispatch?.subList?.map(
                    (item) => {
                      console.log("Item-", item);
                      itemHeader.map((header) => {
                        item[header.label] =
                          header.key === "item_category"
                            ? ItemCateoryList?.find(
                                (d) => d.id === Number(item[header.key])
                              )?.name || ""
                            : header.key === "item_type"
                            ? ItemList?.find(
                                (d) => d.id === Number(item[header.key])
                              )?.broken_percentage + "%"
                            : header.key === "hsn_code"
                            ? HSNlist?.find(
                                (d) => d.id === Number(item[header.key])
                              )?.hsn_code
                            : item[header.key] || "";
                      });
                      item["PackagingArr"] = item.dispatch_packaging || [];
                      return item;
                    }
                  );

                  singleDispatch["Action"] = () => {
                    return singleDispatch.is_draft ? (
                      <ActionContainer
                        {...props}
                        singleDispatch={singleDispatch}
                        operation="edit"
                        FetchNew={fetchPostDispatchList}
                        reloadFn={reloadFn}
                      />
                    ) : null;
                  };

                  return singleDispatch;
                })
                // .sort((a, b) => {
                //   var dateB = moment(b.created_at).format(
                //     "MMM D YYYY h:mm:ss A"
                //   );
                //   var dateA = moment(a.created_at).format(
                //     "MMM D YYYY h:mm:ss A"
                //   );
                //   return new Date(dateB) - new Date(dateA);
                // })
            : []
        }
      />
    </>
  );
};

export default InlandGodownPostDispatch;
