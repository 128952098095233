import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Slide, SwipeableDrawer, Tooltip } from "@mui/material";
import { primary } from "../styles/colors";
import { StyledButton } from "../styles/StyledComponents";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const styles = {
  dialogTitle: {
    background: primary,
    color: "white",
    padding: "8px 24px",
  },
  heading: {
    margin: 0,
    color: "white",
    textAlign: "left",
    fontFamily: "Poppins",
  },
  dialogContent: {
    padding: "10px 24px",
    position: "relative",

      outline: 'none'
      
  },
  drawerBox: (drawerOpen) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    background: "#f5f5f5",
    zIndex: 100,
    boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
    transform: drawerOpen ? "translateY(0)" : "translateY(-100%)",
    transition: "transform 0.3s ease-in-out",
    minHeight: 160,
  }),
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({
  open,
  onClose,
  maxWidth,
  title,
  children,
  noResetButton=false,
  resetButton,
  onResetClick,
  submitButton,
  onSubmitClick,
  drawerTopShown = false,
  submitButtonDisabled=false,
}) => {
  // maxWidth can be: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | custom

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth || "md"}
      scroll="paper"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle
        className="d-flex alignC justifySB"
        style={styles.dialogTitle}
      >
        <h5 style={styles.heading}>{title}</h5>
        <div>
          {drawerTopShown && (
            <IconButton
              style={{
                transform: "scale(0.9)",
                color: "white",
                padding: 5,
                marginRight: 20,
              }}
              onClick={() => toggleDrawer(!drawerOpen)}
            >
              {drawerOpen ? <ArrowUpward /> : <ArrowDownward />}
            </IconButton>
          )}
          <Tooltip title="close" arrow placement="top">
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{
                transform: "scale(0.9)",
                color: "white",
                padding: 5,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent
        dividers={"paper"}
        className="dashedDividerBottom width-100 d-flex d-flex-column"
        style={styles.dialogContent}
        tabIndex={0}
      >
        <Box style={styles.drawerBox(drawerOpen)}>
          <div>check SwipeableDrawer</div>
        </Box>
        {children}
      </DialogContent>
      <DialogActions
        className="d-flex justifyFE"
        style={{ padding: "10px 24px" }}
      >
        {!noResetButton && <StyledButton name={resetButton || "Reset"} onClick={onResetClick} />}
        <StyledButton
          name={submitButton || "Submit"}
          disabled={submitButtonDisabled}
          minWidth="120px"
          margin="0 10px"
          withBg={true}
          onClick={() => {
            onSubmitClick();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
