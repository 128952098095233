import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import { CrossIcon, EditIcon } from "../../styles/Icons";
import ReactDOM from "react-dom";
import moment from "moment";
import NoData from "../../styles/Illustrations/NoData";
import { withStyles } from "@material-ui/styles";
import {
  primary,
  white,
  red,
  mostLightGrey,
  lightGrey,
  lightGreen,
} from "../../styles/colors";
import { EndPoints } from "../../utils/EndPoints";
import { divide, multiply, sum, subtract, add } from "lodash";
// import {
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import {
  Divider,
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tooltip,
  Button,
  Backdrop,
  DialogContent,
  Checkbox,
  DialogTitle,
} from "@material-ui/core";
import {
  CssTextField,
  StyledButton,
  CssTextField95,
} from "../../styles/StyledComponents";
import TextField from "@mui/material/TextField";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { CheckCircle } from "@material-ui/icons";
import { filterData, mediumScreen, StopScroll } from "../../Regex";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import Details from "./Details";
import ShowSpecifications, {
  getSpecificationId,
} from "../../layoutComponents/ShowSpecifications";
import MultipleItemTable from "../../layoutComponents/MultipleItemTable";
import { Warehouse } from "@mui/icons-material";
import SailingIcon from "@mui/icons-material/Sailing";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import ShowPackagingPort from "../../layoutComponents/ShowPackagingPort";
import { SnackbarContext } from "../../snackbarContext";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import debounce from "lodash/debounce";
import { Chip } from "@mui/material";
import SimplePopover from "../../layoutComponents/SimplePopover";
import { DispatchLoosePoItemTable } from "./CustomItemTableUI";
// import DateFnsUtils from "@date-io/date-fns"; // choose your libs
// import { TopArrow, BottomArrow } from "../../styles/Icons";
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  bottomContainer: (data) => ({
    display: data?.length === 0 ? "none" : "flex",
    justifyContent: "center",
    width: "100%",
  }),
  bottomInnerContainer: (data) => ({
    // padding: '4px 8px',
    position: "absolute",
    bottom: 23,
    zIndex: 10000,
  }),
  bottomInnerContentContainer: {
    border: `2px solid ${primary}`,
    borderRadius: 10,
    // padding: '4px 8px',
    color: "#2f2f2f",
    backgroundColor: "#ffffff",
    // backgroundColor: "#c6bebe",
    // backgroundColor: "rgb(158, 158, 158)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionButtonIcons: {
    margin: " 0 8px",
    // color: '#fff',
    color: "#2f2f2f",

    "&:hover": {
      backgroundColor: "#FFF !important",
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const CssButton = withStyles({
  root: {
    "&.MuiButton-text": {
      padding: " 4px 8px",
    },
  },
})(Button);
export const CssCreateTextField = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "balck",
    },
    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled": {
      color: "black",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 5px) scale(1)",
      color: "black",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "5px",
      },
    },
  },
})(TextField);
export class PurchaseOrdersComponent extends Component {
  static contextType = SnackbarContext;
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["PurchaseOrders"],
        tooltip: `Click to approve pending ${EndPoints["PurchaseOrders"]}`,
        data: [],
        route: `/PurchaseOrders/${EndPoints["PurchaseOrders"].split("/")[0]}`,
        goBackRoute: "/PurchaseOrders",
        colorArr: [white, lightGrey],
        primaryField: "po_number",
        primarySecondValue: "total_dispatched_quantity",
        primaryFieldFallback: "total_trips",
        secondaryField: "id",
        primaryFieldThirdFallback: "total_po_quantity",
        unit: true,
        avatarField: "src",
        dispatchList: true,
        progressBar: true,
        salesList: true,
        selectedPoid: false,
        dispatchPackagingArray: [],
        pendingStatus: "approval_status",
        // decorators: {
        //   conditionField: "approval_status",
        //   options: ["pending", "approved", "unidentified"],
        //   colors: ["yellow", "#1a9520", "#FF3D00"],
        // },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      survayorList: [],
      PODispatchedList: [],
      PO_Items_List: [],
      summaryData: [],
      PurchaseOrders: [],
      singlelistView: null,
      singleListViewUpdated: false,
      reloadComp: false,
      listViewArray: [],
      loading: false,
      editingDispatch: false,
      loadingFun: false,
      success: false,
      error: false,
      calenderOpen: false,
      dispatchTab: true,
      selectedSrvayorid: false,
      selectedPoIdinSiurvayor: "",
      status: [
        { name: "DRAFT" },
        { name: "OPEN" },
        { name: "CLOSED" },
        { name: "CANCELLED" },
      ],
      statusDispatch: [
        { name: "dispatched" },
        { name: "reached" },
        { name: "full_return" },
        { name: "semi_returned" },
        { name: "unloaded" },
      ],
      selctedPOStatus: "OPEN",
      total_quantity: "",
      last_survey: new Date(),
      unit: [{ name: "Kgs" }, { name: "MT" }, { name: "Qtl" }],
      selctedStatus: "OPEN",
      openDialog: false,
      searchedData: new Date(),
      calenderOpen: false,
      calenderOpenLS: false,
      poItemheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        "HSN Code",
        "Total Item Quantity",
        "Contract Rate",
        "Sales Tax Terms",
        "Specifications",
      ],
      survayItemheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Moisture",
        "Broken",
        "Damaged/Discoloured",
        "Chalky",
        "Length",
        "Paddy",
        "Green Grain",
        "Whole Red Grain",
        "Average Grain Length",
        "Foreign Matter",
        "Double Polish",
        "REMARK",
        "Survey Company",
        "Surveyor",
        //"Sampling Date"
      ],
      widthList: {},
      dates: [],
      selectedPVItemArray: [],
      contractTypeName: "",
      contractTypeId: "",
      PaymentTerm: "",
      PaymentTermId: "",
      MillerName: "",
      MillerNameId: "",
      MillerAddress: "",
      MillerAddressId: "",
      MillerGST: "",
      MillerGSTId: "",
      ContactPerson: "",
      ContactPersonId: "",
      ItemCategory: "",
      ItemCategoryId: "",
      ItemType: "",
      ItemTypeId: "",
      BorkerName: "",
      BorkerNameId: "",
      BrandName: false,
      selectedBrandMultipleName: false,
      BrandNameId: "",
      Borkerage: "",
      BorkerageId: "",
      Remark: "",
      Unit: "",
      UnitId: "",
      portArry: [],
      packagingArr: [],
      selectedValue: 0,
      added: false,
      cashdiscount: "no",
      addItemMultiple: "false",
      contarctTypeFields: [{ type: "ex factory" }, { type: "FOR" }],
      HSN_id: "",
      poItemsArray: [],
      poItemsArrayCopy: [],
      poItemsArrayTOPreview: [],
      poItemsArrayTOPreviewCopy: [],
      poItemsArrayTOPreviewCopy2: [],
      alreadyFetched: false,
      alreadyFetchedData: false,
      loader: false,
      loadingRight: false,
      submittedDataOfUsedBags: {},
      surveyReport: [],
      surveyorItemArrayCopy: [],
      errorMsg: {
        contractTypeName: false,
        PaymentTerm: false,
        MillerName: false,
        MillerAddress: false,
        MillerGST: false,
        ContactPerson: false,
        ItemCategory: false,
        ItemType: false,
        BorkerName: false,
        BrandName: false,
        Borkerage: false,
        Remark: false,
        portArry: false,
        packagingArr: false,
        Rate: false,
        Unit: false,
        HSN: false,
      },
      errorMsgForDispatch: {},
      openAddBagDialog: false,
      editDialog: false,
      isSpinning: {},
      ReachedList: {},
      paginationData: {},
      confirmApp: false,
      approve: false,
      reject: false,
      selectedCheckboxListArray: [],
      selectAllApproveReject: "",
      resetPage: "",
      displayPending: false,
      searchText: "",
      dateSelectedFrom: "",
      dateSelectedTo: "",
      dispatchItemRowCheckBox: [],
    };
    this.debouncedFilterSearchApi = debounce(this.filterSearchApi, 500);
  }
  componentDidMount() {
    this.showSnackbar = this.context;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(
      "UPADTE",
      prevState.reloadComp,
      this.state.reloadComp,
      prevState.singlelistView,
      this.state.singlelistView,
      prevState.singlelistView !== this.state.singlelistView
    );
    if (
      (prevState.reloadComp !== this.state.reloadComp &&
        this.state.reloadComp) ||
      prevState.singlelistView !== this.state.singlelistView
    ) {
      localStorage.setItem("reloadComp", true);
      this.setState({ reloadComp: false });
      console.log(
        this.state.reloadComp,
        prevState.singlelistView,
        this.state.singlelistView
      );
    }
  }

  handleClearFields = () => {
    this.setState({
      added: false,
      loading: false,
      loadingFun: false,
      success: false,
      error: false,
    });
  };
  reset = (noSnack) => {
    const {
      contractTypeName,

      PaymentTerm,
      MillerName,
      MillerAddress,
      MillerGST,
      ContactPerson,
      ItemCategory,
      ItemType,
      BorkerName,
      BrandName,
      Borkerage,
      Remark,
      portArry,
      packagingArr,
      Rate,
      Unit,
      HSN,
      SalesOrder,
    } = this.state;

    if (
      !Rate &&
      !Unit &&
      !HSN &&
      !Remark &&
      !Borkerage &&
      !BrandName &&
      !BorkerName &&
      !ItemType &&
      !ItemCategory &&
      !ContactPerson &&
      !MillerGST &&
      !MillerAddress &&
      !MillerName &&
      !PaymentTerm &&
      !contractTypeName &&
      packagingArr.length === 0 &&
      portArry.length === 0 &&
      !SalesOrder
    ) {
      this.setState({
        error: noSnack ? false : "Nothing to reset !",
        errorMsg: {
          contractTypeName: false,
          PaymentTerm: false,
          MillerName: false,
          MillerAddress: false,
          MillerGST: false,
          ContactPerson: false,
          ItemCategory: false,
          ItemType: false,
          BorkerName: false,
          BrandName: false,
          Borkerage: false,
          Remark: false,
          portArry: false,
          packagingArr: false,
          Rate: false,
          Unit: false,
          HSN: false,
          SalesOrder: false,
        },
      });
    } else {
      this.setState(
        {
          BrandNameId: "",
          BrandName: false,
          selectedBrandMultipleName: false,
          selctedPOStatus: "OPEN",
          SalesOrder: "",
          SalesOrderId: "",
          contractTypeName: "",
          PaymentTerm: "",
          MillerName: "",
          MillerAddress: "",
          MillerGST: "",
          ContactPerson: "",
          ItemCategory: "",
          ItemTypeId: "",
          ItemCategoryId: "",
          ItemType: "",
          BorkerName: "",
          Borkerage: "",
          Remark: "",
          portArry: [],
          packagingArr: [],
          Rate: "",
          Unit: "",
          HSN: "",
          HSN_id: "",
          enteredDispatchdData: false,
          errorMsg: {
            contractTypeName: false,
            PaymentTerm: false,
            MillerName: false,
            MillerAddress: false,
            MillerGST: false,
            ContactPerson: false,
            ItemCategory: false,
            ItemType: false,
            BorkerName: false,
            BrandName: false,
            Borkerage: false,
            Remark: false,
            portArry: false,
            packagingArr: false,
            Rate: false,
            Unit: false,
            HSN: false,
            SalesOrder: false,
          },
        },
        () => {
          this.setState({ success: noSnack ? false : "Reset successfully !" });
        }
      );
    }
  };
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  fetchReachedData = (urlText = false, type = false) => {
    if (this.state.singlelistView) {
      this.setState({ loadingRight: true, ReachedList: {} });
      this.props
        .PurchaseOrdersFunction(
          "get",
          type === "pagination" ? urlText : "arrival/list",
          null,
          "ReachedList",
          null,
          type === "pagination"
            ? null
            : this.state?.displayPending
            ? {
                po_id: this.state.singlelistView.id,
                arrival_status: "reached",
                pending: "True",
              }
            : {
                po_id: this.state.singlelistView.id,
                arrival_status: "reached",
              },
          null,
          null,
          null,
          null,
          type === "pagination" ? true : false
        )
        .then((ReachedListData) => {
          console.log(ReachedListData);
          const ReachedList = ReachedListData;
          if (ReachedList && ReachedList?.results.length > 0) {
            console.log("ReachedList", ReachedList);
            this.setState({ loadingRight: true });
            ReachedList?.results?.map((sr) => {
              let arr = [];
              let dispatchPack = [];
              let arrivalPack = [];
              sr.arrival_item_details.map((item) => {
                console.log("ITEM", item);
                dispatchPack = item.dispatch_items_details.dispatch_packaging;
                arrivalPack = item.arrival_packagings;
                dispatchPack.map((sp, i) => {
                  let ret =
                    arrivalPack[i] && arrivalPack[i].returned_bags
                      ? arrivalPack[i].returned_bags
                      : 0;
                  sp.returned_bags = ret ? ret : "";
                  return sp;
                });

                item.dispatch_items_details.newPack = dispatchPack;
                arr.push({
                  ...item.dispatch_items_details,
                  arrival_item_status: item.arrival_item_status,
                  ...item,
                });
                return item;
              });
              sr.subList = arr;
              sr.portId = sr.arrival_item_details[0]?.godowns_list[0]?.port
                ? sr.arrival_item_details[0]?.godowns_list[0]?.port
                : "";
              return sr;
              // sr.subList = sr.arrival_item_details.dispatch_items_details;
            });
            this.setState({ loadingRight: false });
          } else {
            this.setState({ loadingRight: false });
          }
          if (ReachedList?.results) {
            this.setState({ ReachedList, loadingRight: false });
          } else {
            this.setState({ loadingRight: false });
          }
          if (ReachedListData?.results) {
            this.setState({
              paginationData: {
                count: ReachedListData?.count_on_page || 0,
                total_count: ReachedListData?.total_count || 0,
                next: ReachedListData?.next || null,
                previous: ReachedListData?.previous || null,
              },
            });
          } else {
            this.setState({ paginationData: {} });
          }
        });
    }
  };

  fetchDisptachList = (urlText = false, type = false) => {
    this.setState({
      loadingRight: true,
      PODispatchedList: [],
      paginationData: {},
    });
    this.props
      .PurchaseOrdersFunction(
        "get",
        type === "pagination" ? urlText : "dispatch/list",
        null,
        "PODispatchedList",
        null,
        type === "pagination"
          ? null
          : {
              po_id: this.state.singlelistView.id,
            },
        null,
        null,
        null,
        null,
        type === "pagination" ? true : false
      )
      .then((DispatchListData) => {
        if (DispatchListData && DispatchListData.results.length > 0) {
          if (DispatchListData?.results) {
            this.setState({
              paginationData: {
                count: DispatchListData?.count_on_page || 0,
                total_count: DispatchListData?.total_count || 0,
                next: DispatchListData?.next || null,
                previous: DispatchListData?.previous || null,
              },
            });
          }
          const blankarr = [];
          DispatchListData.results.map((sData) => {
            this.props
              .PurchaseOrdersFunction(
                "get",
                "dispatch/dispatch-items/list",
                null,
                "dispatch-item",
                null,
                {
                  dispatch_id: sData.id,
                }
              )
              .then((res2) => {
                // console.log(res2, "res2res2");
                sData.subList = res2 ? res2 : [];
                blankarr.push(sData);
                if (DispatchListData.results.length === blankarr.length) {
                  this.setState({
                    PODispatchedList: DispatchListData.results,
                    loadingRight: false,
                  });
                }
                return sData;
              });
            return sData;
          });
          // if (DispatchListData?.results) {
          //   this.setState({
          //     paginationData: {
          //       count: DispatchListData?.count_on_page || 0,
          //       total_count: DispatchListData?.total_count || 0,
          //       next: DispatchListData?.next || null,
          //       previous: DispatchListData?.previous || null,
          //     },
          //   });
          // } else {
          //   this.setState({ paginationData: {} });
          // }
        } else {
          this.setState({ PODispatchedList: [], loadingRight: false });
        }
      });
  };

  fetchSurveyReportList = (urlText = false, type = false) => {
    this.setState({ loadingRight: true, surveyReport: [] });
    this.props
      .PurchaseOrdersFunction(
        "get",
        type === "pagination"
          ? urlText
          : "dispatch/purchase-order/survey-report/list",
        null,
        "surveyReport",
        null,
        type === "pagination"
          ? null
          : {
              po_id: this.state.singlelistView.id,
            },
        null,
        null,
        null,
        null,
        type === "pagination" ? true : false
      )
      .then((SurveyListData) => {
        if (SurveyListData && SurveyListData.results.length > 0) {
          const blankarr = [];
          SurveyListData.results.map((sData) => {
            this.props
              .PurchaseOrdersFunction(
                "get",
                "dispatch/purchase-order/survey-report/items/list",
                null,
                "survay-item",
                null,
                { survey_report_id: sData.id }
              )
              .then((res2) => {
                const newobj = sData;
                newobj.subList = res2;
                blankarr.push(newobj);
                if (SurveyListData.results.length === blankarr.length) {
                  this.setState({
                    surveyReport: blankarr,
                    loadingRight: false,
                  });
                }
                return sData;
              });
            return sData;
          });
          if (SurveyListData?.results) {
            this.setState({
              paginationData: {
                count: SurveyListData?.count_on_page || 0,
                total_count: SurveyListData?.total_count || 0,
                next: SurveyListData?.next || null,
                previous: SurveyListData?.previous || null,
              },
            });
          } else {
            this.setState({ paginationData: {} });
          }
        } else {
          this.setState({ surveyReport: [], loadingRight: false });
        }
      });
  };

  listClickHandler = (value, type = "") => {
    console.log(value, type, "valueList");
    if (type === "reload") {
      this.setState({ reloadComp: true });
    }
    this.setState(
      { loadingRight: true, singlelistView: value },

      () => {
        console.log(this.state.singlelistView, "VVIEW");
        if (this.state.singlelistView && this.state.singlelistView.id) {
          this.setState({
            chalkyCondition: "",
          });
          console.log("TABVALUE", this.state.selectedValue);
          const { selectedValue } = this.state;
          if (Number(selectedValue) === 3) this.fetchReachedData();
          else if (Number(selectedValue) === 2) {
            this.fetchDisptachList();
          } else if (Number(selectedValue) === 1) {
            this.fetchSurveyReportList();
          }

          this.props
            .PurchaseOrdersFunction(
              "get",
              "dispatch/po-items/list",
              null,
              "PO_Items_List",
              null,
              {
                po_id: this.state.singlelistView.id,
                is_active: "True",
              }
            )
            .then(() => {
              this.props
                .PurchaseOrdersFunction(
                  "get",
                  "dispatch/po-packaging/list",
                  null,
                  //state.singlelistView.id,
                  "poPackahging",
                  null,
                  { is_active: "True" }
                )
                .then((poPackahging) => {
                  if (poPackahging) {
                    this.setState({ poPackahging }, () => {
                      this.setData();
                    });
                  } else {
                    this.setData();
                  }
                });
            });
        }
      }
    );
  };

  handleIconClick = (indexId, type) => {
    this.setState(
      {
        isSpinning: {
          ...Object.keys(this.state.isSpinning).reduce(
            (acc, key) => ({ ...acc, [key]: false }),
            {}
          ),
          [indexId]: type,
        },
      },
      () => {
        console.log("After:", this.state.isSpinning);
      }
    );
  };
  pageChangeAPI = (url) => {
    const { selectedValue } = this.state;
    if (Number(selectedValue) === 3) this.fetchReachedData(url, "pagination");
    else if (Number(selectedValue) === 2) {
      this.fetchDisptachList(url, "pagination");
    } else if (Number(selectedValue) === 1) {
      this.fetchSurveyReportList(url, "pagination");
    }
  };
  resetPaginationPage = (field) => {
    this.setState({ resetPage: field });
  };
  handleToggle = (data) => {
    this.setState({ displayPending: data }, () => {
      this.resetPaginationPage("resetPage");
      this.fetchReachedData();
    });
  };
  allCheckBoxSelected = (data) => {
    this.setState({ selectedCheckboxListArray: data });
  };
  handleConfirmApp = () => {
    const moveShippingArr = [];
    const { singlelistView, approve, reject } = this.state;
    const { masters } = this.props;
    let singleDispatchData = approve || reject;

    let shippingAvailable = singleDispatchData.subList.find(
      (it) => it.arrival_item_status === "shipped"
    );

    console.log(
      approve,
      reject,
      singlelistView,
      this.state,
      "appreeeeeeeeove",
      shippingAvailable,
      singleDispatchData.subList
    );

    let itemBulkArr = [];
    let packagingBulkArr = [];
    singleDispatchData.subList.map((d) => {
      const packaging = d.arrival_packagings[0];
      const dispatchdetail = filterData(
        d.dispatch_packaging,
        "id",
        packaging.dispatch_packaging,
        "onlyOne"
      );
      const returned_bags_of_packgings = {};
      returned_bags_of_packgings[dispatchdetail.packaging] =
        packaging.returned_bags || 0;

      packagingBulkArr.push({
        dispatch_packaging: dispatchdetail.id || "",
        arrival_item: packaging.arrival_item || "",
        packaging: packaging.packaging || "",
        returned_bags: packaging?.returned_bags || 0,
        godown: d.godown || "",
        id: packaging.id || "",
      });

      itemBulkArr.push({
        dispatch_items: d.dispatch_items || "",
        arrival: d.arrival || "",
        remark: d.remark || "",
        godown: d.godown || "",
        returned_bags_of_packgings: returned_bags_of_packgings,
        returned_bags: d.returned_bags || 0,
        id: d.id || "",
        port: singleDispatchData.portId || "",
        arrival_status: "reached",
        sales_order: d.sales_order || "",
        custom_invoice: d.custom_invoice || "",
        shipping_date: d.shipping_date || "",
        arrival_item_status: d.arrival_item_status || "",
        return_status: d.return_status || "",
        unloading_agent: d.unloading_agent?.id || "",
        mr_or_leo_no: d.mr_or_leo_no || "",
      });

      console.log(
        "itemBulkArr",
        itemBulkArr,
        "packagingBulkArr",
        packagingBulkArr
      );
    });
    if (approve && shippingAvailable) {
      approve.arrival_item_details.map((item) => {
        const packaging_count = {};
        const used_weight = {};

        const unit =
          masters && masters.salesOrderList && masters.salesOrderList.length > 0
            ? masters.salesOrderList?.find(
                (it) =>
                  it.so_number === approve.arrival_table_details.sales_order
              )?.unit ?? "kgs"
            : "kgs";

        item.dispatch_items_details.dispatch_packaging.map((packaging) => {
          const arrival_pac = item.arrival_packagings.find(
            (it) => it.packaging === packaging.packaging
          );
          const bag_count = subtract(
            packaging.total_bags_dispatched,
            arrival_pac?.returned_bags ?? 0
          );
          packaging_count[packaging.packaging] = bag_count;

          const calculatedValue = this.changeUnit(
            packaging.packaging_details.unit,
            unit,
            packaging.packaging_details.size
          );
          used_weight[packaging.packaging] = multiply(
            Number(calculatedValue ? calculatedValue : 0),
            bag_count
          );
          return packaging;
        });
        moveShippingArr.push({
          used_weight: used_weight,
          packaging_count: packaging_count,
          is_active: true,
          custom_invoice: item.custom_invoice,
          commercial_invoice: item.commercial_invoice,
          coming_from: "DIRECT",
          brand: item.dispatch_items_details.brand,
          godown: null,
          port: singlelistView.id,
          shipping_date: item.shipping_date || "",
          arrival_item: item.arrival_packagings[0].arrival_item || "",
        });
        return item;
      });
      console.log(moveShippingArr, "moveShippingArr");

      this.props
        .PurchaseOrdersFunction(
          "post",
          "arrival/commercial-invoice/packing-list/bulk-create",
          null,
          "post",
          moveShippingArr
        )
        .then((res4) => {
          if (res4.error) {
            this.setState({
              error: res4.data,
              loadingDialog: false,
              confirmApp: false,
              approve: false,
            });
          } else {
            this.setState({
              success: "User Role Other Arrival should be Approved",
              loadingDialog: false,
              confirmApp: false,
              approve: false,
            });
          }
        });
    }
    this.props
      .PurchaseOrdersFunction(
        "patch",
        "arrival",
        this.state.approve ? this.state.approve.id : this.state.reject.id,
        null,
        { approval_status: this.state.approve ? "ACCEPTED" : "REJECTED" },
        null,
        null,
        "noeditparam"
      )
      .then((res) => {
        console.log(res, "resssss1");
        this.showSnackbar(
          approve ? "Approved Successfully" : "Rejected Successfully",
          "success"
        );
        this.fetchReachedData();
        if (approve) {
          this.props
            .PurchaseOrdersFunction(
              "put",
              "arrival/items/bulk-update",
              null,
              "post",
              itemBulkArr
            )
            .then((res2) => {
              console.log(res2, "resssss2");
              this.props
                .PurchaseOrdersFunction(
                  "put",
                  "arrival/packaging/bulk-update",
                  null,
                  "post",
                  packagingBulkArr
                )
                .then((res3) => {
                  console.log(res3, "resssss3");
                  this.setState({
                    confirmApp: false,
                    approve: false,
                    reject: false,
                  });
                });
            });
        }
        if (res === "Arrival has been approved") {
          this.setState({
            confirmApp: false,
            approve: false,
            reject: false,
            snackDel: "approved",
          });
        }
        if (res.toString()[0] === "E") {
          this.setState({
            confirmApp: false,

            approve: false,
            reject: false,
          });
        } else {
          this.setState({
            confirmApp: false,
            approve: false,
            reject: false,
          });
        }
      });
  };
  handleAllConfirmApp = () => {
    const moveShippingArr = [];
    const {
      singlelistView,
      selectAllApproveReject,
      selectedCheckboxListArray,
    } = this.state;
    const { masters } = this.props;

    let shippingAvailable = selectedCheckboxListArray.some((L) =>
      L.subList.some((it) => it.arrival_item_status === "shipped")
    );

    console.log(
      selectAllApproveReject,
      selectedCheckboxListArray,
      masters,
      singlelistView,
      this.state,
      this.props
    );
    let statusBulkArr = [];
    let itemBulkArr = [];
    let packagingBulkArr = [];
    selectedCheckboxListArray.length > 0 &&
      selectedCheckboxListArray.map((L) => {
        let singleDispatchData = L;

        statusBulkArr.push({
          id: singleDispatchData?.id,
          approval_status:
            selectAllApproveReject === "approve" ? "ACCEPTED" : "REJECTED",
        });

        singleDispatchData.subList.map((d) => {
          const packaging = d.arrival_packagings[0];
          const dispatchdetail = filterData(
            d.dispatch_packaging,
            "id",
            packaging.dispatch_packaging,
            "onlyOne"
          );
          const returned_bags_of_packgings = {};
          returned_bags_of_packgings[dispatchdetail.packaging] =
            packaging.returned_bags || 0;

          packagingBulkArr.push({
            dispatch_packaging: dispatchdetail.id || "",
            arrival_item: packaging.arrival_item || "",
            packaging: packaging.packaging || "",
            returned_bags: packaging?.returned_bags || 0,
            godown: d.godown || "",
            id: packaging.id || "",
          });

          itemBulkArr.push({
            dispatch_items: d.dispatch_items || "",
            arrival: d.arrival || "",
            remark: d.remark || "",
            godown: d.godown || "",
            returned_bags_of_packgings: returned_bags_of_packgings,
            returned_bags: d.returned_bags || 0,
            id: d.id || "",
            port: singleDispatchData.portId || "",
            arrival_status: "reached",
            sales_order: d.sales_order || "",
            custom_invoice: d.custom_invoice || "",
            shipping_date: d.shipping_date || "",
            arrival_item_status: d.arrival_item_status || "",
            return_status: d.return_status || "",
            unloading_agent: d.unloading_agent?.id || "",
            mr_or_leo_no: d.mr_or_leo_no || "",
          });
        });
      });

    console.log(
      statusBulkArr,
      "itemBulkArr",
      itemBulkArr,
      "packagingBulkArr",
      packagingBulkArr
    );

    if (selectAllApproveReject === "approve" && shippingAvailable) {
      selectedCheckboxListArray.map((L) => {
        let singleDispatchData = L;

        singleDispatchData.arrival_item_details.map((item) => {
          const packaging_count = {};
          const used_weight = {};

          const unit =
            masters &&
            masters.salesOrderList &&
            masters.salesOrderList.length > 0
              ? masters.salesOrderList?.find(
                  (it) =>
                    it.so_number ===
                    singleDispatchData.arrival_table_details.sales_order
                )?.unit ?? "kgs"
              : "kgs";

          item.dispatch_items_details.dispatch_packaging.map((packaging) => {
            const arrival_pac = item.arrival_packagings.find(
              (it) => it.packaging === packaging.packaging
            );
            const bag_count = subtract(
              packaging.total_bags_dispatched,
              arrival_pac?.returned_bags ?? 0
            );
            packaging_count[packaging.packaging] = bag_count;

            const calculatedValue = this.changeUnit(
              packaging.packaging_details.unit,
              unit,
              packaging.packaging_details.size
            );
            used_weight[packaging.packaging] = multiply(
              Number(calculatedValue ? calculatedValue : 0),
              bag_count
            );
            return packaging;
          });
          moveShippingArr.push({
            used_weight: used_weight,
            packaging_count: packaging_count,
            is_active: true,
            custom_invoice: item.custom_invoice,
            commercial_invoice: item.commercial_invoice,
            coming_from: "DIRECT",
            brand: item.dispatch_items_details.brand,
            godown: null,
            port: singlelistView.id,
            shipping_date: item.shipping_date || "",
            arrival_item: item.arrival_packagings[0].arrival_item || "",
          });
          return item;
        });
      });
      console.log(moveShippingArr, "moveShippingArr");

      this.props
        .PurchaseOrdersFunction(
          "post",
          "arrival/commercial-invoice/packing-list/bulk-create",
          null,
          "post",
          moveShippingArr
        )
        .then((res4) => {
          if (res4.error) {
            this.setState({
              error: res4.data,
              confirmApp: false,
              approve: false,
              selectedCheckboxListArray: [],
            });
          } else {
            this.setState({
              confirmApp: false,
              approve: false,
              selectedCheckboxListArray: [],
            });
          }
        });
    }
    this.props
      .PurchaseOrdersFunction(
        "patch",
        "arrival/bulk-update",
        null,
        null,
        statusBulkArr,
        null,
        null,
        "noeditparam"
      )
      .then((res) => {
        console.log(res, "resssss1");
        this.showSnackbar(
          selectAllApproveReject === "approve"
            ? "Approved All Successfully"
            : "Rejected All Successfully",
          "success"
        );
        this.fetchReachedData();
        this.setState({
          selectAllApproveReject: "",
          confirmApp: false,
          approve: false,
          reject: false,
          selectedCheckboxListArray: [],
        });
        if (selectAllApproveReject === "approve") {
          this.props
            .PurchaseOrdersFunction(
              "put",
              "arrival/items/bulk-update",
              null,
              "post",
              itemBulkArr
            )
            .then((res2) => {
              console.log(res2, "resssss2");
              this.props
                .PurchaseOrdersFunction(
                  "put",
                  "arrival/packaging/bulk-update",
                  null,
                  "post",
                  packagingBulkArr
                )
                .then((res3) => {
                  console.log(res3, "resssss3");
                  this.setState({
                    confirmApp: false,
                    approve: false,
                    reject: false,
                  });
                });
            });
        }
        if (res === "Arrival has been approved") {
          this.setState({
            confirmApp: false,
            approve: false,
            reject: false,
            snackDel: "approved",
          });
        }
        if (res.toString()[0] === "E") {
          this.setState({
            confirmApp: false,

            approve: false,
            reject: false,
          });
        } else {
          this.setState({
            confirmApp: false,
            approve: false,
            reject: false,
          });
        }
      });
  };
  selectHandler = (value, type = "") => {
    console.log("VALUE CLICKED", value);
    this.setState(
      {
        selctedStatus: value,
        selctedData: type !== "" ? value : this.state?.selctedData,
      },
      () => {
        this.props.PurchaseOrdersFunction(
          "get",
          EndPoints["PurchaseOrders"],
          null,
          null,
          null,
          {
            status: this.state.selctedStatus,
          }
        );
      }
    );
  };
  searchHandler = (value) => {
    this.setState({ selectedMonth: value }, () => {
      const { selectedMonth } = this.state;
      this.props.PurchaseOrdersFunction(
        "get",
        EndPoints["PurchaseOrders"],
        null,
        null,
        null,
        {
          month: selectedMonth.getMonth() + 1,
          status: this.state.selctedStatus,
        }
      );
    });
  };
  setInitialValues(props, newData = "") {
    const data = newData
      ? newData
      : props[EndPoints["PurchaseOrders"]] &&
        props[EndPoints["PurchaseOrders"]].results
      ? props[EndPoints["PurchaseOrders"]].results
      : [];
    // if (props && data && data.length > 0 && typeof data === "string") {
    //   data.sort((a, b) => b.id - a.id);
    // }
    console.log("SetIn", data);

    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;

      tab.data =
        data && data.length > 0
          ? data
              .map((data, index) => {
                return data;
              })
              .filter((sD) => {
                if (sD.status) {
                  return sD.status === this.state.selctedStatus;
                } else {
                  return null;
                }
              })
          : // .sort((a, b) => b.id - a.id)
            [];

      if (this.state.selctedStatus && tab.data.length > 0) {
        tab.data = tab.data
          .map((data, index) => {
            return data;
          })
          .filter((sD) => {
            if (sD.status) {
              return sD.status === this.state.selctedStatus;
            } else {
              return null;
            }
          });
      }

      return tab;
    });
    const masters = props.masters;
    if (masters) {
      this.setState({
        loader: props.loader,
        dispatchPackagingArray: masters["dispatchPackagingArray"]
          ? masters["dispatchPackagingArray"]
          : [],
        allsurveyorList: masters["allsurveyorList"]
          ? masters["allsurveyorList"]
          : [],
        ricebagsList: masters["ricebag"] ? masters["ricebag"] : [],
        poPackahging: masters["poPackahging"] ? masters["poPackahging"] : [],
        MillerList: masters["MillerList"] ? masters["MillerList"] : [],
        ItemCateoryList: masters["ItemCateoryList"]
          ? masters["ItemCateoryList"]
          : [],
        ItemList: masters["ItemList"] ? masters["ItemList"] : [],
        BrandList: masters["BrandList"] ? masters["BrandList"] : [],
        PackagingList: masters["PackagingList"] ? masters["PackagingList"] : [],
        BrokerList: masters["BrokerList"] ? masters["BrokerList"] : [],
        ContractTypeList: masters["ContractTypeList"]
          ? masters["ContractTypeList"]
          : [],
        InlandGodownList: masters["InlandGodownList"]
          ? masters["InlandGodownList"]
          : [],
        PortsList: masters["PortsList"] ? masters["PortsList"] : [],
        PaymentTermList: masters["PaymentTermList"]
          ? masters["PaymentTermList"]
          : [],
        TransporterList: masters["TransporterList"]
          ? masters["TransporterList"]
          : [],
        HSNlist: masters["HSNlist"] ? masters["HSNlist"] : [],
        SalesOrderList: masters["SalesOrderList"]
          ? masters["SalesOrderList"]
          : [],
        SurveyCompanyList: masters["SurveyCompanyList"]
          ? masters["SurveyCompanyList"]
          : [],
        specifiationTypes: masters["specifiationTypes"]
          ? masters["specifiationTypes"]
          : [],
      });
    }
    this.setState(
      {
        pagination:
          data.length > 0
            ? {
                count: props[EndPoints["PurchaseOrders"]].total_count,
                loadMore: () => {
                  const { selectedMonth, selctedStatus } = this.state;
                  this.props.PurchaseOrdersFunction(
                    "get",
                    EndPoints["PurchaseOrders"],
                    null,
                    null,
                    null,
                    {
                      month: selectedMonth
                        ? selectedMonth.getMonth() + 1
                        : undefined,
                      status: selctedStatus,
                    },
                    null,
                    true,
                    data,
                    props[EndPoints["PurchaseOrders"]].next
                  );
                },
                next: props[EndPoints["PurchaseOrders"]].next,
                currentLength: data.length,
              }
            : undefined,
        listViewArray:
          data && data.length > 0
            ? data.map((data, index) => {
                return data;
              })
            : [],
        PurchaseOrders: data,
        tabs,
        loading: props.loading,

        PO_Items_List:
          props.PO_Items_List && props.PO_Items_List.length > 0
            ? props.PO_Items_List
            : [],
      },
      () => {
        const {
          singlelistView,
          listViewArray,

          ItemCateoryList,
          ItemList,
          HSNlist,
        } = this.state;
        const oldData = localStorage.getItem("selected-po");
        if (
          singlelistView &&
          listViewArray.length > 0 &&
          HSNlist &&
          HSNlist.length > 0 &&
          ItemCateoryList &&
          ItemCateoryList.length > 0 &&
          ItemList &&
          ItemList.length > 0
        ) {
          if (this.state.alreadyFetchedData === false) {
            this.props
              .PurchaseOrdersFunction(
                "get",
                "dispatch/po-items/list",
                null,
                "PO_Items_List",
                null,
                {
                  po_id: this.state.singlelistView.id,
                  is_active: "True",
                }
              )
              .then((PO_Items_List) => {
                this.props
                  .PurchaseOrdersFunction(
                    "get",
                    "dispatch/po-packaging/list",
                    null,
                    "poPackahging",
                    null,
                    {
                      is_active: "True",
                    }
                  )
                  .then((poPackahging) => {
                    if (poPackahging) {
                      this.setState({ poPackahging }, () => {
                        this.setData();
                      });
                    } else {
                      this.setData();
                    }
                  });
              });

            this.setState({ alreadyFetchedData: true });
          }

          const newsinglelistView =
            listViewArray.filter((sLV) => sLV.id === singlelistView.id) &&
            listViewArray.filter((sLV) => sLV.id === singlelistView.id)[0];
          if (newsinglelistView && newsinglelistView.status) {
            if (newsinglelistView.status === this.state.selctedStatus) {
              // this.listClickHandler(newsinglelistView)
              if (oldData && newsinglelistView) {
                this.setState({
                  singlelistView: newsinglelistView,
                });
                localStorage.removeItem("selected-po");
              }
            } else {
              this.setState({ singlelistView: null }, () => {
                this.reset("no snac");
              });
            }
          } else {
            this.setState({ singlelistView: null }, () => {
              this.reset("no snac");
            });
          }
        } else if (listViewArray.length > 0) {
          const next = this.props;

          if (next.params && next.params.tab) {
            let tab = next.params.tab;

            if (
              tab === EndPoints["PurchaseOrders"].split("/")[0] &&
              next.params.id
            ) {
              if (this.state.alreadyFetched === false) {
                this.listClickHandler(this.state.tabs[0].data[next.params.id]);
                this.setState({ alreadyFetched: true });
              }
            } else if (tab === "NEW") {
              this.setState({ singlelistView: null }, () => {
                this.reset("no snac");
              });
            }
          } else {
            this.setState({ singlelistView: null }, () => {
              this.reset("no snac");
            });
          }
        } else {
          if (oldData) {
          } else {
            this.setState({}, () => {
              this.reset("no snac");
            });
          }
          // this.setState({ singlelistView: null }, () => {
          //   this.reset("no snac");
          // });
        }
      }
    );
  }

  updateSelectedTabData = (
    newTabData,
    tabId,
    TabIndex,
    editDialog = "",
    operation = ""
  ) => {
    console.log("CALLED", newTabData, tabId, TabIndex, editDialog);
    if (operation === "create") {
      console.log("createFetch");
      this.selectHandler(newTabData?.status, "");
    } else {
      console.log("editFetch");
      let tabData = [...this.state.tabs];
      this.setState({ loading: true, tabs: [] });
      tabData[0].data = tabData[0].data.map((d) => {
        return d.id === tabId ? newTabData : d;
      });

      this.setState({ tabs: tabData, loading: false, selectedValue: 0 }, () => {
        this.setInitialValues(this.props, tabData[0].data);
        this.listClickHandler(newTabData, "relaod");
        console.log(this.state.tabs);
        // this.showSnackbar(
        //   editDialog ? "Purchase Order Updated Successfully" : "Purchase Order Added Successfully",
        //   "success"
        // )
      });
    }
  };

  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  setWidth = (list, header) => {
    const { poItemheader } = this.state;
    if (list && list.length > 0) {
      if (list[0]) {
        const widthList = {};
        const array = header
          ? header
          : poItemheader
          ? poItemheader
          : Object.keys(list[0]);

        if (array && array.length > 0) {
          array.map((single, i) => {
            if (single) {
              return (widthList[array[i]] = `${
                Math.floor(
                  10 *
                    (single.toString().length > this.longest(single, list)
                      ? single.toString().length
                      : this.longest(single, list)
                      ? this.longest(single, list)
                      : 1)
                ) < 110
                  ? 110
                  : Math.floor(
                      10 *
                        (single.toString().length > this.longest(single, list)
                          ? single.toString().length
                          : this.longest(single, list)
                          ? this.longest(single, list)
                          : 1)
                    )
              }px`);
            } else {
              return single;
            }
          });
        }
        this.setState({ widthList });
      }
    }
  };
  setData = () => {
    const {
      editDialog,
      BrandList,
      ItemCateoryList,
      ItemList,
      PackagingList,
      PortsList,
      PO_Items_List,
      poItemheader,
      HSNlist,
      singlelistView,
    } = this.state;

    const oldnewPortArr = editDialog.ports ? editDialog.ports : [];
    const newPortArr = [];
    if (PortsList && PortsList.length > 0) {
      PortsList.map((sPack) => {
        if (oldnewPortArr.includes(sPack.id.toString())) {
          return newPortArr.push(sPack);
        } else {
          return null;
        }
      });
    }

    const newPOItemArray = [];
    if (
      PO_Items_List &&
      typeof PO_Items_List === "object" &&
      PO_Items_List.length > 0
    ) {
      PO_Items_List &&
        PO_Items_List.map((sList) => {
          const packaging_details = [];
          const pId = filterData(this.state.poPackahging, "po_items", sList.id)
            ? filterData(this.state.poPackahging, "po_items", sList.id).map(
                (s) => {
                  return s.packaging.toString();
                }
              )
            : [];
          if (PackagingList && PackagingList.length > 0) {
            PackagingList.map((sPack) => {
              const newObject =
                this.state.poPackahging &&
                filterData(this.state.poPackahging, "po_items", sList.id) &&
                filterData(
                  this.state.poPackahging &&
                    filterData(this.state.poPackahging, "po_items", sList.id),
                  "packaging",
                  sPack.id,
                  "onlyOne"
                )
                  ? filterData(
                      this.state.poPackahging &&
                        filterData(
                          this.state.poPackahging,
                          "po_items",
                          sList.id
                        ),
                      "packaging",
                      sPack.id,
                      "onlyOne"
                    )
                  : null;
              console.log(newObject, "newObject");
              if (pId.includes(sPack.id.toString()) || pId.includes(sPack.id)) {
                sPack.remaining_bags = newObject ? newObject.remaining_bags : 0;
                sPack.used_bags = newObject
                  ? newObject.used_bags
                    ? newObject.used_bags
                    : 0
                  : 0;
                return packaging_details.push(sPack);
              } else {
                return null;
              }
            });
          }
          console.log("packaging_details", packaging_details, sList);
          const addData = {
            brand: sList.brand,

            hsn_code: sList.hsn_code ? sList.hsn_code : "",
            packagingArr: packaging_details.map((sP) => {
              sP.sizeUnit = `${sP.size} ${sP.unit}`;
              return sP;
            }),
            total_quantity: sList.total_quantity,

            packaging: sList.packaging,
          };
          addData["id"] = sList.id;
          addData["remaining_bags"] = sList.remaining_bags;
          addData["dispatched_quantity"] = sList.dispatched_quantity;
          addData[poItemheader[1]] =
            ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter((sM) =>
              sM.id === sList.item_category ? sList.item_category : 0
            )[0]
              ? ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name &&
                ItemCateoryList.filter((sM) =>
                  sM.id === sList.item_category ? sList.item_category : 0
                )[0].name
              : "";
          addData[`${poItemheader[1]}id`] = sList.item_category;
          const spArr = sList["specifications"] ? sList["specifications"] : [];
          addData["Specifications"] = spArr;
          addData["specificationArr"] = spArr;
          addData[poItemheader[2]] =
            ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((sM) =>
              sM.id === sList.item_type ? sList.item_type : 0
            )[0]
              ? ItemList.filter((sM) =>
                  sM.id === sList.item_type ? sList.item_type : 0
                )[0].broken_percentage
              : "";
          addData[`${poItemheader[2]}id`] = sList.item_type;
          console.log(sList, "addData sList");
          addData["item_type"] = sList.item_type;
          addData[poItemheader[0]] =
            BrandList &&
            BrandList.length > 0 &&
            BrandList.filter((sM) =>
              sM.id === sList.brand ? sList.brand : 0
            )[0]
              ? BrandList.filter((sM) =>
                  sM.id === sList.brand ? sList.brand : 0
                )[0].brand_name
              : "";
          addData[`${poItemheader[0]}id`] = sList.brand;

          addData.selectedBrandMultipleName = `${
            filterData(BrandList, "id", sList.brand, "onlyOne")
              ? filterData(BrandList, "id", sList.brand, "onlyOne").brand_name
              : ""
          }-${
            filterData(ItemCateoryList, "id", sList.item_category, "onlyOne")
              ? filterData(
                  ItemCateoryList,
                  "id",
                  sList.item_category,
                  "onlyOne"
                ).name
              : ""
          }-${
            filterData(ItemList, "id", sList.item_type, "onlyOne")
              ? filterData(ItemList, "id", sList.item_type, "onlyOne")
                  .broken_percentage
              : ""
          } % `;
          addData[poItemheader[3]] =
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }) &&
            packaging_details.map((sP) => {
              return `${sP.size} ${sP.unit}`;
            }).length > 0
              ? packaging_details.map((sP) => {
                  return `${sP.size} ${sP.unit}`;
                })[0]
              : "-";
          addData[`${poItemheader[3]}Id`] = packaging_details;
          addData["packagageArrUsed"] = packaging_details
            .map((sP) => {
              const newObj = {};
              newObj.id = sP.id;
              newObj.bag_type = sP.bag_type;
              newObj.size = sP.size;
              newObj.unit = sP.unit;
              newObj.sizeUnit = `${sP.size} ${sP.unit}`;
              newObj.used_bags_po = sP.used_bags ? sP.used_bags : 0;

              newObj.remaining_bags = sP.remaining_bags;
              return newObj;
            })
            .map((s) => {
              s.used_bags = 0;
              return s;
            });
          addData[poItemheader[4]] =
            HSNlist &&
            HSNlist.length > 0 &&
            HSNlist.filter(
              (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
            )[0]
              ? HSNlist.filter(
                  (sM) => sM.id === (sList.hsn_code ? sList.hsn_code : 0)
                )[0].hsn_code
              : "";

          addData[`${poItemheader[4]}id`] = sList.hsn_code
            ? sList.hsn_code
            : "";
          addData[poItemheader[5]] = sList.total_quantity;
          addData[`${poItemheader[5]}Unit`] = `${sList.total_quantity} ${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;

          addData[poItemheader[6]] = sList.contract_rate;

          addData[`${poItemheader[6]}Unit`] = `${sList.contract_rate}/${
            singlelistView && singlelistView.unit ? singlelistView.unit : ""
          }`;
          console.log(sList);
          addData[poItemheader[7]] = sList.sales_tax_terms;

          addData.id = sList.id;
          addData.po_item_id = sList.id;
          newPOItemArray.push(addData);
          return sList;
        });
    }

    this.setState(
      {
        poItemsArrayTOPreview: newPOItemArray,
        poItemsArrayTOPreviewCopy: newPOItemArray,
        poItemsArrayTOPreviewCopy2: newPOItemArray,
        poItemsArray: [],
        poItemsArrayCopy: [],
        loadingRight: false,
      },
      () => {
        if (
          this.state.poItemsArrayTOPreview &&
          this.state.poItemsArrayTOPreview.length > 0
        ) {
          this.setWidth(this.state.poItemsArrayTOPreview);
        }
      }
    );
  };
  changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return this.changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return this.changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, multiply(weigths, 100));
      }
    }
  };

  componentWillUnmount() {
    if (this.debouncedFilterApi?.cancel) {
      this.debouncedFilterApi.cancel();
    }
  }

  filterSearchApi = () => {
    const { searchText, selctedStatus, dateSelectedFrom, dateSelectedTo } =
      this.state;
    console.log(dateSelectedFrom);
    // Call the API function with appropriate query parameters
    this.props.PurchaseOrdersFunction(
      "get",
      EndPoints["PurchaseOrders"],
      null,
      null,
      null,
      dateSelectedFrom !== ""
        ? {
            status: selctedStatus,
            search: searchText,
            date_from: dateSelectedFrom,
            date_to: dateSelectedTo,
          }
        : dateSelectedFrom !== "" && !Boolean(searchText)
        ? {
            status: selctedStatus,
            date_from: dateSelectedFrom,
            date_to: dateSelectedTo,
          }
        : searchText
        ? {
            status: selctedStatus,
            search: searchText,
          }
        : { status: selctedStatus }
    );
  };

  handlePOSearch = (data) => {
    this.setState({ searchText: data }, () => {
      this.debouncedFilterSearchApi();
    });
  };
  handlePODateRange = (data) => {
    this.setState(
      { dateSelectedFrom: data?.startDate, dateSelectedTo: data?.endDate },
      () => {
        console.log(
          "dateSelectedFrom",
          this.state.dateSelectedFrom,
          data,
          data?.startDate
        );
        this.props.PurchaseOrdersFunction(
          "get",
          EndPoints["PurchaseOrders"],
          null,
          null,
          null,
          this.state?.searchText && Boolean(data?.startDate)
            ? {
                status: this.state.selctedStatus,
                search: this.state.searchText,
                date_from: this.state.dateSelectedFrom,
                date_to: this.state.dateSelectedTo,
              }
            : this.state?.searchText && !Boolean(data?.startDate)
            ? {
                status: this.state.selctedStatus,
                search: this.state.searchText,
              }
            : !Boolean(this.state.searchText) && Boolean(data?.startDate)
            ? {
                status: this.state.selctedStatus,
                date_from: this.state.dateSelectedFrom,
                date_to: this.state.dateSelectedTo,
              }
            : { status: this.state.selctedStatus }
        );
      }
    );
  };

  handleDispLoosePoCond = () => {
    console.log(
      "DD",
      this.state.PortsList,
      this.state?.singlelistView,
      this.props
    );
    console.log("PO_CON", this.state?.singlelistView?.contract_type_details);
    return Boolean(
      this.state?.singlelistView?.contract_type_details?.contract_type ===
        "ex loose"
    );
  };

  render() {
    const {
      submittedDataOfUsedBags,
      errorMsgForDispatch,
      surveyReport,
      openAddBagDialog,
      allsurveyorList,
      selectedPVItemArray,
      poItemheader,
      widthList,
      colorArr,
      added,
      success,
      error,
      tabs,
      singlelistView,
      loading,
      status,
      selctedStatus,
      MillerList,
      BrandList,
      ItemCateoryList,
      ItemList,
      BrokerList,
      ContractTypeList,
      InlandGodownList,
      errorMsg,
      ItemType,
      ItemCategory,
      ItemCategoryId,
      BrandName,
      BrandNameId,
      selectedBrandMultipleName,
      editDialog,
      itemsId,
      PODispatchedList,
      unit,

      TransporterList,
      HSNlist,

      SurveyCompanyList,
      statusDispatch,
      survayorList,
      poItemsArrayCopy,
      poItemsArrayTOPreview,
      poItemsArrayTOPreviewCopy,
      loader,
      survayorListForSurvay,
      loadingRight,
      survayItemheader,
      Moisture,
      Broken,
      //  SamplingDate,
      Chalky,
      Length,
      GreenGrain,
      WholeRedGrain,
      AverageGrainLength,
      Paddy,
      Foreign,
      Double,
      REMARK,
      Damaged,
      surveyorItemArrayCopy,
      surveyorItemArray,
      selectedSrvayorid,
      selectedPoIdinSiurvayor,
      survayorCompany,
      survayorCompanyId,
      survayor,
      survayorId,
      poItemsArrayTOPreviewCopy2,
      // calenderOpen,
      selectedValue,
      PO_Items_List,
      dispatchTab,
      ReachedList,
      paginationData,
      confirmApp,
      selectedCheckboxListArray,
      // spec,
    } = this.state;
    // this.setState({ survayorList: false });
    // var dispatchTab=true;
    // spec= PO_Items_List.specifications?
    console.log(PODispatchedList, "PODispatchedList");
    console.log(
      this.state.editingDispatch,
      InlandGodownList,
      "yyyyyyyyyy                                                                                                   "
    );
    console.log(SurveyCompanyList, "SurveyCompanyList");
    console.log(survayor, "survey report surveyor");
    console.log(dispatchTab, "dispatch tabb");

    const POITemTable = (
      edit,
      array,
      noTotalQuantity,
      showTotal,
      width,
      addBag,
      submittedData,
      fromPO,
      editFunct,
      Unit,
      noMargin
    ) => {
      return (
        <>
          {/* <MultipleItemTable header={poItemheader} edit={edit} addBag={addBag} array={array} module={"Po Item"} /> */}
          <div
            style={{
              margin: noMargin ? "" : "20px 0 20px 0",
              height: "auto",
              width: width ? width : edit ? "100%" : "calc(100% - 30px)",
              minWidth: width && "100%",
              fontSize: "0.9rem",
            }}
          >
            <Paper
              className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary"
              style={{
                height: "auto",
                width: "100%",
                borderRadius: "7px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#8080801a",
                  color: primary,
                  borderColor: primary,
                  width: "fit-content",
                  minWidth: "100%",
                  tableLayout: "fixed",
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  minHeight: "30px",
                  overflow: "hidden",
                  borderRadius: "7px 7px 0 0",
                }}
              >
                <div
                  className="d-flex alignC justifySE width-100"
                  style={{ background: "rgb(128 128 128 / 64%)" }}
                >
                  {edit === "checkBox" ? (
                    <div
                      onClick={() => {
                        var errorMsg = this.state.errorMsg;
                        delete errorMsg.selectedPVItemArray;
                        if (selectedPVItemArray.length === array.length) {
                          this.setState({ selectedPVItemArray: [], errorMsg });
                        } else {
                          const pvArr1 = [];
                          const totalBag = [];

                          array.map((s) => {
                            pvArr1.push(s.po_item_id);

                            s["packagageArrUsed"].map((sData) => {
                              if (sData.remaining_bags && sData.used_bags) {
                                totalBag.push(sData);
                              } else {
                              }
                            });
                          });
                          const errors = {};
                          const arrayOfPrevState = [
                            "Gross Weight",
                            "Tare Weight",
                            "Net Weight",
                            "Miller Bill Number",
                          ];
                          arrayOfPrevState.map((ss) => {
                            if (submittedData[ss]) {
                            } else {
                              errors[ss] = `Please Add ${ss}`;
                            }
                            return ss;
                          });
                          if (
                            Object.values(errors) &&
                            Object.values(errors).length > 0
                          ) {
                            this.setState({
                              errorMsgForDispatch: {
                                ...errorMsgForDispatch,
                                ...errors,
                              },
                            });
                          } else {
                            if (pvArr1.length === array.length) {
                              this.setState({
                                selectedPVItemArray: pvArr1,
                                errorMsg,
                              });
                            }
                          }
                        }
                      }}
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "0px 10px",
                        width: "65px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                      }}
                    >
                      <Checkbox
                        style={{ margin: "0 0 0 10px" }}
                        edge="start"
                        checked={array.length === selectedPVItemArray.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": "head" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {poItemheader &&
                    poItemheader.map((name, i) =>
                      noTotalQuantity && name === "Total Item Quantity" ? (
                        ""
                      ) : (
                        <div
                          key={name}
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                            width:
                              widthList && widthList[poItemheader[i]]
                                ? name === "Specifications"
                                  ? "180px"
                                  : name === "Packaging" && addBag
                                  ? `222.08px`
                                  : name === "Packaging"
                                  ? "135.49px"
                                  : widthList[poItemheader[i]]
                                : "fit-content",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}
                        >
                          {name}
                        </div>
                      )
                    )}
                  {edit && edit !== "checkBox" && (
                    <div
                      className="justifyC d-flex alignC"
                      style={{
                        margin: "12px 10px",
                        width: "65px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                        textTransform: "capitalize",
                        fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                      }}
                    >
                      Action
                    </div>
                  )}
                </div>
              </div>
              {array && array.length > 0 ? (
                <>
                  <div
                    className="scrollBluePrimary"
                    style={{
                      height: "fit-content",
                      overflowY: "auto",
                      width: "fit-content",
                      minWidth: "100%",
                      position: "relative",
                      // background: "rgb(128 128 128 / 10%)",
                      boxShadow: "0px 0px 28px 0px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    {array.map(
                      (singleDate, i) =>
                        singleDate && (
                          <div
                            key={`${i}tal`}
                            className="d-flex width-100 d-flex-column"
                          >
                            <div className="d-flex width-100 d-flex-column justifySE">
                              <div
                                className="d-flex width-100 justifySE dividerBorder"
                                style={{
                                  backgroundColor:
                                    colorArr && colorArr[i % colorArr.length],
                                  height: edit === "checkBox" ? "44px" : "35px",
                                  fontSize: mediumScreen()
                                    ? "0.8rem"
                                    : "0.9rem",
                                  color:
                                    edit === "checkBox"
                                      ? selectedPVItemArray.indexOf(
                                          singleDate.po_item_id
                                        ) !== -1
                                        ? "black"
                                        : "grey"
                                      : "black",
                                  // (selectedPVItemArray.indexOf(singleDate.po_item_id) !== -1)?"black":"grey"
                                }}
                              >
                                {edit === "checkBox" ? (
                                  <div
                                    className="d-flex alignC justifyC minWidthFC"
                                    style={{
                                      margin: "0 10px",
                                      width: "65px",
                                      // minWidth: '65px',
                                      textTransform: "capitalize",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const errorMsg = this.state.errorMsg;
                                      delete errorMsg.poItemsArrayTOPreview;
                                      const currentIndex =
                                        selectedPVItemArray.indexOf(
                                          singleDate.po_item_id
                                        );
                                      const newChecked = [
                                        ...selectedPVItemArray,
                                      ];

                                      if (currentIndex === -1) {
                                        newChecked.push(singleDate.po_item_id);
                                      } else {
                                        newChecked.splice(currentIndex, 1);
                                      }

                                      const totalBag = [];
                                      singleDate["packagageArrUsed"].map(
                                        (sData) => {
                                          if (
                                            sData.remaining_bags &&
                                            sData.used_bags
                                          ) {
                                            totalBag.push(sData);
                                          } else {
                                          }
                                        }
                                      );
                                      const errors = {};
                                      const arrayOfPrevState = [
                                        "Gross Weight",
                                        "Tare Weight",
                                        "Net Weight",
                                        "Miller Bill Number",
                                      ];
                                      arrayOfPrevState.map((ss) => {
                                        if (submittedData[ss]) {
                                        } else {
                                          errors[ss] = `Please Add ${ss}`;
                                        }
                                        return ss;
                                      });
                                      if (
                                        Object.values(errors) &&
                                        Object.values(errors).length > 0
                                      ) {
                                        this.setState({
                                          errorMsgForDispatch: {
                                            ...errorMsgForDispatch,
                                            ...errors,
                                          },
                                        });
                                      } else {
                                        this.setState({
                                          selectedPVItemArray: newChecked,
                                          errorMsg,
                                        });
                                      }
                                    }}
                                  >
                                    {errorMsg.poItemsArrayTOPreview ||
                                    (selectedPVItemArray.length === 0 &&
                                      this.state.addbagError === true) ? (
                                      <Checkbox
                                        style={{
                                          margin: "0 0 0 10px",
                                          border: "1px solid red",
                                        }}
                                        edge="start"
                                        checked={
                                          selectedPVItemArray.indexOf(
                                            singleDate.po_item_id
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby":
                                            singleDate.po_item_id,
                                        }}
                                      />
                                    ) : (
                                      <Checkbox
                                        style={{ margin: "0 0 0 10px" }}
                                        edge="start"
                                        checked={
                                          selectedPVItemArray.indexOf(
                                            singleDate.po_item_id
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby":
                                            singleDate.po_item_id,
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {poItemheader &&
                                  poItemheader.map((name) =>
                                    noTotalQuantity &&
                                    name === "Total Item Quantity" ? (
                                      ""
                                    ) : (
                                      <div
                                        key={name}
                                        className="d-flex alignC justifyC minWidthFC"
                                        style={{
                                          margin: "12px 10px",
                                          width:
                                            widthList && widthList[name]
                                              ? name === "Specifications"
                                                ? "180px"
                                                : name === "Packaging" && addBag
                                                ? `222.08px`
                                                : name === "Packaging"
                                                ? "135.49px"
                                                : widthList[name]
                                              : "fit-content",
                                          minWidth:
                                            widthList && widthList[name]
                                              ? widthList[name]
                                              : "fit-content",
                                          textTransform: "capitalize",
                                          fontSize: mediumScreen()
                                            ? "0.8rem"
                                            : "0.9rem",
                                        }}
                                      >
                                        {console.log(
                                          "TABLE",
                                          name,
                                          singleDate,
                                          singleDate[name]
                                        )}
                                        {name === "Packaging" &&
                                        singleDate[name] ? (
                                          <ShowPackaging
                                            addBag={addBag}
                                            fromPO={fromPO}
                                            onResetSubmit={(d) => {
                                              const payload = {
                                                packaging_id: d.id,
                                                brand_id: singleDate.brand,
                                                po_id:
                                                  this.state.singlelistView.id,
                                              };
                                              console.log(
                                                "clicked YES",
                                                singleDate,
                                                payload,
                                                d
                                              );
                                              this.props
                                                .PurchaseOrdersFunction(
                                                  "post",
                                                  `dispatch/reset-purchased-bags`,
                                                  null,
                                                  "post",
                                                  payload,
                                                  null
                                                )
                                                .then((res) => {
                                                  if (res.error) {
                                                    console.log(res);
                                                    this.showSnackbar(
                                                      res.data,
                                                      "error",
                                                      5000
                                                    );
                                                  } else {
                                                    this.showSnackbar(
                                                      "Packaging bag reset",
                                                      "success",
                                                      4000
                                                    );
                                                    this.props
                                                      .PurchaseOrdersFunction(
                                                        "get",
                                                        `dispatch/purchase-order/${this.state.singlelistView.id}`,
                                                        null,
                                                        null,
                                                        null,
                                                        null
                                                      )
                                                      .then((resp) => {
                                                        this.updateSelectedTabData(
                                                          resp,
                                                          resp.id,
                                                          window.location.pathname.split(
                                                            "/"
                                                          )[3],
                                                          "",
                                                          "create"
                                                        );
                                                      });
                                                  }
                                                });
                                              // dispatch/reset-purchased-bags/
                                            }}
                                            details={
                                              dispatchTab &&
                                              !this.state.editingDispatch
                                                ? singleDate[
                                                    `packagageArrUsed`
                                                  ] &&
                                                  singleDate[`packagageArrUsed`]
                                                    .length > 0
                                                  ? singleDate[
                                                      `packagageArrUsed`
                                                    ].map((sP) => {
                                                      sP.new_remaining_bags =
                                                        Number(
                                                          sP.remaining_bags
                                                        );

                                                      console.log(
                                                        sP,
                                                        "sPsssss"
                                                      );

                                                      if (edit === "checkBox") {
                                                      } else {
                                                        sP.bag_type =
                                                          sP.bag_type
                                                            ? sP.bag_type
                                                            : filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                            ? filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type &&
                                                              filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type
                                                            : "";

                                                        sP.used_bags = fromPO
                                                          ? sP.used_bags_po
                                                          : sP.used_bags
                                                          ? sP.used_bags
                                                          : sP.used_bags_po
                                                          ? sP.used_bags_po
                                                          : 0;
                                                      }
                                                      return sP;
                                                    })
                                                  : []
                                                : dispatchTab &&
                                                  this.state.editingDispatch
                                                ? singleDate[
                                                    `packagageArrUsed`
                                                  ] &&
                                                  singleDate[`packagageArrUsed`]
                                                    .length > 0
                                                  ? singleDate[
                                                      `packagageArrUsed`
                                                    ].map((sP) => {
                                                      sP.new_remaining_bags =
                                                        Number(
                                                          sP.remaining_bags
                                                        );

                                                      console.log(
                                                        sP,
                                                        "sPsssss"
                                                      );

                                                      if (edit === "checkBox") {
                                                      } else {
                                                        sP.bag_type =
                                                          sP.bag_type
                                                            ? sP.bag_type
                                                            : filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                            ? filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type &&
                                                              filterData(
                                                                singleDate.packagingArr,
                                                                "packaging",
                                                                sP.packaging,
                                                                "onlyOne"
                                                              )
                                                                .packaging_details
                                                                .bag_type
                                                            : "";

                                                        sP.used_bags = fromPO
                                                          ? sP.used_bags_po
                                                          : sP.used_bags
                                                          ? sP.used_bags
                                                          : sP.used_bags_po
                                                          ? sP.used_bags_po
                                                          : 0;
                                                      }
                                                      return sP;
                                                    })
                                                  : []
                                                : singleDate[
                                                    `packagageArrUsed`
                                                  ] &&
                                                  singleDate[`packagageArrUsed`]
                                                    .length > 0
                                                ? singleDate[
                                                    `packagageArrUsed`
                                                  ].map((sP) => {
                                                    sP.new_remaining_bags =
                                                      sP.remaining_bags;

                                                    console.log(sP, "sPsssss");

                                                    if (edit === "checkBox") {
                                                    } else {
                                                      sP.bag_type = sP.bag_type
                                                        ? sP.bag_type
                                                        : filterData(
                                                            singleDate.packagingArr,
                                                            "packaging",
                                                            sP.packaging,
                                                            "onlyOne"
                                                          )
                                                        ? filterData(
                                                            singleDate.packagingArr,
                                                            "packaging",
                                                            sP.packaging,
                                                            "onlyOne"
                                                          ).packaging_details
                                                            .bag_type &&
                                                          filterData(
                                                            singleDate.packagingArr,
                                                            "packaging",
                                                            sP.packaging,
                                                            "onlyOne"
                                                          ).packaging_details
                                                            .bag_type
                                                        : "";

                                                      sP.used_bags = fromPO
                                                        ? sP.used_bags_po
                                                        : sP.used_bags
                                                        ? sP.used_bags
                                                        : sP.used_bags_po
                                                        ? sP.used_bags_po
                                                        : 0;
                                                    }
                                                    return sP;
                                                  })
                                                : []
                                            }
                                          />
                                        ) : name === "Specifications" &&
                                          singleDate[name] ? (
                                          <ShowSpecifications
                                            details={
                                              singleDate[name] &&
                                              singleDate[name].length > 0
                                                ? singleDate[name].map((sP) => {
                                                    // sP.used_bags =0
                                                    console.log(
                                                      sP,
                                                      this.state
                                                        .specifiationTypes,
                                                      "wedwewdws.state.specifiationTypes"
                                                    );
                                                    const ss = sP.nameId
                                                      ? Number(sP.nameId)
                                                      : sP.name;
                                                    const filterS =
                                                      this.state.specifiationTypes.find(
                                                        (st) => st.id === ss
                                                      );
                                                    return {
                                                      ...sP,
                                                      name: filterS
                                                        ? `${filterS.text.toLowerCase()}`
                                                        : "",
                                                      value: filterS
                                                        ? `${
                                                            sP.value
                                                          } ${filterS.unit.toLowerCase()}`
                                                        : sP.value,
                                                    };
                                                  })
                                                : []
                                            }
                                          />
                                        ) : name === "Sales Tax Terms" ? (
                                          singleDate.sales_tax_terms ||
                                          singleDate["Sales Tax Terms"]
                                        ) : singleDate[name] ? (
                                          singleDate[`${name}Unit`] ? (
                                            singleDate[`${name}Unit`]
                                          ) : name === "Item Type" ? (
                                            `${singleDate[name]} %`
                                          ) : (
                                            singleDate[name]
                                          )
                                        ) : (
                                          "-"
                                        )}

                                        {addBag === "showbagOnly" ? (
                                          ""
                                        ) : name === "Packaging" && addBag ? (
                                          <StyledButton
                                            onClick={() => {
                                              this.setState({
                                                addbagError: false,
                                              });
                                              const newData =
                                                submittedDataOfUsedBags;
                                              newData[singleDate.po_item_id] =
                                                singleDate["packagageArrUsed"]
                                                  ? singleDate[
                                                      "packagageArrUsed"
                                                    ]
                                                  : [];
                                              const totalBag = [];
                                              singleDate[
                                                "packagageArrUsed"
                                              ].map((sData) => {
                                                if (sData.remaining_bags) {
                                                  this.setState({
                                                    [`${sData.id}Remaining`]:
                                                      this.state
                                                        .editingDispatch !==
                                                      false
                                                        ? sum([
                                                            sData.remaining_bags,
                                                            sData.used_bags_fordiapatch,
                                                          ])
                                                        : sData.remaining_bags,
                                                  });
                                                  totalBag.push(sData);
                                                } else {
                                                }
                                                return sData;
                                              });
                                              if (totalBag.length > 0) {
                                                this.setState({
                                                  enteredDispatchdData:
                                                    submittedData,
                                                  openAddBagDialog: singleDate,
                                                  submittedDataOfUsedBags:
                                                    newData,
                                                });
                                              } else {
                                                this.setState({
                                                  error:
                                                    "Please allot respective rice bags to this miller!",
                                                });
                                              }
                                            }}
                                            disabled={
                                              selectedPVItemArray.indexOf(
                                                singleDate.po_item_id
                                              ) !== -1
                                                ? false
                                                : true
                                            }
                                            noColorWhenDisable={true}
                                            name={
                                              addBag === "showbagOnly" ? (
                                                "aaaaaaaaaaaaa"
                                              ) : (
                                                <span
                                                  style={{
                                                    color:
                                                      selectedPVItemArray.indexOf(
                                                        singleDate.po_item_id
                                                      ) !== -1
                                                        ? this.state
                                                            .addbagError ===
                                                          true
                                                          ? red
                                                          : primary
                                                        : "grey",
                                                  }}
                                                >
                                                  add bags
                                                </span>
                                              )
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )
                                  )}
                                {edit && edit !== "checkBox" && (
                                  <div
                                    className="d-flex alignC justifyC minWidthFC"
                                    style={{
                                      margin: "12px 10px",
                                      width: "65px",
                                      minWidth: "50px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <StyledButton
                                      tooltip="2Edit"
                                      name={<EditIcon />}
                                      width="60px"
                                      onClick={() => {
                                        if (editFunct) {
                                          editFunct(singleDate);
                                        } else {
                                          const newArr = [];
                                          poItemsArrayCopy.map((singleItem) => {
                                            if (
                                              singleItem.po_item_id ===
                                              singleDate.po_item_id
                                            ) {
                                            } else {
                                              newArr.push(singleItem);
                                            }
                                            return singleItem;
                                          });

                                          this.setState(
                                            {
                                              selectedPoid:
                                                singleDate.po_item_id,
                                              itemsId:
                                                singleDate["Item Typeid"],
                                              ItemType: singleDate["Item Type"],
                                              ItemCategoryId:
                                                singleDate["Item Categoryid"],
                                              ItemCategory:
                                                singleDate["Item Category"],
                                              packagingArr:
                                                singleDate.packagingArr,
                                              poItemsArray: newArr,
                                              poItemsArrayCopy:
                                                poItemsArrayCopy,
                                              BrandNameId:
                                                singleDate["Brandid"],
                                              BrandName: singleDate["Brand"],
                                              HSN_id: singleDate.hsn_code,
                                              HSN: singleDate["HSN Code"],
                                              total_quantity:
                                                singleDate[
                                                  "Total Item Quantity"
                                                ],
                                              Rate: singleDate["Contract Rate"],
                                              selectedBrandMultipleName: `${singleDate["Brand"]}-${singleDate["Item Category"]}-${singleDate["Item Type"]} % `,
                                              // total_quantity:singleDate.total_quantity
                                            },
                                            () => {}
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* <Divider className="width-100" /> */}
                            </div>

                            {/* <Divider /> */}
                          </div>
                        )
                    )}
                  </div>
                  {showTotal && (
                    <div className="d-flex alignC justifySE width-100">
                      {edit === "checkBox" ? (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "0px 10px",
                            width: "65px",
                            minWidth: "65px",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}
                        >
                          {selectedPVItemArray.length === 0
                            ? ""
                            : selectedPVItemArray.length}
                        </div>
                      ) : (
                        ""
                      )}
                      {poItemheader &&
                        poItemheader.map((name, i) =>
                          noTotalQuantity && name === "Total Item Quantity" ? (
                            ""
                          ) : (
                            <div
                              key={name}
                              className="justifyC d-flex alignC"
                              style={{
                                margin: "12px 10px",
                                width:
                                  widthList && widthList[name]
                                    ? name === "Specifications"
                                      ? "180px"
                                      : name === "Packaging" && addBag
                                      ? `222.08px`
                                      : name === "Packaging"
                                      ? "135.49px"
                                      : widthList[name]
                                    : "fit-content",
                                minWidth:
                                  widthList && widthList[name]
                                    ? name === "Specifications"
                                      ? "180px"
                                      : name === "Packaging" && addBag
                                      ? `222.08px`
                                      : name === "Packaging"
                                      ? "135.49px"
                                      : widthList[name]
                                    : "fit-content",
                                display: "flex",
                                alignItems: "center",
                                color: "#100f0f",
                                textTransform: "capitalize",
                                flexDirection:
                                  name === "Packaging" && addBag && "column",
                              }}
                            >
                              {name === "Packaging" && addBag ? (
                                <>
                                  {`${selectedPVItemArray
                                    .reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) +
                                        Number(
                                          filterData(
                                            array,
                                            "po_item_id",
                                            currentValue,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                array,
                                                "po_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )["packagageArrUsed"]
                                              ? filterData(
                                                  array,
                                                  "po_item_id",
                                                  currentValue,
                                                  "onlyOne"
                                                )["packagageArrUsed"].reduce(
                                                  (
                                                    previousValue1,
                                                    currentValue1
                                                  ) =>
                                                    Number(previousValue1) +
                                                    (currentValue1.total_dispatch
                                                      ? Number(
                                                          currentValue1.total_dispatch
                                                        )
                                                      : 0),
                                                  0
                                                )
                                              : 0
                                            : 0
                                        ),
                                      0
                                    )
                                    .toFixed(3)}${
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : ""
                                  }   `}
                                  <div
                                    style={{
                                      color: "#FF3D00",
                                      minWidth: "334px",
                                    }}
                                  >
                                    {Number(submittedData["Net Weight"]) ===
                                    selectedPVItemArray.reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) +
                                        Number(
                                          filterData(
                                            array,
                                            "po_item_id",
                                            currentValue,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                array,
                                                "po_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )["packagageArrUsed"]
                                              ? filterData(
                                                  array,
                                                  "po_item_id",
                                                  currentValue,
                                                  "onlyOne"
                                                )["packagageArrUsed"].reduce(
                                                  (
                                                    previousValue1,
                                                    currentValue1
                                                  ) =>
                                                    Number(previousValue1) +
                                                    (currentValue1.total_dispatch
                                                      ? Number(
                                                          currentValue1.total_dispatch
                                                        )
                                                      : 0),
                                                  0
                                                )
                                              : 0
                                            : 0
                                        ),
                                      0
                                    )
                                      ? ""
                                      : selectedPVItemArray.length > 0
                                      ? // singlelistView &&
                                        //   singlelistView.contract_type_details &&
                                        //   singlelistView.contract_type_details
                                        //     .contract_type === "ex factory"
                                        //   ?
                                        "(Net Weight and Total Quantity is not same!)"
                                      : // : ""
                                        ""}
                                  </div>
                                </>
                              ) : i === 0 ? (
                                <span style={{ fontWeight: "500" }}>
                                  {"Total"}
                                </span>
                              ) : i === 5 ? (
                                `${array.reduce(
                                  (previousValue1, currentValue1) =>
                                    Number(previousValue1) +
                                    (currentValue1["Total Item Quantity"]
                                      ? Number(
                                          currentValue1["Total Item Quantity"]
                                        )
                                      : 0),
                                  0
                                )} ${Unit} `
                              ) : (
                                ""
                              )}
                            </div>
                          )
                        )}
                      {edit === "checkBox"
                        ? ""
                        : edit && (
                            <div
                              className="justifyC d-flex alignC"
                              style={{
                                margin: "12px 10px",
                                width: "65px",
                                display: "flex",
                                alignItems: "center",
                                color: "#100f0f",
                                textTransform: "capitalize",
                              }}
                            ></div>
                          )}
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                  <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                    No PO Item added !
                  </span>
                </div>
              )}
            </Paper>
          </div>
        </>
      );
    };
    const SurvayITemTable = (
      edit,
      array,
      noTotalQuantity,
      showTotal,
      width,
      addBag,
      submittedData
    ) => {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            height: "auto",
            width: width ? width : edit ? "100%" : "calc(100% - 30px)",
            minWidth: width && "100%",
            fontSize: "0.9rem",
          }}
        >
          <Paper
            className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary"
            style={{
              height: "auto",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <div
              style={{
                backgroundColor: "#8080801a",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}
            >
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgb(128 128 128 / 64%)" }}
              >
                {survayItemheader &&
                  survayItemheader.map((name, i) =>
                    noTotalQuantity && name === "Total Item Quantity" ? (
                      ""
                    ) : (
                      <div
                        key={name}
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width:
                            widthList && widthList[survayItemheader[i]]
                              ? widthList[survayItemheader[i]]
                              : "fit-content",
                          minWidth: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}
                      >
                        {name}
                      </div>
                    )
                  )}
                {edit && (
                  <div
                    className="justifyC d-flex alignC"
                    style={{
                      margin: "12px 10px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}
                  >
                    Edit
                  </div>
                )}
              </div>
            </div>
            {array && array.length > 0 ? (
              <>
                <div
                  className="scrollBluePrimary"
                  style={{
                    height: "fit-content",
                    overflowY: "auto",
                    width: "fit-content",
                    minWidth: "100%",
                    position: "relative",
                    // background: "rgb(128 128 128 / 10%)",
                    boxShadow: "0px 0px 28px 0px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {console.log(array, "array12")}
                  {array.map(
                    (singleDate, i) =>
                      singleDate && (
                        <>
                          <div className="d-flex width-100 d-flex-column justifySE">
                            <div
                              className="d-flex width-100 justifySE"
                              style={{
                                // margin: "1px 0 0 0",
                                backgroundColor:
                                  colorArr && colorArr[i % colorArr.length],
                                height: edit === "checkBox" ? "44px" : "35px",
                              }}
                            >
                              {survayItemheader &&
                                survayItemheader.map((name) =>
                                  noTotalQuantity &&
                                  name === "Total Item Quantity" ? (
                                    ""
                                  ) : (
                                    <div
                                      key={name}
                                      className="d-flex alignC justifyC minWidthFC"
                                      style={{
                                        margin: "12px 10px",
                                        width:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        minWidth:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {singleDate[name]
                                        ? name === "Item Type"
                                          ? `${singleDate[name]} %`
                                          : singleDate[`${name}Unit`]
                                          ? singleDate[`${name}Unit`]
                                          : singleDate[name]
                                        : "-"}
                                    </div>
                                  )
                                )}
                              {edit && (
                                <div
                                  className="d-flex alignC justifyC minWidthFC"
                                  style={{
                                    margin: "12px 10px",
                                    width: "65px",
                                    minWidth: "50px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <StyledButton
                                    tooltip="Edit"
                                    name={<EditIcon />}
                                    width="60px"
                                    onClick={() => {
                                      const newArr = [];
                                      console.log(
                                        singleDate,
                                        "surveyorItemArrayCopy"
                                      );
                                      surveyorItemArrayCopy.map(
                                        (singleItem) => {
                                          if (singleItem.id === singleDate.id) {
                                          } else {
                                            newArr.push(singleItem);
                                          }
                                          return singleItem;
                                        }
                                      );
                                      const selectedSurveyor = filterData(
                                        allsurveyorList,
                                        "id",
                                        singleDate["Surveyorid"],
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            allsurveyorList,
                                            "id",
                                            singleDate["Surveyorid"],
                                            "onlyOne"
                                          )
                                        : "";
                                      const selectedSurveyorCompany =
                                        filterData(
                                          SurveyCompanyList,
                                          "id",
                                          singleDate["Survey Companyid"],
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              SurveyCompanyList,
                                              "id",
                                              singleDate["Survey Companyid"],
                                              "onlyOne"
                                            )
                                          : "";

                                      if (edit === "editSurvey") {
                                        singleDate["survayorCompanyId"] =
                                          selectedSurveyorCompany
                                            ? selectedSurveyorCompany.id
                                            : "";

                                        singleDate["survayorCompany"] =
                                          selectedSurveyorCompany
                                            ? selectedSurveyorCompany.name
                                            : "";
                                      }
                                      this.props
                                        .PurchaseOrdersFunction(
                                          "get",
                                          "masters/surveyor/list",
                                          null,
                                          "",
                                          null,
                                          {
                                            is_active: "True",
                                            company_id:
                                              singleDate["survayorCompanyId"],
                                          }
                                        )
                                        .then((res) => {
                                          if (res) {
                                            this.setState({
                                              survayorListForSurvay: res,
                                            });
                                          }
                                        });
                                      var speci = filterData(
                                        PO_Items_List,
                                        "id",
                                        Number(singleDate.po_item),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            PO_Items_List,
                                            "id",
                                            Number(singleDate.po_item),
                                            "onlyOne"
                                          ).specifications
                                        : "";

                                      // var brokenC,
                                      //   moistureC,
                                      //   chalkyC,
                                      //   damagedC = "";

                                      speci.map((item) => {
                                        if (item.condition === "equal_to") {
                                          item.condition = "Only";
                                        }
                                        if (item.condition === "not_equal_to") {
                                          item.condition = "Not";
                                        }
                                        if (item.condition === "greater_than") {
                                          item.condition = "Above";
                                        }
                                        if (
                                          item.condition ===
                                          "greater_than_or_equal_to"
                                        ) {
                                          item.condition = "Minimum";
                                        }
                                        if (item.condition === "less_than") {
                                          item.condition = "Below";
                                        }
                                        if (
                                          item.condition ===
                                          "less_than_or_equal_to"
                                        ) {
                                          item.condition = "Maximum";
                                        }
                                        item.name =
                                          this.state.specifiationTypes.find(
                                            (st) => st.id === item.name
                                          )
                                            ? this.state.specifiationTypes
                                                .find(
                                                  (st) => st.id === item.name
                                                )
                                                .text.toLowerCase()
                                            : item.name.toLowerCase();
                                        console.log(item.name, "nameeeee");

                                        if (item.name === "moisture") {
                                          this.setState({
                                            moistureCondition: item.condition,
                                            moistureValue: item.value,
                                          });
                                          // moistureC =
                                          //   item.condition + " " + item.value;
                                        }
                                        if (item.name === "broken") {
                                          this.setState({
                                            brokenCondition: item.condition,
                                            brokenValue: item.value,
                                          });
                                        }
                                        if (item.name === "chalky") {
                                          this.setState({
                                            chalkyCondition: item.condition,
                                            chalkyValue: item.value,
                                          });
                                          // chalkyC =
                                          //   item.condition + " " + item.value;
                                        }
                                        if (item.name === "damaged/coloured") {
                                          console.log("damageddd");
                                          this.setState({
                                            damagedCondition: item.condition,
                                            damagedValue: item.value,
                                          });

                                          // damagedC =
                                          //   item.condition + " " + item.value;
                                        }
                                        if (item.name === "double polish") {
                                          console.log("double");

                                          this.setState({
                                            doubleCondition: item.condition,
                                            doubleValue: item.value,
                                          });
                                        }
                                        if (item.name === "length") {
                                          this.setState({
                                            lengthCondition: item.condition,
                                            lengthValue: item.value,
                                          });
                                        }
                                        if (item.name === "greenGrain") {
                                          this.setState({
                                            greenGrainCondition: item.condition,
                                            greenGrainValue: item.value,
                                          });
                                        }
                                        if (item.name === "wholeRedGrain") {
                                          this.setState({
                                            wholeRedGrainCondition:
                                              item.condition,
                                            wholeRedGrainValue: item.value,
                                          });
                                        }
                                        if (
                                          item.name === "averageGrainLength"
                                        ) {
                                          this.setState({
                                            averageGrainLengthCondition:
                                              item.condition,
                                            averageGrainLengthValue: item.value,
                                          });
                                        }
                                        if (item.name === "paddy") {
                                          this.setState({
                                            paddyCondition: item.condition,
                                            paddyValue: item.value,
                                          });
                                        }
                                        if (item.name === "foreign matter") {
                                          this.setState({
                                            foreignCondition: item.condition,
                                            foreignValue: item.value,
                                          });
                                        }
                                      });
                                      const brandSelected = filterData(
                                        poItemsArrayTOPreview,
                                        "id",
                                        Number(singleDate.po_item),
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            poItemsArrayTOPreview,
                                            "id",
                                            Number(singleDate.po_item),
                                            "onlyOne"
                                          )
                                        : "";
                                      {
                                        console.log(singleDate, "mmmmmmmmmm");
                                      }
                                      this.setState(
                                        {
                                          selectedSrvayorid: singleDate.id,
                                          selectedPoIdinSiurvayor:
                                            singleDate.po_item,
                                          itemsId: singleDate["Item Typeid"],
                                          ItemType: singleDate["Item Type"],
                                          ItemCategoryId:
                                            singleDate["Item Categoryid"],
                                          ItemCategory:
                                            singleDate["Item Category"],
                                          surveyorItemArray: newArr,
                                          surveyorItemArrayCopy:
                                            surveyorItemArrayCopy,
                                          BrandNameId: singleDate["Brandid"],
                                          BrandName: singleDate["Brand"],
                                          Broken: singleDate["Broken"],
                                          Moisture: singleDate["Moisture"],
                                          Length: singleDate["Length"],
                                          Damaged:
                                            singleDate["Damaged/Discoloured"],
                                          Chalky: singleDate["Chalky"],
                                          Paddy: singleDate["Paddy"],
                                          GreenGrain: singleDate["Green Grain"],
                                          WholeRedGrain:
                                            singleDate["Whole Red Grain"],
                                          AverageGrainLength:
                                            singleDate["Average Grain Length"],

                                          Foreign: singleDate["Foreign Matter"],
                                          Double: singleDate["Double Polish"],
                                          REMARK: singleDate["REMARK"],
                                          survayor: singleDate["Surveyor"],
                                          survayorId: singleDate["Surveyorid"],
                                          survayorCompany:
                                            singleDate["survayorCompany"],
                                          survayorCompanyId:
                                            singleDate["survayorCompanyId"],
                                          // SamplingDate:["Sampling Date"],

                                          selectedBrandMultipleName: `${singleDate["Brand"]}-${singleDate["Item Category"]}-${singleDate["Item Type"]} % - ${brandSelected["total_quantity"]} `,
                                        },
                                        () => {}
                                      );
                                      console.log(
                                        brandSelected,
                                        "brandSelected"
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            <Divider className="width-100" />
                          </div>

                          {/* <Divider /> */}
                        </>
                      )
                  )}
                </div>
              </>
            ) : (
              <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                  No Survey Item added !
                </span>
              </div>
            )}
          </Paper>

          {(edit === "editSurvey" &&
            surveyorItemArrayCopy.length > surveyorItemArray.length) ||
          edit !== "editSurvey" ? (
            <>
              {/* <div style={{ marginTop: "25px" }}> */}
              <CssTextField
                required={true}
                autoComplete="off"
                value={
                  selectedBrandMultipleName !== false
                    ? selectedBrandMultipleName
                    : ""
                }
                error={errorMsg.BrandName ? true : false}
                helperText={errorMsg.BrandName ? errorMsg.BrandName : ""}
                onChange={(e) => {
                  this.setState({
                    chalkyCondition: "",
                  });
                  const errorMsg = this.state.errorMsg;
                  errorMsg.BrandName = false;
                  // // errorMsg.ItemCategory = false;
                  // errorMsg.ItemType = false;
                  const brandSelected =
                    poItemsArrayTOPreview &&
                    poItemsArrayTOPreview.length > 0 &&
                    poItemsArrayTOPreview.filter(
                      (option) =>
                        option.id.toString() === e.currentTarget.id.toString()
                    );

                  var specif = filterData(
                    PO_Items_List,
                    "id",
                    Number(e.currentTarget.id),
                    "onlyOne"
                  )
                    ? filterData(
                        PO_Items_List,
                        "id",
                        Number(e.currentTarget.id),
                        "onlyOne"
                      ).specifications
                    : "";
                  const allSpecifi = [];
                  specif.map((item) => {
                    if (item.condition === "equal_to") {
                      item.condition = "Only";
                    }
                    if (item.condition === "not_equal_to") {
                      item.condition = "Not";
                    }
                    if (item.condition === "greater_than") {
                      item.condition = "Above";
                    }
                    if (item.condition === "greater_than_or_equal_to") {
                      item.condition = "Minimum";
                    }
                    if (item.condition === "less_than") {
                      item.condition = "Below";
                    }
                    if (item.condition === "less_than_or_equal_to") {
                      item.condition = "Maximum";
                    }
                    item.name = this.state.specifiationTypes.find(
                      (st) => st.id === item.name
                    )
                      ? this.state.specifiationTypes.find(
                          (st) => st.id === item.name
                        ).text
                      : item.name;
                    allSpecifi.push(item);
                  });
                  console.log(
                    this.props.masters,
                    e.target.value,
                    e.target.value.split("-")
                  );
                  let barndDetail = e.target.value.split("-");

                  const iCName =
                    // brandSelected && brandSelected[0]["Item Category"];
                    barndDetail[1];

                  const iTname =
                    // brandSelected && brandSelected[0]["Item Type"];
                    barndDetail[2].replace("%", "").trim();
                  this.setState({
                    errorMsg,
                    selectedPoIdinSiurvayor: e.currentTarget.id,
                    BrandNameId: brandSelected && brandSelected[0]["Brandid"],
                    BrandName: brandSelected && brandSelected[0]["Brand"],
                    ItemCategoryId: this.props.masters?.ItemCateoryList.find(
                      (d) => d.name == iCName
                    )?.id,
                    // brandSelected && brandSelected[0]["Item Categoryid"],
                    ItemCategory: iCName,
                    itemsId: this.props.masters?.ItemList.find(
                      (d) => d.broken_percentage == iTname
                    )?.id,
                    ItemType: iTname,
                    selectedBrandMultipleName: e.target.value,
                    allSpecifi: allSpecifi,
                  });
                }}
                style={
                  { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                }
                id="outlined-select-currency-native"
                select
                label="Brand"
                variant="outlined"
                InputLabelProps={{}}
              >
                {poItemsArrayTOPreview &&
                  poItemsArrayTOPreview.length > 0 &&
                  poItemsArrayTOPreview
                    .filter(
                      (ss) => !array?.find((s) => Number(s.po_item) === ss.id)
                    )
                    .map((poItem) => (
                      <MenuItem
                        key={poItem.Brand}
                        value={`${poItem.Brand}-${poItem["Item Category"]}-${poItem["Item Type"]} % - ${poItem["total_quantity"]} `}
                        id={poItem.id}
                      >
                        {`${poItem.Brand}-${poItem["Item Category"]}-${poItem["Item Type"]} % - ${poItem["total_quantity"]}`}
                      </MenuItem>
                    ))}
              </CssTextField>
              <CssTextField
                required={true}
                disabled={true}
                autoComplete="off"
                value={
                  ItemCategory ? ItemCategory : "" //
                }
                error={errorMsg.ItemCategory ? true : false}
                helperText={errorMsg.ItemCategory ? errorMsg.ItemCategory : ""}
                onChange={(e) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.ItemCategory = false;
                  this.setState({
                    errorMsg,
                    ItemCategoryId: e.currentTarget.id,
                    ItemCategory: e.target.value,
                  });
                }}
                style={
                  { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                }
                id="outlined-select-currency-native"
                select
                label="Item Category"
                variant="outlined"
                // disabled={true}
                InputLabelProps={{}}
              >
                {ItemCateoryList &&
                  ItemCateoryList.length > 0 &&
                  ItemCateoryList.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      id={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </CssTextField>
              <CssTextField
                required={true}
                disabled={true}
                autoComplete="off"
                value={
                  ItemType ? ItemType : "" //
                }
                error={errorMsg.ItemType ? true : false}
                helperText={errorMsg.ItemType ? errorMsg.ItemType : ""}
                onChange={(e) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.ItemType = false;
                  this.setState({
                    errorMsg,
                    itemsId: e.currentTarget.id,
                    ItemType: e.target.value,
                  });
                }}
                style={
                  { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                }
                id="outlined-select-currency-native"
                select
                label="Item Type"
                variant="outlined"
                // disabled={true}
                InputLabelProps={{}}
              >
                {ItemList &&
                  ItemList.length > 0 &&
                  ItemList.map((option) => (
                    <MenuItem
                      key={option.broken_percentage}
                      value={option.broken_percentage}
                      id={option.id}
                    >
                      {`${option.broken_percentage} %`}
                    </MenuItem>
                  ))}
              </CssTextField>

              {BrandNameId &&
              this.state.allSpecifi &&
              this.state.allSpecifi.length > 0
                ? this.state.allSpecifi.map((item) => {
                    console.log(
                      item,
                      this.state.specifiationTypes,
                      this.state.specifiationTypes.find(
                        (st) =>
                          st.text.toLowerCase() === item.name.toLowerCase()
                      ),
                      "this.state.specifiationTypes.find((st)=>st.text===item.name)?this.state.specifiationTypes.find((st)=>st.text===item.name).value_type.toLowerCase()"
                    );
                    return (
                      <CssTextField
                        required={true}
                        type={
                          this.state.specifiationTypes.find(
                            (st) =>
                              st.text.toLowerCase() === item.name.toLowerCase()
                          )
                            ? this.state.specifiationTypes
                                .find(
                                  (st) =>
                                    st.text.toLowerCase() ===
                                    item.name.toLowerCase()
                                )
                                .value_type.toLowerCase()
                            : "number"
                        }
                        onFocus={StopScroll}
                        autoComplete="off"
                        style={
                          { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                        }
                        onKeyDown={(evt) => {
                          evt.key === "e" && evt.preventDefault();

                          evt.key === "E" && evt.preventDefault();
                          evt.key === "-" && evt.preventDefault();
                        }}
                        error={errorMsg[item.name] ? true : false}
                        helperText={
                          <div
                            style={{
                              fontStyle: "italic",
                            }}
                          >
                            {errorMsg[item.name]
                              ? errorMsg[item.name]
                              : item.condition
                              ? item.condition +
                                " " +
                                item.value +
                                ` ${
                                  this.state.specifiationTypes.find(
                                    (st) => st.text === item.name
                                  )
                                    ? this.state.specifiationTypes
                                        .find((st) => st.text === item.name)
                                        .unit.toLowerCase()
                                    : ""
                                }`
                              : ""}
                          </div>
                        }
                        id="outlined-basic"
                        label={item.name}
                        InputProps={{
                          endAdornment: this.state.specifiationTypes.find(
                            (st) => st.text === item.name
                          )
                            ? this.state.specifiationTypes
                                .find((st) => st.text === item.name)
                                .unit.toLowerCase()
                            : "",
                        }}
                        variant="outlined"
                        value={this.state[item.name] ?? ""}
                        name={item.name}
                        // onChange={(event) => {
                        //   if (chalkyCondition.length > 0) {
                        //     if (chalkyCondition === "Minimum") {
                        //       if (event.target.value >= chalkyValue) {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] = false;
                        //         this.setState({
                        //           [event.target.name]: event.target.value,
                        //           errorMsg,
                        //         });
                        //       } else {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] =
                        //           chalkyCondition + " " + chalkyValue + " %";
                        //         this.setState({
                        //           errorMsg,
                        //         });
                        //       }
                        //     }
                        //     if (chalkyCondition === "Maximum") {
                        //       if (event.target.value <= chalkyValue) {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] = false;
                        //         this.setState({
                        //           [event.target.name]: event.target.value,
                        //           errorMsg,
                        //         });
                        //       } else {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] =
                        //           chalkyCondition + " " + chalkyValue + " %";
                        //         this.setState({
                        //           errorMsg,
                        //         });
                        //       }
                        //     }
                        //     if (chalkyCondition === "Below") {
                        //       if (event.target.value < chalkyValue) {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] = false;
                        //         this.setState({
                        //           [event.target.name]: event.target.value,
                        //           errorMsg,
                        //         });
                        //       } else {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] =
                        //           chalkyCondition + " " + chalkyValue + " %";
                        //         this.setState({
                        //           errorMsg,
                        //         });
                        //       }
                        //     }
                        //     if (chalkyCondition === "Above") {
                        //       if (event.target.value > chalkyValue) {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] = false;
                        //         this.setState({
                        //           [event.target.name]: event.target.value,
                        //           errorMsg,
                        //         });
                        //       } else {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] =
                        //           chalkyCondition + " " + chalkyValue + " %";
                        //         this.setState({
                        //           errorMsg,
                        //         });
                        //       }
                        //     }
                        //     if (chalkyCondition === "Only") {
                        //       if (event.target.value === chalkyValue) {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] = false;
                        //         this.setState({
                        //           [event.target.name]: event.target.value,
                        //           errorMsg,
                        //         });
                        //       } else {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] =
                        //           chalkyCondition + " " + chalkyValue + " %";
                        //         this.setState({
                        //           errorMsg,
                        //         });
                        //       }
                        //     }
                        //     if (chalkyCondition === "Not") {
                        //       if (event.target.value !== chalkyValue) {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] = false;
                        //         this.setState({
                        //           [event.target.name]: event.target.value,
                        //           errorMsg,
                        //         });
                        //       } else {
                        //         const errorMsg = this.state.errorMsg;
                        //         errorMsg[event.target.name] =
                        //           chalkyCondition + " " + chalkyValue + " %";
                        //         this.setState({
                        //           errorMsg,
                        //         });
                        //       }
                        //     }
                        //   } else {
                        //     const errorMsg = this.state.errorMsg;
                        //     errorMsg[event.target.name] = false;
                        //     this.setState({
                        //       [event.target.name]: event.target.value,
                        //       errorMsg,
                        //     });
                        //   }
                        // }}
                        onChange={this.handleChange}
                      />
                    );
                  })
                : ""}

              <CssTextField
                // required={true}
                type="text"
                autoComplete="off"
                style={
                  { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                }
                // error={errorMsg.REMARK ? true : false}
                // helperText={errorMsg.REMARK ? errorMsg.REMARK : ""}
                id="outlined-basic"
                label="Remark"
                variant="outlined"
                name="REMARK"
                value={REMARK}
                onChange={this.handleChange}
              />
              <CssTextField
                required={true}
                autoComplete="off"
                value={
                  survayorCompany ? survayorCompany : "" //
                }
                error={errorMsg.survayorCompany ? true : false}
                helperText={
                  errorMsg.survayorCompany ? errorMsg.survayorCompany : ""
                }
                onChange={(e) => {
                  this.setState({ survayorId: "", survayor: "" });
                  const errorMsg = this.state.errorMsg;
                  errorMsg.survayorCompany = false;
                  if (SurveyCompanyList && SurveyCompanyList.length > 0) {
                    this.props
                      .PurchaseOrdersFunction(
                        "get",
                        "masters/surveyor/list",
                        null,
                        "",
                        null,
                        {
                          is_active: "True",
                          company_id: e.currentTarget.id,
                        }
                      )
                      .then((res) => {
                        if (res) {
                          this.setState({ survayorListForSurvay: res });
                        }
                      });
                  }
                  this.setState(
                    {
                      errorMsg,

                      survayorCompany: e.target.value,
                      survayorCompanyId: e.currentTarget.id,
                    },
                    () => {}
                  );
                }}
                style={
                  { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                }
                id="outlined-select-currency-native"
                select
                label="Survey Company"
                variant="outlined"
                InputLabelProps={{}}
              >
                {SurveyCompanyList &&
                  SurveyCompanyList.length > 0 &&
                  SurveyCompanyList.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      id={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </CssTextField>

              {survayorCompanyId && (
                <CssTextField
                  // required={true}
                  autoComplete="off"
                  value={
                    survayor ? survayor : "" //
                  }
                  // error={errorMsg.survayor ? true : false}
                  // helperText={errorMsg.survayor ? errorMsg.survayor : ""}
                  onChange={(e) => {
                    // const errorMsg = this.state.errorMsg;
                    // errorMsg.survayor = false;
                    this.setState({
                      // errorMsg,
                      survayor: e.target.value,
                      survayorId: e.currentTarget.id,
                    });
                  }}
                  style={
                    { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                  }
                  id="outlined-select-currency-native"
                  select
                  label="Surveyor"
                  variant="outlined"
                  InputLabelProps={{}}
                >
                  {survayorListForSurvay &&
                    survayorListForSurvay.length > 0 &&
                    survayorListForSurvay.map((option) => (
                      <MenuItem
                        key={option.name}
                        value={option.name}
                        id={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                </CssTextField>
              )}

              <div
                //className="d-flex alignC justifyFE"
                style={{
                  margin: "10px",
                  //width: "calc(25% - 20px)",
                  minWidth: "calc(25% - 20px)",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <StyledButton
                  name="clear"
                  onClick={() => {
                    this.setState({
                      ItemCategoryId: "",
                      ItemCategory: "",
                      ItemType: "",
                      ItemTypeId: "",
                      BrandNameId: "",
                      BrandName: false,
                      REMARK: "",
                      survayor: "",
                      survayorId: "",
                      survayorCompany: "",
                      survayorCompanyId: "",
                      selectedBrandMultipleName: false,
                      selectedPoIdinSiurvayor: "",
                    });
                  }}
                />
                <StyledButton
                  onClick={() => {
                    let g = 0;
                    if (BrandName !== false) {
                      this.state.allSpecifi.map((item) => {
                        if (this.state[item.name]) {
                          if (item.condition === "Minimum") {
                            if (
                              Number(this.state[item.name]) >=
                              Number(item.value)
                            ) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] = false;

                              this.setState({
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] =
                                item.condition +
                                " " +
                                item.value +
                                this.state.specifiationTypes.find(
                                  (st) => st.text === item.name
                                )
                                  ? this.state.specifiationTypes
                                      .find((st) => st.text === item.name)
                                      .unit.toLowerCase()
                                  : "";
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else if (item.condition === "Maximum") {
                            if (
                              Number(this.state[item.name]) <=
                              Number(item.value)
                            ) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] = false;

                              this.setState({
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] =
                                item.condition +
                                " " +
                                item.value +
                                this.state.specifiationTypes.find(
                                  (st) => st.text === item.name
                                )
                                  ? this.state.specifiationTypes
                                      .find((st) => st.text === item.name)
                                      .unit.toLowerCase()
                                  : "";
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else if (item.condition === "Below") {
                            if (
                              Number(this.state[item.name]) < Number(item.value)
                            ) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] = false;

                              this.setState({
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] =
                                item.condition +
                                " " +
                                item.value +
                                this.state.specifiationTypes.find(
                                  (st) => st.text === item.name
                                )
                                  ? this.state.specifiationTypes
                                      .find((st) => st.text === item.name)
                                      .unit.toLowerCase()
                                  : "";
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else if (item.condition === "Above") {
                            if (
                              Number(this.state[item.name]) > Number(item.value)
                            ) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] = false;

                              this.setState({
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] =
                                item.condition +
                                " " +
                                item.value +
                                this.state.specifiationTypes.find(
                                  (st) => st.text === item.name
                                )
                                  ? this.state.specifiationTypes
                                      .find((st) => st.text === item.name)
                                      .unit.toLowerCase()
                                  : "";
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else if (item.condition === "Only") {
                            if (
                              Number(this.state[item.name]) ===
                              Number(item.value)
                            ) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] = false;

                              this.setState({
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] =
                                item.condition +
                                " " +
                                item.value +
                                this.state.specifiationTypes.find(
                                  (st) => st.text === item.name
                                )
                                  ? this.state.specifiationTypes
                                      .find((st) => st.text === item.name)
                                      .unit.toLowerCase()
                                  : "";
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else if (item.condition === "Not") {
                            if (
                              Number(this.state[item.name]) !==
                              Number(item.value)
                            ) {
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] = false;

                              this.setState({
                                errorMsg,
                              });
                            } else {
                              g++;
                              const errorMsg = this.state.errorMsg;
                              errorMsg[item.name] =
                                item.condition +
                                " " +
                                item.value +
                                this.state.specifiationTypes.find(
                                  (st) => st.text === item.name
                                )
                                  ? this.state.specifiationTypes
                                      .find((st) => st.text === item.name)
                                      .unit.toLowerCase()
                                  : "";
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else {
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg[item.name] = `Please give ${item.name}!`;

                          this.setState({ errorMsg });
                        }
                      });
                      if (survayorCompany) {
                        // if (survayor) {
                        if (g === 0) {
                          const newsurveyorItemArray = surveyorItemArrayCopy;
                          const addData = {};
                          addData.id = selectedSrvayorid
                            ? selectedSrvayorid
                            : newsurveyorItemArray.length + 1;
                          addData.po_item_id = selectedSrvayorid
                            ? selectedSrvayorid
                            : newsurveyorItemArray.length + 1;
                          addData.po_item = selectedPoIdinSiurvayor;
                          addData[survayItemheader[0]] = BrandName;
                          addData[`${survayItemheader[0]}id`] = BrandNameId;
                          addData[survayItemheader[1]] = ItemCategory;
                          addData[`${survayItemheader[1]}id`] = ItemCategoryId;
                          addData[survayItemheader[2]] = ItemType;
                          addData[`${survayItemheader[2]}id`] = itemsId;
                          this.state.allSpecifi.map((item) => {
                            addData[item.name] = this.state[item.name];
                          });

                          addData[survayItemheader[14]] = REMARK;
                          // addData["survayorCompany"] = survayorCompany;
                          // addData["survayorCompanyId"] = survayorCompanyId;
                          addData[survayItemheader[15]] = survayorCompany;
                          addData[`${survayItemheader[15]}id`] =
                            survayorCompanyId;
                          addData[survayItemheader[16]] = survayor;
                          addData[`${survayItemheader[16]}id`] = survayorId;

                          const newArr = [];
                          console.log(addData, "add data");
                          if (newsurveyorItemArray.length > 0) {
                            newsurveyorItemArray.map((s) => {
                              if (s.id === addData.id) {
                                newArr.push(addData);
                              } else {
                                newArr.push(s);
                              }
                              return s;
                            });
                            if (!Boolean(selectedSrvayorid)) {
                              newArr.push(addData);
                            }
                          } else {
                            newArr.push(addData); //first time
                          }
                          this.setState(
                            {
                              surveyorItemArray: newArr,
                              surveyorItemArrayCopy: newArr,
                            },
                            () => {
                              this.setWidth(
                                this.state.surveyorItemArray,
                                survayItemheader
                              );
                              this.setState({
                                Broken: "",
                                Moisture: "",
                                ItemCategoryId: "",
                                ItemCategory: "",
                                ItemType: "",
                                ItemTypeId: "",
                                BrandNameId: "",
                                BrandName: false,
                                Length: "",
                                Damaged: "",
                                Chalky: "",
                                Paddy: "",
                                Foreign: "",
                                GreenGrain: "",
                                WholeRedGrain: "",
                                AverageGrainLength: "",
                                Double: "",
                                REMARK: "",
                                survayor: "",
                                survayorId: "",
                                survayorCompany: "",
                                survayorCompanyId: "",
                                selectedBrandMultipleName: false,
                                selectedPoIdinSiurvayor: "",
                                //SamplingDate:""
                              });
                            }
                          );
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.survayorCompany =
                          "Please give SurveyorCompany!";
                        this.setState({
                          errorMsg,
                        });
                      }
                    } else {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.BrandName = "Please select Brand Name!";
                      this.setState({ errorMsg });
                    }
                  }}
                  name={`${
                    editDialog !== false ? "Update" : "Add"
                  } Item Survey`}
                  minWidth="30px"
                  width="150px"
                  margin="0 10px"
                  withBg={true}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      );
    };
    const detailDialog = (onlyDialog) => {
      return (
        <Details
          listClickHandler={this.listClickHandler}
          updateSelectedTabData={this.updateSelectedTabData}
          selectHandler={this.selectHandler}
          singlelistView={this.state.singlelistView}
          onlyDialog={onlyDialog ? onlyDialog : true}
          {...this.props}
          {...this.state}
          POITemTable={POITemTable}
          InlandGodownList={InlandGodownList}
          closeDialog={() => {
            this.setState(
              {
                openDialog: false,
              },
              () => {}
            );
          }}
          openDialogFunct={async () => {
            return this.setState({
              openDialog: true,
            });
          }}
          clearPoItems={() => {
            this.setState({
              poItemsArrayCopy: [],
              poItemsArray: [],
            });
          }}
          setPoItems={(poItemsArrayCopy, poItemsArray) => {
            this.setState(
              poItemsArrayCopy && poItemsArray
                ? {
                    poItemsArrayCopy,
                    poItemsArray,
                  }
                : poItemsArrayCopy
                ? { poItemsArrayCopy }
                : poItemsArray
                ? { poItemsArray }
                : {},
              () => {
                this.setWidth(this.state.poItemsArray);
              }
            );
          }}
          setPoItemsPrev={(
            poItemsArrayTOPreview,
            poItemsArrayTOPreviewCopy,
            poItemsArrayTOPreviewCopy2
          ) => {
            this.setState({
              poItemsArrayTOPreview,
              poItemsArrayTOPreviewCopy,
              poItemsArrayTOPreviewCopy2,
            });
          }}
        />
      );
    };

    return (
      <Layout
        pagination={this.state.pagination}
        // RaisedMonthBar={
        //   selctedStatus === "CLOSED"
        //     ? { hintText: "Search Months", labelField: "month" }
        //     : null
        // }
        tabs={tabs}
        noTab={true}
        loading={
          loading[EndPoints["PurchaseOrders"]]
            ? loading[EndPoints["PurchaseOrders"]] === true
              ? true
              : loader === true
              ? true
              : false
            : loader === true
            ? true
            : false
        }
        RaisedSelectBar={{
          data: status ? status : [],
          hintText: "Select status",
          labelField: "name",
          selctedData: "OPEN",
          newSelectedData: this.state.selctedStatus,
        }}
        fabClickHandler={(value) => {
          this.setState(
            {
              poItemsArray: [],
              poItemsArrayCopy: [],
              openDialog: true,
              loadingFun: false,
              added: false,
              editDialog: false,
              BrandName: false,
              selectedBrandMultipleName: false,
            },
            () => {
              this.reset("no snac");
              this.handleClearFields();
            }
          );
        }}
        listClickHandler={this.listClickHandler}
        registerListClickHandler={(handler) =>
          (this.childListClickHandler = handler)
        }
        serchClick={this.searchHandler}
        selectHandler={this.selectHandler}
        searchData={{
          search: true,
          searchPlaceholder: "Search PO",
          onChangeSearchSelect: this.handlePOSearch,
        }}
        dateRange={{
          datePicker: true,
          onChangeDateSelect: this.handlePODateRange,
        }}
        selectedStatus={this.state?.selctedStatus}
        secondLineUI={(rowData) => (
          <Chip
            label={`${rowData?.contract_type_details?.contract_type || ""}${
              rowData?.contract_rate ? " | " + "₹" + rowData?.contract_rate : ""
            }`}
            variant="outlined"
            size="small"
            sx={{
              fontSize: 12,
              backgroundColor:
                rowData?.contract_type_details?.contract_type === "FOR"
                  ? "#FFE0B4"
                  : "#F9C794",
              borderColor:
                rowData?.contract_type_details?.contract_type === "FOR"
                  ? "#FFE0B4"
                  : "#F9C794",
              color: "#333",
              fontWeight: "bold",
              padding: "4px 8px",
              fontFamily: "Poppins",
              margin: "6px 0",
              // height: 18,
              transform: `scale(0.95)`,
              "& .MuiChip-icon": {
                color: "#333",
              },
              "& .MuiChip-label": {
                paddingLeft: 1, // Remove extra padding on the left
              },
            }}
          />
        )}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        {openAddBagDialog !== false ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100% - 40px)";
                target.style.minHeight = "300px";

                target.style.height = "fit-content";
                target.style.minWidth = "350px";
                target.style.maxWidth = "350px";
                target.style.margin = "0";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              if (
                openAddBagDialog !== false &&
                openAddBagDialog["packagageArrUsed"] &&
                openAddBagDialog["packagageArrUsed"].length > 0
              ) {
                openAddBagDialog["packagageArrUsed"].map((sData) => {
                  if (sData.remaining_bags) {
                    this.setState({
                      [`${sData.id}Remaining`]: false,
                    });
                  } else {
                  }
                  return sData;
                });
              }
              this.setState({
                openAddBagDialog: false,
              });
            }}
            TransitionComponent={Transition}
          >
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              onClick={() => {
                if (
                  openAddBagDialog !== false &&
                  openAddBagDialog["packagageArrUsed"] &&
                  openAddBagDialog["packagageArrUsed"].length > 0
                ) {
                  openAddBagDialog["packagageArrUsed"].map((sData) => {
                    if (sData.remaining_bags) {
                      this.setState({
                        [`${sData.id}Remaining`]: false,
                      });
                    } else {
                    }
                    return sData;
                  });
                }
                this.setState({
                  openAddBagDialog: false,
                });
              }}
            >
              <h3 style={styles.heading}>Add Bags</h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>
            <DialogContent
              className="dashedDividerBottom  width-100 d-flex alignC justifyC d-flex-column"
              style={{ height: "100%" }}
            >
              {this.state.loadingBags && this.state.loadingBags === true ? (
                <Backdrop
                  style={{ zIndex: 1, color: "#fff" }}
                  open={this.state.loadingBags ? this.state.loadingBags : false}
                >
                  <StyledCircularProgress color="rgb(241, 154, 48)" />
                </Backdrop>
              ) : (
                ""
              )}
              {this.state.bagerror || this.state.bagsuccess ? (
                <SnackbarComponent
                  autoHideDuration={this.state.bagerror ? 3000 : 2000}
                  error={this.state.bagerror}
                  success={this.state.bagsuccess}
                  snackbarClose={() => {
                    if (
                      openAddBagDialog !== false &&
                      openAddBagDialog["packagageArrUsed"] &&
                      openAddBagDialog["packagageArrUsed"].length > 0
                    ) {
                      openAddBagDialog["packagageArrUsed"].map((sData) => {
                        if (sData.remaining_bags) {
                          this.setState({
                            [`${sData.id}Remaining`]: false,
                          });
                        } else {
                        }
                        return sData;
                      });
                    }
                    this.setState({
                      openAddBagDialog: false,
                      bagerror: "",
                      bagsuccess: "",
                    });
                  }}
                />
              ) : (
                ""
              )}
              {this.state.poBagAdded && this.state.poBagAdded !== false ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#1a9520",
                    }}
                  />
                  <span
                    className="heading"
                    style={{ color: "#1a9520", margin: "10px 0 0 0" }}
                  >
                    {this.state.poBagAdded}
                  </span>
                </div>
              ) : (
                <>
                  {openAddBagDialog &&
                    openAddBagDialog["packagageArrUsed"] &&
                    openAddBagDialog["packagageArrUsed"].map(
                      (singlePackaging) => (
                        <div
                          key={singlePackaging.sizeUnit}
                          className="d-flex-column"
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "1rem",
                            color: "rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {`${singlePackaging.sizeUnit} Total ${
                            this.state[`${singlePackaging.id}Remaining`]
                              ? subtract(
                                  this.state[`${singlePackaging.id}Remaining`],
                                  singlePackaging.used_bags
                                    ? singlePackaging.used_bags
                                    : 0
                                )
                              : singlePackaging.remaining_bags
                              ? singlePackaging.remaining_bags
                                ? Number(singlePackaging.remaining_bags)
                                : "NA"
                              : "NA"
                          }  ${
                            singlePackaging.bag_type
                              ? `${singlePackaging.bag_type}`
                              : filterData(
                                  openAddBagDialog.packagingArr,
                                  "packaging",
                                  singlePackaging.packaging,
                                  "onlyOne"
                                )
                              ? filterData(
                                  openAddBagDialog.packagingArr,
                                  "packaging",
                                  singlePackaging.packaging,
                                  "onlyOne"
                                ).packaging_details.bag_type &&
                                filterData(
                                  openAddBagDialog.packagingArr,
                                  "packaging",
                                  singlePackaging.packaging,
                                  "onlyOne"
                                ).packaging_details.bag_type
                              : ""
                          } bags `}{" "}
                          {error || success ? (
                            <SnackbarComponent
                              error={error}
                              autoHideDuration={error ? 3000 : 2000}
                              success={success}
                              snackbarClose={() => {
                                this.setState({
                                  error: "",
                                  success: "",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                          <CssTextField95
                            required={true}
                            onInput={(e) => {
                              if (
                                e.target.value >
                                (this.state[`${singlePackaging.id}Remaining`]
                                  ? this.state[`${singlePackaging.id}Remaining`]
                                  : singlePackaging.remaining_bags
                                  ? singlePackaging.remaining_bags
                                    ? Number(singlePackaging.remaining_bags)
                                    : 0
                                  : 0)
                              ) {
                                this.setState({
                                  error: "Can't be greater than total bags",
                                });
                              }
                              e.target.value = e.target.value
                                ? e.target.value >
                                  (this.state[`${singlePackaging.id}Remaining`]
                                    ? this.state[
                                        `${singlePackaging.id}Remaining`
                                      ]
                                    : singlePackaging.remaining_bags
                                    ? singlePackaging.remaining_bags
                                      ? Number(singlePackaging.remaining_bags)
                                      : 0
                                    : 0)
                                  ? this.state[`${singlePackaging.id}Remaining`]
                                    ? this.state[
                                        `${singlePackaging.id}Remaining`
                                      ]
                                    : singlePackaging.remaining_bags
                                    ? singlePackaging.remaining_bags
                                      ? Number(singlePackaging.remaining_bags)
                                      : 0
                                    : 0
                                  : e.target.value
                                : "";
                            }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: this.state[
                                  `${singlePackaging.id}Remaining`
                                ]
                                  ? this.state[`${singlePackaging.id}Remaining`]
                                  : singlePackaging.remaining_bags
                                  ? singlePackaging.remaining_bags
                                    ? Number(singlePackaging.remaining_bags)
                                    : 0
                                  : 0,
                              },
                            }}
                            type="number"
                            onFocus={StopScroll}
                            autoComplete="off"
                            id="outlined-basic"
                            error={
                              singlePackaging.remaining_bags ? false : true
                            }
                            label="Used Bags"
                            style={{ width: "100%", margin: "10px 0" }}
                            variant="outlined"
                            disabled={
                              singlePackaging.remaining_bags ? false : true
                            }
                            value={
                              submittedDataOfUsedBags[
                                openAddBagDialog.po_item_id
                              ]
                                ? filterData(
                                    submittedDataOfUsedBags[
                                      openAddBagDialog.po_item_id
                                    ],
                                    "id",
                                    singlePackaging.id,
                                    "onlyOne"
                                  )
                                  ? filterData(
                                      submittedDataOfUsedBags[
                                        openAddBagDialog.po_item_id
                                      ],
                                      "id",
                                      singlePackaging.id,
                                      "onlyOne"
                                    )["used_bags"]
                                    ? filterData(
                                        submittedDataOfUsedBags[
                                          openAddBagDialog.po_item_id
                                        ],
                                        "id",
                                        singlePackaging.id,
                                        "onlyOne"
                                      )["used_bags"]
                                    : ""
                                  : ""
                                : singlePackaging["used_bags"]
                                ? singlePackaging["used_bags"]
                                : ""
                            }
                            onChange={(e) => {
                              const singlePo = singlePackaging;
                              const newPacks =
                                openAddBagDialog["packagageArrUsed"];
                              const newA = [];
                              newPacks.map((sM) => {
                                if (sM.id === singlePo.id) {
                                  const SelectedUnit =
                                    this.state.enteredDispatchdData &&
                                    this.state.enteredDispatchdData["Unit"];
                                  sM["used_bags"] =
                                    e.target.value && e.target.value !== 0
                                      ? e.target.value
                                      : undefined;

                                  sM["total_dispatch"] =
                                    e.target.value && e.target.value !== 0
                                      ? multiply(
                                          Number(
                                            sM["used_bags"]
                                              ? sM["used_bags"]
                                              : 0
                                          ),
                                          this.changeUnit(
                                            singlePo.unit,
                                            SelectedUnit,
                                            singlePo.size
                                          )
                                        )
                                      : 0;

                                  sM["total_amount"] = singlePo[
                                    "total_dispatch"
                                  ]
                                    ? multiply(
                                        singlePo["total_dispatch"]
                                          ? Number(singlePo["total_dispatch"])
                                          : 0,
                                        openAddBagDialog["Contract Rate"]
                                          ? this.changeUnit(
                                              singlelistView &&
                                                singlelistView["unit"],
                                              SelectedUnit,
                                              Number(
                                                openAddBagDialog[
                                                  "Contract Rate"
                                                ]
                                              ),
                                              "opposite"
                                            )
                                          : 0
                                      )
                                    : 0;
                                  // singlePackaging.remaining_bags=

                                  newA.push(sM);
                                } else {
                                  newA.push(sM);
                                }
                                return sM;
                              });
                              const newData = submittedDataOfUsedBags;
                              newData[openAddBagDialog.po_item_id] = newPacks;
                              console.log(
                                openAddBagDialog.po_item_id,
                                "openAddBagDialog.po_item_id"
                              );
                              this.setState({
                                submittedDataOfUsedBags: newData,
                                // [``]
                                // [`${openAddBagDialog.po_item_id}${singlePackaging.size}usedBags`]:e.target.value
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                </>
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}
            >
              {added ? (
                ""
              ) : (
                <StyledButton
                  onClick={() => {
                    const newArr = poItemsArrayTOPreview;

                    newArr.map((sObj) => {
                      if (sObj.po_item_id === openAddBagDialog.po_item_id) {
                        sObj["packagageArrUsed"] =
                          submittedDataOfUsedBags[openAddBagDialog.po_item_id];
                      } else {
                      }
                      return sObj;
                    });
                    if (
                      openAddBagDialog !== false &&
                      openAddBagDialog["packagageArrUsed"] &&
                      openAddBagDialog["packagageArrUsed"].length > 0
                    ) {
                      openAddBagDialog["packagageArrUsed"].map((sData) => {
                        if (sData.remaining_bags) {
                          this.setState({
                            [`${sData.id}Remaining`]: false,
                          });
                        } else {
                        }
                        return sData;
                      });
                    }
                    this.setState({
                      poItemsArrayTOPreviewCopy2: newArr,
                      openAddBagDialog: false,
                    });
                  }}
                  name={"Submit"}
                  withBg={true}
                  background={primary}
                  width="120px"
                  margin="0 10px 0 0"
                />
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}

        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {console.log("SIN", singlelistView)}
          {detailDialog(true)}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.PurchaseOrders === true || loader === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="rgb(241, 154, 48)" />
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                this.setState(
                  { selectedValue: newVal },
                  () => {
                    if (newVal === Number(3)) {
                      this.fetchReachedData();
                    } else if (Number(newVal) === 2) {
                      this.fetchDisptachList();
                    } else if (Number(newVal) === 1) {
                      this.fetchSurveyReportList();
                    }
                    console.log(newVal, "selected tabbb");
                    this.setState({
                      dispatchTab: newVal === Number(0) ? true : "",
                    });
                    this.setState({
                      dispatchTab: newVal === Number(2) ? false : true,
                    });
                  }
                  // () => {
                  //   if (newVal === Number(2)) {
                  //     console.log("22222222222");
                  //   }

                  // }
                );
              }}
              list={[
                {
                  name: "Order Details",
                  // onClick: { dispatchTab: false },

                  component:
                    loadingRight === true ? (
                      <div className="alignC justiyC d-flex width-100 height-100">
                        <StyledCircularProgress color="rgb(241, 154, 48)" />
                      </div>
                    ) : (
                      <Details
                        listClickHandler={this.listClickHandler}
                        updateSelectedTabData={this.updateSelectedTabData}
                        selectHandler={this.selectHandler}
                        singlelistView={this.state.singlelistView}
                        onlyUi={true}
                        {...this.props}
                        {...this.state}
                        POITemTable={POITemTable}
                        InlandGodownList={InlandGodownList}
                        closeDialog={() => {
                          this.setState(
                            {
                              openDialog: false,
                            },
                            () => {}
                          );
                        }}
                        clearPoItems={() => {
                          this.setState({
                            poItemsArrayCopy: [],
                            poItemsArray: [],
                          });
                        }}
                        setPoItems={(poItemsArrayCopy, poItemsArray) => {
                          this.setState(
                            poItemsArrayCopy && poItemsArray
                              ? {
                                  poItemsArrayCopy,
                                  poItemsArray,
                                }
                              : poItemsArrayCopy
                              ? { poItemsArrayCopy }
                              : poItemsArray
                              ? { poItemsArray }
                              : {},
                            () => {
                              this.setWidth(this.state.poItemsArray);
                            }
                          );
                        }}
                        setPoItemsPrev={(
                          poItemsArrayTOPreview,
                          poItemsArrayTOPreviewCopy,
                          poItemsArrayTOPreviewCopy2
                        ) => {
                          this.setState(
                            {
                              poItemsArrayTOPreview,
                              poItemsArrayTOPreviewCopy,
                              poItemsArrayTOPreviewCopy2,
                            },
                            () => {
                              // if (this.state.poItemsArray.lenght > 0) {
                              this.setWidth(this.state.poItemsArrayTOPreview);
                              // }
                            }
                          );
                        }}
                      />
                    ),
                },
                {
                  name: "Survey Report",
                  component: (
                    <>
                      <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "0px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="PO Number"
                        name="PO Number"
                        value={
                          singlelistView && singlelistView.po_number
                            ? singlelistView && singlelistView.po_number
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        // disabled
                        type="text"
                        autoComplete="off"
                        style={{
                          width: "calc(25% - 20px)",
                          margin: "0px 10px 0 10px",
                          pointerEvents: "none",
                        }}
                        id="outlined-basic"
                        label="MillerName & Address"
                        // variant="outlined"
                        name="MillerName & Address"
                        value={`${
                          singlelistView &&
                          singlelistView.miller_details &&
                          singlelistView.miller_details.name
                            ? singlelistView.miller_details.name
                            : ""
                        }, ${
                          singlelistView &&
                          singlelistView.miller_details.address
                            ? singlelistView.miller_details.address
                            : ""
                        }`}
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "0px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Miller GST NO."
                        // variant="outlined"
                        name="Miller GST NO."
                        value={
                          singlelistView &&
                          singlelistView.miller_details &&
                          singlelistView.miller_details
                            ? singlelistView.miller_details.gstin
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={false}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "0px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Contact Person"
                        // variant="outlined"
                        name="Contact Person"
                        value={
                          singlelistView &&
                          singlelistView.miller_details.contact_person
                        }
                        onChange={this.handleChange}
                      />
                      <CssTextField
                        required={true}
                        // disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "10px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Contract Type"
                        // variant="outlined"
                        name="Contract Type"
                        value={
                          singlelistView && singlelistView.contract_type_details
                            ? singlelistView.contract_type_details.contract_type
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                      {console.log(
                        "singlelistView",
                        singlelistView,
                        this.props.masters
                      )}
                      <CssTextField
                        required={true}
                        //disabled
                        type="text"
                        autoComplete="off"
                        style={
                          {
                            width: "calc(25% - 20px)",
                            margin: "10px 10px 0 10px",
                            pointerEvents: "none",
                          } //disabled={tp}
                        }
                        id="outlined-basic"
                        label="Ports"
                        // variant="outlined"
                        name="Ports"
                        value={
                          singlelistView &&
                          singlelistView.ports_details &&
                          singlelistView.ports_details.length > 0
                            ? singlelistView?.ports_details.map((s) => {
                                return s.city;
                              })
                            : singlelistView?.ports.map((p) => {
                                return Boolean(Number(p))
                                  ? this.props.masters.PortsList.find(
                                      (p) => p.id === Number(p)
                                    )?.name
                                  : p;
                              })
                          // { nameCity: "Any Indian Port",name:"Any Indian Port", id: "Any Indian Port" }]
                        }
                        onChange={this.handleChange}
                      />
                      <TableComponent
                        noAddButton={
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CLOSED") ||
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CANCELLED" &&
                            true)
                        }
                        addButtonDisabled={singlelistView?.status === "DRAFT"}
                        actions={
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CLOSED") ||
                          (singlelistView &&
                            singlelistView.status &&
                            singlelistView.status === "CANCELLED" &&
                            [])
                        }
                        loader={loadingRight}
                        subMenu={true}
                        customCretae={"survay report"}
                        tableValueDetailArray={surveyorItemArrayCopy}
                        module={"Survey Report"}
                        postApi="dispatch/purchase-order/survey-report/create"
                        editApi="dispatch/purchase-order/survey-report/item/bulk-update"
                        editOnlyParam={true}
                        subMenuheader={survayItemheader}
                        {...this.state}
                        header={["Survey Number", "Survey Date"]}
                        reset={() => {
                          const errorMsg = this.state.errorMsg;
                          //   errorMsg.SamplingDate=false;
                          errorMsg.survayor = false;
                          errorMsg.survayorCompany = false;
                          errorMsg.REMARK = false;
                          errorMsg.Double = false;
                          errorMsg.Foreign = false;
                          errorMsg.Paddy = false;
                          errorMsg.Length = false;
                          errorMsg.Chalky = false;
                          errorMsg.GreenGrain = false;
                          errorMsg.WholeRedGrain = false;
                          errorMsg.AverageGrainLength = false;
                          errorMsg.Damaged = false;
                          errorMsg.Broken = false;
                          errorMsg.Moisture = false;
                          errorMsg.BrandName = false;
                          this.setState({
                            errorMsg,
                            Broken: "",
                            Moisture: "",
                            ItemCategoryId: "",
                            ItemCategory: "",
                            ItemType: "",
                            ItemTypeId: "",
                            BrandNameId: "",
                            BrandName: false,
                            Length: "",
                            Damaged: "",
                            Chalky: "",
                            Paddy: "",
                            Foreign: "",
                            Double: "",
                            GreenGrain: "",
                            WholeRedGrain: "",
                            AverageGrainLength: "",
                            REMARK: "",
                            survayor: "",
                            survayorId: "",
                            survayorCompany: "",
                            survayorCompanyId: "",
                            selectedBrandMultipleName: false,
                            selectedPoIdinSiurvayor: "",
                            surveyorItemArray: [],
                            surveyorItemArrayCopy: [],
                            // SamplingDate:""
                          });
                        }}
                        settError={() => {
                          const errorMsg = this.state.errorMsg;
                          //  errorMsg.SamplingDate="Please give an Sampling Date";
                          errorMsg.survayor = "Please give an Surveyor!";
                          errorMsg.survayorCompany =
                            "Please give an SurveyorCompany!";
                          errorMsg.REMARK = "Please give an Contract REMARK!";
                          errorMsg.Double = "Please select an Double Polish!";
                          errorMsg.Foreign = "Please give an Foreign Matter!";
                          errorMsg.Paddy = "Please give an Paddy!";
                          errorMsg.Length = "Please give an Length!";
                          errorMsg.Chalky = "Please give an Chalky!";
                          errorMsg.GreenGrain = "Please give an GreenGrain!";
                          errorMsg.WholeRedGrain =
                            "Please give an WholeRedGrain!";
                          errorMsg.AverageGrainLength =
                            "Please give an AverageGrainLength!";
                          errorMsg.Damaged =
                            "Please give an Damaged/Discoloured!";
                          errorMsg.Broken = "Please give an Broken!";
                          errorMsg.Moisture = "Please select an Moisture!";
                          errorMsg.BrandName = "Please select an BrandName!";
                          this.setState({ errorMsg });
                        }}
                        tableData={(editList, submittedData) => {
                          return editList
                            ? SurvayITemTable(
                                "editSurvey",
                                surveyorItemArray,
                                "noTotalQuantity",
                                "showTotal",
                                null,
                                "showbagOnly",
                                submittedData
                              )
                            : SurvayITemTable(
                                "edit",
                                surveyorItemArray,
                                "noTotalQuantity",
                                "showTotal",
                                null,
                                null,
                                submittedData
                              );
                        }}
                        {...this.props}
                        setSelectedList={(data) => {
                          console.log(
                            data.subList,
                            "data.subList",
                            singlelistView
                          );
                          this.setState(
                            {
                              surveyorItemArray: data.subList,
                              surveyorItemArrayCopy: data.subList,
                            },
                            () => {
                              this.setWidth(
                                this.state.surveyorItemArray,
                                survayItemheader
                              );
                            }
                          );
                        }}
                        tablePagination={true}
                        paginationData={paginationData}
                        pageChangeAPI={this.pageChangeAPI}
                        resetPage={this.state.resetPage}
                        csvDownloadButton={{
                          visible: true,
                          apiCall: {
                            method: "GET",
                            url: "reports/survey-report/list",
                            params: { po_id: this.state.singlelistView?.id },
                          },
                          itemHeader: [
                            {
                              label: "Survey Date",
                              key: "survey_report_items.sampling_date",
                            },
                            {
                              label: "Surveyor",
                              key: "survey_report_items.surveyor",
                            },
                            {
                              label: "Surveyor Company",
                              key: "survey_report_items.survey_company",
                            },
                            {
                              label: "Brand",
                              key: "survey_report_items.brand",
                            },
                            {
                              label: "Item Category",
                              key: "survey_report_items.item_category",
                            },
                            {
                              label: "Item Type",
                              key: "survey_report_items.item_type",
                            },
                            {
                              label: "Moisture",
                              key: "survey_report_items.moisture",
                            },
                            {
                              label: "Broken",
                              key: "survey_report_items.broken",
                            },
                            {
                              label: "Damaged/Discoloured",
                              key: "survey_report_items.damaged",
                            },
                            {
                              label: "Chalky",
                              key: "survey_report_items.chalky",
                            },
                            {
                              label: "Length",
                              key: "survey_report_items.length",
                            },
                            {
                              label: "Paddy",
                              key: "survey_report_items.paddy",
                            },
                            {
                              label: "Green Grain",
                              key: "survey_report_items.green_grain",
                            },
                            {
                              label: "Whole Red Grain",
                              key: "survey_report_items.whole_red_grain",
                            },
                            {
                              label: "Average Grain Length",
                              key: "survey_report_items.average_grain_length",
                            },
                            {
                              label: "Foreign Matter",
                              key: "survey_report_items.foreign_material",
                            },
                            {
                              label: "Double Polish",
                              key: "survey_report_items.double_polish",
                            },
                            {
                              label: "Remark",
                              key: "survey_report_items.remark",
                            },
                          ],
                          mappingArr: ["survey_report_items"],
                          fileName: "Survey Report",
                        }}
                        tableList={
                          surveyReport && surveyReport.length > 0
                            ? surveyReport.map((singleSReport, i) => {
                                const newObj = singleSReport;
                                newObj["Survey Number"] =
                                  i + 1 > 12 ? i + 1 : `0${i + 1}`;
                                newObj["Survey Date"] =
                                  singleSReport.subList &&
                                  singleSReport.subList.length > 0 &&
                                  singleSReport.subList[0].sampling_date
                                    ? moment(
                                        singleSReport.subList[0].sampling_date
                                      ).format("DD MMM YYYY")
                                    : "";
                                newObj["Survey DateMonth"] =
                                  singleSReport.subList &&
                                  singleSReport.subList.length > 0 &&
                                  singleSReport.subList[0].sampling_date
                                    ? singleSReport.subList[0].sampling_date
                                    : "";
                                {
                                  console.log(singleSReport.subList, "ssssss");
                                }
                                newObj["subList"] = singleSReport.subList.map(
                                  (singleItem) => {
                                    const brandSelected = filterData(
                                      BrandList,
                                      "id",
                                      singleItem.brand
                                    )
                                      ? filterData(
                                          BrandList,
                                          "id",
                                          singleItem.brand
                                        )
                                      : "";
                                    const CategorySelected = filterData(
                                      ItemCateoryList,
                                      "id",
                                      singleItem.report_item_category,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          ItemCateoryList,
                                          "id",
                                          singleItem.report_item_category,
                                          "onlyOne"
                                        )
                                      : "";
                                    let typeSelected = filterData(
                                      ItemList,
                                      "id",
                                      singleItem.report_item_type,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          ItemList,
                                          "id",
                                          singleItem.report_item_type,
                                          "onlyOne"
                                        )
                                      : "";
                                    console.log(typeSelected, "typeSelected");
                                    typeSelected = typeSelected;

                                    singleItem[survayItemheader[0]] =
                                      brandSelected[0]
                                        ? brandSelected[0].brand_name
                                        : "";
                                    singleItem[`${survayItemheader[0]}id`] =
                                      singleItem.brand;
                                    singleItem[survayItemheader[1]] =
                                      CategorySelected.name;
                                    singleItem[`${survayItemheader[1]}id`] =
                                      CategorySelected.id;
                                    singleItem[survayItemheader[2]] =
                                      typeSelected.broken_percentage;
                                    singleItem[`${survayItemheader[2]}id`] =
                                      typeSelected.id;
                                    singleItem[
                                      `${survayItemheader[2]}Unit`
                                    ] = `${typeSelected.broken_percentage} %`;
                                    singleItem[survayItemheader[3]] =
                                      singleItem.moisture;
                                    singleItem[survayItemheader[4]] =
                                      singleItem.broken || "";
                                    singleItem[survayItemheader[4]] =
                                      singleItem.broken || "";
                                    singleItem[survayItemheader[5]] =
                                      singleItem.damaged;
                                    singleItem[survayItemheader[6]] =
                                      singleItem.chalky;

                                    singleItem[survayItemheader[9]] =
                                      singleItem.green_grain;
                                    singleItem[survayItemheader[10]] =
                                      singleItem.whole_red_grain;
                                    singleItem[survayItemheader[11]] =
                                      singleItem.average_grain_length;
                                    singleItem[survayItemheader[7]] =
                                      singleItem.length;
                                    singleItem[survayItemheader[8]] =
                                      singleItem.paddy;
                                    singleItem[survayItemheader[12]] =
                                      singleItem.foreign_material;
                                    singleItem[survayItemheader[13]] =
                                      singleItem.double_polish;
                                    singleItem[survayItemheader[14]] =
                                      singleItem.remark;

                                    singleItem[survayItemheader[15]] =
                                      filterData(
                                        SurveyCompanyList,
                                        "id",
                                        singleItem.survey_company,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            SurveyCompanyList,
                                            "id",
                                            singleItem.survey_company,
                                            "onlyOne"
                                          ).name
                                        : "";

                                    singleItem[`${survayItemheader[15]}id`] =
                                      singleItem.survey_company;
                                    singleItem[survayItemheader[16]] =
                                      filterData(
                                        allsurveyorList,
                                        "id",
                                        singleItem.surveyor,
                                        "onlyOne"
                                      ) && singleItem.Surveyorid
                                        ? filterData(
                                            allsurveyorList,
                                            "id",
                                            singleItem.surveyor,
                                            "onlyOne"
                                          ).name
                                        : "";

                                    singleItem[`${survayItemheader[16]}id`] =
                                      singleItem.surveyor;
                                    singleItem[survayItemheader[17]] =
                                      singleItem.sampling_date;
                                    return singleItem;
                                  }
                                );
                                return newObj;
                              })
                            : []
                        }
                        inputArray={[
                          {
                            name: "PO Number",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView && singlelistView.po_number
                                  ? singlelistView && singlelistView.po_number
                                  : "",
                            },
                            api: "purchase_order",
                          },
                          {
                            name: "MillerName & Address",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value: `${
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView &&
                                singlelistView.miller_details.name
                                  ? singlelistView.miller_details.name
                                  : ""
                              }, ${
                                singlelistView &&
                                singlelistView.miller_details.address
                                  ? singlelistView.miller_details.address
                                  : ""
                              }`,
                              id:
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView.miller_details
                                  ? singlelistView.miller_details.id
                                  : "",
                            },
                            api: "miller",
                          },
                          {
                            name: "Miller GST NO.",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.miller_details &&
                                singlelistView.miller_details
                                  ? singlelistView.miller_details.gstin
                                  : "",
                            },
                          },
                          {
                            name: "Contract Type",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.contract_type_details
                                  ? singlelistView.contract_type_details
                                      .contract_type
                                  : "",
                            },
                          },
                          {
                            name: "Contact Person",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.miller_details.contact_person,
                            },
                            not_required: true,
                          },
                          {
                            name: "Ports",
                            type: "multiInput",
                            dataType: "text",
                            array:
                              singlelistView &&
                              singlelistView.ports_details &&
                              singlelistView.ports_details.length > 0
                                ? singlelistView.ports_details
                                : this.state.PortsList.map((data) => {
                                    data[
                                      "namecity"
                                    ] = `${data.name} - ${data.city}`;
                                    return data;
                                  }),
                            value: "city",
                            // disabled: true,
                            defaultValue: {
                              value:
                                singlelistView &&
                                singlelistView.ports_details &&
                                singlelistView.ports_details.length > 0
                                  ? singlelistView.ports_details
                                  : [],

                              id:
                                singlelistView &&
                                singlelistView.ports_details &&
                                singlelistView.ports_details.length > 0
                                  ? singlelistView.ports_details.map((s) => {
                                      return s.id;
                                    })
                                  : [],
                            },
                          },
                          {
                            name: "Broker",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                singlelistView && singlelistView.broker_details
                                  ? singlelistView.broker_details.name //default_brokerage
                                  : "",
                            },
                          },
                          {
                            name: "Survey Date",
                            type: "Month",
                            // disabled: true,
                            // defaultValue: {
                            //   value:singlelistView && singlelistView.sampling_date,
                            // },
                            api: "sampling_date",
                          },
                        ]}
                        postData={this.props.PurchaseOrdersFunction}
                        fetchAgain={() => {
                          this.setState({ editingDispatch: false });
                          // fetchsu
                          this.props
                            .PurchaseOrdersFunction(
                              "get",
                              EndPoints["PurchaseOrders"],
                              null,
                              null,
                              null,
                              { status: this.state.selctedStatus },
                              null,
                              true
                            )
                            .then((res) => {
                              if (
                                (this.state.singlelistView &&
                                  this.state.singlelistView.id) ||
                                (this.props.params.id &&
                                  res &&
                                  res.length > 0 &&
                                  res
                                    // .sort((a, b) => b.id - a.id)
                                    .filter((sD) => {
                                      if (sD.status) {
                                        return (
                                          sD.status === this.state.selctedStatus
                                        );
                                      } else {
                                        return null;
                                      }
                                    }) &&
                                  res
                                    // .sort((a, b) => b.id - a.id)
                                    .filter((sD) => {
                                      if (sD.status) {
                                        return (
                                          sD.status === this.state.selctedStatus
                                        );
                                      } else {
                                        return null;
                                      }
                                    })[this.props.params.id])
                              ) {
                                this.listClickHandler(
                                  res &&
                                    res.length > 0 &&
                                    res
                                      // .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      })[this.props.params.id]
                                );
                              }
                            });
                          this.props
                            .PurchaseOrdersFunction(
                              "get",
                              "dispatch/purchase-order/survey-report/list",
                              null,
                              "surveyReport",
                              null,
                              {
                                po_id: this.state.singlelistView.id,
                              }
                            )
                            .then((res) => {
                              if (res && res.length > 0) {
                                const blankarr = [];
                                res.map((sData) => {
                                  this.props
                                    .PurchaseOrdersFunction(
                                      "get",
                                      "dispatch/purchase-order/survey-report/items/list",
                                      null,
                                      "survay-item",
                                      null,
                                      { survey_report_id: sData.id }
                                    )
                                    .then((res2) => {
                                      const newobj = sData;
                                      newobj.subList = res2;
                                      blankarr.push(newobj);
                                      if (res.length === blankarr.length) {
                                        this.setState({
                                          surveyReport: blankarr,
                                        });
                                      }
                                      return sData;
                                    });
                                  return sData;
                                });
                              } else {
                                this.setState({ surveyReport: [] });
                              }
                            });
                        }}
                      />
                      {/* {detailDialog(true)} */}
                    </>
                  ),
                },
                {
                  name: "Dispatches",
                  // onClick: { dispatchTab: true },
                  component:
                    loadingRight === true ? (
                      <div className="alignC justiyC d-flex width-100 height-100">
                        <StyledCircularProgress color="rgb(241, 154, 48)" />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          <SnackbarComponent
                            error={error}
                            autoHideDuration={error ? 3000 : 2000}
                            success={success}
                            snackbarClose={() => {
                              this.setState({
                                error: "",
                                success: "",
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <TableComponent
                          loader={loadingRight}
                          errorMsgs={errorMsgForDispatch}
                          seterrorMsgs={(s, clear) => {
                            this.setState({
                              errorMsgForDispatch: clear
                                ? {}
                                : {
                                    ...errorMsgForDispatch,
                                    [s]: false,
                                    "Net Weight":
                                      (s === "Tare Weight" ||
                                        s === "Gross Weight") &&
                                      false,
                                  },
                            });
                          }}
                          remaining_quantity={
                            singlelistView &&
                            singlelistView.total_dispatched_quantity
                              ? this.state.editingDispatch !== false
                                ? sum([
                                    singlelistView.total_dispatched_quantity &&
                                      subtract(
                                        Number(
                                          singlelistView.total_po_quantity
                                        ),
                                        Number(
                                          singlelistView.total_dispatched_quantity
                                        )
                                      ),
                                    Number(
                                      this.state.editingDispatch &&
                                        this.state.editingDispatch
                                          .dispatch_weight
                                        ? this.state.editingDispatch
                                            .dispatch_weight
                                        : 0
                                    ),
                                  ])
                                : singlelistView.total_dispatched_quantity &&
                                  subtract(
                                    Number(singlelistView.total_po_quantity),
                                    Number(
                                      singlelistView.total_dispatched_quantity
                                    )
                                  )
                              : singlelistView &&
                                Number(singlelistView.total_po_quantity)
                          }
                          subMenu={true}
                          customCretae={true}
                          tableErrorValue={selectedPVItemArray}
                          isExLoose={Boolean(this.handleDispLoosePoCond())}
                          tableValueDetailArray={poItemsArrayTOPreviewCopy2}
                          validateAddButton={() => {
                            if (
                              this.props?.user?.roles
                                ?.toString()
                                .toLowerCase() === "others" &&
                              singlelistView.approval_status === "PENDING"
                            ) {
                              this.setState({
                                error:
                                  "Dispatch can't e added untill admin approves this PO.",
                              });
                              return "false";
                            } else {
                              return true;
                            }
                          }}
                          onAdd={() => {
                            this.setState({
                              survayorList: [],
                              addbagError: false,
                              errorMsgForDispatch: {},
                              poItemsArrayTOPreview: poItemsArrayTOPreviewCopy,
                              poItemsArrayTOPreviewCopy2:
                                poItemsArrayTOPreviewCopy.map((ss) => {
                                  ss["packagageArrUsed"].map((s) => {
                                    s.used_bags = 0;
                                    s.used_bags_fordiapatch = 0;
                                    return s;
                                  });
                                  return ss;
                                }),
                              selectedPVItemArray: [],
                            });
                          }}
                          tableData={(editList, submittedData) => {
                            return !Boolean(this.handleDispLoosePoCond()) ? (
                              editList ? (
                                POITemTable(
                                  "",
                                  poItemsArrayTOPreviewCopy2,
                                  "noTotalQuantity",
                                  "showTotal",
                                  null,
                                  "showbagOnly",
                                  submittedData
                                )
                              ) : (
                                POITemTable(
                                  "checkBox",
                                  poItemsArrayTOPreviewCopy2,
                                  "noTotalQuantity",
                                  "showTotal",
                                  null,
                                  "addBag",
                                  submittedData
                                )
                              )
                            ) : editList ? (
                              <DispatchLoosePoItemTable
                                itemData={poItemsArrayTOPreviewCopy2}
                                unit={
                                  this?.state?.selectedUnit ||
                                  singlelistView?.unit ||
                                  ""
                                }
                                masters={this.props.masters}
                                handleItemCheckBoxChange={(data) => {
                                  console.log("CC", data);
                                  this.setState({
                                    dispatchItemRowCheckBox: data,
                                    selectedPVItemArray: data.length
                                      ? [
                                          ...(data?.find(
                                            (c) => c.checked === true
                                          )?.id
                                            ? [
                                                data.find(
                                                  (c) => c.checked === true
                                                ).id,
                                              ]
                                            : []),
                                        ]
                                      : [],
                                  });
                                }}
                              />
                            ) : (
                              <DispatchLoosePoItemTable
                                itemData={poItemsArrayTOPreviewCopy2}
                                unit={
                                  this?.state?.selectedUnit ||
                                  singlelistView?.unit ||
                                  ""
                                }
                                masters={this.props.masters}
                                handleItemCheckBoxChange={(data) => {
                                  console.log(
                                    "check",
                                    poItemsArrayTOPreviewCopy2,
                                    data,
                                    data?.length
                                      ? [
                                          data?.find((c) => c?.checked === true)
                                            ?.id,
                                        ]
                                      : []
                                  );
                                  this.setState({
                                    dispatchItemRowCheckBox: data,
                                    selectedPVItemArray: data?.length
                                      ? [
                                          data?.find((c) => c?.checked === true)
                                            ?.id,
                                        ]
                                      : [],
                                  });
                                }}
                              />
                            );
                          }}
                          settableErrorValue={() => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.poItemsArrayTOPreview =
                              "Please add an PO Item!";
                            this.setState({ errorMsg });
                          }}
                          addButtonDisabled={singlelistView?.status === "DRAFT"}
                          module={"Dispatch"}
                          postApi="dispatch/create"
                          editApi="dispatch"
                          noAddAnother={true}
                          closeOnSave={true}
                          editOnlyParam={true}
                          {...this.state}
                          subMenuheader={[
                            "Sr.No.",
                            "Brand",
                            "Item Category",
                            "Item Type",
                            "Packaging",
                            "HSN Code",
                            "Contract Rate",
                          ]}
                          header={
                            singlelistView &&
                            singlelistView.contract_type_details
                              .contract_type === "FOR"
                              ? [
                                  "Sr.No.",
                                  "Dispatch Date",
                                  // "Dispatched From",
                                  // "Dispatched To",
                                  "Dispatch Status",
                                  // "Dispatch Weight",
                                  "Contract Type",
                                  "Miller Bill Number",
                                  // "Miller Bill Amount",
                                  // "Miller Tcs",
                                  // "Miller Advance",
                                  // "Miller Total Amount",
                                  "Purchase Order",
                                  // "Sales Order",
                                  // "Transporter",
                                  "Truck Number",
                                  "Bilty Number",
                                  // "Bilty Date",
                                  // "Gross Weight",
                                  // "Tare Weight",
                                  "Net Weight",
                                  "Freight Rate",
                                  // "Total Freight",
                                  // "Transporter Advance",
                                  // "Transporter Balance",
                                  "Survey Company",
                                  // "Surveyor",
                                  // "Survey Date",
                                  // "Dispatch Status",
                                  // "Miller Payment",
                                  // "Transporter Payment",
                                  "Broker Payment",
                                ]
                              : [
                                  "Sr.No.",
                                  "Dispatch Date",
                                  // "Dispatched From",
                                  // "Dispatched To",
                                  "Dispatch Status",
                                  // "Dispatch Weight",
                                  "Contract Type",
                                  "Miller Bill Number",
                                  // "Miller Bill Amount",
                                  // "Miller Tcs",
                                  // "Miller Advance",
                                  // "Miller Total Amount",
                                  "Purchase Order",
                                  // "Sales Order",
                                  "Transporter",
                                  // "Truck Number",
                                  "Bilty Number",
                                  // "Bilty Date",
                                  // "Gross Weight",
                                  // "Tare Weight",
                                  "Net Weight",
                                  "Freight Rate",
                                  // "Total Freight",
                                  // "Transporter Advance",
                                  // "Transporter Balance",
                                  "Survey Company",
                                  // "Surveyor",
                                  // "Survey Date",
                                  // "Dispatch Status",
                                  // "Miller Payment",
                                  // "Transporter Payment",
                                  "Broker Payment",
                                ]
                          }
                          secondLineHeaderData={{
                            "Dispatch Status": {
                              secondLiner: "Dispatch Weight",
                              secondLinerHover: [
                                // "Dispatch Date",
                                "Dispatched From",
                                "Dispatched To",
                              ],
                            },
                            "Miller Bill Number": {
                              secondLiner: "Miller Payment",
                              secondLinerHover: [
                                "Miller Bill Amount",
                                "Miller Tcs",
                                "Miller Advance",
                                "Miller Total Amount",
                              ],
                            },
                            Transporter: {
                              secondLiner: "Transporter Payment",
                              secondLinerHover: [
                                "Truck Number",
                                "Transporter Balance",
                                "Transporter Advance",
                              ],
                            },
                            "Bilty Number": {
                              secondLiner: "Bilty Date",
                              secondLinerHover: [],
                            },
                            "Purchase Order": {
                              secondLiner: "Sales Order",
                              secondLinerHover: [],
                            },
                            "Net Weight": {
                              secondLiner: "",
                              secondLinerHover: ["Gross Weight", "Tare Weight"],
                            },
                            "Freight Rate": {
                              secondLiner: "Total Freight",
                              secondLinerHover: [],
                            },
                            "Survey Company": {
                              secondLiner: "",
                              secondLinerHover: ["Survey Date", "Surveyor"],
                            },
                          }}
                          {...this.props}
                          clearError={() => {
                            this.setState({ error: "" });
                          }}
                          tablePagination={true}
                          paginationData={paginationData}
                          pageChangeAPI={this.pageChangeAPI}
                          resetPage={this.state.resetPage}
                          csvDownloadButton={{
                            visible: true,
                            apiCall: {
                              method: "GET",
                              url: "reports/dispatch/list",
                              params: { po_id: this.state.singlelistView?.id },
                            },
                            header: [
                              { label: "Dispatch Date", key: "dispatch_date" },
                              {
                                label: "Dispatched From",
                                key: "dispatch_from",
                              },
                              { label: "Dispatched To", key: "dispatch_to" },
                              {
                                label: "Dispatch Weight",
                                key: "dispatch_weight",
                              },
                              {
                                label: "Dispatch Status",
                                key: "dispatch_status",
                              },
                              { label: "Contract Type", key: "contract_type" },
                              {
                                label: "Purchase Order",
                                key: "purchase_order",
                              },
                              { label: "Sales Order", key: "sales_order" },
                              {
                                label: "Miller Bill Number",
                                key: "miller_bill_number",
                              },
                              {
                                label: "Miller Payment",
                                key: "miller_payment",
                              },
                              {
                                label: "Miller Bill Amount",
                                key: "miller_bill_amount",
                              },
                              { label: "Miller Tcs", key: "miller_tcs" },
                              {
                                label: "Miller Advance",
                                key: "miller_advance",
                              },
                              {
                                label: "Miller Total Amount",
                                key: "miller_total_amount",
                              },
                              {
                                label: "Truck Number",
                                key: "truck_number",
                              },
                              {
                                label: "Transporter Payment",
                                key: "transporter_payment",
                              },
                              {
                                label: "Transporter Balance",
                                key: "transporter_balance",
                              },
                              {
                                label: "Transporter Advance",
                                key: "transporter",
                              },
                              { label: "Bilty Number", key: "bilty_number" },
                              { label: "Bilty Date", key: "bilty_date" },
                              {
                                label: "Gross Weight",
                                key: "gross_weight",
                                key2: "unit",
                                key2Prefix: "/",
                              },
                              {
                                label: "Tare Weight",
                                key: "tare_weight",
                                key2: "unit",
                                key2Prefix: "/",
                              },
                              {
                                label: "Net Weight",
                                key: "net_weight",
                                key2: "unit",
                                key2Prefix: "/",
                              },
                              {
                                label: "Freight Rate",
                                key: "freight_rate",
                                key2: "unit",
                                key2Prefix: "/",
                              },
                              { label: "Total Freight", key: "total_freight" },
                              { label: "Survey Date", key: "last_survey" },
                              // {label: "Surveyor", key: "surveyor"},
                              {
                                label: "Survey Company",
                                key: "survey_company",
                              },
                              {
                                label: "Broker Payment",
                                key: "broker_payment",
                              },
                            ],
                            itemHeader: [
                              {
                                label: "Item - Brand",
                                key: "dispatch_items.brand",
                              },
                              {
                                label: "Item - Item Category",
                                key: "dispatch_items.item_category",
                              },
                              {
                                label: "Item - Item Type",
                                key: "dispatch_items.item_type",
                              },
                              {
                                label: "Item - HSN",
                                key: "dispatch_items.hsn_code",
                              },
                              {
                                label: "Item - Contract Rate",
                                key: "dispatch_items.contract_rate",
                                key2: "unit",
                                key2Prefix: "/",
                              },
                              {
                                label: "Item - Packaging - Size Unit",
                                key: "dispatch_items.dispatch_packaging.packaging.size",
                                key2Prefix: "",
                                key2: "dispatch_items.dispatch_packaging.packaging.unit",
                              },
                              {
                                label: "Item - Packaging - Bag Type",
                                key: "dispatch_items.dispatch_packaging.packaging.bag_type",
                              },
                              {
                                label: "Item - Packaging - Used Bags",
                                key: "dispatch_items.dispatch_packaging.total_bags_dispatched",
                              },
                            ],
                            mappingArr: [
                              "dispatch_items",
                              "dispatch_packaging",
                            ],
                            fileName: "Port Reached",
                          }}
                          tableList={
                            PODispatchedList && PODispatchedList.length
                              ? PODispatchedList.map((singleDispatch) => {
                                  singleDispatch["Dispatch Date"] = moment(
                                    singleDispatch.dispatch_date
                                  ).format("DD MMM YYYY");
                                  singleDispatch["Dispatch DateMonth"] =
                                    singleDispatch.dispatch_date;

                                  singleDispatch["Dispatched From"] =
                                    singleDispatch.dispatched_from_details
                                      ? singleDispatch.dispatched_from_details
                                          .name
                                      : "-";
                                  singleDispatch["Dispatched FromId"] =
                                    singleDispatch.dispatched_from_details
                                      ? singleDispatch.dispatched_from_details
                                          .id
                                      : "-";
                                  singleDispatch["Dispatched To"] =
                                    singleDispatch?.dispatched_to_inland_godown
                                      ? InlandGodownList.find(
                                          (d) =>
                                            d.id ==
                                            singleDispatch.dispatched_to_inland_godown
                                        )?.name || ""
                                      : singleDispatch.dispatched_to_details
                                      ? `${singleDispatch.dispatched_to_details.name} - ${singleDispatch.dispatched_to_details.city}`
                                      : "-";
                                  singleDispatch["Dispatched ToId"] =
                                    singleDispatch?.dispatched_to_inland_godown
                                      ? singleDispatch?.dispatched_to_inland_godown
                                      : singleDispatch.dispatched_to_details
                                      ? singleDispatch.dispatched_to_details.id
                                      : "-";
                                  singleDispatch["Total Bags"] =
                                    singleDispatch.total_bags;

                                  singleDispatch["Dispatch Weight"] =
                                    singleDispatch.dispatch_weight
                                      ? Number(
                                          singleDispatch.dispatch_weight
                                        ).toFixed(3)
                                      : "";
                                  singleDispatch["Dispatch WeightUnit"] =
                                    singleDispatch.dispatch_weight
                                      ? Number(
                                          singleDispatch.dispatch_weight
                                        ).toFixed(3)
                                      : "" + " " + singleDispatch.unit;

                                  singleDispatch["Contract Type"] =
                                    singleDispatch.purchase_order_details
                                      .contract_type_details
                                      ? singleDispatch.purchase_order_details
                                          .contract_type_details.contract_type
                                      : "-";
                                  singleDispatch["Miller Bill Number"] =
                                    singleDispatch.miller_bill_number
                                      ? singleDispatch.miller_bill_number
                                      : 0;

                                  singleDispatch["Miller Bill Amount"] =
                                    singleDispatch.miller_bill_amount
                                      ? Number(
                                          singleDispatch.miller_bill_amount
                                        ).toFixed(2)
                                      : 0;
                                  singleDispatch["Miller Advance"] = Number(
                                    singleDispatch.miller_advance
                                  ).toFixed(2);
                                  singleDispatch["Other Amount"] = Number(
                                    singleDispatch.other_amount
                                  ).toFixed(2);
                                  singleDispatch["Other Deduction"] = Number(
                                    singleDispatch.other_deduction
                                  ).toFixed(2);
                                  singleDispatch["Remarks"] =
                                    singleDispatch.remarks;

                                  singleDispatch["Miller Total Amount"] =
                                    singleDispatch.miller_total_amount
                                      ? Number(
                                          singleDispatch.miller_total_amount
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch["Miller Total AmountUnit"] =
                                    singleDispatch.miller_total_amount
                                      ? `₹ ${Number(
                                          singleDispatch.miller_total_amount
                                        ).toFixed(2)}`
                                      : "";

                                  singleDispatch["Purchase Order"] =
                                    singleDispatch.purchase_order_details
                                      ? singleDispatch.purchase_order_details
                                          .po_number
                                      : "-";
                                  singleDispatch["Purchase OrderId"] =
                                    singleDispatch.purchase_order_details
                                      ? singleDispatch.purchase_order_details.id
                                      : "-";

                                  if (
                                    singleDispatch &&
                                    singleDispatch.purchase_order_details &&
                                    singleDispatch.purchase_order_details
                                      .sales_order_details
                                  ) {
                                    singleDispatch["Sales Order"] =
                                      singleDispatch.purchase_order_details
                                        .sales_order_details.so_number &&
                                      singleDispatch.purchase_order_details
                                        .sales_order_details.so_number
                                        ? singleDispatch.purchase_order_details
                                            .sales_order_details.so_number
                                        : "-";
                                    singleDispatch["Sales OrderId"] =
                                      singleDispatch &&
                                      singleDispatch.purchase_order_details &&
                                      singleDispatch.purchase_order_details
                                        .sales_order_details &&
                                      singleDispatch.purchase_order_details
                                        .sales_order_details.so_number
                                        ? singleDispatch.purchase_order_details
                                            .sales_order_details.id
                                        : "";
                                  }

                                  singleDispatch["Transporter"] =
                                    singleDispatch.transporter_details
                                      ? singleDispatch.transporter_details.name
                                      : "-";

                                  singleDispatch["Transpoter Name"] =
                                    TransporterList &&
                                    TransporterList.length > 0 &&
                                    TransporterList.filter(
                                      (sT) =>
                                        sT.id === singleDispatch.transporter
                                    ) &&
                                    TransporterList.filter(
                                      (sT) =>
                                        sT.id === singleDispatch.transporter
                                    )[0] &&
                                    TransporterList.filter(
                                      (sT) =>
                                        sT.id === singleDispatch.transporter
                                    )[0].name
                                      ? TransporterList.filter(
                                          (sT) =>
                                            sT.id === singleDispatch.transporter
                                        )[0].name
                                      : "-";
                                  singleDispatch["Transpoter NameId"] =
                                    singleDispatch.transporter
                                      ? singleDispatch.transporter
                                      : "-";

                                  singleDispatch["Truck"] =
                                    singleDispatch.truck_number
                                      ? singleDispatch.truck_number
                                      : "";
                                  singleDispatch["Truck Number"] =
                                    singleDispatch.truck_number
                                      ? singleDispatch.truck_number
                                      : "";
                                  singleDispatch["Bilty Number"] =
                                    singleDispatch.bilty_number;
                                  singleDispatch["Bilty Date"] =
                                    singleDispatch.bilty_date
                                      ? moment(
                                          singleDispatch.bilty_date
                                        ).format("DD MMM YYYY")
                                      : "";
                                  singleDispatch["Bilty DateMonth"] =
                                    singleDispatch.bilty_date
                                      ? singleDispatch.bilty_date
                                      : "";

                                  // singleDispatch["Truck"] =
                                  //   singleDispatch.truck_number
                                  //     ? singleDispatch.truck_number
                                  //     : "";
                                  singleDispatch["Truck Number"] =
                                    singleDispatch.truck_number
                                      ? singleDispatch.truck_number
                                      : "";
                                  singleDispatch["Bilty Number"] =
                                    singleDispatch.bilty_number;
                                  singleDispatch["Bilty Date"] =
                                    singleDispatch.bilty_date
                                      ? moment(
                                          singleDispatch.bilty_date
                                        ).format("DD MMM YYYY")
                                      : "";
                                  singleDispatch["Bilty DateMonth"] =
                                    singleDispatch.bilty_date
                                      ? singleDispatch.bilty_date
                                      : "";

                                  singleDispatch["Gross Weight"] =
                                    singleDispatch.gross_weight * 1;
                                  singleDispatch["Gross WeightUnit"] =
                                    (singleDispatch.gross_weight
                                      ? Number(
                                          singleDispatch.gross_weight
                                        ).toFixed(3)
                                      : "") +
                                    " " +
                                    singleDispatch.unit;
                                  singleDispatch["Tare Weight"] =
                                    singleDispatch.tare_weight * 1;
                                  singleDispatch["Tare WeightUnit"] =
                                    (singleDispatch.tare_weight
                                      ? Number(
                                          singleDispatch.tare_weight
                                        ).toFixed(3)
                                      : "") +
                                    " " +
                                    singleDispatch.unit;
                                  singleDispatch["Net Weight"] =
                                    singleDispatch.net_weight * 1;
                                  singleDispatch["Net WeightUnit"] =
                                    (singleDispatch.net_weight
                                      ? Number(
                                          singleDispatch.net_weight
                                        ).toFixed(3)
                                      : "") +
                                    " " +
                                    singleDispatch.unit;
                                  singleDispatch["Freight Rate"] = Number(
                                    singleDispatch.freight_rate
                                  ).toFixed(2);
                                  singleDispatch[
                                    "Freight RateUnit"
                                  ] = `₹ ${Number(
                                    singleDispatch.freight_rate
                                  ).toFixed(2)} /${
                                    singlelistView && singlelistView.unit
                                      ? singlelistView.unit
                                      : ""
                                  }`;
                                  singleDispatch["Total Freight"] =
                                    singleDispatch.total_freight
                                      ? Number(
                                          singleDispatch.total_freight
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch[
                                    "Total FreightUnit"
                                  ] = `₹ ${Number(
                                    singleDispatch.total_freight
                                  ).toFixed(2)}`;
                                  singleDispatch["Transporter Advance"] =
                                    singleDispatch.transporter_advance
                                      ? Number(
                                          singleDispatch.transporter_advance
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch["Transporter AdvanceUnit"] =
                                    singleDispatch.transporter_advance
                                      ? `₹ ${Number(
                                          singleDispatch.transporter_advance
                                        ).toFixed(2)}`
                                      : "";

                                  singleDispatch["Transporter Balance"] =
                                    singleDispatch.transporter_balance
                                      ? Number(
                                          singleDispatch.transporter_balance
                                        ).toFixed(2)
                                      : "";
                                  singleDispatch[
                                    "Transporter BalanceUnit"
                                  ] = `₹ ${Number(
                                    singleDispatch.transporter_balance
                                  ).toFixed(2)}`;

                                  singleDispatch["Survey Company"] =
                                    singleDispatch &&
                                    filterData(
                                      SurveyCompanyList,
                                      "id",
                                      singleDispatch.survey_company,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          SurveyCompanyList,
                                          "id",
                                          singleDispatch.survey_company,
                                          "onlyOne"
                                        ).name
                                      : "";
                                  singleDispatch["Survey CompanyId"] =
                                    singleDispatch &&
                                    singleDispatch.survey_company
                                      ? singleDispatch.survey_company
                                      : "-";

                                  singleDispatch["Surveyor"] =
                                    singleDispatch &&
                                    singleDispatch.surveyor_details &&
                                    singleDispatch.surveyor_details.name
                                      ? singleDispatch.surveyor_details.name
                                      : "-";
                                  singleDispatch["SurveyorId"] =
                                    singleDispatch &&
                                    singleDispatch.surveyor_details &&
                                    singleDispatch.surveyor_details.id
                                      ? singleDispatch.surveyor_details.id
                                      : "-";
                                  // singleDispatch["surveyor"] =
                                  //   singleDispatch &&
                                  //   singleDispatch.surveyor_details &&
                                  //   singleDispatch.surveyor_details.name
                                  //     ? singleDispatch.surveyor_details.name
                                  //     : "-";

                                  // singleDispatch["surveyorId"] =
                                  //   singleDispatch &&
                                  //   singleDispatch.surveyor_details &&
                                  //   singleDispatch.surveyor_details.id
                                  //     ? singleDispatch.surveyor_details.id
                                  //     : "-";
                                  singleDispatch["subList"] =
                                    singleDispatch.subList
                                      ? singleDispatch.subList.map((sData) => {
                                          sData["Brand"] = Boolean(
                                            this.handleDispLoosePoCond()
                                          )
                                            ? "-"
                                            : filterData(
                                                BrandList,
                                                "id",
                                                sData.brand,
                                                "onlyOne"
                                              )
                                            ? filterData(
                                                BrandList,
                                                "id",
                                                sData.brand,
                                                "onlyOne"
                                              )["brand_name"]
                                            : "";
                                          sData["Item Category"] = filterData(
                                            ItemCateoryList,
                                            "id",
                                            sData.item_category,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemCateoryList,
                                                "id",
                                                sData.item_category,
                                                "onlyOne"
                                              )["name"]
                                            : "";
                                          sData["Item Type"] = filterData(
                                            ItemList,
                                            "id",
                                            sData.item_type,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemList,
                                                "id",
                                                sData.item_type,
                                                "onlyOne"
                                              )["broken_percentage"]
                                            : "";
                                          sData["Item TypeUI"] = filterData(
                                            ItemList,
                                            "id",
                                            sData.item_type,
                                            "onlyOne"
                                          )
                                            ? `${Number(
                                                filterData(
                                                  ItemList,
                                                  "id",
                                                  sData.item_type,
                                                  "onlyOne"
                                                )["broken_percentage"]
                                              ).toFixed(2)} %`
                                            : "";
                                          sData["Item Typeid"] = sData.item_type
                                            ? sData.item_type
                                            : "";

                                          sData["Packaging"] =
                                            sData.dispatch_packaging &&
                                            sData.dispatch_packaging.map(
                                              (sP) => {
                                                const newAP =
                                                  sP.packaging_details;
                                                return `${newAP.size}${newAP.unit}`;
                                              }
                                            ) &&
                                            sData.dispatch_packaging.map(
                                              (sP) => {
                                                const newAP =
                                                  sP.packaging_details;
                                                return `${newAP.size}${newAP.unit}`;
                                              }
                                            ).length > 0 &&
                                            `tota( )...${
                                              sData.dispatch_packaging.map(
                                                (sP) => {
                                                  const newAP =
                                                    sP.packaging_details;
                                                  return `${newAP.size}${newAP.unit}`;
                                                }
                                              )[0]
                                            }`;

                                          sData["PackagingUI"] = (
                                            <ShowPackaging
                                              noTotalbags={true}
                                              details={sData.dispatch_packaging.map(
                                                (sP) => {
                                                  const newAP =
                                                    sP.packaging_details;
                                                  newAP.used_bags =
                                                    sP.total_bags_dispatched;

                                                  return newAP;
                                                }
                                              )}
                                            />
                                          );

                                          sData["HSN Code"] = filterData(
                                            HSNlist,
                                            "id",
                                            sData.hsn_code,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                HSNlist,
                                                "id",
                                                sData.hsn_code,
                                                "onlyOne"
                                              )["hsn_code"]
                                            : "";
                                          sData["Contract RateUI"] = filterData(
                                            poItemsArrayTOPreview,
                                            "id",
                                            sData.po_item,
                                            "onlyOne"
                                          )
                                            ? `₹ ${Number(
                                                filterData(
                                                  poItemsArrayTOPreview,
                                                  "id",
                                                  sData.po_item,
                                                  "onlyOne"
                                                )["Contract Rate"]
                                              ).toFixed(2)} /${
                                                singlelistView &&
                                                singlelistView.unit
                                                  ? singlelistView.unit
                                                  : ""
                                              }`
                                            : sData.contract_rate
                                            ? `₹ ${Number(
                                                sData.contract_rate
                                              ).toFixed(2)} /${
                                                singlelistView &&
                                                singlelistView.unit
                                                  ? singlelistView.unit
                                                  : ""
                                              }`
                                            : "";

                                          sData["Specifications"] = filterData(
                                            poItemsArrayTOPreviewCopy,
                                            "id",
                                            sData.po_item,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                poItemsArrayTOPreviewCopy,
                                                "id",
                                                sData.po_item,
                                                "onlyOne"
                                              )["Specifications"]
                                            : sData.Specifications
                                            ? sData.Specifications
                                            : "";

                                          sData["SpecificationUI"] = (
                                            <ShowSpecifications
                                              Unit={
                                                singlelistView &&
                                                singlelistView.unit
                                                  ? singlelistView.unit
                                                  : ""
                                              }
                                              details={
                                                sData.Specifications &&
                                                sData.Specifications > 0
                                                  ? sData.Specifications.map(
                                                      (sP) => {
                                                        // sP.used_bags =0
                                                        const ss = sP.nameId
                                                          ? Number(sP.nameId)
                                                          : sP.name;
                                                        const filterS =
                                                          this.state.specifiationTypes.find(
                                                            (st) => st.id === ss
                                                          );
                                                        return {
                                                          ...sP,
                                                          name: filterS
                                                            ? `${filterS.text.toLowerCase()}`
                                                            : "",
                                                          value: filterS
                                                            ? `${
                                                                sP.value
                                                              } ${filterS.unit.toLowerCase()}`
                                                            : sP.value,
                                                        };
                                                      }
                                                    )
                                                  : []
                                              }
                                            />
                                          );
                                          sData["Contract Rate"] = filterData(
                                            poItemsArrayTOPreviewCopy,
                                            "id",
                                            sData.po_item,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                poItemsArrayTOPreviewCopy,
                                                "id",
                                                sData.po_item,
                                                "onlyOne"
                                              )["Contract Rate"]
                                            : sData.contract_rate
                                            ? sData.contract_rate
                                            : "";

                                          return sData;
                                        })
                                      : [];

                                  singleDispatch["Survey Date"] =
                                    singleDispatch.last_survey
                                      ? moment(
                                          singleDispatch.last_survey
                                        ).format("DD MMM YYYY")
                                      : "";
                                  singleDispatch["Survey DateMonth"] =
                                    singleDispatch.last_survey
                                      ? singleDispatch.last_survey
                                      : "";
                                  singleDispatch["Dispatch Status"] =
                                    singleDispatch.dispatch_status
                                      ? singleDispatch.dispatch_status
                                      : "";
                                  singleDispatch["Miller Payment"] =
                                    singleDispatch.miller_payment
                                      ? singleDispatch.miller_payment
                                      : "-";
                                  singleDispatch["Transporter Payment"] =
                                    singleDispatch.transporter_payment
                                      ? singleDispatch.transporter_payment
                                      : "-";
                                  singleDispatch["Broker Payment"] =
                                    singleDispatch.broker_payment
                                      ? singleDispatch.broker_payment
                                      : "-";

                                  singleDispatch["Miller Tcs"] =
                                    singleDispatch.miller_tcs
                                      ? Number(
                                          singleDispatch.miller_tcs
                                        ).toFixed(2)
                                      : 0;

                                  singleDispatch["TCS Amount"] = singleDispatch
                                    ? multiply(
                                        divide(
                                          singleDispatch.miller_tcs
                                            ? Number(singleDispatch.miller_tcs)
                                            : 0,
                                          100
                                        ),
                                        singleDispatch.miller_bill_amount
                                          ? Number(
                                              singleDispatch.miller_bill_amount
                                            ).toFixed(2)
                                          : 0
                                      )
                                    : "-";
                                  singleDispatch["TCS AmountUnit"] =
                                    singleDispatch
                                      ? `₹ ${multiply(
                                          divide(
                                            singleDispatch.miller_tcs
                                              ? Number(
                                                  singleDispatch.miller_tcs
                                                )
                                              : 0,
                                            100
                                          ),
                                          singleDispatch.miller_bill_amount
                                            ? Number(
                                                singleDispatch.miller_bill_amount
                                              ).toFixed(2)
                                            : 0
                                        )}`
                                      : "-";

                                  singleDispatch["Bilty"] =
                                    singleDispatch &&
                                    singleDispatch.bilty_number
                                      ? singleDispatch.bilty_number
                                      : "-";
                                  return singleDispatch;
                                }).sort((a, b) => {
                                  var dateA = moment(b.created_at).format(
                                    "MMM D YYYY h:mm:ss A"
                                  );
                                  var dateB = moment(a.created_at).format(
                                    "MMM D YYYY h:mm:ss A"
                                  );
                                  return new Date(dateA) - new Date(dateB);
                                })
                              : []
                          }
                          whenAddedBeforeValue={() => {
                            if (Boolean(this.handleDispLoosePoCond())) {
                              return true;
                            } else {
                              const pvArr1 = [];
                              const totalBag = [];

                              poItemsArrayTOPreviewCopy2.map((s) => {
                                pvArr1.push(s.po_item_id);

                                s["packagageArrUsed"].map((sData) => {
                                  // if (sData.remaining_bags && sData.used_bags) {
                                  if (sData.used_bags) {
                                    totalBag.push(sData);
                                  } else {
                                  }
                                });
                              });
                              if (totalBag.length > 0) {
                                return true;
                              } else {
                                this.setState({
                                  error:
                                    selectedPVItemArray.length === 0
                                      ? "Please select items!"
                                      : "Please add bags!",
                                  addbagError: true,
                                });
                                return false;
                                // return true
                              }
                            }
                          }}
                          inputArray={[
                            {
                              name: "Dispatch Date",
                              type: "Month",
                              dataType: "text",
                              api: "dispatch_date",
                            },
                            {
                              name: "Dispatched From",
                              type: "select",
                              dataType: "text",
                              array:
                                MillerList && MillerList.length > 0
                                  ? MillerList
                                  : [],
                              value: "name",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.miller_details &&
                                  singlelistView.miller_details
                                    ? singlelistView.miller_details.name
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.miller_details &&
                                  singlelistView.miller_details
                                    ? singlelistView.miller_details.id
                                    : "",
                              },
                              api: "dispatched_from",
                            },
                            !Boolean(this.handleDispLoosePoCond())
                              ? {
                                  name: "Dispatched To",
                                  type: "autocomplete",
                                  dataType: "text",
                                  array:
                                    singlelistView &&
                                    singlelistView.ports_details &&
                                    singlelistView.ports_details.length > 0
                                      ? singlelistView.ports_details.map(
                                          (data) => {
                                            data[
                                              "namecity"
                                            ] = `${data.name} - ${data.city}`;
                                            return data;
                                          }
                                        )
                                      : this.state.PortsList.map((data) => {
                                          data[
                                            "namecity"
                                          ] = `${data.name} - ${data.city}`;
                                          return data;
                                        }),
                                  value: "namecity",
                                  defaultValue: {
                                    autoCompleteValue:
                                      this.state.editingDispatch === false
                                        ? ""
                                        : singlelistView.ports_details[0],
                                  },
                                  api: "dispatched_to",
                                  autoCompleteData: "dispatched_to_port",
                                  onChange: async (submittedData) => {
                                    console.log(
                                      singlelistView.ports_details[0],
                                      submittedData
                                    );
                                    return submittedData;
                                  },
                                }
                              : {
                                  name: "Dispatch To (Godown)",
                                  type: "autocomplete",
                                  dataType: "text",
                                  array:
                                    singlelistView &&
                                    singlelistView.inland_godowns &&
                                    singlelistView.inland_godowns.length > 0
                                      ? singlelistView.inland_godowns.map(
                                          (data) => {
                                            return filterData(
                                              this.props.masters[
                                                "InlandGodownList"
                                              ],
                                              "id",
                                              Number(data),
                                              "onlyOne"
                                            );
                                          }
                                        )
                                      : [],
                                  value: "name",
                                  defaultValue: {
                                    autoCompleteValue:
                                      this.state.editingDispatch === false
                                        ? ""
                                        : filterData(
                                            this.props.masters[
                                              "InlandGodownList"
                                            ],
                                            "id",
                                            Number(
                                              singlelistView.dispatched_to_inland_godown
                                            ),
                                            "onlyOne"
                                          )
                                        ? filterData(
                                            this.props.masters[
                                              "InlandGodownList"
                                            ],
                                            "id",
                                            Number(
                                              singlelistView.dispatched_to_inland_godown
                                            ),
                                            "onlyOne"
                                          )
                                        : "",
                                  },

                                  api: "dispatched_to_inland_godown",
                                  autoCompleteData: "inland_godowns",
                                },
                            {
                              name: "Purchase Order",
                              type: "textField",
                              dataType: "text",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.po_number
                                    ? singlelistView.po_number
                                    : "",
                                id:
                                  singlelistView && singlelistView.id
                                    ? singlelistView.id
                                    : "",
                              },
                              api: "purchase_order",
                            },
                            !Boolean(this.handleDispLoosePoCond()) && {
                              name: "Sales Order",
                              type: "textField",
                              dataType: "text",
                              not_required: true,
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.sales_order_details
                                    ? singlelistView.sales_order_details
                                        .so_number
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.sales_order_details
                                    ? singlelistView.sales_order_details.id
                                    : "",
                              },
                              api: "sales_order",
                              required: "false",
                            },

                            {
                              name: "Contract Type",
                              type: "select",
                              dataType: "text",
                              array:
                                ContractTypeList && ContractTypeList.length > 0
                                  ? ContractTypeList
                                  : [],
                              value: "contract_type",
                              disabled: true,
                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.contract_type_details
                                    ? singlelistView.contract_type_details
                                        .contract_type
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.item_type_details
                                    ? singlelistView.item_type_details.id
                                    : "",
                              },
                            },

                            {
                              name: "Unit",
                              type: "select",
                              dataType: "text",
                              array: unit && unit.length > 0 ? unit : [],
                              value: "name",
                              disabled: !Boolean(this.handleDispLoosePoCond())
                                ? true
                                : false,
                              defaultValue: {
                                value:
                                  singlelistView && singlelistView.unit
                                    ? singlelistView.unit
                                    : "",
                              },
                              api: "unit",
                              onChange: async (submittedData) => {
                                this.setState({
                                  selectedUnit: submittedData["Unit"],
                                });
                                return submittedData;
                              },
                            },

                            !Boolean(this.handleDispLoosePoCond()) && {
                              name: "Gross Weight",
                              type: "textField",
                              dataType: "number",
                              endAdornment: this.state.selectedUnit
                                ? this.state.selectedUnit
                                : singlelistView && singlelistView.unit
                                ? singlelistView.unit
                                : "",
                              onChange: async (submittedData) => {
                                if (
                                  (submittedData["Gross Weight"]
                                    ? Number(submittedData["Gross Weight"])
                                    : 0) <=
                                  (submittedData["Tare Weight"]
                                    ? submittedData["Tare Weight"]
                                    : 0)
                                ) {
                                  submittedData["Tare Weight"] = 0;
                                  this.setState({
                                    error:
                                      "Gross weight can't be less than or equal to Tare Weight!",
                                  });
                                } else {
                                  submittedData["Net Weight"] = Number([
                                    (submittedData["Gross Weight"]
                                      ? Number(submittedData["Gross Weight"])
                                      : 0) -
                                      (submittedData["Tare Weight"]
                                        ? submittedData["Tare Weight"]
                                        : 0),
                                  ]).toFixed(3);
                                }

                                return submittedData;
                              },
                              api: "gross_weight",
                            },
                            !Boolean(this.handleDispLoosePoCond()) && {
                              name: "Tare Weight",
                              type: "textField",
                              dataType: "number",
                              onChange: async (submittedData) => {
                                if (
                                  (submittedData["Gross Weight"]
                                    ? Number(submittedData["Gross Weight"])
                                    : 0) <=
                                  (submittedData["Tare Weight"]
                                    ? submittedData["Tare Weight"]
                                    : 0)
                                ) {
                                  submittedData["Tare Weight"] = 0;
                                  this.setState({
                                    error:
                                      "Tare weight can't be equal or greater to Gross Weight!",
                                  });
                                } else {
                                  submittedData["Net Weight"] = Number([
                                    (submittedData["Gross Weight"]
                                      ? Number(submittedData["Gross Weight"])
                                      : 0) -
                                      (submittedData["Tare Weight"]
                                        ? submittedData["Tare Weight"]
                                        : 0),
                                  ]).toFixed(3);
                                }

                                // submittedData["Total Freight"] = Math.floor(
                                //   (submittedData["Net Weight"]
                                //     ? submittedData["Net Weight"]
                                //     : 0) *
                                //     (submittedData["Freight Rate"]
                                //       ? submittedData["Freight Rate"]
                                //       : 0)
                                // );

                                return submittedData;
                              },

                              api: "tare_weight",
                              endAdornment: this.state.selectedUnit
                                ? this.state.selectedUnit
                                : singlelistView && singlelistView.unit
                                ? singlelistView.unit
                                : "",
                            },
                            singlelistView &&
                              singlelistView.contract_type_details && {
                                //   .contract_type === "ex factory" && // singlelistView.contract_type_details
                                // disabled: true,
                                name: "Net Weight",
                                type: "textField",
                                dataType: "number",
                                api: "net_weight",

                                endAdornment: this.state.selectedUnit
                                  ? this.state.selectedUnit
                                  : singlelistView && singlelistView.unit
                                  ? singlelistView.unit
                                  : "",
                                onChange: async (submittedData) => {
                                  console.log(
                                    "net",
                                    Boolean(this.handleDispLoosePoCond())
                                  );
                                  if (Boolean(this.handleDispLoosePoCond())) {
                                    submittedData["Miller Bill Amount"] =
                                      Number(
                                        (submittedData["Net Weight"]
                                          ? submittedData["Net Weight"]
                                          : 0) *
                                          Number(
                                            poItemsArrayTOPreviewCopy2?.[0]?.[
                                              "Contract Rate"
                                            ] || 0
                                          )
                                      ).toFixed(2);
                                  }
                                  return submittedData;
                                },
                              },

                            {
                              name: "Miller Bill Number",
                              type: "textField",
                              dataType: "text",
                              api: "miller_bill_number",
                              capitalize: true,
                            },
                            {
                              name: "Miller Bill Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              startAdornment: "₹",
                              api: "miller_bill_amount",
                            },
                            {
                              name: "Other Amount",
                              type: "textField",
                              dataType: "number",
                              not_required: true,
                              startAdornment: "₹",
                              onChange: async (submittedData) => {
                                submittedData["Miller Total Amount"] = subtract(
                                  sum([
                                    submittedData["Miller Advance"]
                                      ? -Number(submittedData["Miller Advance"])
                                      : 0,
                                    submittedData["Transporter Advance"]
                                      ? Number(
                                          submittedData["Transporter Advance"]
                                        )
                                      : 0,
                                    submittedData["TCS Amount"]
                                      ? Number(submittedData["TCS Amount"])
                                      : 0,
                                    submittedData["Miller Bill Amount"]
                                      ? Number(
                                          submittedData["Miller Bill Amount"]
                                        )
                                      : 0,
                                    submittedData["Other Amount"]
                                      ? Number(submittedData["Other Amount"])
                                      : 0,
                                  ]),
                                  submittedData["Other Deduction"]
                                    ? Number(submittedData["Other Deduction"])
                                    : 0
                                ).toFixed(2);
                                return submittedData;
                              },
                              api: "other_amount",
                            },
                            {
                              name: "Other Deduction",
                              type: "textField",
                              dataType: "number",
                              not_required: true,
                              startAdornment: "₹",
                              onChange: async (submittedData) => {
                                submittedData["Miller Total Amount"] = subtract(
                                  sum([
                                    submittedData["Miller Advance"]
                                      ? -Number(submittedData["Miller Advance"])
                                      : 0,
                                    submittedData["Transporter Advance"]
                                      ? Number(
                                          submittedData["Transporter Advance"]
                                        )
                                      : 0,
                                    submittedData["TCS Amount"]
                                      ? Number(submittedData["TCS Amount"])
                                      : 0,
                                    submittedData["Miller Bill Amount"]
                                      ? Number(
                                          submittedData["Miller Bill Amount"]
                                        )
                                      : 0,
                                    submittedData["Other Amount"]
                                      ? Number(submittedData["Other Amount"])
                                      : 0,
                                  ]),
                                  submittedData["Other Deduction"]
                                    ? Number(submittedData["Other Deduction"])
                                    : 0
                                ).toFixed(2);
                                return submittedData;
                              },
                              api: "other_deduction",
                            },
                            {
                              name: "Miller Advance",
                              type: "textField",
                              dataType: "number",

                              onChange: async (submittedData) => {
                                submittedData["Miller Total Amount"] = subtract(
                                  sum([
                                    submittedData["Miller Advance"]
                                      ? -Number(submittedData["Miller Advance"])
                                      : 0,
                                    submittedData["Transporter Advance"]
                                      ? Number(
                                          submittedData["Transporter Advance"]
                                        )
                                      : 0,
                                    submittedData["TCS Amount"]
                                      ? Number(submittedData["TCS Amount"])
                                      : 0,
                                    submittedData["Miller Bill Amount"]
                                      ? Number(
                                          submittedData["Miller Bill Amount"]
                                        )
                                      : 0,
                                    submittedData["Other Amount"]
                                      ? Number(submittedData["Other Amount"])
                                      : 0,
                                  ]),
                                  submittedData["Other Deduction"]
                                    ? Number(submittedData["Other Deduction"])
                                    : 0
                                ).toFixed(2);
                                return submittedData;
                              },
                              api: "miller_advance",
                            },
                            {
                              name: "Miller Tcs",
                              type: "textField",
                              dataType: "number",
                              endAdornment: "%",
                              onChange: async (submittedData) => {
                                submittedData["TCS Amount"] = multiply(
                                  divide(
                                    submittedData["Miller Tcs"]
                                      ? Number(submittedData["Miller Tcs"])
                                      : 0,
                                    100
                                  ),
                                  submittedData["Miller Bill Amount"]
                                    ? Number(
                                        submittedData["Miller Bill Amount"]
                                      )
                                    : 0
                                ).toFixed(2);
                                submittedData["Miller Total Amount"] = subtract(
                                  sum([
                                    submittedData["Miller Advance"]
                                      ? -Number(submittedData["Miller Advance"])
                                      : 0,
                                    submittedData["Transporter Advance"]
                                      ? Number(
                                          submittedData["Transporter Advance"]
                                        )
                                      : 0,
                                    submittedData["TCS Amount"]
                                      ? Number(submittedData["TCS Amount"])
                                      : 0,
                                    submittedData["Miller Bill Amount"]
                                      ? Number(
                                          submittedData["Miller Bill Amount"]
                                        )
                                      : 0,
                                    submittedData["Other Amount"]
                                      ? Number(submittedData["Other Amount"])
                                      : 0,
                                  ]),
                                  submittedData["Other Deduction"]
                                    ? Number(submittedData["Other Deduction"])
                                    : 0
                                ).toFixed(2);
                                return submittedData;
                              },
                              api: "miller_tcs",
                            },
                            {
                              name: "TCS Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              startAdornment: "₹",
                            },
                            // {
                            //   name: "Miller Bill Amount",
                            //   type: "textField",
                            //   dataType: "number",
                            //   disabled: true,
                            //   startAdornment: "₹",
                            //   api: "miller_bill_amount",
                            // },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Transporter Advance",
                                type: "textField",
                                onChange: async (submittedData) => {
                                  submittedData["Miller Total Amount"] =
                                    subtract(
                                      sum([
                                        submittedData["Miller Advance"]
                                          ? -Number(
                                              submittedData["Miller Advance"]
                                            )
                                          : 0,
                                        submittedData["Transporter Advance"]
                                          ? Number(
                                              submittedData[
                                                "Transporter Advance"
                                              ]
                                            )
                                          : 0,
                                        submittedData["TCS Amount"]
                                          ? Number(submittedData["TCS Amount"])
                                          : 0,
                                        submittedData["Miller Bill Amount"]
                                          ? Number(
                                              submittedData[
                                                "Miller Bill Amount"
                                              ]
                                            )
                                          : 0,
                                        submittedData["Other Amount"]
                                          ? Number(
                                              submittedData["Other Amount"]
                                            )
                                          : 0,
                                      ]),
                                      submittedData["Other Deduction"]
                                        ? Number(
                                            submittedData["Other Deduction"]
                                          )
                                        : 0
                                    ).toFixed(2);
                                  submittedData["Transporter Balance"] =
                                    subtract(
                                      submittedData["Total Freight"]
                                        ? submittedData["Total Freight"]
                                        : 0,
                                      submittedData["Transporter Advance"]
                                        ? Number(
                                            submittedData["Transporter Advance"]
                                          )
                                        : 0
                                    ).toFixed(2);

                                  return submittedData;
                                },
                                dataType: "number",
                                api: "transporter_advance",
                                startAdornment: "₹",
                              },
                            {
                              name: "Miller Total Amount",
                              type: "textField",
                              dataType: "number",
                              disabled: true,
                              startAdornment: "₹",

                              api: "miller_total_amount",
                            },

                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Transpoter Name",
                                type: "select",
                                numeric: true,

                                dataType: "text",
                                array:
                                  TransporterList && TransporterList.length > 0
                                    ? TransporterList
                                    : [],
                                value: "name",

                                defaultValue: {
                                  value:
                                    singlelistView &&
                                    singlelistView.transporter_details
                                      ? singlelistView.transporter_details.name
                                      : "",
                                  id:
                                    singlelistView &&
                                    singlelistView.transporter_details
                                      ? singlelistView.transporter_details.id
                                      : "",
                                },
                                api: "transporter",
                              },

                            !Boolean(this.handleDispLoosePoCond()) && {
                              name: "Truck Number",
                              type: "textField",
                              dataType: "text",
                              api: "truck_number",
                              upperCase: true,
                            },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Freight Rate",
                                type: "textField",
                                dataType: "number",

                                api: "freight_rate",
                                startAdornment: "₹",
                                endAdornment: this.state.selectedUnit
                                  ? `/${this.state.selectedUnit}`
                                  : singlelistView && singlelistView.unit
                                  ? `/${singlelistView.unit}`
                                  : "",
                                onChange: async (submittedData) => {
                                  submittedData["Total Freight"] = Math.floor(
                                    selectedPVItemArray.reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) +
                                        Number(
                                          filterData(
                                            poItemsArrayTOPreviewCopy2,
                                            "po_item_id",
                                            currentValue,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                poItemsArrayTOPreviewCopy2,
                                                "po_item_id",
                                                currentValue,
                                                "onlyOne"
                                              )["packagageArrUsed"]
                                              ? filterData(
                                                  poItemsArrayTOPreviewCopy2,
                                                  "po_item_id",
                                                  currentValue,
                                                  "onlyOne"
                                                )["packagageArrUsed"].reduce(
                                                  (
                                                    previousValue1,
                                                    currentValue1
                                                  ) =>
                                                    Number(previousValue1) +
                                                    (currentValue1.total_dispatch
                                                      ? Number(
                                                          currentValue1.total_dispatch
                                                        )
                                                      : 0),
                                                  0
                                                )
                                              : 0
                                            : 0
                                        ),
                                      0
                                    ) *
                                      (submittedData["Freight Rate"]
                                        ? submittedData["Freight Rate"]
                                        : 0)
                                  ).toFixed(2);

                                  submittedData["Transporter Balance"] =
                                    subtract(
                                      submittedData["Total Freight"]
                                        ? submittedData["Total Freight"]
                                        : 0,
                                      submittedData["Transporter Advance"]
                                        ? Number(
                                            submittedData["Transporter Advance"]
                                          )
                                        : 0
                                    ).toFixed(2);
                                  return submittedData;
                                },
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Transporter Balance",
                                type: "textField",
                                disabled: true,
                                dataType: "number",
                                api: "transporter_balance",
                                startAdornment: "₹",
                              },

                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Total Freight",
                                type: "textField",
                                dataType: "number",
                                api: "total_freight",
                                disabled: true,
                                startAdornment: "₹",
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Bilty",
                                type: "textField",
                                dataType: "text",
                                api: "bilty_number",
                                capitalize: true,
                              },
                            singlelistView &&
                              singlelistView.contract_type_details &&
                              singlelistView.contract_type_details
                                .contract_type === "ex factory" && {
                                name: "Bilty Date",
                                type: "Month",
                                dataType: "text",
                                api: "bilty_date",
                                defaultValue: null,
                              },

                            {
                              name: "Survey Company",
                              type: "select",
                              dataType: "text",
                              api: "survey_company",
                              array:
                                SurveyCompanyList &&
                                SurveyCompanyList.length > 0
                                  ? SurveyCompanyList
                                  : [],
                              value: "name",
                              defaultValue: {
                                value: singlelistView.survey_company
                                  ? filterData(
                                      SurveyCompanyList,
                                      "id",
                                      singlelistView.survey_company,
                                      "onlyOne"
                                    ).name
                                  : "",
                                id: singlelistView.survey_company
                                  ? singlelistView.survey_company
                                  : "",
                              },
                              numeric: true,

                              onChange: async (
                                submittedData,
                                setLoad,
                                loadClose
                              ) => {
                                if (
                                  SurveyCompanyList &&
                                  SurveyCompanyList.length > 0
                                ) {
                                  if (setLoad) {
                                    setLoad();
                                  }
                                  this.props
                                    .PurchaseOrdersFunction(
                                      "get",
                                      "masters/surveyor/list",
                                      null,
                                      "",
                                      null,
                                      {
                                        is_active: "True",
                                        company_id:
                                          submittedData["Survey CompanyId"],
                                      }
                                    )
                                    .then((res) => {
                                      if (res) {
                                        console.log(res, "surveyor list");
                                        this.setState(
                                          { survayorList: res },
                                          () => {
                                            if (loadClose) {
                                              loadClose();
                                            }
                                          }
                                        );
                                      }
                                    });
                                  submittedData["Surveyor"] = "";
                                  submittedData["SurveyorId"] = "";

                                  return submittedData;
                                }
                              },
                            },
                            survayorList.length > 0 && {
                              name: "Surveyor",
                              type: "select",
                              not_required: true,

                              dataType: "text",
                              array:
                                survayorList && survayorList.length > 0
                                  ? survayorList
                                  : [],
                              value: "name" ? "name" : "",

                              api: "surveyor",
                              numeric: true,
                              defaultValue: {
                                value: singlelistView.surveyor_details
                                  ? singlelistView.surveyor_details.name
                                  : "",
                                id: singlelistView.surveyor_details
                                  ? singlelistView.surveyor_details.id
                                  : "",
                              },
                            },

                            singlelistView.last_survey && {
                              name: "Survey Date",
                              type: "Month",
                              dataType: "text",
                              disabled: true,
                              api: singlelistView.last_survey
                                ? "last_survey"
                                : false,
                              defaultValue: {
                                value: singlelistView.last_survey
                                  ? singlelistView.last_survey
                                  : "",
                              },
                            },

                            {
                              name: "Broker",
                              type: "select",
                              dataType: "text",
                              array:
                                BrokerList && BrokerList.length > 0
                                  ? BrokerList
                                  : [],
                              value: "name",

                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.broker_details
                                    ? singlelistView.broker_details.name //default_brokerage
                                    : "",
                                id:
                                  singlelistView &&
                                  singlelistView.broker_details
                                    ? singlelistView.broker_details.id
                                    : "",
                              },
                              disabled: true,
                            },

                            {
                              name: "Dispatch Status",
                              type: "select",
                              dataType: "text",
                              array:
                                statusDispatch && statusDispatch.length > 0
                                  ? statusDispatch
                                  : [],
                              value: "name",

                              defaultValue: {
                                value:
                                  singlelistView &&
                                  singlelistView.statusDispatch
                                    ? singlelistView.statusDispatch
                                    : "dispatched",
                              },
                              api: "dispatch_status",
                            },
                            {
                              name: "Miller payment",
                              datatype: "text",
                              defaultValue: {
                                value: "UNPAID",
                              },
                              api: "miller_payment",
                            },
                            {
                              name: "Remarks",
                              type: "textField",
                              dataType: "text",
                              not_required: true,
                              api: "remarks",
                              // capitalize: true,
                            },

                            // {
                            //   name: "Miller Payment",
                            //   type: "checkBox",
                            //   defaultValue: {
                            //     value: false,
                            //   },
                            //   api: "miller_payment",
                            // },
                            // singlelistView &&
                            //   singlelistView.contract_type_details &&
                            //   singlelistView.contract_type_details.contract_type ===
                            //     "ex factory" && {
                            //     name: "Transporter Payment",
                            //     type: "checkBox",
                            //     defaultValue: {
                            //       value: false,
                            //     },
                            //     api: "transporter_payment",
                            //   },
                            // {
                            //   name: "Broker Payment",
                            //   type: "checkBox",
                            //   defaultValue: {
                            //     value: false,
                            //   },
                            //   api: "broker_payment",
                            // },
                          ]}
                          setSelectedList={(selected) => {
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                "masters/surveyor/list",
                                null,
                                "",
                                null,
                                {
                                  is_active: "True",
                                  company_id: selected["Survey CompanyId"],
                                }
                              )
                              .then((res) => {
                                console.log(
                                  res,
                                  selected,
                                  selected["Survey CompanyId"],
                                  "wsrtyujio"
                                );
                                if (res) {
                                  this.setState({ survayorList: res });
                                } else {
                                  console.log("no surveyor");
                                }
                              });

                            const poItemArr = [];
                            selected.subList.map((sSub) => {
                              if (!poItemArr.includes(sSub.po_item)) {
                                poItemArr.push(sSub.po_item);
                              }
                              return sSub;
                            });

                            this.setState(
                              {
                                editingDispatch: selected,
                                poItemsArrayTOPreviewCopy2:
                                  poItemsArrayTOPreviewCopy
                                    .map((sList) => {
                                      if (poItemArr.includes(sList.id)) {
                                        const sListPackaging = filterData(
                                          selected.subList,
                                          "po_item",
                                          sList.id,
                                          "onlyOne"
                                        );
                                        console.log(
                                          selected,
                                          "selected sublist"
                                        );
                                        const packaging_details =
                                          sListPackaging.dispatch_packaging &&
                                          sListPackaging.dispatch_packaging
                                            .length > 0
                                            ? sListPackaging.dispatch_packaging.map(
                                                (s) => {
                                                  s.size =
                                                    s.packaging_details.size;
                                                  s.unit =
                                                    s.packaging_details.unit;
                                                  s.remaining_bags = filterData(
                                                    sList.packagageArrUsed,
                                                    "id",
                                                    s.packaging_details.id,
                                                    "onlyOne"
                                                  )
                                                    ? filterData(
                                                        sList.packagageArrUsed,
                                                        "id",
                                                        s.packaging_details.id,
                                                        "onlyOne"
                                                      ).remaining_bags
                                                    : s.remaining_bags;
                                                  s.used_bags_po = filterData(
                                                    sList.packagageArrUsed,
                                                    "id",
                                                    s.packaging_details.id,
                                                    "onlyOne"
                                                  )
                                                    ? filterData(
                                                        sList.packagageArrUsed,
                                                        "id",
                                                        s.packaging_details.id,
                                                        "onlyOne"
                                                      ).used_bags_po
                                                    : s.used_bags_po;
                                                  return s;
                                                }
                                              )
                                            : [];
                                        // sData["Specifications"] = (
                                        //   <ShowSpecifications
                                        //     details={
                                        //       sData.specifications && sData.specifications.length > 0
                                        //         ? sData.specifications.map((sS) => {
                                        //             return sS;
                                        //           })
                                        //         : []
                                        //     }
                                        //   />
                                        // );
                                        const addData = {
                                          brand: sListPackaging.brand,
                                          po_item: sListPackaging.po_item,
                                          packagingArr: packaging_details.map(
                                            (sP) => {
                                              sP.sizeUnit = `${sP.size} ${sP.unit}`;
                                              return sP;
                                            }
                                          ),
                                          total_quantity:
                                            sListPackaging.total_quantity,

                                          packaging: sListPackaging.packaging,
                                        };

                                        addData["id"] = sListPackaging.id;

                                        addData.po_item_id =
                                          sListPackaging.po_item;

                                        // addData["used_bags"] = sListPackaging.used_bags ? sListPackaging.used_bags : null;
                                        addData["dispatched_quantity"] =
                                          sListPackaging.dispatched_quantity;
                                        addData[poItemheader[1]] = filterData(
                                          ItemCateoryList,
                                          "id",
                                          sListPackaging.item_category,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemCateoryList,
                                              "id",
                                              sListPackaging.item_category,
                                              "onlyOne"
                                            ).name
                                          : "";
                                        console.log(
                                          sListPackaging,
                                          "sListPackaging"
                                        );
                                        addData[`${poItemheader[1]}id`] =
                                          sListPackaging.item_category;

                                        addData[poItemheader[2]] = filterData(
                                          ItemList,
                                          "id",
                                          sListPackaging.item_type,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemList,
                                              "id",
                                              sListPackaging.item_type,
                                              "onlyOne"
                                            ).broken_percentage
                                          : "";
                                        addData[`${poItemheader[2]}id`] =
                                          sListPackaging.item_type
                                            ? sListPackaging.item_type
                                            : sListPackaging[
                                                `${poItemheader[2]}id`
                                              ]
                                            ? sListPackaging[
                                                `${poItemheader[2]}id`
                                              ]
                                            : "";
                                        console.log(sListPackaging, "addData");

                                        addData[poItemheader[0]] = filterData(
                                          BrandList,
                                          "id",
                                          sListPackaging.brand,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              BrandList,
                                              "id",
                                              sListPackaging.brand,
                                              "onlyOne"
                                            ).brand_name
                                          : "";
                                        addData[`${poItemheader[0]}id`] =
                                          sListPackaging.brand;

                                        addData.selectedBrandMultipleName = `${
                                          filterData(
                                            BrandList,
                                            "id",
                                            sListPackaging.brand,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                BrandList,
                                                "id",
                                                sListPackaging.brand,
                                                "onlyOne"
                                              ).brand_name
                                            : ""
                                        }-${
                                          filterData(
                                            ItemCateoryList,
                                            "id",
                                            sListPackaging.item_category,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemCateoryList,
                                                "id",
                                                sListPackaging.item_category,
                                                "onlyOne"
                                              ).name
                                            : ""
                                        }-${
                                          filterData(
                                            ItemList,
                                            "id",
                                            sListPackaging.item_type,
                                            "onlyOne"
                                          )
                                            ? filterData(
                                                ItemList,
                                                "id",
                                                sListPackaging.item_type,
                                                "onlyOne"
                                              ).broken_percentage
                                            : ""
                                        } %`;

                                        addData[poItemheader[3]] =
                                          packaging_details.map((sP) => {
                                            return `${sP.size} ${sP.unit}`;
                                          }) &&
                                          packaging_details.map((sP) => {
                                            return `${sP.size} ${sP.unit}`;
                                          }).length > 0
                                            ? packaging_details.map((sP) => {
                                                return `${sP.size} ${sP.unit}`;
                                              })[0]
                                            : "-";
                                        addData["packagageArrUsed"] =
                                          packaging_details.map((sP) => {
                                            const newObj = {};
                                            newObj.id = sP.id;

                                            newObj.bag_type =
                                              sP.packaging_details.bag_type;
                                            newObj.packaging = sP.packaging;

                                            newObj.dispatch_items =
                                              sP.dispatch_items;

                                            newObj.net_weight = sP.net_weight
                                              ? sP.net_weight
                                              : "0.000";

                                            newObj.size = sP.size;
                                            newObj.unit = sP.unit;
                                            newObj.sizeUnit = `${sP.size} ${sP.unit}`;
                                            newObj.total_bags =
                                              sP.remaining_bags;
                                            newObj.remaining_bags =
                                              sP.remaining_bags;

                                            newObj.total_dispatch = multiply(
                                              Number(
                                                sP.total_bags_dispatched
                                                  ? sP.total_bags_dispatched
                                                  : 0
                                              ),
                                              this.changeUnit(
                                                sP.unit,
                                                singlelistView &&
                                                  singlelistView.unit
                                                  ? singlelistView.unit
                                                  : "",
                                                sP.size
                                              )
                                            );

                                            newObj.total_amount = Math.floor(
                                              (sListPackaging["Contract Rate"]
                                                ? sListPackaging[
                                                    "Contract Rate"
                                                  ]
                                                : 0) *
                                                this.changeUnit(
                                                  sP.unit,
                                                  singlelistView &&
                                                    singlelistView.unit
                                                    ? singlelistView.unit
                                                    : "",
                                                  sP.size
                                                ) *
                                                (sP.total_bags_dispatched
                                                  ? sP.total_bags_dispatched
                                                  : 0)
                                            );

                                            newObj.used_bags =
                                              sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0;
                                            newObj.used_bags_fordiapatch =
                                              sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0;
                                            newObj.used_bags_po =
                                              sP.used_bags_po;

                                            return newObj;
                                          });
                                        addData["PackagingId"] =
                                          packaging_details;
                                        addData[poItemheader[4]] =
                                          HSNlist &&
                                          HSNlist.length > 0 &&
                                          HSNlist.filter(
                                            (sM) =>
                                              sM.id ===
                                              (sListPackaging.hsn_code
                                                ? sListPackaging.hsn_code
                                                : 0)
                                          )[0]
                                            ? HSNlist.filter(
                                                (sM) =>
                                                  sM.id ===
                                                  (sListPackaging.hsn_code
                                                    ? sListPackaging.hsn_code
                                                    : 0)
                                              )[0].hsn_code
                                            : "";

                                        addData[`${poItemheader[4]}id`] =
                                          sListPackaging.hsn_code
                                            ? sListPackaging.hsn_code
                                            : "";
                                        addData[poItemheader[5]] =
                                          sListPackaging.total_quantity;
                                        addData[`${poItemheader[5]}Unit`] = `${
                                          sListPackaging.total_quantity
                                        } ${
                                          singlelistView && singlelistView.unit
                                            ? singlelistView.unit
                                            : ""
                                        }`;
                                        console.log(
                                          sListPackaging,
                                          "slist packaging"
                                        );
                                        addData[poItemheader[6]] = addData[
                                          poItemheader[6]
                                        ] = sList["Contract Rate"]
                                          ? sList["Contract Rate"]
                                          : sList.contract_rate;
                                        addData["specificationArr"] =
                                          sList["Specifications"];
                                        addData["Specifications"] =
                                          sList["Specifications"];

                                        addData[`${poItemheader[6]}Unit`] = `${
                                          sList["Contract Rate"]
                                            ? sList["Contract Rate"]
                                            : sList.contract_rate
                                        }/${
                                          singlelistView && singlelistView.unit
                                            ? singlelistView.unit
                                            : ""
                                        }`;
                                        addData[poItemheader[7]] =
                                          sList.sales_tax_terms;
                                        addData.id = sListPackaging.id;

                                        return addData;
                                      } else {
                                        return false;
                                      }
                                    })
                                    .filter((sD) => sD !== false),
                                // poItemsArrayTOPreviewCopy:
                                //   poItemsArrayTOPreviewCopy
                                poItemsArrayTOPreview: poItemsArrayTOPreviewCopy
                                  .map((sList) => {
                                    if (poItemArr.includes(sList.id)) {
                                      const sListPackaging = filterData(
                                        selected.subList,
                                        "po_item",
                                        sList.id,
                                        "onlyOne"
                                      );
                                      console.log(
                                        sListPackaging,
                                        "sListPackaging"
                                      );
                                      const packaging_details =
                                        sListPackaging.dispatch_packaging &&
                                        sListPackaging.dispatch_packaging
                                          .length > 0
                                          ? sListPackaging.dispatch_packaging.map(
                                              (s) => {
                                                s.size =
                                                  s.packaging_details.size;
                                                s.unit =
                                                  s.packaging_details.unit;
                                                s.remaining_bags = filterData(
                                                  sList.packagageArrUsed,
                                                  "id",
                                                  s.packaging_details.id,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      sList.packagageArrUsed,
                                                      "id",
                                                      s.packaging_details.id,
                                                      "onlyOne"
                                                    ).remaining_bags
                                                  : s.remaining_bags;
                                                s.used_bags_po = filterData(
                                                  sList.packagageArrUsed,
                                                  "id",
                                                  s.packaging_details.id,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      sList.packagageArrUsed,
                                                      "id",
                                                      s.packaging_details.id,
                                                      "onlyOne"
                                                    ).used_bags_po
                                                  : s.used_bags_po;
                                                return s;
                                              }
                                            )
                                          : [];

                                      const addData = {
                                        brand: sListPackaging.brand,
                                        po_item: sListPackaging.po_item,
                                        // hsn_code: sListPackaging.hsn_code ? sListPackaging.hsn_code : '',
                                        packagingArr: packaging_details.map(
                                          (sP) => {
                                            sP.sizeUnit = `${sP.size} ${sP.unit}`;
                                            return sP;
                                          }
                                        ),
                                        total_quantity:
                                          sListPackaging.total_quantity,

                                        packaging: sListPackaging.packaging,
                                      };
                                      addData["id"] = sListPackaging.id;

                                      addData.po_item_id =
                                        sListPackaging.po_item;
                                      addData["dispatched_quantity"] =
                                        sListPackaging.dispatched_quantity;
                                      addData[poItemheader[1]] = filterData(
                                        ItemCateoryList,
                                        "id",
                                        sListPackaging.item_category,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            ItemCateoryList,
                                            "id",
                                            sListPackaging.item_category,
                                            "onlyOne"
                                          ).name
                                        : "";
                                      addData[`${poItemheader[1]}id`] =
                                        sListPackaging.item_category;

                                      addData[poItemheader[2]] = filterData(
                                        ItemList,
                                        "id",
                                        sListPackaging.item_type,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            ItemList,
                                            "id",
                                            sListPackaging.item_type,
                                            "onlyOne"
                                          ).broken_percentage
                                        : "";
                                      addData[`${poItemheader[2]}id`] =
                                        sListPackaging.item_type
                                          ? sListPackaging.item_type
                                          : sListPackaging[
                                              `${poItemheader[2]}id`
                                            ]
                                          ? sListPackaging[
                                              `${poItemheader[2]}id`
                                            ]
                                          : "";

                                      console.log(
                                        sListPackaging,
                                        "sListPackaging addData"
                                      );
                                      addData[poItemheader[0]] = filterData(
                                        BrandList,
                                        "id",
                                        sListPackaging.brand,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            BrandList,
                                            "id",
                                            sListPackaging.brand,
                                            "onlyOne"
                                          ).brand_name
                                        : "";
                                      addData[`${poItemheader[0]}id`] =
                                        sListPackaging.brand;

                                      addData.selectedBrandMultipleName = `${
                                        filterData(
                                          BrandList,
                                          "id",
                                          sListPackaging.brand,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              BrandList,
                                              "id",
                                              sListPackaging.brand,
                                              "onlyOne"
                                            ).brand_name
                                          : ""
                                      }-${
                                        filterData(
                                          ItemCateoryList,
                                          "id",
                                          sListPackaging.item_category,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemCateoryList,
                                              "id",
                                              sListPackaging.item_category,
                                              "onlyOne"
                                            ).name
                                          : ""
                                      }-${
                                        filterData(
                                          ItemList,
                                          "id",
                                          sListPackaging.item_type,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              ItemList,
                                              "id",
                                              sListPackaging.item_type,
                                              "onlyOne"
                                            ).broken_percentage
                                          : ""
                                      } %`;

                                      addData[poItemheader[3]] =
                                        packaging_details.map((sP) => {
                                          return `${sP.size} ${sP.unit}`;
                                        }) &&
                                        packaging_details.map((sP) => {
                                          return `${sP.size} ${sP.unit}`;
                                        }).length > 0
                                          ? packaging_details.map((sP) => {
                                              return `${sP.size} ${sP.unit}`;
                                            })[0]
                                          : "-";

                                      addData["packagageArrUsed"] =
                                        packaging_details.map((sP) => {
                                          const newObj = {};
                                          newObj.id = sP.id;
                                          newObj.bag_type =
                                            sP.packaging_details.bag_type;
                                          newObj.packaging = sP.packaging;

                                          newObj.dispatch_items =
                                            sP.dispatch_items;

                                          newObj.net_weight = sP.net_weight
                                            ? sP.net_weight
                                            : "0.000";

                                          newObj.size = sP.size;
                                          newObj.unit = sP.unit;
                                          newObj.sizeUnit = `${sP.size} ${sP.unit}`;
                                          newObj.total_bags = sP.remaining_bags;
                                          newObj.remaining_bags =
                                            sP.remaining_bags;

                                          newObj.total_dispatch = multiply(
                                            Number(
                                              sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0
                                            ),
                                            this.changeUnit(
                                              sP.unit,
                                              singlelistView &&
                                                singlelistView.unit
                                                ? singlelistView.unit
                                                : "",
                                              sP.size
                                            )
                                          );

                                          newObj.total_amount = Math.floor(
                                            (sListPackaging["Contract Rate"]
                                              ? sListPackaging["Contract Rate"]
                                              : 0) *
                                              this.changeUnit(
                                                sP.unit,
                                                singlelistView &&
                                                  singlelistView.unit
                                                  ? singlelistView.unit
                                                  : "",
                                                sP.size
                                              ) *
                                              (sP.total_bags_dispatched
                                                ? sP.total_bags_dispatched
                                                : 0)
                                          );
                                          newObj.used_bags =
                                            sP.total_bags_dispatched
                                              ? sP.total_bags_dispatched
                                              : 0;

                                          newObj.used_bags_fordiapatch =
                                            sP.total_bags_dispatched
                                              ? sP.total_bags_dispatched
                                              : 0;
                                          newObj.used_bags_po = sP.used_bags_po;
                                          return newObj;
                                        });
                                      addData["PackagingId"] =
                                        packaging_details;
                                      addData[poItemheader[4]] =
                                        HSNlist &&
                                        HSNlist.length > 0 &&
                                        HSNlist.filter(
                                          (sM) =>
                                            sM.id ===
                                            (sListPackaging.hsn_code
                                              ? sListPackaging.hsn_code
                                              : 0)
                                        )[0]
                                          ? HSNlist.filter(
                                              (sM) =>
                                                sM.id ===
                                                (sListPackaging.hsn_code
                                                  ? sListPackaging.hsn_code
                                                  : 0)
                                            )[0].hsn_code
                                          : "";

                                      addData[`${poItemheader[4]}id`] =
                                        sListPackaging.hsn_code
                                          ? sListPackaging.hsn_code
                                          : "";
                                      addData[poItemheader[5]] =
                                        sListPackaging.total_quantity;
                                      addData[`${poItemheader[5]}Unit`] = `${
                                        sListPackaging.total_quantity
                                      } ${
                                        singlelistView && singlelistView.unit
                                          ? singlelistView.unit
                                          : ""
                                      }`;
                                      console.log(addData, "add data");
                                      addData[`${poItemheader[6]}Unit`] = `${
                                        sListPackaging["Contract Rate"]
                                          ? sListPackaging["Contract Rate"]
                                          : sListPackaging.contract_rate
                                      }/${
                                        singlelistView && singlelistView.unit
                                          ? singlelistView.unit
                                          : ""
                                      }`;
                                      addData[poItemheader[8]] =
                                        sListPackaging.Specifications;
                                      addData[poItemheader[7]] =
                                        sList.sales_tax_terms;
                                      addData[poItemheader[6]] = addData[
                                        poItemheader[6]
                                      ] = sList["Contract Rate"]
                                        ? sList["Contract Rate"]
                                        : sList.contract_rate;
                                      addData["specificationArr"] =
                                        sList["Specifications"];
                                      addData["Specifications"] =
                                        sList["Specifications"];

                                      addData[`${poItemheader[6]}Unit`] = `${
                                        sList["Contract Rate"]
                                          ? sList["Contract Rate"]
                                          : sList.contract_rate
                                      }/${
                                        singlelistView && singlelistView.unit
                                          ? singlelistView.unit
                                          : ""
                                      }`;
                                      addData.id = sListPackaging.id;

                                      // addData[poItemheader[7]] = sListPackaging[
                                      //   "Specifications"
                                      // ]
                                      //   ? sListPackaging["Specifications"]
                                      //   : sListPackaging.specifications;
                                      return addData;
                                    } else {
                                      return false;
                                    }
                                  })
                                  .filter((sD) => sD !== false),
                                selectedPVItemArray: poItemsArrayTOPreviewCopy
                                  .map((sList) => {
                                    if (poItemArr.includes(sList.id)) {
                                      return sList;
                                    } else {
                                      return false;
                                    }
                                  })
                                  .filter((sD) => sD !== false)
                                  .map((s) => {
                                    return s.id;
                                  }),
                              },
                              () => {}
                            );
                          }}
                          postData={this.props.PurchaseOrdersFunction}
                          fetchAgain={() => {
                            this.setState({ editingDispatch: false });
                            this.props
                              .PurchaseOrdersFunction(
                                "get",
                                EndPoints["PurchaseOrders"],
                                null,
                                null,
                                null,
                                { status: this.state.selctedStatus },
                                null,
                                true
                              )
                              .then((res) => {
                                const results = res.results ?? [];
                                console.log(results, "resultssss");
                                if (
                                  (this.state.singlelistView &&
                                    this.state.singlelistView.id) ||
                                  (this.props.params.id &&
                                    results &&
                                    results.length > 0 &&
                                    results
                                      // .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      }) &&
                                    results
                                      // .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      })[this.props.params.id])
                                ) {
                                  this.listClickHandler(
                                    results
                                      // .sort((a, b) => b.id - a.id)
                                      .filter((sD) => {
                                        if (sD.status) {
                                          return (
                                            sD.status ===
                                            this.state.selctedStatus
                                          );
                                        } else {
                                          return null;
                                        }
                                      })[this.props.params.id]
                                  );
                                }
                              });
                          }}
                          noAddButton={
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CLOSED") ||
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CANCELLED" &&
                              true)
                          }
                          actions={
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CLOSED") ||
                            (singlelistView &&
                              singlelistView.status &&
                              singlelistView.status === "CANCELLED" &&
                              [])
                          }
                        />
                        {/* {detailDialog(true)} */}
                      </>
                    ),
                },
                {
                  name: "Reached",
                  component: (
                    <>
                      {confirmApp === true ? (
                        <Dialog
                          open={true}
                          onClose={() =>
                            this.setState({
                              confirmApp: false,
                              approve: false,
                              reject: false,
                            })
                          }
                          aria-labelledby="confirm-dialog"
                        >
                          <DialogTitle id="confirm-dialog">
                            Make Changes ?
                          </DialogTitle>
                          <DialogContent>
                            Are you sure you want to{" "}
                            {this.state?.selectAllApproveReject !== ""
                              ? this.state?.selectAllApproveReject === "approve"
                                ? "approve all"
                                : "reject all"
                              : this.state.approve &&
                                this.state.approve !== false
                              ? "approve"
                              : "reject"}{" "}
                            this Arrival ?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (this.state?.selectAllApproveReject !== "") {
                                  this.setState({
                                    confirmApp: false,
                                    selectAllApproveReject: "",
                                  });
                                } else {
                                  this.setState({
                                    confirmApp: false,
                                    approve: false,
                                    reject: false,
                                  });
                                }
                              }}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                                padding: "2.5px",
                              }}
                            >
                              No
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (this.state?.selectAllApproveReject !== "") {
                                  this.handleAllConfirmApp();
                                } else {
                                  this.handleConfirmApp();
                                }
                              }}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                                padding: "2.5px",
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ""
                      )}
                      {console.log(this.state, this.props)}
                      <TableComponent
                        module={"Reached"}
                        noAddButton={true}
                        subMenu={true}
                        subMenuheader={[
                          "Sr.No.",
                          "Brand",
                          "Item Category",
                          "Packaging",
                          "Godown",
                          "Custom Invoice",
                          "Commercial Invoice",
                          "Unloading Agent",
                          "MR/LEO No.",
                        ]}
                        {...this.state}
                        loader={loadingRight}
                        actions={[]}
                        footer={true}
                        footerList={
                          ReachedList && {
                            Approval: `Total: ${
                              ReachedList?.total_no_of_reached_items
                            } ${
                              ReachedList?.total_no_of_reached_items > 1
                                ? "Items"
                                : "Item"
                            }`,
                            "Net Weight": ReachedList?.total_net_weight,
                            "Total Bags": ReachedList?.total_num_bags,
                            "Returned Bags":
                              ReachedList?.total_num_returned_bags,
                          }
                        }
                        header={[
                          "Sr.No.",
                          "Arrival Date",
                          "Dispatch Date",
                          "Contract Rate",
                          "Approval",
                          "Miller",
                          "Truck Number",
                          "Miller Bill Amount",
                          "Dispatch Miller Total Amount",
                          // "Miller Bill Number",
                          // "Gross Weight",
                          // "Tare Weight",
                          "Net Weight",
                          "Total Bags",
                          // "Returned Bags",
                          // "Surveyor",
                        ]}
                        secondLineHeaderData={{
                          "Arrival Date": {
                            secondLiner: "Status",
                            secondLinerHover: [],
                          },
                          Miller: {
                            secondLiner: "Miller Bill Number",
                            secondLinerHover: [],
                          },
                          "Miller Bill Amount": {
                            secondLiner: "",
                            secondLinerHover: [
                              "Dispatch Other Amount",
                              "Dispatch Other Deduction",
                              "Remark",
                            ],
                          },
                          "Net Weight": {
                            secondLiner: "",
                            secondLinerHover: ["Gross Weight", "Tare Weight"],
                          },
                          "Total Bags": {
                            secondLiner: "",
                            secondLinerHover: ["Returned Bags"],
                          },
                          "Contract Rate": {
                            secondLiner: "Contract Date",
                            secondLinerHover: [],
                          },
                        }}
                        toggleComponent={{
                          display: true,
                          label: "Display Pending Only",
                          onChange: this.handleToggle,
                        }}
                        checkbox={
                          this.props?.user?.userData?.roles
                            ?.toString()
                            .toLowerCase() === "admin"
                            ? true
                            : false
                        }
                        selectAllCheckBoxCond={{
                          field: "approval_status",
                          condition: "PENDING",
                        }}
                        allCheckBoxSelected={this.allCheckBoxSelected}
                        selectCheckox={selectedCheckboxListArray}
                        tablePagination={true}
                        paginationData={paginationData}
                        pageChangeAPI={this.pageChangeAPI}
                        resetPage={this.state.resetPage}
                        csvDownloadButton={{
                          visible: true,
                          apiCall: {
                            method: "GET",
                            url: "reports/arrival-report/list",
                            params: this.state?.displayPending
                              ? {
                                  po_id: this.state.singlelistView?.id,
                                  arrival_status: "reached",
                                  pending: "True",
                                }
                              : {
                                  po_id: this.state.singlelistView?.id,
                                  arrival_status: "reached",
                                },
                          },
                          header: [
                            { label: "Arrival Date", key: "arrival_datetime" },
                            { label: "Dispatch Date", key: "dispatch_date" },
                            { label: "Approval", key: "approval_status" },
                            {
                              label: "Contract Rate",
                              key: "contract_rate",
                              key2: "po_unit",
                              key2Prefix: "/",
                            },
                            { label: "Contract Date", key: "contract_date" },
                            { label: "Miller", key: "miller" },
                            {
                              label: "Miller Bill Number",
                              key: "miller_bill_number",
                            },
                            {
                              label: "Miller Bill Amount",
                              key: "miller_bill_amount",
                            },
                            { label: "Truck Number", key: "truck_number" },
                            {
                              label: "Dispatch Other Amount",
                              key: "dispatch_other_amount",
                            },
                            {
                              label: "Dispatch Other Deduction",
                              key: "dispatch_other_deduction",
                            },
                            {
                              label: "Dispatch Miller Total Amount",
                              key: "dispatch_miller_total_amount",
                            },
                            {
                              label: "Dispatch Miller Total Amount",
                              key: "dispatch_miller_total_amount",
                            },
                            { label: "Gross Weight", key: "gross_weight" },
                            { label: "Tare Weight", key: "tare_weight" },
                            { label: "Net Weight", key: "net_weight" },
                            { label: "Total Bags", key: "total_bags" },
                            { label: "Returned Bags", key: "returned_bags" },
                            { label: "Remark", key: "remark" },
                          ],
                          itemHeader: [
                            {
                              label: "Item - Status",
                              key: "arrival_item_details.arrival_item_status",
                            },
                            {
                              label: "Item - Brand",
                              key: "arrival_item_details.brand",
                            },
                            {
                              label: "Item - Item Category",
                              key: "arrival_item_details.item_category",
                            },
                            {
                              label: "Item - Godown",
                              key: "arrival_item_details.godown",
                            },
                            {
                              label: "Item - Sales Order",
                              key: "arrival_item_details.sales_order_number",
                            },
                            {
                              label: "Item - Custom Invoice",
                              key: "arrival_item_details.custom_invoice_number",
                            },
                            {
                              label: "Item - Commercial Invoice",
                              key: "arrival_item_details.commercial_invoice_number",
                            },
                            {
                              label: "Item - Unloading Agent",
                              key: "arrival_item_details.unloading_agent",
                            },
                            {
                              label: "Item - MR/Leo No.",
                              key: "arrival_item_details.mr_or_leo_no",
                            },
                            {
                              label: "Item - Packaging - Size Unit",
                              key: "arrival_item_details.arrival_packagings.packaging.size",
                              key2Prefix: "",
                              key2: "arrival_item_details.arrival_packagings.packaging.unit",
                            },
                            {
                              label: "Item - Packaging - Bag Type",
                              key: "arrival_item_details.arrival_packagings.packaging.bag_type",
                            },
                            {
                              label: "Item - Packaging - Remaining Bags",
                              key: "arrival_item_details.arrival_packagings.total_bags_dispatched",
                            },
                            {
                              label: "Item - Packaging - Returned Bags",
                              key: "arrival_item_details.arrival_packagings.returned_bags",
                            },
                          ],
                          mappingArr: [
                            "arrival_item_details",
                            "arrival_packagings",
                          ],
                          fileName: "PortReached",
                        }}
                        tableList={
                          ReachedList && ReachedList?.results?.length > 0
                            ? ReachedList?.results
                                .map((singleDispatch) => {
                                  console.log(
                                    singleDispatch,
                                    ReachedList,
                                    "singleDispatch"
                                  );

                                  singleDispatch["Miller"] =
                                    singleDispatch.arrival_table_details.miller;
                                  singleDispatch["Miller Bill Number"] =
                                    singleDispatch.arrival_table_details
                                      .miller_bill_number ?? "wed";
                                  singleDispatch["Truck Number"] =
                                    singleDispatch.arrival_table_details.truck_number;
                                  singleDispatch["Dispatch Date"] = moment(
                                    singleDispatch.arrival_table_details
                                      .dispatch_date
                                  ).format("DD MMM YYYY");
                                  singleDispatch["Status"] = () => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            width: 18,
                                            height: 18,
                                            borderRadius: "50%",
                                            padding: "2%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: primary,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {singleDispatch["subList"].find(
                                            (d) =>
                                              d.arrival_item_status?.toUpperCase() ===
                                              "GODOWN"
                                          ) &&
                                          singleDispatch["subList"].find(
                                            (d) =>
                                              d.arrival_item_status?.toUpperCase() ===
                                              "SHIPPED"
                                          ) ? (
                                            <span className="d-flex alignC">
                                              <Warehouse
                                                style={{
                                                  transform: "scale(0.8)",
                                                  color: "#4b2e2e",
                                                }}
                                              />{" "}
                                              /{" "}
                                              <SailingIcon
                                                style={{
                                                  transform: "scale(0.8)",
                                                  color: primary,
                                                }}
                                              />
                                            </span>
                                          ) : singleDispatch["subList"].find(
                                              (d) =>
                                                d.arrival_item_status?.toUpperCase() ===
                                                "GODOWN"
                                            ) ? (
                                            <SimplePopover
                                              popOverIcon={
                                                <Warehouse
                                                  style={{
                                                    transform: "scale(0.8)",
                                                    color: "#4b2e2e",
                                                  }}
                                                />
                                              }
                                              content={{
                                                type: "custom",
                                                rows: singleDispatch.arrival_item_details.map(
                                                  (d) => {
                                                    return {
                                                      godown:
                                                        d.godowns_list?.find(
                                                          (g) =>
                                                            g.id === d.godown
                                                        )?.name,
                                                    };
                                                  }
                                                ),
                                                keys: "godown",
                                              }}
                                            />
                                          ) : (
                                            (
                                              <SimplePopover
                                                popOverIcon={
                                                  <SailingIcon
                                                    style={{
                                                      transform: "scale(0.8)",
                                                      color: primary,
                                                    }}
                                                  />
                                                }
                                                children="shipping"
                                              />
                                            ) || ""
                                          )}
                                        </div>
                                      </>
                                    );
                                  };

                                  singleDispatch["Approval"] = () => {
                                    return singleDispatch.approval_status ===
                                      "PENDING" &&
                                      this.props?.user?.userData?.roles
                                        ?.toString()
                                        .toLowerCase() === "admin" ? (
                                      // return 1 > 0 ? (
                                      <>
                                        <Tooltip
                                          title="Approve this Arrival"
                                          arrow
                                          placement="top"
                                        >
                                          <Button
                                            onClick={() => {
                                              this.setState({
                                                confirmApp: true,
                                                approve: singleDispatch,
                                              });
                                            }}
                                            style={{
                                              fontSize: "0.8rem",
                                              boxShadow:
                                                "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                                              marginRight: "10px",
                                              color: "green",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Approve
                                            {/* <CheckCircleOutlineIcon color="success" /> */}
                                          </Button>
                                        </Tooltip>

                                        <Tooltip
                                          title="Reject this Arrival"
                                          arrow
                                          placement="top"
                                        >
                                          <Button
                                            onClick={() => {
                                              this.setState({
                                                confirmApp: true,
                                                reject: singleDispatch,
                                              });
                                            }}
                                            style={{
                                              fontSize: "0.8rem",
                                              boxShadow:
                                                "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                                              marginRight: "10px",
                                              color: "red",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Reject
                                            {/* <CancelOutlinedIcon color="error" /> */}
                                          </Button>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      singleDispatch.approval_status
                                    );
                                  };

                                  singleDispatch["Arrival Date"] = moment(
                                    singleDispatch.arrival_datetime
                                  ).format("DD MMM YYYY");

                                  singleDispatch["Miller Bill Amount"] =
                                    singleDispatch.arrival_table_details
                                      ?.miller_bill_amount || "";

                                  singleDispatch["Dispatch Other Amount"] =
                                    singleDispatch.arrival_table_details
                                      ?.dispatch_other_amount || "";

                                  singleDispatch["Dispatch Other Deduction"] =
                                    singleDispatch.arrival_table_details
                                      ?.dispatch_other_deduction || "";

                                  singleDispatch["Remark"] =
                                    singleDispatch?.remarks || "";

                                  singleDispatch[
                                    "Dispatch Miller Total Amount"
                                  ] =
                                    singleDispatch.arrival_table_details
                                      ?.dispatch_miller_total_amount || "";

                                  singleDispatch["Gross Weight"] =
                                    singleDispatch.gross_weight;

                                  singleDispatch["Tare Weight"] =
                                    singleDispatch.tare_weight;
                                  singleDispatch["Net Weight"] =
                                    singleDispatch.net_weight;
                                  singleDispatch["Total Bags"] =
                                    singleDispatch.arrival_table_details.total_bags;
                                  singleDispatch["Returned Bags"] =
                                    singleDispatch.returned_bags
                                      ? singleDispatch.returned_bags
                                      : 0;

                                  singleDispatch["Contract Rate"] =
                                    singleDispatch?.arrival_table_details?.po_contract_rate;
                                  singleDispatch["Contract RateUI"] =
                                    `${singleDispatch?.arrival_table_details?.po_contract_rate}` +
                                    `${
                                      singleDispatch?.arrival_table_details
                                        ?.po_unit
                                        ? "/" +
                                          singleDispatch?.arrival_table_details
                                            ?.po_unit
                                        : ""
                                    }`;

                                  singleDispatch["Contract Date"] = moment(
                                    singleDispatch?.arrival_table_details
                                      ?.po_contract_date
                                  ).format("DD MMM YYYY");

                                  singleDispatch["subList"] =
                                    singleDispatch.subList
                                      ? singleDispatch.subList.map(
                                          (singleSub) => {
                                            singleSub["Godown"] =
                                              singleSub.godown
                                                ? filterData(
                                                    singleSub.godowns_list,
                                                    "id",
                                                    singleSub.godown,
                                                    "onlyOne"
                                                  )
                                                  ? filterData(
                                                      singleSub.godowns_list,
                                                      "id",
                                                      singleSub.godown,
                                                      "onlyOne"
                                                    ).name
                                                  : ""
                                                : "";
                                            singleSub["Custom Invoice"] =
                                              singleSub.custom_invoice_number ||
                                              "";

                                            // console.log("singleSub Commercial Invoice", singleSub, singleSub.commercial_invoice_number, !Boolean(singleSub.commercial_invoice_number))
                                            singleSub["Commercial Invoice"] =
                                              () => {
                                                return (
                                                  <div className="d-flex alignC justifyC">
                                                    <span
                                                      style={{
                                                        color:
                                                          !singleSub?.is_synced
                                                            ? "rgba(0,0,0,0.4)"
                                                            : "inherit",
                                                      }}
                                                    >
                                                      {
                                                        singleSub.commercial_invoice_number
                                                      }
                                                    </span>
                                                    <span
                                                      style={{
                                                        display:
                                                          singleSub?.is_synced
                                                            ? "none"
                                                            : "block",
                                                        margin: "0 auto",
                                                        padding: "4px 10px 0",
                                                        color: Boolean(
                                                          this.state.isSpinning[
                                                            singleSub.id
                                                          ]
                                                        )
                                                          ? lightGreen
                                                          : primary,
                                                        animation:
                                                          Boolean(
                                                            this.state
                                                              .isSpinning[
                                                              singleSub.id
                                                            ]
                                                          ) &&
                                                          "spin 1s infinite",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.handleIconClick(
                                                          singleSub.id,
                                                          true
                                                        );
                                                        console.log(
                                                          "SPIN",
                                                          singleSub,
                                                          this.state.isSpinning,
                                                          Boolean(
                                                            this.state
                                                              .isSpinning[
                                                              singleSub.id
                                                            ]
                                                          )
                                                        );
                                                        let newArr = [];
                                                        singleSub?.newPack.map(
                                                          (sP, i) => {
                                                            let usedWeight = {};

                                                            usedWeight[
                                                              sP.packaging_details.id
                                                            ] = this.changeUnit(
                                                              sP
                                                                .packaging_details
                                                                .unit,
                                                              singleSub.so_unit,
                                                              multiply(
                                                                sP
                                                                  .packaging_details
                                                                  .used_bags
                                                                  ? sP
                                                                      .packaging_details
                                                                      .used_bags
                                                                  : 0,
                                                                sP
                                                                  .packaging_details
                                                                  .size
                                                                  ? Number(
                                                                      sP
                                                                        .packaging_details
                                                                        .size
                                                                    )
                                                                  : 0
                                                              )
                                                            );

                                                            newArr.push({
                                                              id:
                                                                singleSub.packing_list_ids !==
                                                                ""
                                                                  ? Number(
                                                                      singleSub.packing_list_ids.split(
                                                                        ","
                                                                      )[i]
                                                                    )
                                                                  : null,
                                                              is_active: true,
                                                              shipping_date:
                                                                moment(
                                                                  singleSub.shipping_date ||
                                                                    new Date()
                                                                ).format(
                                                                  "DD MMM YYYY"
                                                                ),
                                                              unloading_agent:
                                                                singleSub.unloading_agent,
                                                              mr_or_leo_no:
                                                                singleSub.mr_or_leo_no,
                                                              packaging_count: {
                                                                [sP
                                                                  .packaging_details
                                                                  .id]:
                                                                  sP
                                                                    .packaging_details
                                                                    .used_bags,
                                                              },
                                                              used_weight:
                                                                usedWeight,
                                                              custom_invoice:
                                                                Number(
                                                                  singleSub.custom_invoice
                                                                ),
                                                              commercial_invoice:
                                                                Number(
                                                                  singleSub.commercial_invoice
                                                                ),
                                                              brand:
                                                                singleSub.brand,
                                                              godown: singleSub
                                                                ? singleSub.godown
                                                                : "",
                                                              port: singleDispatch.portId,
                                                              sales_order:
                                                                singleSub.sales_order,
                                                              bl_number:
                                                                singleSub?.custom_bl_number ||
                                                                "",
                                                              arrival_item:
                                                                singleSub.id,
                                                              coming_from:
                                                                "DIRECT",
                                                            });
                                                          }
                                                        );
                                                        console.log(
                                                          "newArr",
                                                          newArr
                                                        );
                                                        this.props
                                                          .PurchaseOrdersFunction(
                                                            "put",
                                                            "arrival/commercial-invoice/packing-list/bulk-update",
                                                            null,
                                                            "put",
                                                            newArr
                                                          )
                                                          .then((res2) => {
                                                            console.log(
                                                              "RES2",
                                                              res2
                                                            );
                                                            if (res2.error) {
                                                              this.showSnackbar(
                                                                res2.data,
                                                                "error"
                                                              );
                                                              this.handleIconClick(
                                                                singleSub.id,
                                                                false
                                                              );
                                                            } else {
                                                              this.showSnackbar(
                                                                "Sync successfully",
                                                                "success"
                                                              );
                                                              // this.setState(
                                                              //   {
                                                              //     success:
                                                              //       "Sync successfully",
                                                              //   }
                                                              // );
                                                              setTimeout(() => {
                                                                this.handleIconClick(
                                                                  singleSub.id,
                                                                  false
                                                                );
                                                                this.fetchReachedData();
                                                              }, 2000);
                                                            }
                                                          })
                                                          .catch((e) => {
                                                            console.log(
                                                              "error500",
                                                              e
                                                            );
                                                            this.setState({
                                                              error:
                                                                "Shipping details are mismatched",
                                                            });
                                                          });
                                                      }}
                                                    >
                                                      {singleSub.commercial_invoice_number &&
                                                      !singleSub?.is_synced ? (
                                                        <Tooltip
                                                          title="Sync"
                                                          placement="right-start"
                                                        >
                                                          <AutorenewOutlinedIcon
                                                            style={{
                                                              transform:
                                                                "scale(0.9)",
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  </div>
                                                );
                                              };

                                            singleSub["Unloading Agent"] =
                                              singleSub?.unloading_agent?.name
                                                ? singleSub?.unloading_agent
                                                    ?.name
                                                : "";
                                            singleSub["MR/LEO No."] =
                                              singleSub?.mr_or_leo_no
                                                ? singleSub?.mr_or_leo_no
                                                : "";

                                            singleSub["Brand"] = filterData(
                                              BrandList,
                                              "id",
                                              singleSub.brand,
                                              "onlyOne"
                                            )
                                              ? filterData(
                                                  BrandList,
                                                  "id",
                                                  singleSub.brand,
                                                  "onlyOne"
                                                ).brand_name
                                              : "";

                                            singleSub["Item Category"] =
                                              filterData(
                                                ItemCateoryList,
                                                "id",
                                                singleSub.item_category,
                                                "onlyOne"
                                              )
                                                ? filterData(
                                                    ItemCateoryList,
                                                    "id",
                                                    singleSub.item_category,
                                                    "onlyOne"
                                                  ).name
                                                : "";
                                            singleSub["Item Type"] = filterData(
                                              ItemList,
                                              "id",
                                              singleSub.item_type,
                                              "onlyOne"
                                            )
                                              ? `${Number(
                                                  filterData(
                                                    ItemList,
                                                    "id",
                                                    singleSub.item_type,
                                                    "onlyOne"
                                                  ).broken_percentage
                                                ).toFixed(2)} %`
                                              : "";
                                            singleSub["Packaging"] =
                                              singleSub.newPack &&
                                              singleSub.newPack.length > 0
                                                ? `  ${
                                                    singleSub.newPack.map(
                                                      (sP) => {
                                                        const singlePack =
                                                          sP.packaging_details;

                                                        return singlePack
                                                          ? `${
                                                              singlePack.size
                                                            }${
                                                              singlePack.unit
                                                            }${" - "}${
                                                              singlePack.bag_type
                                                            }`
                                                          : "";
                                                      }
                                                    )[0]
                                                  } Total (...s)`
                                                : "-";
                                            singleSub["Arrival Status"] =
                                              singleSub.arrival_item_status;
                                            console.log(
                                              singleSub,
                                              "singleSub",
                                              singleDispatch
                                            );
                                            singleSub["PackagingUI"] = (
                                              <ShowPackagingPort
                                                // noTotalbags={true}
                                                // details={singleSub.dispatch_packaging.map(
                                                //   (sP) => {
                                                //     const singlePack =
                                                //       sP.packaging_details;
                                                //     singlePack.used_bags =
                                                //       sP.total_bags_dispatched;

                                                //     return singlePack;
                                                //   }
                                                // )}
                                                noTotalbags={true}
                                                returned_bags={true}
                                                details={singleSub.dispatch_packaging.map(
                                                  (sP) => {
                                                    const singlePack =
                                                      sP.packaging_details;
                                                    singlePack.used_bags =
                                                      sP.total_bags_dispatched;

                                                    singlePack.returned_bags =
                                                      sP.returned_bags;
                                                    return singlePack;
                                                  }
                                                )}
                                              />
                                            );
                                            singleSub["PackagingUIPdf"] =
                                              singleSub.dispatch_packaging &&
                                              singleSub.dispatch_packaging
                                                .length > 0
                                                ? singleSub.dispatch_packaging
                                                    .map((sP) => {
                                                      const singlePack =
                                                        sP.packaging_details;

                                                      return singlePack
                                                        ? `${singlePack.size}${
                                                            singlePack.unit
                                                          }${" - "}${
                                                            singlePack.bag_type
                                                          }`
                                                        : "";
                                                    })
                                                    .join(", ")
                                                : "-";
                                            return singleSub;
                                          }
                                        )
                                      : [];
                                  return singleDispatch;
                                })
                                .sort((a, b) => {
                                  var dateB = moment(b.modified_at).format(
                                    "MMM D YYYY h:mm:ss A"
                                  );
                                  var dateA = moment(a.modified_at).format(
                                    "MMM D YYYY h:mm:ss A"
                                  );
                                  return new Date(dateB) - new Date(dateA);
                                })
                            : // .filter((s) => s.arrival_status === "reached")
                              // .map((s) => {
                              //   return s;
                              // })
                              []
                        }
                      />
                    </>
                  ),
                },
              ]}
              selectedValue={selectedValue ? selectedValue : 0}
            />
          )}
        </Paper>
        <div style={styles.bottomContainer(selectedCheckboxListArray)}>
          <div style={styles.bottomInnerContainer(selectedCheckboxListArray)}>
            <div style={styles.bottomInnerContentContainer}>
              <IconButton
                aria-label="delete"
                style={styles.actionButtonIcons}
                onClick={() => {
                  this.setState({ selectedCheckboxListArray: [] });
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>

              <span
                style={{
                  margin: "0 20px 0 10px",
                  // color: '#fff'
                  color: "#2f2f2f",
                }}
              >
                {selectedCheckboxListArray.length} selected
              </span>
              <span style={{ margin: "0 10px 0 20px" }}>
                <Button
                  onClick={() => {
                    this.setState({
                      confirmApp: true,
                      selectAllApproveReject: "approve",
                    });
                  }}
                  // size="small"
                  style={{
                    fontSize: "0.8rem",
                    boxShadow: "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                    marginRight: "10px",
                    color: "#fff",
                    fontWeight: 500,
                    background: "green",
                  }}
                >
                  Approve ALL
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      confirmApp: true,
                      selectAllApproveReject: "reject",
                    });
                  }}
                  style={{
                    fontSize: "0.8rem",
                    boxShadow: "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                    marginRight: "10px",
                    color: "#fff",
                    fontWeight: 500,
                    background: "#D32F2F",
                  }}
                >
                  Reject ALL
                </Button>
              </span>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PurchaseOrdersComponent;
