

import { Checkbox, FormControlLabel, Tooltip, withStyles } from "@material-ui/core";
import { Add } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import CreateDialog from "../../layoutComponents/CreateDialog";
import TableComponent from "../../layoutComponents/TableComponent";
import {
  calculateGstAmount,
  calculatePercentage,
  filterData,
  mediumScreen,
  StopScroll,
} from "../../Regex";
import { lightGrey, primary, white } from "../../styles/colors";
import { EditIcon } from "../../styles/Icons";
import {
  CssTextField95,
  HeadingLine,
  ShowDetails,
  StyledButton,
  StyledCircularProgress,
  StyledSearchBar,
} from "../../styles/StyledComponents";
import MultipleSelectBar from "../../layoutComponents/MultipleSelectBar";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import ShowSpecifications from "../../layoutComponents/ShowSpecifications";
import ItemDetailCommercial from "./ItemDetailCommercial";
import moment from "moment";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from '@mui/icons-material/Event';
import { sum } from "lodash";

export const CssKeyboardDatePickerSmall = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "9.5px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

function CreateCommercial(props) {
  const {
    onClose,
    editList,
    fetchAgain,
    postData,
    masters,
    singlelistView,
    customInv,
    fetchCustomInvoices,
  } = props;

  const [alldisabled, setDisabled] = useState(
    editList && editList.status ? editList.status === "CLOSED" : false
  );
  const [customInvArr, setCustomInvArr] = useState([]);
  const [customInvArrCopy, setCustomInvArrCopy] = useState([]);
  useEffect(() => {
    setCustomInvArr(customInv);
    setCustomInvArrCopy(customInv);
  }, [customInv]);
  const [loader, setLoad] = useState(false);
  const [so, setSO] = useState(false);

  const [submitted, setSubmitted] = useState();
  const [rateOfGst, setRateOfGst] = useState(0);

  const [doApiCall, setDoApiCall] = useState(false);

  const [soItems, setSoItems] = useState([]);
  const [type_ci, setType_ci] = useState("WIGST");
  const [errorMsg, setErrorMsg] = useState({});
  const colorArr = [white, lightGrey];
  const [selectedBrandsArr, setSelectedBrandsArr] = useState([]);
  const [bldata, setSubmittedData] = useState({});
  const [blDateData, setSubmittedBlDateData] = useState({});
  const [vesselFlightData, setSubmittedVesselFlightData] = useState({});
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [amountData, setAmountData] = useState({});
  const [amountCurrency, setAmountCurrency] = useState('');
  const [checked, setChecked] = React.useState({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [draftToggle,setDraftToggle]=useState(true);
  const handleChange1 = (customs, selectedSO) => {
    const data = {};

    const blObjs = {};
    const blDateObjs = {};
    const vesselFlightObjs = {};
    
    customs.map((ci, i) => {
      data[`${ci.invoice_number}`] = true;
      if (ci.bl_number) {
        blObjs[`Bl-${selectedSO.so_number}-${ci.invoice_number}`] =
          ci.bl_number;
      }
      console.log("BL",customs,editList, ci.bl_date)
      if (ci.bl_date) {
        blDateObjs[`Bl-${selectedSO.so_number}-${ci.invoice_number}`] =
          ci.bl_date;
      }
      if (ci.vessel_number) {
        vesselFlightObjs[`vessel-${selectedSO.so_number}-${ci.invoice_number}`] =
          ci.vessel_number;
      }
      if (ci.flight_number) {
        vesselFlightObjs[`flight-${selectedSO.so_number}-${ci.invoice_number}`] =
          ci.flight_number;
      }
      return ci;
    });
    setSubmittedData(blObjs);
    setSubmittedBlDateData(blDateObjs)
    setSubmittedVesselFlightData(vesselFlightObjs)

    setChecked((prev) => {
      return { [selectedSO.so_number]: { ...data } };
    });
  };

  useEffect(() => {
    console.log("SO-DATA", so, props.masters.CurrencyList);
    if(so?.id){
      let currency = filterData(props.masters.CurrencyList,"id", Number(so?.currency), "onlyOne" )
      setAmountCurrency(currency?.name)
    }
  }, [so])

  useEffect(() => {
    if(!amountData.total_fob_value){
      let Obj = {
        [`Amount In ${amountCurrency}`]: editList.amount_in_other,
        [`Advance Amount In ${amountCurrency}`]:  editList.advance_amount_in_other,
        [`Pending Amount In ${amountCurrency}`]:  editList.pending_amount_in_other,
        'Total FOB Value':  editList.total_fob_value,
        'Duty':  editList.duty,
        'Freight':  editList.freight,
        'Insurance':  editList.insurance,
      }
      console.log(Obj)
      setAmountData(Obj)
    }
  }, [editList, amountCurrency])

  useEffect(() => {
    if (editList) {
      const selectedSO =
        editList.custom_invoice_details &&
        editList.custom_invoice_details.length > 0
          ? editList.custom_invoice_details[0].sales_order_details
          : false;
      setSO(selectedSO);
      fetchSoItems(selectedSO?.id, "setData");
      if (fetchCustomInvoices) {
        fetchCustomInvoices(selectedSO.id);
      }
      setDisabled(
        editList && editList.status ? editList.status === "CLOSED" : false
      );

      if (
        editList.custom_invoice_details &&
        editList.custom_invoice_details.length > 0
      ) {
        handleChange1(editList.custom_invoice_details, selectedSO);
      }
      if (doApiCall) {
        setDoApiCall(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editList, doApiCall]);

  const handleAmountValueChange = (allData, field) => {
    console.log(field,allData, allData[field])
    let amount = {...amountData}
    amount[field] = allData[field]
    if(field === `Amount In ${amountCurrency}` || field === `Advance Amount In ${amountCurrency}`){
      console.log(amountCurrency, Number(Number(amount[`Amount In ${amountCurrency}`] || 0) - Number(amount[`Advance Amount In ${amountCurrency}`] || 0) ).toFixed(2))
      amount[`Pending Amount In ${amountCurrency}`] = Number(Number(amount[`Amount In ${amountCurrency}`] || 0) - Number(amount[`Advance Amount In ${amountCurrency}`] || 0) ).toFixed(2)
      allData[`Pending Amount In ${amountCurrency}`] = amount[`Pending Amount In ${amountCurrency}`]
    }

    if(['Total FOB Value', 'Duty', 'Freight', 'Insurance'].includes(field)){
      amount[`Amount In ${amountCurrency}`] = Number(sum([
        Number(amount['Total FOB Value'] || 0),
        Number(amount['Duty'] || 0),
        Number(amount['Freight'] || 0),
        Number(amount['Insurance'] || 0),
      ])).toFixed(2)

      amount[`Pending Amount In ${amountCurrency}`] = Number(Number(amount[`Amount In ${amountCurrency}`] || 0) - Number(amount[`Advance Amount In ${amountCurrency}`] || 0) ).toFixed(2)
      allData[`Amount In ${amountCurrency}`] = amount[`Amount In ${amountCurrency}`]
      allData[`Pending Amount In ${amountCurrency}`] = amount[`Pending Amount In ${amountCurrency}`]
      console.log(amount)
    }

    setAmountData(amount)
    return allData;
  }

  const fetchSoItems = (soId, setData) => {
    if (soId && postData) {
      setLoad(true);
      postData("get", "dispatch/so-items/list", null, "PortCIs", null, {
        so_id: soId,
      }).then((soItems) => {
        if (
          setData &&
          editList.commercial_invoice_item_details &&
          editList.commercial_invoice_item_details.length > 0
        ) {
          setType_ci(
            editList && editList.invoice_type ? editList.invoice_type : "WIGST"
          );

          setRateOfGst(
            editList && editList.igst_value ? editList.igst_value : 0
          );
          const allDetails = {};
          editList.commercial_invoice_item_details.map((s) => {
            const result = editList.custom_invoice_details.filter((o) =>
              o.custom_invoice_item_details.some(
                (tag) => Number(s.custom_invoice_item) === Number(tag.id)
              )
            );
            const customItem =
              result &&
              result[0] &&
              result[0].custom_invoice_item_details &&
              result[0].custom_invoice_item_details.filter(
                (item) => item.id === Number(s.custom_invoice_item)
              )
                ? result[0].custom_invoice_item_details.filter(
                    (item) => item.id === Number(s.custom_invoice_item)
                  )
                : false;
            if (
              result &&
              result.length > 0 &&
              result[0] &&
              customItem &&
              customItem[0]
            ) {
              const singleSo = filterData(
                soItems,
                "id",
                Number(customItem[0].so_item),
                "onlyOne"
              )
                ? filterData(
                    soItems,
                    "id",
                    Number(customItem[0].so_item),
                    "onlyOne"
                  )
                : "";
              const obj = {};
              obj["container_number"] = s.container_numbers;
              obj["amount_in_other"] = s.amount_in_other;
              obj["Gross Weight"] = s.gross_weight;
              obj["Net Weight"] = s.net_weight;
              singleSo.packaging &&
                singleSo.packaging.length > 0 &&
                singleSo.packaging.map((sP) => {
                  obj[`${sP}-bags`] = s.actual_pkg_counter && s.actual_pkg_counter[sP]
                      ? Boolean(s.actual_pkg_counter[sP]) ? Number(s.actual_pkg_counter[sP]) : 0
                      : 0;
                      console.log(s,sP,"s.extra_empty_bags")
                      obj[`${sP}-empty-bags`] =
                   s.extra_empty_bags && s.extra_empty_bags[sP]
                      ? Boolean(s.extra_empty_bags[sP]) ? Number(s.extra_empty_bags[sP]) : 0
                      : 0;
                      // 
                  return sP;
                });

              allDetails[
                `${result[0].invoice_number}${s.custom_invoice_item}`
              ] = obj;
            }

            return s;
          });
          setSubmitted(allDetails);
          console.log("allDetails", allDetails)
        }
        console.log("SOITEMS", soItems, submitted)
        setSoItems(soItems);
        setLoad(false);
      });
    }
  };

  const ciItemTable = (
    selectedCustomInvoice,
    submittedDataTable,
    salesOrder
  ) => {
    console.log("EDITTABLE",editList, selectedCustomInvoice, so)
    const unit = salesOrder && salesOrder.unit;
    return so ? (
      <div
        style={{ width: "100%", height: "fit-content", margin: "0px 0 10px 0" }}
      >
        <HeadingLine
          maxWidth="100%"
          width="fit-content"
          children={`Item Details`}
          center={true}
          margin={"0 0 10px 0px"}
        />
        <TableComponent
          padding={"0px"}
          nobottomBarAction={true}
          loading={loader}
          heightNew="800px"
          maxHeight="calc(100vh - 40px)"
          widthNew="100%"
          module={"Bag details"}
          actions={[]}
          noAddButton={true}
          header={
            editList
              ? [
                  "Details",
                  "Brand",
                  "Packaging",
                  "Total Item Quantity",
                  "Contract Rate",
                  "HSN Code",
                  "Buyer Marking",
                  "Specifications",
                ]
              : [
                  "Brand",
                  "Packaging",
                  "Total Item Quantity",
                  "Contract Rate",
                  "HSN Code",
                  "Buyer Marking",
                  "Specifications",
                ]
          }
          noSearchBar={true}
          {...props}
          tableList={
            selectedCustomInvoice &&
            selectedCustomInvoice.custom_invoice_item_details &&
            selectedCustomInvoice.custom_invoice_item_details.length
              ? selectedCustomInvoice.custom_invoice_item_details
                  .sort((a, b) => b.id - a.id)
                  .map((s,index) => {
                    const details = selectedCustomInvoice?.sales_order_details?.so_items?.find(d => d.id == s.so_item)
                    // so.so_items?.find(d => d.id == s.so_item) 
                    // ? so.so_items.find(d => d.id == s.so_item) 
                    // : filterData(
                    //   soItems,
                    //   "id",
                    //   s.so_item,
                    //   "onlyOne"
                    // )
                    //   ? filterData(soItems, "id", s.so_item, "onlyOne")
                    //   : false;
                      console.log(details,so,s,selectedCustomInvoice,"detailsdetails")
                    return details
                      ? {
                          ...s,
                          Details: "Update Details ",
                          DetailsUI: () => {
                            return (
                              <ItemDetailCommercial
                                amountDataInOther={amountData[`Amount In ${amountCurrency}`] || 0}
                                id={`${s.id}addDetail`}
                                rateOfGst={rateOfGst ? rateOfGst : 0}
                                ci_type={
                                  submittedDataTable["Type"]
                                    ? submittedDataTable["Type"]
                                    : ""
                                }
                                editData={
                                  submitted &&
                                  submitted[
                                    `${selectedCustomInvoice.invoice_number}${s.id}`
                                  ]
                                    ? submitted[
                                        `${selectedCustomInvoice.invoice_number}${s.id}`
                                      ]
                                    : false
                                }
                                errorMsg={errorMsg}
                                setData={(data) => {
                                  console.log("SETDATA", data)
                                  setSubmitted((prev) => {
                                    return {
                                      ...prev,
                                      [`${selectedCustomInvoice.invoice_number}${s.id}`]:
                                        data,
                                    };
                                  });
                                }}
                                setErrorMsg={() => {
                                  setErrorMsg({});
                                }}
                                masters={masters}
                                selected={{
                                  ...s,
                                  packaging: details.packaging.map((sP) => {
                                    const pVal = sP?.id 
                                    ? sP
                                    : filterData(masters.PackagingList,"id",Number(sP),"onlyOne")
                                      ? filterData(masters.PackagingList,"id",Number(sP),"onlyOne")
                                      : "";

                                    return {
                                      ...pVal,
                                      total_bags: s.pkg_counter[sP?.id ? sP?.id : sP]
                                        ? s.pkg_counter[sP?.id ? sP?.id : sP]
                                        : "",
                                    };
                                  }),
                                  Brand: details.brand.name || "",
                                  "Item Category": details.item_category.name || "",
                                  "Item Type": Number(details.item_type?.broken_percentage).toFixed(2) || "",
                                  "HSN Code": details.hsn_code.hsn_code || "",
                                  "Total Item Quantity": details.total_quantity || "",
                                  "Contract Rate": `${details.contract_rate}`,
                                  "Buyer Marking": details.buyers_marking ? details.buyers_marking : "-",
                                  Currency: filterData(masters["CurrencyList"], "id", Number(so?.currency),"onlyOne")
                                    ? filterData(masters["CurrencyList"],"id", Number(so?.currency),"onlyOne").name
                                    : "",
                                }}
                                selectedCi={selectedCustomInvoice}
                                selectedSo={salesOrder}
                                alldisabled={alldisabled}
                              />
                            );
                          },
                          Brand: `${details.brand.name || ""} - ${details.item_category?.name || ""} - ${Number(details.item_type?.broken_percentage).toFixed(2) +'%' || ""}`,
                          "Item Category": details.item_category?.name || "",
                          "Item Type": details.item_type?.broken_percentage || "",
                          Packaging: "deferferfeeeeeeeeeee",
                          PackagingUI: (
                            <ShowPackaging
                              totalCiBags={true}
                              noTotalbags={true}
                              returned_bags={false}
                              noBags={true}
                              details={details.packaging.map((sP) => {
                                const pVal = sP?.id 
                                ? sP
                                : filterData(
                                  masters.PackagingList,
                                  "id",
                                  Number(sP),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      masters.PackagingList,
                                      "id",
                                      Number(sP),
                                      "onlyOne"
                                    )
                                  : "";

                                return {
                                  ...pVal,
                                  total_bags: s.pkg_counter[sP]
                                    ? s.pkg_counter[sP]
                                    : "",
                                };
                              })}
                            />
                          ),
                          "HSN Code": details.hsn_code.hsn_code || "",
                          "Total Item Quantity": details.total_quantity + ` ${so.unit ? so.unit : ""}` || "",
                          "Contract Rate": `${Number(details.contract_rate).toFixed(2)}/${so.unit ? so.unit : ""}`,
                          "Buyer Marking": details.buyers_marking ? details.buyers_marking : "-",
                          Currency: filterData(
                            masters["CurrencyList"],
                            "id",
                            Number(so?.currency),
                            "onlyOne"
                          )
                            ? filterData(
                                masters["CurrencyList"],
                                "id",
                                Number(so?.currency),
                                "onlyOne"
                              ).name
                            : "",
                          Specifications: "WEFERFRT4G56YH6",
                          SpecificationsUI: (
                            <ShowSpecifications
                              details={
                                details.so_specifications &&
                                details.so_specifications.length > 0
                                  ? details.so_specifications.map((sP) => {
                                      return sP;
                                    })
                                  : []
                              }
                            />
                          ),
                        }
                      : { ...s };
                  })
              : []
          }
          filterChild={false}
        />{" "}
      </div>
    ) : (
      ""
    );
  };

  const handleChange2 = (event, ci, so) => {
    if (event.target.checked === true) {
      if (
        (soItems &&
          soItems.length > 0 &&
          soItems[0].sales_order &&
          soItems[0].sales_order !== so.id) ||
        (soItems && soItems.length === 0)
      ) {
        setSO(so);
        fetchSoItems(so.id);
      }
    }
    if (editList) {
    } else {
      setSubmittedData((prev) => {
        return { ...prev, [`Bl-${so.so_number}-${ci.invoice_number}`]: "" };
      });
      setSubmittedBlDateData((prev) => {
        return { ...prev, [`Bl-${so.so_number}-${ci.invoice_number}`]: "" };
      });
      setSubmittedVesselFlightData((prev) => {
        return { ...prev, 
          [`vessel-${so.so_number}-${ci.invoice_number}`]: "", 
          [`flight-${so.so_number}-${ci.invoice_number}`]: "", 
        };
      });
      const newObj = submitted ? submitted : {};
      if (
        ci &&
        ci.custom_invoice_item_details &&
        ci.custom_invoice_item_details.length
      ) {
        ci.custom_invoice_item_details
          .sort((a, b) => b.id - a.id)
          .map((s) => {
            if (newObj[`${ci.invoice_number}${s.id}`]) {
              delete newObj[`${ci.invoice_number}${s.id}`];
            }
            return s;
          });
      }
      setSubmitted(newObj);
    }
    setChecked((prev) => {
      return prev[so.so_number]
        ? {
            [so.so_number]: {
              ...prev[so.so_number],
              [ci.invoice_number]: event.target.checked,
            },
          }
        : { [so.so_number]: { [ci.invoice_number]: event.target.checked } };
    });
  };
  const allChecked = (list, s) => {
    const dataLength = [];
    const toCheckData = checked[s.so_number] ? checked[s.so_number] : {};
    list.map((ci, i) => {
      if (toCheckData[`${ci.invoice_number}`] && !dataLength.includes(ci.id)) {
        dataLength.push(ci.id);
      }
      return ci;
    });
    return dataLength.length === 0
      ? false
      : dataLength.length === list.length
      ? true
      : "semi";
  };
  const soItemTable = (submittedData) => {
    console.log(customInv, "customInv");
    // const customs = customInv === "loading" ? false : customInv;
    //  filterData(customInv, "sales_order", so.id)
    // ? filterData(customInv, "sales_order", so.id)
    // : [];
    return so ? (
      <div className="width-100 height-100 " 
      // style={{ height: "calc(100% - 178.75px)" }}
        >
        {error ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={5000}
            success={success}
            snackbarClose={() => {
              setError(false);
            }}
          />
        ) : (
          ""
        )}
        {customInv === "loading" ? (
          <StyledCircularProgress />
        ) : (
          <div className="d-flex justifySB width-100">
            <HeadingLine
              maxWidth="100%"
              width="fit-content"
              children={`All Custom Invoices`}
              center={true}
              margin={"10px"}
            />{" "}
            <StyledSearchBar
              padding={"9px"}
              bgC={"#F2F2F2"}
              fieldName={["invoice_number"]}
              copyData={customInvArrCopy}
              filteredData={(filterNotify) => {
                setCustomInvArr(filterNotify);
              }}
              onClear={() => {
                setCustomInvArr(customInvArrCopy);
              }}
              placeholder={`Search Custom Invoice`}
            />
          </div>
        )}
        {customInv === "loading" ? (
          ""
        ) : so &&
          customInv &&
          customInv.length > 0 &&
          customInvArr &&
          customInvArr.length > 0 ? (
          <div
            style={{
              width: "100%",
              margin: "10px 0",
              padding: "10px 20px",
              // height: "calc(100% - 42.88px)",
            }}
            className=" d-flex d-flex-column  scrollBluePrimary overflowYA"
          >
            {customInvArr &&
            typeof customInvArr === "object" &&
            customInvArr.length > 0
              ? customInvArr
                  .sort((a, b) => a.id - b.id)
                  .map((ci, i) => {
                    return (
                      <div
                        className="d-flex d-flex-column width-100 "
                        style={{ margin: "5px 0" }}
                      >
                        {/* //boxShadow borderRadius7 */}
                        <div
                          className="d-flex d-flex-column"
                          style={{
                            // marginLeft: "24px",
                            width: "100%", // "calc(100% - 24px)",
                          }}
                        >
                          <FormControlLabel
                            // style={{
                            //   color: ci.packaging_list_details && ci.packaging_list_details.length ? "" : "grey",
                            // }}
                            // style={{
                            //   color: ci.packaging_list_details && ci.packaging_list_details.length ? "" : "grey",
                            // }}
                            label={`Custom Invoice : ${ci.invoice_number}`}
                            style={{
                              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                            }}
                            control={
                              errorMsg &&
                              errorMsg["selectCi"] &&
                              errorMsg["selectCi"] === true ? (
                                <Checkbox
                                  style={{
                                    color: "red",
                                  }}
                                  checked={
                                    checked[so.so_number]
                                      ? checked[so.so_number][
                                          `${ci.invoice_number}`
                                        ] === true
                                        ? true
                                        : false
                                      : false
                                  }
                                  onChange={(e) => {
                                    // if (ci.packaging_list_details && ci.packaging_list_details.length > 0) {
                                    //   setErrorMsg({
                                    //     ...errorMsg,
                                    //     selectCi: false,
                                    //   });
                                    handleChange2(e, ci, so);
                                    // } else {
                                    //   setError("No bags are shipped using this custom invoice!");
                                    // }
                                  }}
                                />
                              ) : (
                                <Checkbox
                                  checked={
                                    checked[so.so_number]
                                      ? checked[so.so_number][
                                          `${ci.invoice_number}`
                                        ] === true
                                        ? true
                                        : false
                                      : false
                                  }
                                  onChange={(e) => {
                                    // if (ci.packaging_list_details && ci.packaging_list_details.length > 0) {
                                    //   setErrorMsg({
                                    //     ...errorMsg,
                                    //     selectCi: false,
                                    //   });
                                    handleChange2(e, ci, so);
                                    // } else {
                                    //   setError("No bags are shipped using this custom invoice!");
                                    // }
                                  }}
                                />
                              )
                            }
                          />
                          {/* <div style={{ color: "red", marginLeft: "32px", fontSize: "0.7rem", marginTop: "-2px" }}>
                            {ci.packaging_list_details && ci.packaging_list_details.length
                              ? ""
                              : "No bags are shipped!"}
                          </div> */}
                        </div>
                        {checked[so.so_number] ? (
                          checked[so.so_number][`${ci.invoice_number}`] ===
                          true ? (
                            <>
                              <div
                                className="d-flex"
                                style={{
                                  marginLeft: "24px",
                                  width: "calc(100% - 24px)",
                                }}
                              >
                                <CssTextField95
                                  required={true}
                                  error={
                                    errorMsg[
                                      `Bl-${so.so_number}-${ci.invoice_number}`
                                    ]
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                  style={{
                                    width: "140px",
                                    minWidth: "140px",
                                    margin: "10px 20px 10px 0",
                                  }}
                                  id={`Bl-${so.so_number}-${ci.invoice_number}`}
                                  label={"Bl Number"}
                                  type={"text"}
                                  value={
                                    bldata[
                                      `Bl-${so.so_number}-${ci.invoice_number}`
                                    ]
                                      ? bldata[
                                          `Bl-${so.so_number}-${ci.invoice_number}`
                                        ]
                                      : ""
                                  }
                                  name={[
                                    `Bl-${so.so_number}-${ci.invoice_number}`,
                                  ]}
                                  onChange={(event) => {
                                    setSubmittedData({
                                      ...bldata,
                                      [`Bl-${so.so_number}-${ci.invoice_number}`]:
                                        event.target.value,
                                    });

                                    setErrorMsg({
                                      ...errorMsg,
                                      [`Bl-${so.so_number}-${ci.invoice_number}`]: false,
                                    });
                                  }}
                                  variant="outlined"
                                />
                                <MuiPickersUtilsProvider
                                  libInstance={moment()}
                                  utils={DateFnsUtils}
                                >
                                  <CssKeyboardDatePickerSmall
                                    disabled={false}
                                    variant="inline"
                                    onOpen={() => {
                                      setCalenderOpen(`Bl-${so.so_number}-${ci.invoice_number}`)
                                    }}
                                    onClose={() => {
                                      setCalenderOpen(false)
                                    }}
                                    style={{ margin: '10px 0' }}
                                    // maxDate={singleInput.maxDate?undefined:new Date()}
                                    // style={
                                    //   textFieldStyle
                                    //     ? textFieldStyle
                                    //     : textFieldWidth
                                    //     ? styles.withDivider502
                                    //     : styles.withDivider50
                                    // }
                                    // ={singleInput.enableFutureDates === true ? false : true}
                                    // minDate={"2022-01-22"}
                                    error={false}
                                    invalidLabel=""
                                    onKeyPress={(ev) => {
                                      console.log(ev.keyCode, "keyCode");
                                      ev.preventDefault();
                                    }}
                                    onKeyUp={(ev) => {
                                      ev.preventDefault();
                                    }}
                                    onKeyDown={(ev) => {
                                      ev.preventDefault();
                                    }}
                                    allowKeyboardControl={false}
                                    keyboardIcon={
                                      calenderOpen === `Bl-${so.so_number}-${ci.invoice_number}` ? (
                                        <EventIcon />
                                      ) : (
                                        <EventIcon />
                                      )
                                    }
                                    //
                                    autoOk
                                    inputVariant="outlined"
                                    label={'Bl Date'}
                                    format="do MMM, yyyy"
                                    id={`Bl-${so.so_number}-${ci.invoice_number}date`}
                                    value={blDateData[`Bl-${so.so_number}-${ci.invoice_number}`] || new Date()}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={(newValue) => {
                                      setSubmittedBlDateData({
                                        ...blDateData,
                                        [`Bl-${so.so_number}-${ci.invoice_number}`]: moment(newValue).format('YYYY-MM-DD')
                                      })
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                                <CssTextField95
                                  required={true}
                                  error={
                                    errorMsg[
                                      `vessel-${so.so_number}-${ci.invoice_number}`
                                    ]
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                  style={{
                                    width: "190px",
                                    minWidth: "190px",
                                    margin: "10px 20px 10px 20px",
                                  }}
                                  id={`vessel-${so.so_number}-${ci.invoice_number}`}
                                  label={"Vessel/Flight Name"}
                                  type={"text"}
                                  value={
                                    vesselFlightData[
                                      `vessel-${so.so_number}-${ci.invoice_number}`
                                    ]
                                      ? vesselFlightData[
                                          `vessel-${so.so_number}-${ci.invoice_number}`
                                        ]
                                      : ""
                                  }
                                  name={[
                                    `vessel-${so.so_number}-${ci.invoice_number}`,
                                  ]}
                                  onChange={(event) => {
                                    setSubmittedVesselFlightData({
                                      ...vesselFlightData,
                                      [`vessel-${so.so_number}-${ci.invoice_number}`]:
                                        event.target.value,
                                    });

                                    setErrorMsg({
                                      ...errorMsg,
                                      [`vessel-${so.so_number}-${ci.invoice_number}`]: false,
                                    });
                                  }}
                                  variant="outlined"
                                />
                                <CssTextField95
                                  // required={true}
                                  error={
                                    errorMsg[
                                      `flight-${so.so_number}-${ci.invoice_number}`
                                    ]
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                  style={{
                                    width: "190px",
                                    minWidth: "190px",
                                    margin: "10px 20px 10px 0",
                                  }}
                                  id={`flight-${so.so_number}-${ci.invoice_number}`}
                                  label={"Vessel/Flight Number"}
                                  type={"text"}
                                  value={
                                    vesselFlightData[
                                      `flight-${so.so_number}-${ci.invoice_number}`
                                    ]
                                      ? vesselFlightData[
                                          `flight-${so.so_number}-${ci.invoice_number}`
                                        ]
                                      : ""
                                  }
                                  name={[
                                    `flight-${so.so_number}-${ci.invoice_number}`,
                                  ]}
                                  onChange={(event) => {
                                    setSubmittedVesselFlightData({
                                      ...vesselFlightData,
                                      [`flight-${so.so_number}-${ci.invoice_number}`]:
                                        event.target.value,
                                    });

                                    setErrorMsg({
                                      ...errorMsg,
                                      [`flight-${so.so_number}-${ci.invoice_number}`]: false,
                                    });
                                  }}
                                  variant="outlined"
                                />
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  marginLeft: "24px",
                                  width: "calc(100% - 24px)",
                                }}
                              >
                                {ciItemTable(ci, submittedData, so)}
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
              : "No custom invoice available of this sales order."}
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      <div
        className="width-100 scrollBluePrimary overflowYA"
        style={{ height: "calc(100% - 178.75px)" }}
      ></div>
    );
  };
  const scrollDiv = (id) => {
    const chat = document.getElementById(id);
    chat.scrollIntoView();
  };
  // const calculateTotalAmount = () => {
  //   const customs =
  //     so && filterData(customInv, "sales_order", so.id) ? filterData(customInv, "sales_order", so.id) : [];
  //   const customsFiltered = customs.filter((s) =>
  //     checked[so.so_number] ? (checked[so.so_number][`${s.invoice_number}`] === true ? true : false) : false
  //   );

  //   const allItemAmount = [];
  //   const ids = [];
  //   if (customsFiltered.length > 0) {
  //     customsFiltered.map((ciData) => {
  //       if (ciData.custom_invoice_item_details.length > 0) {
  //         ciData.custom_invoice_item_details.map((sI) => {
  //           const details = filterData(soItems, "id", sI.so_item, "onlyOne")
  //             ? filterData(soItems, "id", sI.so_item, "onlyOne")
  //             : false;
  //           if (!ids.includes(sI.id)) {
  //             allItemAmount.push(
  //               submitted && submitted[`${ciData.invoice_number}${details.id}`]
  //                 ? submitted[`${ciData.invoice_number}${details.id}`]["amount_in_other"]
  //                   ? submitted[`${ciData.invoice_number}${details.id}`]["amount_in_other"]
  //                   : 0
  //                 : 0
  //             );
  //             ids.push(sI.id);
  //           }
  //           return sI;
  //         });
  //       }

  //       return ciData;
  //     });
  //   }
  //   return allItemAmount
  //     .reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue ? currentValue : 0), 0)
  //     .toFixed(2);
  // };
  console.log("SO", so)
  return (
    <CreateDialog
      noAddAnother={true}
      closeOnSave={true}
      //fullScreen={true}
      isSetLoad={loader}
      reset={() => {
        if (editList) {
          setDoApiCall(true);
        } else {
          setSO(false);
          setSoItems([]);
          setRateOfGst(0);
          setType_ci("WIGST");
          setSubmitted();
          setSubmittedData({});
          setSubmittedBlDateData({});
          setSubmittedVesselFlightData({})
          setChecked({});
          if (fetchCustomInvoices) {
            fetchCustomInvoices();
          }
        }
      }}
      detailUI={() => {
        console.log(singlelistView,customInvArr,"singlelistViewqwdqwdwd")
        return (
          <ShowDetails
            isMobile={false}
            width="100%"
            lists={[
              {
                name: "Loading Port",
                value:
                  singlelistView && singlelistView.name
                    ? singlelistView.name
                    : "",
              },
              {
                name: "Contract Date",
                value:
                  so && so.contract_date
                    ? moment(so.contract_date).format("ddd, MMM Do YYYY")
                    : "",
              },
              {
                name: "Unit",
                value: so && so.unit ? so.unit : "",
              },
              {
                name: "Buyer",
                value:
                  so &&
                  filterData(
                    masters && masters.buyersList,
                    "id",
                    so.buyer,
                    "onlyOne"
                  )
                    ? filterData(
                        masters && masters.buyersList,
                        "id",
                        so.buyer,
                        "onlyOne"
                      ).client_name
                    : "",
              },
              {
                name: "Payment Term",
                value:
                  so &&
                  filterData(
                    masters && masters.soPaymentTermsList,
                    "id",
                    so.so_payment_term,
                    "onlyOne"
                  )
                    ? filterData(
                        masters && masters.soPaymentTermsList,
                        "id",
                        so.so_payment_term,
                        "onlyOne"
                      ).term
                    : "",
              },
            ]}
            height={"fit-content"}
            margin="10px"
          />
        );
      }}
      checkAllFields={() => {
        const customs = customInvArr;
        const customsFiltered = customs.filter((s) =>
          checked[so.so_number]
            ? checked[so.so_number][`${s.invoice_number}`] === true
              ? true
              : false
            : false
        );
        var success = false;
        var stop = true;
        if (customsFiltered.length > 0) {
          console.log(customsFiltered, "customsFiltered");
          customsFiltered.map((cF, i) => {
            if (stop === true) {
              if (bldata[`Bl-${so.so_number}-${cF.invoice_number}`]) {
                if (cF.custom_invoice_item_details.length > 0) {
                  cF.custom_invoice_item_details.map((sI, i2) => {
                    if (stop === true) {
                      const details = filterData(
                        soItems,
                        "id",
                        sI.so_item,
                        "onlyOne"
                      )
                        ? filterData(soItems, "id", sI.so_item, "onlyOne")
                        : false;
                      const plCount = [];
                      details.packaging &&
                        details.packaging.length > 0 &&
                        details.packaging.map((sP) => {
                          if (
                            submitted &&
                            submitted[`${cF.invoice_number}${sI.id}`]
                          ) {
                            plCount.push(
                              submitted[`${cF.invoice_number}${sI.id}`][
                                `${sP}-bags`
                              ]
                                ? Number(
                                    submitted[`${cF.invoice_number}${sI.id}`][
                                      `${sP}-bags`
                                    ]
                                  )
                                : 0
                            );
                          }
                          return sP;
                        });
                      if (
                        (submitted &&
                          submitted[`${cF.invoice_number}${sI.id}`]) ||
                        !editList ||
                        plCount.reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) +
                            Number(currentValue ? currentValue : 0),
                          0
                        ) === 0
                      ) {
                        console.log(!editList, "editList");
                        if (
                          customsFiltered.length - 1 === i &&
                          cF.custom_invoice_item_details.length - 1 === i2
                        ) {
                          success = true;
                          return success;
                        }
                      } else {
                        scrollDiv(`${sI.id}addDetail`);
                        setErrorMsg({
                          ...error,
                          [`${sI.id}addDetail`]: true,
                        });
                        stop = false;
                      }
                    }
                    return sI;
                  });
                }
              } else {
                scrollDiv(`Bl-${so.so_number}-${cF.invoice_number}`);
                setErrorMsg({
                  ...error,
                  [`Bl-${so.so_number}-${cF.invoice_number}`]: true,
                });
                stop = false;
              }
            }
            return cF;
          });
          if (success === true) {
            return "success";
          } else if (stop === false) {
            return false;
          }
        } else {
          setError("Please select atleast 1 custom invoice!");
          setErrorMsg({
            ...error,
            selectCi: true,
          });
          return false;
        }
      }}
      secondUpdate={
        editList
          ? {
              api: "arrival/custom-invoice/bulk-update",
              method: "put",
              api2: "arrival/commercial-invoice-item/bulk-change",
              method2: "post",
            }
          : false
        // {
        //     api: "arrival/custom-invoice/bulk-update",
        //     method: "put",
        //   }
      }
      formatData={(data, editData, first_api, second_api) => {
        console.log(data,editData, first_api, second_api, "formatData");
        const customs =
          so && filterData(customInv, "sales_order", so.id)
            ? filterData(customInv, "sales_order", so.id)
            : [];
        const customsFiltered = customs.filter((s) =>
          checked[so.so_number]
            ? checked[so.so_number][`${s.invoice_number}`] === true
              ? true
              : false
            : false
        );

        const itemsArr = [];
        const allItemAmount = [];
        const ids = [];
        const idsArr = [];
        const grossWEightsArr = [];
        const netWEightsArr = [];
        const custom_invoice_info = first_api ? [] : {};
        if (customsFiltered.length > 0) {
          customsFiltered.map((ciData) => {
            if (ciData.custom_invoice_item_details.length > 0) {
              ciData.custom_invoice_item_details.map((sI) => {
                const details = filterData(soItems, "id", sI.so_item, "onlyOne")
                  ? filterData(soItems, "id", sI.so_item, "onlyOne")
                  : false;
                if (!idsArr.includes(sI.id)) {
                  idsArr.push(sI.id);
                  grossWEightsArr.push(
                    Number(
                      submitted && submitted[`${ciData.invoice_number}${sI.id}`]
                        ? submitted[`${ciData.invoice_number}${sI.id}`][
                            "Gross Weight"
                          ]
                          ? submitted[`${ciData.invoice_number}${sI.id}`][
                              "Gross Weight"
                            ]
                          : 0
                        : 0
                    )
                  );
                  netWEightsArr.push(
                    Number(
                      submitted && submitted[`${ciData.invoice_number}${sI.id}`]
                        ? submitted[`${ciData.invoice_number}${sI.id}`][
                            "Net Weight"
                          ]
                          ? submitted[`${ciData.invoice_number}${sI.id}`][
                              "Net Weight"
                            ]
                          : 0
                        : 0
                    )
                  );
                }
                const actual_pkg_counter = {};

                const empty_pkg_counter = {};
                details.packaging &&
                  details.packaging.length > 0 &&
                  details.packaging.map((sP) => {
                    if (
                      submitted &&
                      submitted[`${ciData.invoice_number}${sI.id}`]
                    ) {
                      actual_pkg_counter[sP] = submitted[
                        `${ciData.invoice_number}${sI.id}`
                      ][`${sP}-bags`]
                        ? Boolean(submitted[`${ciData.invoice_number}${sI.id}`][
                            `${sP}-bags`
                          ]) ? Number(submitted[`${ciData.invoice_number}${sI.id}`][
                            `${sP}-bags`
                          ]) : 0
                        : 0;
                        empty_pkg_counter[sP]=submitted[
                          `${ciData.invoice_number}${sI.id}`
                        ][`${sP}-empty-bags`]
                          ? Boolean(submitted[`${ciData.invoice_number}${sI.id}`][
                              `${sP}-empty-bags`
                            ]) ? Number(submitted[`${ciData.invoice_number}${sI.id}`][ `${sP}-empty-bags`]) : 0
                          : 0;
                        
                    }
                    return sP;
                  });
                const newObj =
                  submitted && submitted[`${ciData.invoice_number}${sI.id}`]
                    ? {
                        container_numbers: submitted[
                          `${ciData.invoice_number}${sI.id}`
                        ]
                          ? submitted[`${ciData.invoice_number}${sI.id}`][
                              "container_number"
                            ]
                            ? submitted[`${ciData.invoice_number}${sI.id}`][
                                "container_number"
                              ]
                            : []
                          : [],
                        gross_weight:
                          submitted &&
                          submitted[`${ciData.invoice_number}${sI.id}`]
                            ? submitted[`${ciData.invoice_number}${sI.id}`][
                                "Gross Weight"
                              ]
                              ? submitted[`${ciData.invoice_number}${sI.id}`][
                                  "Gross Weight"
                                ]
                              : 0
                            : 0,
                        net_weight:
                          submitted &&
                          submitted[`${ciData.invoice_number}${sI.id}`]
                            ? submitted[`${ciData.invoice_number}${sI.id}`][
                                "Net Weight"
                              ]
                              ? submitted[`${ciData.invoice_number}${sI.id}`][
                                  "Net Weight"
                                ]
                              : 0
                            : 0,
                        actual_pkg_counter: Object.entries(actual_pkg_counter).reduce((acc, [key, value]) => (value !== "" ? { ...acc, [key]: value } : acc), {}) || null,
                        extra_empty_bags: Object.entries(empty_pkg_counter).reduce((acc, [key, value]) => (value !== "" ? { ...acc, [key]: value } : acc), {}) || null,
                        no_of_containers: submitted[
                          `${ciData.invoice_number}${sI.id}`
                        ]
                          ? submitted[`${ciData.invoice_number}${sI.id}`][
                              "container_number"
                            ]
                            ? submitted[`${ciData.invoice_number}${sI.id}`][
                                "container_number"
                              ].length
                            : 0
                          : 0,
                        custom_invoice_item: sI.id,
                        amount_in_other:
                          submitted &&
                          submitted[`${ciData.invoice_number}${sI.id}`]
                            ? submitted[`${ciData.invoice_number}${sI.id}`][
                                "amount_in_other"
                              ]
                              ? submitted[`${ciData.invoice_number}${sI.id}`][
                                  "amount_in_other"
                                ]
                              : 0
                            : 0,
                      }
                    : { custom_invoice_item: sI.id };
                if (
                  second_api &&
                  filterData(
                    editList.commercial_invoice_item_details,
                    "custom_invoice_item",
                    Number(sI.id),
                    "onlyOne"
                  )
                ) {
                  console.log(editList, "second_apisecond_api");
                  newObj["id"] = filterData(
                    editList.commercial_invoice_item_details,
                    "custom_invoice_item",
                    Number(sI.id),
                    "onlyOne"
                  ).id;
                  newObj["commercial_invoice"] = editList.id;
                } else if (second_api) {
                  console.log(editList, "second_apisecond_api 12");
                  newObj["commercial_invoice"] = editList.id;
                }
                // console.log(editData.split("id - ")[1],"editData")
                if (first_api) {
                  // delete ciData.precarrier_details
                  custom_invoice_info.push({
                    ...ciData,
                    id: ciData.id,
                    precarrier:ciData.precarrier_details?.id??null,
                    bl_number: bldata[
                      `Bl-${so.so_number}-${ciData.invoice_number}`
                    ]
                      ? bldata[`Bl-${so.so_number}-${ciData.invoice_number}`]
                      : "",
                    bl_date: blDateData[`Bl-${so.so_number}-${ciData.invoice_number}`]
                      ? blDateData[`Bl-${so.so_number}-${ciData.invoice_number}`]
                      : moment(new Date()).format('YYYY-MM-DD'),
                    vessel_number: vesselFlightData[
                      `vessel-${so.so_number}-${ciData.invoice_number}`
                    ]
                      ? vesselFlightData[`vessel-${so.so_number}-${ciData.invoice_number}`]
                      : "",
                    flight_number: vesselFlightData[
                      `flight-${so.so_number}-${ciData.invoice_number}`
                    ]
                      ? vesselFlightData[`flight-${so.so_number}-${ciData.invoice_number}`]
                      : "",
                    commercial_invoice: editList ? editList.id : "", //:editData.split("id - ")[1],

                    status: "CLOSED",
                  });
                  console.log(custom_invoice_info, )
                } else {
                  custom_invoice_info[ciData.id] = bldata[
                    `Bl-${so.so_number}-${ciData.invoice_number}`
                  ]
                    ? bldata[`Bl-${so.so_number}-${ciData.invoice_number}`]
                    : "";
                  // custom_invoice_info.status = "CLOSED";
                }

                // if (secondApi) {
                //   itemsArr.push(blAmount);
                // } else {
                if (!ids.includes(sI.id) && newObj !== undefined) {
                  itemsArr.push(newObj);
                  allItemAmount.push(
                    submitted && submitted[`${ciData.invoice_number}${sI.id}`]
                      ? submitted[`${ciData.invoice_number}${sI.id}`][
                          "amount_in_other"
                        ]
                        ? submitted[`${ciData.invoice_number}${sI.id}`][
                            "amount_in_other"
                          ]
                        : 0
                      : 0
                  );
                  ids.push(sI.id);
                }
                // }
                return sI;
              });
            }

            return ciData;
          });
        }
        const totalGrossWeight = grossWEightsArr
          .reduce(
            (previousValue, currentValue) =>
              Number(previousValue) + Number(currentValue ? currentValue : 0),
            0
          )
          .toFixed(3);
        const totalNetWeight = netWEightsArr
          .reduce(
            (previousValue, currentValue) =>
              Number(previousValue) + Number(currentValue ? currentValue : 0),
            0
          )
          .toFixed(3);
          console.log(data["Notify 1"],data["Notify 1Id"], data["Notify 2"],data["Notify 2Id"], data["Notify 3"],data["Notify 3Id"])
        const commercial_invoice = {
          amount_in_other: amountData[`Amount In ${amountCurrency}`] || "",
          advance_amount_in_other: amountData[`Advance Amount In ${amountCurrency}`]|| "",
          pending_amount_in_other: Number(Number(amountData[`Amount In ${amountCurrency}`] || 0) - Number(amountData[`Advance Amount In ${amountCurrency}`] || 0) ).toFixed(2)|| "",
          total_fob_value: amountData['Total FOB Value'],
          duty: amountData['Duty'],
          freight: amountData['Freight'],
          insurance: amountData['Insurance'],
          notify_1: data["Notify 1Id"] ? data["Notify 1Id"] : "",
          notify_2: data["Notify 2Id"] ? data["Notify 2Id"] : "",
          notify_3: data["Notify 3Id"] ? data["Notify 3Id"] : "",
          net_weight: totalNetWeight,
          gross_weight: totalGrossWeight,
          invoice_number: data["Commercial Invoice Number"]
            ? data["Commercial Invoice Number"]
            : "",
          invoice_type: data["Type"] ? data["Type"] : "",
          invoice_date: data[["Commercial Invoice Date"]]
            ? moment(data[["Commercial Invoice Date"]]).format("YYYY-MM-DD")
            : "",
          total_taxable_value_of_shipment: allItemAmount
            .reduce(
              (previousValue, currentValue) =>
                Number(previousValue) + Number(currentValue ? currentValue : 0),
              0
            )
            .toFixed(2),
          total_fob_value_of_shipped_cargo: 1,
          invoice_type_details: {},
          igst_value: data["Rate of IGST ( IN % )"]
            ? data["Rate of IGST ( IN % )"]
            : "0",
          // status: data["Status"] ? data["Status"] : "OPEN",
          status:
            (data["Status"] ? data["Status"] : "DRAFT") === "DRAFT"
              ? totalNetWeight > 0
                ? "OPEN"
                : data["Status"]
              : data["Status"]
              ? data["Status"]
              : "DRAFT",
          is_active: true,
          sales_order: so && so.id ? so.id : "",
        };
        return second_api
          ? itemsArr
          : first_api
          ? custom_invoice_info
          : editData
          ? commercial_invoice
          : {
              custom_invoice_info: custom_invoice_info,
              commercial_invoice: commercial_invoice,
              commercial_invoice_items: itemsArr,
            };
      }}
      // contentHeight="100%"
      extraDetial={soItemTable}
      extraDetialValue={selectedBrandsArr}
      addFieldsToAdd={[
        { name: "is_active", value: true },
        {
          name: "shipment_process_status",
          value: editList.shipment_process_status
            ? editList.shipment_process_status
            : "CUSTOM INVOICE GENERATED",
        },
        { name: "loading_port", value: singlelistView && singlelistView.id },
        // {
        //   name: "container_details",
        //   value: containerArrCopy.map((ss) => {
        //     return {
        //       number_of_containers: ss["number_of_containers"],
        //       container_size: "26",
        //       container_number: ss["container_number"],
        //       container_brand: ss["container_brand"],
        //     };
        //   }),
        // },
      ]}
      module={"Commercial Invoice"}
      selectedList={editList ? editList : false}
      editList={editList ? editList : false}
      handleClose={() => {
        onClose(false);
      }}
      fetchAgain={(res, msg) => {
        fetchAgain(res,msg);
      }}
      postApi="arrival/commercial-invoice/list"
      editApi="arrival/commercial-invoice"
      textFieldStyle={{ width: "calc(25% - 20px)", margin: "10px" }}
      heightNew="calc(100vh - 100px)"
      maxHeight="calc(100vh - 100px)"
      widthNew="90%"
      inputArray={[
        (!editList || editList?.status  === 'DRAFT') &&{
          name: "DRAFT",
          type: "toggle",
          defaultValue: {
            value: editList && editList?.status  === 'DRAFT' ? true : draftToggle,
          },
          disabled: !Boolean(editList) || editList?.status  === 'DRAFT' ? false : true,
          onChange: async(submittedData) => {
            console.log("submittedData", submittedData);
            setDraftToggle(submittedData['DRAFT']);
            submittedData['Status'] = submittedData['DRAFT'] ? "DRAFT" : 'OPEN';
            submittedData['StatusId'] = submittedData['DRAFT'] ? "DRAFT" : 'OPEN';
            return submittedData;
          },
        },
        {
          name: "Type",
          type: "radio",
          array: ["GST", "WIGST"],
          // update: true,
          api: "status",
          disabled: editList && editList.invoice_type ? false : true,
          defaultValue: {
            value:
              editList && editList.invoice_type
                ? editList.invoice_type
                : type_ci
                ? type_ci
                : "WIGST",
          },
          onChange: async(data) => {
            setType_ci(data["Type"]);
            return data;
          },
        },
        {
          name: "Status",
          type: "select",
          dataType: "text",
          array: [
            { name: "DRAFT", id: "DRAFT" },
            { name: "OPEN", id: "OPEN" },
            { name: "CLOSED", id: "CLOSED" },
            { name: "CANCELLED", id: "CANCELLED" },
          ],
          value: "name",
          api: "status",
          disabled: !editList 
          ? true
          : editList.status ? false : true,
          defaultValue: {
            // value: editList && editList.status ? editList.status : "OPEN",
            // id: editList && editList.status ? editList.status : "OPEN",
            value: editList && editList.status ? editList.status : draftToggle ? "DRAFT" : "OPEN",
            id: editList && editList.status ? editList.status : draftToggle ? "DRAFT" : "OPEN",
          },
          onChange: async (submittedData) => {
            if (submittedData["Status"]) {
              setDisabled(submittedData["Status"] === "CLOSED");
              submittedData['DRAFT'] = submittedData["Status"] === 'DRAFT' ? true : false;
            }

            return submittedData;
          },
        },

        {
          name: "Commercial Invoice Date",
          type: "Month",
          dataType: "text",
          disabled: false,
          api: "invoice_date",
          defaultValue: {
            value:
              editList && editList.invoice_date
                ? editList.invoice_date
                : new Date(),
          },
        },
        {
          name: "Commercial Invoice Number",
          type: "textField",
          dataType: "text",
          disabled: alldisabled,
          api: "invoice_number",
          defaultValue: {
            value:
              editList && editList.invoice_number
                ? editList.invoice_number
                : "",
          },
        },
        type_ci !== "WIGST" && {
          name: "Rate of IGST ( IN % )",
          type: "textField",
          dataType: "number",
          endAdornment: "%",
          percentage: "100",
          update: true,
          defaultValue: {
            value:
              editList && editList["igst_value"] ? editList["igst_value"] : "",
          },
          onChange: async (data) => {
            setRateOfGst(data["Rate of IGST ( IN % )"]);
            // data["GST Amount"] = calculatePercentage(
            //   data["Total Amount"] ? data["Total Amount"] : 0,
            //   data["Rate of IGST ( IN % )"]
            // );
            // data["Total Amount IGST"] = calculateGstAmount(
            //   data["Total Amount"] ? data["Total Amount"] : 0,
            //   data["Rate of IGST ( IN % )"]
            // );

            return data;
          },
          api: "igst_value",
          not_required: draftToggle
        },
       
        {
          name: "Sales Orders",
          type: "select",
          dataType: "text",
          disabled: editList ? true : alldisabled,
          array:
            masters &&
            masters.salesOrderList &&
            masters.salesOrderList.length > 0
              ? masters.salesOrderList.sort((a, b) => b.id - a.id)
              : [],
          value: "so_number",
          subMenuTitle: "Remaining",
          subMenuValue: ["remaining_quantity","unit"],
          defaultValue: {
            value:
              editList.custom_invoice_details &&
              editList.custom_invoice_details.length > 0
                ? editList.custom_invoice_details[0].sales_order_details
                  ? editList.custom_invoice_details[0].sales_order_details
                      .so_number
                  : ""
                : "",
            id:
              editList.custom_invoice_details &&
              editList.custom_invoice_details.length > 0
                ? editList.custom_invoice_details[0].sales_order_details
                  ? editList.custom_invoice_details[0].sales_order_details.id
                  : ""
                : "",
          },
          
          api: "sales_order",
          onChange: async (submittedData) => {
            if (submittedData["Sales Orders"]) {
              const selectedSAles = submittedData["Sales OrdersId"];
              const selectedSO = filterData(
                masters && masters.salesOrderList,
                "id",
                Number(selectedSAles),
                "onlyOne"
              )
                ? filterData(
                    masters && masters.salesOrderList,
                    "id",
                    Number(selectedSAles),
                    "onlyOne"
                  )
                : "";
              submittedData["Contract Date"] = selectedSO
                ? selectedSO.contract_date
                : "";
              submittedData["Unit"] = selectedSO ? selectedSO.unit : "";

              submittedData["Buyer"] = filterData(
                masters && masters.buyersList,
                "id",
                selectedSO.buyer,
                "onlyOne"
              )
                ? filterData(
                    masters && masters.buyersList,
                    "id",
                    selectedSO.buyer,
                    "onlyOne"
                  ).client_name
                : "";
              submittedData["BuyerId"] =
                selectedSO && selectedSO.buyer ? selectedSO.buyer : "";
              setSO(selectedSO);
              fetchSoItems(selectedSAles);
              if (fetchCustomInvoices) {
                fetchCustomInvoices(selectedSAles);
              }
            }

            return submittedData;
          },
          // disabled: alldisabled,
        },

        Boolean(so?.id) &&
        {
          name: "Total FOB Value",
          type: "textField",
          dataType: "number",
          update: true,
          defaultValue: {
            value:
              editList && editList["total_fob_value"] ? editList["total_fob_value"] : "",
            },
          onChange: async (submittedData) => {
            let updatedData = handleAmountValueChange(submittedData, "Total FOB Value");
            return updatedData;
          },
          api: "total_fob_value",
          not_required: draftToggle
        },
        Boolean(so?.id) &&
        {
          name: "Duty",
          type: "textField",
          dataType: "number",
          update: true,
          defaultValue: {
            value:
              editList && editList["duty"] ? editList["duty"] : "",
            },
          onChange: async (submittedData) => {
            let updatedData = handleAmountValueChange(submittedData, "Duty");
            return updatedData;
          },
          api: "duty",
          not_required: draftToggle
        },
        Boolean(so?.id) &&
        {
          name: "Freight",
          type: "textField",
          dataType: "number",
          update: true,
          defaultValue: {
            value:
              editList && editList["freight"] ? editList["freight"] : "",
            },
          onChange: async (submittedData) => {
            let updatedData = handleAmountValueChange(submittedData, "Freight");
            return updatedData;
          },
          api: "freight",
          not_required: draftToggle
        },
        Boolean(so?.id) &&
        {
          name: "Insurance",
          type: "textField",
          dataType: "number",
          update: true,
          defaultValue: {
            value:
              editList && editList["insurance"] ? editList["insurance"] : "",
            },
          onChange: async (submittedData) => {
            let updatedData = handleAmountValueChange(submittedData, "Insurance");
            return updatedData;
          },
          api: "insurance",
          not_required: draftToggle
        },
        Boolean(so?.id) &&
        {
          name: `Amount In ${amountCurrency}`,
          type: "textField",
          dataType: "number",
          update: true,
          not_required: true,
          defaultValue: {
            value:
              editList && editList["amount_in_other"] ? editList["amount_in_other"] : "",
            },
          onChange: async (submittedData) => {
            let updatedData = handleAmountValueChange(submittedData, `Amount In ${amountCurrency}`);
            return updatedData;
          },
          api: "amount_in_other",
        },
        Boolean(so?.id) &&
        {
          name: `Advance Amount In ${amountCurrency}`,
          type: "textField",
          dataType: "number",
          update: true,
          not_required: true,
          defaultValue: {
            value:
              editList && editList["advance_amount_in_other"] ? editList["advance_amount_in_other"] : "",
            },
          onChange: async (submittedData) => {
            let updatedData = handleAmountValueChange(submittedData, `Advance Amount In ${amountCurrency}`);
            return updatedData;
          },
          api: "advance_amount_in_other",
        },
        Boolean(so?.id) &&
        {
          name: `Pending Amount In ${amountCurrency}`,
          type: "static",
          dataType: "number",
          update: true,
          disabled: true,
          not_required: true,
          defaultValue: {
            value:
              amountData && amountData[`Pending Amount In ${amountCurrency}`] ? amountData[`Pending Amount In ${amountCurrency}`] : "",
            },
          api: "pending_amount_in_other",
        },
        {
          name: "Notify 1",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.notifyList && masters.notifyList.length > 0
              ? masters.notifyList.sort((a, b) => b.id - a.id)
              : [],
          value: "notify",
          defaultValue: {
            value:
              editList && editList.notify_1
                ? filterData(masters && masters.notifyList, "id", Number(editList.notify_1), "onlyOne")
                  ? filterData(masters && masters.notifyList, "id", Number(editList.notify_1), "onlyOne").notify
                  : ""
                : "",
            id: editList && editList.notify_1 ? editList.notify_1 : "",
          },
          disabled: alldisabled,
        },
        {
          name: "Notify 2",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.notifyList && masters.notifyList.length > 0
              ? masters.notifyList.sort((a, b) => b.id - a.id)
              : [],
          value: "notify",
          defaultValue: {
            value:
              editList && editList.notify_2
                ? filterData(masters && masters.notifyList, "id", Number(editList.notify_2), "onlyOne")
                  ? filterData(masters && masters.notifyList, "id", Number(editList.notify_2), "onlyOne").notify
                  : ""
                : "",
            id: editList && editList.notify_2 ? editList.notify_2 : "",
          },
          disabled: alldisabled,
        },
        {
          name: "Notify 3",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.notifyList && masters.notifyList.length > 0
              ? masters.notifyList.sort((a, b) => b.id - a.id)
              : [],
          value: "notify",
          defaultValue: {
            value:
              editList && editList.notify_3
                ? filterData(masters && masters.notifyList, "id", Number(editList.notify_3), "onlyOne")
                  ? filterData(masters && masters.notifyList, "id", Number(editList.notify_3), "onlyOne").notify
                  : ""
                : "",
            id: editList && editList.notify_3 ? editList.notify_3 : "",
          },
          disabled: alldisabled,
        },
        
      ]}
      postData={postData}
    />
  );
}

export default CreateCommercial;