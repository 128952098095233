import React, { useState } from "react";
import { StyledButton } from "../styles/StyledComponents";
import { Popover } from "@material-ui/core";

// import ClickAwayListener from "@mui/base/ClickAwayListener";
import { BottomArrow } from "../styles/Icons";
import { ListMapUI } from "./ListMapUI";
import { ClickAwayListener } from "@mui/base";
export const getCondition = (condition) => {
  if (condition) {
    switch (condition) {
      case "equal_to":
        return "Equal to (=)";
        break;
      case "not_equal_to":
        return "not equal to (!=)";
        break;
      case "greater_than":
        return "greater than (>)";
        break;
      case "greater_than_or_equal_to":
        return "greater than or equal to (>=)";
        break;
      case "less_than":
        return "less than (<)";
        break;
      case "less_than_or_equal_to":
        return "less than or equal to (<=)";
        break;
      default:
        return "No Condition";
        break;
    }
  }
  else return ""
};
export const getSpecificationId = (condition) => {
  if (condition) {
    console.log(condition, "getSpecificationId condition");
    switch (condition) {
      case "equal_to":
        return "Equal To";
        break;
      case "not_equal_to":
        return "Not Equal To";
        break;
      case "greater_than":
        return "Greater Than";
        break;
      case "greater_than_or_equal_to":
        return "Greater Than Or Equal To";
        break;
      case "less_than":
        return "Less Than";
        break;
      case "less_than_or_equal_to":
        return "Less Than Or Equal To";
        break;
        default:
          return "No Condition";
          break;
    }
  }
};
export const getSpecificationIdOnly = (condition) => {
  if (condition) {
    console.log(condition, "getSpecificationId condition");
    switch (condition) {
      case "Equal To":
        return "equal_to";
        break;
      case "Not Equal To":
        return "not_equal_to";
        break;
      case "Greater Than":
        return "greater_than";
        break;
      case "Greater Than Or Equal To":
        return "greater_than_or_equal_to";
        break;
      case "Less Than":
        return "less_than";
        break;
      case "Less Than Or Equal To":
        return "less_than_or_equal_to";
        break;
      default:
        break;
    }
  }
};
function ShowSpecifications(props) {
  const [openPopover, setOpenPopover] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const { title, details, Unit } = props;
  console.log(props, details, "details");

  return details && details.length > 0 ? (
    <>
      <div
        style={{
          width: details && details.length > 0 ? "150px" : "0",
          overflow: "hidden",
          height: "20.58px",
          textOverflow: "ellipsis",
        }}
      >
        {title
          ? title
          : details &&
            details[0] &&
            `${details[0].name} ${getCondition(
              details[0].conditionId ? details[0].conditionId : details[0].condition
            )} ${details[0].value} ${details.length > 1 ? `.. (${details.length > 9 ? details.length : `0${details.length}`})` : ""}`}
      </div>
      {console.log(details, details.length, "detailsLength", details.length > 1 , details.length > 9 , details.length , `0${details.length}`)}
      <StyledButton
        name={<BottomArrow width="20" />}
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopover(true);
          setAnchorEl(e.currentTarget);
        }}
        padding="0"
        minWidth="20px"
      />

      <Popover
        open={openPopover}
        // onRequestClose={() => {
        //   setOpenPopover(false);
        //   setAnchorEl(null);
        // }}
        anchorEl={anchorEl}
        style={{ marginTop: "-2.5%" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            setOpenPopover(false);
            setAnchorEl(null);
          }}
        >
          <div
            style={{
              height: "auto",
              minHeight: "fit-content",
              width: "100%",
              padding: "8px 0",
            }}
            className="d-flex d-flex-column alignC justifyC"
          >
            <ListMapUI 
              header={[
                { label: "Name", key: "name", },
                { label: "Condition", key: "condition" },
                { label: "Value", key: "value" },
              ]} 
              data={details.map(d => {
                return {
                  name: d.name,
                  condition: getSpecificationId(d.conditionId ? d.conditionId : d.condition),
                  value: d.value,
                }
              })}
            />
            {/* {details &&
              details.map((sDetails) => (
                <div key={sDetails.condition} style={{ width: "100%", padding: "8px 16px" }}>
                  <div className="d-flex alignC textC" style={{ margin: "0", fontSize: "0.9rem", height: "24px" }}>
                    {<span style={{ fontWeight: "600", minWidth: "fit-content" }}>{"Name"}</span>}{" "}
                    <div style={{ marginRight: "3px" }} />
                    <span style={{ width: "fit-content", minWidth: "160px" }}>{` : ${sDetails.name} ,`} </span>
                    <div style={{ marginRight: "3px" }} />{" "}
                    <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{" Condition "}</span>{" "}
                    <div style={{ marginRight: "3px" }} />
                    <span style={{ width: "fit-content", minWidth: "228px" }}>
                      {` : ${getCondition(sDetails.conditionId ? sDetails.conditionId : sDetails.condition)??"No Condition"} , `}
                    </span>
                    <div style={{ marginRight: "3px" }} />{" "}
                    <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{" Value "}</span>{" "}
                    <div style={{ marginRight: "3px" }} />
                    <span style={{ width: "fit-content", minWidth: "51.99px" }}>
                      {` : ${
                        sDetails.value && sDetails.value
                          ? `${sDetails.value} `
                          : ""
                      } .`}
                    </span>
                  </div>
                </div>
              ))} */}
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  ) : (
    "-"
  );
}

export default ShowSpecifications;
