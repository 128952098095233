// import React, { useState, useEffect, useRef } from "react";
// import PropTypes from "prop-types";
// import Paper from "@material-ui/core/Paper";
// import Tooltip from "@material-ui/core/Tooltip";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import List from "@material-ui/core/List";
// import ListItem from "./ListItem";
// import SearchBar from "./SearchBar";
// import "./styles.css";
// import { primary } from "../styles/colors";
// import RaisedSearchBar from "./RaisedSearchBar";
// import RaisedSelectBar from "./RaisedSelectBar";
// import RaisedMonthBar from "./RaisedMonthBar";

// import { StyledCircularProgress } from "../styles/StyledComponents";

// const styles = {
//   fab: { position: "absolute", bottom: 16, right: 16 },
//   tab: (isMobile, length) => ({
//     minWidth: isMobile ? `${100 / length}%` : 291.47 / length,
//   }),
//   paper: { color: primary, backgroundColor: "#f8f8f8" },
//   container: (isMobile, search, RaisedSearchBar) => ({
//     height: isMobile
//       ? search || RaisedSearchBar
//         ? "calc(100% - 56px)"
//         : "100%"
//       : search || RaisedSearchBar
//       ? "calc(100% - 72px)"
//       : "100%",

//     overflow: "auto",
//     // unicodeBidi: "bidi-override",
//     // direction: "rtl",
//     margin: "0 2px",
//     width: "calc(100% - 4px)",
//   }),
// };

// function TabPanel(props) {
//   const { children, value, index } = props;
//   if (value === index) return children;
//   else return null;
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }

// export default function SubMenuList(props) {
//   // const user = localStorage.getItem("ssipluser") ? JSON.parse(localStorage.getItem("ssipluser")) : "";
//   const [selectedIndex, setIndex] = useState(-1);
//   const [tabIndex, setTab] = useState(0);
//   const [searchedData, setSearchedData] = useState(null);

//   const [fetched, setFetched] = useState(false);
//   const [selctedData, setSelctedData] = useState(null);
//   const handleSearchMonth = (newValue) => {
//     setSearchedData(newValue);
//     if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
//       props.serchClick(newValue);
//     }
//   };
//   const [pagination_loading,setLoad]=useState(false)
//   const lastDivRef = useRef(null);
//   function isScrolledToBottom(element) {
//     if (!element) return false
//     return (
//       // ((element.scrollHeight) - Math.round(element.scrollTop)) === element.clientHeight)||
//       (
// ((element.scrollHeight) - Math.round(element.scrollTop)) - element.clientHeight)<2    )
//   }
//   useEffect(() => {
//    if(props.pagination) {
//     const {count,loadMore,next,currentLength}=props.pagination
//     const scrollElement = document.getElementById('scroll');
//     setLoad(false)
//     const handleScroll = () => {
//         if (isScrolledToBottom(scrollElement)) {
//           if(next&&count>currentLength&&!props.loading&&!pagination_loading){
//               setLoad(true)
//                 loadMore()
//           }
//       }
//       };

//     if (scrollElement) {
//       scrollElement.addEventListener('scroll', handleScroll);
//       return () => {
//         scrollElement.removeEventListener('scroll', handleScroll);
//       };
//     }}
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [props.pagination]);
//   useEffect(() => {
//     if (props.RaisedSelectBar) {
//       if (props.RaisedSelectBar.selctedData) {
//         setSelctedData(props.RaisedSelectBar.selctedData);
//         handleSelect("", props.RaisedSelectBar.selctedData);
//       }
//     }
//   }, []);

//   useEffect(() => {
//     if (props.RaisedMonthBar) {
//       handleSearchMonth(new Date());
//     }
//   }, []);
//   const handleSearch = (e, newValue) => {
//     if (props.tabs[tabIndex].searchArr) {
//       setIndex(-1);

//       setSearchedData(newValue);

//       if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
//         props.serchClick(newValue);
//       }
//       props.navigate(`${props.tabs[tabIndex].goBackRoute}`);
//     } else {
//       const index = props.tabs[tabIndex].data.findIndex((e) => e === newValue);
//       if (index > -1) {
//         setSearchedData(newValue);
//         setIndex(index);
//         if (props.setOpen) props.setOpen(false);
//         if (props.setIndex) props.setIndex(index);

//         if (props.setSwipeableIndex) {
//           setTimeout(() => {
//             props.setSwipeableIndex(1);
//             if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
//               props.serchClick(props.tabs[tabIndex].data[index]);
//             }
//             props.navigate(`${props.tabs[tabIndex].route}/${index}`);
//           }, 350);
//         } else {
//           if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
//             props.serchClick(props.tabs[tabIndex].data[index]);
//           }
//           props.navigate(`${props.tabs[tabIndex].route}/${index}`);
//         }
//       }
//     }
//   };
//   const handleSelect = (e, selctedData) => {
//     var newData = selctedData ? selctedData : e.target.value;

//     var newValue = newData.props && newData.props.value && newData.props.value ? newData.props.value : newData;
//     setSelctedData(newValue);
//     if (typeof props.selectHandler === "function") {
//       props.selectHandler(newValue);
//     }
//     if (props.tabs[tabIndex].searchArr) {
//       setIndex(-1);
//     } else {
//       const index = props.tabs[tabIndex].data.findIndex((e) => e === newValue);
//       if (index > -1) {
//         setIndex(index);
//         if (props.setOpen) props.setOpen(false);
//         if (props.setIndex) props.setIndex(index);
//       }
//     }
//   };

//   const handleTabsChange = (e, value) => {
//     setTab(value);
//     setIndex(-1);
//     setSearchedData(null);
//     if (props.setTab) props.setTab(value);
//     if (props.setIndex) props.setIndex(-1);
//   };

//   const listClickHandler = (index) => {
//     setIndex(index);
//     if (props.setOpen) props.setOpen(false);
//     if (props.setIndex) props.setIndex(index);
//     if (props.setSwipeableIndex) props.setSwipeableIndex(1);
//     if (typeof props.listClickHandler === "function" && props.tabs[tabIndex]) {
//       props.listClickHandler(props.tabs[tabIndex].data[index]);
//     }

//     props.navigate(`${props.tabs[tabIndex].route}/${index}`);
//   };

//   useEffect(() => {
//     if (props.swipeableIndex === 0) {
//       setIndex(-1);
//       setSearchedData(null);
//     }
//   }, [props.swipeableIndex]);
//   useEffect(() => {
//     if ((props.loading && !props.loading === false) || !props.loading) {
//       if (
//         selectedIndex === -1 &&
//         props.params &&
//         props.params.id &&
//         typeof props.listClickHandler === "function" &&
//         props.tabs[tabIndex].data.length > 0 //&&
//         // props.loading&&!props.loading===false,
//         // props.listItemREfresh&& props.listItemREfresh===false
//       ) {
//         setIndex(Number(props.params && props.params.id));
//         listClickHandler(Number(props.params && props.params.id));
//       }
//     }
//   }, []); //props.loading
//   return (
//     <>
//       {props.RaisedSelectBar ? (
//         <RaisedSelectBar
//           hintText={props.RaisedSelectBar.hintText || "Search"}
//           labelField={props.RaisedSelectBar.labelField}
//           handleSearch={handleSelect}
//           selctedData={selctedData}
//           data={props.RaisedSelectBar.data || props.tabs[tabIndex].data}
//         />
//       ) : (
//         ""
//       )}
//       {props.RaisedMonthBar ? (
//         <RaisedMonthBar
//           hintText={props.RaisedMonthBar.hintText || "Search"}
//           labelField={props.RaisedMonthBar.labelField}
//           handleSearch={handleSearchMonth}
//           searchedData={searchedData ? searchedData : new Date()}
//         />
//       ) : props.RaisedSearchBar ? (
//         <RaisedSearchBar
//           hintText={props.RaisedSearchBar.hintText || "Search"}
//           labelField={props.RaisedSearchBar.labelField}
//           handleSearch={handleSearch}
//           searchedData={searchedData}
//           data={props.RaisedSearchBar.data || props.tabs[tabIndex].data}
//         />
//       ) : (
//         props.search && (
//           <SearchBar
//             hintText={props.search.hintText || "Search"}
//             labelField={props.search.labelField}
//             handleSearch={handleSearch}
//             searchedData={searchedData}
//             data={props.search.data || props.tabs[tabIndex].data}
//           />
//         )
//       )}

//       <div
//         className="noTrack"
//         style={styles.container(
//           props.isMobile,
//           props.search,
//           props.RaisedSearchBar || props.RaisedSelectBar || props.RaisedMonthBar
//         )}id={"scroll"}
//       >
//         {props.noTab ? (
//           ""
//         ) : (
//           <Paper square style={styles.paper}>
//             <Tabs value={tabIndex} indicatorColor="primary" color="white" onChange={handleTabsChange}>
//               {props.tabs.map((tab, index) => (
//                 <Tooltip key={`${"Tooltip"}-${index}`} title={tab.tooltip} placement="top">
//                   <Tab
//                     {...a11yProps(index)}
//                     key={`TAB-${tab}-${index}`}
//                     label={tab.label}
//                     style={styles.tab(props.isMobile, props.tabs.length)}
//                   />
//                 </Tooltip>
//               ))}
//             </Tabs>
//           </Paper>
//         )}
//         {props.loading ? (
//           <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
//             <StyledCircularProgress />
//           </div>
//         ) : props.noList ? (
//           <div
//             style={{
//               justifyContent: "center",
//               alignItems: "center",
//               display: "flex",
//               height: "60%",
//               color: "#998f8f",
//               fontSize: "2rem",
//             }}
//           >
//             <span> Coming Soon</span>
//           </div>
//         ) : (
//           <>
//             {props.tabs.map((tab, i) => (
//               <TabPanel key={`${"TabPanel"}-${i}`} value={tabIndex} index={i}>
//                 {props && props.tabs && props.tabs[i] && props.tabs[i].data && props.tabs[i].data.length > 0 ? (
//                   <List key={`${"List"}-${i}`} disablePadding >
//                     {props.tabs[i].data.map((menu, index) => {
//                      return  <ListItem
//                         primaryField={tab.primaryField}
//                         secondaryField={tab.secondaryField}
//                         unit={tab.unit ? (menu["unit"] ? menu["unit"] : "") : ""}
//                         primarySecondValue={tab.dispatchList ? menu[tab.primarySecondValue] : ""}
//                         key={`ListItem-${i}-${index}`}
//                         selectedIndex={selectedIndex}
//                         index={index}
//                         is_finalised={tab.is_finalised ? menu[tab.is_finalised] : false}
//                         dispatchList={tab.dispatchList}
//                         progressBar={tab.progressBar}
//                         salesList={tab.salesList}
//                         listClickHandler={listClickHandler}
//                         primary={menu[tab.primaryField] || menu[tab.primaryFieldFallback]}
//                         primaryFieldThirdFallback={
//                           tab.salesList
//                             ? menu[tab.primaryFieldThirdFallback]
//                             : tab.dispatchList
//                             ? menu[tab.secondaryField]
//                             : ""
//                         }
//                         secondary={
//                           tab.secondaryField === "is_active"
//                             ? menu[tab.secondaryField] === true
//                               ? "Active"
//                               : menu[tab.secondaryField] === false
//                               ? "Inactive"
//                               : ""
//                             : menu[tab.secondaryField]
//                         }
//                         avatar={menu[tab.avatarField]}
//                         color={tab.dispatchList?
//                           tab.pendingStatus&& menu[tab.pendingStatus] === "PENDING"?"red":"":
//                           tab.secondaryField === "is_active"
//                             ? menu[tab.secondaryField] === true
//                               ? "#1a9520"
//                               : menu[tab.secondaryField] === false
//                               ? "#FF3D00"
//                               : "grey"
//                             : "grey"

//                         }
//                       />
//                       })}
//                       {
//                         pagination_loading ? (
//                           <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
//                             <StyledCircularProgress />
//                           </div>
//                         ) :""
//                       }
//                     <div style={{ margin: "101px 0 0 0" }} />
//                   </List>
//                 ) : (
//                   <div className="d-flex width-100 height-100 justifyC alignC">No data yet!</div>
//                 )}
//               </TabPanel>
//             ))}
//           </>
//         )}
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "./ListItem";
import SearchBar from "./SearchBar";
import "./styles.css";
import { mostLightGrey, primary } from "../styles/colors";
import RaisedSearchBar from "./RaisedSearchBar";
import RaisedSelectBar from "./RaisedSelectBar";
import RaisedMonthBar from "./RaisedMonthBar";

import { CssTextField, CssTextField85, StyledCircularProgress } from "../styles/StyledComponents";
import { mediumScreen } from "../Regex";
import { MuiDateRangePicker } from "./DateRangePicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Chip, IconButton, InputAdornment, Typography } from "@material-ui/core";
import moment from "moment";
import { TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { SubMenuListPOSkeleton, SubMenuListSkeleton, SubMenuListSOSkeleton } from "./ShowSkeleton";

const styles = {
  fab: { position: "absolute", bottom: 16, right: 16 },
  tab: (isMobile, length) => ({
    minWidth: isMobile ? `${100 / length}%` : 291.47 / length,
  }),
  paper: { color: primary, backgroundColor: "#f8f8f8" },
  container: (isMobile, search, RaisedSearchBar,secondTabData) => ({
    height: isMobile
      ? search || RaisedSearchBar
        ? "calc(100% - 56px)"
        : "100%"
      : search || RaisedSearchBar
      ? "calc(100% - 72px)"
      : "100%",
    maxHeight: Boolean(secondTabData)
      ? "calc(100vh - 170px)"
      :"inherit",
    overflow: "auto",
    // unicodeBidi: "bidi-override",
    // direction: "rtl",
    margin: "0 2px",
    width: "calc(100% - 4px)",
  }),
  dateButtonsGroup: {
    width: 20,
    height: 25,
    color: primary,
  },
};

function TabPanel(props) {
  const { children, value, index } = props;
  if (value === index) return children;
  else return null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SubMenuList(props) {
  console.log(props)
  // const user = localStorage.getItem("ssipluser") ? JSON.parse(localStorage.getItem("ssipluser")) : "";
  const [selectedIndex, setIndex] = useState(-1);
  const [tabIndex, setTab] = useState(0);
  const [searchedData, setSearchedData] = useState(null);

  const [fetched, setFetched] = useState(false);
  const [selctedData, setSelctedData] = useState(null);

  const [pagination_loading, setLoad] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [dateRangeTitle, setDateRangeTitle] = useState("");
  const [searchApiText, setSearchApiText] = useState("");

  const toggle = () => setDatePickerOpen(!datePickerOpen);

  const handleDateChange = (date, callBackFunction) => {
    if(date?.startDate || date?.endDate){
      setDateRangeTitle(
        `Date ${moment(date?.startDate).format("DD-MMM-YY")} to ${moment(
          date?.endDate
        ).format("DD-MM-YY")}`
      );
      callBackFunction &&
        callBackFunction({
          startDate: moment(date?.startDate).format("YYYY-MM-DD"),
          endDate: moment(date?.endDate).format("YYYY-MM-DD"),
        });
      setDateRange(date);
      setDatePickerOpen(!datePickerOpen);
    }
    else{
      setDateRangeTitle("") 
      callBackFunction && callBackFunction({
        startDate: "",
        endDate: "",
      });
      setDateRange({});
    }
  };

  function isScrolledToBottom(element) {
    if (!element) return false;
    return (
      // ((element.scrollHeight) - Math.round(element.scrollTop)) === element.clientHeight)||
      element.scrollHeight -
        Math.round(element.scrollTop) -
        element.clientHeight <
      2
    );
  }
  useEffect(() => {
    if (props.pagination) {
      const { count, loadMore, next, currentLength } = props.pagination;
      const scrollElement = document.getElementById("scroll");
      // setLoad(false)
      const handleScroll = () => {
        // console.log("SCROLL",scrollElement, isScrolledToBottom(scrollElement),props.pagination.next, next,count,currentLength, !props.loading,!pagination_loading )
        if (isScrolledToBottom(scrollElement)) {
          if (next && count > currentLength && !props.loading) {
            console.log("HERE SCROLL");
            // setLoad(true)
            loadMore();
          }
        }
      };

      if (scrollElement) {
        scrollElement.addEventListener("scroll", handleScroll);
        return () => {
          scrollElement.removeEventListener("scroll", handleScroll);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pagination]);
  const handleSearchMonth = (newValue) => {
    setSearchedData(newValue);
    if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
      props.serchClick(newValue);
    }
  };
  useEffect(() => {
    if (props.RaisedSelectBar) {
      if (props.RaisedSelectBar.selctedData) {
        setSelctedData(props.RaisedSelectBar.selctedData);
        handleSelect("", props.RaisedSelectBar.selctedData);
      }
    }
  }, []);
  useEffect(() => {
    if (props.RaisedMonthBar) {
      handleSearchMonth(new Date());
    }
  }, []);

  useEffect(() => {
    let reload = JSON.parse(localStorage.getItem("reloadComp"));
    console.log(reload, "useEff", props.tabs[tabIndex].data);
    if(reload) {
      setFetched(false);
      localStorage.setItem("reloadComp", false);
    }

  }, [JSON.parse(localStorage.getItem("reloadComp"))])
  const handleSearch = (e, newValue) => {
    if (props.tabs[tabIndex].searchArr) {
      setIndex(-1);

      setSearchedData(newValue);

      if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
        props.serchClick(newValue);
      }
      if (props.noAppBar) {
      } else {
        props.navigate(`${props.tabs[tabIndex].goBackRoute}`);
      }
    } else {
      const index = props.tabs[tabIndex].data.findIndex((e) => e === newValue);
      if (index > -1) {
        setSearchedData(newValue);
        setIndex(index);
        if (props.setOpen) props.setOpen(false);
        if (props.setIndex) props.setIndex(index);

        if (props.setSwipeableIndex) {
          setTimeout(() => {
            props.setSwipeableIndex(1);
            if (
              typeof props.serchClick === "function" &&
              props.tabs[tabIndex]
            ) {
              props.serchClick(props.tabs[tabIndex].data[index]);
            }
            if (props.noAppBar) {
            } else {
              props.navigate(`${props.tabs[tabIndex].route}/${index}`);
            }
          }, 350);
        } else {
          if (typeof props.serchClick === "function" && props.tabs[tabIndex]) {
            props.serchClick(props.tabs[tabIndex].data[index]);
          }
          if (props.noAppBar) {
          } else {
            props.navigate(`${props.tabs[tabIndex].route}/${index}`);
          }
        }
      }
    }
  };
  const handleSelect = (e, selctedData) => {
    console.log(selctedData,e?.target?.value, "selctedData");
    var newData = selctedData ? selctedData : e.target.value;

    var newValue =
      newData.props && newData.props.value && newData.props.value
        ? newData.props.value
        : newData;
    console.log(newValue, "newValue");
    setSelctedData(newValue);
    if (typeof props.selectHandler === "function") {
      props.selectHandler(newValue);
    }
    if (props.tabs[tabIndex].searchArr) {
      setIndex(-1);
    } else {
      const index = props.tabs[tabIndex].data.findIndex((e) => e === newValue);
      if (index > -1) {
        setIndex(index);
        if (props.setOpen) props.setOpen(false);
        if (props.setIndex) props.setIndex(index);
      }
    }
  };

  const handleTabsChange = (e, value) => {
    setTab(value);
    setIndex(-1);
    setSearchedData(null);
    if (props.setTab) props.setTab(value);
    if (props.setIndex) props.setIndex(-1);
  };

  const listClickHandler = (index, data="") => {
    console.log(index, data, props.tabs, window.location.pathname.includes('PurchaseOrder'))
    setIndex(index);
    if (props.setOpen) props.setOpen(false);
    if (props.setIndex) props.setIndex(index);
    if (props.setSwipeableIndex) props.setSwipeableIndex(1);
    if (typeof props.listClickHandler === "function" && props.tabs[tabIndex]) {
      props.listClickHandler(props.tabs[tabIndex].data[index], 'subMenuList');
    }
    if (props.noAppBar) {
    } else {
      console.log("navigated to ", `${props.tabs[tabIndex].route}/${index}`)
      props.navigate(`${props.tabs[tabIndex].route}/${index}`);
      // props.navigate(`${props.tabs[tabIndex].route}/${window.location.pathname.includes('PurchaseOrder') ? data?.id : index}`);
    }
  };

  useEffect(() => {
    if (props.registerListClickHandler) {
      // props.navigate(`${props.tabs[tabIndex]?.goBackRoute}`);
      props.registerListClickHandler(listClickHandler);
    }
  }, [props.registerListClickHandler]);

  useEffect(() => {
    if (props.swipeableIndex === 0) {
      setIndex(-1);
      setSearchedData(null);
    }
  }, [props.swipeableIndex]);
  useEffect(() => {
    if ((props.loading && !props.loading === false) || !props.loading) {
      if (
        selectedIndex === -1 &&
        props.params &&
        props.params.id &&
        typeof props.listClickHandler === "function" &&
        props.tabs[tabIndex].data.length > 0 //&&
        // props.loading&&!props.loading===false,
        // props.listItemREfresh&& props.listItemREfresh===false
      ) {
        setIndex(Number(props.params && props.params.id));
        listClickHandler(Number(props.params && props.params.id));
      }
    }
  }, []); //props.loading

  const handleChangeSearchAPI = (value, callBackFunction) => {
    console.log(value);
    setSearchApiText(value);
    callBackFunction && callBackFunction(value);
  };

  useEffect(() => {
    if (props.RaisedSelectBar?.newSelectedData) {
      console.log("RaisedSelectBar", props.RaisedSelectBar);
      setSelctedData(props.RaisedSelectBar?.newSelectedData);
    }
  }, [props.RaisedSelectBar?.newSelectedData]);

  return (
    <>
      {props.RaisedSelectBar && !Boolean(props.dateRange?.datePicker) ? (
        <RaisedSelectBar
          hintText={props.RaisedSelectBar.hintText || "Search"}
          labelField={props.RaisedSelectBar.labelField}
          handleSearch={handleSelect}
          selctedData={selctedData}
          data={props.RaisedSelectBar.data || props.tabs[tabIndex].data}
        />
        ) : props.RaisedSelectBar && Boolean(props.dateRange?.datePicker) ? (
          <>
            <div
              className="d-flex justifySB alignC"
              style={{ padding: "0 8px 0 0px",  margin: '0px 0 6px 0', }}
            >
              <RaisedSelectBar
                hintText={props.RaisedSelectBar.hintText || "Search"}
                labelField={props.RaisedSelectBar.labelField}
                handleSearch={handleSelect}
                selctedData={selctedData}
                data={props.RaisedSelectBar.data || props.tabs[tabIndex].data}
              />
              <div style={{ paddingTop: 10 }}>
                <IconButton
                  style={styles.dateButtonsGroup}
                  disableAutoFocus={true}  // Prevent focusing when the menu opens
                  disableEnforceFocus={true}
                  onClick={(e) => {
                    setDatePickerOpen(!datePickerOpen);
                  }}
                >
                  <DateRangeIcon color="grey" />
                </IconButton>

                <MuiDateRangePicker
                  open={datePickerOpen}
                  toggle={toggle}
                  dateRange={dateRange}
                  onDateRangeChange={(e) =>
                    handleDateChange(e, props.dateRange?.onChangeDateSelect)
                  }
                />
              </div>
            </div>
            <div>
              { dateRangeTitle &&
                <Chip 
                  label={dateRangeTitle} 
                  style={{
                    fontSize: '0.7rem',
                    fontFamily: 'Poppins',
                    paddingLeft: 5,
                    transform: 'scale(0.9)'
                  }}
                  onDelete={() => {
                    handleDateChange(null, props.dateRange?.onChangeDateSelect)
                  }} 
                />
              }
            </div>
          </>
      ) : (
        ""
      )}
      {props.RaisedMonthBar ? (
        <RaisedMonthBar
          hintText={props.RaisedMonthBar.hintText || "Search"}
          labelField={props.RaisedMonthBar.labelField}
          handleSearch={handleSearchMonth}
          searchedData={searchedData ? searchedData : new Date()}
        />
      ) : props.RaisedSearchBar ? (
        <RaisedSearchBar
          chatSearch={props.RaisedSearchBar.chatSearch ?? undefined}
          hintText={props.RaisedSearchBar.hintText || "Search"}
          labelField={props.RaisedSearchBar.labelField}
          handleSearch={handleSearch}
          searchedData={searchedData}
          data={props.RaisedSearchBar.data || props.tabs[tabIndex].data}
        />
      ) : (
        props.search && (
          <SearchBar
            hintText={props.search.hintText || "Search"}
            labelField={props.search.labelField}
            handleSearch={handleSearch}
            searchedData={searchedData}
            data={props.search.data || props.tabs[tabIndex].data}
          />
        )
      )}
      {props.searchData?.search ? (
          <CssTextField85
            size="small"
            placeholder="Search"
            value={searchApiText}
            onChange={(e) =>
              handleChangeSearchAPI(
                e.target.value,
                props.searchData?.onChangeSearchSelect
              )
            }
            variant="outlined"
            style={{ 
              width: '97%', 
              background: '#FFF',
              padding: "0 5px",  margin: '6px 1px 6px 1px',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ transform: "scale(0.8)" }}
                >
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    transform: "scale(0.8)",
                    display: !Boolean(searchApiText) && "none",
                  }}
                  onClick={() => {
                    handleChangeSearchAPI(
                      "",
                      props.searchData?.onChangeSearchSelect
                    );
                  }}
                >
                  <CloseIcon />
                </InputAdornment>
              ),
              style: { 
                fontSize: 14, 
                border: '1px solid #ccc',
                borderRadius: '10px', 
                top: -6,
                bottom: -1,
                left: -1,
                right: -1,
              },
            }}
          />
          
      ) : (
        ""
      )}

      <div
        className="noTrack"
        style={styles.container(
          props.isMobile,
          props.search,
          props.RaisedSearchBar || props.RaisedSelectBar || props.RaisedMonthBar,
          props.secondTabData?.array
        )}
        id={"scroll"}
      >
        {props.noTab ? (
          ""
        ) : (
          <Paper square style={styles.paper}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              color="white"
              onChange={handleTabsChange}
            >
              {props.tabs.map((tab, index) => (
                <Tooltip
                  key={`${"Tooltip"}-${index}`}
                  title={tab.tooltip}
                  placement="top"
                >
                  <Tab
                    {...a11yProps(index)}
                    key={`TAB-${tab}-${index}`}
                    label={tab.label}
                    style={styles.tab(props.isMobile, props.tabs.length)}
                  />
                </Tooltip>
              ))}
            </Tabs>
          </Paper>
        )}
        {props.loading ? (
          <div
            className="alignC justiyC d-flex"
            style={{ width: "100%", height: "100%" }}
          >
          {props.pathname.includes("/SalesOrders")
            ? <SubMenuListSOSkeleton tileLength={3} />
            : props.pathname.includes("/PurchaseOrders")
            ? <SubMenuListPOSkeleton tileLength={3} />
            : <SubMenuListSkeleton tileLength={3} />
          }
            {/* <StyledCircularProgress /> */}
          </div>
        ) : props.noList ? (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "60%",
              color: "#998f8f",
              fontSize: "2rem",
            }}
          >
            <span> Coming Soon</span>
          </div>
        ) : (
          <>
          {console.log("props", props.tabs)}
            {props.tabs.map((tab, i) => (
              <TabPanel key={`${"TabPanel"}-${i}`} value={tabIndex} index={i}>
                {props &&
                props.tabs &&
                props.tabs[i] &&
                props.tabs[i].data &&
                props.tabs[i].data.length > 0 ? (
                  <List key={`${"List"}-${i}`} disablePadding>
                    {props.tabs[i].data.map((menu, index) => (
                      <ListItem
                        primaryField={tab.primaryField}
                        secondaryField={tab.secondaryField}
                        unit={
                          tab.unit ? (menu["unit"] ? menu["unit"] : "") : ""
                        }
                        primarySecondValue={
                          tab.dispatchList ? menu[tab.primarySecondValue] : ""
                        }
                        key={`ListItem-${i}-${index}`}
                        selectedIndex={selectedIndex}
                        index={index}
                        is_finalised={
                          tab.is_finalised ? menu[tab.is_finalised] : false
                        }
                        dispatchList={tab.dispatchList}
                        progressBar={tab.progressBar}
                        salesList={tab.salesList}
                        onlySecondary={tab.onlySecondary}
                        listClickHandler={listClickHandler}
                        primaryTitle={menu[tab?.primaryTitle] || null}
                        primary={
                          menu[tab.primaryField] ||
                          menu[tab.primaryFieldFallback]
                        }
                        primaryFieldThirdFallback={
                          tab.salesList
                            ? menu[tab.primaryFieldThirdFallback]
                            : tab.dispatchList
                            ? menu[tab.secondaryField]
                            : ""
                        }
                        secondary={
                          tab.secondaryField === "is_active"
                            ? menu[tab.secondaryField] === true
                              ? "Active"
                              : menu[tab.secondaryField] === false
                              ? "Inactive"
                              : ""
                            : menu[tab.secondaryField]
                        }
                        secondLineUI={props.secondLineUI}
                        avatar={menu[tab.avatarField]}
                        color={
                          tab.secondaryField === "is_active"
                            ? menu[tab.secondaryField] === true
                              ? "rgb(241 154 48)"
                              : menu[tab.secondaryField] === false
                              ? "#FF3D00"
                              : "grey"
                            : "grey"
                          // tab.decorators.colors[
                          //   tab.decorators.options.findIndex(
                          //     (e) => e === menu[tab.decorators.conditionField]
                          //   )
                          // ]
                        }
                        selectedStatus={props?.selectedStatus}
                        itemData={menu}
                      />
                    ))}
                    {pagination_loading ? (
                      <div
                        className="alignC justiyC d-flex"
                        style={{ width: "100%", height: "100%" }}
                      >
                      {props.pathname.includes("/SalesOrders")
                        ? <SubMenuListSOSkeleton tileLength={3} />
                        : props.pathname.includes("/PurchaseOrders")
                        ? <SubMenuListPOSkeleton tileLength={3} />
                        : <SubMenuListSkeleton tileLength={3} />
                      }
                        {/* <StyledCircularProgress /> */}
                      </div>
                    ) : (
                      ""
                    )}
                    <div style={{ margin: "101px 0 0 0" }} />
                  </List>
                ) : (
                  <div className="d-flex width-100 height-100 justifyC alignC">
                    No data yet!
                  </div>
                )}
              </TabPanel>
            ))}
          </>
        )}
      </div>
    </>
  );
}
