import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ReactDOM from "react-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { primary } from "../styles/colors";
import { connect } from "react-redux";
import StaticVersionDisplay from "./StaticVersionDisplay";
import { Divider } from "@material-ui/core";
import ProjectName from "./ProjectName";
import RightIcon from "./RightIcon";
import routes from "../routes/sidebar";
import { Version } from "../Defaults";
import { withStyles } from "@material-ui/core/styles";
const drawerWidth = 240;

const CssListItem = withStyles({
  root: {
    "& label.Mui-focused": {
      border: "none",
    },
    //   "&.MuiDrawer-paperAnchorDockedLeft": {
    //     // border-right: 1px solid rgba(0, 0, 0, 0.12); */
    //    boxShadow:"0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
    // },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
    },

    overflowX: "hidden",
  },
})(ListItem);
const CssDivider = withStyles({
  root: {
    "&.MuiDivider-middle": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
})(Divider);

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedClose: {
    paddingLeft: theme.spacing(3),
  },
  // position: 'absolute',
  // bottom: 0,
  // right: 5,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor:
      "transparent linear- gradient(1deg, #3B69B0 0 %, #1E3558 100 %) 0% 0 % no - repeat padding- box",
    height: "100%",
    // boxShadow:"0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
  },
  divBigAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: "column",
    padding: 10,
    background: "white",
    borderRadius: "0 0 10px 10px",
  },
}));

const keyMap = {
  DASHBOARD: { name: "Expand square area", sequence: "1" },
  SELLORDERS: { name: "Expand square area", sequence: "2" },
  DISPATCHES: { name: "Expand square area", sequence: "3" },
  MODULES: { name: "Expand square area", sequence: "4" },
  USERANDPERMISSION: { name: "Expand square area", sequence: "5" },
};

function DrawerContent(props) {
  const classes = useStyles();

  const tofilterWIth =
    props.pathname.indexOf("/", props.pathname.indexOf("/") + 1) === -1
      ? props.pathname
      : props.pathname
          .toString()
          .slice(
            0,
            props.pathname.indexOf("/", props.pathname.indexOf("/") + 1)
          );
  const childRoutArr = [
    "/Policys",
    "/Incoterms",
    "/SOpaymentTerms",
    "/ShippingTerms",
    "/Currencys",
    "/Notifys",
    "/RiceBagSellers",
    "/Beneficiarys",
    "/Shippers",
    "/Clients",
    "/SurveyCompanys",
    "/ItemCategories",
    "/ItemTypes",
    "/PaymentTerms",
    "/ContractTypes",
    "/Packagings",
    "/HsNs",
  ];
  const childRoutArrPartners = ["/Transporter", "/Brokers", "/Millers"];
  const [isMasterOpen, setIsMasterOpen] = useState(
    childRoutArr.includes(tofilterWIth) ? true : false
  );
  const [isPartnerOpen, setIsPartnerOpen] = useState(
    childRoutArrPartners.includes(tofilterWIth) ? true : false
  );
  const [selectedMastersIndex, setSelectedMastersIndex] = useState(0);

  const [open, setOpen] = React.useState(props.open);

  // const [scrollTo, setscrollTo] = React.useState("");

  const user = localStorage.getItem("ssipluser")
    ? JSON.parse(localStorage.getItem("ssipluser"))
    : "";
  //   useEffect(() => {
  // }, [props.user])

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  //const [innerMenu, setInnerMenu] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const handlePageTitle = (title, index) => setSelectedIndex(index);

  const myRef = useRef(null);

  const handleScrollToElement = (path) => {
    if (
      childRoutArr.includes(tofilterWIth) ||
      childRoutArrPartners.includes(tofilterWIth)
    ) {
      const chat = document.getElementById(path);
      chat.scrollIntoView();
    }
  };

  useEffect(() => {
    handleScrollToElement(tofilterWIth);
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
    if (props.setOpen) props.setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    if (props.setOpen) props.setOpen(false);
  };

  const chectSelected = (path, title) => {
    const tofilterWIth =
      props.pathname.indexOf("/", props.pathname.indexOf("/") + 1) === -1
        ? props.pathname
        : props.pathname
            .toString()
            .slice(
              0,
              props.pathname.indexOf("/", props.pathname.indexOf("/") + 1)
            );

    if (
      title &&
      path === null &&
      (title === "Masters"
        ? isMasterOpen === false && childRoutArr.includes(tofilterWIth)
        : isPartnerOpen === false &&
          childRoutArrPartners.includes(tofilterWIth))
    ) {
      //&& !innerMenu && childRoutesARR.includes(tofilterWIth))
      return true;
    } else {
      if (tofilterWIth === path) {
        return true;
      } else {
        return false;
      }
    }
  };
  const selectedStyle = (path, noIcon, state) => ({
    color: chectSelected(path, state) ? primary : "white",
    background: chectSelected(path, state) ? "white" : "",
    overflow: "hidden",
    borderRadius: chectSelected(path, state) ? "8px" : "0px 7px 7px 0",
    // margin: chectSelected(path,state) ? "5px 0 5px 0" : open ? "5px" : "5px 0",
    width: chectSelected(path, state) ? "100%" : open ? "" : "109%",
    // padding: open ? "" : noIcon ? "8px 0" : "0",
    minWidth: !open ? "0" : "40px",
    justifyContent: !open ? "center" : "",
    display: !open ? "flex" : "",
    padding: "5px 18.5px",
    height: open ? "" : "42px",
  });

  return (
    <div
      style={{ height: "100%", width: "100%", borderRadius: "20px" }}
      className="d-flex d-flex-column"
    >
      {/* <GlobalHotKeys keyMap={keyMap} handlers={handleShortcut} />{' '} */}
      {
        <List disablePadding className={classes.root}>
          {open && (
            <div className={classes.divBigAvatar}>
              <img src="/BriIcon&Name.jpg" alt="loading..." />
              {/* <ProjectName {...props} /> */}
            </div>
          )}
          {open ? (
            <Divider variant="middle" />
          ) : (
            <div
              style={{
                height: "72px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <img src="/BriIcon.jpg" alt="loading..." />
            </div>
          )}
          <div
            style={{
              // height: "calc(100% - 132.35px)",
              height: open ? "calc(100% - 91.18px)" : "calc(100% - 144.35px)",
              overflowY: "auto",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            {routes
              .map((list, index) => {
                {
                  /* console.log(user, "user"); */
                }
                if (
                  user &&
                  user.accessibleModules &&
                  (user.accessibleModules.includes(list.access) ||
                    (list.title === "Partners" &&
                      list.childRoutes.find((it) =>
                        user.accessibleModules.includes(it.access)
                      )))
                ) {
                  return (
                    <div
                      key={`ListItemContainer-${index}`}
                      //style={{display:"flex",justifyContent:"center",margin:"0 8px"}}
                    >
                      <CssListItem
                        button
                        key={`ListItem-${index}`}
                        open={open}
                        onClick={(e) => {
                          e.preventDefault();

                          if (list.title === "Partners") {
                            setIsPartnerOpen(!isPartnerOpen);
                          } else if (list.title === "Masters") {
                            setIsMasterOpen(!isMasterOpen);
                          } else {
                            handlePageTitle(list.title, index);
                            props.navigate(list.path);
                          }
                        }}
                        style={selectedStyle(
                          list.path,
                          "noIcon",
                          list.title === "Partners" || list.title === "Masters"
                            ? list.title
                            : undefined
                        )}
                      >
                        <ListItemIcon
                          style={{
                            padding: open ? "" : "0",
                            minWidth: !open ? "0" : "",

                            margin: open
                              ? (list.access === "Sales Orders" ||
                                list.access === "Users & Permissions"
                                  ? "0 7px 0 -7px "
                                  : "") ||
                                (list.access === "Masters" ||
                                list.access === "Partners"
                                  ? "0 -20px 0 0px "
                                  : "")
                              : "",
                          }}
                        >
                          <Tooltip title={list.title} placement="right-start">
                            {list.icon(
                              list.path,
                              chectSelected,
                              list.title === "Partners" ||
                                list.title === "Masters"
                                ? list.title
                                : undefined
                            )}
                          </Tooltip>
                        </ListItemIcon>
                        {props.open ? (
                          list.title !== "Masters" &&
                          list.title !== "Partners" ? (
                            <ListItemText primary={list.title} />
                          ) : (
                            <>
                              <ListItemText primary={list.title} />
                              {(
                                list.title === "Masters"
                                  ? isMasterOpen
                                  : isPartnerOpen
                              ) ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </>
                          )
                        ) : null}
                      </CssListItem>

                      {(list.title === "Masters" ||
                        list.title === "Partners") && (
                        <Collapse
                          in={
                            list.title === "Masters"
                              ? isMasterOpen
                              : isPartnerOpen
                          }
                          unmountOnExit
                        >
                          <List className={classes.root}>
                            {list.childRoutes.map((item, index) => {
                              return user.accessibleModules.includes(
                                item.access
                              ) ||
                                user.accessibleModules.includes(list.access) ? (
                                <div
                                  id={item.path}
                                  key={`NestedListCont-${index}`}
                                  style={{ overflowX: "hidden" }}
                                >
                                  <CssListItem
                                    button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedMastersIndex(index);
                                      props.navigate(item.path);
                                    }}
                                    style={selectedStyle(item.path, "noIcon")}
                                  >
                                    <ListItemIcon
                                      style={{
                                        paddingLeft: props.open
                                          ? "15px"
                                          : "14px",
                                        minWidth: "51px",
                                      }}
                                    >
                                      <Tooltip
                                        title={item.title}
                                        placement="right-start"
                                      >
                                        {item.icon(item.path, chectSelected)}
                                      </Tooltip>
                                    </ListItemIcon>
                                    {props.open ? (
                                      <ListItemText
                                        style={{ marginLeft: "25px" }}
                                        primary={item.title}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </CssListItem>
                                </div>
                              ) : (
                                ""
                              );
                            })}
                          </List>
                        </Collapse>
                      )}
                    </div>
                  );
                } else return null;
              })
              .reverse()}
          </div>

          <div className={classes.toolbar}>
            <div style={{ minWidth: props.open && "45px" }}></div>
            {props.open && <StaticVersionDisplay version={Version} />}
          </div>

          <div style={{ margin: "0 50px 10px" }} />
        </List>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
