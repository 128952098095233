import React, { useEffect, useState } from "react";
import { lightGrey, primary, white } from "../../styles/colors";
import { Checkbox, InputAdornment, Paper, Step, StepLabel, Stepper } from "@mui/material";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import ShowSpecifications from "../../layoutComponents/ShowSpecifications";
import { CssTextField, StyledButton } from "../../styles/StyledComponents";
import { CrossIcon, EditIcon } from "../../styles/Icons";
import {
  CustomAutocomplete,
  CustomMultiSelectAutocomplete,
} from "../../layoutComponents/CustomAutocomplete";
import { Add } from "@material-ui/icons";
import { filterData, StopScroll } from "../../Regex";
import TableComponent from "../../layoutComponents/TableComponent";

const styles = {
  editButton: {
    background: "rgb(241 154 48)",
    color: "white",
    marginRight: "10px",
    textTransform: "capitalize",
  },
  container: {
    height: "calc(100% - 10px)",
    width: "fit-content",
    minWidth: "100%",
    overflowX: "auto",
    margin: "45px 0 0 0",
  },
  innerContainer: {
    backgroundColor: "#8080801a",
    padding: "1em 10px",
    borderRadius: "10px",
    width: "100%",
    minWidth: "100%",
  },
  deliveryTermsContainer: {
    backgroundColor: "#8080801a",
    marginTop: 2,
    padding: "1px 10px 10px",
    borderRadius: "10px",
    width: "100%",
    minWidth: "100%",
  },
  tableContainer: (width, edit) => ({
    margin: "0px 0 20px 0",
    height: "auto",
    width: width ? width : edit ? "100%" : "calc(100% - 30px)",
    minWidth: width && "100%",
    fontSize: "0.9rem",
  }),
  tablePaper: {
    height: "auto",
    width: "100%",
    borderRadius: "7px",
  },
  tableInnerContainer: {
    backgroundColor: "#8080801a",
    color: primary,
    borderColor: primary,
    width: "fit-content",
    minWidth: "100%",
    tableLayout: "fixed",
    display: "flex",
    alignItems: "center",
    height: "30px",
    minHeight: "30px",
    overflow: "hidden",
    borderRadius: "7px 7px 0 0",
  },
  tableHeader: (name, widthList, row) => ({
    margin: "12px 10px",
    width:
      widthList && widthList[row]
        ? name === "Specifications"
          ? "180px"
          : name === "Packaging"
          ? "135.49px"
          : widthList[row]
        : "fit-content",
    display: "flex",
    alignItems: "center",
    color: "#100f0f",
    textTransform: "capitalize",
  }),
  editColumn: (width) => ({
    margin: "12px 10px",
    width: width ? width : "65px",
    display: "flex",
    alignItems: "center",
    color: "#100f0f",
    textTransform: "capitalize",
  }),
  tableBody: {
    height: "fit-content",
    overflowY: "auto",
    width: "fit-content",
    minWidth: "100%",
    position: "relative",
    background: "rgb(128 128 128 / 10%)",
  },
  tableInnerBody: (name, widthList) => ({
    margin: "12px 10px",
    width:
      widthList && widthList[name]
        ? name === "Specifications"
          ? "180px"
          : name === "Packaging"
          ? "135.49px"
          : widthList[name]
        : "fit-content",
    minWidth: widthList && widthList[name] ? widthList[name] : "fit-content",
    textTransform: "capitalize",
  }),
  tableBodyEditColumn: {
    margin: "12px 10px",
    width: "65px",
    minWidth: "50px",
    textTransform: "capitalize",
  },
  dialogTitleAction: {
    background: primary,
    color: "white",
    padding: "10px 24px",
  },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  dialogFieldDiv: {
    display: "flex",
    width: "100%",
    fontSize: "1rem",
    color: "grey",
    background: "white",
    minHeight: "71.91px",
  },
  dialogItemContainer: {
    fontSize: "1rem",
    margin: "20px 0",
    fontWeight: "500",
    justifyContent: "center",
    display: "flex",
  },
  specificationContainer: {
    height: "fit-content",
    width: "100%",
    minWidth: "100%",
    position: "relative",
    borderRadius: "0 0 7px 7px",
    borderRight: "1px solid rgba(128, 128, 128, 0.64)",
    borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
    borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
    padding: "5px 0",
  },
};

const colorArr = [white, lightGrey];

const specificationHeader = [
  {
    name: "Type",
    api: "name",
    value: "name",
  },
  {
    name: "Condition",
    api: "condition",
    value: "name",
  },
  {
    name: "Value",
    api: "value",
  },
];

const MaxMin = [
  { name: "Greater Than", id: "greater_than" },
  { name: "Greater Than Or Equal To", id: "greater_than_or_equal_to" },
  { name: "Less Than", id: "less_than" },
  { name: "Not Equal To", id: "not_equal_to" },
  { name: "Equal To", id: "equal_to" },
  { name: "Less Than Or Equal To", id: "less_than_or_equal_to" },
];

export const ItemTable = ({
  edit,
  array,
  showTotal,
  width,
  editFunct = "",
  Unit = "",
  itemHeader,
  widthList,
  specifiationTypes,
}) => {
  const Header = [...itemHeader.map((d) => d.label)];

  if (edit) {
    Header.push("Action");
  }

  return (
    <>
      <TableComponent
        module={"SO Item"}
        noAddButton={true}
        noSearchBar={true}
        simpleTable={true}
        padding={"0px"}
        tableRowHeight={30}
        headerShownAlways={true}
        tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
        loader={false}
        actions={[]}
        header={Header}
        tableHeight="auto"
        tablePagination={false}
        footer={true}
        footerList={
          array?.length && {
            "Item Category": "Total",
            "Total Item Quantity":
              array?.reduce((acc, item) => {
                return Number(acc) + Number(item["Total Item Quantity"]);
              }, 0) +
              " " +
              (Unit || ""),
          }
        }
        tableList={
          array && array.length
            ? array.map((s) => {
                s["Total Item QuantityUnit"] =
                  s["Total Item Quantity"] + " " + (Unit || "");
                s["Contract RateUnit"] =
                  s["Contract Rate"] + (Unit ? "/" + Unit : "");
                s["Item TypeUnit"] = s["Item Type"] + "%";
                console.log("s", s);
                s[`Specifications`] = () => {
                  const transformedSpecifications =
                    s["Specifications_data"] &&
                    s["Specifications_data"].length > 0
                      ? s["Specifications_data"].map((sP) => {
                          const ss = sP.name;
                          const filterS = specifiationTypes?.find(
                            (st) => st.id === ss
                          );
                          return {
                            ...sP,
                            name: filterS
                              ? `${filterS.text?.toLowerCase()}`
                              : "",
                            value: filterS
                              ? `${sP.value} ${filterS.unit?.toLowerCase()}`
                              : sP.value,
                          };
                        })
                      : [];

                  return (
                    <div
                      className="d-flex widthFC alignFE"
                      style={{ margin: "0px auto" }}
                    >
                      <ShowSpecifications details={transformedSpecifications} />
                    </div>
                  );
                };
                s["PackagingUI"] = () => {
                  <ShowPackaging
                    noTotalbags={true}
                    returned_bags={false}
                    noBags={true}
                    details={
                      s[`Packaging`] && s[`Packaging`].length > 0
                        ? s[`Packaging`]
                        : []
                    }
                  />;
                };
                s["Action"] = () => {
                  return (
                    <StyledButton
                      tooltip="Edit"
                      name={<EditIcon />}
                      width="60px"
                      onClick={() => {
                        if (editFunct) {
                          editFunct(s);
                        }
                      }}
                    />
                  );
                };

                return s;
              })
            : []
        }
      />

      {/* <div style={styles.tableContainer(width, edit)}>
      {console.log("array", array)}
      <Paper
        className="d-flex d-flex-column overflowXA positionR scrollBluePrimary"
        style={styles.tablePaper}
      >
        <div style={styles.tableInnerContainer}>
          <div
            className="d-flex alignC justifySE width-100"
            style={{ background: "rgb(128 128 128 / 64%)" }}
          >
            {itemHeader &&
              itemHeader.map((name, i) => (
                <div
                  key={name}
                  className="justifyC d-flex alignC"
                  style={styles.tableHeader(
                    name.label,
                    widthList,
                    itemHeader[i].label
                  )}
                >
                  {name.label}
                </div>
              ))}
            {edit && (
              <div
                className="justifyC d-flex alignC"
                style={styles.editColumn()}
              >
                Edit
              </div>
            )}
          </div>
        </div>
        {array && array.length > 0 ? (
          <>
            <div className="scrollBluePrimary" style={styles.tableBody}>
              {array.map(
                (row, i) =>
                  row && (
                    <div
                      key={`${i}row`}
                      className="d-flex width-100 d-flex-column"
                    >
                      <div className="d-flex width-100 d-flex-column justifySE">
                        <div
                          className="d-flex width-100 justifySE dividerBorder"
                          style={{
                            backgroundColor:
                              colorArr && colorArr[i % colorArr.length],
                            height: "35px",
                          }}
                        >
                          {itemHeader &&
                            itemHeader.map((name) => (
                              <div
                                key={name + "body" + i}
                                className="d-flex alignC justifyC minWidthFC"
                                style={styles.tableInnerBody(
                                  name.label,
                                  widthList
                                )}
                              >
                                {name.label === "Packaging" &&
                                row[name.labelKey] ? (
                                  <ShowPackaging
                                    noTotalbags={true}
                                    returned_bags={false}
                                    noBags={true}
                                    details={
                                      row[`Packaging`] &&
                                      row[`Packaging`].length > 0
                                        ? row[`Packaging`]
                                        : []
                                    }
                                  />
                                ) : name.label === "Specifications" &&
                                  row[name.labelKey] ? (
                                  <ShowSpecifications
                                    details={
                                      row[name.labelKey] &&
                                      row[name.labelKey].length > 0
                                        ? row[name.labelKey].map((sP) => {
                                            const ss = sP.name;
                                            const filterS =
                                              specifiationTypes?.find(
                                                (st) => st.id === ss
                                              );
                                            return {
                                              ...sP,
                                              name: filterS
                                                ? `${filterS.text?.toLowerCase()}`
                                                : "",
                                              value: filterS
                                                ? `${
                                                    sP.value
                                                  } ${filterS.unit?.toLowerCase()}`
                                                : sP.value,
                                            };
                                          })
                                        : []
                                    }
                                  />
                                ) : row[name.labelKey] ? (
                                  name.labelKey === "Item Type" ? (
                                    `${row[name.labelKey]} %`
                                  ) : (
                                    row[name.labelKey]
                                  )
                                ) : (
                                  "-"
                                )}
                              </div>
                            ))}
                          {edit && (
                            <div
                              className="d-flex alignC justifyC minWidthFC"
                              style={styles.tableBodyEditColumn}
                            >
                              <StyledButton
                                tooltip="Edit"
                                name={<EditIcon />}
                                width="60px"
                                onClick={() => {
                                  if (editFunct) {
                                    editFunct(row);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            {showTotal && (
              <div className="d-flex alignC width-100">
                {itemHeader &&
                  itemHeader.map((name, i) => (
                    <div
                      key={name.label}
                      className="justifyC d-flex alignC"
                      style={styles.tableInnerBody(name.label, widthList)}
                    >
                      {i === 0 ? (
                        <span style={{ fontWeight: "500" }}>{"Total"}</span>
                      ) : name.label === "Total Item Quantity" ? (
                        `${array.reduce(
                          (previousValue1, currentValue1) =>
                            Number(previousValue1) +
                            (currentValue1["Total Item Quantity"]
                              ? Number(currentValue1["Total Item Quantity"])
                              : 0),
                          0
                        )} ${Unit} `
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                {edit && (
                  <div
                    className="justifyC d-flex alignC"
                    style={styles.editColumn()}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
            <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
              No SO Item added !
            </span>
          </div>
        )}
      </Paper>
    </div> */}
    </>
  );
};

const SpecificationTable = ({
  specificationHeader,
  specificationArr,
  specifiationTypes,
  handleResetItemRow,
  handleSpecificationAddition,
}) => {
  const [editSpecificationRow, setEditSpecificationRow] = useState({
    id: "",
    name: null,
    value: "",
    condition: null,
    so_items: null,
  });

  const [editSpecRowId, setEditSpecRowId] = useState(null);
  const [editSpecRowError, setEditSpecRowError] = useState({
    name: "",
    value: "",
    condition: "",
  });

  return (
    <div className="d-flex width-100 flexWrap justifyC">
      <div className="width-100 " style={{ margin: "20px 10px" }}>
        <div style={styles.tableInnerContainer}>
          <div
            className="d-flex alignC justifySE width-100"
            style={{ background: "rgb(128 128 128 / 64%)" }}
          >
            {specificationHeader.map((sC) => (
              <div
                key={sC.name}
                className="justifyC d-flex alignC"
                style={styles.editColumn("calc(25% - 54px)")}
              >
                {sC.name}
              </div>
            ))}
            <div
              className="justifyC d-flex alignC"
              style={styles.editColumn(82)}
            >
              Action
            </div>
          </div>
        </div>
        {console.log("specificationArr", specificationArr)}
        <div style={styles.specificationContainer}>
          {specificationArr &&
            specificationArr.map((sA, i) => (
              <div
                className={`dividerBorder d-flex width-100 justifySE `}
                style={{
                  backgroundColor: colorArr && colorArr[i % colorArr.length],
                  height: "44px",
                }}
              >
                {console.log("sA", sA)}
                {specificationHeader.map((sC) => (
                  <div
                    key={sC.name}
                    className="justifyC d-flex alignC"
                    style={styles.editColumn("calc(25% - 54px)")}
                  >
                    {editSpecRowId !== sA?.id
                      ? sC.name === "Type"
                        ? filterData(
                            specifiationTypes,
                            "id",
                            sA.name,
                            "onlyOne"
                          )?.text || ""
                        : sC.name === "Condition"
                        ? MaxMin.find((d) => d.id === sA.condition)?.name || "-"
                        : sA.value
                      : null}
                  </div>
                ))}
                <div
                  className="justifyC d-flex alignC"
                  style={styles.editColumn(82)}
                  onClick={() => {
                    setEditSpecRowId(sA.id);
                    setEditSpecificationRow({
                      id:
                        sA.id || `${sA.name}-${sA.condition || 0}-${sA.value}`,
                      name: filterData(
                        specifiationTypes,
                        "id",
                        sA.name,
                        "onlyOne"
                      ),
                      value: sA["value"],
                      condition: sA["condition"]
                        ? filterData(MaxMin, "id", sA.condition, "onlyOne")
                        : null,
                      so_items: sA.so_items,
                      operation: "update",
                    });
                  }}
                >
                  <StyledButton
                    name={<EditIcon />}
                    toolTip="Edit"
                    maxHeight="24px"
                    padding="0"
                    height="24px"
                    width="40px"
                    minWidth="0"
                  />
                </div>
              </div>
            ))}
          <div
            className="d-flex width-100 justifySE"
            style={{
              backgroundColor:
                colorArr &&
                colorArr[specificationArr?.length % colorArr?.length],
              minHeight: "60px",
            }}
          >
            <CustomAutocomplete
              id="Type"
              required={true}
              autoComplete="off"
              options={specifiationTypes || []}
              getOptionLabel={(option) => option.text || ""}
              value={editSpecificationRow?.name || null}
              error={editSpecRowError?.name ? true : false}
              onChange={(e, newValue) => {
                setEditSpecificationRow({
                  ...editSpecificationRow,
                  name: newValue,
                });
              }}
              style={
                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
              }
              label="Type"
            />
            <CustomAutocomplete
              id="Condition"
              required={
                editSpecificationRow?.name?.value_type === "number"
                  ? true
                  : false
              }
              autoComplete="off"
              disabled={editSpecificationRow?.name?.value_type !== "number"}
              options={
                editSpecificationRow?.name?.value_type === "number"
                  ? MaxMin
                  : []
              }
              getOptionLabel={(option) => option.name || ""}
              value={editSpecificationRow?.condition || null}
              error={editSpecRowError?.condition ? true : false}
              onChange={(e, newValue) => {
                setEditSpecificationRow({
                  ...editSpecificationRow,
                  condition: newValue,
                });
              }}
              style={
                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
              }
              label={
                editSpecificationRow?.name?.value_type !== "number"
                  ? "No Condition"
                  : "Condition"
              }
            />
            <CssTextField
              required={true}
              type="Value"
              autoComplete="off"
              id="Value"
              label="Value"
              style={
                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
              }
              variant="outlined"
              name="ShipmentBy"
              value={editSpecificationRow?.value || ""}
              onChange={(e) => {
                setEditSpecificationRow({
                  ...editSpecificationRow,
                  value: e.target.value,
                });
              }}
              error={editSpecRowError?.value ? true : false}
            />
            <div
              className="justifySA d-flex"
              style={{
                margin: "10px",
                width: "82px",
                display: "flex",
                color: "#100f0f",
                textTransform: "capitalize",
              }}
            >
              <StyledButton
                name={<Add />}
                toolTip="Add"
                maxHeight="37.99px"
                padding="0"
                height="37.99px"
                width="40px"
                minWidth="0"
                onClick={() => {
                  if (
                    Object.values(editSpecificationRow).every(
                      (v) => v === "" || v === null || v === undefined
                    )
                  ) {
                    setEditSpecRowError({
                      name: true,
                      condition: true,
                      value: true,
                    });
                    return;
                  }
                  let hasError = false;
                  if (editSpecificationRow?.name) {
                    if (
                      editSpecificationRow?.name?.value_type === "number" &&
                      !editSpecificationRow?.condition
                    ) {
                      setEditSpecRowError((prev) => ({
                        ...prev,
                        condition: true,
                      }));
                      hasError = true;
                    }
                    if (!editSpecificationRow?.value) {
                      setEditSpecRowError((prev) => ({ ...prev, value: true }));
                      hasError = true;
                    }
                  } else if (
                    editSpecificationRow?.condition ||
                    editSpecificationRow?.value
                  ) {
                    setEditSpecRowError((prev) => ({ ...prev, name: true }));
                    hasError = true;
                  }
                  if (
                    editSpecificationRow?.name ||
                    editSpecificationRow?.condition
                  ) {
                    if (!editSpecificationRow?.value) {
                      setEditSpecRowError((prev) => ({ ...prev, value: true }));
                      hasError = true;
                    }
                  }
                  if (!hasError) {
                    let d = { ...editSpecificationRow };
                    setEditSpecificationRow({
                      id: "",
                      name: null,
                      value: "",
                      condition: null,
                      so_items: "",
                    });
                    setEditSpecRowId(null);
                    setEditSpecRowError({
                      name: false,
                      condition: false,
                      value: false,
                    });
                    handleSpecificationAddition(
                      {
                        id:
                          d?.id ||
                          `${d.name?.id}-${d.condition?.id || 0}-${d.value}`,
                        name: Number(d?.name?.id),
                        value: d?.value,
                        condition: d?.condition?.id,
                        so_items: d?.so_items,
                      },
                      "single",
                      d?.id ||
                        `${d.name?.id}-${d.condition?.id || 0}-${d.value}`,
                      d?.operation
                    );
                  }
                }}
              />
              <StyledButton
                name={<CrossIcon color={primary} strokeWidth="2" />}
                toolTip="Clear"
                maxHeight="37.99px"
                onClick={() => {
                  setEditSpecRowId(null);
                  setEditSpecificationRow({
                    id: "",
                    name: null,
                    value: "",
                    condition: null,
                  });
                  setEditSpecRowError({
                    name: false,
                    condition: false,
                    value: false,
                  });
                }}
                padding="0"
                margin="0 0 0 2px"
                height="37.99px"
                width="40px"
                minWidth="0"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="update-button"
        className="d-flex alignC justifyFE width-100"
        style={{
          margin: "10px",
        }}
      >
        <StyledButton
          onClick={() => {
            setEditSpecRowError({
              name: false,
              condition: false,
              value: false,
            });
            setEditSpecificationRow({
              id: "",
              name: null,
              value: "",
              condition: null,
            });
            setEditSpecRowId(null);
            handleResetItemRow();
            handleSpecificationAddition(specificationArr, "all");
          }}
          name={`Update Item`}
          minWidth="30px"
          width="150px"
          margin="0 10px"
          withBg={true}
        />
      </div>
    </div>
  );
};

const BrandUI = ({
  required,
  BrandList,
  ItemCateoryList,
  ItemList,
  value,
  error,
  onChange,
  onBlur,
}) => {
  return (
    <CustomAutocomplete
      id="Brand"
      required={required !== undefined ? required : true}
      autoComplete="off"
      onBlur={onBlur}
      options={
        (BrandList &&
          BrandList?.sort(
            (a, b) =>
              (Boolean(a.item_category) || Boolean(a.item_type)) -
              (Boolean(b.item_category) || Boolean(b.item_type))
          )) ||
        []
      }
      getOptionLabel={(option) =>
        option?.item_category && option?.item_type
          ? `${option?.brand_name}-${
              filterData(
                ItemCateoryList,
                "id",
                option.item_category,
                "onlyName"
              ).name || ""
            }-${
              filterData(ItemList, "id", option.item_type, "onlyName")
                .broken_percentage || ""
            } %`
          : `${option.brand_name}`
      }
      value={value || null}
      error={error ? true : false}
      onChange={(e, newValue) => {
        onChange(e, newValue);
      }}
      style={
        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
      }
      label="Brand"
    />
  );
};

const ItemCategoryUI = ({
  required,
  ItemCateoryList,
  value,
  error,
  onChange,
  onBlur,
}) => {
  console.log(error, !!error);
  return (
    <CustomAutocomplete
      id="ItemCategory"
      required={required !== undefined ? required : true}
      autoComplete="off"
      onBlur={onBlur}
      options={ItemCateoryList || []}
      getOptionLabel={(option) => option?.name || ""}
      value={value || null}
      error={!!error}
      onChange={onChange}
      style={{ width: "calc(25% - 20px)", margin: "10px" }}
      label="ItemCategory"
    />
  );
};

const ItemTypeUI = ({ required, ItemList, value, error, onChange, onBlur }) => {
  return (
    <CustomAutocomplete
      id="ItemType"
      required={required !== undefined ? required : true}
      autoComplete="off"
      onBlur={onBlur}
      options={ItemList || []}
      getOptionLabel={(option) => `${option?.broken_percentage} %` || ""}
      value={value || null}
      error={!!error}
      onChange={onChange}
      style={{ width: "calc(25% - 20px)", margin: "10px" }}
      label="ItemType"
    />
  );
};

const PackagingUI = ({
  required,
  PackagingList,
  value,
  error,
  onChange,
  onBlur,
}) => {
  const options =
    PackagingList && PackagingList.length > 0
      ? PackagingList.map((sP) => {
          sP.sizeUnit = `${sP.size}${sP.unit} - ${sP.bag_type}`;
          return sP;
        })
      : [];

  return (
    <CustomMultiSelectAutocomplete
      id="packagingArr"
      required={required !== undefined ? required : true}
      autoComplete="off"
      multiple
      onBlur={onBlur}
      options={options}
      getOptionLabel={(option) => option?.sizeUnit || ""}
      value={value || null}
      error={!!error}
      onChange={onChange}
      style={{ width: "calc(25% - 20px)", margin: "10px" }}
      optionKey="sizeUnit"
      label="Packaging"
    />
  );
};

const HsnUI = ({ required, HSNlist, value, error, onChange, onBlur }) => {
  return (
    <CustomAutocomplete
      id="HSN"
      required={required !== undefined ? required : true}
      autoComplete="off"
      onBlur={onBlur}
      options={HSNlist || []}
      getOptionLabel={(option) => option?.hsn_code || ""}
      value={value}
      error={!!error}
      onChange={onChange}
      style={
        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
      }
      label="HSN"
    />
  );
};

const TotalItemQtyUI = ({ required, unit, value, error, onBlur, onChange }) => {
  return (
    <CssTextField
      required={required !== undefined ? required : true}
      type="number"
      onFocus={StopScroll}
      onBlur={onBlur}
      autoComplete="off"
      error={!!error}
      id="outlined-basic"
      label="Total Item Quantity"
      onKeyDown={(evt) => {
        evt.key === "e" && evt.preventDefault();

        evt.key === "E" && evt.preventDefault();
        evt.key === "-" && evt.preventDefault();
        evt.keyCode === 38 && evt.preventDefault();
        evt.keyCode === 40 && evt.preventDefault();
      }}
      style={{
        width: "calc(25% - 20px)",
        margin: "10px",
      }}
      InputProps={{
        endAdornment: unit || "",
      }}
      variant="outlined"
      name="total_quantity"
      value={value || ""}
      onInput={(e) => {
        e.target.value = e.target.value.toString().slice(0, 20);
      }}
      onChange={onChange}
    />
  );
};

const ContractRateUI = ({ required, unit, value, error, onBlur, onChange }) => {
  return (
    <CssTextField
      required={required !== undefined ? required : true}
      type="number"
      onFocus={StopScroll}
      onBlur={onBlur}
      autoComplete="off"
      style={{
        width: "calc(25% - 20px)",
        margin: "10px",
      }}
      onKeyDown={(evt) => {
        evt.key === "e" && evt.preventDefault();

        evt.key === "E" && evt.preventDefault();
        evt.key === "-" && evt.preventDefault();
        evt.keyCode === 38 && evt.preventDefault();
        evt.keyCode === 40 && evt.preventDefault();
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">/{unit || ""}</InputAdornment>
        ),
      }}
      error={!!error}
      id="outlined-basic"
      label="Contract Rate"
      variant="outlined"
      value={value || ""}
      onChange={onChange}
    />
  );
};

const SimpleText = ({ required, label, value, error, onChange }) => {
  return (
    <CssTextField
      required={required !== undefined ? required : true}
      autoComplete="off"
      value={value || ""}
      error={!!error}
      onChange={onChange}
      style={{
        width: "calc(25% - 20px)",
        margin: "10px",
      }}
      id="outlined-select-currency-native"
      label={label}
      variant="outlined"
      InputLabelProps={{}}
    />
  );
};

export const CustomItemTableUI = (props) => {
  const [editItemRow, setEditItemRow] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [itemStep, setItemStep] = useState(0);
  const [itemTableData, setItemTableData] = useState([]);

  const {
    specifiationTypes,
    masters,
    widthList,
    BrandList,
    ItemCateoryList,
    ItemList,
    itemHeader,
    Unit,
    // defaultItemTableData,
    loosePoItem,
    module,
  } = props;

  const arrangeItemTableData = (itemHeader, data) => {
    console.log("arrange", itemHeader, data);
    const newData = [];
    let itemsData = data;
    if (itemsData) {
      itemsData.map((item) => {
        //ex: Brand : brand shown data for table , Brand_data: brand whole data
        const newItem = {};
        itemHeader.map((header) => {
          newItem[header.label] = item[header.label] || "";
          newItem[header.labelKey] = item[header.labelKey] || "";
          newItem[`${header.label}_data`] =
            item[`${header.label}_data`] !== undefined
              ? item[`${header.label}_data`]
              : header?.array
              ? header?.array.find(
                  (d) => d[header.arrayKey] == item[header.key]
                ) || []
              : item[header?.key] || item[header?.orKey] || "";
        });
        newItem["id"] = item.id;

        newData.push(newItem);
      });
    }
    console.log("newData", newData);
    return newData;
  };

  const handleItemRowEditButton = (rowData) => {
    console.log("rowEditData", rowData);
    setEditItemRow(rowData);
  };

  const handleItemChange = (field, data) => {
    setEditItemRow((prev) => {
      return {
        ...prev,
        [field]: data,
      };
    });
  };

  const handleNext = () => {
    const hasEmptyFields = itemHeader.some(
      (field) => !editItemRow[`${field.label}_data`]
    );
    if (hasEmptyFields) {
      const newErrors = {};
      itemHeader.forEach((field) => {
        if (module === "PO") {
          if (
            field.label === "Item Category" &&
            !Boolean(editItemRow[`Item Type_data`]?.id)
          ) {
            newErrors[`Item Type_data`] = true;
          } else if (
            field.label === "Item Type" &&
            !Boolean(editItemRow[`Item Category_data`]?.id)
          ) {
            newErrors[`Item Category_data`] = true;
          }
        } else if (field.required && !editItemRow[`${field.label}_data`]) {
          newErrors[`${field.label}_data`] = true;
        }
      });
      console.log(newErrors);
      setErrorMsg((prev) => ({ ...prev, ...newErrors }));
      return !Boolean(Object.keys(newErrors).length);
    }
    return true;
  };

  const handleSpecificationAddition = (data, type, id, updatingRow = "") => {
    console.log(
      "handleSpecificationAddition",
      data,
      type,
      type === "single",
      id,
      updatingRow,
      editItemRow["Specifications_data"]
    );
    if (type === "single") {
      console.log("single");
      let so_specifications = Boolean(
        editItemRow["Specifications_data"]?.length
      )
        ? [...editItemRow["Specifications_data"]]
        : [];
      console.log(
        "so_specifications",
        so_specifications,
        editItemRow["Specifications_data"],
        id
      );
      if (id) {
        const existingIndex = so_specifications.length
          ? so_specifications.findIndex((spec) => spec.id === data.id)
          : -1;
        console.log("data", data, type, id, existingIndex);
        if (existingIndex !== -1) {
          so_specifications[existingIndex] = data;
        } else {
          so_specifications.push(data);
        }
      } else {
        console.log(updatingRow, "update");
        if (updatingRow === "update") {
          const index = so_specifications.findIndex(
            (spec) =>
              spec.name === data.name ||
              spec.id === data.id ||
              spec.value === data.value
          );

          if (index !== -1) {
            so_specifications[index] = data;
          } else {
            so_specifications.push(data);
          }
        } else {
          so_specifications.push(data);
        }
      }

      setEditItemRow({
        ...editItemRow,
        ["Specifications_data"]: so_specifications,
      });
    } else {
      console.log("UPDATE ITEM", editItemRow, itemTableData, data);

      let Obj = { ...editItemRow };

      console.log("Obj", Obj);

      itemHeader.forEach((field) => {
        const getFieldValue = (key) => {
          const fieldData = editItemRow[`${field.label}_data`];
          if (key.includes("Unit")) {
            return fieldData ? fieldData + " " + (Unit || "") : "";
          }
          if (field.apiKey) {
            return fieldData?.[field.apiKey] || "";
          }
          return fieldData || "";
        };

        console.log("field", field.label, getFieldValue(field.labelKey));
        Obj[field.labelKey] = getFieldValue(field.labelKey);
        Obj[field.label] = getFieldValue(field.label);
      });
      console.log("Obj", Obj);

      let so_items = Boolean(itemTableData) ? [...itemTableData] : [];

      if (!Obj?.id) {
        Obj.id = `${editItemRow["Item Category_data"]?.id}-${editItemRow["Item Type_data"]?.id}-${so_items?.length}`;
      }

      if (
        so_items.length &&
        so_items.find(
          (item) => item?.id === editItemRow?.id && Boolean(editItemRow?.id)
        )
      ) {
        so_items = so_items.map((item) =>
          item?.id === editItemRow?.id ? Obj : item
        );
      } else {
        console.log("PUSH", Obj);
        so_items.push(Obj);
      }

      console.log("dialogItemData", so_items);
      setItemTableData(so_items);
      props.handleCreatedEditedItemList(so_items);
      setEditItemRow({});
      setErrorMsg({});
    }
  };

  const handleResetItemRow = () => {
    setEditItemRow({});
    setItemStep(0);
  };

  useEffect(() => {
    console.log("111111111111111")
    // setItemTableData(
    //   arrangeItemTableData(
    //     itemHeader,
    //     loosePoItem?.length ? loosePoItem : defaultItemTableData,
    //   )
    // );
    // if(loosePoItem?.length === 0){
      setItemTableData(
        arrangeItemTableData(
          itemHeader,
          loosePoItem,
        )
      );
    //   props.handleCreatedEditedItemList(arrangeItemTableData(
    //     itemHeader,
    //     loosePoItem,
    //   ))
    // }else{
    //   setItemTableData(
    //     arrangeItemTableData(
    //       itemHeader,
    //       loosePoItem ,
    //     )
    //   );
    // }
  }, [loosePoItem]);

  useEffect(() => {
    return () => {
      console.log("unmount");
      setItemTableData([]);
      setEditItemRow({});
      setErrorMsg({});
      setItemStep(0);
    };
  }, []);

  return (
    <>
      <div
        style={{ border: "1px solid rgba(128, 128, 128, 0.64)" }}
        className="borderRadius7"
        id="item"
      >
        <ItemTable
          edit={true}
          array={itemTableData}
          showTotal={true}
          width={null}
          itemHeader={itemHeader}
          widthList={widthList}
          specifiationTypes={specifiationTypes}
          editFunct={handleItemRowEditButton}
          Unit={Unit}
        />
        {console.log("editItemRow", editItemRow)}
        <div style={{ margin: "20px", padding: "10px" }}>
          {itemStep === 0 ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  fontSize: "1rem",
                  color: "grey",
                  background: "white",
                  width: "100%",
                  minHeight: "71.91px",
                }}
              >
                {console.log(
                  module === "PO",
                  editItemRow[`Item Category_data`],
                  editItemRow
                )}
                {itemHeader.map((p) =>
                  p.label === "Brand" ? (
                    <BrandUI
                      BrandList={BrandList}
                      ItemCateoryList={ItemCateoryList}
                      ItemList={ItemList}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e, newValue) => {
                        handleItemChange(`${p.label}_data`, newValue);
                      }}
                      required={p.required}
                    />
                  ) : p.label === "Item Category" ? (
                    <ItemCategoryUI
                      ItemCateoryList={ItemCateoryList}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e, newValue) => {
                        handleItemChange(`${p.label}_data`, newValue);
                      }}
                      required={
                        module === "PO" && editItemRow[`Item Type_data`]?.id
                          ? true
                          : p.required
                      }
                    />
                  ) : p.label === "Item Type" ? (
                    <ItemTypeUI
                      ItemList={ItemList}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e, newValue) => {
                        handleItemChange(`${p.label}_data`, newValue);
                      }}
                      required={
                        module === "PO" && editItemRow[`Item Category_data`]?.id
                          ? true
                          : p.required
                      }
                    />
                  ) : p.label === "Packaging" ? (
                    <PackagingUI
                      PackagingList={masters.packaging}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e, newValue) => {
                        handleItemChange(`${p.label}_data`, newValue);
                      }}
                      required={p.required}
                    />
                  ) : p.label === "HSN Code" ? (
                    <HsnUI
                      HSNlist={masters["HSNlist"]}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e, newValue) => {
                        handleItemChange(`${p.label}_data`, newValue);
                      }}
                      required={p.required}
                    />
                  ) : p.label === "Total Item Quantity" ? (
                    <TotalItemQtyUI
                      unit={Unit}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e) => {
                        handleItemChange(`${p.label}_data`, e.target.value);
                      }}
                      required={p.required}
                    />
                  ) : p.label === "Contract Rate" ? (
                    <ContractRateUI
                      unit={Unit}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onBlur={(e) => {
                        setErrorMsg((prev) => ({
                          ...prev,
                          [`${p.label}_data`]: !Boolean(e.target.value),
                        }));
                      }}
                      onChange={(e) => {
                        handleItemChange(`${p.label}_data`, e.target.value);
                      }}
                      required={p.required}
                    />
                  ) : p.label === "Buyer Marking" ||
                    p.label === "Sales Tax Terms" ? (
                    <SimpleText
                      label={p.label}
                      value={editItemRow[`${p.label}_data`]}
                      error={errorMsg[`${p.label}_data`]}
                      onChange={(e) => {
                        handleItemChange(`${p.label}_data`, e.target.value);
                      }}
                      required={p.required}
                    />
                  ) : null
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "1rem",
                  color: "grey",
                  background: "white",
                  width: "100%",
                  minHeight: "71.91px",
                }}
              >
                {itemStep === 1 ? (
                  ""
                ) : (
                  <div
                    style={{
                      width: "calc(100% - 20px)",
                      margin: "10px",
                    }}
                    className="d-flex justifyFE"
                    id="update-button"
                  >
                    <StyledButton
                      name="Next"
                      background={primary}
                      margin="10px 0"
                      withBg={true}
                      onClick={() => {
                        console.log("Next", handleNext());
                        if (handleNext()) {
                          setItemStep(itemStep + 1);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <SpecificationTable
              specificationHeader={specificationHeader}
              specificationArr={editItemRow["Specifications_data"]}
              soItemId={editItemRow.id || null}
              specifiationTypes={specifiationTypes}
              handleResetItemRow={handleResetItemRow}
              handleSpecificationAddition={handleSpecificationAddition}
            />
          )}
          <Stepper activeStep={itemStep}>
            <Step
              onClick={() => {
                if (itemStep === 1) {
                  setItemStep(itemStep - 1);
                }
              }}
              style={{
                cursor: itemStep ? "pointer" : "",
                "& .MuiStepIcon-root": {
                  color: itemStep === 0 ? primary : "#ccc",
                },
              }}
            >
              <StepLabel
                style={{
                  marginLeft: "200px",
                  fontFamily: "Poppins",
                }}
                StepIconProps={{
                  style: {
                    color: primary,
                    fontFamily: "Poppins",
                  },
                }}
              >
                Add Item Details
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                style={{
                  marginRight: "200px",
                  fontFamily: "Poppins",
                }}
                StepIconProps={{
                  style: {
                    color: itemStep === 1 ? primary : "#ccc",
                    fontFamily: "Poppins",
                  },
                }}
              >
                Add Multiple Specifications
              </StepLabel>
            </Step>
          </Stepper>
        </div>
      </div>
    </>
  );
};

export const DispatchLoosePoItemTable = ({ itemData, unit, masters, handleItemCheckBoxChange }) => {
  const [dispatchItemRowCheckBox, setDispatchItemRowCheckBox] = useState([]);

  const handleItemRowCheckBox = (value, id) => {
    if(dispatchItemRowCheckBox.length === 0) {
      setDispatchItemRowCheckBox([{ id: id, checked: value }]);
    }
    else if(dispatchItemRowCheckBox.length > 0) {
      const isItemPresent = dispatchItemRowCheckBox.find((item) => item.id === id);
      if(isItemPresent) {
        setDispatchItemRowCheckBox((prev) => {
          return prev.map((item) => {
            if (item.id === id) {
              return { ...item, checked: value };
            }
            return item;
          });
        });
      }
      else {
        setDispatchItemRowCheckBox((prev) => {
          return [...prev, { id: id, checked: value }];
        });
      }
    }
  };
  useEffect(() => {
    console.log(dispatchItemRowCheckBox);
    handleItemCheckBoxChange(dispatchItemRowCheckBox);
  }, [dispatchItemRowCheckBox]);

  console.log(itemData)


  return (
    <TableComponent
      module={"Dispatch Item"}
      noAddButton={true}
      noSearchBar={true}
      simpleTable={true}
      padding={"0px 10px"}
      tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
      headerShownAlways={true}
      tableRowHeight={30}
      loader={false}
      actions={[]}
      header={[
        "Item Category",
        "Item Type",
        "HSN Code",
        "Total Item Quantity",
        "Contract Rate",
        "Sales Tax Terms",
        "Specifications",
      ]}
      checkBoxUI={{
        isvisible: true,
        header: null,
        component: (singleItemRow) => (
          <Checkbox
            onChange={(e) => {
              handleItemRowCheckBox(e.target.checked, singleItemRow.id);
            }}
            checked={
              dispatchItemRowCheckBox.find((d) => d.id === singleItemRow.id)
                ?.checked
            }
            style={{ color: primary, padding: "0px 4px" }}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": "head" }}
          />
        ),
      }}
      tableHeight="auto"
      tablePagination={false}
      footer={true}
      footerList={
        itemData?.length && {
          "Item Category": "Total",
          "Total Item Quantity":
            itemData?.reduce((acc, item) => {
              return Number(acc) + Number(item["Total Item Quantity"]);
            }, 0) +
            " " +
            (unit || ""),
        }
      }
      tableList={
        itemData && itemData.length
          ? itemData.map((s) => {
              s["Total Item QuantityUnit"] =
                s["Total Item Quantity"] + " " + (unit || "");
              s["Contract RateUnit"] =
                s["Contract Rate"] + (unit ? "/" + unit : "");
              s["Item TypeUnit"] = s["Item Type"] + "%";
              console.log("s", s);
              s["Specifications"] = () => {
                return (
                  <div
                    className="d-flex widthFC alignFE"
                    style={{ margin: "0px auto" }}
                  >
                    <ShowSpecifications
                      details={
                        s["specificationArr"] && s["specificationArr"].length > 0
                          ? s["specificationArr"].map((sP) => {
                              const ss = sP.name;
                              const filterS = masters[
                                "specifiationTypes"
                              ]?.find((st) => st.id === ss);
                              console.log("filterS", filterS, sP);
                              return {
                                ...sP,
                                name: filterS
                                  ? `${filterS.text?.toLowerCase()}`
                                  : "",
                                value: filterS
                                  ? `${sP.value} ${filterS.unit?.toLowerCase()}`
                                  : sP.value,
                              };
                            })
                          : []
                      }
                    />
                  </div>
                );
              };

              return s;
            })
          : []
      }
    />
  );
};
