import { useEffect, useState } from "react";

import { List, ListItem, Typography } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";

import { SubMenuListSkeleton } from "../layoutComponents/ShowSkeleton";
import StatusChip from "../layoutComponents/StatusChip";
import { mediumScreen } from "../Regex";
import { Fab } from "@material-ui/core";
import TableSearch from "../layoutComponents/TableSearch";
import TableSelect from "../layoutComponents/TableSelect";

const styles = {
  listContainer: (isMobile, search, RaisedSearchBar, secondTabData) => ({
    height: isMobile
      ? search || RaisedSearchBar
        ? "calc(100% - 56px)"
        : "100%"
      : search || RaisedSearchBar
      ? "calc(100% - 72px)"
      : "100%",
    maxHeight: Boolean(secondTabData) ? "calc(100vh - 170px)" : "inherit",
    overflow: "auto",
    margin: "0 2px",
    width: "calc(100% - 4px)",
  }),
  listItem: (selected) => ({
    padding: "16px",
    borderBottom: "1px solid rgb(241, 154, 48)",
    fontSize: mediumScreen() ? "12px" : "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    // height: 70,
    cursor: "pointer",
    backgroundColor: selected ? "rgba(30, 53, 89, 0.09)" : "#fff",
  }),
  fab: {
    position: "absolute",
    bottom: 16,
    right: 16,
    color: "white",
  },
  search: {
    backgroundColor: "#FFF",
    borderRadius: "4px",
    width: "96%",
    margin: "4px",
    maxWidth: "380px",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
};

const CustomSubMenuList = (props) => {
  const [listData, setListData] = useState([]);
  // const [shouldUpdate, setShouldUpdate] = useState(true);

  const handleSearchTextOnChange = async (text) => {
    await props.RaisedSearchBar.apiCall(text)
      .then((res) => setListData(res))
      .catch((err) => {
        console.log(err);
        setListData([]);
      });
  };

  const handleSelectOnChange = async (text) => {
    await props.RaisedSelectBar.apiCall(text)
      .then((res) => setListData(res))
      .catch((err) => {
        console.log(err);
        setListData([]);
      });
  };

  function isScrolledToBottom(element) {
    if (!element) return false;
    return (
      element.scrollHeight -
        Math.round(element.scrollTop) -
        element.clientHeight <
      2
    );
  }

  useEffect(() => {
    if (props.pagination) {
      const { count, loadMore, next, currentLength } = props.pagination;
      const scrollElement = document.getElementById("scroll");
      const handleScroll = () => {
        if (isScrolledToBottom(scrollElement)) {
          if (next && count > currentLength && !props.loading) {
            console.log("HERE SCROLL");
            loadMore();
          }
        }
      };

      if (scrollElement) {
        scrollElement.addEventListener("scroll", handleScroll);
        return () => {
          scrollElement.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, [props.pagination]);

  useEffect(() => {
    console.log(props.checklayoutfnfromchild);
  }, [props.checklayoutfnfromchild]);

  useEffect(() => {
    console.log("calledthis");
    if (
      // shouldUpdate &&
      props.tabs[0].data
    ) {
      console.log("calledthis2");
      setListData(props.tabs[0].data);
      // setShouldUpdate(false);
    }
  }, [props.tabs[0].data]);

  const {
    loading,
    listClickHandler,
    navigate,
    tabs,
    fabClickHandler,
    customItemRender = false,
    isMobile,
    search,
    RaisedSearchBar,
    RaisedSelectBar,
    RaisedMonthBar,
    secondTabData,
  } = props;

  console.log("CustomSubMenuList=", props);

  return (
    <div className="height-100">
      <div style={{ background: "#FFF" }}>
        {RaisedSearchBar?.visible ? (
          <TableSearch
            tableSearchField={{
              onChange: (text) => {
                handleSearchTextOnChange(text);
              },
            }}
            style={styles.search}
          />
        ) : null}
        {RaisedSelectBar?.visible ? (
          <TableSelect
            onChange={(value) => {
                handleSelectOnChange(value);
              }
            }
            selectedData={RaisedSelectBar?.selectedData}
            data={RaisedSelectBar?.data}
            {...RaisedSelectBar}
          />
        ) : null}
      </div>
      <>
        {loading ? (
          <div
            className="alignC justiyC d-flex width-100 height-100"
            style={{ padding: "10px 0" }}
          >
            <SubMenuListSkeleton tileLength={5} />
          </div>
        ) : listData ? (
          <>
            <div
              className="noTrack"
              style={styles.listContainer(
                isMobile,
                search,
                RaisedSearchBar || RaisedSelectBar || RaisedMonthBar,
                secondTabData?.array
              )}
              id={"scroll"}
            >
              <List disablePadding>
                {listData &&
                  listData.map((item, index) => (
                    <ListItem
                      key={index}
                      style={styles.listItem(
                        window?.location?.pathname.split("/")[3] == item?.id
                      )}
                      onClick={() => {
                        navigate(`${tabs[0]?.route}/${item?.id}`);
                        listClickHandler(item);
                      }}
                    >
                      <div className="d-flex d-flex-column width-100">
                        {customItemRender ? (
                          customItemRender(item)
                        ) : (
                          <div
                            className="d-flex d-flex-column"
                            style={{ margin: "6px 0" }}
                          >
                            <div>{item[tabs[0]?.primaryField]}</div>
                            <div>
                              {tabs[0]?.secondaryField === "is_active" ? (
                                <StatusChip
                                  status={
                                    item?.[tabs[0]?.secondaryField]
                                      ? "Active"
                                      : "Inactive"
                                  }
                                />
                              ) : (
                                item?.[tabs[0]?.secondaryField] || ""
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </ListItem>
                  ))}
              </List>
            </div>
            {fabClickHandler && fabClickHandler ? (
              <Fab
                style={styles.fab}
                color="primary"
                aria-label="add"
                size="medium"
                onClick={fabClickHandler}
              >
                <AddIcon />
              </Fab>
            ) : (
              ""
            )}
          </>
        ) : (
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              textAlign: "center",
              padding: "20px 0px",
            }}
          >
            {" "}
            No data yet!
          </Typography>
        )}
      </>
    </div>
  );
};

export default CustomSubMenuList;
