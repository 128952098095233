import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { withStyles } from "@material-ui/core/styles";
const CssRadio = withStyles({
  root: {
    "&.MuiButtonBase-root": {
      padding: "4px !important",
    },
  },
})(Radio);

export default function RowRadioButtonsGroup(props) {
  const { selected, array, onChange, width, margin } = props;
  return (
    <FormControl
      style={{
        fontSize: "0.7rem",
        width: width ? width : "fit-content",
        margin: margin ? margin : "",
      }}
    >
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        style={{
          fontSize: "0.9rem",
          width: width ? width : "fit-content",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {array && array.length > 0
          ? array.map((option) => (
              <FormControlLabel
              disableTypography
                control={
                  <CssRadio
                    checked={selected === option}
                    onChange={(e) => {
                      console.log(onChange, "onChange");
                      if (onChange) {
                        onChange(selected === option ? "" : option);
                      }
                    }}
                    value={selected}
                    size="medium"
                  />
                }
                label={option}
              />
            ))
          : ""}
      </RadioGroup>
    </FormControl>
  );
}
