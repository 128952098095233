import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";
import moment from "moment";
import { mediumScreen, StopScroll } from "../../Regex";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material";
import CustomDialog from "../../layoutComponents/CustomDialog";
import {
  CssTextField,
  CssTextField85,
  CssTextField95,
} from "../../styles/StyledComponents";
import {
  CustomAutocomplete,
  CustomMultiSelectAutocomplete,
  CustomSearchAutocomplete,
} from "../../layoutComponents/CustomAutocomplete";
import { primary } from "../../styles/colors";
import MuiDatePicker from "../../layoutComponents/MuiDatePicker";
import { EndPoints } from "../../utils/EndPoints";
import debounce from "lodash.debounce";
import { divide, multiply, subtract, sum } from "lodash";
import { useSnackbar } from "../../snackbarContext";
import { Divider } from "@material-ui/core";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import BackspaceIcon from '@mui/icons-material/Backspace';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const styles = {
  inputField: {
    minWidth: 230,
    width: "calc(25% - 20px)",
    margin: "10px",
  },
  inputAddressField: {
    // minWidth: 230,
    width: "auto",
    margin: "10px",
    flexGrow: 1,
  },
  brandPackagingField: {
    minWidth: 230,
    maxWidth: 280,
    width: "calc(100% - 20px)",
    margin: "0",
  },
  addBagsTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "grey",
    margin: "0px 0 10px 0",
    fontFamily: "Poppins",
  },
};

export const itemHeader = [
  {
    label: "Brand",
    labelKey: "Brand",
    key: "brand",
  },
  {
    label: "Item Category",
    labelKey: "Item Category",
    key: "item_category",
  },
  {
    label: "Item Type",
    labelKey: "Item TypeUnit",
    key: "item_type",
  },
  {
    label: "Contract Rate",
    labelKey: "Contract Rate",
    key: "contract_rate",
  },
  {
    label: "HSN Code",
    labelKey: "HSN Code",
    key: "hsn_code",
  },
  {
    label: "Packaging",
    labelKey: "Packaging",
    key: "packaing",
  },
];

const checkField = [
  { key: "dispatch_date", field: "select" },
  { key: "contract_type", field: "select" },
  { key: "dispatch_from", field: "select" },
  { key: "dispatch_to", field: "select" },
  { key: "unit", field: "select" },
  { key: "net_weight", field: "fill" },
  { key: "sender_name", field: "fill" },
  { key: "sender_address", field: "fill" },
  { key: "sender", field: "select" },
  { key: "receiver_name", field: "fill" },
  // { key: "receiver_city", field: "fill" },
  { key: "receiver_address", field: "fill" },
  { key: "receiver", field: "select" },
  { key: "total_amount", field: "fill" },
  { key: "delivery_challan_number", field: "fill" },
  { key: "transport_advance", field: "fill", type: "ex factory" },
  { key: "transport", field: "select", type: "ex factory" },
  { key: "truck_number", field: "fill", type: "ex factory" },
  { key: "transport_balance", field: "fill", type: "ex factory" },
  { key: "freight_rate", field: "fill", type: "ex factory" },
  { key: "total_freight", field: "fill", type: "ex factory" },
  { key: "bilty", field: "fill", type: "ex factory" },
  { key: "bilty_date", field: "select", type: "ex factory" },
  { key: "survey_company", field: "select" },
  { key: "dispatch_status", field: "select" },
];

const changeUnit = (from, to, weigths, opposite) => {
  //default in kg

  if (opposite) {
    if (from === "MT") {
      return changeTo(to, divide(weigths, 1000), opposite);
    } else if (from === "Kgs") {
      return changeTo(to, weigths);
    } else if (from === "Qtl") {
      return changeTo(to, divide(weigths, 100), opposite);
    }
  } else {
    if (from === "MT") {
      return changeTo(to, multiply(weigths, 1000));
    } else if (from === "Kgs") {
      return changeTo(to, weigths);
    } else if (from === "Qtl") {
      return changeTo(to, multiply(weigths, 100));
    }
  }
};

const changeTo = (to, weigths, opposite) => {
  if (opposite) {
    if (to === "MT") {
      return multiply(weigths, 1000);
    } else if (to === "Kgs") {
      return weigths;
    } else if (to === "Qtl") {
      return multiply(weigths, 100);
    }
  } else {
    if (to === "MT") {
      return divide(weigths, 1000);
    } else if (to === "Kgs") {
      return weigths;
    } else if (to === "Qtl") {
      return divide(weigths, 100);
    }
  }
};

export const CloseDispatch = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const showSnackbar = useSnackbar();

  const { singleDispatch } = props;

  const handlePreDispatchClose = (id) => {
    props
      .InlandGodownFunction(
        "patch",
        `dispatch/${id}`,
        null,
        "post",
        { is_closed: true },
        null,
        null
      )
      .then((res) => {
        if (res.error) {
          console.log(res);
          showSnackbar(res.data, "error");
        } else {
          showSnackbar("Dispatch Closed Successfully", "success");
          props.FetchNew();
        }
      });
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpenDialog(true);
        }}
        style={{
          cursor: "pointer",
          backgroundColor: "#8f8f8f",
          color: "white",
          fontSize: mediumScreen() ? "0.7rem" : "0.75rem",
          fontFamily: "Poppins",
          margin: '0 10px'
        }}
        size="small"
        withBg={true}
        startIcon={<HighlightOffIcon />}
      >
        Close
      </Button>
      <ConfirmDialog
        openDialog={openDialog}
        setOpenDialog={(d) => setOpenDialog(d)}
        width="400px"
        height="250px"
        header="Pre Dispatch Inland Godown"
        action="Yes"
        resetText="No"
        onSubmit={() => {
          handlePreDispatchClose(singleDispatch?.id);
          setOpenDialog(false);
        }}
        content={() => (
          <div
            className="d-flex d-flex-column justifyC"
            style={{ width: "100%", padding: "2px" }}
          >
            <span
              style={{
                fontSize: "13px",
                color: "#888",
                fontWeight: "500",
              }}
            >
              PO : {singleDispatch?.purchase_order_details?.po_number}
            </span>
            <span
              style={{
                fontSize: "12px",
                color: "#888",
                paddingBottom: 16,
                fontWeight: "500",
              }}
            >
              Miller Bill No. : {singleDispatch?.miller_bill_number}
            </span>

            <span style={{ fontSize: "14px", color: "#333" }}>
              Are you sure you want to Close this dispatch?
            </span>
          </div>
        )}
      />
    </>
  );
};

const AddPackagingBag = (props) => {
  const {
    masters,
    rowData,
    handleBrandPackagingDataChange,
    itemRowPackagingData,
    rowInventoryStockList,
    itemID,
  } = props;
  const [packBagBagDialog, setPackBagBagDialog] = useState(false);
  const [packagingDetails, setPackagingDetails] = useState([]);

  const handlePackagingBagChange = (index, value, totalBags) => {
    const updatedPackagingDetails = [...packagingDetails];
    updatedPackagingDetails[index].add_bags = value;
    updatedPackagingDetails[index].add_bags_error =
      Number(value || 0) > Number(totalBags)
        ? "Bags exceeds the total bags"
        : false;
    setPackagingDetails(updatedPackagingDetails);
  };

  useEffect(() => {
    if (itemRowPackagingData) {
      setPackagingDetails(
        itemRowPackagingData && itemRowPackagingData.length > 0
          ? itemRowPackagingData.map((item) => {
              console.log("BAG-", item, rowInventoryStockList);
              return {
                ...item,
                total_bags:
                  rowInventoryStockList?.length > 0
                    ? rowInventoryStockList.find(
                        (inv) => inv.packaging_id == item.id
                      )?.total_bags || 0
                    : 0,
                add_bags: item?.add_bags || "",
                add_bags_error: item.add_bags_error || false,
              };
            })
          : []
      );
    }
  }, [itemRowPackagingData, rowInventoryStockList]);

  return (
    <>
      <Button
        style={{
          cursor: "pointer",
          color: "rgb(241 154 48)",
          fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
          fontFamily: "Poppins",
          fontWeight: 600,
          textTransform: "none",
        }}
        size="small"
        withBg={false}
        onClick={() => {
          setPackBagBagDialog(true);
        }}
      >
        ADD
      </Button>
      <CustomDialog
        open={packBagBagDialog}
        onClose={() => {
          setPackBagBagDialog(false);
        }}
        maxWidth={"sm"}
        title={"Add Packaging-Bags"}
        noResetButton={true}
        submitButton="Done"
        onSubmitClick={() => {
          if (packagingDetails.every((item) => !item.add_bags_error)) {
            console.log("packagingDetails", packagingDetails);
            handleBrandPackagingDataChange(
              "packaging",
              packagingDetails,
              itemID
            );
            setPackBagBagDialog(false);
          }
        }}
      >
        <div className="d-flex d-flex-column" style={{ margin: "10px 0" }}>
          <div
            className="d-flex justifySB alignFS"
            style={{ marginBottom: 30 }}
          >
            <Typography style={styles.addBagsTitle}>Packaging </Typography>
            <CustomMultiSelectAutocomplete
              options={
                masters["PackagingList"]?.map((pa) => {
                  return {
                    ...pa,
                    sizeUnit: `${Number(pa?.size)} ${pa?.unit} ${pa?.bag_type}`,
                  };
                }) || []
              }
              getOptionLabel={(option) =>
                option?.size
                  ? `${option?.size} ${option?.unit} ${option?.bag_type}`
                  : ""
              }
              multiple
              optionKey="sizeUnit"
              fixedOptions={rowData?.fixedPackagingOptions}
              disableClearable={Boolean(
                rowData?.fixedPackagingOptions.length > 0
              )}
              error={!!rowData?.packagingError}
              helperText={rowData?.packagingError}
              value={rowData?.packaging || []}
              onChange={(e, newValue) => {
                handleBrandPackagingDataChange("packaging", newValue, itemID);
              }}
              style={{
                width: "70%",
              }}
              label=""
            />
          </div>

          <Typography style={styles.addBagsTitle}>Add Bags </Typography>
          <div className="d-flex justifySB" style={{ margin: "20px 0 0 0" }}>
            <span style={{ fontWeight: 600, width: "35%", fontSize: 12 }}>
              Packaging Details
            </span>
            <span
              style={{
                fontWeight: 600,
                width: "30%",
                textAlign: "center",
                fontSize: 12,
              }}
            >
              Total Bags
            </span>
            <span
              style={{
                fontWeight: 600,
                width: "35%",
                textAlign: "end",
                fontSize: 12,
              }}
            >
              Add Bags
            </span>
          </div>
          <Divider style={{ margin: "0 0 5px 0" }} />

          {packagingDetails.length > 0
            ? packagingDetails.map((pack, index) => {
                return (
                  <>
                    <div
                      className="d-flex justifySB alignC"
                      style={{ margin: "10px 0" }}
                    >
                      <span style={{ width: "35%", fontSize: 12 }}>
                        {pack?.size} {pack?.unit} {pack?.bag_type}
                      </span>
                      <span
                        style={{
                          width: "30%",
                          textAlign: "center",
                          fontSize: 12,
                        }}
                      >
                        {pack?.total_bags}
                      </span>
                      <span
                        className="d-flex justifyFE alignC"
                        style={{ width: "35%" }}
                      >
                        <CssTextField85
                          required={true}
                          id="outlined-basic"
                          label={"Add Bags"}
                          style={{ width: "60%" }}
                          type={"number"}
                          onFocus={StopScroll}
                          value={pack.add_bags || ""}
                          error={pack.add_bags_error}
                          helperText={pack.add_bags_error || ""}
                          onKeyDown={(evt) => {
                            ["e", "E", "-", "+", "."].includes(evt.key) &&
                              evt.preventDefault();
                          }}
                          InputProps={{
                            style: {
                              fontSize: 12,
                            },
                          }}
                          onChange={(e) => {
                            handlePackagingBagChange(
                              index,
                              e.target.value,
                              pack?.total_bags
                            );
                          }}
                          variant="outlined"
                        />
                      </span>
                    </div>
                    {index === packagingDetails.length - 1 ? (
                      ""
                    ) : (
                      <Divider style={{ margin: "2px 0" }} />
                    )}
                  </>
                );
              })
            : null}
        </div>
      </CustomDialog>
    </>
  );
};

export const ActionContainer = (props) => {
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [singlePreDispatch, setSinglePreDispatch] = useState({});
  const [inputData, setInputData] = useState({});
  const [itemBrandPackagingData, setItemBrandPackagingData] = useState([]);
  const [error, setError] = useState({});
  const [itemRowCheckBox, setItemRowCheckBox] = useState([]);
  const [itemRowError, setItemRowError] = useState("");
  const [isDraft, setIsDraft] = useState(true);
  const [inventoryStock, setInventoryStock] = useState([]);

  const showSnackbar = useSnackbar();

  const TOTALSUMFIELDS = [
    "gst_amount",
    "igst_amount",
    "cgst_amount",
    "sgst_amount",
    "other_amount",
    "transport_advance",
  ];
  const SUBTRACTFIELD = "other_deduction";

  const fetchInventoryStock = async (godownId, id) => {
    props
      .PortsFunction(
        "get",
        "dispatch/inland-godown-dispatch-bag-stock/list/",
        null,
        "",
        null,
        {
          inland_godown: godownId,
          dispatch_id: id,
        }
      )
      .then((res) => {
        setInventoryStock(res);
      });
  };

  const resetState = () => {
    setInputData({});
    setError({});
    setItemRowCheckBox([]);
    setItemBrandPackagingData([]);
    setActionDialogOpen(false);
  };

  const handleRemainingQty = (prevData, newData, type = "") => {
    let prevNetWeight =
      props.operation === "edit"
        ? Number(
            Number(prevData?.remaining_weight) + Number(prevData?.net_weight)
          )
        : prevData?.remaining_weight
        ? Number(prevData?.remaining_weight)
        : prevData?.net_weight || 0;
    let remainingQty = Number(prevNetWeight) - Number(newData?.net_weight || 0);
    if (prevData?.unit == newData?.unit?.name) {
      return type === ""
        ? Number(remainingQty).toFixed(3) + " " + (prevData?.unit || "")
        : type === "payload"
        ? Number(remainingQty).toFixed(3)
        : Number(remainingQty).toFixed(3);
    } else {
      let updatedPrevNetWeight = changeUnit(
        prevData?.unit,
        newData?.unit?.name,
        prevNetWeight
      );
      remainingQty =
        Number(updatedPrevNetWeight || 0) - Number(newData?.net_weight || 0);
      return type === ""
        ? Number(remainingQty).toFixed(3) + " " + (newData?.unit?.name || "")
        : type === "payload"
        ? changeUnit(newData?.unit?.name, prevData?.unit, remainingQty)
        : Number(remainingQty).toFixed(3);
    }
  };

  const checkItemSelected = () => {
    return !Boolean(itemRowCheckBox.length);
  };

  const isDraftEnabled = () => {
    let isEnabled = true;

    if (props.operation === "edit") {
      props.singleDispatch?.subList?.map((s) => {
        let selectedPacking = itemBrandPackagingData?.find(
          (d) => d.id === s.id
        )?.packaging;
        console.log(
          selectedPacking?.length == s.PackagingArr?.length,
          itemBrandPackagingData,
          selectedPacking,
          s,
          s.PackagingArr
        );
        if (
          selectedPacking?.length == s.PackagingArr?.length &&
          selectedPacking?.length > 0
        ) {
        } else {
          isEnabled = false;
          return isEnabled;
        }
      });
    }

    return isEnabled;
  };

  const formatLabel = (str) => {
    console.log(str);
    if (str) {
      return str
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  };

  const calcContractRateSizeBag = () => {
    console.log(itemBrandPackagingData, itemRowCheckBox);
    let selectedItemsList = itemBrandPackagingData.filter((t) =>
      itemRowCheckBox.includes(t.id)
    );
    let requiredUnit = inputData?.unit?.name;
    let totalSum = 0;

    selectedItemsList.forEach((s) => {
      let rowPackSizeBagSum = 0;
      s.packaging.forEach((pack) => {
        if (pack.unit) {
          // Convert size if units are different
          const convertedSize =
            pack.unit == requiredUnit
              ? Number(pack.size)
              : changeUnit(pack.unit, requiredUnit, Number(pack.size));
          // Multiply converted size by add_bags and add to totalSum
          rowPackSizeBagSum =
            Number(rowPackSizeBagSum) +
            Number(convertedSize) * Number(parseFloat(pack.add_bags || 0));
          console.log(
            "TSIZE",
            rowPackSizeBagSum,
            convertedSize,
            pack.unit,
            requiredUnit,
            changeUnit(pack.unit, requiredUnit, Number(pack.size))
          );
        }
      });

      totalSum =
        Number(totalSum) +
        Number(rowPackSizeBagSum || 0) * Number(s.contract_rate || 0);
      console.log("TS", rowPackSizeBagSum, totalSum);
    });

    console.log("TT", totalSum);
    return Number(totalSum).toFixed(2);
  };

  const calcTotalAmount = (key = "", value = "", allValue) => {
    let totalSum = sum(
      TOTALSUMFIELDS.map((t) => Number(t === key ? value : allValue[t] || 0))
    );
    console.log(
      "TOTALSUM",
      totalSum,
      key,
      value,
      allValue,
      itemBrandPackagingData,
      itemRowCheckBox
    );
    totalSum = Number(totalSum) + Number(calcContractRateSizeBag() || 0);
    console.log("TOTALSUM2", totalSum, Number(calcContractRateSizeBag() || 0));

    return Number(
      totalSum -
        Number(key === SUBTRACTFIELD ? value : allValue[SUBTRACTFIELD] || 0)
    ).toFixed(2);
  };

  const Calculation = (key, value) => {
    console.log("CALCULATION", value);
    setInputData((prevData) => {
      const updatedData = { ...prevData };

      if (
        [
          "gst_amount",
          "igst_amount",
          "cgst_amount",
          "sgst_amount",
          "other_amount",
          "other_deduction",
        ].includes(key)
      ) {
        // updatedData["total_amount"] = calcTotalAmount(key, value, prevData);
      }
      if (key === "transport_advance") {
        // updatedData["total_amount"] = calcTotalAmount(key, value, prevData);
        updatedData["transport_balance"] = Number(
          subtract(Number(prevData.total_freight || 0), Number(value || 0))
        ).toFixed(2);
      }
      if (key === "freight_rate") {
        updatedData["total_freight"] = Number(
          multiply(Number(value || 0), Number(prevData.net_weight || 0))
        ).toFixed(2);
        updatedData["transport_balance"] = Number(
          subtract(
            Number(
              multiply(Number(value || 0), Number(prevData.net_weight || 0))
            ),
            Number(prevData.transport_advance || 0)
          )
        ).toFixed(2);
      }
      if (key === "net_weight") {
        updatedData["total_freight"] = Number(
          multiply(Number(value || 0), Number(prevData.freight_rate || 0))
        ).toFixed(2);
        updatedData["transport_balance"] = Number(
          subtract(
            Number(
              multiply(Number(value || 0), Number(prevData.freight_rate || 0))
            ),
            Number(prevData.transport_advance || 0)
          )
        ).toFixed(2);
      }

      console.log("updatedData", updatedData);
      return updatedData;
    });
  };

  const debouncedCalculation = useCallback(
    debounce((key, value) => {
      Calculation(key, value);
    }, 500), // 500ms debounce delay
    []
  );

  const handleInputDataChange = (key, value) => {
    console.log(key, value);
    if (key === "sender") {
      setInputData((prev) => ({
        ...prev,
        [key]: value,
        sender_name: value?.name || "",
        sender_address: value?.address || "",
      }));
      setError((prev) => ({
        ...prev,
        sender: value ? "" : prev.sender,
        sender_name: value ? "" : prev.sender_name,
        sender_address: value ? "" : prev.sender_address,
      }));
    } else if (key === "receiver") {
      setInputData((prev) => ({
        ...prev,
        [key]: value,
        receiver_name: value?.name || "",
        // receiver_city: value?.city || "",
        receiver_address: value?.address || "",
      }));
      setError((prev) => ({
        ...prev,
        receiver: value ? "" : prev.receiver,
        receiver_name: value ? "" : prev.receiver_name,
        // receiver_city: value ? "" : prev.receiver_city,
        receiver_address: value ? "" : prev.receiver_address,
      }));
    } else {
      setInputData((prev) => ({
        ...prev,
        [key]: ["truck_number", "bilty", "delivery_challan_number"].includes(
          key
        )
          ? value.toUpperCase()
          : value,
      }));

      setError((prev) => ({
        ...prev,
        [key]: [
          "gst_amount",
          "igst_amount",
          "cgst_amount",
          "sgst_amount",
          "other_amount",
          "other_deduction",
          "broker",
          "remarks",
          "delivery_challan_number",
        ].includes(key)
          ? ""
          : value
          ? ""
          : prev[key],
      }));

      if (
        [
          "gst_amount",
          "igst_amount",
          "cgst_amount",
          "sgst_amount",
          "other_amount",
          "other_deduction",
          "transport_advance",
          "freight_rate",
          "net_weight",
        ].includes(key)
      ) {
        debouncedCalculation(key, value);
      }
    }
  };

  const handleBrandPackagingDataChange = (field, value, id) => {
    if (field == "packaging") {
      console.log("PACK-", isDraftEnabled(), !Boolean(isDraftEnabled()));
      if (value.every((v) => Number(v.add_bags) > 0) && isDraftEnabled()) {
        setIsDraft(false);
      } else {
        setIsDraft(true);
      }
      console.log("packaging", value);
    }

    setItemBrandPackagingData((prev) => {
      const updatedData = prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            [field == "brand"
              ? "brandError"
              : field == "contract_rate"
              ? "contractRateError"
              : "packagingError"]: !Boolean(
              value?.id || value?.length > 0 || value
            ),
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleItemRowCheckBox = (value, id) => {
    console.log(value, id);
    setItemRowCheckBox((prev) => {
      return prev.length
        ? value
          ? [...prev, id]
          : prev.filter((itemId) => itemId !== id)
        : [id];
    });
  };

  const handleFieldErrorCheck = async () => {
    console.log("here", inputData);
    let hasError = false;
    let errorMsg = { ...error };

    checkField.map((c) => {
      // When contract_type is 'ex factory', validate all fields
      if (inputData?.contract_type?.contract_type === "ex factory") {
        if (Boolean(errorMsg[c.key])) {
          errorMsg[c.key] = errorMsg[c.key];
          hasError = true;
        } else if (!inputData[c.key]) {
          errorMsg[c.key] = `Please ${c.field} ${formatLabel(c.key)}!`;
          hasError = true;
        }
      }

      // When contract_type is not 'ex factory', validate all fields except those with c.type === 'ex factory'
      if (
        inputData?.contract_type?.contract_type !== "ex factory" &&
        c?.type !== "ex factory" &&
        !inputData[c.key]
      ) {
        if (
          c.key === "sender" &&
          inputData["sender_name"] &&
          inputData["sender_address"]
        ) {
          return;
        } else if (
          c.key === "receiver" &&
          inputData["receiver_name"] &&
          // inputData["receiver_city"] &&
          inputData["receiver_address"]
        ) {
          return;
        }
        errorMsg[c.key] = `Please ${c.field} ${formatLabel(c.key)}!`;
        hasError = true;
      }
      if (c.key === "net_weight") {
        let isValid = handleRemainingQty(
          props.singleDispatch,
          inputData,
          "onlyQty"
        );
        console.log(isValid, Number(isValid) < 0);
        if (Number(isValid) < 0) {
          errorMsg[c.key] = `Net Weight Exceeds Limit!`;
          hasError = true;
        }
      }
    });

    //brand and packaging validation
    if (itemBrandPackagingData.length) {
      setItemBrandPackagingData(
        itemBrandPackagingData.map((d) => {
          if (!d.brand?.id) {
            d.brandError = "Please select brand!";
            hasError = true;
          }
          if (!d.contract_rate) {
            d.contractRateError = "Please fill contract rate!";
            hasError = true;
          }
          if (!d.packaging?.length) {
            d.packagingError = "Please select packaging!";
            setItemRowError("Please select packaging!");
            hasError = true;
          }
          console.log(
            "CHECKBAG-",
            d.packaging,
            !Boolean(d.packaging.every((a) => Boolean(a?.add_bags)))
          );
          if (Number(d.packaging?.length > 0)) {
            if (
              !isDraft &&
              !Boolean(d.packaging.every((a) => Boolean(a?.add_bags)))
            ) {
              setItemRowError("Please toggle the draft to proceed!");
              hasError = true;
            } else {
              setItemRowError("");
            }
          }

          return d;
        })
      );
    } else {
      setItemBrandPackagingData(
        itemBrandPackagingData.map((d) => {
          d.brandError = "Please select brand!";
          d.contractRateError = "Please fill contract rate!";
          d.packagingError = "Please select packaging!";
          return d;
        })
      );

      hasError = true;
    }

    console.log(hasError, errorMsg);
    setError(errorMsg);
    return hasError;
  };

  const handleSubmit = async () => {
    console.log(
      "clciked",
      Number(handleRemainingQty(props.singleDispatch, inputData, "payload"))
    );
    let isError = await handleFieldErrorCheck();
    if (isError) {
      return;
    } else {
      console.log("inputData-submit", inputData);
      let dispatch = {};
      let dispatch_items = [];

      let Operation = props.operation;

      dispatch = {
        dispatch_date: moment(inputData.dispatch_date).format("YYYY-MM-DD"),
        contract_type: inputData.contract_type?.id,
        dispatched_to_inland_godown: inputData.dispatch_from?.id,
        dispatched_to: inputData.dispatch_to?.id,
        unit: inputData.unit?.name,
        net_weight: inputData.net_weight,
        sender: inputData.sender?.id,
        sender_name: inputData.sender_name,
        sender_address: inputData.sender_address,
        receiver: inputData.receiver?.id,
        receiver_name: inputData.receiver_name,
        // receiver_city: inputData.receiver_city,
        receiver_address: inputData.receiver_address,
        gst_amount: inputData?.gst_amount || "",
        igst_amount: inputData?.igst_amount || "",
        cgst_amount: inputData?.cgst_amount || "",
        sgst_amount: inputData?.sgst_amount || "",
        other_amount: inputData?.other_amount || "",
        other_deduction: inputData?.other_deduction || "",
        total_amount: inputData.total_amount,
        survey_company: inputData.survey_company?.id,
        broker: inputData?.broker?.id || "",
        dispatch_status: inputData.dispatch_status?.name,
        remarks: inputData?.remarks || "",
        delivery_challan_number: inputData?.delivery_challan_number || "",
        dispatch_weight: Number(inputData.net_weight || 0),
        remaining_weight: Number(
          handleRemainingQty(props.singleDispatch, inputData, "payload")
        ),
        is_active: true,
        is_post_dispatch: true,
        is_draft: isDraft,
        total_bags: itemBrandPackagingData.reduce(
          (sum, item) =>
            sum +
            Number(
              item.packaging.reduce(
                (innerSum, pkg) => innerSum + Number(pkg.add_bags),
                0
              )
            ),
          0
        ),
      };

      if (Operation === "create") {
        dispatch.pre_dispatch = props.singleDispatch?.id;
      }

      if (inputData?.contract_type?.contract_type === "ex factory") {
        dispatch.transport_advance = inputData?.transport_advance || "";
        dispatch.transport = inputData?.transport?.id || "";
        dispatch.truck_number = inputData?.truck_number || "";
        dispatch.transport_balance = inputData?.transport_balance || "";
        dispatch.freight_rate = inputData?.freight_rate || "";
        dispatch.total_freight = inputData?.total_freight || "";
        dispatch.bilty_number = inputData?.bilty || "";
        dispatch.bilty_date = moment(inputData?.bilty_date).format(
          "YYYY-MM-DD"
        );
      }

      let itemSelected = (
        singlePreDispatch?.id ? inputData : singleDispatch
      ).subList?.filter((d) => itemRowCheckBox.includes(d.id));

      let PackagingBulkUpdate = [];
      let ItemBulkUpdate = [];

      itemSelected.map((item) => {
        let packagingItem = itemBrandPackagingData.find(
          (d) => d?.id === item?.id
        );

        if (Operation !== "create") {
          packagingItem.packaging.map((pa) => {
            let Obj = {
              dispatch_items: pa?.allData?.dispatch_items || null,
              net_weight: Number(pa?.size || 0) * Number(pa?.add_bags || 0),
              packaging: pa.id,
              total_bags_dispatched: Number(pa?.add_bags || 0),
              remaining_bags:
                Number(pa?.total_bags || 0) - Number(pa?.add_bags || 0),
            };

            if (pa?.allData?.id) {
              Obj.id = pa?.allData?.id;
            }

            PackagingBulkUpdate.push(Obj);
          });
        }

        let dispatchPackaging = packagingItem?.packaging?.map((d) => ({
          packaging: d?.id,
          total_bags_dispatched: Number(d.add_bags || 0),
        }));
        let packagingID = packagingItem?.packaging.map((d) => d?.id);

        dispatch_items.push({
          item_category: item?.item_category || "",
          item_type: item?.item_type || "",
          brand: packagingItem?.brand?.id || "",
          contract_rate: packagingItem?.contract_rate || "",
          dispatch_packaging: dispatchPackaging,
          packaging: packagingID,
          total_bags:
            packagingItem.packaging.reduce(
              (acc, d) => Number(acc) + Number(d?.add_bags || 0),
              0
            ) || 0,
          total_quantity: item?.total_quantity || 0,
          hsn_code: item?.hsn_code || "",
          po_item: item?.po_item || "",
        });
        if (Operation !== "create") {
          ItemBulkUpdate.push({
            is_active: true,
            id: item?.id,
            brand: packagingItem?.brand?.id || "",
            contract_rate: packagingItem?.contract_rate || "",
            item_category: item?.item_category || "",
            item_type: item?.item_type || "",
            hsn_code: item?.hsn_code || "",
            dispatch_packaging: dispatchPackaging,
            packaging: packagingID,
            total_bags:
              packagingItem.packaging.reduce(
                (acc, d) => Number(acc) + Number(d?.add_bags || 0),
                0
              ) || 0,
            total_quantity: item?.total_quantity || 0,
            dispatch: singlePreDispatch?.id,
            po_item: item?.po_item || "",
          });
        }
      });

      const payload = {
        dispatch: dispatch,
        dispatch_items: dispatch_items,
      };
      console.log(
        "payload",
        payload,
        dispatch,
        PackagingBulkUpdate,
        ItemBulkUpdate,
        itemBrandPackagingData,
        inputData,
        itemSelected
      );

      if (Operation === "create") {
        props
          .InlandGodownFunction(
            "post",
            "dispatch/create",
            null,
            "post",
            payload,
            null,
            null
          )
          .then((res) => {
            if (res.error) {
              console.log(res);
              showSnackbar(res.data, "error");
            } else {
              showSnackbar(`Pre Dispatch Save Successfully`, "success");
              resetState();
              props.reloadFn();
              props.FetchNew();
            }
          });
      } else {
        props
          .InlandGodownFunction(
            "patch",
            `dispatch/${singlePreDispatch?.id}`,
            null,
            "post",
            dispatch,
            null,
            null
          )
          .then((res1) => {
            if (res1.error) {
              console.log(res1);
              showSnackbar(res1.data, "error");
            } else {
              props
                .InlandGodownFunction(
                  "put",
                  "dispatch/dispatch-packaging/bulk-update",
                  null,
                  "postdata",
                  PackagingBulkUpdate
                )
                .then((res2) => {
                  if (res2.error) {
                    console.log(res2);
                    showSnackbar(res2.data, "error");
                  } else {
                    props
                      .InlandGodownFunction(
                        "put",
                        "dispatch/dispatch-items/bulk-update",
                        null,
                        "postdata",
                        ItemBulkUpdate
                      )
                      .then((res3) => {
                        if (res3.error) {
                          console.log(res3);
                          showSnackbar(res3.data, "error");
                        } else {
                          showSnackbar(
                            `Pre Dispatch Updated Successfully`,
                            "success"
                          );
                          resetState();
                          props.reloadFn();
                          props.FetchNew();
                        }
                      });
                  }
                });
            }
          });
      }
    }
  };

  const setInitialValue = (data) => {
    console.log(data);
    if (data?.id) {
      setError({});
      setItemRowCheckBox([]);
      setIsDraft(true);
      const subList = [];
      let datSubList =
        data?.dispatch_items?.length > 0
          ? data.dispatch_items
          : [...data?.subList];
      console.log(
        data,
        datSubList,
        props.masters["BrandList"],
        props.masters["BrandList"].find((d) => d.id == datSubList[0].brand)
      );
      if (datSubList.length) {
        let brandPackList = [];

        datSubList.map((item) => {
          let packaging =
            item?.dispatch_packaging && item?.dispatch_packaging?.length > 0
              ? item?.dispatch_packaging.map((d) => {
                  let data = props.masters["PackagingList"].find(
                    (p) => p.id == d.packaging
                  );
                  return {
                    ...data,
                    sizeUnit: `${data.size} ${data.unit} ${data.bag_type}`,
                    total_bags: inventoryStock.length
                      ? Number(
                          inventoryStock
                            .find((inv) => inv.brand_id == item.brand)
                            ?.packaging_data.find(
                              (pac) => pac.packaging_id == data.id
                            )?.total_bags || 0
                        )
                      : 0,
                    add_bags: d.total_bags_dispatched || "",
                    add_bags_error: false,
                    allData: d,
                  };
                })
              : [];
          let FixedPackaging =
            item?.dispatch_packaging && item?.dispatch_packaging?.length > 0
              ? item?.dispatch_packaging.map((d) => d.packaging_details.id)
              : [];

          brandPackList.push({
            id: item.id,
            brand: item?.brand
              ? props.masters["BrandList"].find((d) => d.id == item.brand)
              : [],
            brandError: false,
            packaging: packaging,
            contract_rate: item?.contract_rate || "",
            contractRateError: false,
            fixedPackagingOptions: FixedPackaging,
            packagingError: false,
          });
        });
        setItemBrandPackagingData(brandPackList);
      }

      let Obj = {};

      if (datSubList.length > 0) {
        setItemRowCheckBox(datSubList.map((d) => d.id));
        // setIsDraft(false);
        Obj = {
          dispatch_date: moment(data?.dispatch_date).format("YYYY-MM-DD"),
          dispatch_from: props.singleInlandGodown,
          dispatch_status: props.StatusDispatch.find(
            (d) => d.name == "dispatched"
          ),
          contract_type: props.ContractTypeList?.find(
            (d) => d.id == data?.contract_type
          ),
          dispatch_to: props.masters["PortList"].find(
            (d) => d.id == data?.dispatched_to
          ),
          unit: props.UnitList.find((d) => d.name == data?.unit) || "",
          net_weight:
            props.operation === "create"
              ? Number(data.remaining_weight)
              : Number(data?.net_weight || 0).toFixed(2),
          sender:
            props?.masters["shippersList"]?.find((d) => d.id == data?.sender) ||
            "",
          sender_name: data?.sender_name || "",
          sender_address: data?.sender_address || "",
          receiver:
            props?.masters["shippersList"]?.find(
              (d) => d.id == data?.receiver
            ) || "",
          receiver_name: data?.receiver_name || "",
          // receiver_city: data?.receiver_city || "",
          receiver_address: data?.receiver_address || "",
          gst_amount: Number(data?.gst_amount || 0).toFixed(2),
          igst_amount: Number(data?.igst_amount || 0).toFixed(2),
          cgst_amount: Number(data?.cgst_amount || 0).toFixed(2),
          sgst_amount: Number(data?.sgst_amount || 0).toFixed(2),
          other_amount: Number(data?.other_amount || 0).toFixed(2),
          other_deduction: Number(data?.other_deduction || 0).toFixed(2),
          total_amount: Number(data?.total_amount || 0).toFixed(2),
          delivery_challan_number: data?.delivery_challan_number || "",
          survey_company:
            props.SurveyCompanyList.find((d) => d.id == data?.survey_company) ||
            "",
          broker:
            props.masters["BrokerList"].find((d) => d.id == data?.broker) || "",
          remarks: data?.remarks || "",
          transport:
            props.masters["TransporterList"].find(
              (d) => d.id == data?.transport
            ) || "",
          truck_number: data?.truck_number || "",
          transport_balance: Number(data?.transport_balance || 0).toFixed(2),
          freight_rate: Number(data?.freight_rate || 0).toFixed(2),
          total_freight: Number(data?.total_freight || 0).toFixed(2),
          bilty: data?.bilty || "",
          bilty_date: moment(data?.bilty_date).format("YYYY-MM-DD") || "",
          subList: datSubList,
        };
      } else {
        Obj = {
          dispatch_from: props.singleInlandGodown,
          dispatch_status: props.StatusDispatch.find(
            (d) => d.name == "dispatched"
          ),
          total_amount: Number(0).toFixed(2),
          transport_balance: Number(0).toFixed(2),
          total_freight: Number(0).toFixed(2),
          unit: props.UnitList.find((d) => d.name == data?.unit) || "",
          net_weight: Number(data?.net_weight || 0).toFixed(2),
          subList: subList,
        };
      }
      console.log(Obj);
      setInputData(Obj);
    }
  };

  const getTotalAmount = async () => {
    const totalAmount = calcTotalAmount("", "", inputData);
    console.log("getTotalAmount", totalAmount);
    // Update the state with the latest total amount
    setInputData((prevInputData) => ({
      ...prevInputData,
      total_amount: totalAmount,
    }));
  };

  useEffect(() => {
    // if (itemBrandPackagingData.length > 0 && itemRowCheckBox.length > 0) {
    getTotalAmount();
    // }
  }, [
    itemBrandPackagingData,
    itemRowCheckBox,
    inputData.gst_amount,
    inputData.igst_amount,
    inputData.cgst_amount,
    inputData.sgst_amount,
    inputData.other_amount,
    inputData.transport_advance,
    inputData.other_deduction,
  ]);

  console.log("p", props, props.singleDispatch);

  const {
    masters,
    singleDispatch,
    ContractTypeList,
    UnitList,
    SurveyCompanyList,
    StatusDispatch,
    ItemCateoryList,
    ItemList,
    HSNlist,
    postDispatchDraftList,
    reloadFn,
  } = props;

  return (
    <>
      <Button
        style={{
          cursor: "pointer",
          backgroundColor:
            props.operation === "edit" ? "#7D4909" : "rgb(241 154 48)",
          color: "white",
          fontSize: mediumScreen() ? "0.7rem" : "0.75rem",
          fontFamily: "Poppins",
        }}
        size="small"
        withBg={true}
        onClick={async () => {
          await fetchInventoryStock(
            props?.singleInlandGodown?.id,
            props.operation === "create"
              ? singleDispatch.id
              : singleDispatch?.pre_dispatch
          );
          setActionDialogOpen(true);
          setInitialValue(singleDispatch);
          setSinglePreDispatch(singleDispatch);
        }}
      >
        {Boolean(props.operation === "edit")
          ? "Saved Dispatch"
          : "Move To Port"}
      </Button>
      <CustomDialog
        open={actionDialogOpen}
        onClose={() => {
          resetState();
          reloadFn();
        }}
        maxWidth={"xl"}
        title={"Move To Port"}
        resetButton="Reset"
        onResetClick={() => {
          setInitialValue(singleDispatch);
        }}
        submitButton={isDraft ? "Save" : "Submit"}
        onSubmitClick={() => {
          handleSubmit();
        }}
      >
        <div className="d-flex justifyFE">
          <FormControlLabel
            control={
              <Switch
                size={"small"}
                checked={isDraft}
                onChange={(e) => {
                  console.log(e.target.checked, isDraftEnabled());
                  if (isDraftEnabled()) setIsDraft(e.target.checked);
                  else e.preventDefault();
                }}
                color={"warning"}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            disableTypography={true}
            sx={{ fontFamily: "Poppins" }}
            label={"DRAFT"}
          />
        </div>
        <div className="d-flex">
          <div className="d-flex flexWrap justifyFS" style={{ width: "66%" }}>
            <MuiDatePicker
              required={true}
              style={styles.inputField}
              error={!!error?.dispatch_date}
              helperText={error?.dispatch_date}
              invalidLabel=""
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
              allowKeyboardControl={false}
              autoOk
              inputVariant="outlined"
              label={"Dispatch Date"}
              value={inputData?.dispatch_date || ""}
              onChange={(newValue) => {
                handleInputDataChange("dispatch_date", newValue);
              }}
            />
            <CustomAutocomplete
              id="contractType"
              required={true}
              options={
                ContractTypeList?.filter(
                  (d) => d.contract_type !== "ex loose"
                ) || []
              }
              getOptionLabel={(option) =>
                option?.contract_type ? formatLabel(option?.contract_type) : ""
              }
              value={inputData.contract_type || null}
              error={!!error.contract_type}
              helperText={error.contract_type}
              onChange={(e, newValue) => {
                handleInputDataChange("contract_type", newValue);
              }}
              style={styles.inputField}
              label="Contract Type"
            />
            <CustomAutocomplete
              id="dispatchFrom"
              required={true}
              disabled={true}
              options={[]}
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              value={inputData.dispatch_from || null}
              error={false}
              onChange={(e, newValue) => {
                handleInputDataChange("dispatch_from", newValue);
              }}
              style={styles.inputField}
              label="Dispatch From"
            />
            <CustomAutocomplete
              id="dispatchTo"
              required={true}
              options={masters["PortList"] || []}
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              value={inputData.dispatch_to || null}
              error={!!error.dispatch_to}
              helperText={error.dispatch_to}
              onChange={(e, newValue) => {
                handleInputDataChange("dispatch_to", newValue);
              }}
              style={styles.inputField}
              label="Dispatch To (Port)"
            />
            <CustomAutocomplete
              id="unit"
              required={true}
              options={UnitList || []}
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              value={inputData.unit || null}
              error={!!error.unit}
              helperText={error.unit}
              onChange={(e, newValue) => {
                handleInputDataChange("unit", newValue);
              }}
              style={styles.inputField}
              label="Unit"
            />
            <CssTextField
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                      {inputData?.unit?.name || ""}
                    </span>
                  </InputAdornment>
                ),
              }}
              style={styles.inputField}
              id="outlined-basic"
              label={"Net weight"}
              type={"number"}
              onFocus={StopScroll}
              value={inputData?.net_weight || ""}
              error={!!error.net_weight}
              helperText={
                error.net_weight ||
                `Remaining : ${
                  handleRemainingQty(singleDispatch, inputData) || ""
                }`
              }
              onKeyDown={(evt) => {
                ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
              }}
              onChange={(e) => {
                handleInputDataChange("net_weight", e.target.value);
              }}
              variant="outlined"
            />
          </div>
          <div
            style={{
              // width: 360,
              flexGrow: 1,
              border: "1px solid gainsboro",
              borderRadius: 10,
              padding: "0px 5px 0px 8px",
              maxHeight: "220px",
            }}
          >
            <h4 style={{ margin: "6px 0 2px" }}>Brand Details</h4>
            <div
              className="d-flex d-flex-column justifySB"
              style={{ overflowY: "auto", height: "85%", paddingRight: 3 }}
            >
              {inventoryStock.map((d, i) => {
                let packagingData = d.packaging_data;
                return (
                  <div
                    style={{
                      border: "1px solid gainsboro",
                      margin: "10px 0",
                      borderRadius: 10,
                    }}
                  >
                    <div
                      className="d-flex justifySB"
                      style={{
                        fontFamily: "Poppins",
                        background: "#eee",
                        padding: "2px 8px",
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                      }}
                    >
                      <span style={{ fontSize: 13, flexGrow: 1 }}>
                        {d?.brand_name || ""}
                      </span>
                      <div style={{ display: "flex", minWidth: 140 }}>
                        <span
                          style={{
                            color: primary,
                            fontWeight: 500,
                            width: "50%",
                            textAlign: "center",
                            fontSize: 12,
                          }}
                        >
                          Available
                        </span>
                        <span
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontSize: 12,
                          }}
                        >
                          Used
                        </span>
                      </div>
                    </div>
                    <Divider
                      variant="middle"
                      style={{ margin: "0", padding: "0" }}
                    />
                    {packagingData.map((p, index) => {
                      let data = p;
                      return (
                        <div
                          className="d-flex d-flex-column"
                          style={{ padding: "2px 8px" }}
                          key={i}
                        >
                          <div className="d-flex justifySB">
                            <span style={{ fontSize: 12, flexGrow: 1 }}>
                              {`${data?.size} ${data?.unit} ${data?.bag_type}`}
                            </span>
                            <div style={{ display: "flex", minWidth: 140 }}>
                              <span
                                style={{
                                  color: primary,
                                  fontWeight: 500,
                                  width: "50%",
                                  textAlign: "center",
                                  fontSize: 12,
                                }}
                              >
                                {Number(p?.total_bags || 0)}
                              </span>
                              <span
                                style={{
                                  width: "50%",
                                  textAlign: "end",
                                  fontSize: 12,
                                }}
                              >
                                {Number(p?.used_bags || 0)}
                              </span>
                            </div>
                          </div>
                          {index !== packagingData?.length - 1 && (
                            <Divider
                              variant="middle"
                              style={{ margin: "1px 0" }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex flexWrap justifyFS">
          <CustomSearchAutocomplete
            id="sender"
            required={
              !Boolean(inputData.sender_name && inputData.sender_address)
            }
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            value={inputData.sender || null}
            error={!!error.sender}
            helperText={error.sender}
            onChange={(e, newValue) => {
              handleInputDataChange("sender", newValue);
            }}
            style={styles.inputField}
            label="Sender"
            placeholder="Search Sender"
            multiLineRender={[
              { label: "", key: "name" },
              { label: "Address :", key: "address" },
            ]}
            api={{
              callFn: props.InlandGodownFunction,
              url: EndPoints["Shippers"],
              resKey: "ShippersList",
              params: { is_active: "True" },
            }}
          />
          <CssTextField
            required={true}
            disabled={!Boolean(inputData.sender)}
            style={styles.inputField}
            id="outlined-basic"
            label={"Sender Name"}
            type="text"
            value={inputData?.sender_name || ""}
            error={!!error.sender_name}
            helperText={error.sender_name}
            onChange={(e) => {
              handleInputDataChange("sender_name", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            disabled={!Boolean(inputData.sender)}
            style={styles.inputAddressField}
            id="outlined-basic"
            label={"Sender Address"}
            type="text"
            multiline
            minRows={2}
            value={inputData?.sender_address || ""}
            error={!!error.sender_address}
            helperText={error.sender_address}
            onChange={(e) => {
              handleInputDataChange("sender_address", e.target.value);
            }}
            variant="outlined"
          />
        </div>
        <div className="d-flex flexWrap justifyFS">
          <CustomSearchAutocomplete
            id="receiver"
            required={
              !Boolean(
                inputData.receiver_name &&
                  // inputData.receiver_city &&
                  inputData.receiver_address
              )
            }
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            value={inputData.receiver || null}
            error={!!error.receiver}
            helperText={error.receiver}
            onChange={(e, newValue) => {
              handleInputDataChange("receiver", newValue);
            }}
            style={styles.inputField}
            label="Receiver"
            placeholder="Search Receiver"
            multiLineRender={[
              { label: "", key: "name" },
              { label: "City :", key: "city" },
              { label: "Address :", key: "address" },
            ]}
            api={{
              callFn: props.InlandGodownFunction,
              url: EndPoints["Shippers"],
              resKey: "ShippersList",
              params: { is_active: "True" },
            }}
          />
          <CssTextField
            required={true}
            disabled={!Boolean(inputData.receiver)}
            style={styles.inputField}
            id="outlined-basic"
            label={"Receiver Name"}
            type="text"
            value={inputData?.receiver_name || ""}
            error={!!error.receiver_name}
            helperText={error.receiver_name}
            onChange={(e) => {
              handleInputDataChange("receiver_name", e.target.value);
            }}
            variant="outlined"
          />
          {/* <CssTextField
            required={true}
            disabled={!Boolean(inputData.receiver)}
            style={styles.inputField}
            id="outlined-basic"
            label={"Receiver City"}
            type="text"
            value={inputData?.receiver_city || ""}
            error={!!error.receiver_city}
            helperText={error.receiver_city}
            onChange={(e) => {
              handleInputDataChange("receiver_city", e.target.value);
            }}
            variant="outlined"
          /> */}
          <CssTextField
            required={true}
            disabled={!Boolean(inputData.receiver)}
            style={styles.inputAddressField}
            id="outlined-basic"
            label={"Receiver Address"}
            type="text"
            multiline
            minRows={2}
            value={inputData?.receiver_address || ""}
            error={!!error.receiver_address}
            helperText={error.receiver_address}
            onChange={(e) => {
              handleInputDataChange("receiver_address", e.target.value);
            }}
            variant="outlined"
          />
        </div>
        <div style={{ margin: "20px 0" }} className="borderRadius7" id="item">
          <TableComponent
            {...props}
            module={"Pre Dispatch Item"}
            noAddButton={true}
            noSearchBar={true}
            simpleTable={true}
            padding={"0px 10px"}
            tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
            headerShownAlways={true}
            tableRowHeight={30}
            loader={false}
            actions={[]}
            header={itemHeader.map((d) => d.label)}
            checkBoxUI={{
              isvisible: true,
              header: null,
              component: (singleItemRow) => (
                <Checkbox
                  onChange={(e) => {
                    handleItemRowCheckBox(e.target.checked, singleItemRow.id);
                  }}
                  checked={Boolean(
                    itemRowCheckBox.find((d) => d == singleItemRow.id)
                  )}
                  style={{ color: primary, padding: "0px 4px" }}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": "head" }}
                />
              ),
            }}
            tableHeight="auto"
            tablePagination={false}
            footer={true}
            footerList={
              singleDispatch?.subList?.length && {
                "Item Category": "Total",
              }
            }
            tableList={
              singlePreDispatch?.id
                ? inputData?.subList && inputData?.subList.length
                  ? inputData?.subList.map((s) => {
                      console.log(
                        "itemsLIST1",
                        inputData,
                        "2",
                        itemBrandPackagingData,
                        "3",
                        s
                      );
                      let rowData = itemBrandPackagingData?.find(
                        (d) => d.id === s.id
                      );

                      itemHeader.map((header) => {
                        s[header.label] =
                          header.key === "item_category"
                            ? ItemCateoryList?.find(
                                (d) => d.id === Number(s[header.key])
                              )?.name || ""
                            : header.key === "item_type"
                            ? ItemList?.find(
                                (d) => d.id === Number(s[header.key])
                              )?.broken_percentage
                            : header.key === "hsn_code"
                            ? HSNlist?.find(
                                (d) => d.id === Number(s[header.key])
                              )?.hsn_code
                            : s[header.key] || "";
                      });
                      s["Item TypeUnit"] = s["Item Type"] + "%";
                      s["Brand"] = () => (
                        <CustomAutocomplete
                          required={true}
                          options={masters["BrandList"] || []}
                          getOptionLabel={(option) =>
                            option?.brand_name ? option?.brand_name : ""
                          }
                          value={rowData?.brand || null}
                          size="small"
                          error={!!rowData?.brandError}
                          helperText={rowData?.brandError}
                          onChange={(e, newValue) => {
                            handleBrandPackagingDataChange(
                              "brand",
                              newValue,
                              s.id
                            );
                          }}
                          style={styles.brandPackagingField}
                          label=""
                        />
                      );
                      s["Contract Rate"] = () => (
                        <CssTextField95
                          required={true}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment>
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: 14,
                                  }}
                                >
                                  ₹
                                </span>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment>
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: 14,
                                  }}
                                >
                                  / {inputData?.unit?.name || ""}
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          value={rowData?.contract_rate || null}
                          size="small"
                          error={!!rowData?.contractRateError}
                          helperText={rowData?.contractRateError}
                          type={"number"}
                          onFocus={StopScroll}
                          onKeyDown={(evt) => {
                            ["e", "E", "-", "+"].includes(evt.key) &&
                              evt.preventDefault();
                          }}
                          onChange={(e) => {
                            /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                              handleBrandPackagingDataChange(
                                "contract_rate",
                                e.target.value,
                                s.id
                              );
                          }}
                          style={styles.brandPackagingField}
                          label=""
                          variant="outlined"
                        />
                      );
                      s["Packaging"] = () => (
                        <div className="d-flex justifyC alignC">
                          <ShowPackaging
                            noTotalbags={true}
                            totalCiBags={true}
                            details={rowData.packaging.map((sP) => {
                              const newAP = sP;
                              newAP.used_bags = sP.add_bags;
                              console.log(newAP, "newap");
                              return newAP;
                            })}
                          />
                          {
                            <AddPackagingBag
                              masters={masters}
                              rowData={rowData}
                              handleBrandPackagingDataChange={
                                handleBrandPackagingDataChange
                              }
                              itemRowPackagingData={rowData?.packaging || []}
                              rowInventoryStockList={
                                inventoryStock.find(
                                  (inv) => inv.brand_id == rowData.brand?.id
                                )?.packaging_data
                              }
                              itemID={s.id}
                            />
                          }
                        </div>
                      );
                      console.log("s", s);
                      return s;
                    })
                  : []
                : []
            }
          />
          {console.log("ITEMROWERROR", itemRowError)}
          {itemRowError && (
            <div
              className="d-flex justifyC"
              style={{ color: "red", marginTop: 10 }}
            >
              {itemRowError || ""}
            </div>
          )}
        </div>
        <div className="d-flex flexWrap justifyFS">
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Bill Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.gst_amount || ""}
            error={!!error.gst_amount}
            helperText={error.gst_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                handleInputDataChange("gst_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Igst Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.igst_amount || ""}
            error={!!error.igst_amount}
            helperText={error.igst_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                handleInputDataChange("igst_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Cgst Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.cgst_amount || ""}
            error={!!error.cgst_amount}
            helperText={error.cgst_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                handleInputDataChange("cgst_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Sgst Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.sgst_amount || ""}
            error={!!error.sgst_amount}
            helperText={error.sgst_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                handleInputDataChange("sgst_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Other Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.other_amount || ""}
            error={!!error.other_amount}
            helperText={error.other_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                handleInputDataChange("other_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Other Deduction"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.other_deduction || ""}
            error={!!error.other_deduction}
            helperText={error.other_deduction}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              /^\d*\.?\d{0,2}$/.test(e.target.value) &&
                handleInputDataChange("other_deduction", e.target.value);
            }}
            variant="outlined"
          />
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CssTextField
              required={true}
              disabled={checkItemSelected()}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                      ₹
                    </span>
                  </InputAdornment>
                ),
              }}
              style={styles.inputField}
              id="outlined-basic"
              label={"Transport Advance"}
              type={"number"}
              onFocus={StopScroll}
              value={inputData?.transport_advance || ""}
              error={!!error.transport_advance}
              helperText={error.transport_advance}
              onKeyDown={(evt) => {
                ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
              }}
              onChange={(e) => {
                handleInputDataChange("transport_advance", e.target.value);
              }}
              variant="outlined"
            />
          )}
          <CssTextField
            required={true}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <span style={{ fontFamily: "Poppins", fontSize: 14 }}>₹</span>
                </InputAdornment>
              ),
            }}
            style={styles.inputField}
            id="outlined-basic"
            label={"Total Amount"}
            type={"number"}
            onFocus={StopScroll}
            value={inputData?.total_amount}
            error={!!error.total_amount}
            helperText={error.total_amount}
            onKeyDown={(evt) => {
              ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
            }}
            onChange={(e) => {
              handleInputDataChange("total_amount", e.target.value);
            }}
            variant="outlined"
          />
          <CssTextField
            required={true}
            disabled={checkItemSelected()}
            style={styles.inputField}
            id="outlined-basic"
            label={"Delivery Challan Number"}
            type="text"
            value={inputData?.delivery_challan_number || ""}
            error={!!error.delivery_challan_number}
            helperText={error.delivery_challan_number}
            onChange={(e) => {
              handleInputDataChange("delivery_challan_number", e.target.value);
            }}
            variant="outlined"
          />
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CustomSearchAutocomplete
              id="transportName"
              required={true}
              disabled={checkItemSelected()}
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              value={inputData.transport || null}
              error={!!error.transport}
              helperText={error.transport}
              onChange={(e, newValue) => {
                handleInputDataChange("transport", newValue);
              }}
              style={styles.inputField}
              label="Transport"
              placeholder="Search Transport"
              multiLineRender={[
                { label: "", key: "name" },
                { label: "Address :", key: "address" },
              ]}
              api={{
                callFn: props.InlandGodownFunction,
                url: EndPoints["Transporters"],
                resKey: "TransporterList",
                params: { is_active: "True" },
              }}
            />
          )}
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CssTextField
              required={true}
              disabled={checkItemSelected()}
              style={styles.inputField}
              id="outlined-basic"
              label={"Truck Number"}
              type="text"
              value={inputData?.truck_number || ""}
              error={!!error.truck_number}
              helperText={error.truck_number}
              onChange={(e) => {
                handleInputDataChange("truck_number", e.target.value);
              }}
              variant="outlined"
            />
          )}
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CssTextField
              required={true}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                      ₹
                    </span>
                  </InputAdornment>
                ),
              }}
              style={styles.inputField}
              id="outlined-basic"
              label={"Transport Balance"}
              type={"number"}
              onFocus={StopScroll}
              value={inputData?.transport_balance || ""}
              error={!!error.transport_balance}
              helperText={error.transport_balance}
              onKeyDown={(evt) => {
                ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
              }}
              onChange={(e) => {
                handleInputDataChange("transport_balance", e.target.value);
              }}
              variant="outlined"
            />
          )}
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CssTextField
              required={true}
              disabled={checkItemSelected()}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                      / {inputData?.unit?.name || ""}
                    </span>
                  </InputAdornment>
                ),
              }}
              style={styles.inputField}
              id="outlined-basic"
              label={"Freight Rate"}
              type={"number"}
              onFocus={StopScroll}
              value={inputData?.freight_rate || ""}
              error={!!error.freight_rate}
              helperText={error.freight_rate}
              onKeyDown={(evt) => {
                ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
              }}
              onChange={(e) => {
                handleInputDataChange("freight_rate", e.target.value);
              }}
              variant="outlined"
            />
          )}
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CssTextField
              required={true}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <span style={{ fontFamily: "Poppins", fontSize: 14 }}>
                      ₹
                    </span>
                  </InputAdornment>
                ),
              }}
              style={styles.inputField}
              id="outlined-basic"
              label={"Total Freight"}
              type={"number"}
              onFocus={StopScroll}
              value={inputData?.total_freight || ""}
              error={!!error.total_freight}
              helperText={error.total_freight}
              onKeyDown={(evt) => {
                ["e", "E", "-", "+"].includes(evt.key) && evt.preventDefault();
              }}
              onChange={(e) => {
                handleInputDataChange("total_freight", e.target.value);
              }}
              variant="outlined"
            />
          )}
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <CssTextField
              required={true}
              disabled={checkItemSelected()}
              style={styles.inputField}
              id="outlined-basic"
              label={"Bilty"}
              type="text"
              value={inputData?.bilty || ""}
              error={!!error.bilty}
              helperText={error.bilty}
              onChange={(e) => {
                handleInputDataChange("bilty", e.target.value);
              }}
              variant="outlined"
            />
          )}
          {Boolean(
            inputData?.contract_type?.contract_type === "ex factory"
          ) && (
            <MuiDatePicker
              required={true}
              disabled={checkItemSelected()}
              style={styles.inputField}
              error={!!error?.bilty_date}
              helperText={error?.bilty_date}
              invalidLabel=""
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
              allowKeyboardControl={false}
              autoOk
              inputVariant="outlined"
              label={"Bilty Date"}
              value={inputData?.bilty_date || ""}
              onChange={(newValue) => {
                handleInputDataChange("bilty_date", newValue);
              }}
            />
          )}
          <CustomSearchAutocomplete
            id="surveyCompany"
            required={true}
            disabled={checkItemSelected()}
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            value={inputData.survey_company || null}
            error={!!error.survey_company}
            helperText={error.survey_company}
            onChange={(e, newValue) => {
              handleInputDataChange("survey_company", newValue);
            }}
            style={styles.inputField}
            label="Survey Company"
            placeholder="Search Survey Company"
            multiLineRender={[
              { label: "", key: "name" },
              { label: "GST :", key: "gstin" },
              { label: "Address :", key: "address" },
            ]}
            api={{
              callFn: props.InlandGodownFunction,
              url: EndPoints["SurveyCompanys"],
              resKey: "SurveyCompanyList",
              params: { is_active: "True" },
            }}
          />
          <CustomSearchAutocomplete
            id="broker"
            required={false}
            disabled={false}
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            value={inputData.broker || null}
            error={!!error.broker}
            helperText={error.broker}
            onChange={(e, newValue) => {
              handleInputDataChange("broker", newValue);
            }}
            style={styles.inputField}
            label="Broker"
            placeholder="Search Brokers"
            multiLineRender={[
              { label: "", key: "name" },
              { label: "GST :", key: "gstin" },
              { label: "Address :", key: "address" },
            ]}
            api={{
              callFn: props.InlandGodownFunction,
              url: EndPoints["Brokers"],
              resKey: "BrokersList",
              params: { is_active: "True" },
            }}
          />
          {console.log(
            "INPUT",
            inputData,
            inputData.dispatch_status?.name == "reached",
            formatLabel(inputData.dispatch_status?.name)
          )}
          <CustomAutocomplete
            id="dispatchStatus"
            required={true}
            disabled={true}
            options={StatusDispatch || []}
            getOptionLabel={(option) =>
              option?.name ? formatLabel(option?.name) : ""
            }
            value={inputData.dispatch_status || null}
            error={!!error.dispatch_status}
            helperText={error.dispatch_status}
            onChange={(e, newValue) => {
              handleInputDataChange("dispatch_status", newValue);
            }}
            style={styles.inputField}
            label="Dispatch Status"
          />
          <CssTextField
            required={false}
            disabled={checkItemSelected()}
            style={styles.inputField}
            id="outlined-basic"
            label={"Remarks"}
            type="text"
            value={inputData?.remarks || ""}
            error={!!error.remarks}
            helperText={error.remarks}
            onChange={(e) => {
              handleInputDataChange("remarks", e.target.value);
            }}
            variant="outlined"
          />
        </div>
      </CustomDialog>
    </>
  );
};
