import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { withStyles } from "@material-ui/styles";
import { Chip } from "@material-ui/core";
import { primary, mostLightGrey } from "../styles/colors";
import { StopScroll } from "../Regex";
import { CssTextField } from "../styles/StyledComponents";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssCheckbox = withStyles({
  "&.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input": {
    padding: "0",
  },
  root: {},
})(Checkbox);
const CssAutocomplete = withStyles({
  root: {
    "& .MuiMenu-list": { textTransform: "capitalize" },
    "& .MuiOutlinedInput-root": {
      padding: "5.5px 15px !important",
      height: "auto",
      minHeight: "49px",
      width: "100%",
    },
    "&.MuiInputLabel-formControl": {
      transform: "translate(14px, 14px) scale(1) !important",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 14px) scale(1) !important",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: " translate(14px, -8px) scale(0.75) !important",
    },
  },
  // }
})(Autocomplete);
const CssAutocomplete95 = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      padding: "0 9.5px !important",
      height: "auto",
      minHeight: "37.99px",
      width: "100%",
    },
    "& label.Mui-focused": {
      color: primary,
    },
    "& fieldset": {
      borderColor: mostLightGrey,
    },
    "&:hover fieldset": {
      borderColor: primary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: primary,
    },
    "&.MuiInputLabel-formControl": {
      transform: "translate(9.5px, 9.5px) scale(1) !important",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(9.5px, 9.5px) scale(1) !important",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: " translate(15px, -8px) scale(0.75) !important",
    },
  },
  // }
})(Autocomplete);
export default function CheckboxesTags(props) {
  const {
    array,
    dataType,
    name,
    title,
    error,
    helperText,
    value,
    disabled,
    only_disabled,
    onlyDisable,
    freeSolo,
    small,
    placeholder,
    setERrorFalse,
    id,
    label,
    required,
    disabledItem,
  } = props;
  console.log(array, value, "arrayarray");
  console.log("MULTIPLE", props)
  // const [textfieldvalue, settextfieldvalue] = React.useState("");

  // const [arrays, setArry] = React.useState([]);
  // React.useEffect(() => {
  //   setArry(value);
  // }, [value]);
  return disabled 
  ? (
    <div
      className="d-flex justifyC alignC positionR"
      style={{
        height: props?.height || "43px",
        // borderRadius: '3px',
        textTransform: "capitalize",
        width: props.width ? props.width : "100%",
        borderBottom: "1px solid #00000069",
        padding: "0",
        position: "relative",
      }}
    >
      <div
        style={{
          background: "rgb(40 80 203 / 0%)",
          position: "absolute",
          top: "-9px",
          left: "0px",
          color: "#0000007a",
          fontSize: "0.7rem",
        }}
      >
        {label ? label : title === "city" || title === "nameCity" ? "Ports" : "Packaging"}
      </div>
      <div
        className="scrollBluePrimary d-flex alignC "
        style={{
          height: "100%",

          overflowX: "auto",
          overflowY: "hidden",
          width: "100%",
        }}
      >
        <div
          className=" d-flex alignC justifySE"
          style={{
            height: "fit-content",
            width: "fit-content",
            margin: "0 10px",
            padding: "10px 0",
            flexWrap: "wrap",
          }}
        >
          {value && value?.length > 0
          ? value.map((option, index) => (
            <Chip size="small" key={value.toString()} label={option[title]} style={{ margin: "2px 5px" }} />
          ))
          : ""}
        </div>
      </div>
    </div>
  ) 
  : small 
  ? (
    <CssAutocomplete95
      className={onlyDisable && "only_disabled"}
      disabled={only_disabled ? only_disabled : false}
      value={value && value.length > 0 && typeof value !== "string" ? value : []}
      multiple={props?.multiple || true}
      onChange={(event, newValue) => {
        if (props && props.selectedArr) {
          props.selectedArr(newValue);
        }
      }}
      freeSolo={freeSolo ? freeSolo : false}
      id="checkboxes-tags-demo"
      options={freeSolo ? [] : array && array.length > 0 ? array : []}
      disableCloseOnSelect
      getOptionLabel={(option) => (freeSolo ? option : option[title])}
      renderTags={(value, getTagProps) => {
        return value && value.length > 0 && typeof value !== "string"
          ? value.map((option, index) =>
              freeSolo ? (
                <Chip
                  key={value.toString()}
                  variant="outlined"
                  label={option}
                  size="small"
                  {...getTagProps({ index })}
                />
              ) : onlyDisable ? (
                <Chip size="small" key={value.toString()} label={option[title]} />
              ) : (
                <Chip
                  key={value.toString()}
                  variant="outlined"
                  label={freeSolo ? option : option[title]}
                  size="small"
                  {...getTagProps({ index })}
                />
              )
            )
          : "";
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <CssCheckbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {freeSolo ? option : option[title]}
        </li>
      )}
      style={
        {
          pointerEvents: onlyDisable ? "none" : "",
          textTransform: "capitalize",
          width: props.width ? props.width : "calc(40% - 5px)",
          margin: props.margin ? props.margin : "0",
          // height:"48.98px"
        } //onlyDisable={tp}
      }
      renderInput={(params) => (
        <CssTextField
          variant="outlined"
          onFocus={StopScroll}
          type={dataType === "number" ? "number" : "text"}
          onKeyDown={(evt) => {
            if (dataType === "number") {
              evt.key === "e" && evt.preventDefault();
              evt.key === "." && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }
          }}
          error={false}
          helperText={helperText ? helperText : freeSolo ? "Press Enter to add!" : ""}
          style={{ textTransform: "capitalize" }}
          {...params}
          label={freeSolo ? name : label ? label : title === "city" || title === "nameCity" ? "Ports" : "Packaging"}
          placeholder={
            freeSolo
              ? name
              : onlyDisable
              ? ""
              : label
              ? label
              : title === "city" || title === "nameCity"
              ? "Ports"
              : "Packaging"
          }
          onChange={(e) => {
            if (setERrorFalse) {
              setERrorFalse();
            }
          }}
        />
      )}
    />
  ) 
  : 
  (
    <CssAutocomplete
      required={required}
      disabled={only_disabled ? only_disabled : false}
      className={onlyDisable && "only_disabled"}
      placeholder={
        placeholder ? placeholder : onlyDisable ? "" : title === "city" || title === "nameCity" ? "Ports" : "Packaging"
      }
      value={value && value.length > 0 ? value : []}
      multiple={props?.multiple || true}
      onChange={(event, newValue) => {
        const id = [];
        const newArr = [];
        if (freeSolo) {
        } else {
          newValue.map((s) => {
            if (!id.includes(s?.id)) {
              newArr.push(s);
              id.push(s?.id);
            }
          });
        }
        if (props && props.selectedArr) {
          props.selectedArr(freeSolo ? newValue : newArr);
        }
      }}
      freeSolo={freeSolo ? freeSolo : false}
      id={id ? id : "checkboxes-tags-demo"}
      options={
        freeSolo
          ? []
          : array && array.length > 0
          ? array.filter(
              (s) =>
                !value
                  .map((ss) => {
                    return ss?.id;
                  })
                  .includes(s?.id)
            )
          : []
      }
      disableCloseOnSelect
      getOptionLabel={(option) => (freeSolo ? option : option[title])}
      renderTags={(value, getTagProps) =>
        value.map((option, index) =>
          freeSolo ? (
            <Chip key={value.toString()} variant="outlined" label={option} size="small" {...getTagProps({ index })} />
          ) : onlyDisable ? (
            <Chip size="small" key={value?.toString()} label={option?.[title]} />
          ) : (
            <Chip
              key={value?.toString()}
              variant="outlined"
              label={freeSolo ? option : option?.[title]}
              size="small"
              onDelete={
                disabledItem && disabledItem?.includes(option.id) ? null : getTagProps({ index }).onDelete // Disable delete for protected tags
              }
              style={{
                backgroundColor: disabledItem && disabledItem?.includes(option.id) ? "rgba(0, 0, 0, 0.1)" : undefined,
                color: disabledItem && disabledItem?.includes(option.id) ? "rgba(0, 0, 0, 0.4)" : 'inherit',
                pointerEvents: disabledItem && disabledItem?.includes(option.id) ? "none" : "auto",
              }}
              {...getTagProps({ index })}
            />
          )
        )
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <CssCheckbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {freeSolo ? option : option[title]}
        </li>
      )}
      style={
        {
          pointerEvents: onlyDisable ? "none" : "",
          textTransform: "capitalize",
          width: props.width ? props.width : "calc(40% - 5px)",
          margin: props.margin ? props.margin : "0",
          // height:"48.98px"
        } //onlyDisable={tp}
      }
      renderInput={(params) => (
        <CssTextField
          variant="outlined"
          required={required}
          type={dataType === "number" ? "number" : "text"}
          onKeyDown={(evt) => {
            if (dataType === "number") {
              evt.key === "e" && evt.preventDefault();
              evt.key === "." && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }
          }}
          error={error ? true : false}
          helperText={helperText ? helperText : freeSolo ? "Press Enter to add!" : ""}
          style={{ textTransform: "capitalize" }}
          {...params}
          label={
            label
              ? label
              : placeholder
              ? placeholder
              : freeSolo
              ? name
              : title === "city" || title === "nameCity"
              ? "Ports"
              : "Packaging"
          }
          placeholder={
            placeholder
              ? placeholder
              : freeSolo
              ? name
              : onlyDisable
              ? ""
              : title === "city" || title === "nameCity"
              ? "Ports"
              : "Packaging"
          }
          value={value}
        />
      )}
    />
  );
}
