import { DialogActions, Typography } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Slide,
  Switch,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { primary } from "../../styles/colors";
import { CrossIcon } from "../../styles/Icons";
import ReactDOM from "react-dom";
import { CssTextField, StyledButton } from "../../styles/StyledComponents";
import { StopScroll } from "../../Regex";
import { align } from "@progress/kendo-drawing";
import { Edit } from "@material-ui/icons";
import { Cancel, Clear, Delete, Done } from "@mui/icons-material";
import { useSnackbar } from "../../snackbarContext";
import { set } from "lodash";

const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  fields: { width: "calc(40% - 20px)", padding: "20px", margin: "20px" },
  dialogContentTopContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "5px 0 20px 0",
    width: "100%",
  },
  dialogContentBottomContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "5px 0 20px 0",
    width: "100%",
  },
  dialogFieldDiv: {
    display: "flex",
    width: "100%",
    fontSize: "1rem",
    color: "grey",
    background: "white",
    minHeight: "71.91px",
  },
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "grey",
    margin: "0px 0 10px 0",
  },
  unloadingDialogLeftFieldDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "40%",
    fontSize: "1rem",
    color: "grey",
    background: "white",
    minHeight: "71.91px",
  },
  unloadingDialogRightFieldDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    width: "60%",
    height: "100%",
    fontSize: "1rem",
    color: "grey",
    background: "white",
    minHeight: "71.91px",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InlandGodownAddEdit = (props) => {
  const showSnackbar = useSnackbar();
  const [inlandGodownData, setInlandGodownData] = useState({});
  const [inlandUnloadingAddData, setInlandUnloadingAddData] = useState({
    name: "",
  });
  const [inlandUnloadingEditData, setInlandUnloadingEditData] = useState({
    id: "",
    name: "",
    is_active: true,
    operation: "view",
  });

  const [errors, setErrors] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
  });

  const resetAllState = () => {
    setInlandGodownData({});
    setInlandUnloadingAddData({ name: "" });
    setInlandUnloadingEditData({
      id: "",
      name: "",
      is_active: true,
      operation: "view",
    });
    setErrors({
      name: "",
      address: "",
      city: "",
      state: "",
    });
  };

  const handleChangeData = (name, value) => {
    setInlandGodownData({
      ...inlandGodownData,
      [name]: value,
    });
  };

  const handleChangeUnloadingData = (name, value) => {
    setInlandUnloadingAddData({
      ...inlandUnloadingAddData,
      [name]: value,
    });
  };

  const handleChangeUnloadingEditData = (name, value) => {
    setInlandUnloadingEditData({
      ...inlandUnloadingEditData,
      [name]: value,
    });
  };

  const handleUnloadingAgentAdd = () => {
    if (inlandUnloadingAddData?.name.trim()) {
      let payload = {
        name: inlandUnloadingAddData?.name,
        is_active: true,
        inland_godown: inlandGodownData?.id,
      };

      props
        .InlandGodownFunction(
          "post",
          "masters/inland-godown-unloading-agent/list",
          null,
          "post",
          payload,
          null,
          null,
        )
        .then((res) => {
          if (res.error) {
            showSnackbar(res.data, "error");
          } else {
            setInlandUnloadingAddData({
              name: "",
            });
            showSnackbar(`Unloading Agent Added Successfully`, "success");

            let newData = [...inlandGodownData?.inland_godown_unloading_agents];
            console.log(res);
            newData.push({
              id: res?.id,
              name: res?.name,
              is_active: true,
            });

            setInlandGodownData({
              ...inlandGodownData,
              inland_godown_unloading_agents: newData,
            });
            props.fetchInlandGodownList();
            props.fetchSingleListData(inlandGodownData?.id);
          }
        });
    }
  };
  const handleUnloadingAgentRowSave = () => {
    if (inlandUnloadingEditData?.name.trim()) {
      let payload = {
        name: inlandUnloadingEditData?.name,
        id: inlandUnloadingEditData?.id,
        is_active: inlandUnloadingEditData?.is_active,
      };

      props
        .InlandGodownFunction(
          "patch",
          "masters/inland-godown-unloading-agent",
          inlandUnloadingEditData?.id,
          null,
          payload,
          null,
          null
        )
        .then((res) => {
          if (res.error) {
            showSnackbar(res.data, "error");
          } else {
            setInlandUnloadingEditData({
              name: "",
              id: "",
              is_active: true,
              operation: "view",
            });
            let newData = inlandGodownData?.inland_godown_unloading_agents?.map(
              (item) => {
                if (item?.id === inlandUnloadingEditData?.id) {
                  return {
                    ...item,
                    name: res?.name,
                    is_active: res?.is_active,
                  };
                }
                return item;
              }
            );

            setInlandGodownData((prevData) => ({
              ...prevData,
              inland_godown_unloading_agents: newData,
            }));
            showSnackbar(`Unloading Agent Updated Successfully`, "success");
            props.fetchInlandGodownList();
            props.fetchSingleListData(inlandGodownData?.id);
          }
        });
    }
  };

  const handleUnloadingAgentRowDelete = (id) => {
    if (id) {
      props
        .InlandGodownFunction(
          "delete",
          "masters/inland-godown-unloading-agent",
          id,
          null,
          null,
          null,
          null,
        )
        .then((res) => {
          if (res.error) {
            showSnackbar(res.data, "error");
          } else {
            showSnackbar(`Unloading Agent Deleted Successfully`, "success");
            setInlandGodownData({
              ...inlandGodownData,
              inland_godown_unloading_agents:
                inlandGodownData?.inland_godown_unloading_agents?.filter(
                  (item) => item.id !== id
                ),
            });
            props.fetchInlandGodownList();
            props.fetchSingleListData(inlandGodownData?.id);
          }
        });
    }
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      address: "",
      city: "",
      state: "",
    };

    if (!inlandGodownData?.name?.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!inlandGodownData?.address?.trim()) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!inlandGodownData?.city?.trim()) {
      newErrors.city = "City is required";
      isValid = false;
    }

    if (!inlandGodownData?.state?.trim()) {
      newErrors.state = "State is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    if (props.editData?.id) {
      setInlandGodownData(props.editData);
    }
  }, [props.editData]);

  useEffect(() => {
    console.log("inlandGodownData", inlandGodownData);
  }, [inlandGodownData]);

  useEffect(() => {
    return () => {
      resetAllState();
    };
  }, []);

  const { operation, handleInlandGodown } = props;

  return (
    <div>
      <Dialog
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children[2].children[0];
            target.style.borderRadius = "0px";
            target.style.maxHeight = "90%";
            target.style.minHeight =
              operation === "add" ? "fit-content" : "fit-content";
            target.style.height =
              operation === "add" ? "fit-content" : "fit-content";
            target.style.minWidth = operation === "add" ? "60%" : "70%";
            target.style.maxWidth =
              operation === "add" ? "calc(60% - 62px)" : "calc(70% - 62px)";
            target.style.margin = "32px";
          } catch (e) {}
        }}
        open={props?.openDialog}
        onClose={() => {
          resetAllState();
          props.closeDialog();
        }}
        TransitionComponent={Transition}
      >
        <DialogActions
          className="d-flex justifySB"
          style={{
            background: primary,
            color: "white",
            padding: "10px 24px",
          }}
          onClick={() => {
            resetAllState();
            props.closeDialog();
          }}
        >
          <h3 style={styles.heading}>
            {operation === "add"
              ? "Create Inland Godown"
              : "Edit Inland Godown"}
          </h3>
          <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
            <CrossIcon strokeWidth="2" />
          </IconButton>
        </DialogActions>
        <DialogContent
          className="dashedDividerBottom  width-100 d-flex d-flex-column alignC justifyC"
          // style={{ height: "100%", }}
        >
          <div style={{ width: "100%", overflowY: "auto", maxHeight: 600 }}>
            <div style={styles.dialogContentTopContainer}>
              <Typography style={styles.title}>
                Inland Godown Details
              </Typography>
              <div style={styles.dialogFieldDiv}>
                <CssTextField
                  autoComplete="off"
                  style={{ width: "calc(50% - 20px)", margin: "10px" }}
                  error={errors?.name ? true : false}
                  required={true}
                  helperText={errors?.name ? errors?.name : false}
                  InputProps={{}}
                  id="InlandGodownName"
                  label="Inland Godown Name"
                  variant="outlined"
                  value={inlandGodownData?.name || ""}
                  onChange={(e) => {
                    handleChangeData("name", e.target.value);
                  }}
                />
                <span
                  style={{
                    width: "calc(50% - 20px)",
                    margin: "10px",
                    textAlign: "end",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        color="warning"
                        checked={
                          inlandGodownData?.is_active === undefined
                            ? true
                            : inlandGodownData?.is_active
                        }
                        onChange={(e) => {
                          handleChangeData("is_active", e.target.checked);
                        }}
                      />
                    }
                    label="Active"
                  />
                </span>
              </div>
              <div style={styles.dialogFieldDiv}>
                <CssTextField
                  autoComplete="off"
                  style={{ width: "calc(100% - 20px)", margin: "10px" }}
                  error={errors?.address ? true : false}
                  required={true}
                  helperText={errors?.address ? errors?.address : false}
                  InputProps={{}}
                  multiline
                  minRows={2}
                  maxRows={3}
                  id="address"
                  label="Address"
                  variant="outlined"
                  value={inlandGodownData?.address || ""}
                  onChange={(e) => {
                    handleChangeData("address", e.target.value);
                  }}
                />
              </div>
              <div style={styles.dialogFieldDiv}>
                <CssTextField
                  autoComplete="off"
                  style={{ width: "calc(50% - 20px)", margin: "10px" }}
                  error={errors?.city ? true : false}
                  required={true}
                  helperText={errors?.city ? errors?.city : false}
                  InputProps={{}}
                  id="city"
                  label="City"
                  variant="outlined"
                  value={inlandGodownData?.city || ""}
                  onChange={(e) => {
                    handleChangeData("city", e.target.value);
                  }}
                />
                <CssTextField
                  autoComplete="off"
                  style={{ width: "calc(50% - 20px)", margin: "10px" }}
                  error={errors?.state ? true : false}
                  required={true}
                  helperText={errors?.state ? errors?.state : false}
                  InputProps={{}}
                  id="state"
                  label="State"
                  variant="outlined"
                  value={inlandGodownData?.state || ""}
                  onChange={(e) => {
                    handleChangeData("state", e.target.value);
                  }}
                />
              </div>
            </div>
            {operation !== "add" ? (
              <div style={styles.dialogContentBottomContainer}>
                <Typography style={styles.title}>Unloading Agent</Typography>
                <div className="d-flex alignFS width-100 justifyFS">
                  <div style={styles.unloadingDialogLeftFieldDiv}>
                    {inlandGodownData &&
                    inlandGodownData?.inland_godown_unloading_agents?.length > 0
                      ? [...inlandGodownData?.inland_godown_unloading_agents]
                          .reverse()
                          .map((item, index) => {
                            return inlandUnloadingEditData?.id === item?.id &&
                              inlandUnloadingEditData.operation === "edit" ? (
                              <div
                                className="d-flex width-100 alignC justifySB"
                                key={index}
                              >
                                <CssTextField
                                  autoComplete="off"
                                  style={{
                                    width: "calc(70% - 20px)",
                                    margin: "10px 0",
                                  }}
                                  error={
                                    !inlandUnloadingEditData?.name
                                      ? true
                                      : false
                                  }
                                  required={true}
                                  InputProps={{}}
                                  id="inlandUnloadingName"
                                  label="Name"
                                  variant="outlined"
                                  value={inlandUnloadingEditData?.name || ""}
                                  onChange={(e) => {
                                    handleChangeUnloadingEditData(
                                      "name",
                                      e.target.value
                                    );
                                  }}
                                />
                                <span>
                                  <Tooltip title="Save">
                                    <IconButton
                                      style={{ margin: "0 2px" }}
                                      onClick={() => {
                                        handleUnloadingAgentRowSave();
                                      }}
                                    >
                                      <Done />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cancel">
                                    <IconButton
                                      style={{ margin: "0 0 0 2px" }}
                                      onClick={() => {
                                        setInlandUnloadingEditData({
                                          id: "",
                                          name: "",
                                          is_active: true,
                                          operation: "view",
                                        });
                                      }}
                                    >
                                      <Clear />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="d-flex width-100  alignC justifySB"
                                key={index}
                                style={{ margin: "10px 0" }}
                              >
                                <span>{item?.name}</span>
                                <span>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      style={{ margin: "0 2px" }}
                                      onClick={() => {
                                        handleUnloadingAgentRowDelete(item?.id);
                                      }}
                                      color="error"
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      style={{ margin: "0 0 0 2px" }}
                                      onClick={() => {
                                        setInlandUnloadingEditData({
                                          id: item?.id,
                                          name: item?.name,
                                          is_active: item?.is_active,
                                          operation: "edit",
                                        });
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </div>
                            );
                          })
                      : null}
                  </div>
                  <div style={styles.unloadingDialogRightFieldDiv}>
                    <CssTextField
                      autoComplete="off"
                      style={{ width: "calc(50% - 20px)", margin: "10px" }}
                      required={true}
                      InputProps={{}}
                      id="inlandUnloadingName"
                      label="Name"
                      variant="outlined"
                      value={inlandUnloadingAddData?.name || ""}
                      onChange={(e) => {
                        handleChangeUnloadingData("name", e.target.value);
                      }}
                    />
                    <Button
                      style={{
                        padding: "10px",
                        margin: "0px 10px",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        background: primary,
                        color: "#fff",
                      }}
                      onClick={() => {
                        if (inlandUnloadingAddData?.name) {
                          handleUnloadingAgentAdd();
                        }
                      }}
                    >
                      Add Unloading Agent
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          className="d-flex justifyFE"
          style={{
            padding: "10px 24px",
          }}
        >
          <StyledButton
            name="Reset"
            onClick={() => {
              setInlandGodownData({});
              setErrors({});
            }}
          />
          <StyledButton
            name={operation === "add" ? "Add" : "Update"}
            minWidth="120px"
            margin="0 10px"
            withBg={true}
            onClick={() => {
              let check = validateFields();
              if (check) {
                handleInlandGodown(inlandGodownData, operation, "inlandGodown");
                resetAllState();
                props.closeDialog();
              }
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InlandGodownAddEdit;
