import React, { useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";
import moment from "moment";

const BrandEmptyBags = (props) => {
  const [BagEmptyList, setBagEmptyList] = useState([]);

  const fetchEmptyBags = async () => {
    props
      .BrandsManagementsFunction(
        "get",
        "arrival/commercial-invoice/empty-bags-history",
        null,
        "BagEmptyList",
        null,
        { is_active: "True", brand_id: props.brandId }
      )
      .then((BagEmptyList) => {
        const formattedArray = BagEmptyList.flatMap((item) => {
          // Check if the item is not undefined and has extra_empty_bags
          if (item && item.extra_empty_bags) {
            // Extract the values of extra_empty_bags
            const extraBagsValues = Object.values(item.extra_empty_bags);

            // Create a new object with the rest of the fields
            const restFields = { ...item };
            delete restFields.extra_empty_bags;

            // Create a new object for each set of extra empty bags
            return extraBagsValues.map((bags) => ({
              ...restFields,
              ...bags,
            }));
          } else {
            // If item is undefined or has no extra_empty_bags, return an empty array
            return [];
          }
        });
        setBagEmptyList(formattedArray);
      });
  };

  useEffect(() => {
    fetchEmptyBags();
  }, [window.location.pathname]);

  return (
    <div>
      <TableComponent
        module={"Empty Bags"}
        noAddButton={true}
        padding={"0px"}
        tableRowHeight={60}
        headerShownAlways={true}
        tableHeaderBGColor={"rgb(128 128 128 / 64%)"}
        loader={false}
        actions={[]}
        header={[
          "Sr.No.",
          "Invoice Date",
          "Invoice Number",
          "Packaging",
          "Empty Bags",
          "Com. Status",
        ]}
        tableHeight="auto"
        tablePagination={false}
        footer={false}
        tableList={
          BagEmptyList && BagEmptyList.length
            ? BagEmptyList.map((singleBag) => {
                singleBag["Invoice Date"] =
                  singleBag &&
                  moment(singleBag.invoice_date).format("ddd, MMM Do YYYY");
                singleBag["Empty Bags"] = singleBag.no_of_bags;
                singleBag["Invoice Number"] =
                  singleBag.commercial_invoice_number
                    ? singleBag.commercial_invoice_number
                    : "-";
                singleBag[
                  "Packaging"
                ] = `${singleBag.size} ${singleBag.unit} ${singleBag.bag_type}`;

                singleBag["Com. Status"] = singleBag.commercial_invoice_status;

                return singleBag;
              })
            : []
        }
      />
    </div>
  );
};

export default BrandEmptyBags;
