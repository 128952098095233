import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import {
  StyledButton,
  StyledCircularProgress,
  CssTextField,
} from "../../styles/StyledComponents";
import ReactDOM from "react-dom";
import TableComponent from "../../layoutComponents/TableComponent";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import NoData from "../../styles/Illustrations/NoData";
import { Paper } from "@material-ui/core";
import moment from "moment";
import { EndPoints } from "../../utils/EndPoints";
import CreateDialog from "../../layoutComponents/CreateDialog";
import BagPurchasePdf from "./BagPurchasePdf";
import {
  Divider,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  Button,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { filterData, StopScroll } from "../../Regex";
import { CrossIcon, TopArrow, BottomArrow } from "../../styles/Icons";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import CloseIcon from "@mui/icons-material/Close";
import BrandDetail from "./BrandDetail";
import BrandEmptyBags from "./BrandEmptyBags";
import BrandInventory from "./BrandInventory";

import { SuppliedBagsDialog } from "./SuppliedBagsDialog";

export const CssKeyboardDatePicker = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export class BrandsManagementsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["BrandsManagements"],
        tooltip: `Click to approve pending ${EndPoints["BrandsManagements"]}`,
        data: [],
        route: `/BrandsManagements/${
          EndPoints["BrandsManagements"].split("/")[0]
        }`,
        goBackRoute: "/BrandsManagements",
        primaryField: "brand_name",
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        status: false,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      summaryData: [],
      BrandsManagements: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      openDialog: false,
      selectedBrand: "",
      selctedtMiller: "SSIPL",
      error: false,
      success: false,
      addFieldsToAdd: false,
      selectedPO: [],
      supplierMillerList: [],
      loadingRight: false,
      SupplierDialog: false,
      calenderOpen: false,
      supplierData: new Date(),
      supplierBags: "",
      errorMsg: {
        supplierBags: "",
      },
      paginationData: {},
      resetPage: "",
      selectedValue: 0,
      ricebag_movement_radio: "Purchase Order",
      GodownList: [],
      PortList: [],
      selectedRiceMovementData: {},
      bill_date: "",
      bill_no: "",
      remaining_bags: "",
      supplied_bags_amount: "",
      tax_amount: "",
      freight_or_vat_amount: "",
      total_amount: "",
      remaining_amount: "",
      delivery_location: "",
    };
  }
  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.fetchAllData();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["BrandsManagements"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.BrandsManagementsFunction(
                "get",
                this.props.user.uid,
                EndPoints["BrandsManagements"],
                this.state.singlelistView.id,
                "BrandsManagements"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  commanMasterFetchApi = async (
    url,
    arrField,
    params = "",
    onSuccessCallback = false
  ) => {
    await this.props
      .BrandsManagementsFunction(
        "get",
        url,
        null,
        arrField,
        null,
        params ? params : { is_active: "True" }
      )
      .then((res) => {
        console.log(res);
        if (res) {
          let data = res;
          this.setState({ [arrField]: data }, () => {
            onSuccessCallback && onSuccessCallback(res);
          });
        }
      });
  };

  fetchRiceBagMovementList = (urlText = false, type = false) => {
    console.log("ID BRAND", this.state.singlelistView);
    this.setState({ loadingRight: true, RiceBagList: [], paginationData: {} });
    this.props
      .BrandsManagementsFunction(
        "get",
        type === "pagination" ? urlText : "dispatch/rice-bag-movement/list",
        null,
        "RiceBagList",
        null,
        type === "pagination"
          ? null
          : { brand__id: this.state.singlelistView.id },
        type === "pagination" ? true : false
      )
      .then((RiceBagDataList) => {
        let RiceBagList = RiceBagDataList?.results
          ? RiceBagDataList?.results
          : RiceBagDataList;
        if (RiceBagList) {
          this.setState(
            {
              RiceBagList: filterData(
                RiceBagList,
                "brand",
                this.state.singlelistView?.id
              )
                ? filterData(
                    RiceBagList,
                    "brand",
                    this.state.singlelistView?.id
                  )
                : [],
            },
            () => {
              this.setState({
                paginationData:
                  this.state.RiceBagList?.length > 0
                    ? {
                        count: RiceBagDataList?.count_on_page || 0,
                        total_count: RiceBagDataList?.total_count || 0,
                        next: RiceBagDataList?.next || null,
                        previous: RiceBagDataList?.previous || null,
                      }
                    : {},
              });
            }
          );
        } else {
          this.setState({
            RiceBagList: [],
            loadingRight: false,
            paginationData: {},
          });
        }
      });
  };

  fetchBagPurchaseOrderList = (urlText = false, type = false) => {
    this.setState({ loadingRight: true, BagPoList: [], paginationData: {} });
    this.props
      .BrandsManagementsFunction(
        "get",
        type === "pagination" ? urlText : "dispatch/bag-purchase-order/list",
        null,
        "BagPoList",
        null,
        type === "pagination"
          ? null
          : { brand__id: this.state.singlelistView.id },
        type === "pagination" ? true : false
      )
      .then((BagPoDataList) => {
        let BagPoList = BagPoDataList?.results
          ? BagPoDataList?.results
          : BagPoDataList;
        if (BagPoList) {
          this.setState(
            {
              BagPoList: filterData(
                BagPoList,
                "brand",
                this.state.singlelistView.id
              )
                ? filterData(BagPoList, "brand", this.state.singlelistView.id)
                : [],
              loadingRight: false,
            },
            () => {
              console.table(BagPoDataList);
              this.setState({
                paginationData:
                  this.state.BagPoList?.length > 0
                    ? {
                        count: BagPoDataList?.count_on_page || 0,
                        total_count: BagPoDataList?.total_count || 0,
                        next: BagPoDataList?.next || null,
                        previous: BagPoDataList?.previous || null,
                      }
                    : {},
              });
            }
          );
        } else {
          this.setState({
            BagPoList: [],
            loadingRight: false,
            paginationData: {},
          });
        }
      });
  };
  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,
        loadingRight: true,
      },
      () => {
        console.log(
          "TABVAlue",
          this.state?.selectedValue,
          this.state?.selectedValue == 4
        );
        if (this.state?.selectedValue === 0) {
          this.fetchRiceBagMovementList();
        } else if (this.state?.selectedValue === 1) {
          this.fetchBagPurchaseOrderList();
        }
        this.props
          .BrandsManagementsFunction(
            "get",
            "dispatch/purchase-order/list",
            null,
            "POList",
            null,
            { brand: this.state.singlelistView.id }
          )
          .then((POList) => {
            if (POList) {
              this.setState({ POList });
            }
          });
        // this.props
        //   .BrandsManagementsFunction(
        //     "get",
        //     "dispatch/ssipl-bag-inventory/list",
        //     null,
        //     // this.state.singlelistView.id,
        //     "BagInventoryList"
        //   )
        //   .then((BagInventoryList) => {
        //     if (BagInventoryList) {
        //       this.setState({
        //         BagInventoryList: filterData(
        //           BagInventoryList,
        //           "brand",
        //           this.state.singlelistView.id
        //         )
        //           ? filterData(
        //               BagInventoryList,
        //               "brand",
        //               this.state.singlelistView.id
        //             )
        //           : [],
        //       });
        //     }
        //   });

        // this.props
        //   .BrandsManagementsFunction(
        //     "get",
        //     "arrival/commercial-invoice/empty-bags-history",
        //     null,
        //     "BagEmptyList",
        //     null,
        //     {
        //       is_active: "True",
        //       brand_id: this.state.singlelistView.id,
        //     }
        //   )
        //   .then((BagEmptyList) => {
        //     if (BagEmptyList && Array.isArray(BagEmptyList)) {
        //       console.log(BagEmptyList.data, "rrrrrrrrrrrrr");
        //       const formattedArray = BagEmptyList.flatMap((item) => {
        //         // Check if the item is not undefined and has extra_empty_bags
        //         if (item && item.extra_empty_bags) {
        //           // Extract the values of extra_empty_bags
        //           const extraBagsValues = Object.values(item.extra_empty_bags);

        //           // Create a new object with the rest of the fields
        //           const restFields = { ...item };
        //           delete restFields.extra_empty_bags;

        //           // Create a new object for each set of extra empty bags
        //           return extraBagsValues.map((bags) => ({
        //             ...restFields,
        //             ...bags,
        //           }));
        //         } else {
        //           // If item is undefined or has no extra_empty_bags, return an empty array
        //           return [];
        //         }
        //       });

        //       console.log(formattedArray, BagEmptyList, "formattedArray");
        //       this.setState({ BagEmptyList: formattedArray });
        //     }
        //   });
        this.props
          .BrandsManagementsFunction(
            "get",
            "masters/ricebagseller/list",
            null,
            "RiceBagSeller",
            null,
            {
              is_active: "True",
            }
          )
          .then((RiceBagSeller) => {
            if (RiceBagSeller) {
              this.setState({
                RiceBagSeller: Object.entries(RiceBagSeller).flatMap(
                  ([key, value]) =>
                    value.map((item) => ({
                      ...item,
                      obj_type: key,
                    }))
                ),
                RiceBagSellerObject: RiceBagSeller,
              });
            }
            console.log(RiceBagSeller, "seller list");
          });
        this.props
          .BrandsManagementsFunction(
            "get",
            "masters/miller/list",
            null,
            "ReceiverList",
            null,
            {
              is_active: "True",
              brand_id: this.state.singlelistView.id,
            }
          )
          .then((ReceiverList) => {
            if (ReceiverList) {
              this.setState({ ReceiverList });
            }
          });
        // this.props
        //   .BrandsManagementsFunction(
        //     "get",
        //     EndPoints["InlandGodown"],
        //     null,
        //     "InlandGodownList",
        //     null,
        //     {
        //       is_active: "True",
        //     }
        //   )
        //   .then((InlandGodownList) => {
        //     if (InlandGodownList) {
        //       this.setState({ InlandGodownList });
        //     }
        //   });

        // this.props
        //   .BrandsManagementsFunction(
        //     "get",
        //     "dispatch/rice-bag-count",
        //     null,
        //     //this.state.singlelistView.id,
        //     "RiceBagCountList",
        //     null,
        //     { is_active: "True", brand_id: this.state.singlelistView.id }
        //   )
        //   .then((RiceBagCountList) => {
        //     if (RiceBagCountList) {
        //       this.setState({ RiceBagCountList });
        //     }
        //   });
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  pageChangeAPI = (url) => {
    const { selectedValue } = this.state;
    if (Number(selectedValue) === 0)
      this.fetchRiceBagMovementList(url, "pagination");
    else if (Number(selectedValue) === 1) {
      this.fetchBagPurchaseOrderList(url, "pagination");
    }
  };
  resetPaginationPage = (field) => {
    this.setState({ resetPage: field });
  };

  setInitialValues = (props) => {
    const { BrandList } = props;
    const { ItemCateoryList, ItemList, PackagingList, MillerList, POList } =
      this.state;
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["BrandsManagements"]] &&
        props[EndPoints["BrandsManagements"]].length > 0
          ? props[EndPoints["BrandsManagements"]]
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
              .map((sBrand) => {
                if (
                  ItemCateoryList &&
                  ItemCateoryList.length > 0 &&
                  ItemList &&
                  ItemList.length > 0
                ) {
                  // sBrand.item_category_name = ItemCateoryList.filter(
                  //   (sB) => sB.id === sBrand.item_category
                  // )
                  //   ? ItemCateoryList.filter(
                  //       (sB) => sB.id === sBrand.item_category
                  //     ) &&
                  //     ItemCateoryList.filter(
                  //       (sB) => sB.id === sBrand.item_category
                  //     )[0] &&
                  //     ItemCateoryList.filter(
                  //       (sB) => sB.id === sBrand.item_category
                  //     )[0].name
                  //   : "";
                  // sBrand.item_type_name =
                  //   ItemList &&
                  //   ItemList.filter((sB) => sB.id === sBrand.item_type)
                  //     ? ItemList.filter(
                  //         (sB) => sB.id === sBrand.item_type
                  //       )[0] &&
                  //       ItemList.filter((sB) => sB.id === sBrand.item_type)[0]
                  //         .broken_percentage
                  //       ? ItemList.filter((sB) => sB.id === sBrand.item_type)[0]
                  //           .broken_percentage
                  //       : ""
                  //     : "";
                  return sBrand;
                } else {
                  return sBrand;
                }
              })
          : [];
      return tab;
    });
    this.setState(
      {
        listViewArray:
          props[EndPoints["BrandsManagements"]] &&
          props[EndPoints["BrandsManagements"]].length > 0
            ? props[EndPoints["BrandsManagements"]].map((sBrand) => {
                if (
                  BrandList &&
                  BrandList.length > 0 &&
                  ItemCateoryList &&
                  ItemCateoryList.length > 0
                ) {
                  sBrand.po_number = POList.filter(
                    (sB) => sB.id === sBrand.purchase_order
                  )
                    ? POList.filter((sB) => sB.id === sBrand.purchase_order)[0]
                        .po_number
                    : "";

                  if (sBrand.supplier_miller) {
                    sBrand.miller_name =
                      MillerList &&
                      MillerList.filter(
                        (sB) => sB.id === sBrand.supplier_miller
                      )
                        ? MillerList.filter(
                            (sB) => sB.id === sBrand.supplier_miller
                          )[0].name
                        : "";
                  }
                  if (sBrand.supplier_other) {
                    sBrand.miller_name = "SSIPL";
                  }
                  sBrand.receiver_name =
                    MillerList &&
                    MillerList.filter((sB) => sB.id === sBrand.receiver)
                      ? MillerList.filter((sB) => sB.id === sBrand.receiver)[0]
                          .name
                      : "";

                  sBrand.packaging_name =
                    PackagingList &&
                    PackagingList.filter((sB) => sB.id === sBrand.packaging)
                      ? PackagingList.filter(
                          (sB) => sB.id === sBrand.packaging
                        ).map((sP) => {
                          sP.sizeUnit = `${sP.size}${sP.unit}${" - "}${
                            sP.bag_type
                          }`;
                          return sP;
                        })[0].sizeUnit
                      : "";
                } else {
                  sBrand.packaging_name = " ";
                }
                return sBrand;
              })
            : [],

        BrandsManagements: props.BrandsManagements,
        tabs,
        loading: props.loading,
      },
      () => {
        if (
          this.state.singlelistView &&
          this.state.singlelistView.id &&
          props[EndPoints["BrandsManagements"]] &&
          props[EndPoints["BrandsManagements"]].length > 0
        ) {
          const newData = props[EndPoints["BrandsManagements"]].filter(
            (sB) => sB.id === this.state.singlelistView.idsupplierBagsQuanity
          );
          if (newData && newData[0]) {
            this.setState({ singlelistView: newData[0] });
          }
        }
      }
    );
  };

  handleClear = () => {
    this.setState({
      openDialog: false,
      supplierBags: "",
    });
  };
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({
      [event.target.name]: event.target.value,
      errorMsg,
    });
  };

  SubmenuClick = (singleList) => {
    console.log(singleList, "singleList");
    this.props

      .BrandsManagementsFunction(
        "get",
        "dispatch/bag-po-receival/list",
        null,
        //this.state.singlelistView.id,
        "supplierDetail",
        null,
        {
          id: singleList.id,
          packaging_id: singleList["PackagingId"],
          brand_id: singleList["BrandId"],
        }
      )
      .then((supplierDetail) => {
        if (supplierDetail) {
          this.setState(
            {
              supplierDetail,
              submenuId: singleList.id,
              loadingDialog: false,
            },
            () => {
              // this.setWidth(
              //   supplierDetail,
              // );
            }
          );
        } else {
          this.setState({
            supplierDetail: [],
            loadingDialog: false,
          });
        }
      });
  };
  AddSupplierBag = async () => {
    this.setState({ loadingBags: true });

    const addData = {
      date: moment(this.state.supplierData).format("YYYY-MM-DD"),
      supplied_bags: Number(this.state.supplierBags),
      bag_purchase_order: this.state.SupplierDialog.id,
    };

    await this.props
      .BrandsManagementsFunction(
        "post",
        "dispatch/bag-po-receival/list",
        null,
        // this.state.singlelistView.id,
        "SupplierDetail",
        addData
      )
      .then((SupplierDetail) => {
        this.setState({
          success: "supplier bag added",
          // SupplierDialog: false,
        });
      });
  };
  fetchAllData = async () => {
    this.setState({ loader: true });

    await this.commanMasterFetchApi(
      "masters/item-category/list",
      "ItemCateoryList"
    );
    await this.commanMasterFetchApi("masters/item-type/list", "ItemList");
    await this.commanMasterFetchApi("masters/miller/list", "MillerList");
    await this.commanMasterFetchApi(
      EndPoints["InlandGodown"],
      "InlandGodownList"
    );
    await this.commanMasterFetchApi("masters/port/list", "PortList");
    await this.commanMasterFetchApi(
      "masters/packaging/list",
      "PackagingList",
      "",
      () => {
        this.setState({ loader: false }, () =>
          this.setInitialValues(this.props)
        );
      }
    );

    // await this.props
    //   .BrandsManagementsFunction(
    //     "get",
    //     "masters/item-category/list",
    //     null,
    //     "ItemCateoryList",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((ItemCateoryList) => {
    //     if (ItemCateoryList) {
    //       this.setState({ ItemCateoryList });
    //     }
    //   });

    // await this.props
    //   .BrandsManagementsFunction(
    //     "get",
    //     "masters/item-type/list",
    //     null,
    //     // this.state.singlelistView.id,
    //     "ItemList",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((ItemList) => {
    //     if (ItemList) {
    //       this.setState({ ItemList }, () => {
    //         this.setInitialValues(this.props);
    //       });
    //     }
    //   });
    // await this.props
    //   .BrandsManagementsFunction(
    //     "get",
    //     "masters/miller/list",
    //     null,
    //     // this.state.singlelistView.id,
    //     "MillerList",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((MillerList) => {
    //     if (MillerList) {
    //       this.setState({ MillerList }, () => {
    //         this.setInitialValues(this.props);
    //       });
    //     }
    //   });
    // await this.props
    //   .BrandsManagementsFunction(
    //     "get",
    //     "masters/port/list",
    //     null,
    //     // this.state.singlelistView.id,
    //     "PortList",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((PortList) => {
    //     if (PortList) {
    //       this.setState({ PortList });
    //     }
    //   });

    // await this.props
    //   .BrandsManagementsFunction(
    //     "get",
    //     "masters/packaging/list",
    //     null,
    //     // this.state.singlelistView.id,
    //     "PackagingList",
    //     null,
    //     { is_active: "True" }
    //   )
    //   .then((PackagingList) => {
    //     console.log(PackagingList, "packingListCopy fetch");
    //     this.setState({ loader: false });
    //     if (PackagingList) {
    //       this.setState(
    //         { PackagingList, loader: false, packingListCopy: PackagingList },
    //         () => {
    //           this.setInitialValues(this.props);
    //         }
    //       );
    //     }
    //   });
  };

  fetchGodownList = (port_id) => {
    this.props
      .BrandsManagementsFunction(
        "get",
        "masters/godown/list",
        null,
        "GodownList",
        null,
        { port_id: port_id }
      )
      .then((GodownList) => {
        if (GodownList) {
          this.setState({ GodownList });
        }
      });
  };
  fetchInlandGodownDispatchList = (brandID, inlandGodownID) => {
    this.props
      .BrandsManagementsFunction(
        "get",
        "dispatch/list",
        null,
        "PODispatchedList",
        null,
        {
          post_dispatch: "False",
          // is_draft: "True",
          inland_godown_id: inlandGodownID,
          dispatch_status: "dispatched",
          // brand_id: brandID,
        }
      )
      .then((DispatchedList) => {
        if (DispatchedList) {
          this.setState({ DispatchedList: DispatchedList?.results });
        } else {
          this.setState({ DispatchedList: [] });
        }
      });
  };

  fetchInlandGodownDispatchPackagingList = (brandID, dispatchID) => {
    this.props
      .BrandsManagementsFunction(
        "get",
        "dispatch/dispatch-packaging/list",
        null,
        "PODispatchedList",
        null,
        {
          dispatch_id: dispatchID,
          brand_id: brandID,
        }
      )
      .then((dispatchPackaging) => {
        if (dispatchPackaging) {
          this.setState({
            dispatchPackaging: dispatchPackaging.map((item) => {
              return {
                ...item,
                sizeUnit: `${item.packaging_details.size} ${item.packaging_details.unit} ${item.packaging_details.bag_type}`,
                id: item.packaging_details.id,
                ID: item.id,
              };
            }),
          });
        } else {
          this.setState({ dispatchPackaging: [] });
        }
      });
  };

  handleSubMenuListDataChange = () => {
    console.log(this.state.tabs);
    let tabs = [...this.state.tabs];
    tabs[0].data[0].brand_name = "new name";
    this.setState({
      tabs: tabs,
    });
  };

  render() {
    const {
      tabs,
      listViewArray,
      singlelistView,
      loading,
      openDialog,
      ItemCateoryList,
      selectedBrand,
      ItemList,
      POList,
      MillerList,
      PackagingList,
      packingListCopy,
      selctedtMiller,
      selectedReceiver,
      ReceiverList,
      RiceBagSeller,
      RiceBagSellerObject,
      editList,
      loader,
      RiceBagList,
      BagInventoryList,
      BagPoList,
      RiceBagCountList,
      selectedPO,
      supplierMillerList,
      loadingRight,
      sellerGst,
      BagEmptyList,
      SupplierDialog,
      calenderOpen,
      error,
      success,
      supplierDetail,
      supplierData,
      supplierBags,
      errorMsg,
      loadingDialog,
      paginationData,
      selectedValue,
      ricebag_movement_radio,
      GodownList,
      PortList,
      InlandGodownList,
      DispatchedList,
      dispatchPackaging,
      selectedRiceMovementData,
    } = this.state;
    var labelField = "brand_name";

    console.log(selectedPO, "selectedPO");
    console.log(supplierDetail, "supplierDetail");
    console.log(PackagingList, "PackagingList");
    console.log(this.props);

    return (
      <Layout
        loading={
          loader === true ? loader : loading[EndPoints["BrandsManagements"]]
        }
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Brands`,
          labelField: labelField,
          visible: true,
          apiCall: (text) =>
            this.props.BrandsManagementsFunction(
              "get",
              EndPoints["BrandsManagements"],
              null,
              null,
              null,
              { search: text }
            ),
        }}
        fabClickHandler={
          loader === true
            ? false
            : (value) => {
                this.setState({
                  openDialog: true,
                  loadingFun: false,
                  selctedtMiller: "SSIPL",
                  selectedReceiver: false,
                  editList: false,
                });
              }
        }
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        {SupplierDialog !== false ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "300px";

                target.style.height = "300px";
                target.style.minWidth = "60%";
                target.style.maxWidth = "60%";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.setState(
                {
                  SupplierDialog: false,
                  // editDialog: false,
                  // added: false,
                },
                () => {
                  // this.reset("no snac");
                }
              );
            }}
            TransitionComponent={Transition}
          >
            <DialogTitle
              id="confirm-dialog"
              style={{ background: "rgb(241, 154, 48)", color: "white" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Add Supplier Bag Detail</div>
                <div>
                  {" "}
                  <Tooltip title="Close">
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      color="#fff"
                      onClick={() => this.setState({ SupplierDialog: false })}
                    />
                  </Tooltip>
                </div>
              </div>
            </DialogTitle>

            <DialogContent
              className="dashedDividerBottom"
              style={{ height: "100%" }}
            >
              {this.state.loadingBags === true ? (
                <div className="alignC justiyC d-flex width-100 height-100">
                  <StyledCircularProgress color="rgb(241, 154, 48)" />
                </div>
              ) : !this.state.loadingBags && success ? (
                <div className="alignC justiyC d-flex width-100 height-100">
                  Bag updated
                </div>
              ) : (
                <div
                  style={{
                    margin: "20px 0 20px 0",
                    height: "calc(100% - 40px)",
                    // width: width ? width : edit ? "100%" : "calc(100% - 30px)",
                    // minWidth: width && "100%",
                    fontSize: "0.9rem",
                  }}
                >
                  <div className="d-flex flexWrap justifyFS">
                    {/* <MuiPickersUtilsProvider
                      libInstance={moment()}
                      utils={DateFnsUtils}
                    >
                    <CssKeyboardDatePicker
                        label={"Supplier Date"}
                        variant="inline"
                        onKeyUp={(ev) => {
                          ev.preventDefault();
                        }}
                        onKeyDown={(ev) => {
                          ev.preventDefault();
                        }}
                        onOpen={() => {
                          this.setState({ calenderOpen: true });
                        }}
                        onClose={() => {
                          this.setState({ calenderOpen: false });
                        }}
                        //
                        //
                        // minDate={"2022-01-22"}
                        // error={errorMsg.supplierData ? true : false}
                        // helperText={errorMsg.supplierData ? errorMsg.supplierData : ""}
                        invalidLabel=""
                        onKeyPress={(ev) => {
                          ev.preventDefault();
                        }}
                        allowKeyboardControl={false}
                        keyboardIcon={
                          calenderOpen ? <TopArrow /> : <BottomArrow />
                        }
                        autoOk
                        inputVariant="outlined"
                        style={
                          {
                            width: "100%",
                            maxWidth: "100%",
                            marginRight: "20px",
                          } //disabled={tp}
                        }
                        format="do MMM, yyyy"
                        value={supplierData ? supplierData : new Date()}
                        //views={['year', 'month']}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(newValue) => {
                          this.setState({
                            supplierData: newValue,
                            // errorMsg,
                          });
                        }}
                      />
                    </MuiPickersUtilsProvider> */}
                  </div>
                </div>
              )}
              {error || success ? (
                <SnackbarComponent
                  autoHideDuration={error ? 5000 : 1500}
                  error={error}
                  success={success}
                  snackbarClose={() => {
                    // if (success === `${module} Edited`) {

                    if (!error) {
                      this.setState(
                        {
                          error: "",
                          success: "",
                          SupplierDialog: false,
                          loadingBags: false,
                        },
                        () => {
                          this.handleClear();
                        }
                      );
                    } else {
                    }
                    this.setState(
                      { error: "", success: "", loadingBags: false },
                      () => {}
                    );
                    // } else {
                    //   this.setState({ error: "", success: "" });
                    // }
                  }}
                />
              ) : (
                ""
              )}
            </DialogContent>
            {this.state.loadingBags === true ||
            (!this.state.loadingBags && success) ? (
              ""
            ) : (
              <DialogActions
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  minHeight: "52.5px",
                }}
              >
                {" "}
                <div
                  //className="d-flex alignC justifyFE"
                  style={{
                    margin: "10px",
                    //width: "calc(25% - 20px)",
                    minWidth: "calc(25% - 20px)",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <StyledButton
                    name={"Reset"}
                    onClick={() => {
                      this.handleClear();
                    }}
                  />
                  <StyledButton
                    name={"Add"}
                    minWidth="30px"
                    width="150px"
                    margin="0 10px"
                    withBg={true}
                    onClick={() => {
                      this.handleClear();
                      if (BagPoList.length > 0) {
                        if (this.state.supplierData) {
                          if (this.state.supplierBags) {
                            if (
                              Number(this.state.supplierBags) <=
                              Number(this.state.SupplierDialog.Quantity)
                            ) {
                              this.AddSupplierBag();
                            } else {
                              return this.setState({
                                errorMsg,
                                error: "Quantity exceeded",
                              });
                              // this.setState({
                              //   // loadingDialog: true,
                              //   SupplierDialog: false,
                              // });
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.supplierBags = true;

                            // return this.setState({
                            //   errorMsg,
                            //   error: "Quantity exceeded",
                            // });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.supplierData = true;

                          return this.setState({ errorMsg });
                        }
                      }
                    }}
                    // onClick={()=>{
                    //   this.AddSupplierBag();
                    // }}
                  />
                </div>
              </DialogActions>
            )}
          </Dialog>
        ) : (
          ""
        )}
        {openDialog === true ? (
          <CreateDialog
            module={"Brand"}
            closeOnSave={true}
            selectedList={editList ? editList : false}
            editList={editList ? editList : false}
            handleClose={() => {
              this.setState({
                openDialog: false,
                loadingFun: false,
                editList: false,
                selctedtMiller: "SSIPL",
                selectedReceiver: false,
              });
            }}
            fetchAgain={() => {
              this.props.BrandsManagementsFunction(
                "get",
                EndPoints["BrandsManagements"]
              );
            }}
            postApi="masters/brand/list"
            editApi="masters/brand"
            textFieldWidth="calc(50% - 20px)"
            heightNew="400px"
            maxHeight="calc(100vh - 40px)"
            widthNew="500px"
            inputArray={[
              {
                name: "Active",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList.is_active : true,
                },
                api: "is_active",
              },
              {
                name: "Brand",
                type: "textField",
                dataType: "text",
                api: "brand_name",
                defaultValue: {
                  value: editList && editList.brand_name,
                },
              },
              // {
              //   name: "Item Category",
              //   type: "select",
              //   dataType: "text",
              //   array:
              //     ItemCateoryList && ItemCateoryList.length > 0
              //       ? ItemCateoryList
              //       : [],
              //   value: "name",
              //   defaultValue: {
              //     value:
              //       editList && editList.item_category_name
              //         ? editList.item_category_name
              //         : "",
              //     id:
              //       editList && editList.item_category
              //         ? editList.item_category
              //         : "",
              //   },
              //   api: "item_category",
              //   selectonChange: true,
              // },
              // {
              //   name: "Item Type",
              //   type: "select",
              //   array: ItemList && ItemList.length > 0 ? ItemList : [],
              //   value: "broken_percentage",
              //   defaultValue: {
              //     value:
              //       editList && editList.item_type_name
              //         ? editList.item_type_name
              //         : "",
              //     id: editList && editList.item_type ? editList.item_type : "",
              //   },
              //   selectonChange: true,
              //   endAdornment: "%",
              //   api: "item_type",
              // },
            ]}
            postData={this.props.BrandsManagementsFunction}
          />
        ) : (
          ""
        )}

        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.BrandsManagements === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="rgb(241, 154, 48)" />
              {console.log("SIN", singlelistView)}
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                const selctedTab = this.setState(
                  { selectedValue: newVal },
                  () => {
                    if (newVal === Number(0)) {
                      this.fetchRiceBagMovementList();
                    } else if (Number(newVal) === 1) {
                      this.fetchBagPurchaseOrderList();
                    }
                  }
                );
              }}
              selectedValue={selectedValue ? selectedValue : 0}
              list={[
                {
                  name: "Rice Bag Movement",
                  component: (
                    <TableComponent
                      textFieldWidth="calc(33% - 20px)"
                      heightNew="500px"
                      maxHeight="calc(100vh - 40px)"
                      widthNew="900px"
                      setSelectedList={(selectedData) => {
                        console.log("selectedData", selectedData);
                        this.setState({
                          selectedRiceMovementData: selectedData,
                        });
                        if (
                          !selectedData.godown &&
                          !selectedData?.inland_godown
                        ) {
                          const selectedPOData = filterData(
                            POList,
                            "id",
                            Number(selectedData["Receiver Purchase OrderId"]),
                            "onlyOne"
                          )
                            ? filterData(
                                POList,
                                "id",
                                Number(
                                  selectedData["Receiver Purchase OrderId"]
                                ),
                                "onlyOne"
                              )
                            : "";
                          if (
                            Boolean(selectedData["Receiver Purchase OrderId"])
                          ) {
                            this.props
                              .BrandsManagementsFunction(
                                "get",
                                "dispatch/po-items/list",
                                null,
                                "po",
                                null,
                                {
                                  is_active: "True",
                                  po_id: Number(
                                    selectedData["Receiver Purchase OrderId"]
                                  ),

                                  brand_id:
                                    this.state.singlelistView &&
                                    this.state.singlelistView.id
                                      ? this.state.singlelistView.id
                                      : "",
                                }
                              )
                              .then((supplierPO) => {
                                if (supplierPO) {
                                  const packagingId = [];

                                  const packaging = [];
                                  console.log(supplierPO, "supplierPO");
                                  if (
                                    supplierPO.length &&
                                    supplierPO.length > 0
                                  ) {
                                    supplierPO.map((po) => {
                                      po.packaging_detail.map((s) => {
                                        if (!packagingId.includes(s)) {
                                          // const pVal = filterData(
                                          //   PackagingList,
                                          //   "id",
                                          //   Number(s),
                                          //   "onlyOne"
                                          // )
                                          //   ? filterData(
                                          //       PackagingList,
                                          //       "id",
                                          //       Number(s),
                                          //       "onlyOne"
                                          //     )
                                          //   : undefined;
                                          if (s) {
                                            packaging.push(s);
                                            packagingId.push(s.id);
                                          }
                                        }
                                        return s;
                                      });
                                    });
                                  }
                                  this.setState({
                                    selectedPO: packaging,
                                  });
                                }
                              });
                          }
                          const selectedPackaging = filterData(
                            selectedPOData.po_packaging,
                            "id",
                            Number(selectedData["PackagingId"]),
                            "onlyOne"
                          )
                            ? filterData(
                                selectedPOData.po_packaging,
                                "id",
                                Number(selectedData["PackagingId"]),
                                "onlyOne"
                              )
                            : "";
                          if (selectedPackaging.id) {
                            this.props
                              .BrandsManagementsFunction(
                                "get",
                                "masters/miller/list",
                                null,
                                "supplierPO",
                                "",
                                {
                                  is_active: "True",
                                  packaging_id: selectedPackaging.id,
                                }
                              )
                              .then((RiceBagList) => {
                                if (RiceBagList) {
                                  this.setState({
                                    supplierMillerList: RiceBagList,
                                  });
                                }
                              });
                          }
                          console.log(selectedData, "editselectedData");
                        }
                        this.setState({
                          selctedtMiller:
                            selectedData["Supplier"] === "SSIPL"
                              ? "SSIPL"
                              : selectedData["Supplier"],
                          selectedReceiver: selectedData["Receiver"],
                        });
                      }}
                      onAdd={() => {
                        this.setState({ selctedtMiller: "SSIPL" });
                      }}
                      // actions={[]}
                      noAddButton={false}
                      module={"Rice Bag Movement"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        "Date",
                        "Packaging",
                        "Total Bags",
                        "Supplier",
                        // "Supplier Purchase Order",
                        "Receiver",
                        // "Receiver Purchase Order",
                      ]}
                      secondLineHeaderData={{
                        Supplier: {
                          secondLiner: "Supplier Purchase Order",
                          secondLinerHover: [],
                        },
                        Receiver: {
                          secondLiner: "Receiver Purchase Order",
                          secondLinerHover: [],
                        },
                      }}
                      tablePagination={true}
                      paginationData={paginationData}
                      pageChangeAPI={this.pageChangeAPI}
                      resetPage={this.state.resetPage}
                      {...this.props}
                      tableList={
                        RiceBagList && RiceBagList.length
                          ? RiceBagList.map((singleDispatch) => {
                              singleDispatch["Date"] = moment(
                                singleDispatch.date
                              ).format("ddd, MMM Do YYYY");

                              singleDispatch["DateMonth"] = singleDispatch.date;

                              singleDispatch["Packaging"] =
                                PackagingList && PackagingList.length > 0
                                  ? PackagingList.map((sP) => {
                                      sP.sizeUnit = `${sP.size}${
                                        sP.unit
                                      }${" - "}${sP.bag_type}`;
                                      return sP;
                                    }).filter(
                                      (sP) => sP.id === singleDispatch.packaging
                                    ) &&
                                    PackagingList.map((sP) => {
                                      sP.sizeUnit = `${sP.size}${
                                        sP.unit
                                      }${" - "}${sP.bag_type}`;
                                      return sP;
                                    }).filter(
                                      (sP) => sP.id === singleDispatch.packaging
                                    )[0] &&
                                    PackagingList.map((sP) => {
                                      sP.sizeUnit = `${sP.size}${
                                        sP.unit
                                      }${" - "}${sP.bag_type}`;
                                      return sP;
                                    }).filter(
                                      (sP) => sP.id === singleDispatch.packaging
                                    )[0].sizeUnit
                                  : "-";

                              singleDispatch["PackagingId"] =
                                singleDispatch.packaging;

                              singleDispatch["Total Bags"] =
                                singleDispatch.total_bags_supplied;
                              singleDispatch.action = singleDispatch.is_editable
                                ? "true"
                                : "false";
                              singleDispatch["Supplier"] =
                                singleDispatch.supplier_other &&
                                singleDispatch.supplier_other === "SSIPL"
                                  ? singleDispatch.supplier_other
                                  : // .toUpperCase()
                                  filterData(
                                      MillerList,
                                      "id",
                                      singleDispatch.supplier_miller,
                                      "onlyOne"
                                    )
                                  ? filterData(
                                      MillerList,
                                      "id",
                                      singleDispatch.supplier_miller,
                                      "onlyOne"
                                    ).name
                                  : "";
                              singleDispatch["SupplierId"] =
                                singleDispatch.supplier_other &&
                                singleDispatch.supplier_other === "SSIPL"
                                  ? "SSIPL"
                                  : singleDispatch.supplier_miller;
                              singleDispatch["Receiver"] = filterData(
                                ReceiverList,
                                "id",
                                singleDispatch.receiver,
                                "onlyOne"
                              )
                                ? filterData(
                                    ReceiverList,
                                    "id",
                                    singleDispatch.receiver,
                                    "onlyOne"
                                  ).name
                                : "";
                              singleDispatch["ReceiverId"] =
                                singleDispatch.receiver;

                              singleDispatch["Supplier Purchase Order"] =
                                singleDispatch.purchase_order_supplier
                                  ? filterData(
                                      POList,
                                      "id",
                                      singleDispatch.purchase_order_supplier,
                                      "onlyOne"
                                    )
                                    ? filterData(
                                        POList,
                                        "id",
                                        singleDispatch.purchase_order_supplier,
                                        "onlyOne"
                                      ).po_number
                                    : ""
                                  : "";

                              singleDispatch["Supplier Purchase OrderId"] =
                                singleDispatch.purchase_order_supplier
                                  ? singleDispatch.purchase_order_supplier
                                  : "-";

                              singleDispatch["Receiver Purchase Order"] =
                                singleDispatch.purchase_order_receiver
                                  ? filterData(
                                      POList,
                                      "id",
                                      singleDispatch.purchase_order_receiver,
                                      "onlyOne"
                                    )
                                    ? filterData(
                                        POList,
                                        "id",
                                        singleDispatch.purchase_order_receiver,
                                        "onlyOne"
                                      ).po_number
                                    : ""
                                  : "-";
                              singleDispatch["Receiver Purchase OrderId"] =
                                singleDispatch.purchase_order_receiver
                                  ? singleDispatch.purchase_order_receiver
                                  : "-";
                              return singleDispatch;
                            })
                          : []
                      }
                      addFieldsToAdd={[
                        { name: "is_active", value: true },
                        selctedtMiller &&
                          selctedtMiller === "SSIPL" && {
                            name: "purchase_order_supplier",
                            value: "",
                          },
                        selctedtMiller &&
                          selctedtMiller === "SSIPL" && {
                            name: "supplier_miller",
                            value: "",
                          },
                        selctedtMiller &&
                          selctedtMiller !== "SSIPL" && {
                            name: "supplier_other",
                            value: "",
                          },
                      ]}
                      reset={() => {
                        this.setState({
                          selctedtMiller: "SSIPL",
                          selectedReceiver: false,
                          selectedPO: false,
                        });
                      }}
                      postApi="dispatch/rice-bag-movement/list"
                      editApi="dispatch/rice-bag-movement"
                      handleClose={() => {
                        console.log("close clicked", PortList);
                        this.setState({
                          GodownList: [],
                          selectedRiceMovementData: [],
                          ricebag_movement_radio: "Purchase Order",
                        });
                      }}
                      inputArray={[
                        {
                          name: "Date",
                          type: "Month",
                          dataType: "text",
                          disabled: false,
                          api: "date",
                        },
                        {
                          name: "Brand",
                          type: "select",
                          dataType: "text",
                          disabled: true,
                          array:
                            listViewArray && listViewArray.length > 0
                              ? listViewArray
                              : [],
                          value: "brand_name",
                          defaultValue: {
                            value:
                              singlelistView && singlelistView.brand_name
                                ? singlelistView.brand_name
                                : "",
                            id:
                              singlelistView && singlelistView.id
                                ? singlelistView.id
                                : "",
                          },
                          api: "brand",
                        },
                        {
                          name: "Send To",
                          type: "radio",
                          array: ["Godown", "Inland Godown", "Purchase Order"],
                          value: "send_to",
                          defaultValue: {
                            value:
                              selectedRiceMovementData &&
                              selectedRiceMovementData.godown
                                ? "Godown"
                                : selectedRiceMovementData &&
                                  selectedRiceMovementData.inland_godown
                                ? "Inland Godown"
                                : "Purchase Order",
                          },
                          onChange: async (submittedData) => {
                            this.setState({
                              ricebag_movement_radio: submittedData["Send To"],
                            });
                            return submittedData;
                          },
                        },
                        ricebag_movement_radio === "Purchase Order"
                          ? {
                              name: "Receiver",
                              type: "autocomplete",
                              dataType: "text",
                              array:
                                ReceiverList && ReceiverList.length > 0
                                  ? ReceiverList
                                  : [],
                              value: "name",
                              api: "receiver",
                              multiLineRender: [
                                { label: "", key: "name" },
                                {
                                  label: "GST :",
                                  key: "gstin",
                                },
                              ],
                              autoCompleteData: "ReceiverSelected",
                              onChange: async (submittedData) => {
                                this.setState({
                                  selectedReceiver:
                                    submittedData["ReceiverSelected"]?.name,
                                });

                                return submittedData;
                              },
                            }
                          : "",

                        ricebag_movement_radio === "Purchase Order" &&
                        selectedReceiver &&
                        selectedReceiver !== false
                          ? {
                              name: "Receiver Purchase Order",
                              type: "select",
                              dataType: "text",
                              array: filterData(
                                POList,
                                "miller",
                                filterData(
                                  ReceiverList,
                                  "name",
                                  selectedReceiver,
                                  "onlyOne"
                                )
                                  ? filterData(
                                      ReceiverList,
                                      "name",
                                      selectedReceiver,
                                      "onlyOne"
                                    ).id
                                  : ""
                              )
                                ? filterData(
                                    POList,
                                    "miller",
                                    filterData(
                                      ReceiverList,
                                      "name",
                                      selectedReceiver,
                                      "onlyOne"
                                    )
                                      ? filterData(
                                          ReceiverList,
                                          "name",
                                          selectedReceiver,
                                          "onlyOne"
                                        ).id
                                      : ""
                                  ).sort((a, b) => b.id - a.id)
                                : [],
                              value: "po_number",
                              api: "purchase_order_receiver",
                              onChange: async (submittedData) => {
                                if (
                                  Boolean(
                                    submittedData["Receiver Purchase OrderId"]
                                  )
                                ) {
                                  this.props
                                    .BrandsManagementsFunction(
                                      "get",
                                      "dispatch/po-items/list",
                                      null,
                                      "po",
                                      null,
                                      {
                                        is_active: "True",
                                        po_id: Number(
                                          submittedData[
                                            "Receiver Purchase OrderId"
                                          ]
                                        ),

                                        brand_id:
                                          singlelistView && singlelistView.id
                                            ? singlelistView.id
                                            : "",
                                      }
                                    )
                                    .then((supplierPO) => {
                                      if (supplierPO) {
                                        const packagingId = [];

                                        const packaging = [];

                                        if (
                                          supplierPO.length &&
                                          supplierPO.length > 0
                                        ) {
                                          supplierPO.map((po) => {
                                            po.packaging_detail &&
                                              po.packaging_detail.map((s) => {
                                                if (
                                                  !packagingId.includes(s.id)
                                                ) {
                                                  {
                                                    console.log(
                                                      packagingId,
                                                      "ggggffffgggg"
                                                    );
                                                  }
                                                  // const newPl = s;
                                                  // const pVal = filterData(
                                                  //   newPl,
                                                  //   "id",
                                                  //   Number(s),
                                                  //   "onlyOne"
                                                  // )
                                                  //   ? filterData(
                                                  //       newPl,
                                                  //       "id",
                                                  //       Number(s),
                                                  //       "onlyOne"
                                                  //     )
                                                  //   : undefined;
                                                  if (s) {
                                                    {
                                                      console.log(
                                                        s,
                                                        "gggggggg"
                                                      );
                                                    }
                                                    packaging.push(s);
                                                    packagingId.push(s.id);
                                                  }
                                                }
                                                return s;
                                              });
                                          });
                                        }

                                        this.setState({
                                          selectedPO: packaging,
                                        });
                                      }
                                    });
                                }
                                return submittedData;
                              },
                            }
                          : ricebag_movement_radio === "Godown"
                          ? {
                              name: "Port",
                              type: "autocomplete",
                              dataType: "text",
                              array: PortList,
                              value: "name",
                              defaultValue: {
                                value: filterData(
                                  PortList,
                                  "id",
                                  Number(selectedRiceMovementData.port),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      PortList,
                                      "id",
                                      Number(selectedRiceMovementData.port),
                                      "onlyOne"
                                    )
                                  : "",
                              },

                              api: "port",
                              autoCompleteData: "PortSelected",
                              onChange: async (submittedData) => {
                                this.fetchGodownList(
                                  submittedData["PortSelected"]?.id
                                );
                                return submittedData;
                              },
                            }
                          : ricebag_movement_radio === "Inland Godown"
                          ? {
                              name: "Inland Godown",
                              type: "autocomplete",
                              dataType: "text",
                              array: InlandGodownList,
                              value: "name",
                              defaultValue: {
                                value: filterData(
                                  InlandGodownList,
                                  "id",
                                  Number(
                                    selectedRiceMovementData.inland_godown
                                  ),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      InlandGodownList,
                                      "id",
                                      Number(
                                        selectedRiceMovementData.inland_godown
                                      ),
                                      "onlyOne"
                                    )
                                  : "",
                              },

                              api: "inland_godown",
                              autoCompleteData: "InlandGodownSelected",
                              onChange: async (submittedData) => {
                                submittedData["Dispatch List"] = [];
                                submittedData["dispatchedSelected"] = [];
                                this.fetchInlandGodownDispatchList(
                                  singlelistView.id,
                                  submittedData["InlandGodownSelected"]?.id
                                );
                                return submittedData;
                              },
                            }
                          : "",
                        ricebag_movement_radio === "Godown"
                          ? {
                              name: "Godown",
                              type: "autocomplete",
                              dataType: "text",
                              array: GodownList,
                              value: "name",
                              defaultValue: {
                                value: filterData(
                                  GodownList,
                                  "id",
                                  Number(selectedRiceMovementData.godown),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      GodownList,
                                      "id",
                                      Number(selectedRiceMovementData.godown),
                                      "onlyOne"
                                    )
                                  : "",
                              },

                              api: "godown",
                              autoCompleteData: "godownSelected",
                            }
                          : ricebag_movement_radio === "Inland Godown"
                          ? {
                              name: "Dispatch List",
                              type: "autocomplete",
                              dataType: "text",
                              array: DispatchedList,
                              value: "miller_bill_number",
                              defaultValue: {
                                value: filterData(
                                  DispatchedList,
                                  "id",
                                  Number(selectedRiceMovementData.dispatched),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      DispatchedList,
                                      "id",
                                      Number(
                                        selectedRiceMovementData.dispatched
                                      ),
                                      "onlyOne"
                                    )
                                  : "",
                              },
                              multiLineRender: [
                                {
                                  label: "Bill No. :",
                                  key: "miller_bill_number",
                                  labelB: "",
                                  keyB: "purchase_order_details.po_number",
                                },
                                {
                                  label: "Date :",
                                  key: "dispatch_date",
                                  labelB: "Net Weight :",
                                  keyB: "net_weight",
                                  keyB2: "unit",
                                },
                              ],
                              api: "dispatch",
                              autoCompleteData: "dispatchedSelected",
                              onChange: async (submittedData) => {
                                console.log(submittedData, "dispatchList");
                                this.fetchInlandGodownDispatchPackagingList(
                                  singlelistView.id,
                                  submittedData["dispatchedSelected"]?.id
                                );
                                return submittedData;
                              },
                            }
                          : "",
                        ricebag_movement_radio === "Godown"
                          ? {
                              name: "Inventory Brand",
                              type: "autocomplete",
                              dataType: "text",
                              array:
                                this.state.listViewArray &&
                                this.state.listViewArray.length > 0
                                  ? this.state.listViewArray
                                  : [],
                              defaultValue: {
                                value: filterData(
                                  this.state.listViewArray,
                                  "id",
                                  Number(
                                    selectedRiceMovementData.inventory_brand
                                  ),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      this.state.listViewArray,
                                      "id",
                                      Number(
                                        selectedRiceMovementData.inventory_brand
                                      ),
                                      "onlyOne"
                                    )
                                  : "",
                              },
                              value: "brand_name",
                              sortKey: "brand_name",
                              api: "inventory_brand",
                              autoCompleteData: "InventoryBrandSelected",
                            }
                          : "",
                        ricebag_movement_radio === "Godown" ||
                        ricebag_movement_radio === "Inland Godown"
                          ? {
                              name: "Packaging",
                              type: "autocomplete",
                              dataType: "text",
                              array:
                                packingListCopy && packingListCopy.length > 0
                                  ? packingListCopy.map((sP) => {
                                      const packaging = sP;
                                      sP.sizeUnit = `${packaging.size}${
                                        packaging.unit
                                      }${" - "}${packaging.bag_type}`;
                                      sP.id = sP.id;
                                      return sP;
                                    })
                                  : [],
                              defaultValue: {
                                value: filterData(
                                  packingListCopy,
                                  "id",
                                  Number(selectedRiceMovementData.packaging),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      packingListCopy,
                                      "id",
                                      Number(
                                        selectedRiceMovementData.packaging
                                      ),
                                      "onlyOne"
                                    )
                                  : "",
                              },
                              value: "sizeUnit",
                              sortKey: "sizeUnit",
                              api: "packaging",
                              autoCompleteData: "packingSelected",
                            }
                          : "",

                        selectedPO && selectedPO !== false
                          ? {
                              name: "Packaging",
                              type: "select",
                              dataType: "text",
                              array:
                                selectedPO && selectedPO.length > 0
                                  ? selectedPO.map((sP) => {
                                      const packaging = sP;
                                      sP.sizeUnit = `${packaging.size}${
                                        packaging.unit
                                      }${" - "}${packaging.bag_type}`;
                                      sP.id = sP.id;
                                      return sP;
                                    })
                                  : [],
                              value: "sizeUnit",
                              api: "packaging",
                              onChange: async (submittedData) => {
                                const selectedPackaging = filterData(
                                  selectedPO,
                                  "id",
                                  Number(submittedData["PackagingId"]),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      selectedPO,
                                      "id",
                                      Number(submittedData["PackagingId"]),
                                      "onlyOne"
                                    )
                                  : "";

                                this.props
                                  .BrandsManagementsFunction(
                                    "get",
                                    "masters/miller/list",
                                    null,
                                    "supplierPO",
                                    null,
                                    {
                                      is_active: "True",
                                      packaging_id: selectedPackaging.id,
                                    }
                                  )
                                  .then((supplierPO) => {
                                    if (supplierPO) {
                                      this.setState({
                                        supplierMillerList: supplierPO,
                                      });
                                    }
                                  });
                                return submittedData;
                              },
                            }
                          : "",
                        {
                          name: "Total Bags",
                          type: "textField",
                          dataType: "number",
                          only_number: true,
                          api: "total_bags_supplied",
                          defaultValue: {
                            value: selectedRiceMovementData.total_bags_supplied,
                          },
                        },
                        {
                          name: "Supplier",
                          type: "select",
                          dataType: "text",
                          subheaderTiitle: "Supplier's List",
                          array2: [
                            { name: "SSIPL", id: "SSIPL" },
                            { subheaderTiitle: true },
                          ],
                          array:
                            supplierMillerList && supplierMillerList.length > 0
                              ? supplierMillerList.sort((a, b) => b.id - a.id)
                              : [],
                          value: "name",

                          defaultValue: {
                            value: "SSIPL",
                            id: "SSIPL",
                          },
                          api:
                            selctedtMiller && selctedtMiller === "SSIPL"
                              ? "supplier_other"
                              : "supplier_miller",
                          onChange: async (submittedData) => {
                            this.setState(
                              { selctedtMiller: submittedData["Supplier"] },
                              () => {}
                            );
                            return submittedData;
                          },
                        },
                        ricebag_movement_radio === "Purchase Order"
                          ? selctedtMiller && selctedtMiller === "SSIPL"
                            ? ""
                            : {
                                name: "Supplier Purchase Order",
                                type: "select",
                                dataType: "text",
                                array: filterData(
                                  POList,
                                  "miller",
                                  filterData(
                                    MillerList,
                                    "name",
                                    selctedtMiller,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        MillerList,
                                        "name",
                                        selctedtMiller,
                                        "onlyOne"
                                      ).id
                                    : ""
                                )
                                  ? filterData(
                                      POList,
                                      "miller",
                                      filterData(
                                        MillerList,
                                        "name",
                                        selctedtMiller,
                                        "onlyOne"
                                      )
                                        ? filterData(
                                            MillerList,
                                            "name",
                                            selctedtMiller,
                                            "onlyOne"
                                          ).id
                                        : ""
                                    ).sort((a, b) => b.id - a.id)
                                  : [],
                                value: "po_number",

                                // defaultValue: {
                                //   value:
                                //     editList && editList.po_number
                                //       ? editList.po_number
                                //       : '',
                                //   id:
                                //     editList && editList
                                //       ? editList.purchase_order
                                //       : '',
                                // },
                                api: "purchase_order_supplier",
                              }
                          : "",
                      ]}
                      postData={this.props.BrandsManagementsFunction}
                      fetchAgain={() => {
                        this.props
                          .BrandsManagementsFunction(
                            "get",
                            "masters/miller/list",
                            null,
                            "ReceiverList",
                            null,
                            {
                              is_active: "True",
                              brand_id: this.state.singlelistView.id,
                            }
                          )
                          .then((ReceiverList) => {
                            if (ReceiverList) {
                              this.setState({ ReceiverList });
                            }
                          });

                        this.props
                          .BrandsManagementsFunction(
                            "get",
                            "dispatch/ssipl-bag-inventory/list",
                            null,
                            // this.state.singlelistView.id,
                            "BagInventoryList"
                          )
                          .then((BagInventoryList) => {
                            if (BagInventoryList) {
                              this.setState({
                                BagInventoryList: filterData(
                                  BagInventoryList,
                                  "brand",
                                  this.state.singlelistView.id
                                )
                                  ? filterData(
                                      BagInventoryList,
                                      "brand",
                                      this.state.singlelistView.id
                                    )
                                  : [],
                              });
                            }
                          });

                        this.props
                          .BrandsManagementsFunction(
                            "get",
                            "dispatch/rice-bag-count",
                            null,
                            //this.state.singlelistView.id,
                            "RiceBagCountList",
                            null,
                            {
                              is_active: "True",
                              brand_id: this.state.singlelistView.id,
                            }
                          )
                          .then((RiceBagCountList) => {
                            if (RiceBagCountList) {
                              this.setState({ RiceBagCountList });
                            }
                          });

                        this.props
                          .BrandsManagementsFunction(
                            "get",
                            "dispatch/rice-bag-movement/list",
                            null,
                            // this.state.singlelistView.id,
                            "RiceBagList"
                          )
                          .then((RiceBagDataList) => {
                            let RiceBagList = RiceBagDataList?.results
                              ? RiceBagDataList?.results
                              : RiceBagDataList;
                            if (RiceBagList) {
                              this.setState({
                                RiceBagList: filterData(
                                  RiceBagList,
                                  "brand",
                                  this.state.singlelistView.id
                                )
                                  ? filterData(
                                      RiceBagList,
                                      "brand",
                                      this.state.singlelistView.id
                                    )
                                  : [],
                              });
                            }
                          });
                        this.props.BrandsManagementsFunction(
                          "get",
                          EndPoints["BrandsManagements"]
                        );
                      }}
                      loader={loadingRight ? loadingRight : false}
                    />
                  ),
                },
                {
                  name: "Bag Purchase Order",
                  component: (
                    <>
                      <TableComponent
                        textFieldWidth="calc(33% - 20px)"
                        heightNew="500px"
                        maxHeight="calc(100vh - 40px)"
                        widthNew="900px"
                        fetchSubmenuData={this.SubmenuClick}
                        noAddButton={false}
                        // actions={[]}
                        module={"Bag purchase order"}
                        {...this.state}
                        subMenu={true}
                        header={[
                          "Sr.No.",
                          "Action",
                          "Date",
                          "Godown Location",
                          "Packaging",
                          "Bag PO Number",
                          "Buyer",
                          // "Buyer Address",
                          // "Buyer GST",
                          "Seller",
                          // "Seller Address",
                          // "Seller GST",
                          "Commodity Name",
                          "Quantity",
                          "Price",
                          "Bags Weight",
                          // "Delivery Date",
                          "Delivery Place",
                          // "Taxes",
                          "Payment",
                          // "Vehicle Number",
                          // "Total Freight",
                          "Brand",
                          "PDF",
                        ]}
                        subMenuheader={[
                          "Sr.No.",
                          "Supplier Date",
                          "Supplier Bags",
                        ]}
                        secondLineHeaderData={{
                          Buyer: {
                            secondLiner: "",
                            secondLinerHover: ["Buyer Address", "Buyer GST"],
                          },
                          Seller: {
                            secondLiner: "",
                            secondLinerHover: ["Seller Address", "Seller GST"],
                          },
                        }}
                        tablePagination={true}
                        paginationData={paginationData}
                        pageChangeAPI={this.pageChangeAPI}
                        resetPage={this.state.resetPage}
                        {...this.props}
                        tableList={
                          BagPoList && BagPoList.length
                            ? BagPoList.sort((a, b) => {
                                var dateA = moment(b.created_at).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateB = moment(a.created_at).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateA) - new Date(dateB);
                              }).map((singleDispatch) => {
                                singleDispatch["Action"] = () => {
                                  return (
                                    <SuppliedBagsDialog
                                      supplierDialogData={singleDispatch}
                                      BrandsManagementsFunction={
                                        this.props.BrandsManagementsFunction
                                      }
                                      fetchBagPurchaseOrderList={
                                        this.fetchBagPurchaseOrderList
                                      }
                                    />
                                  );
                                };
                                singleDispatch["subList"] =
                                  this.state.submenuId &&
                                  this.state.submenuId === singleDispatch.id &&
                                  supplierDetail
                                    ? supplierDetail.map((singleSub) => {
                                        singleSub["Supplier Date"] =
                                          singleSub.date
                                            ? moment(singleSub.date).format(
                                                "ddd, MMM Do YYYY"
                                              )
                                            : "-";
                                        singleSub["Supplier Bags"] =
                                          singleSub.supplied_bags;
                                        return { ...singleSub };
                                      })
                                    : [];

                                singleDispatch["Date"] = moment(
                                  singleDispatch.date
                                ).format("ddd, MMM Do YYYY");
                                singleDispatch["Godown Location"] =
                                  singleDispatch.godown_location;
                                singleDispatch["DateMonth"] =
                                  singleDispatch.date;
                                const newPk = PackagingList;
                                singleDispatch["Packaging"] =
                                  newPk && newPk.length > 0
                                    ? newPk
                                        .map((sP) => {
                                          sP.sizeUnit = `${sP.size}${
                                            sP.unit
                                          }${" - "}${sP.bag_type}`;
                                          return sP;
                                        })
                                        .filter(
                                          (sP) =>
                                            sP.id === singleDispatch.packaging
                                        ) &&
                                      newPk
                                        .map((sP) => {
                                          sP.sizeUnit = `${sP.size}${
                                            sP.unit
                                          }${" - "}${sP.bag_type}`;
                                          return sP;
                                        })
                                        .filter(
                                          (sP) =>
                                            sP.id === singleDispatch.packaging
                                        )[0] &&
                                      newPk
                                        .map((sP) => {
                                          sP.sizeUnit = `${sP.size}${
                                            sP.unit
                                          }${" - "}${sP.bag_type}`;
                                          return sP;
                                        })
                                        .filter(
                                          (sP) =>
                                            sP.id === singleDispatch.packaging
                                        )[0].sizeUnit
                                    : "-";

                                singleDispatch["PackagingId"] =
                                  singleDispatch.packaging;

                                singleDispatch["Bag PO Number"] =
                                  singleDispatch.po_number;

                                singleDispatch["Buyer"] = singleDispatch.buyer;
                                singleDispatch["Buyer Address"] =
                                  singleDispatch.buyer_address;
                                singleDispatch["Buyer GST"] =
                                  singleDispatch.buyer_gst;

                                singleDispatch["Seller"] =
                                  RiceBagSeller &&
                                  singleDispatch.seller &&
                                  filterData(
                                    RiceBagSeller,
                                    "id",
                                    Number(singleDispatch.seller),
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        RiceBagSeller,
                                        "id",
                                        Number(singleDispatch.seller),
                                        "onlyOne"
                                      ).name
                                    : "";
                                singleDispatch["Seller Address"] =
                                  singleDispatch.seller_address;
                                singleDispatch["Seller GST"] =
                                  singleDispatch.seller_gst;

                                singleDispatch["Commodity Name"] =
                                  singleDispatch.commodity_name;

                                singleDispatch["Quantity"] =
                                  singleDispatch.quantity;

                                singleDispatch["Price"] = singleDispatch.price;

                                singleDispatch["Bags Weight"] =
                                  singleDispatch.bags_weight;

                                // singleDispatch["Delivery Date"] =
                                //   singleDispatch.delivery_date;
                                singleDispatch["Delivery DateMonth"] =
                                  singleDispatch.delivery_date;

                                singleDispatch["Delivery Place"] =
                                  singleDispatch.delivery_place;

                                // singleDispatch["Taxes"] = singleDispatch.taxes;

                                singleDispatch["Payment"] =
                                  singleDispatch.payment;

                                // singleDispatch["Vehicle Number"] = singleDispatch.vehicle_number;

                                // singleDispatch["Total Freight"] = singleDispatch.total_freight;

                                singleDispatch["Brand"] =
                                  singlelistView.brand_name;
                                singleDispatch["BrandId"] = singlelistView.id;
                                singleDispatch.action =
                                  singleDispatch.is_editable ? "true" : "false";

                                const selectedPOData = filterData(
                                  POList,
                                  "po_number",
                                  singleDispatch["po_number"],
                                  "onlyOne"
                                )
                                  ? filterData(
                                      POList,
                                      "po_number",
                                      singleDispatch["po_number"],
                                      "onlyOne"
                                    )
                                  : "";
                                // singleDispatch["Supplier"]= singleDispatch["Supplier"]==="SSIPL"?"ssipl":
                                singleDispatch["po_date"] = moment(
                                  selectedPOData.date
                                ).format("DD-MM-YYYY");
                                console.log(singleDispatch, "singleDispatch");
                                singleDispatch["PDF"] = () => {
                                  return (
                                    <BagPurchasePdf
                                      singleDispatch={singleDispatch}
                                      singlelistView={singlelistView}
                                    />
                                  );
                                };
                                console.log(singleDispatch, "singleDispatch");
                                return singleDispatch;
                              })
                            : []
                        }
                        addFieldsToAdd={[{ name: "is_active", value: true }]}
                        postApi="dispatch/bag-purchase-order/list"
                        editApi="dispatch/bag-purchase-order"
                        inputArray={[
                          {
                            name: "Date",
                            type: "Month",
                            dataType: "text",
                            disabled: false,
                            api: "date",
                            maxDate: true,
                          },
                          {
                            name: "Godown Location",
                            type: "textField",
                            dataType: "text",

                            api: "godown_location",
                          },
                          {
                            name: "Brand",
                            type: "select",
                            dataType: "text",
                            disabled: true,
                            array:
                              listViewArray && listViewArray.length > 0
                                ? listViewArray
                                : [],
                            value: "brand_name",
                            defaultValue: {
                              value:
                                singlelistView && singlelistView.brand_name
                                  ? singlelistView.brand_name
                                  : "",
                              id:
                                singlelistView && singlelistView.id
                                  ? singlelistView.id
                                  : "",
                            },
                            api: "brand",
                          },
                          {
                            name: "Buyer",
                            type: "textField",
                            disabled: true,
                            api: "buyer",
                            dataType: "text",
                            defaultValue: {
                              value: "SRI SAINATH INDUSTRY PVT.LTD",
                            },
                            value: "SRI SAINATH INDUSTRY PVT.LTD",
                          },
                          {
                            name: "Buyer Address",
                            type: "textField",

                            api: "buyer_address",
                            dataType: "text",
                            disabled: true,
                            defaultValue: {
                              value:
                                "C/o Anand Oil Mill, behind Samrat Talkies, civil station road raipur C.G",
                            },
                          },
                          {
                            name: "Buyer GST",
                            type: "textField",
                            dataType: "text",
                            disabled: true,
                            onInput: "gst_in",
                            value: "22AALCS6148P1ZR",
                            defaultValue: {
                              value: "22AALCS6148P1ZR",
                            },
                            api: "buyer_gst",
                          },
                          {
                            name: "Seller",
                            type: "select",
                            dataType: "text",
                            group: true,
                            groupKey: "obj_type",
                            // array: RiceBagSellerObject || {},
                            array:
                              RiceBagSeller && RiceBagSeller.length > 0
                                ? RiceBagSeller.sort((a, b) => b.id - a.id)
                                : [],

                            value: "name",
                            defaultValue: {
                              value:
                                editList && editList.seller
                                  ? filterData(
                                      RiceBagSeller,
                                      "id",
                                      Number(editList.seller),
                                      "onlyOne"
                                    )
                                    ? filterData(
                                        RiceBagSeller,
                                        "id",
                                        Number(editList.seller),
                                        "onlyOne"
                                      ).name
                                    : ""
                                  : "",

                              id:
                                editList && editList.seller
                                  ? editList.seller
                                  : "",
                            },
                            api: "seller",

                            onChange: async (submittedData) => {
                              if (submittedData["Seller"]) {
                                const selectedSeller = submittedData["Seller"];
                                const selectedSel =
                                  selectedSeller &&
                                  filterData(
                                    RiceBagSeller,
                                    "name",
                                    selectedSeller,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        RiceBagSeller,
                                        "name",
                                        selectedSeller,
                                        "onlyOne"
                                      )
                                    : "";
                                submittedData["Seller GST"] = selectedSel
                                  ? selectedSel.gstin
                                  : "";
                                submittedData["Seller Address"] = selectedSel
                                  ? selectedSel.address
                                  : "";
                                submittedData["SellerId"] = selectedSel
                                  ? selectedSel.id
                                  : "";
                              }
                              return submittedData;
                            },
                          },
                          { name: "SellerId" },
                          {
                            name: "Seller Address",
                            type: "textField",
                            // only_number: true,
                            onInput: "address",
                            dataType: "text",
                            array:
                              RiceBagSeller && RiceBagSeller.length > 0
                                ? RiceBagSeller.sort((a, b) => b.id - a.id)
                                : [],
                            disabled: true,
                            value: "address",
                            api: "seller_address",
                          },
                          {
                            name: "Seller GST",
                            type: "textField",
                            // only_number: true,
                            onInput: "gst_in",
                            dataType: "text",
                            array:
                              RiceBagSeller && RiceBagSeller.length > 0
                                ? RiceBagSeller.sort((a, b) => b.id - a.id)
                                : [],
                            disabled: true,
                            value: "gstin",
                            api: "seller_gst",
                          },
                          {
                            name: "Commodity Name",
                            type: "textField",
                            dataType: "text",
                            api: "commodity_name",
                          },

                          {
                            name: "Price",
                            type: "textField",
                            dataType: "number",
                            api: "price",
                            startAdornment: "₹",
                            endAdornment: "/bag",
                          },
                          {
                            name: "Quantity",
                            type: "textField",
                            dataType: "number",
                            endAdornment: "bags",
                            only_number: true,
                            api: "quantity",
                          },
                          {
                            name: "Packaging",
                            type: "select",
                            dataType: "text",
                            array:
                              PackagingList && PackagingList.length > 0
                                ? PackagingList.map((sP) => {
                                    const packaging = sP;
                                    sP.sizeUnit = `${packaging.size}${
                                      packaging.unit
                                    }${" - "}${packaging.bag_type}`;
                                    return sP;
                                  })
                                : [],
                            value: "sizeUnit",

                            api: "packaging",
                          },
                          {
                            name: "Bags Weight",
                            type: "textField",
                            dataType: "number",
                            endAdornment: "grams",
                            api: "bags_weight",
                          },
                          {
                            name: "Delivery Place",
                            type: "textField",
                            dataType: "text",
                            api: "delivery_place",
                          },
                          {
                            name: "Payment",
                            type: "textField",
                            not_required: true,
                            dataType: "text",
                            api: "payment",
                          },
                        ]}
                        postData={this.props.BrandsManagementsFunction}
                        fetchAgain={() => {
                          this.props
                            .BrandsManagementsFunction(
                              "get",
                              "dispatch/bag-purchase-order/list",
                              null,
                              // this.state.singlelistView.id,
                              "BagPoList"
                            )
                            .then((BagPoList) => {
                              if (BagPoList) {
                                this.setState({
                                  BagPoList: filterData(
                                    BagPoList,
                                    "brand",
                                    this.state.singlelistView.id
                                  )
                                    ? filterData(
                                        BagPoList,
                                        "brand",
                                        this.state.singlelistView.id
                                      )
                                    : [],
                                });
                              }
                            });
                          this.props
                            .BrandsManagementsFunction(
                              "get",
                              "dispatch/ssipl-bag-inventory/list",
                              null,
                              // this.state.singlelistView.id,
                              "BagInventoryList"
                            )
                            .then((BagInventoryList) => {
                              if (BagInventoryList) {
                                this.setState({
                                  BagInventoryList: filterData(
                                    BagInventoryList,
                                    "brand",
                                    this.state.singlelistView.id
                                  )
                                    ? filterData(
                                        BagInventoryList,
                                        "brand",
                                        this.state.singlelistView.id
                                      )
                                    : [],
                                });
                              }
                            });
                          this.props.BrandsManagementsFunction(
                            "get",
                            EndPoints["BrandsManagements"]
                          );
                        }}
                        loader={loadingRight ? loadingRight : false}
                      />
                    </>
                  ),
                },
                {
                  name: "Inventory",

                  component: (
                    <BrandInventory
                      {...this.props}
                      PackagingList={this.state.PackagingList}
                      brandId={this.state.singlelistView.id}
                    />
                  ),
                },
                {
                  name: "Empty Bags",

                  component: (
                    <BrandEmptyBags
                      {...this.props}
                      brandId={this.state.singlelistView.id}
                    />
                  ),
                },
                {
                  name: "Brand Details",

                  component: (
                    <>
                      <Paper
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          padding: "10px 10px 20px 10px",
                          marginBottom: "20px",
                        }}
                      >
                        {singlelistView === null ? (
                          <div className="d-flex width-100 height-100 justifyC alignC">
                            <NoData />
                          </div>
                        ) : loader === true ||
                          loading.BrandsManagements === true ? (
                          <div className="alignC justiyC d-flex width-100 height-100">
                            <StyledCircularProgress color="rgb(241, 154, 48)" />
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                height: "10%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Tooltip title="Edit">
                                <EditIcon
                                  onClick={() => {
                                    singlelistView["Date"] =
                                      singlelistView.date;
                                    singlelistView["DateMonth"] =
                                      singlelistView.date;
                                    singlelistView["Brand"] =
                                      singlelistView.brand_name;
                                    singlelistView["BrandId"] =
                                      singlelistView.brand;

                                    // singlelistView["Item Category"] =
                                    //   singlelistView.item_category_name;
                                    // singlelistView["Item CategoryId"] =
                                    //   singlelistView.item_category;

                                    // singlelistView["Item Type"] =
                                    //   singlelistView.item_type_name;
                                    // singlelistView["Item TypeId"] =
                                    //   singlelistView.item_type;

                                    this.setState({
                                      selectedBrand:
                                        selectedBrand && selectedBrand[0]
                                          ? selectedBrand[0]
                                          : "",
                                      openDialog: true,
                                      editList: singlelistView,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </Tooltip>
                            </div>

                            <div style={{ height: "90%" }}>
                              <span style={{ fontSize: "1rem" }}>Brand : </span>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  margin: "0 30px 0 10px",
                                  color: "#808080",
                                  wordBreak: "break-all",
                                }}
                              >
                                {singlelistView.brand_name}
                              </span>
                              <span style={{ fontSize: "1rem" }}>
                                Created At :{" "}
                              </span>{" "}
                              <span
                                style={{
                                  fontSize: "1rem",
                                  margin: "0 15px 0 10px",
                                  color: "#808080",
                                }}
                              >
                                {singlelistView &&
                                  moment(singlelistView.created_at).format(
                                    "ddd, MMM Do YYYY"
                                  )}
                              </span>
                            </div>
                          </>
                        )}
                      </Paper>
                      <BrandDetail
                        {...this.props}
                        brandId={this.state.singlelistView.id}
                      />
                    </>
                  ),
                },
              ]}
            />
          )}
          {console.log("singlelistView", singlelistView)}
        </Paper>
      </Layout>
    );
  }
}

export default BrandsManagementsComponent;
