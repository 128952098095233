import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SubMenuList from "../layoutComponents/SubMenuList";
import { mediumScreen } from "../Regex";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { StyledCircularProgress } from "../styles/StyledComponents";
import StatusChip from "../layoutComponents/StatusChip";
import { SubMenuListSkeleton } from "../layoutComponents/ShowSkeleton";
import CustomSubMenuList from "./CustomSubMenuList";

const styles = {
  container: (isMobile, centerWidth) => ({
    marginTop: "55px",
    height: "calc(100% - 52px)",
    width: isMobile
      ? "100%"
      : mediumScreen()
      ? 175
      : centerWidth
      ? "230px"
      : 291.47,
    minWidth: isMobile
      ? "100%"
      : mediumScreen()
      ? 175
      : centerWidth
      ? centerWidth
      : 270,
    maxWidth: isMobile
      ? "100%"
      : mediumScreen()
      ? 175
      : centerWidth
      ? "230px"
      : 291.47,
    position: "relative",
    borderRight: centerWidth && "1px solid rgb(30 53 89 / 12%)",
  }),
  fab: { position: "absolute", bottom: 16, right: 16, color: "white" },
  accordionTitle: {
    fontFamily: "Poppins",
    fontSize: mediumScreen() ? "14px" : "16px",
    fontWeight: 500,
  },
  accordionSummary: {
    maxHeight: 35,
    minHeight: 35,
    background: "#d0d0d0",
  },
};

function SubMenuLayout(props) {
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const fabHandler = (e) => {
    e.preventDefault();
    if (props.setOpen) props.setOpen(false);
    props.fabClickHandler();
  };
  useEffect(() => {
    setloading(props.loading ? props.loading : false);
  }, [props.loading]);

  const { secondTabData, tabsData } = props;

  return (
    <div style={styles.container(props.isMobile, props.centerWidth)}>
      {console.log(props)}
      {!props?.pathname.includes("/Ports") ? (
        ['/BrandsManagements', '/Millers', '/Brokers', '/Transporters'].some(p => window.location.pathname.startsWith(p))
          ? <CustomSubMenuList {...props} />
        : <> 
          <SubMenuList {...props} />
          {props.tabs && props.fabClickHandler ? (
            loading === true ? (
              ""
            ) : (
              <Fab
                style={styles.fab}
                color="primary"
                aria-label="add"
                size="medium"
                onClick={fabHandler}
              >
                <AddIcon />
              </Fab>
            )
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="height-100 d-flex d-flex-column justifySB">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={styles.accordionSummary}
              sx={{
                [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
                  {
                    transform: "rotate(90deg)",
                  },
              }}
            >
              <Typography style={styles.accordionTitle}>Ports</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, maxHeight: 'calc(100vh - 105px)' }}>
              <div
                style={{
                  position: "relative",
                }}
              >
                <SubMenuList {...props} />
                {props.tabs && props.fabClickHandler ? (
                  loading === true ? (
                    ""
                  ) : (
                    <Fab
                      style={styles.fab}
                      color="primary"
                      aria-label="add"
                      size="medium"
                      onClick={fabHandler}
                    >
                      <AddIcon />
                    </Fab>
                  )
                ) : (
                  ""
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <div style={{ width: "100%", maxHeight: "94%", flex: "1", position: "relative"}}>
          <Accordion
            sx={{
              position: "absolute",
              bottom: 0, // Align it with the top of the first accordion
              left: 0,
              right: 0,
              zIndex: 1, // Ensure it's above the first accordion
              transition: "transform 0.3s ease",
              transformOrigin: "bottom", // Bottom-to-top effect
            }}
          >
            <AccordionSummary
              expandIcon={<ChevronRightIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              style={styles.accordionSummary}
              sx={{
                [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
                  {
                    transform: "rotate(90deg)",
                  },
              }}
            >
              <Typography style={styles.accordionTitle}>
                Inland Godown
              </Typography>
            </AccordionSummary>
            {console.log("inlandLoader",props, secondTabData.inlandLoader)}
            <AccordionDetails style={{ padding: 0, maxHeight: "calc(100vh - 125px)", overflowY: 'auto' }}>
              {secondTabData.inlandLoader ? (
                <div
                  className="alignC justiyC d-flex"
                  style={{ width: "100%", height: "100%", padding: "10px 0" }}
                >
                  <SubMenuListSkeleton tileLength={3} />
                  {/* <StyledCircularProgress /> */}
                </div>
              ) : props.secondTabData?.array ? (
                <div
                  style={{
                    // position: "relative",
                  }}
                >
                  <div>
                    <List style={{ paddingBottom: 70, paddingTop: 0 }}>
                      {props?.secondTabData?.array &&
                        props?.secondTabData?.array.map((item, index) => (
                          <ListItem
                            key={index}
                            style={{
                              padding: "16px",
                              borderBottom: "1px solid rgb(241, 154, 48)",
                              fontSize: mediumScreen() ? "12px" : "14px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              height: 70,
                              cursor: "pointer",
                              backgroundColor:
                                window?.location?.pathname.split("/")[3] ==
                                item?.id
                                  ? "rgba(30, 53, 89, 0.09)"
                                  : "#fff",
                            }}
                            onClick={() => {
                              props.navigate(`/Ports/InlandGodown/${item?.id}`);
                              secondTabData.listItemClickHandler(item);
                            }}
                          >
                            <div className="d-flex d-flex-column width-100">
                              <div>{item.name}</div>
                              <div>
                                <StatusChip
                                  status={
                                    item?.is_active ? "Active" : "Inactive"
                                  }
                                />
                              </div>
                            </div>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                  {secondTabData.fabClickHandler &&
                  secondTabData.fabClickHandler ? (
                    <Fab
                      style={styles.fab}
                      color="primary"
                      aria-label="add"
                      size="medium"
                      onClick={secondTabData.fabClickHandler}
                    >
                      <AddIcon />
                    </Fab>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    textAlign: "center",
                    padding: "20px 0px",
                  }}
                >
                  {" "}
                  No Inland Godown
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubMenuLayout;
